import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertBox from '../common/AlertBox';
import { patchTanentProfile } from '../../redux/thunks/UserProfile';
import Button from './../sub-component/Button';
import Input from './../sub-component/Input';
import { useHistory } from 'react-router-dom';
import { RequestStatus } from '../../utils/Constants';
import { ReactComponent as ErrorIcon } from '../../assets/icons/basic/x-circle-error.svg';


const CompanyInfoForm = () => {

	/**@type {userProfileState} **/
	const { processing_patch_profile } = useSelector(state => state.userProfileReducer);

	const dispatch = useDispatch();
	const history = useHistory();

	const [credentials, setCredentials] = useState({
		company_name: '',
	});

	const [error, setError] = useState({
		company_name: null,
	});

	const [alert, setAlert] = useState(null);

	const [formSubmitted, setFormSubmitted] = useState(false);

	const handleChange = (fieldName, event) => {
		const value = event.target.value;
		setCredentials({ ...credentials, [fieldName]: value });
		setError({ ...error, [fieldName]: null });
	};

	const isFormValid = () => {
		let isValid = true;

		const _credentials = {
			company_name: { value: credentials.company_name, is_required: true },
		};

		let _error = {};
		Object.keys(_credentials).forEach(key => {
			if (_credentials[key].is_required && (_credentials[key].value === null || _credentials[key].value === '')) {
				_error = { ..._error, [key]: `${key.replace('_', ' ')} is required` };
				isValid = false;
			} else {
				_error = { ..._error, [key]: null };
			}
		});

		setError(_error);
		return isValid;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setFormSubmitted(true);

		if (!isFormValid()) {
			return;
		}

		const dispatched = await dispatch(patchTanentProfile({ data: {company_name: credentials.company_name} }));

		if (dispatched.meta.requestStatus === RequestStatus.REJECTED) {
			setAlert(
				<AlertBox description={`${dispatched.payload}!`} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
			);
			return;
		}

		// history.push('/');
		window.location.reload();
	};

	return (
		<div className="signup-input-group signup-form">
			<form onSubmit={handleSubmit}>
			{formSubmitted && error.company_name && (
						<div className="error-text description">
							<div style={{ width: '30px' }}>
						<ErrorIcon />
					</div>
							{error.company_name}
						</div>
					)}

				<div className='alert-wrapper'>
					{alert}
				</div>

				<div className="d-flex flex-column form-input-field">
					<Input
						name={'company_name'}
						placeholder="Company Name"
						onChange={(e) => { handleChange('company_name', e); }}
						type="text"
						value={credentials.company_name}
						className={formSubmitted && error.company_name ? 'error-input' : ''}
					/>
					
				</div>

				<div className="submit-button">
					<Button type={'submit'} rectangle-button onClick={handleSubmit} title={'Continue'} processing={processing_patch_profile} />
				</div>
			</form>
		</div >
	);
};

export default CompanyInfoForm;
