import React from 'react';

import { useHistory } from 'react-router';
import { useState } from 'react';

import OnboardingBox from '../components/OnboardingBox';
import Input from '../components/sub-component/Input';
import Button from '../components/sub-component/Button';
import { useDispatch } from 'react-redux';
import { requestResetPassword } from '../redux/thunks/Auth';
import AlertBox from '../components/common/AlertBox';
import { isEmailValid } from '../utils/utilities';
import { useMediaQuery } from '@mui/material';

function ForgetPassword() {
	const history = useHistory();

	const [email, setEmail] = useState();
	const [processing, setProcessing] = useState(false);
	const [alert, setAlert] = useState(null);

	const dispatch = useDispatch();
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	const handleChange = (value) => {
		setEmail(`${value}`.trim());
		if (alert) {
			setAlert();
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();

		if (!isEmailValid(email)) {
			setAlert(
				<AlertBox description={'Enter a valid email!'} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
			);
			return;
		}

		(async () => {

			setProcessing(true);
			const dispatched = await dispatch(requestResetPassword({ email: email }));
			setProcessing(false);

			if (dispatched.error) {
				setAlert(
					<AlertBox description={`${dispatched.payload}!`} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
				);
				return;
			}

			setAlert(
				<AlertBox description={'Check your email for reset password details'} color="success" title="Success" onCloseAlert={() => { setAlert(null); }} />
			);



		})();

	};
	return (
		<>
			<OnboardingBox >
				<div
					className="d-flex flex-column login-form-container"
				// style={{ maxWidth: '450px' }}
				>
					<div className="form">
						<div className={`${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-3'}`}>Forgot Password</div>
						<div className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '2'} dark-50`} style={{ marginTop: '10px', lineHeight: '24px' }}>
							{`Enter your email and we'll send you instructions to reset your password.`}
						</div>
						<div>
							<form onSubmit={handleSubmit}>
								<div className="login-input-group">
									<div className="d-flex flex-column mt-3" >
										<label className='body-2 dark-50 mb-2' style={{ marginLeft: '24px' }}>Email</label>
										<Input
											// placeholder="Company Email"
											varrient={'primary'}
											onChange={(e) => {
												handleChange(e.target.value);
											}}
											type={'text'}
											value={email}
											onBoardingInput
										/>
									</div>
									<div className='mt-4'>
										{alert}
									</div>
									<div className="">
										<Button
											themeRectangleButton
											// onClick={handleSubmit}
											processing={processing}
											type={'submit'}
											title={'Send Reset Link'}
											style={{ height: '40px' }}
										/>
									</div>
									<div
										className=" body-2 text-center primary-text w-100 pointer"
										style={{ marginTop: '16px' }}
										onClick={() => {
											history.push('/login');
										}}
									>
										{'< Back to login'}
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</OnboardingBox>
		</>
	);
}

export default ForgetPassword;
