import React from 'react';
import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';


export default function CustomSwitch(props) {

	const AntSwitch = styled(Switch)(({ theme }) => ({
		width: 40,
		height: 20,
		padding: 0,
		border: '1px solid #121216',
		borderRadius: 10,
		display: 'flex',
		'&:active': {
			'& .MuiSwitch-thumb': {
				width: 20,
			},
			'& .MuiSwitch-switchBase.Mui-checked': {
				transform: 'translateX(18px)',
			},
		},
		'& .MuiSwitch-switchBase': {
			padding: 1,
			color: '#121216',
			'&.Mui-checked': {
				transform: 'translateX(20px)',
				color: '#fff',
				'& + .MuiSwitch-track': {
					opacity: 1,
					backgroundColor:
						theme.palette.mode === 'dark' ? '#177ddc' : '#121216',
				},
			},
		},
		'& .MuiSwitch-thumb': {
			boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
			width: 16,
			height: 16,
			borderRadius: 8,
			transition: theme.transitions.create(['width'], {
				duration: 200,
			}),
		},
		'& .MuiSwitch-track': {
			borderRadius: 16 / 2,
			opacity: 1,
			backgroundColor:
				theme.palette.mode === 'dark'
					? 'rgba(255,255,255,.35)'
					: 'rgba(0,0,0, 0)',
			boxSizing: 'border-box',
		},
	}));
	return (
		<div className='primary-switch '>
			<div className='primary-switch-container'>
				<AntSwitch
					className={`${props.className} switch`}
					checked={props.checked}
					defaultChecked={props.defaultChecked}
					onChange={props.onChange}
					readOnly={true}
					value={props.value}
					inputProps={{ 'aria-label': 'ant design' }}
				/>
				<span className='body-2 dark-50' style={{ ...props.labelStyle }}>
					{props.label}
				</span>
			</div>
		</div>
	);
}
