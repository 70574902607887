import React, { useEffect } from 'react';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/components/common/modal/cross.svg';
import { ReactComponent as Cross2Icon } from '../../../../assets/icons/Views/dashboard/assessments/x.svg';
import { ReactComponent as ListIcon } from '../../../../assets/icons/components/common/modal/list.svg';
import { ReactComponent as BarChartIcon } from '../../../../assets/icons/components/common/modal/bar-chart.svg';
import { ReactComponent as CodeIcon } from '../../../../assets/icons/components/common/modal/code.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/components/common/modal/clock.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icons/Views/dashboard/assessments/plus.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/Views/dashboard/assessments/eye.svg';
import { secondsToDhms } from '../../../../utils/utilities';
import Button from '../../../sub-component/Button';
import { useMediaQuery } from '@mui/material';

export default function TestpackDetails(props) {
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const { add_remove_assessment, isItemAdded, handleCloseModal, disabled, preview_questions, showPreviewPage } = props;
	useEffect(() => {
		console.log(props?.test, '#props.test123');
	}, [props?.test]);
	return (
		<div className='view-testpack-details-container hide-scrollbar'>
			
			
			
				
			
			<div className='VTD-container-header'>
				<div className='w-100 d-flex justify-content-between'>
					<span className='headline-3 ' style={{textAlign:'left',width:isOnMobileScreen ? '70%' : '50%'}}>
						{props?.test?.title}
					</span>
					{isOnMobileScreen ? 
						<div className='w-90 d-flex flex-row row-wrap' style={{justifyContent:'space-between',gap:'12px'}}>
							{ preview_questions && 
					
					<div 
						onClick={showPreviewPage}
						className='d-flex align-items-center justify-content-center'
						style={{
							height: '44px',
							width:'44px',
							background: 'white',
							borderRadius:'50px',
							border:'1px solid #121216'

						}}>
						<EyeIcon color='white' stroke='white' />
					</div>
							}
							<div 
								onClick={() => {
									add_remove_assessment(props.test);
								}}
								className='d-flex align-items-center justify-content-center'
								style={{
									height: '44px',
									width:'44px',
									background: '#121216',
									borderRadius:'50px',
								}}>
								{
									isItemAdded ? (
										<Cross2Icon style={{ width: '15.4px',stroke:'white' }} />
									) : (
										<AddIcon style={{ width: '15.4px',stroke:'white' }} />
									)
								}
							</div>	
							<div onClick={handleCloseModal} >	
								<CrossIcon style={{height:'44px',width:'44px',cursor:'pointer'}}/>
							</div>					
						</div>
						:
						<div className='w-90 d-flex flex-row row-wrap' style={{justifyContent:'space-between',gap:'12px'}}>
							{ preview_questions && 
						<Button
							onClick={showPreviewPage}
							style={{ height: '26px' }}
							btn='ragular-btn'
							varrient='secondary-btn'
							size='sm'
							className='coding-test-box-btn'
							title={'Preview Sample Questions'}
							preIcon={ <EyeIcon style={{ width: '15.4px' ,stroke:'white'}} /> }
						/>
							}
					
							<Button
								onClick={() => {
									add_remove_assessment(props.test);
								}}
								style={{ height: '26px' }}
								btn='ragular-btn'
								varrient='secondary-btn'
								disabled={disabled}
								size='sm'
								className={`${
									isItemAdded
										? 'selected-coding-test-box-btn'
										: disabled 
											? 'disabled-coding-test-box-btn'
											: 'coding-test-box-btn'
								}`}
								title={isItemAdded ? 'Remove from Assessment' : 'Add to Assessment'}
								preIcon={
									isItemAdded ? (
										<Cross2Icon style={{ width: '15.4px' }} />
									) : (
										<AddIcon style={{ width: '15.4px' }} />
									)
								}
							/>
							<div onClick={handleCloseModal} >	
								<CrossIcon style={{height:'44px',width:'44px',cursor:'pointer'}}/>
							</div>
						
						</div>

					
					}
				
				</div>
				<span className='body-2 dark-100 w-100' style={{textAlign:'left'}}>
					{props?.test?.brief_description || '-'}
				</span>
			
			</div>
			<div className='VTD-container-details'>
				<div className='details-and-coverd-skills-sections'>
					<div className='details-section'>
						<div className='test-detail-box' style={{width:props?.test?.test_pack_type === 'coding_test_pack' ? '24%' : '31'}}>
							
							<div className='d-flex flex-column' style={{gap:'8px'}}>
								<span className='body-2 dark-50'>Test Category</span>
								<span className='headline-5 dark-100'>
									{props?.test?.category?.name || '-'}
								</span>
							</div>
							<ListIcon />
						</div>
						<div className='test-detail-box' style={{width:props?.test?.test_pack_type === 'coding_test_pack' ? '23%' : '32%'}}>
							
							<div className='d-flex flex-column' style={{gap:'8px'}}>
								<span className='body-2 dark-50'>Total Time</span>
								<span className='headline-5 dark-100'>
									{secondsToDhms(props?.test?.duration, true) || '-'}
								</span>
							</div>
							<ClockIcon />
						</div>
						{props?.test?.test_pack_type === 'coding_test_pack' && (
							<div className='test-detail-box' style={{width:props?.test?.test_pack_type === 'coding_test_pack' ? '23%' : '32%'}}>
								
								<div className='d-flex flex-column' style={{gap:'8px'}}>
									<span className='body-2 dark-50'>
										Code Language
									</span>
									<span className='headline-5 dark-100'>
										{props?.test?.supported_coding_languages_text || '-'}
									</span>
								</div>
								<CodeIcon />
							</div>
						)}
						<div className='test-detail-box' style={{width:props?.test?.test_pack_type === 'coding_test_pack' ? '23%' : '32%'}}>
							
							<div className='d-flex flex-column' style={{gap:'8px'}}>
								<span className='body-2 dark-50'>Test Level</span>
								<span className='headline-5 dark-100'>
									{props?.test?.difficulty}{' '}
								</span>
							</div>
							<BarChartIcon />
						</div>
					</div>
					<div className='coverd-skills-section'>
						<span className='subtitle-2 dark-50'>
							Covered Skills
						</span>
						<div style={{padding:'24px',background:'#F6F5F8',borderRadius:'24px',minHeight:'40px',maxheight:'150px'}}>
							<ol>
								{props?.test?.covered_skills?.map((skill, i) => (
									<li
										key={i}
										className='body-2 dark-100'
										style={{ marginBottom: '12px',marginLeft:'-20px',lineHeight:'10px',textAlign:'left' }}
									>
										{skill.name}
									</li>
								))}
							</ol>
						</div>
					</div>
				</div>
				<div className='d-flex flex-column details-and-coverd-skills-sub-section' style={{ gap: '12px' }}>
					<span className='subtitle-2 dark-50'>
						Relevant Job Roles
					</span>
					<div style={{padding:'24px',background:'#F6F5F8',borderRadius:'24px',textAlign:'left'}}>
						<span className='body-2 dark-100'>
							{props?.test?.test_relevance || '-'}
						</span>
					</div>
				</div>
				<div className='d-flex flex-column details-and-coverd-skills-sub-section' style={{ gap: '12px' }}>
					<span className='subtitle-2 dark-50'>
						Description
					</span>
					<div style={{padding:'24px',background:'#F6F5F8',borderRadius:'24px',textAlign:'left'}}>
						<span className='body-2 dark-100'>
							{props?.test?.description || '-'}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
