import React from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import { TestType, UserRole } from '../../../utils/Constants';
import ProcessingIndicator from '../../../components/common/ProcessingIndicator';

export default function CaseStudyQuestionDetails(props) {
	const { responses, isForPublic } = props;
	const auth = useSelector((state) => state.authReducer);
	const assessSpec = useSelector((state) => state.assessSpecReducer);
	const assessments = useSelector((state) => state.assessmentReducer);
	const publicResult = useSelector((state) => state.publicResultReducer);
	const resultData = publicResult?.public_result_data && publicResult?.public_result_data;

	if(assessSpec.loading) {
		return <ProcessingIndicator />;
	}

	return (
		<div className='w-100 d-flex flex-column' style={{ gap: '24px' }}>
			<span className='body-1 dark-50 text-start'>
				Introductory Text
			</span>
			<ReactQuill
				className='ql-editor-test-instruction'
				theme='bubble'
				readOnly
				value={(auth?.data?.role?.name === UserRole.CANDIDATE && !isForPublic) ?
					assessments?.current_assessment?.tests.find(
						(test) =>
							test.test_type === TestType.CASE_STUDY_TEST,
					)?.intro_text
					:
					resultData ?
						resultData?.tests.find(
							(test) =>
								test.test_type === TestType.CASE_STUDY_TEST,
						)?.intro_text
						:
						assessSpec?.curr_assessment_spec?.tests.find(
							(test) =>
								test.test_type === TestType.CASE_STUDY_TEST,
						)?.intro_text
				}
			/>
			{(auth?.data?.role?.name === UserRole.CANDIDATE && !isForPublic) ?
				responses.map(
					(question, index) => {
						return (
							<div key={index} className='w-100 d-flex flex-column align-items-start' style={{padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#FFF', gap: '16px'}}>
								<span className='subtitle-2 dark-100 text-start'>{`Question ${index + 1}`}</span>
								<div className='w-100 d-flex flex-column align-items-start' style={{padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#F6F5F8', gap: '10px'}}>
									<ReactQuill
										className='ql-editor-test-instruction'
										theme='bubble'
										readOnly
										value={
											question.text
										}
									/>
								</div>
							</div>
						);
					},
				)
				:
				resultData ?
					resultData?.responses?.filter(r => r.test_type === TestType.CASE_STUDY_TEST)?.map(
						(question, index) => {
							return (
								<div key={index} className='w-100 d-flex flex-column align-items-start' style={{padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#FFF', gap: '16px'}}>
									<span className='subtitle-2 dark-100 text-start'>{`Question ${index + 1}`}</span>
									<div className='w-100 d-flex flex-column align-items-start' style={{padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#F6F5F8', gap: '10px'}}>
										<ReactQuill
											className='ql-editor-test-instruction'
											theme='bubble'
											readOnly
											value={
												question.text
											}
										/>
									</div>
								</div>
							);
						},
					)
					:
					assessSpec?.curr_assessment_spec?.tests.find(t => t.test_type === TestType.CASE_STUDY_TEST)?.questions?.map(
						(question, index) => {
							return (
								<div key={index} className='w-100 d-flex flex-column align-items-start' style={{padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#FFF', gap: '16px'}}>
									<span className='subtitle-2 dark-100 text-start'>{`Question ${index + 1}`}</span>
									<div className='w-100 d-flex flex-column align-items-start' style={{padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#F6F5F8', gap: '10px'}}>
										<ReactQuill
											className='ql-editor-test-instruction'
											theme='bubble'
											readOnly
											value={
												question.text
											}
										/>
									</div>
								</div>
							);
						},
					)
			}
		</div>
	);
}
