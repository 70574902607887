import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from '../Constants';
import CookieDisclaimer from '../../views/CookieDisclaimer';

export const CommonContext = createContext(null);

export default function CommonContextWrapper(props) {


	const auth = useSelector((state) => state.authReducer);

	const [commonValues, setCommonValues] = useState({
		isSideBarExpanded: true,
		detailedTestView: { isExpanded: false, testType: null, data: null }
	});


	return (
		<CommonContext.Provider value={{ commonValues, setCommonValues }}>
			{
				props.children
			}
			<CookieDisclaimer />
		</CommonContext.Provider>
	);
}
