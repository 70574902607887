import React, { useEffect, useState } from 'react';
import CustomModal from '../../../common/Modal';
import Input from '../../../sub-component/Input';
import Select from '../../../sub-component/Select';
import { isEmailValid } from '../../../../utils/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, deleteUser, updateUser } from '../../../../redux/thunks/User';
import { UserRole } from '../../../../utils/Constants';

export default function AddTeamModal(props) {
	const { open, handleCloseModal, systemRoles, adminEmail, action, selectedUser } = props;

	// const [option, setOption] = useState();
	const [newUser, setNewUser] = useState({
		email: null,
		role_id: null,
	});

	const [selectedAssessSpecs, setSelectedAssessSpecs] = useState([]);

	const [error, setError] = useState();
	const [processing, setProcessing] = useState(false);

	const assessSpecs = useSelector((store) => store.assessSpecReducer);
	const dispatch = useDispatch();

	const handleSubmit = async () => {
		if (!isEmailValid(newUser.email)) {
			setError('Invalid email address');
			return;
		}

		if (newUser.email.split('@')[1] !== adminEmail.split('@')[1]) {
			setError(
				`Email address should contain @${adminEmail?.split('@')[1]} domain`
			);
			return;
		}

		setError(false);

		if (action === 'add') {
			const data = newUser;

			data['assessment_spec_ids'] = selectedAssessSpecs.map(
				(selectedSpec) => selectedSpec.id
			);

			setProcessing(true);
			const dispatched = await dispatch(
				createUser({ data: newUser, query: '?expand=role' })
			);
			setProcessing(false);

			if (dispatched.error) {
				setError(dispatched.payload);
				return;
			}
		}
		else {

			setProcessing(true);
			const dispatched = await dispatch(
				updateUser({ data: newUser, id: selectedUser.id, users: props.users })
			);
			setProcessing(false);

			if (dispatched.error) {
				setError(dispatched.payload);
				return;
			}
		}


		setNewUser({
			email: null,
			role_id: null,
		});
		handleCloseModal();
	};

	const handleDeleteUser = async () => {
		setProcessing(true);
		const dispatched = await dispatch(
			deleteUser({ id: selectedUser.id, email: selectedUser.email })
		);
		setProcessing(false);

		if (dispatched.error) {
			setError(dispatched.payload);
			return;
		}
		setNewUser({
			email: null,
			role_id: null,
		});
		handleCloseModal();
	};

	useEffect(() => {
		if (action !== 'add' && selectedUser) {
			setNewUser({
				email: selectedUser?.email,
				role_id: selectedUser?.role.id
			});
		}

	}, [selectedUser, action]);


	return (
		<CustomModal
			open={open}
			handleCloseModal={() => {
				setError(null);
				setNewUser({
					email: null,
					role_id: null,
				});
				handleCloseModal();
			}}
			btnStyle={{ padding: '13px 16px' }}
			btnWidth="144px"
			onConfirmBtnDisable={((newUser?.email === '' || newUser?.email === null) || newUser?.role_id === null) ? true : false}
			confirmBtnTitle={action === 'update' ? 'Update Member' : action === 'delete' ? 'Delete Member' : 'Add Member'}
			onConfirm={() => {
				setError(null);
				if (action === 'delete') {
					handleDeleteUser();
				}
				else {
					handleSubmit();
				}
			}}
			processing={processing}
		>
			<div className="team-modal d-flex flex-column" style={{padding:'24px'}}>
				<div className="team-detail d-flex flex-column">
					{
						action === 'add'
							?
							<div className="modal-header d-flex flex-column">
								<span className="headline-4 dark-100 ">Add New Member</span>
								<span className="body-2 dark-50 mt-2">Add team member and give permissions</span>
							</div>
							:
							action === 'update'
								?
								<div className="modal-header d-flex flex-column ">
									<span className="headline-4 dark-100">Update Member Details</span>
									<span className="body-2 dark-50 mt-2">Update team member and give permissions</span>
								</div>
								:
								<div className="modal-header d-flex flex-column">
									<span className="headline-4 dark-100">Delete Member</span>
									{/* <span className="body-3">Update team member and give permissions</span> */}
								</div>

					}

					<div className="team-email d-flex flex-column">
						<label className="body-2 dark-50" style={{marginLeft:'24px'}}>Email</label>
						<Input
							disabled={action !== 'add'}
							value={newUser.email}
							varrient="primary"
							className="email-input body-3 primary-input"
							// placeholder="Enter email"
							onChange={(event) => {
								setNewUser({ ...newUser, email: event.target.value });
							}}
						/>
						<label className={` dark-50 body-2`} style={{marginLeft:'24px'}}>
							<span className={`${error ? 'danger-text' : ''}`}>
								{error ||
									`Email address should contain @${adminEmail?.split('@')[1]
									} domain`}
							</span>
						</label>
						{/* <label className="description semibold">
							Use comma to add more than one email
						</label> */}
					</div>
					<div className="team-role d-flex flex-column">
						<label className="body-2 dark-50" style={{marginLeft:'24px'}}>Role</label>
						<Select
							disabled={action === 'delete'}
							readOnly={true}
							options={systemRoles.map((role) => ({
								name: role.name,
								value: role.id,
							}))}
							selected={newUser.role_id}
							onSelection={(option) =>
								setNewUser({
									...newUser,
									name: option.name,
									role_id: option.value,
								})
							}
							placeholder={'Select Role'}
							className="role-select"
						/>
					</div>
					{newUser?.name === UserRole.HIRING_MANAGER && (
						<div className="team-role d-flex flex-column">
							<label className="description semibold">Assessments</label>
							<Select
								readOnly={true}
								options={assessSpecs.data
									?.filter(
										(spec) =>
											!selectedAssessSpecs.find(
												(selectedSpec) => selectedSpec.id === spec.id
											)
									)
									.map((spec) => ({
										name: spec.title,
										value: spec.id,
									}))}
								// onSelection={(option) => setNewUser((user) => ({ ...user, assessment_spec_ids: [option.value] }))}
								onSelection={(option) =>
									setSelectedAssessSpecs((preState) => [
										...preState,
										{ name: option.name, id: option.value },
									])
								}
								itemContainerStyle={{ maxHeight: '200px' }}
							/>
							<div className="d-flex flex-row flex-wrap" style={{ gap: '5px' }}>
								{selectedAssessSpecs.map((_assessSpec, index) => {
									return (
										<div
											className="d-flex align-items-center"
											key={`spec_${index}`}
											style={{
												background: '#392D69',
												padding: '2px 6px',
												borderRadius: '2px',
												fontSize: '12px',
												gap: '5px',
												color: '#FFF',
											}}
										>
											{/* <span style={{ background: '#e1e1e1', padding: '0 5px', borderRadius: '2px', }}>{_assessSpec.name}</span> */}
											<span>{_assessSpec.name}</span>
											<span
												className="x-cross-icon icon-white pointer"
												style={{ height: '10px', width: '10px' }}
												onClick={() => {
													setSelectedAssessSpecs((preState) => {
														return preState.filter(
															(spec) => spec.id !== _assessSpec.id
														);
													});
												}}
											/>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</div>
		</CustomModal>
	);
}
