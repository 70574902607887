import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import { Switch } from 'react-router-dom/cjs/react-router-dom';
import { PathName } from '../utils/routes';

export default function Settings(props) {

	const { path } = useRouteMatch();
	const { routeDetails } = props;
	const auth = useSelector((state) => state.authReducer);

	return (

		// <Redirect to={`${path}${PathName.COMPANY_SETTINGS}`} />
		<Switch>
			{
				Object.values(routeDetails.nested_routes).map((route, i) => {
					if (route.required_roles.some(item => auth.data.role.name === item)) {
						return (
							<Route exact={route.exact} path={`${routeDetails.pathname}${route.pathname}`} key={`${route.pathname}_${i}`}>
								<route.component routeDetails={route} />
							</Route>
						);

					}
				})

			}
		</Switch>

	);

}