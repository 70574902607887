import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as MoreIcon } from '../../../../../../assets/icons/Views/dashboard/assessments/more-vertical.svg';
import Input from '../../../../../sub-component/Input';
import Select from '../../../../../sub-component/Select';
import CustomSwitch from '../../../../../sub-component/Switch';
import { useMediaQuery } from '@mui/material';

export default function QuestionContainer(props) {
	const isOnWebScreen = useMediaQuery('(min-width:1280px)');
	const { index } = props;
	const questionDuration = [
		{ name: '1 min', value: 60 },
		{ name: '2 min', value: 120 },
		{ name: '5 min', value: 300 },
		{ name: '10 min', value: 600 },
	];
	const questionType = [
		{ name: 'Create Custom Question', value: 'create_custom_question' },
		{ name: 'Add Pre-defined Question', value: 'add_question' },
	];

	const VideoModeTypes = [
		{ name: 'Record with camera', value: 'native' },
		{ name: 'Record with loom', value: 'loom' },
		{ name: 'Let candidate choose camera or loom', value: 'open' },
	];

	const [openMenu, setOpenMenu] = useState(false);
	const [questions, setQuestions] = useState(props?.questions);

	useMemo(() => props.getQuestions(questions), [questions]);

	const changeIsMandatory = (index) => {
		const updatedQuestions = questions.map((question, i) => {
			if (i == index) {
				return {
					...question,
					is_mandatory: !question.is_mandatory
				};
			}
			return question;
		});
		setQuestions(updatedQuestions);
		props.getUpdatedData(updatedQuestions);
	};

	useEffect(() => {
		setQuestions(props?.questions);
	}, [props?.questions]);





	return (
		<div className='w-100 d-flex flex-column'>
			<div className='text-response-question-container'>
				<div className='w-100 h-100 d-flex flex-row align-items-center justify-content-between'>
					<div className='h-100 d-flex flex-row align-items-start justify-content-center' style={{ gap: '12px' }}>
						<span className='body-1 dark-50 text start' style={{ width: '130px' }}>{`Question ${index + 1}:`}</span>
						<CustomSwitch
							label={'Mark Mandatory'}
							checked={questions[index].is_mandatory}
							onChange={() => changeIsMandatory(index)}
						/>
					</div>
					<div className='text-response-question-container-menu'>
						<MoreIcon
							className='pointer'
							style={{ transform: 'rotate(90deg)' }}
							onClick={() => {
								setOpenMenu(!openMenu);
							}}
						/>
						{openMenu && (
							<div className='container-menu'>
								{questions?.length < props.limit &&
									<div
										onClick={() => {
											const _questions = questions;
											_questions.push(questions[index]);
											setQuestions([...questions]);
											setOpenMenu(false);
										}}
									>
										<span
											className='body-3 black-700'
											style={{ fontWeight: '500' }}
										>
											Duplicate{' '}
										</span>
									</div>
								}
								<div
									onClick={() => {
										const _questions = questions;
										_questions.splice(index, 1);
										setQuestions([..._questions]);
										props.getUpdatedData([..._questions]);
										setOpenMenu(false);
										props.onCheckSectionDataConfermation();
									}}
								>
									<span
										className='body-3 black-700'
										style={{ fontWeight: '500' }}
									>
										Delete
									</span>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='text-response-question-upper-container align-items-start'>
					<div className='d-flex flex-column' style={{ width: isOnWebScreen ? props.type === 'video_test' ? 'calc(50% - 16px)' : 'calc(50% - 16px)' : '100%' }}>
						<Select
							readOnly
							options={questionType}
							style={{ width: '100%' }}
							className='question-select-responsive select-ideal-answer'
							selected={
								questions.length > 0 ? questions[index]?.question_type : ''
							}
							title={'Question type'}
							placeholder='Create Custom Question'
							onSelection={(option) => {
								const _questions = questions;
								if (index <= (questions.length - 1)) {
									_questions[index] = {
										..._questions[index],
										question: option.value !== 'add_question' ? _questions[index].question : '',
										question_type: option.value,
										qp_id: option.value !== 'add_question' ? null : _questions[index].question.qp_id,

									};
								} else {
									_questions.push({
										question_type: option.value,
										question: '',
										duration: 0,
										recording_type: null,
									});
								}
								setQuestions([..._questions]);
								props.getUpdatedData([..._questions]);
								props.onCheckSectionDataConfermation();
							}}
						/>
					</div>
					<div className='d-flex flex-column' style={{ width: '100%', maxWidth: isOnWebScreen ? props.type === 'video_test' ? 'calc(50% - 16px)' : 'calc(50% - 16px)' : '100%' }}>
						<Select
							readOnly
							options={questionDuration}
							className='question-select-responsive select-ideal-answer'
							style={{ width: '100%' }}
							// disabled={questions[index].question_type === questionType[1].value}
							disabled={questions.length > 0 && questions[index]?.recording_type === 'loom'}
							toolTip={(questions.length > 0 && questions[index]?.recording_type === 'loom') ? `You have only 5 minutes for "Record with loom"` : ''}
							selected={questions.length > 0 ? questions[index]?.duration : ''}
							placeholder='Time to answer this question'
							innerClassName={
								props?.error && props?.error?.duration_required
									? 'primary-input-error'
									: ''
							}
							title={'Time to answer this question'}
							onSelection={(option) => {
								const _questions = questions;
								if (index <= questions.length) {
									_questions[index] = {
										..._questions[index],
										duration: questions[index]?.recording_type === 'loom' ? 300 : option.value
									};
								} else {
									_questions.push({
										question_type: '',
										question: '',
										duration: option.value,
										recording_type: null,
									});
								}
								setQuestions([..._questions]);
								props.getUpdatedData([..._questions]);
								props.onCheckSectionDataConfermation();
							}}
						/>
						{props?.error && props?.error?.duration_required &&
							<span className='body-3 danger-text text-start' style={{ paddingLeft: '24px' }}>Select time to fix the issue</span>
						}
					</div>
					{
						props.type === 'video_test' &&
						<div className='d-flex flex-column' style={{ width: '100%', maxWidth: isOnWebScreen ? 'calc(50% - 16px)' : '100%' }}>
							<Select
								readOnly
								options={VideoModeTypes}
								style={{ widtth: '100%' }}
								className='question-select-responsive select-ideal-answer'
								selected={questions.length > 0 ? questions[index]?.recording_type : ''}
								placeholder='Select Video Mode'
								innerClassName={
									props?.error && props?.error?.video_mode_required
										? 'primary-input-error'
										: ''
								}
								title={'Select Video Mode'}
								onSelection={(option) => {
									const _questions = questions;
									if (index <= questions.length) {
										_questions[index] = {
											..._questions[index],
											recording_type: option.value,
											duration: option.value === 'loom' ? 300 : _questions[index]?.duration || 0
										};
									} else {
										_questions.push({
											question_type: '',
											question: '',
											recording_type: option.value,
											duration: option.value === 'loom' ? 300 : 0
										});
									}
									setQuestions([..._questions]);
									props.getUpdatedData([..._questions]);
									props.onCheckSectionDataConfermation();
								}}
							/>
							{
								props?.error && props?.error?.video_mode_required &&
								<span className='body-3 danger-text text-start' style={{ paddingLeft: '24px' }}>Select Video Mode to fix the issue</span>
							}
						</div>
					}
				</div>
				<div className='d-flex flex-column' style={{ width: '100%' }}>
					{questions[index].question_type !== 'add_question' ? (
						<Input
							varrient={'primary'}
							placeholder='Text here *'
							className={
								props?.error && props?.error?.question_required
									? 'primary-input-error'
									: ''
							}
							title={'Create question'}
							value={questions[index].question || ''}
							onChange={(e) => {
								const _questions = questions;
								if (index <= questions.length) {
									_questions[index] = {
										..._questions[index],
										question: e.target.value,
									};
								} else {
									_questions.push({
										question_type: '',
										question: e.target.value,
										duration: 0,
										recording_type: null,
									});
								}
								setQuestions([..._questions]);
								props.onCheckSectionDataConfermation();
							}}
							onBlur={() => { props.getUpdatedData([...questions]); }}
						/>
					) : (
						<Select
							readOnly={true}
							// options={[{ name: 'List of questions from Database', value: 0 }]}
							options={
								props.quesPrompts?.map((quesPrompt, index) => ({
									name: quesPrompt.text,
									value: index,
								})) || []
							}
							className='question-select-responsive select-ideal-answer'
							innerClassName={
								props?.error && props?.error?.question_required
									? 'primary-input-error'
									: ''
							}
							title={'Create question'}
							style={{ widtth: '100%' }}
							innerStyle={{ maxWidth: 'none' }}
							selected={questions.length > 0 ? questions[index]?.question : ''}
							placeholder='Select Question *'
							onSelection={(option) => {
								const _questions = questions;
								if (index <= questions.length) {
									_questions[index] = {
										..._questions[index],
										question: props.quesPrompts[option.value]?.text || '',
										duration: (props.type === 'video_test' && questions[index]?.recording_type === 'loom') ? 300 : props.quesPrompts[option.value]?.duration || 0,
										qp_id: props.quesPrompts[option.value]?.id || null,
									};
								} else {
									_questions.push({
										question_type: '',
										question: option.value,
										duration: 0,
										recording_type: null,
									});
								}
								setQuestions([..._questions]);
								props.getUpdatedData([..._questions]);
								props.onCheckSectionDataConfermation();
							}}
						/>
					)}
					{props?.error && props?.error?.question_required &&
						<span className='body-3 danger-text text-start' style={{ paddingLeft: '24px' }}>{questions[index].question_type !== 'add_question' ? 'Add some text here to fix the issue' : 'Select question option from the dropdown to fix the issue'}</span>
					}
				</div>
			</div >

		</div >
	);
}
