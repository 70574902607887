import { useMediaQuery } from '@mui/material';
import React from 'react';
import { ReactComponent as TimerIcon } from '../../../../../assets/icons/Views/dashboard/assessments/clock.svg';
import { ReactComponent as WarningIcon } from '../../../../../assets/icons/Views/dashboard/assessments/alert-circle.svg';
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/Views/dashboard/assessments/trash.svg';
import Button from '../../../../sub-component/Button';
import Select from '../../../../sub-component/Select';


export default function SectionContainer(props) {
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	return (
		<div
			className={`section-container`}
			style={{ border: (props?.hasError || props?.error) ? '1px solid red' : '' }}
		>
			{(props.customizedDuration && isOnMobileScreen) && (
				<>
					<Select
						readOnly={true}
						options={props?.durations || []}
						className='question-select-responsive select-ideal-answer'
						style={{ width: '200px', marginBottom: '8px' }}
						inputStyle={{background: '#FFFFFF'}}
						innerStyle={{ maxWidth: 'none' }}
						selected={props.selectedDuration}
						placeholder='Select time duration *'
						onSelection={(option) => {
							props.setDuration(option.value);
						}}
						innerClassName={
							props.error?.duration_required && ' primary-input-error '
						}
					/>
				</>
			)}
			<div className='d-flex justify-content-between align-items-center w-100'>
				<div className='d-flex flex-row align-items-center justify-content-center'>
					{/* {(props?.hasError || props?.error) &&
						<WarningIcon style={{ width: '24px', height: '24px', marginRight: '12px' }} />
					} */}
					<span className='subtitle-1 dark-100'>{props.title}</span>
				</div>
				<div className={`d-flex align-items-center`}>
					{props.enableDuration && (
						<>
							<TimerIcon className='svg' style={{ width: '16px', height: '16px', marginRight: '8px' }} />
							<span
								className='body-4 black-500'
								style={{ marginRight: '17px' }}
							>{`${props.duration || 0} mins`}</span>
						</>
					)}

					{(props.customizedDuration && !isOnMobileScreen) && (
						<>
							{/* <TimerIcon className='svg' style={{ width: '16px', height: '16px', marginRight: '8px' }} /> */}
							<Select
								readOnly={true}
								options={props?.durations || []}
								className='question-select-responsive select-ideal-answer'
								style={{ width: '100%', marginRight: '17px' }}
								inputStyle={{background: '#FFFFFF'}}
								innerStyle={{ maxWidth: 'none' }}
								selected={props.selectedDuration}
								placeholder='Select time duration *'
								onSelection={(option) => {
									props.setDuration(option.value);
								}}
								innerClassName={
									props.error?.duration_required && ' primary-input-error '
								}
							/>
						</>
					)}

					{/* <XIcon className="svg pointer" onClick={() => { props.onRemoveSection(); }} /> */}
					<TrashIcon
						className='svg pointer'
						style={{ width: '20px', height: '20px', cursor: 'pointer' }}
						onClick={() => {
							props.onRemoveSection();
						}}
					/>
				</div>
			</div>


			<div className="d-flex justify-content-start mt-2 mb-2" style={{ width: '100%'}}>
				<span className='body-2 dark-50 text-start'>
					{props.description}
				</span>
			</div>

			{props.children}
			{/* <div className='section-data-confermation'>
				{/* <Button
					btn="ragular-btn"
					varrient="secondary-btn"
					title={'Cancel'}
					onClick={() => { props.onCancel(); }}
					style={{ width: '120px', marginRight: '20px' }} /> 

				{
					props.hideScndryBtn &&
					<span
						className='button-medium-text pointer'
						onClick={() => {
							props.onCancel();
						}}
						style={{ color: '#392D69', marginRight: '43.5px' }}
					>
						Cancel
					</span>

				}

				<Button
					btn='ragular-btn'
					varrient='primary'
					title={'Confirm'}
					style={{ width: '80px', borderRadius: '4px' }}
					onClick={() => {
						props.onConfirm();
					}}
					processing={props.processing}
					disabled={props.disabled}
				/>
			</div> */}
		</div>
	);
}
