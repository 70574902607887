import { createSlice } from '@reduxjs/toolkit';
import { getTenant } from '../thunks/Tenant';

const Tenant = createSlice({
	name: 'Tenant',
	initialState: {
		processing: false,
		data: [],
		curr_tenant: null,
		error_message: null,
		success_message: null,
	},

	reducers: {},

	extraReducers: {

		[getTenant.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[getTenant.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				curr_tenant: payload,
			};
		},
		[getTenant.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},
	},
});

export default Tenant.reducer;