import React from 'react';
import { ReactComponent as ArrowDrownIcon } from '../../../../assets/icons/components/code-resposnse-result/down.svg';

export const ExecutedSubmissionExpandedView = (props) => {

	const { submission, token, submissionIndex } = props;
	if (submission) {
		// const token = currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === 'test'); })[0].tokens[selectedSubmission];
		let results = `(case ${submissionIndex + 1}):\n`;

		if (submission?.message) {
			results += `${atob(
				submission?.message,
			)}\n --- \n`;
		}

		if (submission?.stderr) {
			results += `${atob(submission?.stderr)}\n`;
		}

		if (token.input_args) {
			results += `Input parameters -> ${atob(
				token.input_args,
			)}\n`;
		}

		if (token.expected_output) {
			results += `Expected output -> ${atob(
				token.expected_output,
			)}\n`;
		}

		if (submission?.stdout) {
			results += `Standard output -> ${atob(
				submission?.stdout,
			)}\n`;
		}

		if (submission?.compile_output && submission?.status.id != 3) {
			results += `Compile output -> ${atob(
				submission?.compile_output,
			)}\n`;
		}

		if (submission?.function_return) {
			results += `Output -> ${atob(
				submission?.function_return,
			)}\n`;
		}

		return (
			<div
				className='executed-task-expanded'
				style={{
					textAlign: 'start',
					borderBottom: '1px solid #FFF',
					color: submission?.has_passed
						? '#00D69A'
						: '#FC4848',
				}}
			>
				<pre>{results}</pre>
			</div>
		);
	}
};

export const ExecutedTask = (props) => {

	const { submission, isLastIndex } = props;
	return (
		<>
			<div className='pointer executed-task' style={isLastIndex ? { border: 'none' } : {}} onClick={props.onClick}>
				<span className='body-4 black-700'>{submission?.has_passed ? 'Test case successful' : 'Test case failed'}</span>
				<div className='d-flex align-items-center'>
					<div className={`${submission?.has_passed ? 'OK' : 'KO'}`}>
						<span className='body-4' style={{ color: '#FFFFFF' }}>{submission?.has_passed ? 'OK' : 'KO'}</span>
					</div>
					<ArrowDrownIcon style={{ marginLeft: '14px' }} />
				</div>
			</div>
		</>
	);
};
