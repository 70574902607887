import React from 'react';

const Checkbox = (props) => {

	if (props.primary) {
		return (
			<div className="primary-checkbox" onClick={props.onClick}>
				<div className='primary-checkbox-container'>
					<input type="checkbox"
						readOnly={true}
						checked={props.checked}
						onChange={props.onChange}
						id={props.id}
						style={{ ...props.style }}
						disabled={props.disabled || props.processing}
						value={props.value}
						className={`${props.className} primary-checkbox-input pointer`} />
				</div>
				<span className='primary-checkbox-title ms-2 body-3' style={props.titleStyle}>
					{props.title}
				</span>
			</div>

		);
	}
	if (props.primary_light) {
		return (
			<div className="primary-light-checkbox " onClick={props.onClick}>
				<div className='primary-light-checkbox-container'>
					<input type="checkbox"
						readOnly={true}
						checked={props.checked}
						onChange={props.onChange}
						id={props.id}
						style={{ ...props.style }}
						disabled={props.disabled || props.processing}
						value={props.value}
						className={`${props.className} primary-light-checkbox-input pointer`} />
				</div>
				<span className='primary-light-checkbox-title ms-2 description' style={props.titleStyle}>
					{props.title}
				</span>

			</div>
		);
	}
};

export default Checkbox;