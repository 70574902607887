import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const listTestPacks = createAsyncThunk(
	'testPack/listTestPacks',
	async (_, { rejectWithValue, dispatch }) => {

		try {

			const skillTestPack = await secure_instance.request({
				url: '/v1/test_packs/?test_pack_type=skill_test_pack',
				method: 'GET',
			});

			const condingTestPack = await secure_instance.request({
				url: '/v1/test_packs/?test_pack_type=coding_test_pack',
				method: 'GET',
			});

			const valueFitnessTestPack = await secure_instance.request({
				url: '/v1/test_packs/?test_pack_type=value_fitness_test_pack',
				method: 'GET',
			});

			return {
				skill_test_packs: skillTestPack.data,
				coding_test_packs: condingTestPack.data,
				value_fitness_test_packs: valueFitnessTestPack.data
			};

		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	}
);

// export const listCodingTestPacks = createAsyncThunk(
// 	'testPack/listTestPacks',
// 	async (_, { rejectWithValue }) => {

// 		try {

// const request = await secure_instance.request({
// 	url: '/v1/test_packs/?test_pack_type=coding_test_pack',
// 	method: 'GET',
// });

// 			return request.data;

// 		} catch (error) {
// 			return rejectWithValue(error?.message);
// 		}

// 	}
// );

// export const listValueFitnessTestPacks = createAsyncThunk(
// 	'testPack/listTestPacks',
// 	async (_, { rejectWithValue }) => {

// 		try {

// 			const request = await secure_instance.request({
// 				url: '/v1/test_packs/?test_pack_type=value_fitness_test_pack',
// 				method: 'GET',
// 			});

// 			return request.data;

// 		} catch (error) {
// 			return rejectWithValue(error?.message);
// 		}

// 	}
// );