import React, { useState, useEffect, useMemo } from 'react';
import Button from '../../../components/sub-component/Button';
import { ReactComponent as SearchIcon } from '../../../assets/icons/components/common/table/search.svg';
import { ReactComponent as PreIcon } from '../../../assets/icons/Views/candidate/assessments/arrow-down.svg';
import { ReactComponent as PostIcon } from '../../../assets/icons/Views/candidate/assessments/arrow-up.svg';
import { Checkbox } from '@mui/material';
import AssessmentCard from './AssessmentCard';
import { useDispatch, useSelector } from 'react-redux';
import { createTest, getAssessment, listAssessments, patchAssessment } from '../../../redux/thunks/Assessment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PathName } from '../../../utils/routes';
import CandidateAssessmentRightSideMenu from './candidate-assessment-right-side-menu/CandidateAssessmentRightSideMenu';
import NavBar from '../../../components/common/NavBar';
import CustomModal from '../../../components/common/Modal';
import { getAssessSpec } from '../../../redux/thunks/AssessSpec';
import { useMediaQuery } from '@mui/material';
import { calculateExpiry } from '../../../utils/utilities';


export default function CandidateAssessments(props) {
	const isOnWebScreen = useMediaQuery('(min-width:1340px)');
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1339px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const [isOnMobileScreenModal, setIsOnMobileScreenModal] = useState(false);
	// const [assessmentForAction, setAssessmentForAction] = useState(null);
	const [selectedAssessment, setSelectedAssessment] = useState(null);
	const [declineAssessmentModal, setDeclineAssessmentModal] = useState({
		isOpen: false
	});
	const [assessmentResultModal, setAssessmentResultModal] = useState({
		isOpen: false
	});
	const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
	const [filters, setFilters] = useState({});

	// const [processing, setProcessing] = useState(false);

	const { path } = useRouteMatch();
	const history = useHistory();

	const dispatch = useDispatch();
	const assessments = useSelector((state) => state.assessmentReducer);

	const navOptions = [
		{ name: 'Pending', value: 'PENDING' },
		{ name: 'In Progress', value: 'IN_PROGRESS' },
		{ name: 'Completed', value: 'FINISHED' },
		{ name: 'Declined', value: 'DECLINED' },
		{ name: 'Not Qualified', value: 'NOT_QUALIFIED' },
		{ name: 'Expired', value: 'EXPIRED' },
	];
	const [selectedNavLink, setSelectedNavLink] = useState('all');
	const [testData, setTestData] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');

	useEffect(() => {
		if (assessments?.data?.result) {
			let filteredData = assessments?.data?.result;
			if (filters.type && filters.type !== '') {
				if (filters.type === 'expiry') {
					if (filters.option === 'asc') {
						filteredData = [...filteredData].sort((a, b) => {
							return new Date(calculateExpiry(a.deadline, a.created_at, true)) - new Date(calculateExpiry(b.deadline || 0, b.created_at, true));
						});
					} else if (filters.option === 'dsc') {
						filteredData = [...filteredData].sort((a, b) => {
							return new Date(calculateExpiry(b.deadline, b.created_at, true)) - new Date(calculateExpiry(a.deadline || 0, a.created_at, true));
						});
					}
				} else if (filters.type === 'invite') {
					if (filters.option === 'asc') {
						filteredData = [...filteredData].sort((a, b) => {
							return new Date(a.created_at) - new Date(b.created_at);
						});
					} else if (filters.option === 'dsc') {
						filteredData = [...filteredData].sort((a, b) => {
							return new Date(b.created_at) - new Date(a.created_at);
						});
					}
				}
			}
			filteredData = filteredData?.filter((card) => {
				if (
					(!filters.status || card.status === filters.status) &&
					(!filters.title || card.title.toString().toLowerCase().includes(filters.title)) &&
					(!filters.companyTags?.length || filters?.companyTags?.includes(card.tenant?.company_name.toString().toLowerCase()))
				) {
					return true;
				}
				return false;
			});
			filteredData = filteredData.filter((card) =>
				card.tenant?.company_name.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
				card.status.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
				card.title.toString().toLowerCase().includes(searchQuery.toLowerCase())
			);
			setTestData(filteredData);
			setSelectedAssessment(filteredData[0]);
		}
	}, [searchQuery, filters]);

	// useEffect(() => {
	// 	console.log(navigator.vendor, 'Set Set');
	// }, []);

	useEffect(() => {
		dispatch(listAssessments());
	}, []);

	// useEffect(() => {
	// 	// console.log(assessments, 'assessments');
	// 	if (selectedAssessment === null && assessments?.data?.length > 0) {

	// 		setSelectedAssessment(assessments?.data[0]);
	// 	}
	// }, [selectedAssessment, assessments]);


	useMemo(() => {
		if (assessments?.data === null) return;
		setTestData(assessments.data.result);
	}, [assessments?.data]);

	// useMemo(() => {
	// 	if (assessments?.data === null) return;
	// 	const _navOptions = [];
	// 	assessments?.data?.length > 0 && navOptions.map((navOp) => {
	// 		if (navOp?.value === 'all') {
	// 			return (
	// 				_navOptions.push({ ...navOp, count: assessments?.data?.length || 0 })
	// 			);
	// 		}
	// 		else {
	// 			return (
	// 				_navOptions.push({ ...navOp, count: assessments?.data?.filter((_assess) => { return (_assess.status === navOp?.value?.toUpperCase()); }).length || 0 })
	// 			);
	// 		}
	// 	});
	// 	setNavOptions(_navOptions.length > 0 ? [..._navOptions] : [...navOptions]);

	// }, [assessments?.data]);

	const enterFullScreen = () => {
		const element = document.body;
		if (element.requestFullscreen) {
			element.requestFullscreen();
		} else if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
		} else if (element.webkitRequestFullscreen) {
			element.webkitRequestFullscreen();
		}
	};

	const startAssessment = ({ id, status }) => {
		if (status === 'FINISHED') {
			console.log();
		} else {
			history.push(`${path}/${id}`);
			enterFullScreen();
		}
	};

	const handleDeclineAssessment = async () => {
		const dispatched = await dispatch(patchAssessment({
			id: selectedAssessment?.id,
			data: { status: 'DECLINED' }
		}));

		if (!dispatched.error) {
			await dispatch(listAssessments());
		}
		setDeclineAssessmentModal({ isOpen: false });

	};

	useEffect(() => {
		const modalShown = sessionStorage.getItem('modalShown');
		if (!modalShown && (isOnTabletScreen || isOnMobileScreen)) {
			setIsOnMobileScreenModal(true);
			sessionStorage.setItem('modalShown', true);
		}
	}, [isOnMobileScreen, isOnTabletScreen]);

	if (assessments.loading || assessments?.data?.result === null) {
		return (
			<div className='processing-indicator-wrapper medium-indicator'>
				<div
					className='processing-indicator primary'
					style={{ width: '40px', height: '40px' }}
				/>
			</div>
		);
	}
	return (
		<>
			{
				<CustomModal
					open={declineAssessmentModal.isOpen}
					varrient='alert-warning-modal'
					title={'Decline Assessment?'}
					description={'Are you sure, that you want to decline this assessment?'}
					dangerAlert={true}
					warningText={'Warning: If you decline the assessment you will not be able to attempt it again.'}
					handleCloseModal={() => { setDeclineAssessmentModal({ isOpen: false }); }}
					confirmBtnTitle={'Decline'}
					onConfirm={handleDeclineAssessment}
				/>
			}

			<CustomModal
				hideScendBtn
				open={isOnMobileScreenModal}
				varrient='alert-warning-modal'
				title={'Attention!'}
				description={'We strongly recommend you to continue this assessment on a desktop device for the best experience.'}
				warningAlert={true}
				confirmBtnTitle={'Close'}
				onConfirm={() => {
					setIsOnMobileScreenModal(false);
				}}
			/>
			{isOnWebScreen ?
				<div className='candidate-view-with-sidebar'>
					<div className={`candidate-left-sidebar-container hide-scrollbar ${isRightMenuOpen && 'candidate-left-sidebar-open'}`}>
						<div className={`candidate-assessment-detail-container`}>
							<div className='candidate-assessment-header'>
								<span className='headline-3 dark-100 text-start'>{`Assessments`}</span>
							</div>
							<div className='candidate-assessment'>
								<NavBar navLinks={navOptions} setSearchQuery={setSearchQuery}
									setFilters={setFilters}
									filters={filters}
									onNavSelect={(value) => { setSelectedNavLink(value); }}
									selectedNav={selectedNavLink} />
								<div className='assessment-cards hide-scrollbar'>
									{(testData === null || !testData?.length) &&
										<div className='w-100 d-flex flex-column align-items center justify-content-center' style={{ height: '190px', background: '#fff', gap: '12px' }}>
											<span className='headline-medium medium'>No assessments to show!</span>
											<span className='body-2 medium black-400'>Try Adjusting your filters.</span>
										</div>
									}
									{
										(testData && selectedNavLink === 'all' ? testData : testData.filter((_test) => { return (_test?.status?.toLowerCase() === selectedNavLink?.toLowerCase()); }))?.map((data, index) => {

											return (
												<AssessmentCard
													key={index}
													data={data}
													isRightMenuOpen={isRightMenuOpen}
													onStart={startAssessment}
													onClick={async () => {
														setSelectedAssessment(data);
														setIsRightMenuOpen(true);
														if (data && data?.status === 'FINISHED') {
															await dispatch(getAssessment({ id: data?.id }));
														}
													}}
													onAssessmentDecline={(_assessment) => { setSelectedAssessment(_assessment); setDeclineAssessmentModal({ isOpen: true }); }}
													selectedAssessment={selectedAssessment}
												/>
											);
										})
									}
								</div>
							</div>
						</div>
					</div>
					{isRightMenuOpen &&
						<div className={`candidate-right-sidebar-container hide-scrollbar candidate-right-sidebar-open `}>
							<CandidateAssessmentRightSideMenu
								onStart={startAssessment}
								selectedAssessment={selectedAssessment}
								selectedAssessmentIndex={testData?.findIndex((assess) => assess?.id === selectedAssessment?.id)}
								assessmentsLength={testData?.length}
								setSelectedAssessment={(index) => setSelectedAssessment(testData[index])}
								onAssessmentDecline={(_assessment) => { setSelectedAssessment(_assessment); setDeclineAssessmentModal({ isOpen: true }); }}
								handleClose={() => { setIsRightMenuOpen(false), setSelectedAssessment(null); }}
							/>
						</div>
					}
				</div>
				:
				<div className='candidate-view-with-sidebar'>
					{isRightMenuOpen ?
						<div className={`candidate-right-sidebar-container hide-scrollbar candidate-right-sidebar-open `}>
							<CandidateAssessmentRightSideMenu
								onStart={startAssessment}
								selectedAssessment={selectedAssessment}
								selectedAssessmentIndex={testData?.findIndex((assess) => assess?.id === selectedAssessment?.id)}
								assessmentsLength={testData?.length}
								setSelectedAssessment={(index) => setSelectedAssessment(testData[index])}
								onAssessmentDecline={(_assessment) => { setSelectedAssessment(_assessment); setDeclineAssessmentModal({ isOpen: true }); }}
								handleClose={() => { setIsRightMenuOpen(false), setSelectedAssessment(null); }}
							/>
						</div>
						:
						<div className={`candidate-left-sidebar-container hide-scrollbar`}>
							<div className={`candidate-assessment-detail-container`}>
								<div className='candidate-assessment-header'>
									<span className='headline-3 dark-100 text-start'>{`Assessments`}</span>
								</div>
								<div className='candidate-assessment'>
									<NavBar navLinks={navOptions} setSearchQuery={setSearchQuery}
										setFilters={setFilters}
										filters={filters}
										onNavSelect={(value) => { setSelectedNavLink(value); }}
										selectedNav={selectedNavLink} />
									<div className='assessment-cards hide-scrollbar'>
										{(testData === null || !testData?.length) &&
											<div className='w-100 d-flex flex-column align-items center justify-content-center' style={{ height: '190px', background: '#fff', gap: '12px' }}>
												<span className='headline-medium medium'>No assessments to show!</span>
												<span className='body-2 medium black-400'>Try Adjusting your filters.</span>
											</div>
										}
										{
											(testData && selectedNavLink === 'all' ? testData : testData.filter((_test) => { return (_test?.status?.toLowerCase() === selectedNavLink?.toLowerCase()); }))?.map((data, index) => {
												return (
													<AssessmentCard
														key={index}
														data={data}
														isRightMenuOpen={isRightMenuOpen}
														onStart={startAssessment}
														onClick={async () => {
															setSelectedAssessment(data);
															setIsRightMenuOpen(true);
															if (data && data?.status === 'FINISHED') {
																await dispatch(getAssessment({ id: data?.id }));
															}
															if (!isOnWebScreen) {
																setAssessmentResultModal({ isOpen: true });
															}
														}}
														onAssessmentDecline={(_assessment) => { setSelectedAssessment(_assessment); setDeclineAssessmentModal({ isOpen: true }); }}
														selectedAssessment={selectedAssessment}
													/>
												);
											})
										}
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			}
		</>
	);
}
