import { width } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ClockIcon } from '../../assets/icons/candidate-assessment/clock-b.svg';
import utilities from '../../utils/utilities';

export default function OnTestStartCard(props) {
	const { varrient, testStartsInDuration, testDuration } = props;

	const [startsIn, setStartsIn] = useState(testStartsInDuration);

	if (startsIn > 0) {
		setTimeout(() => {
			setStartsIn(startsIn - 1);
		}, 1000);
	}

	if (startsIn === 0) {
		setStartsIn(-1);
		if (props.onWaitComplete) props.onWaitComplete();
	}

	return (
		<div className='on-test-start-container'>
			<span className='headline-4 dark-100'>
				{
					(() => {
						return utilities.toTitleCase(varrient.replace('_', ' '));
					})()
				}
			</span>
			<ClockIcon />
			<span className='body-2 dark-50'>Test will start in: <span className='dark-100'>{startsIn} seconds</span></span>
			<span className='body-2 dark-50'>
				Please stay on this screen. The timer for your skill-based test has started and can not be paused.
				You must complete the test in the given time. Good Luck!
			</span>
		</div>
	);
}
