import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCodelanguage } from '../../../../redux/thunks/Assessment';
import InputRange from 'react-input-range';
import { ReactComponent as FastForwardIcon } from '../../../../assets/icons/components/code-resposnse-result/fast-forward.svg';
import { ReactComponent as PlayIcon } from '../../../../assets/icons/components/code-resposnse-result/Frame 2608418.svg';
import { ReactComponent as PauseIcon } from '../../../../assets/icons/components/code-resposnse-result/Frame 2609002.svg';
import { ReactComponent as RewindIcon } from '../../../../assets/icons/components/code-resposnse-result/rewind.svg';
import { ReactComponent as SkipBackIcon } from '../../../../assets/icons/components/code-resposnse-result/skip-back.svg';
import { ReactComponent as SkipForwardIcon } from '../../../../assets/icons/components/code-resposnse-result/skip-forward.svg';

import { ReactComponent as PasteLabelIcon } from '../../../../assets/icons/components/code-resposnse-result/paste-label.svg';
import { ReactComponent as FailLabelIcon } from '../../../../assets/icons/components/code-resposnse-result/Group 13791.svg';
import { ReactComponent as PassLabelIcon } from '../../../../assets/icons/components/code-resposnse-result/Group 13792.svg';
import { ReactComponent as LabelPointer } from '../../../../assets/icons/components/code-resposnse-result/Ellipse 293.svg';
import { ReactComponent as EndResultIcon } from '../../../../assets/icons/components/code-resposnse-result/endresult.svg';

import { secondsToDhms } from '../../../../utils/utilities';

export default function CodingTestTimeLinePlayer(props) {

	const { snapshots, response } = props;
	const [codeLanguage, setCodeLanguage] = useState();
	const [codeValue, setCodeValue] = useState('');
	const [rangeValue, setRangeValue] = useState(0);

	const [startTimeLinePlayer, setStartTimeLinePlayer] = useState(false);
	const dispatch = useDispatch();
	const [timmer, setTimmer] = useState(null);

	useEffect(() => {
		// console.log(resource, 'resource');
		if (!snapshots || snapshots?.length <= 0) {
			return;
		}


		// console.log(snapshots, 'codeSnapshots');

		const interval__of_5s_code_value = rangeValue - (rangeValue % 5);
		// console.log(rangeValue, snapshots[(interval__of_5s_code_value / 5)], 'snapshots[(interval__of_5s_code_value / 5)]');
		// console.log(snapshots[], 'copyTextSuspected');

		props?.onChangeCodeSnapshot(snapshots[(interval__of_5s_code_value / 5)]);
		if (codeLanguage !== snapshots[(interval__of_5s_code_value / 5)]?.code_language) {
			dispatch(getCodelanguage(snapshots[(interval__of_5s_code_value / 5)]?.code_language));
			setCodeLanguage(snapshots[(interval__of_5s_code_value / 5)]?.code_language);
		}
		if (codeValue !== snapshots[(interval__of_5s_code_value / 5)]?.source_code) {
			setCodeValue(snapshots[(interval__of_5s_code_value / 5)]?.source_code);
			if (((interval__of_5s_code_value / 5) - 1) >= 0) {
				props.onChangePreviousValue(atob(snapshots[((interval__of_5s_code_value / 5) - 1)]?.source_code));
			}
			props.onChangeCodeValue(atob(snapshots[(interval__of_5s_code_value / 5)]?.source_code));
			props?.onChangeCodeSnapshot(snapshots[(interval__of_5s_code_value / 5)]);

			if (snapshots[(interval__of_5s_code_value / 5)]?.copy_text) {
				// console.log(snapshots[(interval__of_5s_code_value / 5)], 'copyTextSuspected');
				props.getCoppiedText(snapshots[(interval__of_5s_code_value / 5)]?.copy_text);
			}
			else {
				props.getCoppiedText(null);
			}
		}

	}, [codeValue, codeLanguage, rangeValue]);

	useMemo(() => {
		if (startTimeLinePlayer) {
			setTimmer(
				setInterval(() => {
					if (rangeValue < parseInt(snapshots?.length * 5) - 5 && startTimeLinePlayer) {
						setRangeValue(rangeValue => rangeValue + 1);
					}
				}, 100)
			);
		}
		else {
			clearInterval(timmer);
		}

		return () => clearInterval(timmer);

	}, [startTimeLinePlayer]);

	useMemo(() => {

		if (document.getElementsByClassName('input-range__slider')[0]) {
			document.getElementsByClassName('input-range__slider')[0]?.classList?.add('body-5-white-color-500');
			document.getElementsByClassName('input-range__slider')[0].innerHTML = secondsToDhms(rangeValue);
			// secondsToDhms(rangeValue)
		}

		if (rangeValue >= parseInt(snapshots?.length * 5) - 5) {
			setStartTimeLinePlayer(false);
		}
	}, [rangeValue, document.getElementsByClassName('input-range__slider')[0]]);

	useEffect(() => {
		setRangeValue(0);
		setStartTimeLinePlayer(false);
	}, [response]);


	const moveToNextTag = () => {
		const interval__of_5s_code_value = rangeValue - (rangeValue % 5);
		let nextSnapIndex = -1;
		snapshots?.forEach((snapShot, index) => {
			if (index > (interval__of_5s_code_value / 5) && (snapShot.type === 'copypaste' || snapShot.type === 'verify') && nextSnapIndex === -1) {
				nextSnapIndex = index;
			}
		});
		if (nextSnapIndex !== -1) {
			setRangeValue(nextSnapIndex * 5);
		}
	};

	const moveToPreviousTag = () => {
		const interval__of_5s_code_value = rangeValue - (rangeValue % 5);
		let nextSnapIndex = -1;
		snapshots?.forEach((snapShot, index) => {
			if (index < (interval__of_5s_code_value / 5) && (snapShot.type === 'copypaste' || snapShot.type === 'verify')) {
				nextSnapIndex = index;
			}
		});
		if (nextSnapIndex !== -1) {
			setRangeValue(nextSnapIndex * 5);
		}
	};



	return (
		<div className='coding-test-timeline-player'>
			{
				snapshots?.map((snapshot, index) => {
					const isFinalSnapshot = snapshot?.final;
					if (snapshot?.type === 'copypaste') {

						return (
							<div className='timeline-label' key={index} style={{ left: `${((100 / parseInt((snapshots?.length - 1) * 5)) * ((index) * 5))}%` }}>
								<div style={{ position: 'relative', width: '0px', height: '0px' }}>
									<PasteLabelIcon className='pointer' onClick={() => { setRangeValue((index) * 5); }} style={{ position: 'absolute', top: '-55.5px' }} />
								</div>
								<LabelPointer className={`${((index + 1) * 5) < rangeValue ? 'unvisited-label' : 'visited-label'} pointer`}
									onClick={() => { setRangeValue((index) * 5); }}
								/>
							</div>
						);
					}
					else if (snapshot?.type === 'verify') {
						// console.log(snapshots[((rangeValue - (rangeValue % 5)) / 5)]?.exec_results.filter((result, _) => { return (result.exec_type === 'test'); })[0]?.submissions, 'adsasdasdas');
						const score = response?.score ? parseInt((100 / response?.score?.total) * response?.score?.correct) : 0;
						return (
							<div className='timeline-label' key={index} style={{ left: `${((100 / parseInt((snapshots?.length - 1) * 5)) * ((index) * 5)) - ((index === snapshots.length - 1) ? 2 : 0)}%` }}>
								{
									index === snapshots.length - 1
										?
										<>
											<div style={{ position: 'relative', width: '0px', height: '0px' }}>
												<div className='pointer' onClick={() => { setRangeValue((index) * 5); }}>
													<EndResultIcon style={{ position: 'absolute', top: '-45.5px', fill: score <= 50 ? '#FC4848' : score <= 75 ? '#F4C20D' : '#00D69A', opacity: '0.2' }} />
													<div className='align-items-center justify-content-center' style={{ display: 'flex', position: 'absolute', top: '-32.5px', left: '6.9px', width: '25px', height: '12px' }}>
														<span className='body-5 pointer'
															style={{ color: score <= 50 ? '#FC4848' : score <= 75 ? '#F4C20D' : '#00D69A', transform: 'rotate(300deg)', display: 'inline-block', }}>{score}%</span>

													</div>
												</div>
											</div>
										</>
										:
										<>
											{
												snapshot?.exec_results.filter((result, _) => { return (result.exec_type === (isFinalSnapshot ? 'validation' : 'test')); })[0]?.submissions?.filter((submission, _) => { return (!submission.has_passed); }).length > 0
													?
													<div style={{ position: 'relative', width: '0px', height: '0px' }}>
														<FailLabelIcon className='pointer' onClick={() => { setRangeValue((index) * 5); }} style={{ position: 'absolute', top: '-45.5px' }} />
													</div>
													:
													<div style={{ position: 'relative', width: '0px', height: '0px' }}>
														<PassLabelIcon className='pointer' onClick={() => { setRangeValue((index) * 5); }} style={{ position: 'absolute', top: '-70.5px' }} />
													</div>
											}
										</>
								}

								<LabelPointer className={`${((index + 1) * 5) < rangeValue ? 'unvisited-label' : 'visited-label'} pointer`}
									onClick={() => { setRangeValue((index) * 5); }}
								/>
							</div>
						);
					}
				})
			}

			<InputRange
				// formatLabel={value => `${value} kg`}
				maxValue={parseInt((snapshots?.length * 5)) - 5}
				minValue={0}
				value={rangeValue}
				onChange={(value) => {
					if (value <= parseInt(snapshots?.length * 5) - 5) {
						setRangeValue(value);
					}
				}}
			// onChangeComplete={() => { setStartTimeLinePlayer(false); }}
			/>

			<div className='w-100 d-flex justify-content-center align-items-center' style={{ marginTop: '24px', gap: '15px' }}>
				<RewindIcon className='pointer' onClick={() => { setRangeValue(0); }} />
				<SkipBackIcon className='pointer' onClick={moveToPreviousTag} />
				<PlayIcon className='pointer' onClick={() => { setStartTimeLinePlayer(true); }} />
				<PauseIcon className='pointer' onClick={() => { setStartTimeLinePlayer(false); }} />
				<SkipForwardIcon className='pointer' onClick={moveToNextTag} />
				<FastForwardIcon className='pointer' onClick={() => { setRangeValue((snapshots.length - 1) * 5); }} />
			</div>
		</div>
	);
}
