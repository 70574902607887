import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { OSInfo, browserInfo, deviceInfo, deviceType, uuid } from '../../utils/utilities';
import { handleApiError } from '../../api/axios/interceptors';

export const createAssessment = createAsyncThunk(
	'assessment/createAssessment',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			// if (!localStorage.getItem('invite_id')) {
			// 	localStorage.setItem('invite_id', data.invite_id);
			// } else if (localStorage.getItem('invite_id') === data.invite_id) {
			// 	return;
			// }

			const request = await secure_instance.request({
				url: '/v1/assessments/',
				method: 'POST',
				data: {
					invite_id: data.invite_id,
				},
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const listAssessments = createAsyncThunk(
	'assessments/listAssessments',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/assessments/',
				method: 'GET',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const getAssessment = createAsyncThunk(
	'assessments/getAssessment',
	async ({ id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/${id}`,
				method: 'GET',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const createTest = createAsyncThunk(
	'assessments/createTest',
	async ({ assess_id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/test?assess_id=${assess_id}`,
				method: 'POST',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const getNextActiveTest = createAsyncThunk(
	'assessments/getNextActiveTest',
	async ({ assess_id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/test?assess_id=${assess_id}`,
				method: 'GET',
			});

			if (request?.data?.test?.tp_id) {
				dispatch(getTestResultPrev({ tp_id: request?.data?.test?.tp_id }));
			}
			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const getTestResultPrev = createAsyncThunk(
	'assessments/getTestResultPrev',
	async ({ tp_id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/test_results/${tp_id}`,
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const patchTestWithPrevResult = createAsyncThunk(
	'assessments/patchTestWithPrevResult',
	async ({ assess_id, data }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/${assess_id}/reuse_results`,
				method: 'PATCH',
				data: data,
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const patchPublicLinkState = createAsyncThunk(
	'assessments/patchPublicLinkState',
	async ({ assess_id, data }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/${assess_id}/public_link_state`,
				method: 'PATCH',
				data: data,
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const getTest = createAsyncThunk(
	'assessments/getTest',
	async ({ assess_id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/in_progress?assess_id=${assess_id}`,
				method: 'GET',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const createTestQuestion = createAsyncThunk(
	'assessments/createTestQuestion',
	async ({ assess_id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/question?assess_id=${assess_id}`,
				method: 'POST',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const answerTestQuestion = createAsyncThunk(
	'assessments/answerTestQuestion',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/question`,
				method: 'PATCH',
				data: data,
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);
export const patchAssessmentHiringStage = createAsyncThunk(
	'assessments/hiringStage',
	async ({ data }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/hiring_stage`,
				method: 'PATCH',
				data: data,
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);
export const patchAssessment = createAsyncThunk(
	'assessments/patchAssessment',
	async ({ id, data }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/${id}`,
				method: 'PATCH',
				data: data,
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const verifyCodeSnapShot = createAsyncThunk(
	'assessments/verifyCodeSnapShot',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/code_snapshot`,
				method: 'POST',
				data: data,
			});

			if (typeof request?.data.exec_id === 'string' && data.type === 'verify') {
				dispatch(
					executeResult({
						id: request.data.exec_id,
						assess_id: data.assess_id,
					}),
				);
			}

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const saveCodeSnapShot = createAsyncThunk(
	'assessments/saveCodeSnapShot',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/code_snapshot`,
				method: 'POST',
				data: data,
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const executeResult = createAsyncThunk(
	'assessments/executeResult',
	async ({ id, assess_id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/exec_result/${id}?assess_id=${assess_id}`,
				method: 'GET',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const resetExecResultState = createAsyncThunk(
	'assessment/resetExecResultState',
	async (_, { rejectWithValue, dispatch }) => {

		try {

			return _;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const getCodingTestSnapShots = createAsyncThunk(
	'userProfile/getCodingTestSnapShots',
	async ({ assess_id, test_id }, { rejectWithValue, dispatch }) => {
		try {

			const request = await secure_instance.request({
				url: `/v1/assessments/code_report?assess_id=${assess_id}&test_id=${test_id}`,
				method: 'GET',
			});

			return request?.data;

		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	}
);

export const getCodelanguage = createAsyncThunk(
	'assessments/getCodelanguage',
	async (id, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/coding_languages/${id}`,
				method: 'GET',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const createSession = createAsyncThunk(
	'assessments/createSession',
	async ({ assess_id }, { rejectWithValue, dispatch }) => {

		try {

			if (!sessionStorage.getItem('tab_id')) {
				sessionStorage.setItem('tab_id', `${uuid()}`);
			}

			const request = await secure_instance.request({
				url: `/v1/assessments/session`,
				method: 'POST',
				data: {
					'session_data': {
						'os': JSON.stringify(OSInfo(navigator.userAgent)),
						'device': deviceInfo(navigator.userAgent),
						'device_type': deviceType(navigator.userAgent),
						'browser': browserInfo(navigator.userAgent, navigator.vendor),
						'tab_id': sessionStorage.getItem('tab_id')
					},
					'assess_id': assess_id,
					'take_over': false
				}
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const sessionHeartBeat = createAsyncThunk(
	'assessments/sessionHeartBeat',
	async ({ assess_id }, { rejectWithValue, dispatch }) => {

		if (!sessionStorage.getItem('tab_id')) {
			sessionStorage.setItem('tab_id', `${uuid()}`);
		}

		const data = {
			session_data: {
				os: JSON.stringify(OSInfo(navigator.userAgent)),
				device: deviceInfo(navigator.userAgent),
				device_type: deviceType(navigator.userAgent),
				browser: browserInfo(navigator.userAgent, navigator.vendor),
				tab_id: sessionStorage.getItem('tab_id')
			},
			assess_id: assess_id,
		};
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/session`,
				method: 'PATCH',
				data: data
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const takeOverSession = createAsyncThunk(
	'assessments/takeOverSession',
	async ({ assess_id }, { rejectWithValue, dispatch }) => {

		if (!sessionStorage.getItem('tab_id')) {
			sessionStorage.setItem('tab_id', `${uuid()}`);
		}

		const data = {
			session_data: {
				os: JSON.stringify(OSInfo(navigator.userAgent)),
				device: deviceInfo(navigator.userAgent),
				device_type: deviceType(navigator.userAgent),
				browser: browserInfo(navigator.userAgent, navigator.vendor),
				tab_id: sessionStorage.getItem('tab_id')
			},
			assess_id: assess_id,
			take_over: true
		};
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/session`,
				method: 'POST',
				data: data,

			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const resetAssessmentReducerState = createAsyncThunk(
	'assessments/resetAssessmentReducerState',
	async (data, { rejectWithValue, dispatch }) => {

		try {

			return data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);
