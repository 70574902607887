import React, { useEffect, useMemo, useState } from 'react';
import Radio from '../sub-component/Radio';
import { CaseStudyQuestionTypes, TestType } from '../../utils/Constants';
import VideoRecorder from '../candidate-assessment-flow/VideoRecorder';
import { useDispatch, useSelector } from 'react-redux';
import { createResource, deleteResource } from '../../redux/thunks/Resource';
import CustomModal from '../common/Modal';
import LoomVideoRecorderContainer from '../candidate-assessment-flow/test-question-types/loom-video-recorder/LoomVideoRecorderContainer';

export default function VideoRecorderWithChoice(props) {

	const { question, onRetry, testType, setVideoCaptureMode, videoCapturedAndUploaded, setIsSkipModalOpen, isSomthingIsInProcess, isLoomVideoRecording } = props;



	const dispatch = useDispatch();
	const resourceReducer = useSelector((state) => state.resourceReducer);



	const [error, setError] = useState('');
	const [isVideoUploading, setIsVideoUploading] = useState(false);
	const [videoResourceId, setVideoResourceId] = useState(null);
	const [uploadBlobURL, setUploadBlobURL] = useState(null);
	const [videoMode, setvideoMode] = useState();
	const [isOpenModalOnChangeVideoMode, setIsOpenModalOnChangeVideoMode] = useState(false);
	const [loomVideoURL, setLoomVideoURL] = useState('');
	const [isUserTryingToRetry, setIsUserTryingToRetry] = useState(false);



	const onChangeVideoMode = (video_mode) => {
		if (videoResourceId && videoMode === 'native') {
			setIsOpenModalOnChangeVideoMode(true);
		}
		else if (loomVideoURL && videoMode === 'loom') {
			setIsOpenModalOnChangeVideoMode(true);
		}
		else {
			setvideoMode(video_mode);
			setVideoCaptureMode(video_mode);
		}
	};

	const handleVideoFileUpload = async (blobURL) => {
		setError('');
		const blob = await fetch(blobURL).then((r) => r.blob());
		const file = new File([blob], `${Date.now().toString()}.mp4`, {
			type: 'video/mp4',
		});
		setIsVideoUploading(true);
		const formData = new FormData();
		formData.append('file', file);
		const { payload, meta } = await dispatch(createResource({ data: formData, signal: null }));
		if (meta.requestStatus === 'rejected') {
			setError('Failed to upload video. Please check your internet connection and retry.');
			setIsVideoUploading(false);
			return;
		}
		else {
			setVideoResourceId(payload.id);
			setUploadBlobURL(null);
			setIsVideoUploading(false);
		}
	};

	const deleteVideo = async (videoId) => {
		await dispatch(deleteResource({ id: videoId }));
		setVideoResourceId(null);
	};

	const VideoRecorderMemo = useMemo(() => {
		return (
			<VideoRecorder
				recordingDuration={question?.duration}
				height='100%'
				width='100%'
				onVideoSubmit={(blobURL) => { setUploadBlobURL(blobURL); handleVideoFileUpload(blobURL); }}
				processingUpload={resourceReducer.processing}
				uploadProgress={resourceReducer.upload_percentage || 0}
				camMustActive={true}
				onRetry={() => { setIsUserTryingToRetry(true); }}
				retriesLeft={testType === TestType.VIDEO_TEST ? 2 - question.retries : undefined}
				isSomthingIsInProcess={isSomthingIsInProcess}
			/>
		);
	}, [question?.type, testType, isSomthingIsInProcess]);

	useEffect(() => {
		if (question?.recording_type !== 'open') {
			setvideoMode(question?.recording_type);
			setVideoCaptureMode(question?.recording_type);
		}
		else {
			setvideoMode('native');
			setVideoCaptureMode('native');
		}

	}, [question?.recording_type]);


	useEffect(() => {

		videoCapturedAndUploaded(videoMode === 'loom' ? loomVideoURL : videoResourceId);
	}, [loomVideoURL, videoResourceId]);

	useEffect(() => {
		if (isUserTryingToRetry) {
			onRetry();
			deleteVideo(videoResourceId);
			setIsUserTryingToRetry(false);
		}
	}, [isUserTryingToRetry, videoResourceId]);




	return (
		<div style={{ width: '100%', maxWidth: '538px', height: 'min-content', display: 'flex', flexDirection: 'column' }}>


			<CustomModal
				open={isOpenModalOnChangeVideoMode}
				varrient='alert-warning-modal'
				title='Change Video Mode?'
				description={'Are you sure you want to change the mode of your recorder?'}
				warningAlert={true}
				warningText={'Warning: Your previous video recording for this answer will be replaced!'}
				handleCloseModal={() => { setIsOpenModalOnChangeVideoMode(false); }}
				confirmBtnTitle={'Yes, Continue'}
				btnWidth='max-content'
				processing={resourceReducer?.processing_delete}
				onConfirm={async () => {
					if (videoMode === 'native') {
						await dispatch(deleteResource({ id: videoResourceId }));
						setVideoResourceId(null);
						setvideoMode('loom');
						setVideoCaptureMode('loom');
						setIsOpenModalOnChangeVideoMode(false);
					}
					else {
						setLoomVideoURL('');
						setvideoMode('native');
						setVideoCaptureMode('native');
						setIsOpenModalOnChangeVideoMode(false);
					}
				}}
			/>

			<div className='w-100' style={{ maxWidth: '538px', alignSelf: 'center' }}>
				<div className='video-cs-container'>
					{
						question?.recording_type === 'open' &&
						<div className='video-cs-container-header'>
							<Radio varrient='radio-with-custom-component'
								onChangeRadioOption={() => { onChangeVideoMode('native'); }}
								checked={videoMode === 'native'}
								radioValue={'native'}
								disabled={isSomthingIsInProcess}
							>
								<span className='body-2 dark-100' style={{ marginLeft: '8px' }}>
									Record with Native Cam
								</span>
							</Radio>
							<Radio varrient='radio-with-custom-component'
								onChangeRadioOption={() => { onChangeVideoMode('loom'); }}
								checked={videoMode === 'loom'}
								radioValue={'loom'}
								disabled={isSomthingIsInProcess}
							>
								<span className='body-2 dark-100' style={{ marginLeft: '8px' }}>
									Record with Loom
								</span>
							</Radio>
						</div>
					}
					<div className='video-cs-container-main'>
						{
							videoMode === 'native'
								?
								VideoRecorderMemo
								:
								<LoomVideoRecorderContainer
									switchToNativeOption={() => { setvideoMode('native'); setVideoCaptureMode('native'); }}
									getLoomVideoUrl={(url) => { setLoomVideoURL(url); }}
									avaiableVideoMode={question?.recording_type}
									isSomthingIsInProcess={isSomthingIsInProcess}
									isLoomVideoRecording={isLoomVideoRecording}

								/>
						}
					</div>
				</div>
			</div>
			{
				error &&
				<span className='body-3 danger-text align-items-center w-100' style={{ marginTop: '24px', textAlign: 'left', }}>
					{error}
					<span className='semibold primary-dark-text pointer'
						onClick={() => {
							setIsSkipModalOpen(false);
							setError('');
							handleVideoFileUpload(uploadBlobURL);
						}}
						style={{ textDecoration: 'underline', marginLeft: '2px' }}>Upload Again</span>
				</span>
			}

			{(videoMode === 'native' && testType === TestType.VIDEO_TEST) && <span className='body-2 dark-100' style={{ marginTop: '24px', textAlign: 'left' }}>{2 - question.retries} more attempts <span style={{ fontWeight: '300' }}>left to record this answer</span></span>}
		</div>
	);
}
