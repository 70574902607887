import { duration } from '@mui/material';
import { useFocusWithin } from '@react-aria/interactions';
import React, { useMemo, useState } from 'react';
import CustomModal from '../../../../components/common/Modal';
import ProcessingIndicator from '../../../../components/common/ProcessingIndicator';
import Timer from '../../../../components/common/Timer';
import Button from '../../../../components/sub-component/Button';
// import { useDispatch } from 'react-redux';
import Radio from '../../../../components/sub-component/Radio';
import utilities from '../../../../utils/utilities';
// import { answerTestQuestion } from '../../../../redux/thunks/Assessment';
import TestContentWrapper from './TestContentWrapper';
import ReactQuillBubble from '../../../../components/sub-component/ReactQuillBubble';
import { ReactComponent as AlertIcon } from '../../../../assets/icons/pricing/alert-circle.svg';

export default function SkillBasedTest(props) {
	const { active_question } = props.test;
	const [selectedChoice, setSelectedChoice] = useState(null);
	const [error, setError] = useState();
	// const [processing, setProcessing] = useState(false);
	// const [showSkipQuestionModal, setShowSkipQuestionModal] = useState(false);;

	const alphabets = ['A', 'B', 'C', 'D', 'E', 'F'];
	const choices = active_question?.choices?.map((item, index) => {
		return {
			...item,
			option: alphabets[index],
		};
	});
	// const dispatch = useDispatch();

	const onQuestionSubmit = () => {
		const data = {
			assess_id: props?.assessmentId,
			ques_id: active_question?.id,
			answer: selectedChoice?.id,
		};
		if (props.onConfirm) props.onConfirm(data);
	};

	const remainTime = useMemo(() => {
		if (!active_question) return 0;
		if (selectedChoice) {
			setSelectedChoice(null);
		}
		if (error) {
			setError(null);
		}
		const serveTime = new Date(active_question.serve_time);
		const duration = active_question.duration * 1000;

		const remainingTime = (serveTime.getTime() + duration - Date.now()) / 1000;
		return Number(remainingTime.toFixed(0));
	}, [active_question]);



	const [timeDuration, setTimeDuration] = useState(0);
	const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: e => { },
		onBlurWithin: e => { setIsSkipModalOpen(false); }
	});

	useMemo(() => {
		if (timeDuration === 0) {
			setIsSkipModalOpen(false);
		}
	}, [timeDuration]);

	const timeoutMessage = useMemo(() => {
		return(
			(active_question && timeDuration <= 0) && 
			<div className='timeout-message'>
				<span><AlertIcon style={{width: '24px', height: '24px'}} /></span>
				<span className='body-2 dark-100 text-start'>Your time for this question is expired. You cannot attempt this question anymore! Press click on next to continue the assessment.</span>
			</div>
		);
	}, [timeDuration]);

	const QuestionTextMemo = useMemo(() => {
		if (!active_question) return;
		return (
			<ReactQuillBubble
				value={active_question.text}
				className='ql-editor-skill-candidate-side text-read-only '
			/>
		);
	}, [active_question]);


	return (
		<>
			{timeoutMessage}
			<TestContentWrapper
				title={utilities.toTitleCase(
					props?.test?.section?.type === 'skill_test'
						? props?.test?.test?.title
						: props?.test?.section?.type,
				)}
				duration={remainTime}
				answer={selectedChoice}
				currentQuestion={props.currentQuestionNo}
				totalQuestions={props.totalQuestions || 0}
				onSubmit={(action) => {
					if (action === 'skip') {
						const data = {
							assess_id: props?.assessmentId,
						};
						if (props.onConfirm) props.onConfirm(data);
					} else {
						onQuestionSubmit();
					}
				}}
				processing={props.processing}
			>
				{(() => {
					if (!active_question) {
						return (
							<div className='align-self-center' >
								<ProcessingIndicator className={'medium-indicator'} />
							</div>
						);
					}
					return (
						<div className='assessment-test-container position-relative'>
							<div className='assessment-test-questions-container' style={{ opacity: (timeDuration <= 0) ? '0.3' : '1', pointerEvents: (timeDuration <= 0) ? 'none' : 'auto' }} onKeyDown={(e) => timeDuration <= 0 && e.preventDefault()}>
								<span className='subtitle-2 dark-100 disable-text-selection text-start'>
									{QuestionTextMemo}
								</span>
							</div>
							<div className='assessment-test-answers-container' style={{ position: 'sticky', top: '15px' }}>
								<div className='w-100 d-flex align-items-center justify-content-between'
									style={{ opacity: (timeDuration <= 0) ? '0.3' : '1', pointerEvents: (timeDuration <= 0) ? 'none' : 'auto' }} onKeyDown={(e) => timeDuration <= 0 && e.preventDefault()}
								>
									<span className='subtitle-2 dark-100'>
										Select only one
									</span>
									<Timer duration={remainTime}
										getUpdatedTime={(duration) => { setTimeDuration(duration); }}
									/>
								</div>
								<div className='w-100' style={{ opacity: (timeDuration <= 0) ? '0.3' : '1', pointerEvents: (timeDuration <= 0) ? 'none' : 'auto' }} onKeyDown={(e) => timeDuration <= 0 && e.preventDefault()}>
									{choices.map((choice, _index) => {
										const isSelected = choice.id === selectedChoice?.id;
										return (
											<Radio
												style={{ marginTop: '16px', background: isSelected ? '#ECEDF1' : '#F6F5F8' }}
												key={_index}
												varrient='radio-with-input'
												dangerouslySetInnerHTML
												onChangeRadioOption={() => {
													setSelectedChoice(choice);
												}}
												checked={choice.id === selectedChoice?.id}
												radioValue={choice}
												optionValue={choice.option}
												value={choice.text}
												readonly
												placeholder=''
												disabled={remainTime <= 0}
											/>
										);
									})}
								</div>
								<div className='w-100 d-flex justify-content-end align-items-end' style={{ marginTop: '24px' }}>
									<div tabIndex={-1} {...focusWithinProps}>
										{isSkipModalOpen ?
											<Button
												processing={props.processing}
												btn='ragular-btn' 
												varrient={'primary'}
												title={'Skip Question?'}
												style={{ width: 'max-content', height: '40px' }}
												onClick={() => {
													const data = {
														assess_id: props?.assessmentId
													};
													if (props.onConfirm) props.onConfirm(data);
													setIsSkipModalOpen(false);
												}} 
											/>
											:
											<Button
												processing={props.processing}
												// disabled={((!selectedChoice || selectedChoice === '') && (timeDuration === null || timeDuration > 0))}
												btn='ragular-btn' 
												varrient={'primary'}
												title={props.currentQuestionNo === props.totalQuestions || 0 ? 'Submit' : 'Next'}
												style={{ width: 'max-content', height: '40px' }}
												onClick={() => {
													if ((!selectedChoice || selectedChoice === '') && (timeDuration === null || timeDuration > 0)) {
														setIsSkipModalOpen(true);
													}
													else if (!isSkipModalOpen) {
														onQuestionSubmit();
														setIsSkipModalOpen(false);
													}
												}} 
											/>
										}
									</div>
								</div>
								<span className='body-3 danger-text mt-2'>{error}</span>
							</div>
						</div>
					);
				})()}
			</TestContentWrapper>
		</>
	);
}
