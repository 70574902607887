import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const getTanentProfile = createAsyncThunk(
	'userProfile/getTanentProfile',
	async ({ id, isCallForCurrAssessment }, { rejectWithValue, dispatch }) => {
		try {

			const request = await secure_instance.request({
				url: `/v1/tenant_profiles/${isCallForCurrAssessment ? id : ''}`,
				method: 'GET',
			});

			return { data: request?.data, isCallForCurrAssessment: isCallForCurrAssessment };
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	}
);

export const patchTanentProfile = createAsyncThunk(
	'userProfile/patchTanentProfile',
	async ({ data }, { rejectWithValue, dispatch }) => {
		try {

			const request = await secure_instance.request({
				url: `/v1/tenant_profiles/`,
				method: 'PATCH',
				data: data
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	}
);

export const getUserProfile = createAsyncThunk(
	'userProfile/getUserProfile',
	async ({ access_token }, { rejectWithValue, dispatch }) => {
		try {

			const request = await secure_instance.request({
				url: `/v1/user_profiles/me`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${access_token}`
				},
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	}
);
export const updateUserProfile = createAsyncThunk(
	'/user_profiles',
	async ({ first_name, last_name, access_token }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/user_profiles/',
				method: 'PATCH',
				headers: {
					Authorization: `Bearer ${access_token}`
				},
				data: {
					first_name: first_name,
					last_name: last_name
				},
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}

);

export const patchUserProfile = createAsyncThunk(
	'userProfile/patchUserProfile',
	async ({ data }, { rejectWithValue, dispatch }) => {
		try {

			const request = await secure_instance.request({
				url: `/v1/user_profiles/`,
				method: 'PATCH',
				data: data
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	}
);
