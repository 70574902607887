import { useMediaQuery } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import CustomTooltip from '../../../../../components/common/CustomTooltip';
import Button from '../../../../../components/sub-component/Button';
import { addSections, isSectionValidate, _sectionTypes } from './CreateAssessment';
import { ReactComponent as DragIcon } from '../../../../../assets/icons/assessment-creation/drag.svg';
import { ReactComponent as SuccessIcon } from '../../../../../assets/icons/candidate-assessment/SCheckCircle.svg';
import { ReactComponent as DangerIcon } from '../../../../../assets/icons/basic/danger.svg';




export default function CreateAssessmentTestTypes(props) {

	const { errorsInSections, selectedSectionType, setSelectedSectionType, selectedSectionIndex, getCurrentAssessmentComponentData,
		setSelectedSectionIndex, getSelectedOnRightSideMenuIndex, onUpdateSections, setErrorsInSections, fromDragIndex, setFromDragIndex } = props;

	const [assessmentSpecsDetails, setAssessmentSpecsDetails] = useState(props.assessmentSpecsDetails);
	const isOnTabletScreen = useMediaQuery('(max-width:1200px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	useEffect(() => {
		setAssessmentSpecsDetails(props.assessmentSpecsDetails);
	}, [props.assessmentSpecsDetails]);


	return (
		<div className='create-assessment-secttion-options-container'>
			<span className='subtitle-2 dark-100'>Test Types</span>
			{/* <span className='body-2 dark-50'>{`Drag & drop components to create personalized assessments`}</span> */}
			<div className='section-type-container'>
				{
					_sectionTypes?.map((sectionType, index) => {
						const draggable = !isOnTabletScreen && !assessmentSpecsDetails?.sections?.find(section => section?.type === sectionType?.id);
						const btnStatus = assessmentSpecsDetails?.sections?.filter(section => section?.type === sectionType?.id)?.length > 0
							?
							errorsInSections.filter((_section, _) => { return (_section.testType === sectionType.id); }).length > 0 ? { success: false } : { success: true }
							:
							null;
						return (
							<CustomTooltip key={index} text={'Drag and drop to add'} position='bottom-end'
								width="100%" disabled={isOnTabletScreen ? true : false} customPosition={{
									bottom: '0px',
									left: '20px'
								}}>
								<Fragment>
									{
										<Button
											onClick={
												() => {
													//   if (isOnTabletScreen) {
													//       setSelectedSectionType(sectionType.id);
													//       setOnTabAddSectionStepNo(2);
													//   }
												}
											}
											// disabled={!draggable}
											draggable={draggable}
											onDragEnd={async () => {
												addSections(assessmentSpecsDetails, selectedSectionType, selectedSectionIndex, isSectionValidate, setAssessmentSpecsDetails, getCurrentAssessmentComponentData, setSelectedSectionIndex, getSelectedOnRightSideMenuIndex, onUpdateSections, errorsInSections, setErrorsInSections);
												// handlePlay1(); 
											}}
											onDragStart={() => { setSelectedSectionType(sectionType.id); setFromDragIndex(-1); }}
											className={
												`draggable body-2 dark-50
															${selectedSectionIndex !== -1 && assessmentSpecsDetails?.sections[selectedSectionIndex]?.type === sectionType?.id ? ' in-section-btn-focused ' : ''}

															`}
											key={index} btn='in-section-btn-V2'
											preIcon={sectionType.icon}
											postIcon={
												btnStatus 
													? (btnStatus.success === false 
														? <DangerIcon/> 
														: <SuccessIcon/>
													) 
													: <DragIcon/>
											}
											title={sectionType.title}
											style={{ width: '100%', color: '#808191', cursor: `${draggable ? 'grab' : 'not-allowed'}` }} />
											
									}
								</Fragment>
							</CustomTooltip>
						);
					})
				}
			</div>
		</div>
	);
}
