
import freeEmailDomains from 'free-email-domains';

/**
 *
 * @param {string} email Email to be validated
 * @returns {boolean} true if email is valid else false
 */
export const isEmailValid = function (email) {

	const validationRegex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
	return validationRegex.test(email);

};

export const isEmailPublic = function (email) {
	const domain = email?.split('@')[1];

	return freeEmailDomains.includes(domain);
};

/**
 *
 * @param {string} password Password to be validated
 */
export const isPasswordValid = function (password) {
	const validationRegex = /^(?=.*[~!@#$%^&*()_+|\-=\\[\]{};':"\\|,.<>\\/?0-9])(?=.*[a-zA-Z]).{10,}$/g;
	return validationRegex.test(password);

};

/**
 * @param {string} text Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isTextValid = function (text) {
	const validationRegex = new RegExp(/[a-zA-Z]{3,}/);
	return text ? validationRegex.test(text) : false;
};

/**
 * @param {Number} number Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isNumberValid = function (number) {
	const validationRegex = new RegExp(/[0-9]/);
	return validationRegex.test(number);
};

/**
 * @param {string} webUrl Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isUrlValid = function (webUrl) {
	const validationRegex = new RegExp(/^((https):\/\/)(www.)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/);
	return validationRegex.test(webUrl);
};


/**
 * @param {string} zipcode Check if only contain text
 * @returns {boolean} true if text is valid or false
 */
export const isZipcodeValid = function (zipcode) {
	const validationRegex = new RegExp(/[0-9]{5,5}/);
	return validationRegex.test(zipcode);
};
