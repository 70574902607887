import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	ReactMediaRecorder,
	useReactMediaRecorder,
} from 'react-media-recorder';
import Button from '../sub-component/Button';
import { ReactComponent as PlayIcon } from '../../assets/icons/candidate-assessment/play.svg';
import { ReactComponent as PuaseIcon } from '../../assets/icons/candidate-assessment/pause.svg';
import { ReactComponent as CirclePlayIcon } from '../../assets/icons/candidate-assessment/circlePlay.svg';
import { ReactComponent as CirclePauseIcon } from '../../assets/icons/candidate-assessment/circlePause.svg';
import ProcessingIndicator from '../common/ProcessingIndicator';
import CustomModal from '../common/Modal';
import { addVideoStreamToVideoTag } from '../../utils/utilities';


function LiveStreamPreview({
	stream,
	style, status, startRecording, stopRecording, recordingDuration, variant,
	recordedVideoDuration, isSomthingIsInProcess
}) {
	const videoPreviewRef = React.useRef();
	const [currentTime, setCurrentTime] = useState(0);

	React.useEffect(() => {

		if (videoPreviewRef.current && stream) {
			videoPreviewRef.current.srcObject = stream;
		}
		return () => {
		};
	}, []);

	if (!stream) {
		return <span>No stream available</span>;
	}

	if (status === 'recording') {
		setTimeout(() => {
			setCurrentTime(currentTime + 1);
		}, 1000);
	}

	return (
		<>
			<video
				ref={videoPreviewRef}
				autoPlay
				style={{ height: 'calc(100% - 52px)', width: '100%', objectFit: 'cover' }}

				onTimeUpdate={() => {
					if (status === 'recording' && currentTime >= recordingDuration) {
						recordedVideoDuration(currentTime);
						stopRecording();
					}
				}}
			/>

			{
				variant === 'testing'
					?
					<>
						<div className='camera-container-footer-testing' style={{ background: '#121216' }}>
							{
								<span className='body-2' style={{ color: 'white' }}>
									{status === 'idle' ? 'Preview' : `${currentTime === 0 ? '00:00' :
										`${parseInt((currentTime / 60)) < 10 ? '0' + parseInt((currentTime / 60)) : parseInt((currentTime / 60))}:${(currentTime - (60 * parseInt((currentTime / 60)))) < 10 ? '0' + `${(currentTime - (60 * parseInt((currentTime / 60))))}` : (currentTime - (60 * parseInt((currentTime / 60))))}`}
										/ 
										${parseInt((recordingDuration / 60)) < 10 ? '0' + parseInt((recordingDuration / 60)) : parseInt((recordingDuration / 60))}:${(recordingDuration - (60 * parseInt((recordingDuration / 60)))) < 10 ? '0' + `${parseInt(recordingDuration - (60 * parseInt((recordingDuration / 60))))}` : parseInt(recordingDuration - (60 * parseInt((recordingDuration / 60))))}`}
								</span>
							}
						</div>
						{
							status === 'recording' &&
							< Button btn='ragular-btn' varrient='primary' size='sm' title={'Stop recording'} onClick={() => { recordedVideoDuration(currentTime); stopRecording(); }} style={{ marginTop: '12px', width: 'fit-content' }} />
						}
					</>
					:
					<div className='camera-container-footer'>
						<Button btn='ragular-btn' varrient='primary' size='sm'
							title={status === 'idle' ? 'Start recording' : status === 'recording' ? 'Stop recording' : 'Record again'}
							disabled={isSomthingIsInProcess}
							onClick={status === 'idle' ? () => { startRecording(); } : status === 'recording' ? () => { recordedVideoDuration(currentTime); stopRecording(); } : startRecording} />


						{
							<span className='body-2 dark-100'>
								{`${currentTime === 0 ? '00:00' :
									`${parseInt((currentTime / 60)) < 10 ? '0' + parseInt((currentTime / 60)) : parseInt((currentTime / 60))}:${(currentTime - (60 * parseInt((currentTime / 60)))) < 10 ? '0' + `${(currentTime - (60 * parseInt((currentTime / 60))))}` : (currentTime - (60 * parseInt((currentTime / 60))))}`}
									/ 
									${parseInt((recordingDuration / 60)) < 10 ? '0' + parseInt((recordingDuration / 60)) : parseInt((recordingDuration / 60))}:${(recordingDuration - (60 * parseInt((recordingDuration / 60)))) < 10 ? '0' + `${parseInt(recordingDuration - (60 * parseInt((recordingDuration / 60))))}` : (recordingDuration - (60 * parseInt((recordingDuration / 60))))}
								`}
							</span>
						}
					</div>
			}

		</>
	);
}

export default function VideoRecorder(props) {

	const { variant, recordingDuration, isSomthingIsInProcess, setPVRDuration } = props;
	const [recorderVideoDuration, setRecorderVideoDuration] = useState(0);
	const [onRetryModal, setOnRetryModal] = useState(false);

	const onRecordStop = (blobURL, blob) => {
		var fileOfBlob = new File([blob], `Recorded-${Math.random() * 10}-version`);
		//startRecording();
	};

	const {
		status,
		startRecording,
		stopRecording,
		mediaBlobUrl,
		clearBlobUrl,
		previewStream
	} = useReactMediaRecorder({
		onStop: onRecordStop,
		video: true,
		askPermissionOnMount: true,
		blobOptions: { type: 'video/webm' },
		mediaStreamConstraints: { audio: true, video: true }
	});
	// const [blobUrls, setBlobUrls] = useState([]);

	// const canShowRecording = mediaBlobUrl && status !== 'recording';

	const video_Ref = useRef();
	const [currentTime, setCurrentTime] = useState(0);

	const [isVideoPlaying, setIsVideoPlaying] = useState(false);

	// const [isRefreshed, setIsRefreshed] = useState(false);

	useEffect(() => {

		if (!mediaBlobUrl && status !== 'stopped') return;

		if (props.onVideoSubmit) {
			props.onVideoSubmit(mediaBlobUrl);
		}

	}, [mediaBlobUrl]);


	return (
		<>
			{
				<CustomModal
					open={onRetryModal}
					varrient='alert-warning-modal'
					title='Record Again?'
					description={'Are you sure, you want to record a new video for this question?'}
					warningAlert={true}
					warningText={'Warning: By doing this your previous video recording will be replaced!'}
					handleCloseModal={() => { setOnRetryModal(false); }}
					confirmBtnTitle={'Yes, Record Again'}
					btnWidth='max-content'
					onConfirm={() => {
						if (props.onRetry) {
							props.onRetry();
						}
						// startRecording();
						clearBlobUrl();
						setOnRetryModal(false);
					}}
				/>
			}

			<div id='demo' className='video-recorder-container' style={{ height: props.height ? props.height : '100%' }}>
				{/* status: {status.toString()}
			{(status === 'stopped' || status === 'idle') && (
				<button onClick={startRecording}>Start Recording</button>
			)}
			{status === 'recording' && (
				<button onClick={stopRecording}>Stop Recording</button>.
			)} */}
				{
					(previewStream && ((status === 'idle' || status === 'recording'))) ?
						<LiveStreamPreview stream={previewStream}
							status={status}
							isSomthingIsInProcess={isSomthingIsInProcess}
							startRecording={startRecording}
							stopRecording={stopRecording}
							recordingDuration={recordingDuration} variant={variant}
							recordedVideoDuration={(duration) => {
								setRecorderVideoDuration(duration);
								if (setPVRDuration) {
									setPVRDuration(duration);
								}
							}}
						/>
						:
						<>
							<video ref={video_Ref} src={mediaBlobUrl} autoPlay={true} className='video-recorder'

								onTimeUpdate={() => {
									setCurrentTime(Math.floor(video_Ref?.current?.currentTime));
								}}
								onEnded={() => {
									setIsVideoPlaying(false);
								}}

								onPlay={() => {
									setIsVideoPlaying(true);
								}}

								onPause={() => {
									setIsVideoPlaying(false);
								}}
							/>
						</>
				}

				{
					variant === 'testing'
						?
						<>
							{
								status === 'stopped' &&
								<div className='camera-container-footer-testing' style={{ background: '#121216' }}>
									{
										(isVideoPlaying)
											?
											<PuaseIcon className='pointer' style={{ marginRight: '10px' }} onClick={() => { video_Ref.current.pause(); }} />
											:
											<PlayIcon className='pointer' style={{ marginRight: '10px' }} onClick={() => { video_Ref.current.play(); }} />
									}

									{
										<span className='body-2' style={{ color: 'white' }}>
											{`${currentTime === 0 ? '00:00' :
												`${parseInt((currentTime / 60)) < 10 ? '0' + parseInt((currentTime / 60)) : parseInt((currentTime / 60))}:${(currentTime - (60 * parseInt((currentTime / 60)))) < 10 ? '0' + `${parseInt(currentTime - (60 * parseInt((currentTime / 60))))}` : parseInt(currentTime - (60 * parseInt((currentTime / 60))))}`}
												/ 
												${parseInt((recorderVideoDuration / 60)) < 10 ? '0' + parseInt((recorderVideoDuration / 60)) : parseInt((recorderVideoDuration / 60))}:${(recorderVideoDuration - (60 * parseInt((recorderVideoDuration / 60)))) < 10 ? '0' + `${parseInt(recorderVideoDuration - (60 * parseInt((recorderVideoDuration / 60))))}` : parseInt(recorderVideoDuration - (60 * parseInt((recorderVideoDuration / 60))))}
											`}
										</span>
									}
								</div>
							}
						</>
						:
						<>
							{
								status === 'stopped' &&
								<div className='camera-container-footer'>
									{
										props.processingUpload ? (
											<>
												<ProcessingIndicator className='small-indicator' />
											</>
										) : (
											<>
												<Button btn='ragular-btn' varrient='primary' size='sm'
													disabled={props.retriesLeft === 0 || isSomthingIsInProcess}
													title={status === 'idle' ? 'Start recording' : status === 'recording' ? 'Stop recording' : 'Record again'}
													onClick={status === 'idle' ? startRecording : status === 'recording'
														?
														() => {
															stopRecording();
															if (props?.camMustActive && sessionStorage.getItem('cam_and_mic_permission_for_ss') !== 'blocked' && sessionStorage.getItem('cam_and_mic_permission_for_ss') !== 'undefined') {
																addVideoStreamToVideoTag('capture-user-snapshots');
															}
														}
														: () => {
															setOnRetryModal(true);
														}} />
												{
													(isVideoPlaying)
														?
														<CirclePauseIcon className='pointer' style={{ marginRight: '10px' }} onClick={() => { video_Ref.current.pause(); }} />
														:
														<CirclePlayIcon className='pointer' style={{ marginRight: '10px' }} onClick={() => { video_Ref.current.play(); }} />
												}

												{
													status === 'stopped' &&
													<div style={{ display: 'flex', width: '100px' }}>
														<span className='body-2 dark-100'>
															{`${currentTime === 0 ? '00:00' :
																`${parseInt((currentTime / 60)) < 10 ? '0' + parseInt((currentTime / 60)) : parseInt((currentTime / 60))}:${(currentTime - (60 * parseInt((currentTime / 60)))) < 10 ? '0' + `${parseInt(currentTime - (60 * parseInt((currentTime / 60))))}` : parseInt(currentTime - (60 * parseInt((currentTime / 60))))}`}
														/ 
														${parseInt((recorderVideoDuration / 60)) < 10 ? '0' + parseInt((recorderVideoDuration / 60)) : parseInt((recorderVideoDuration / 60))}:${(recorderVideoDuration - (60 * parseInt((recorderVideoDuration / 60)))) < 10 ? '0' + `${parseInt(recorderVideoDuration - (60 * parseInt((recorderVideoDuration / 60))))}` : parseInt(recorderVideoDuration - (60 * parseInt((recorderVideoDuration / 60))))}
													`}
														</span>
													</div>
												}
											</>
										)

									}
								</div>
							}
						</>


				}

				{
					(variant === 'testing' && (status === 'idle' || status === 'stopped')) &&
					<Button btn='ragular-btn' varrient='primary' title={'Test camera'} onClick={(status === 'idle' || status === 'stopped') ? startRecording : stopRecording} style={{ marginTop: '12px', width: 'fit-content', padding: '6px 24px' }} />
				}

			</div>



		</>
	);
}
