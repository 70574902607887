import React from 'react';
import Button from '../sub-component/Button';
import { secondsToDhmsFormat } from '../../utils/utilities';

export default function AssessmentTestInfoWrapper(props) {
	const { title, varrient, children, primaryBtnTitle, secondaryBtnTitle, btnWidth, secondaryBtnWidth, onSecondaryBtnClick, onPrimaryBtnClick, disablePrimaryBtn, loading } = props;

	return (
		<div className={`assessment-info-dialog-box-container ${props.className}`} style={{maxWidth: varrient === 'qq-test' && '100%', height: varrient === 'assessmentInstructions' ? '100%' : varrient === 'qq-test' && '100%', ...props.style}}>
			{loading ?
				<div className='processing-indicator-wrapper medium-indicator'>
					<div className='processing-indicator primary' style={{ width: '100%', height: '100%' }} />
				</div>
				:
				<>
					<div className='title d-flex align-items-center justify-content-between'>
						<span className='headline-4 dark-100 truncate-title'>{title || 'Title'}</span>
						{varrient === 'assessmentInstructions' &&
							<div className='d-flex align-items-end' style={{ width: '300px', gap: '12px'}}>
								{!props.disableSecondaryBtn &&
									<Button 
										btn='ragular-btn'
										varrient='secondary-btn'
										style={{ width: '110px', height: '40px' }}
										title={secondaryBtnTitle || 'Cancel'} 
										onClick={onSecondaryBtnClick} 
									/>
								}
								<Button 
									processing={props.processing}
									disabled={disablePrimaryBtn}
									btn='ragular-btn'
									varrient='primary'
									style={{ width: '175px', height: '40px' }}
									title={primaryBtnTitle || 'Confirm'} 
									onClick={onPrimaryBtnClick} 
								/>
							</div>
						}
						{props?.test && 
							<span className='body-2 dark-50'>Time: <span className='dark-100'>~{secondsToDhmsFormat(props?.test?.duration, false, true)}</span></span>
						}
					</div>
					<div className='w-100' style={{ padding: '24px', wordBreak: 'break-word' }}>
						{children}
					</div>
					{varrient !== 'assessmentInstructions' &&
						<div className={`assessment-info-dialog-box-bottom-container ${!props.disableSecondaryBtn && 'justify-content-between'}`} style={{borderTop: varrient === 'qq-test' && 'none'}}>
							{!props.disableSecondaryBtn &&
								<Button 
									btn='ragular-btn'
									varrient='secondary-btn'
									style={{ width: 'max-content', height: '40px' }}
									title={secondaryBtnTitle || 'Cancel'} 
									onClick={onSecondaryBtnClick} 
								/>
							}
							<Button 
								processing={props.processing}
								disabled={disablePrimaryBtn}
								btn='ragular-btn'
								varrient='primary'
								style={{ width: 'max-content', height: '40px' }}
								title={primaryBtnTitle || 'Confirm'} 
								onClick={onPrimaryBtnClick} 
							/>
						</div>
					}
				</>
			}
		</div>
	);
}
