import axios from 'axios';
import baseURL from './../../utils/BaseURL';
import {
	addAccessToken,
	handleRequestError,
	handleResponseOK,
	handleResponseError,
} from './interceptors';

const instance = axios.create({
	baseURL: baseURL,
	withCredentials: false,
	timeout: 60000,
});

const instance_resource = axios.create({
	baseURL: baseURL,
	withCredentials: false,
	timeout: 600000,
});

const secure_instance = axios.create({
	baseURL: baseURL,
	withCredentials: false,
	timeout: 60000,
});

const secure_instance_resource = axios.create({
	baseURL: baseURL,
	withCredentials: false,
	timeout: 600000,
});

instance.interceptors.response.use(handleResponseOK, handleResponseError);

instance_resource.interceptors.response.use(handleResponseOK, handleResponseError);

secure_instance.interceptors.request.use(addAccessToken, handleRequestError);
secure_instance.interceptors.response.use(
	handleResponseOK,
	handleResponseError,
);

secure_instance_resource.interceptors.request.use(addAccessToken, handleRequestError);
secure_instance_resource.interceptors.response.use(
	handleResponseOK,
	handleResponseError,
);

export { instance, instance_resource, secure_instance, secure_instance_resource };
