import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertBox from '../common/AlertBox';
import { signup } from '../../redux/thunks/Auth';
import { isEmailValid, isEmailPublic, isPasswordValid } from './../../utils/Validator';
import Button from './../sub-component/Button';
import Input from './../sub-component/Input';
import { useHistory } from 'react-router-dom';
import { RequestStatus } from '../../utils/Constants';
import { useLocation } from 'react-router-dom';
import { ReactComponent as GoogleIcon } from '../../assets/icons/logo/google_icon.svg';
import { getGoogleOauthOpts } from '../../utils/utilities';
import { ReactComponent as ErrorIcon } from '../../assets/icons/basic/x-circle-error.svg';
import { PathName } from '../../utils/routes';
import { useMediaQuery } from '@mui/material';



const SignupForm = () => {

	const search = useLocation().search;
	const searchParams = new URLSearchParams(search);
	const invite_token = searchParams.get('invite_token');
	const invite_id = searchParams.get('invite_id');
	const failure = searchParams.get('failure');
	const isMobile = useMediaQuery('(max-width:767px)');




	/**@type {AuthState} **/
	const auth = useSelector(state => state.authReducer);

	const dispatch = useDispatch();
	const history = useHistory();

	let user = '';


	const [credentials, setCredentials] = useState({
		first_name: null,
		last_name: null,
		company_name: null,
		email: null,
		password: null,
		confirm_password: null,
		invite_token: invite_token,
		invite_id: invite_id
	});

	useEffect(() => {

		if (invite_token) {

			user = JSON.parse(atob(invite_token?.split('.')?.slice(1, 2)[0]));
			setCredentials({ ...credentials, email: user?.user?.email || null });
		}
	}, [invite_token]);

	const [error, setError] = useState({
		first_name: null,
		last_name: null,
		company_name: null,
		email: null,
		password: null,
		confirm_password: null
	});

	const [hidePassword, setHidePassword] = useState(true);
	const [hideConfirmPassword, setHideConfirmPassword] = useState(true);

	const [alert, setAlert] = useState(null);


	const handleChange = (fieldName, event) => {
		let value = event.target.value;
		if (fieldName === 'email') {
			value = event.target.value.trim();
		}
		setCredentials({ ...credentials, [fieldName]: value });
	};

	useEffect(() => {
		if (failure) {
			setAlert(
				<AlertBox description={`${failure}!`} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
			);

			searchParams.delete('failure');
			history.replace(`${PathName.SIGNUP}?${searchParams.toString()}`);
		}
	}, [failure]);

	const isFormValid = () => {

		var isValid = true;

		const _credentials = {
			first_name: { value: credentials.first_name, is_reuiqred: true },
			last_name: { value: credentials.last_name, is_reuiqred: true },
			company_name: { value: credentials.company_name, is_reuiqred: (invite_token || invite_id) ? false : true },
			email: { value: credentials.email, is_reuiqred: (invite_token || invite_id) ? false : true },
			password: { value: credentials.password, is_reuiqred: true },
			confirm_password: { value: credentials.confirm_password, is_reuiqred: true }
		};

		var _error = {};

		Object.keys(_credentials).map(key => {

			if (_credentials[key].is_reuiqred && (_credentials[key].value === null || _credentials[key].value === '')) {
				_error = { ..._error, [key]: `${key.replace('_', ' ')} is required` };
				isValid = false;

			} else {
				_error = { ..._error, [key]: null };
			}

		});


		if (_credentials.email.is_reuiqred && !isEmailValid(credentials.email)) {
			_error['email'] = 'Invalid email address';

		} else if (_credentials.email.is_reuiqred && isEmailPublic(credentials.email)) {
			_error['email'] = [
				<span key={Math.random().toString()}>
					You cannot signup using a non-organization email e.g.
					<b> {credentials.email.split('@')[1]}</b>
				</span>
			];
		}

		if (!isPasswordValid(credentials.password)) {
			_error['password'] = 'Password should contain atleast 10 characters and 1 special character or a digit';
		}

		if (credentials.password !== credentials.confirm_password) {
			_error['confirm_password'] = 'Password does not match';
			isValid = false;
		}

		setError(_error);
		return isValid;


	};


	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!isFormValid()) {
			return;
		}

		const dispatched = await dispatch(signup({ is_candidate: (invite_token || invite_id) ? true : false, data: credentials }));

		if (dispatched.meta.requestStatus === RequestStatus.REJECTED) {
			setAlert(
				<AlertBox description={`${dispatched.payload}!`} color='danger' varrient='' title="Error" onCloseAlert={() => { setAlert(null); }} />
			);
			return;
		}

		window.location.reload();


	};

	const signUpWithGoogleHandler = () => {
		const { options, rootUrl } = getGoogleOauthOpts();

		const qs = new URLSearchParams(options);
		const searchParams = new URLSearchParams(search);
		const searchParamsObj = Object.fromEntries(searchParams);

		const additionalParams = {
			...searchParamsObj,
			authProcess: 'signup'
		};
		const state = JSON.stringify(additionalParams);

		const googleConsentScreenUrl = `${rootUrl}?${qs.toString()}&state=${encodeURIComponent(state)}`;

		window.location.href = googleConsentScreenUrl;
	};

	return (
		<div className="signup-input-group signup-form">
			<form onSubmit={handleSubmit}>
				{error.first_name ? <div className="error-text">
					<div style={{ width: '30px' }}>
						<ErrorIcon />
					</div>
					{error.first_name}
				</div> :
					<></>
				}
				{error.last_name ? <div className="error-text">
					<div style={{ width: '30px' }}>
						<ErrorIcon />
					</div>
					{error.last_name}
				</div> :
					<></>
				}
				{error.company_name ? <div className="error-text">
					<div style={{ width: '30px' }}>
						<ErrorIcon />
					</div>
					{error.company_name}
				</div> :
					<></>
				}
				{error.email ? <div className="error-text">
					<div style={{ width: '30px' }}>
						<ErrorIcon />
					</div>
					{error.email}
				</div> :
					<></>
				}
				{error.password ? <div className="error-text">
					<div style={{ width: '30px' }}>
						<ErrorIcon />
					</div>
					{error.password}
				</div> :
					<></>
				}
				{error.first_name ? <div className="error-text">
					<div style={{ width: '30px' }}>
						<ErrorIcon />
					</div>
					{error.confirm_password}
				</div> :
					<></>
				}

				{/* <div className='alert-wrapper'>
					{alert}
				</div> */}

				<div className='d-flex justify-content-between' style={{ gap: '16px', flexDirection: isMobile ? 'column' : 'row' }}>
					<div className="d-flex flex-column form-input-field" style={{ width: isMobile ? '100%' : '50%' }}>
						<label className='body-2 dark-50 mb-2' style={{ marginLeft: '24px' }}>First Name</label>
						<Input
							name={'first_name'}
							// placeholder="First Name"
							onChange={(e) => { handleChange('first_name', e); }}
							type="text" value={credentials.first_name}
							className={error.first_name ? 'error-input' : ''}
						// varrient={'primary'}
						/>


					</div>
					<div className="d-flex flex-column  form-input-field" style={{ width: isMobile ? '100%' : '50%' }}>
						<label className='body-2 dark-50 mb-2' style={{ marginLeft: '24px' }}>Last Name</label>

						<Input
							name={'last_name'}
							// placeholder="Last Name"
							onChange={(e) => { handleChange('last_name', e); }}
							type="text"
							value={credentials.last_name}
							className={error.last_name ? 'error-input' : ''}
							// varrient={'primary'}

						/>

					</div>
				</div>
				{
					(!invite_token && !invite_id) && (
						<>
							<div className="d-flex flex-column form-input-field">
								<label className='body-2 dark-50 mb-2' style={{ marginLeft: '24px' }}>Company Name</label>

								<Input
									name={'company_name'}
									// placeholder="Company Name"
									onChange={(e) => { handleChange('company_name', e); }}
									type="text" value={credentials.company_name}
									className={error.company_name ? 'error-input' : ''}
								// varrient={'primary'}
								/>

							</div>
						</>
					)
				}
				<div className="d-flex flex-column form-input-field">
					<label className='body-2 dark-50 mb-2' style={{ marginLeft: '24px' }}>{invite_id ? 'Email' : 'Company Email'}</label>

					<Input
						name={'email'}
						// placeholder={invite_id ? 'Email' : 'Company Email'}
						onChange={(e) => { handleChange('email', e); }}
						type="email" value={credentials.email || ''}
						className={error.email ? 'error-input' : ''}
						disabled={user?.user?.email}
						readOnly={invite_token}
					// varrient={'primary'}
					/>

				</div>
				<div className='d-flex justify-content-between' style={{ gap: '16px', flexDirection: isMobile ? 'column' : 'row' }}>
					<div className="d-flex flex-column form-input-field" style={{ width: isMobile ? '100%' : '50%' }}>
						<label className='body-2 dark-50 mb-2' style={{ marginLeft: '24px', fontSize: '14.5px' }}>Password</label>

						<Input
							name={'password'}
							// placeholder="Password"
							onChange={(e) => { handleChange('password', e); }}
							type={hidePassword ? 'password' : 'text'}
							value={credentials.password}
							enableRevealIcon={true}
							revealPasswordClick={() => setHidePassword(!hidePassword)}
							className={error.password ? 'error-input' : ''}
						// varrient={'primary'}
						/>
					</div>
					<div className="d-flex flex-column form-input-field" style={{ width: isMobile ? '100%' : '50%' }}>
						<label className='body-2 dark-50 mb-2' style={{ marginLeft: '24px' }}>Confirm Password</label>

						<Input
							// placeholder="Confirm Password"
							name={'confirm_password'}
							onChange={(e) => { handleChange('confirm_password', e); }}
							type={hideConfirmPassword ? 'password' : 'text'}
							value={credentials.confirm_password}
							enableRevealIcon={true}
							revealPasswordClick={() => setHideConfirmPassword(!hideConfirmPassword)}
							className={error.confirm_password ? 'error-input' : ''}
						// varrient={'primary'}
						/>

					</div>
				</div>
				{/* <div style={{ marginTop: '24px' }}>
					<span className='body-2'>By signing up, you agree to our <a href={`${window._env_.TF_LANDING_SITE_URL}/terms-of-service`} rel='noopener noreferrer' target='_blank' className='primary-text semibold' style={{ color: '#6F2DBD' }}>Terms of Service</a> and  <a href={`${window._env_.TF_LANDING_SITE_URL}/privacy-policy`} rel='noopener noreferrer' target='_blank' className='primary-text semibold' style={{ color: '#6F2DBD' }}>Privacy Policy</a> </span>
				</div> */}
				<div className="submit-button">
					<Button style={{ width: '100%', padding: '0px 14px', height: '34px' }} varrient="primary" btn="ragular-btn" type={'submit'} rectangle-button onClick={handleSubmit} title={'Sign Up'} processing={auth.is_loading} />
				</div>
			</form>
			<div className='d-flex justify-content-between align-items-center mt-2'>
				<div style={{ border: '1px solid #ECEDF1', width: '45%' }}></div>
				<div className="body-2 black-700 d-flex justify-content-center align-content-center">
					{`Or`}
				</div>
				<div style={{ border: '1px solid #ECEDF1', width: '45%' }}></div>

			</div>
			<div style={{ marginTop: '12px' }}>
				<Button type={'submit'} onClick={signUpWithGoogleHandler} isGoogleAuth={true} style={{ width: '100%', padding: '0px 14px', height: '34px' }} btn="ragular-btn" varrient="secondary-btn" title={'Sign Up With Google'} className='btn-md d-flex align-items-center justify-content-center' processing={auth.is_loading} preIcon={<GoogleIcon style={{ width: '20px' }} />} />
			</div>
		</div >


	);
};
export default SignupForm;
