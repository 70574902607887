import React, { useState, useEffect, useMemo } from 'react';
import TestContentWrapper from '../../../candidate-assessment-flow/flow-steps/tests/TestContentWrapper';
import ReactQuillBubble from '../../../../components/sub-component/ReactQuillBubble';
import ProcessingIndicator from '../../../../components/common/ProcessingIndicator';
import Button from '../../../../components/sub-component/Button';
import Radio from '../../../../components/sub-component/Radio';

export default function SkillBasedTestPQ(props) {
	const { active_question, isFirstQuestion, isLastQuestion, handleNext, handlePrev } = props;
	const [selectedChoice, setSelectedChoice] = useState(null);

	const alphabets = ['A.', 'B.', 'C.', 'D.', 'E.', 'F.'];
	const choices = active_question?.choices?.map((item, index) => {
		return {
			...item,
			option: alphabets[index],
		};
	});

	const QuestionTextMemo = useMemo(() => {
		return (
			<ReactQuillBubble
				value={active_question?.text}
				className='ql-editor-skill-candidate-side text-read-only '
			/>
		);
	}, [active_question]);


	return (
		<>
			<TestContentWrapper>
				<div className='testpack-test-container justify-content-between position-relative'>
					<div className='testpack-test-questions-container' style={{ maxWidth: '596px', }}>
						<span
							className='body-2 disable-text-selection'
							style={{ textAlign: 'start' }}
						>
							{QuestionTextMemo}
						</span>
					</div>
					<div className='testpack-test-answers-container' style={{ maxWidth: '652px', position: 'sticky', top: '15px' }}>
						<div className='w-100 d-flex align-items-center justify-content-between'>
							<span className='subtitle-2  dark-100'>
								Select only one
							</span>
						</div>
						<div className='w-100'>
							{ choices?.map((choice, index) => { 
								const isSelected = choice.id === selectedChoice?.id;
								return (
									<div
										key={index}
										className={`${isSelected && ' selected-radio '
										} radio-with-input-container pointer d-flex align-items center readonly-radio-with-input-container`}
										style={{ marginTop: '16px', background: isSelected ? '#DEDAF2' : '#FFFFFF', borderColor: isSelected ? '#6F2DBD' : '#C8CBCD' }}
										onClick={() => {
											setSelectedChoice(choice);
										}}
									>
										<input
											checked={isSelected}
											className='form-check-input'
											type='radio'
											id='radioNoLabel1'
											value={choice}
											aria-label='...'
											readOnly={true}
										/>
										<div
											className='read-only-div d-flex align-items-start'
											style={{ textAlign: 'left' }}
										>
											{choice.option && (
												<div style={{ marginRight: '18px' }}>
													<span className='body-2 black-700 form-option disable-text-selection'>
														{choice.option}
													</span>
												</div>
											)}
											<ReactQuillBubble
												value={choice.text}
												className='ql-editor-skill-candidate-side text-read-only '
												
												
											/>
										</div>
									</div>
								);
							})}
						</div>
						<div className='w-100 d-flex justify-content-end align-items-end'
							style={{ marginTop: '24px', gap: '12px' }}
						>
							{ !isFirstQuestion &&
								<Button
									processing={props.processing}
									btn="ragular-btn" varrient={'secondary-btn'}
									title={'Previous'}
									style={{ width: '103px', height: '40px' }}
									onClick={handlePrev} 
								/>
							}
							{ !isLastQuestion &&
								<Button
									processing={props.processing}
									btn="ragular-btn" varrient={'primary'}
									title={'Next'}
									style={{ width: '78px', height: '40px' }}
									onClick={handleNext} 
								/>								
							}
						</div>
					</div>
				</div>
			</TestContentWrapper>
		</>
	);
}
