import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TestType, UserRole } from '../../Constants';
import { takeCandidatePicture, takeCandidateScreenEvent } from '../../../redux/thunks/AntiCheat';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import SessionContextWrapper, { SessionContext } from './SessionContext';
import VideoTestingView from '../../../views/candidate-assessment-flow/flow-steps/tests/VideoResposnses/VideoTestingView';
import CustomModal from '../../../components/common/Modal';
import { addVideoStreamToVideoTag, browserInfo } from '../../utilities';

export const AntiCheatContext = createContext(null);

let capture_pic_interval = null;
let always_check_interval = null;
let check_cam_permission_when_active_interval = null;
let media_stream = null;
let check_media_stream_active = null;

export default function AntiCheatContextWrapper(props) {


	const auth = useSelector((state) => state.authReducer);
	const assessment = useSelector((state) => state.assessmentReducer);
	const [isVideoStreamActive, setIsVideoStreamActive] = useState(false);
	const dispatch = useDispatch();
	const params = useParams();

	const [antiCheatStates, setAntiCheatStates] = useState({
		test_cam_before_video_test: false,
		media_devices: { isEnabled: undefined, message: '' }
	});

	const capturePic = () => {
		navigator.mediaDevices.enumerateDevices()
			.then(async devices => {
				const cameras = devices.filter(device => (device.kind === 'videoinput' && device.label !== ''));
				if (cameras.length > 0) {
					const canvasElement = document.getElementById('capture-user-snapshots-canvas');
					const videoElement = document.getElementById('capture-user-snapshots');
					const context = canvasElement.getContext('2d');
					context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

					canvasElement.toBlob(function (blob) {
						const formData = new FormData();
						formData.append('file', new File([blob], 'captured_image.png', { type: 'image/png' }));
						dispatch(takeCandidatePicture({ file: formData, assess_id: params.id }));
					}, 'image/png');
				}
			})
			.catch(error => {
				// sessionStorage.setItem('cam_and_mic_permission_for_ss', `connection_lost`);
			});
	};

	const check_cam_permission_when_active = () => {

		navigator.mediaDevices.enumerateDevices()
			.then(devices => {
				const cameras = devices.filter(device => (device.kind === 'videoinput' && device.label !== ''));
				if (cameras.length === 0) {
					sessionStorage.setItem('cam_and_mic_permission_for_ss', `connection_lost`);
				}
			})
			.catch(error => {
				sessionStorage.setItem('cam_and_mic_permission_for_ss', `connection_lost`);
			});
	};

	const checkMediaPermission = () => {
		navigator.mediaDevices.enumerateDevices()
			.then(devices => {
				const cameras = devices.filter(device => (device.kind === 'videoinput' && device.label !== ''));
				const microphones = devices.filter(device => (device.kind === 'audioinput' && device.label !== ''));
				if (assessment?.current_assessment?.anti_cheat_settings?.snapshot_settings?.required) {
					if (cameras.length === 0) {
						setAntiCheatStates({ ...antiCheatStates, media_devices: { isEnabled: false, message: 'Webcam issue' } });
					}
				}

				if (assessment?.current_assessment?.active_test?.test?.has_video_response || assessment?.current_assessment?.active_test?.test?.test_type === TestType.VIDEO_TEST) {
					if (cameras.length === 0 || microphones.length === 0) {
						setAntiCheatStates({ ...antiCheatStates, media_devices: { isEnabled: false, message: 'Mic and Webcam issue' } });

					}
				}
			})
			.catch(error => {
				setAntiCheatStates({ ...antiCheatStates, media_devices: { isEnabled: false, message: 'Mic and Webcam issue' } });
			});
	};

	const retryToAllowMediaPermission = () => {

		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices.getUserMedia({ video: true, audio: true })
				.then(function (stream) {
					setAntiCheatStates({ ...antiCheatStates, media_devices: { isEnabled: true, message: '' } });
					if (sessionStorage.getItem('cam_and_mic_permission_for_ss') === 'connection_lost' || sessionStorage.getItem('cam_and_mic_permission_for_ss') === 'allowed') {
						sessionStorage.setItem('cam_and_mic_permission_for_ss', `allowed`);
						addVideoStreamToVideoTag('capture-user-snapshots');
					}
				})
				.catch(function (err) {
					setAntiCheatStates({ ...antiCheatStates, media_devices: { isEnabled: false, message: 'Mic and Webcam issue' } });
				});
		}
		else {
			setAntiCheatStates({ ...antiCheatStates, media_devices: { isEnabled: false, message: 'Media Devices' } });
		}

	};

	useEffect(() => {

		always_check_interval = setInterval(async () => {
			if (sessionStorage.getItem('check_cam_and_mic_permission') === 'job_started') {
				clearInterval(check_cam_permission_when_active_interval);
				checkMediaPermission();
			}

		}, 1000);

		return () => {
			clearInterval(always_check_interval);
		};

	}, [sessionStorage.getItem('check_cam_and_mic_permission'), assessment?.current_assessment?.anti_cheat_settings]);

	useEffect(() => {

		check_cam_permission_when_active_interval = setInterval(async () => {
			if ((sessionStorage.getItem('cam_and_mic_permission_for_ss') !== 'blocked' && sessionStorage.getItem('cam_and_mic_permission_for_ss') !== 'undefined')
				&& sessionStorage.getItem('check_cam_and_mic_permission') !== 'job_started') {

				check_cam_permission_when_active();

			}

		}, 3000);

		return () => {
			clearInterval(check_cam_permission_when_active_interval);
		};

	}, [sessionStorage.getItem('cam_and_mic_permission_for_ss'), sessionStorage.getItem('check_cam_and_mic_permission')]);

	useEffect(() => {
		if (sessionStorage.getItem('cam_and_mic_permission_for_ss') === 'allowed' || sessionStorage.getItem('cam_and_mic_permission_for_ss') === 'connection_lost') {
			addVideoStreamToVideoTag('capture-user-snapshots');
		}
	}, [assessment?.current_assessment?.active_test, sessionStorage.getItem('cam_and_mic_permission_for_ss')]);


	useEffect(() => {
		capture_pic_interval = setInterval(async () => {
			if (sessionStorage.getItem('cam_and_mic_permission_for_ss') !== 'blocked'
				&& sessionStorage.getItem('cam_and_mic_permission_for_ss') !== 'undefined'
				&& assessment?.current_assessment?.anti_cheat_settings?.snapshot_settings?.active && isVideoStreamActive) {
				capturePic();
			}
		}, 30000);


		return () => {
			clearInterval(capture_pic_interval);
		};
	}, [sessionStorage.getItem('cam_and_mic_permission_for_ss'), assessment?.current_assessment?.anti_cheat_settings, isVideoStreamActive]);


	useEffect(() => {

		check_media_stream_active = setInterval(async () => {

			if (sessionStorage.getItem('cam_and_mic_permission_for_ss') !== 'blocked'
				&& sessionStorage.getItem('cam_and_mic_permission_for_ss') !== 'undefined'
				&& assessment?.current_assessment?.anti_cheat_settings?.snapshot_settings?.active) {

				navigator.mediaDevices.enumerateDevices()
					.then(async devices => {
						const cameras = devices.filter(device => (device.kind === 'videoinput' && device.label !== ''));
						if (cameras.length > 0) {
							addVideoStreamToVideoTag('capture-user-snapshots');
							setIsVideoStreamActive(true);
						}
						else {
							setIsVideoStreamActive(false);
						}

					})
					.catch(error => {
						setIsVideoStreamActive(false);
					});
			}
		}, 2000);


		return () => {
			clearInterval(check_media_stream_active);
		};
	}, [sessionStorage.getItem('cam_and_mic_permission_for_ss'), assessment?.current_assessment?.anti_cheat_settings]);

	useEffect(() => {

		const videoElement = document.getElementById('capture-user-snapshots');
		if (sessionStorage.getItem('cameraStreamID') && videoElement) {
			if (media_stream) {
				media_stream.getTracks().forEach((track) => {
					media_stream.getTracks().forEach((track) => {
						track.stop();
					});
				});
			}
			media_stream = videoElement.srcObject;
		}

	}, [sessionStorage.getItem('cameraStreamID')]);

	useEffect(() => {
		return () => {
			if (media_stream) {
				media_stream.getTracks().forEach((track) => {
					track.stop();
				});
			}
		};
	}, []);

	useEffect(() => {
		const handleMouseOut = (e) => {
			if (!sessionStorage.getItem('cam_and_mic_ts')) {
				if (e.clientY <= 0) {
					const qs = new URLSearchParams({ is_left_screen: true });
					dispatch(takeCandidateScreenEvent({ assess_id: params.id, qs: qs.toString() }));
				}
			}
		};
		window.addEventListener('mouseout', handleMouseOut);
		return () => {
			window.removeEventListener('mouseout', handleMouseOut);
		};
	}, []);

	const enterFullScreen = () => {
		const element = document.body;
		if (element.requestFullscreen) {
			element.requestFullscreen();
		} else if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
		} else if (element.webkitRequestFullscreen) {
			element.webkitRequestFullscreen();
		}
	};

	const exitFullscreen = () => {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}
	};

	useEffect(() => {
		document.addEventListener('fullscreenchange', (event) => {
			if (!sessionStorage.getItem('cam_and_mic_ts')) {
				if (document.fullscreenElement) {
					console.log('');
				} else {
					const qs = new URLSearchParams({ is_exited_full_screen: true });
					dispatch(takeCandidateScreenEvent({ assess_id: params.id, qs: qs.toString() }));
				}
			}
		});

		if (document.fullscreenElement) {
			return () => {
				exitFullscreen();
			};
		}

	}, []);

	return (
		<SessionContextWrapper>
			<AntiCheatContext.Provider value={{ antiCheatStates, setAntiCheatStates }}>
				<video id='capture-user-snapshots' width="640" height="480" autoPlay style={{ position: 'absolute', zIndex: '-99999', top: '-99999px', left: '-99999px' }}></video>
				<canvas id="capture-user-snapshots-canvas" style={{ display: 'none' }} width="640" height="480"></canvas>
				{
					(
						((assessment?.current_assessment?.anti_cheat_settings?.snapshot_settings?.required) && sessionStorage.getItem('cam_and_mic_permission_for_ss') === 'undefined')
						&& assessment?.current_assessment?.active_test?.test?.test_type !== TestType.QUALIFYING_TEST &&
						((assessment?.current_assessment?.welcome_video !== null && assessment?.current_assessment?.has_watched_ww) || assessment?.current_assessment?.welcome_video === null)
					)

						?
						<div className='candidate-assessment-flow-container'>
							<div className='candidate-assment-flow-wrapper'>
								<VideoTestingView
									isRequired={assessment?.current_assessment?.anti_cheat_settings?.snapshot_settings?.required}
									content={["This assessment requires enabling your camera/webcam for monitoring. Testfuse's snapshot feature captures periodic images of you, upholding the integrity of the evaluation process by ensuring a consistent and cheat-free testing environment.",
										"Utilizing the video recorder below, you can quickly record and watch a trial video. Make sure that you're facing towards the camera to ensure both audio and video are captured correctly. No need to fret; these practice videos won't be stored nor shown anywhere else!"
									]}
									handleCamTested={(status) => {

										if (status === 'allowed' && assessment?.current_assessment?.anti_cheat_settings?.snapshot_settings?.active) {
											addVideoStreamToVideoTag('capture-user-snapshots');
										}

										sessionStorage.setItem('cam_and_mic_permission_for_ss', `${status}`);

										if (assessment?.current_assessment?.anti_cheat_settings?.snapshot_settings?.required) {
											sessionStorage.setItem('check_cam_and_mic_permission', `job_started`);
										}

										setAntiCheatStates({ ...antiCheatStates, media_devices: { isEnabled: true, message: '' } });
										if (browserInfo('Firefox', null)) {
											enterFullScreen();
										}
									}}
								/>
							</div>
						</div>
						:

						<>
							{
								(
									((assessment?.current_assessment?.anti_cheat_settings?.snapshot_settings?.active) && sessionStorage.getItem('cam_and_mic_permission_for_ss') === 'undefined') &&
									assessment?.current_assessment?.active_test?.test?.test_type !== TestType.QUALIFYING_TEST &&
									((assessment?.current_assessment?.welcome_video !== null && assessment?.current_assessment?.has_watched_ww) || assessment?.current_assessment?.welcome_video === null)
								)
									?
									<div className='candidate-assessment-flow-container'>
										<div className='candidate-assment-flow-wrapper'>
											<VideoTestingView
												isRequired={assessment?.current_assessment?.anti_cheat_settings?.snapshot_settings?.required}
												content={["The recruiter has requested that you enable your camera/webcam for monitoring. Testfuse's snapshot feature captures periodic images of you, upholding the integrity of the evaluation process by ensuring a consistent and cheat-free testing environment.",
													"Utilizing the video recorder below, you can quickly record and watch a trial video. Make sure that you’re facing towards the camera to ensure both audio and visuals are captured correctly. No need to fret; these practice videos won't be stored nor shown anywhere else!"
												]}
												handleCamTested={(status) => {
													if (status === 'allowed' && assessment?.current_assessment?.anti_cheat_settings?.snapshot_settings?.active) {
														addVideoStreamToVideoTag('capture-user-snapshots');
													}

													sessionStorage.setItem('cam_and_mic_permission_for_ss', `${status}`);

													if (assessment?.current_assessment?.anti_cheat_settings?.snapshot_settings?.required) {
														sessionStorage.setItem('check_cam_and_mic_permission', `job_started`);
													}

													setAntiCheatStates({ ...antiCheatStates, media_devices: { isEnabled: true, message: '' } });
													if (browserInfo('Firefox', null)) {
														enterFullScreen();
													}
												}}
											/>
										</div>
									</div>
									:
									<>
										{
											<CustomModal
												open={antiCheatStates.media_devices.isEnabled === false}
												varrient='alert-warning-modal'
												title='Webcam Disabled!'
												description={`Testfuse has lost the access to the webcam. Kindly retry or go back to webcam setup to continue the assessment.`}
												dangerAlert={true}
												handleCloseModal={() => {
													retryToAllowMediaPermission();
												}}
												cancelBtnTitle={'Retry'}
												confirmBtnTitle={'Webcam Setup'}
												btnWidth='max-content'
												onConfirm={async () => {
													sessionStorage.setItem('check_cam_and_mic_permission', 'undefined');
													sessionStorage.setItem('cam_and_mic_permission_for_ss', 'undefined');
													sessionStorage.setItem('cam_and_mic_permission_for_test', 'undefined');
													setAntiCheatStates({ ...antiCheatStates, media_devices: { isEnabled: undefined, message: 'Webcam issue' } });
													clearInterval(always_check_interval);
													clearInterval(capture_pic_interval);
												}}
											/>
										}
										{
											props.children
										}
									</>
							}


						</>
				}

			</AntiCheatContext.Provider>
		</SessionContextWrapper>
	);
}
