import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const listPublicTestpacks = createAsyncThunk(
	'publicTestPack/listPublicTestPacks',
	async (_, { rejectWithValue, dispatch }) => {
		try {

			const request = await instance.request({
				url: `/v1/test_packs/public`,
				method: 'GET',
			});

			return request?.data;

		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	},
);

export const getPublicTestPack = createAsyncThunk(
	'publicTestPack/getPublicTestPack',
	async ({ id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: `/v1/test_packs/public/${id}`,
				method: 'GET',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const createExecutedId = createAsyncThunk(
	'publicTestPack/createExecutedId',
	async ({ data }, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: `/v1/test_packs/public/exec_preview/`,
				method: 'POST',
				data,
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const executeResult = createAsyncThunk(
	'publicTestPack/executeResult',
	async ({ id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: `/v1/test_packs/public/exec_result/${id}`,
				method: 'GET',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const resetExecResultState = createAsyncThunk(
	'publicTestPack/resetExecResultState',
	async (_, { rejectWithValue, dispatch }) => {
		try {

			return _;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);
