import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const listCandidates = createAsyncThunk(
	'userProfile/getUserProfiles/getCandidates',
	async (_, { rejectWithValue, dispatch }) => {
		try {

			const request = await secure_instance.request({
				url: `/v1/user_profiles/candidates`,
				method: 'GET',
			});

			return request?.data;

		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	}
);

export const getCandidate = createAsyncThunk(
	'userProfile/getUserProfile/getCandidate',
	async ({ id, query }, { rejectWithValue, dispatch }) => {
		try {

			const request = await secure_instance.request({
				url: `/v1/user_profiles/${id}${query ? query : ''}`,
				method: 'GET',
			});

			return request?.data;

		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	}
);

