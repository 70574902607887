import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createAssessment } from '../redux/thunks/Assessment';
import { UserRole, fullBlock, inviteBlock } from '../utils/Constants';
import { PathName } from '../utils/routes';

export default function ProcessAssessmentInvite() {

	const [isReady, setIsReady] = useState(false);
	const search = useLocation().search;
	const inviteId = useMemo(() => new URLSearchParams(search).get('invite_id'), [search]);

	const invitedRef = useRef();

	const dispatch = useDispatch();
	const auth = useSelector((state) => state.authReducer);
	const assessment = useSelector((state) => state.assessmentReducer);
	console.log(assessment,'assessment');

	useEffect(() => {

		if (!invitedRef.current) {
			invitedRef.current = '1';
			if (auth.data?.role?.name === UserRole.CANDIDATE) {
				dispatch(createAssessment({ invite_id: inviteId }));
			}
		}

		setIsReady(true);

	}, []);

	if (!isReady) {
		return <></>;
	}

	if (auth.data?.role?.name !== UserRole.CANDIDATE) {
		window.location.replace(PathName.MAIN);
	}

	if (assessment.processing) {

		return (
			<div className='d-flex' style={{ height: '100vh' }}>
				<div className='processing-indicator-wrapper'>
					<div className="processing-indicator primary" style={{ width: '100%', height: '100%' }} />
				</div>
			</div>
		);

	}

	// window.location.replace(assessment?.current_assessment?.id ? `${PathName.ASSESSMENTS}/${assessment?.current_assessment?.id}`: (assessment?.current_assessment.status === 'PENDING' && ((inviteBlock.includes(assessment?.current_assessment?.tenant?.status) || fullBlock.includes(assessment?.current_assessment?.tenant?.status) || assessment?.current_assessment?.tenant?.status === 'pre_onboard'))) ? PathName.UNAVALABLE_ASSESSMENT : PathName.MAIN);

}
