import '../assets/styles/App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
// import ProtectedRoute from "../components/ProtectedRoute";

//Components
// import Home from "./pages/Home";
// import SignIn from "../views/SignIn/SignIn";
// import SignUp from "../views/SignUp/SignUp";
// import ForgotPassword from "../components/dashboard/ForgotPassword";
// import Onboarding from "./Onboarding/Onboarding";
// import Dashboard from "./Dashboard/DashboardContainer";
// import ResetPassword from "../components/dashboard/ResetPassword";
// import ReferenceForm from "./ReferenceForm";
// import PaymentInfo from "../components/dashboard/PaymentInfo/PaymentInfo";
// import AccountSuspended from "../components/AccountSuspended";
// import OAuthScreen from "../components/dashboard/OAuthScreen";
// import PricingScreen from "../views/PricingScreen/PricingScreen";
// import FeaturesPage from "./pages/FeaturesPage";
// import PricingPage from "./pages/PricingPage";
// import AssessmentInvite from "../components/dashboard/CandidateDashboard/AssessmentInvite";
// import CandidateAssessmentReport from "../components/dashboard/CandidateDetails/CandidateAssessmentReport";
// import PageNotFound from './PageNotFound';
import { DashboardRoutes, MainRoutes, PathName } from '../utils/routes';
import RoutesWrapper from './RoutesWrapper';
// import CandidateAssessFlow from './candidate-assessment-flow/CandidateAssessFlow';
import SideMenu from '../components/dashboard/SideMenu';
// import TestDetails from './dashboard/test-details/TestDetails';
import { useDispatch, useSelector } from 'react-redux';
import CommonContext from '../utils/contexts/CommonContext';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/react';
import ErrorPage from './ErrorPage';
import { setLatestErrorId } from '../redux/thunks/ErrorBoundary';
import IntercomWrapper from '../utils/contexts/IntercomWrapper';
import ChatwootComponent from '../utils/contexts/ChatwootWrapper';
import AlertBox from '../components/common/AlertBox';
import { candidateActivation } from '../redux/thunks/Auth';
import { Redirect, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { fullBlock } from '../utils/Constants';
import AssessmentPage from './AssessmentPage';
import RedirectIfNoMatch from '../utils/RedirectIfNoMatch';


function App() {
	const auth = useSelector((state) => state.authReducer);
	const alert = useSelector((state)=> state.alertReducer);
	const assessment = useSelector((state) => state.assessmentReducer);
	const dispatch = useDispatch();
	const history = useHistory();
	const baseChatwootUrl = window._env_.CHATWOOT_BASE_URL;
	const chatwootWebsiteToken = window._env_.CHATWOOT_WEB_ACCESS_TOKEN;

	const [isOnline, setIsOnline] = useState(true);

	useEffect(() => {
		const ping = () => {
			if (!navigator.onLine && alert?.message === '') {
				setIsOnline(false);
			} else {
				setIsOnline(true);
			}
		};
		const interval = setInterval(() => {
			ping();
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (auth.data.user_id && auth.data.email) {
			posthog.identify(`${auth.data.user_id}`, { email: auth.data.email });
			if (auth.data.tenant_id)
				posthog.group('tenant', String(auth.data.tenant_id));
			posthog.register({
				email: auth.data.email
			});
		}

		const streamID = sessionStorage.getItem('cameraStreamID');
		if (streamID) {
			const tracks = Array.from(navigator.mediaDevices.enumerateDevices()).filter(device => device.kind === 'videoinput' && device.deviceId === streamID);
			if (tracks.length > 0) {
				tracks[0].stop();
			}
			sessionStorage.removeItem('cameraStreamID');
		}
	}, [auth]);

	return (
		<div className='App'>
			<ChatwootComponent baseUrl={baseChatwootUrl} websiteToken={chatwootWebsiteToken}>
				{/* <IntercomWrapper> */}
				<CommonContext>
					<AlertBox alert={alert} />
					{!isOnline && <AlertBox varrient='check-online-alert'/>}
					<Router>
						<Switch>
							<Sentry.ErrorBoundary
								fallback={(error) => {
									console.info('error', error);
									dispatch(setLatestErrorId(error));
									return <ErrorPage />;
								}}
							>
								<RoutesWrapper>
									<div>
										{Object.values(MainRoutes).map((route, index) => {
											return (
												<React.Fragment key={index}>
													{route.pathname && (
														<Route
															exact={route.exact}
															path={route.pathname}
															component={route.component}
														/>
													)}
													{route.param_pathname && (
														<Route
															path={route.param_pathname}
															component={route.param_component}
														/>
													)}
												</React.Fragment>
											);
										})}
										<Route path="*" component={RedirectIfNoMatch} />


									</div>
								</RoutesWrapper>
							</Sentry.ErrorBoundary>
						</Switch>
					</Router>
				</CommonContext>
				{/* </IntercomWrapper> */}
			</ChatwootComponent>
		</div>
	);
}



export default App;