import React, { useEffect, useMemo, useState } from 'react';
import InputRange from 'react-input-range';


import { ReactComponent as FastForwardIcon } from '../../../../assets/icons/components/code-resposnse-result/fast-forward.svg';
import { ReactComponent as PlayIcon } from '../../../../assets/icons/components/code-resposnse-result/Frame 2608418.svg';
import { ReactComponent as PauseIcon } from '../../../../assets/icons/components/code-resposnse-result/Frame 2609002.svg';
import { ReactComponent as RewindIcon } from '../../../../assets/icons/components/code-resposnse-result/rewind.svg';
import { ReactComponent as SkipBackIcon } from '../../../../assets/icons/components/code-resposnse-result/skip-back.svg';
import { ReactComponent as SkipForwardIcon } from '../../../../assets/icons/components/code-resposnse-result/skip-forward.svg';

import { ReactComponent as PasteLabelIcon } from '../../../../assets/icons/components/code-resposnse-result/paste-label.svg';
import { ReactComponent as FailLabelIcon } from '../../../../assets/icons/components/code-resposnse-result/Group 13791.svg';
import { ReactComponent as PassLabelIcon } from '../../../../assets/icons/components/code-resposnse-result/Group 13792.svg';
import { ReactComponent as LabelPointer } from '../../../../assets/icons/components/code-resposnse-result/Ellipse 293.svg';
import { ReactComponent as EndResultIcon } from '../../../../assets/icons/components/code-resposnse-result/endresult.svg';
import { secondsToDhms } from '../../../../utils/utilities';

const SnapshotsPlayer = (props) => {

	const { snapshots } = props;

	const [rangeValue, setRangeValue] = useState(0);
	const [currentImage, setCurrentImage] = useState(null);
	const [startTimeLinePlayer, setStartTimeLinePlayer] = useState(false);
	const [timmer, setTimmer] = useState(null);


	useEffect(() => {
		if (!snapshots || snapshots?.length <= 0) {
			return;
		}

		const interval__of_5s_code_value = rangeValue - (rangeValue % 5);
		if (interval__of_5s_code_value / 5 < snapshots?.length) {
			// setCurrentImage(snapshots[(interval__of_5s_code_value / 5)].url);
			setCurrentImage(interval__of_5s_code_value / 5);
		}
		else {
			setCurrentImage(snapshots.length - 1);
		}

	}, [rangeValue]);

	useMemo(() => {
		if (startTimeLinePlayer) {
			setTimmer(
				setInterval(() => {
					if (rangeValue < parseInt(snapshots?.length * 5) && startTimeLinePlayer) {
						setRangeValue(rangeValue => rangeValue + 1);
					}
				}, 100)
			);
		}
		else {
			clearInterval(timmer);
		}

		return () => clearInterval(timmer);

	}, [startTimeLinePlayer]);


	useMemo(() => {

		if (rangeValue >= parseInt(snapshots?.length * 5)) {
			setStartTimeLinePlayer(false);
		}
	}, [rangeValue]);

	const moveToNextTag = () => {
		const interval__of_5s_code_value = rangeValue - (rangeValue % 5);
		let nextSnapIndex = -1;
		snapshots?.forEach((snapShot, index) => {
			if (index > (interval__of_5s_code_value / 5) && nextSnapIndex === -1) {
				nextSnapIndex = index;
			}
		});
		if (nextSnapIndex !== -1) {
			setRangeValue(nextSnapIndex * 5);
		}
	};

	const moveToPreviousTag = () => {
		const interval__of_5s_code_value = rangeValue - (rangeValue % 5);
		let nextSnapIndex = -1;
		snapshots?.forEach((snapShot, index) => {
			if (index < (interval__of_5s_code_value / 5)) {
				nextSnapIndex = index;
			}
		});
		if (nextSnapIndex !== -1) {
			setRangeValue(nextSnapIndex * 5);
		}
	};

	if (!snapshots || snapshots?.length <= 0) {
		return (
			<></>
		);
	}
	else {
		return (
			<div className='ss-video-player-container'>
				<div className='ss-video-image-container'>
					{
						snapshots.map((snapShot, snapShotIndex) => {
							return (
								<img key={snapShotIndex} style={{ display: snapShotIndex === currentImage ? 'flex' : 'none' }} className='w-100 h-100' src={snapShot.url} />
							);
						})
					}
					<div className='timmer-view'>
						<span className='body-3' style={{ color: '#392D69' }}>{secondsToDhms((rangeValue * 6))} / <span>{secondsToDhms((snapshots?.length * 5) * 6)}</span></span>
					</div>
				</div>
				<div className='ss-player-timeline'>
					{
						snapshots?.map((shotshot, snapIndex) => {
							if (snapIndex > 0) {
								return (
									<div className='timeline-label' key={snapIndex} style={{ left: `${((100 / parseInt(snapshots?.length * 5)) * ((snapIndex) * 5))}%` }}>
										<LabelPointer className={`${((snapIndex) * 5) < rangeValue ? 'unvisited-label' : 'visited-label'} pointer`}
											onClick={() => { setRangeValue((snapIndex) * 5); }}
										/>
									</div>
								);
							}
						})
					}
					<InputRange
						maxValue={parseInt((snapshots?.length * 5))}
						minValue={0}
						value={rangeValue}
						onChange={(value) => {
							if (value <= parseInt(snapshots?.length * 5)) {
								setRangeValue(value);
							}
						}}
					/>

					<div className='w-100 d-flex justify-content-center align-items-center' style={{ marginTop: '24px', gap: '15px' }}>
						<RewindIcon className='pointer' onClick={() => { setRangeValue(0); }} />
						<SkipBackIcon className='pointer' onClick={moveToPreviousTag} />
						<PlayIcon className='pointer' onClick={() => { setStartTimeLinePlayer(true); }} />
						<PauseIcon className='pointer' onClick={() => { setStartTimeLinePlayer(false); }} />
						<SkipForwardIcon className='pointer' onClick={moveToNextTag} />
						<FastForwardIcon className='pointer' onClick={() => { setRangeValue((snapshots.length) * 5); }} />
					</div>
				</div>
			</div>
		);
	}
};

export default SnapshotsPlayer;