import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const getNotification = createAsyncThunk(
	'notification/getNotification',
	async ({ page, limit, status = '' }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/notifications/`,
				method: 'GET',
				params: { page, limit, status },
			});
			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const patchNotificationStatus = createAsyncThunk(
	'notification/patchNotificationStatus',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/notifications/status`,
				method: 'PATCH',
				data: data
			});
			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const patchMarkAllRead = createAsyncThunk(
	'notification/patchMarkAllRead',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/notifications/mark-all`,
				method: 'PATCH',
				data: data
			});
			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const postEmailPreview = createAsyncThunk(
	'notification/postEmailPreview',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/templates/preview_email_template`,
				method: 'POST',
				data: data
			});
			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const getEmailTemplate = createAsyncThunk(
	'notification/getEmailTemplate',
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/templates/email`,
				method: 'GET',
				params: {
					event_name: params.event_name
				}
			});
			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);
