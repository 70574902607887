import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import OnboardingBox from './../components/OnboardingBox';
import Input from './../components/sub-component/Input';
import Button from './../components/sub-component/Button';
import { Redirect, useParams } from 'react-router-dom';
import { PathName } from '../utils/routes';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../redux/thunks/Auth';
import AlertBox from '../components/common/AlertBox';
import { isPasswordValid } from '../utils/utilities';
import { useMediaQuery } from '@mui/material';
import { ReactComponent as ErrorIcon } from '../assets/icons/basic/x-circle-error.svg';
import styled from '@emotion/styled';


function ResetPassword() {
	const history = useHistory();

	const params = useParams();

	const [values, setValues] = useState({ password: '', confirmPassword: '' });
	const [confirmPasswordError, setConfirmPasswordError] = useState('');
	const [reveal, setReveal] = useState({
		revealPassword: false,
		revealConfirmPassword: false,
	});

	const [processing, setProcessing] = useState(false);
	const [alert, setAlert] = useState(null);

	const dispatch = useDispatch();
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	const shouldRevealPassword = (revealOf) => {
		setReveal({ ...reveal, [revealOf]: !reveal[revealOf] });
	};
	const handleChange = (valueToChange, e) => {
		setValues({ ...values, [valueToChange]: e.target.value });
	};
	const handleSubmit = (e) => {
		e.preventDefault();

		if (values.password !== values.confirmPassword) {
			setAlert(
				<AlertBox description={'Passwords do not match!'} color='danger' />
			);
			return;
		}

		if (!isPasswordValid(values.password)) {
			setAlert(
				<AlertBox description={'Password should contain atleast 10 characters and 1 special character or a digit'} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
			);
			return;
		}

		(async () => {

			setProcessing(true);
			const dispatched = await dispatch(resetPassword({ reset_token: params.token, password: values.password }));
			setProcessing(false);

			if (dispatched.error) {
				setAlert(
					<AlertBox description={`${dispatched.payload}!`} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
				);
				return;
			}

			setAlert(
				<AlertBox description={'Your password has been reset!'} color="success" title="Success" onCloseAlert={() => { setAlert(null); }} />
			);

			setTimeout(() => window.location.replace(PathName.LOGIN), 1000);

		})();

	};

	if (!params.token) {
		<Redirect to={PathName.LOGIN} />;
	}

	return (
		<>
			<OnboardingBox>
				<div
					className="d-flex flex-column login-form-container"
					// style={{ maxWidth: '450px' }}
				>
					<div className="form">
						<div className={`${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-3'} bold`}>Reset Password</div>
						<div className={`link-text body-${isOnMobileScreen || isOnTabletScreen ? '1' : '2'} dark-50` } style={{lineHeight:'24px'}}>
							Your new password must be different from previously used passwords
						</div>
						<div>
							<form onSubmit={handleSubmit}>
								<div className="login-input-group">
									<form onSubmit={handleSubmit}></form>
									{confirmPasswordError ? 	<div className="error-text">
										<div style={{width:'30px'}}>
											<ErrorIcon/>
										</div>
										{confirmPasswordError}
									</div> : 
										<></> 
									}
									<div className="d-flex flex-column mt-4">
										<Input
											placeholder="New Password"
											onChange={(e) => {
												handleChange('password', e);
											}}
											type={reveal.revealPassword ? 'text' : 'password'}
											value={values.password}
											onBoardingInput
											enableRevealIcon
											// varrient={'primary'}
											revealPasswordClick={() => {
												shouldRevealPassword('revealPassword');
											}}
										/>
									</div>
									<div className="d-flex flex-column mt-4">
										<Input
											placeholder="Confirm Password"
											onChange={(e) => {
												handleChange('confirmPassword', e);
											}}
											type={reveal.revealConfirmPassword ? 'text' : 'password'}
											value={values.confirmPassword}
											// varrient={'primary'}
											onBoardingInput
											className={confirmPasswordError ? 'error-input' : ''}
											enableRevealIcon
											revealPasswordClick={() => {
												shouldRevealPassword('revealConfirmPassword');
											}}
										/>
									</div>
									<div className='mt-4'>
										{alert}
									</div>
									<div className="submit-button mb-3">
										<Button
											themeRectangleButton
											// onClick={handleSubmit}
											processing={processing}
											type={'submit'}
											title={'Set  New  Password'}
											style={{ height: '40px' }}
										/>
									</div>
									<div className="body-2 text-center primary-text w-100 pointer"
										onClick={() => {
											history.push('/login');
										}}
									>
										{'<'} Back to login
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</OnboardingBox>
		</>
	);
}

export default ResetPassword;
