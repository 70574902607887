import React, { useEffect, useState } from 'react';
import AssessmentTestInfoWrapper from '../../../../../components/candidate-assessment-flow/AssessmentTestInfoWrapper';
import { useReactMediaRecorder } from 'react-media-recorder';
import VideoRecorder from '../../../../../components/candidate-assessment-flow/VideoRecorder';
import Button from '../../../../../components/sub-component/Button';
import { ReactComponent as MicIcon } from '../../../../../assets/icons/candidate-assessment/mic.svg';
import { ReactComponent as CamIcon } from '../../../../../assets/icons/candidate-assessment/camera.svg';
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/pricing/alert-circle.svg';

let check_video_mic_permission_interval;

export default function VideoTestingView(props) {
	const [alert, setAlert] = useState(true);
	const { status, startRecording, stopRecording, mediaBlobUrl } =
		useReactMediaRecorder({
			video: true,
			type: 'video/mp4',
			askPermissionOnMount: true,
			audio: true,
		});

	const [micEnabled, setMicEnabled] = useState(false);
	const [videoEnabled, setVideoEnabled] = useState(false);
	const [mediaDeviceIssue, setMediaDeviceIssue] = useState(false);


	const [audioDevices, setAudioDevices] = useState([]);
	const [videoDevices, setVideoDevices] = useState([]);

	const handlePermissionGranted = () => {
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			setMediaDeviceIssue(false);
			navigator.mediaDevices.getUserMedia({ audio: true })
				.then(function (stream) {
					setMicEnabled(true);
					navigator.mediaDevices.enumerateDevices().then((_devices) => {
						const _audioDevices = _devices.filter((device, _) => {
							return (device.kind === 'audioinput');
						});
						setAudioDevices([..._audioDevices]);
					});

				})
				.catch(function (err) {
					setMicEnabled(false);
				});

			navigator.mediaDevices.getUserMedia({ video: true })
				.then(function (stream) {
					setVideoEnabled(true);
					navigator.mediaDevices.enumerateDevices().then((_devices) => {
						const _videoDevices = _devices.filter((device, _) => {
							return device.kind === 'videoinput';
						});
						setVideoDevices([..._videoDevices]);
					});
				})
				.catch(function (err) {
					setVideoEnabled(false);
				});
		}
		else {
			setMediaDeviceIssue(true);
		}
	};

	useEffect(() => {
		check_video_mic_permission_interval = setInterval(async () => {
			handlePermissionGranted();
		}, 1000);

		return () => {
			clearInterval(check_video_mic_permission_interval);
		};
	}, []);

	useEffect(() => {
		if (!sessionStorage.getItem('cam_and_mic_ts')) {
			sessionStorage.setItem('cam_and_mic_ts', 'processing');
		}
		return () => {
			sessionStorage.removeItem('cam_and_mic_ts');
		};
	}, []);

	return (
		<div className='video-testing-container'>
			{/* <AssessmentTestInfoWrapper
				className='video-testing-container'
				title='Camera and Mic permissions'
				disableSecondaryBtn
				disablePrimaryBtn={props.isRequired ? !(videoEnabled && micEnabled && !(mediaDeviceIssue)) : false}
				primaryBtnTitle='Start'
				onPrimaryBtnClick={() => { props.handleCamTested((videoEnabled === false || micEnabled === false || mediaDeviceIssue === true) ? 'blocked' : 'allowed'); }}
			> */}
			<span className='headline-4 dark-100 text-start'>Camera and Mic permissions</span>
			<div className='w-100 d-flex flex-column' style={{gap: '12px'}}>
				{
					props.content.map((text, _) => {
						return (
							<span key={_} className='body-2 dark-100 text-start'>{text}</span>
						);
					})
				}
			</div>
			<div className='w-100 d-flex flex-column' style={{gap: '24px'}}>
				{alert && (
					<div className='error-message'>
						<span><AlertIcon style={{width: '24px', height: '24px'}} /></span>
						<span className='body-2 danger-text text-start'>Please grant access to your camera and microphone!</span>
					</div>
				)}
				<div className='CS-VR-vide-text-info-container'>
					<div className='CS-VR-left-container'>
						<div className='d-flex flex-column align-items-start justify-content-end'>
							<VideoRecorder
								recordingDuration={60}
								// height='395px'
								variant={'testing'}
							/>
						</div>
					</div>
					<div className='CS-VR-left-container'>
						<div className='webcam-instructions'>
							<span className='subtitle-2 dark-100'>
								Trouble with your webcam?
							</span>
							<ul className='body-2 dark-100 text-start'>
								<li>
									Ensure you have granted permission for your browser to access
									your camera.
								</li>
								<li>Ensure you are using a supported browser.</li>
								<li>
									If you have multiple camera devices, ensure you have given
									your browser and our website permission to use the right
									device.
								</li>
								<li>
									Try launching the assessment in incognito mode ort in a
									private window.
								</li>
								<li>
									Ensure your camera drivers and web browser are up to date.
								</li>
								<li>
									Restart your device and try accessing the assessment again
									using the link in the invitation email.
								</li>
							</ul>
						</div>
						<div className='webcam-instructions'>
							<div className='d-flex flex-column align-items-start justify-content-end'>
								<div className='d-flex align-items-center justify-content-start'>
									<CamIcon style={{ marginRight: '6px' }} />
									<span className='body-1 dark-100'>Camera:</span>
								</div>
								{videoDevices.length > 0 ? (
									<ul className='d-flex flex-column align-items-start'>
										{videoDevices.map((device, _) => {
											return (
												<li
													key={_}
													className='body-2 dark-50'
													style={{ marginTop: '6px' }}
												>
													{device.label}
												</li>
											);
										})}
									</ul>
								) : (
									<span
										className='body-2 dark-50'
										style={{ marginTop: '6px' }}
									>
										No Device Found!
									</span>
								)}
							</div>
							<div
								className='d-flex flex-column align-items-start justify-content-end'
								style={{ marginTop: '12px' }}
							>
								<div className='d-flex align-items-center justify-content-start'>
									<MicIcon style={{ marginRight: '6px' }} />
									<span className='body-1 dark-100'>Microphone:</span>
								</div>
								{audioDevices.length > 0 ? (
									<ul className='d-flex flex-column align-items-start'>
										{audioDevices.map((device, _) => {
											return (
												<li
													key={_}
													className='body-2 dark-50'
													style={{ marginTop: '6px' }}
												>
													{device.label}
												</li>
											);
										})}
									</ul>
								) : (
									<span
										className='body-2 dark-50'
										style={{ marginTop: '6px' }}
									>
										No Device Found!
									</span>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='w-100 d-flex align-items-end justify-content-end'>
				<Button 
					disabled={props.isRequired ? !(videoEnabled && micEnabled && !(mediaDeviceIssue)) : false}
					btn='ragular-btn'
					varrient='primary'
					style={{ width: 'max-content', height: '40px' }}
					title={'Start'} 
					onClick={() => { props.handleCamTested((videoEnabled === false || micEnabled === false || mediaDeviceIssue === true) ? 'blocked' : 'allowed'); }} 
				/>
			</div>
			{/* </AssessmentTestInfoWrapper> */}
		</div>
	);
}
