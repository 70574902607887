import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import ProgressBar from '../../../common/ProgressBar';
import CodingTestTimeline from './CodingTestTimeline';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/components/code-resposnse-result/left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/components/code-resposnse-result/right.svg';

import hljs from 'highlight.js';
import { ExecutedSubmissionExpandedView, ExecutedTask } from './ExecutedSubmissionExpandedView';
import { CommonContext } from '../../../../utils/contexts/CommonContext';

export default function CodingTestDetails(props) {

	const modules = {
		syntax: {
			highlight: (text) => hljs.highlightAuto(text).value,
		},
	};

	const assessment = useSelector((state) => state.assessmentReducer);
	const { selectedResponse } = props;

	const { commonValues, setCommonValues } = useContext(CommonContext);
	const [questionNo, setQuestionNo] = useState(0);

	const [currSnapShot, setCurrSnapShot] = useState(null);

	const [selectedSubmission, setSelectedSubmission] = useState({
		index: -1,
		submission: null
	});

	const QuestionTextMemo = useMemo(() => {
		if (!assessment?.code_snapshot?.responses[questionNo]) return;

		return (
			<ReactQuill
				theme='bubble'
				readOnly
				value={assessment?.code_snapshot?.responses[questionNo]?.text}
			/>
		);
	}, [assessment?.code_snapshot, questionNo]);

	useEffect(() => {
		if (assessment?.code_snapshot) {
			props.getSelectedResponse(assessment?.code_snapshot?.responses[questionNo]);
		}
		return (
			setQuestionNo(0)
		);
	}, [assessment?.code_snapshot, questionNo]);

	useMemo(() => {
		setQuestionNo(0);
	}, [commonValues]);


	return (
		<div className='w-100'>
			{

				<div className='coding-test-response-container'>
					<div className='w-100 d-flex flex-column' style={{ marginBottom: '8px' }}>
						<div className='w-100 d-flex justify-content-between align-items-center mb-3'>
							<span className='subtitle-2 dark-100'>Question No {questionNo + 1}</span>
							<div className='d-flex align-items-center' style={{ gap: '11px' }}>
								<ArrowLeftIcon className='pointer' style={{width: '16px', height: '16px'}} onClick={() => { if (questionNo > 0) { setQuestionNo(questionNo - 1); } }} />
								<span className='body-2 medium black-500'><span className='primary-text'>{questionNo + 1}</span> of {assessment?.code_snapshot?.responses?.length}</span>
								<ArrowRightIcon className='pointer' style={{width: '16px', height: '16px'}} onClick={() => { if ((questionNo + 1) < assessment?.code_snapshot?.responses?.length) { setQuestionNo(questionNo + 1); } }} />
							</div>
						</div>
						<div className='coding-test-question-container'>
							<div className='body-1 text-start w-full mb-3 dark-100'>Statement</div>
							<span className='body 2 dark-100'>
								{QuestionTextMemo}
							</span>
						</div>

						
					</div>

					{/* solution */}
					<div className='w-100 d-flex flex-column' style={{padding:'2em' ,background:'#F6F5F8',borderRadius:'24px'}}>
					
						<span className='body-1 text-start dark-100'>Solution:</span>
						{
							selectedResponse?.status === 'SKIPPED' ?
								<span className='body-2 danger-text text-start' style={{marginBottom: '48px'}}>No solution to display! This question was skipped.</span>
								:
								selectedResponse?.status === 'EXPIRED' ?
									<span className='body-2 danger-text text-start' style={{marginBottom: '48px'}}>No solution to display! This question was expired.</span>
									:
									<div className='coding-test-timeline-container'>
										<CodingTestTimeline
											onChangeCodeSnapshot={(codeSnapshot) => { setCurrSnapShot(codeSnapshot); setSelectedSubmission({ submission: null, index: -1 }); }}
											response={selectedResponse}
											codeSnapshots={assessment?.code_snapshot?.snapshots?.filter((snapshot, _) => { return (snapshot?.ques_id === selectedResponse?.id); })} />
										<div className='coding-result-event-container'>
											<div className='d-flex w-100 justify-content-between align-items-center'>
												<span className='headline-small semibold' style={{ color: '#000000' }}>Event:</span>
												<button className='body-3 black-500' style={{width: 'auto', height: 'auto', padding: '4px 8px'}}>{currSnapShot?.type === 'autosave' ? 'Auto Save' : currSnapShot?.type === 'copypaste' ? 'Copy Paste' : 'Verify'}</button>
											</div>
											{
												currSnapShot?.type === 'verify' &&
												<>
													<span className='body-3 black-700' style={{ fontWeight: '500' }}>Validation Result:
														<span className='body-3' style={{ fontWeight: '400', color: currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (currSnapShot?.final ? 'validation' : 'test')); })[0]?.submissions?.filter((submission, _) => { return (!submission.has_passed); }).length > 0 ? '#FC4848' : '#00D69A' }}>
															{
																currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (currSnapShot?.final ? 'validation' : 'test')); })[0]?.submissions?.filter((submission, _) => { return (!submission.has_passed); }).length > 0
																	?
																	' Task failed'
																	:
																	' Task executed successfully!'
															}


														</span></span>
													<div className='executed-tasks-container'>
														{
															currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (currSnapShot?.final ? 'validation' : 'test')); }).length > 0 &&
															currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (currSnapShot?.final ? 'validation' : 'test')); })[0]?.submissions &&
															currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (currSnapShot?.final ? 'validation' : 'test')); })[0]?.submissions.map((submission, _) => {
																const isFinalSnapshot = currSnapShot?.final;
																const length = currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (isFinalSnapshot ? 'validation' : 'test')); })[0]?.submissions.length;

																return (
																	<div key={_} className='d-flex flex-column'>
																		<ExecutedTask key={_} submission={submission} isLastIndex={_ === (length - 1)} onClick={() => { setSelectedSubmission({ submission: submission, index: _ }); }} />
																		{
																			(selectedSubmission.index != -1 && selectedSubmission.index === _ && selectedSubmission.submission) &&
																			<ExecutedSubmissionExpandedView
																				submission={selectedSubmission.submission}
																				token={currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (isFinalSnapshot ? 'validation' : 'test')); })[0].tokens[selectedSubmission.index]}
																				submissionIndex={selectedSubmission.index}
																			/>
																		}
																	</div>

																);
															})
														}
													</div>
												</>
											}

										</div>
									</div>
						}
					</div>
				

				</div>
			}

		</div>
	);
}
