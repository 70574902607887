import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';
import * as yup from 'yup';
import axios from 'axios';
const cancle_tocken = axios.CancelToken;

export const createAssessSpec = createAsyncThunk(
	'assessSpec/createAssessSpec',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/assess_specs/',
				method: 'POST',
				data: data,
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const listAssessSpec = createAsyncThunk(
	'assessSpec/listAssessSpec',
	async ({ query }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assess_specs/${query ? query : ''}`,
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

let listAssessSpecApiCancle = null;
export const listAssessSpecWithQueryParam = createAsyncThunk(
	'assessSpec/listAssessSpec',
	async ({ data }, { rejectWithValue, dispatch }) => {
		if (listAssessSpecApiCancle) {
			listAssessSpecApiCancle();
		}
		try {
			const request = await secure_instance.request({
				url: `/v1/assess_specs/?page=${data?.page}&limit=${data?.limit}&sort_by=${data?.sort_by}&sort_direction=${data?.sort_direction}&status=${data?.status}&search=${data?.search}`,
				method: 'GET',
				cancelToken: new cancle_tocken((c) => {
					listAssessSpecApiCancle = c;
				})
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const getAssessSpecStatsData = createAsyncThunk(
	'assessSpec/getAssessSpecStatsData',
	async ({ status }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assess_specs/stats?status=${status}`,
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const getAssessSpec = createAsyncThunk(
	'assessSpec/getAssessSpec',
	async ({ id, query }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assess_specs/${id}${query ? query : ''}`,
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const archiveAssessSpec = createAsyncThunk(
	'assessSpec/archiveAssessSpec',
	async ({ id, data }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assess_specs/${id}`,
				method: 'PATCH',
				data: {
					status: 'ARCHIVED'
				}
			});
			return data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const deleteAssessSpec = createAsyncThunk(
	'TestPacks/deleteAssessSpec',
	async ({ id, data }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assess_specs/${id}`,
				method: 'DELETE',
			});
			return data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const updateAssessSpec = createAsyncThunk(
	'assessSpec/updateAssessSpec',
	async ({ id, data }, { rejectWithValue, dispatch }) => {
		try {
			const schema = yup.object().shape({
				title: yup.string().required("'title' is required"),
				objective_test_duration: yup.number(),

				qualifying_questions: yup
					.array()
					.notRequired()
					.of(
						yup.object().shape({
							statement: yup.string().required(),
							type: yup
								.string()
								.required()
								.equals(['mcq', 'boolean', 'numeric']),
							correct_answer: yup.mixed().required(),
						})
					),

				sections: yup
					.array()
					.notRequired()
					.of(
						yup.object().shape({
							type: yup
								.string()
								.required()
								.equals([
									'skill_test',
									'coding_test',
									'value_test',
									'text_test',
									'video_test',
									'upload_test',
								]),
						})
					),

				tests: yup
					.array()
					.notRequired()
					.of(
						yup.object().shape(
							{
								test_type: yup
									.string()
									.required()
									.equals([
										'skill_test',
										'coding_test',
										'value_test',
										'text_test',
										'video_test',
										'upload_test',
									]),

								tp_id: yup.string().when('test_type', {
									is: (test_type) =>
										test_type === 'skill_test' ||
										test_type === 'coding_test',
									then: yup.string().required(),
								}),

								difficulty: yup.number().when('test_type', {
									is: (test_type) =>
										test_type === 'skill_test',
									then: yup.number().required(),
								}),

								values: yup
									.array()
									.of(
										yup.object().shape({
											value_name: yup.string().required(),
											val_id: yup.string().required(),
										})
									)
									.when('test_type', {
										is: (test_type) =>
											test_type === 'value_test',
										then: yup.array().required(),
									}),

								questions: yup
									.array()
									.of(
										yup.object().shape({
											text: yup.string().required(),
											qp_id: yup.string().required(),
											duration: yup.number().required(),
										})
									)
									.when('test_type', {
										is: (test_type) =>
											test_type === 'text_test' ||
											test_type === 'video_test',
										then: yup.array().required(),
									}),

								allow_file_upload: yup
									.boolean()
									.when('test_type', {
										is: (test_type) =>
											test_type === 'text_test',
										then: yup.boolean().required(),
									}),
							},
							['test_type', 'tp_id']
						)
					),
			});

			await schema.isValid(data);

			const request = await secure_instance.request({
				url: `/v1/assess_specs/${id}`,
				method: 'PATCH',
				data: data,
			});

			// return data;
			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const resetCurrAssessSpec = createAsyncThunk(
	'TestPacks/resetCurrAssessSpec',
	(_) => {
		return {
			curr_assessment_spec: null
		};
	}
);

export const getAssessSpecAI = createAsyncThunk(
	'assessSpec/getAssessSpecAI',
	async ({ data }, { rejectWithValue, dispatch }) => {
		try {

			const request = await secure_instance.request({
				url: `/v1/llm_requests/jd_to_spec`,
				method: 'POST',
				data: data,
			});

			const resp_data = request.data;
			var qualifying_questions = null;
			var title = 'New Test';
			var sections = [];
			var tests = [];
			var missing_tests = {
				missing_skill_tests: [],
				missing_coding_tests: [],
				missing_value_tests: []
			};

			if (resp_data?.skill_test_data?.length) {
				sections.push({ intro_text: 'Skill Based Test', type: 'skill_test' });
				tests = [...tests, ...resp_data.skill_test_data];
			}
			if (resp_data?.coding_test_data?.length) {
				sections.push({ intro_text: 'Coding Test', type: 'coding_test' });
				tests = [...tests, ...resp_data.coding_test_data];
			}
			if (resp_data?.value_test_data) {
				sections.push({ intro_text: 'Values Fitness', type: 'value_test' });
				tests = [...tests, resp_data.value_test_data];
			}
			if (resp_data?.text_response_questions) {
				sections.push({ intro_text: 'Text Responses', type: 'text_test' });
				tests = [...tests, {
					test_type: 'text_test',
					questions: resp_data.text_response_questions,
					allow_file_upload: false
				}];
			}
			if (resp_data?.video_response_questions) {
				sections.push({ intro_text: 'Video Responses', type: 'video_test' });
				tests = [...tests, {
					test_type: 'video_test',
					questions: resp_data.video_response_questions,
				}];
			}
			if (resp_data?.upload_questions) {
				sections.push({ intro_text: 'Upload Responses', type: 'upload_test' });
				tests = [...tests, {
					test_type: 'upload_test',
					questions: resp_data.upload_questions,
				}];
			}
			if (resp_data?.case_study) {
				sections.push({ intro_text: 'Case Study', type: 'case_study_test' });
				tests = [...tests, {
					test_type: 'case_study_test',
					...resp_data.case_study,
				}];
			}
			//added the personality test creation from ai
			if (resp_data?.job_dimensions) {
				sections.push({ intro_text: 'Personality Test', type: 'personality_test' });
				tests = [...tests, {
					test_type: 'personality_test',
					...resp_data.job_dimensions
				}];
			}
			if (resp_data?.qualifying_questions) {
				qualifying_questions = resp_data.qualifying_questions;
			}
			if (resp_data?.title) {
				title = resp_data.title;
			}

			missing_tests = {
				missing_coding_tests: resp_data?.missing_coding_tests || [],
				missing_skill_tests: resp_data.missing_skill_tests || [],
				missing_value_tests: resp_data.missing_value_tests || []
			};
			return { qualifying_questions, sections, tests, title, missing_tests };
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const cloneAssessSpec = createAsyncThunk(
	'TestPacks/cloneAssessSpec',
	async ({ id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assess_specs/${id}/clone`,
				method: 'POST',
			});
			return request;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);
