import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const oauthAuthorization = createAsyncThunk(
	'oauth/authorize',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const search_params = new URLSearchParams(data);

			const request = await secure_instance.request({
				url: '/v1/oauth/authorize',
				method: 'POST',
				data: {
					response_type: search_params.get('response_type'),
					client_id: search_params.get('client_id'),
					state: search_params.get('state'),
					redirect_uri: search_params.get('redirect_uri'),
				},
			});

			window.location.href = request.data.redirect_uri;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);
