import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

export default function CandidatePage(props) {
	const auth = useSelector((state) => state.authReducer);
	const { routeDetails } = props;

	return (
		<Switch>
			{
				Object.values(routeDetails.nested_routes).map((route, i) => {
					if (route.required_roles.some(item => auth.data.role.name === item)) {
						return (
							<Route exact={route.exact} path={`${routeDetails.pathname}${route.pathname}`} key={`${route.pathname}_${i}`}>
								<route.component routeDetails={route} />
							</Route>
						);

					}
				})
			}
		</Switch>
	);
	
}
