import React, { useEffect, useRef, useState } from 'react';
import CustomModal from '../../../../components/common/Modal';
import ProgressBar from '../../../../components/common/ProgressBar';
import Button from '../../../../components/sub-component/Button';

export default function TestContentWrapper(props) {


	const { title, children, duration, is_mandatory, currentQuestion, totalQuestions, onSubmit, answer } = props;


	const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);




	const onSkipQuestion = () => {

		setIsSkipModalOpen(false);
	};

	return (
		<>
			<div className='test-content-wrapper'>
				{/* <div className='test-content-wrapper-header'>
					<div className='w-100 d-flex flex-column align-items-start' style={{ maxWidth: '900px' }}>
						<ProgressBar size="lg" value={`${(100 / totalQuestions) * currentQuestion}`} />
						<span className='headline-small black-400' style={{ marginTop: '12px', fontWeight: '500' }}>Question: <span className='black-700'>{currentQuestion} / {totalQuestions}</span></span>
					</div>
					<div className='w-100 d-flex align-items-center' style={{ padding: '12px 0px 24px 0px', marginTop: '20px', borderBottom: '0.6px solid #C8CBCD', justifyContent: title !== null ? 'space-between' : duration !== null ? 'flex-end' : 'flex-start' }}>
						{
							title !== null &&
							<span className='headline-small black-700' style={{ fontWeight: '600' }}>{title || 'Test Title'}</span>
						}

					</div>
				</div> */}
				<div className='test-content-wrapper-content'>
					<div className='test-content-wrapper-content-container' style={{ position: 'relative' }}>
						{children}
					</div>
					{/* <div className='w-100 d-flex justify-content-end align-items-end' style={{ marginTop: '24px' }}>
						<Button
							processing={props.processing}
							disabled={is_mandatory && (!answer || answer === '') && (duration === null || duration > 0)}
							btn="ragular-btn" varrient="primary" title={currentQuestion === totalQuestions ? 'Submit' : 'Next'}
							style={{ width: '120px', height: '38px' }}
							onClick={() => {
								if ((!answer || answer === '') && (duration === null || duration > 0)) {
									setIsSkipModalOpen(true);
								}
								else {
									onSubmit('submit');
								}
							}} />
					</div> */}
				</div>
			</div >
		</>
	);
}
