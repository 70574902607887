import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { checkCurrentUrl } from './utilities';


const RedirectIfNoMatch = () => {
    const location = useLocation();

    if (!checkCurrentUrl()) {
        return <Redirect to="/dashboard/assessments" />;
    }

    return null; // Render nothing if URL matches
};

export default RedirectIfNoMatch;