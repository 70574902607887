import React, { useContext, useEffect, useMemo, useState } from 'react';
import LastActivity from '../../../assets/icons/Views/dashboard/test-detail/Vector.png';
import { ReactComponent as MinimizeIcon } from '../../../assets/icons/components/dashboard/minimize.svg';
import { ReactComponent as InboxIcon } from '../../../assets/icons/Views/dashboard/test-detail/inbox.svg';
import { ReactComponent as GrayLineIcon } from '../../../assets/icons/Views/dashboard/test-detail/line.svg';
import Table from '../../../components/common/Table';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProcessingIndicator from '../../../components/common/ProcessingIndicator';
import { PathName } from '../../../utils/routes';
import { getFormatedDate } from '../../../utils/utilities';
import { CommonContext } from '../../../utils/contexts/CommonContext';
import { GetAvtarImage } from '../../../components/dashboard/AvtarBadge';
import { getCandidate } from '../../../redux/thunks/Candidate';
import { useMediaQuery } from '@mui/material';
import TestDetailsRightView from '../test-details/TestDetailsRightView';
import AssessmentActionBtnComp from '../../../components/dashboard/assessments/AssessmentActionBtnComp';

import { ReactComponent as RedTrashIcon } from '../../../assets/icons/Views/dashboard/test-detail/trash.svg';
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/Views/dashboard/test-detail/left-arrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/Views/dashboard/assessments/arrow-left.svg';
import { ReactComponent as TimerIcon } from '../../../assets/icons/Views/dashboard/test-detail/HourglassMedium(1).svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/Views/dashboard/test-detail/LinkSimple(1).svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/Views/dashboard/test-detail/share.svg';
import { ReactComponent as Search } from '../../../assets/icons/Views/candidate/assessments/search.svg';
import NavBar from '../../../components/common/NavBar';
import TestDetailedView from '../test-details/TestDetailedView';
import Input from '../../../components/sub-component/Input';


export default function CandidateDetails() {



	const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [selectedRow, setSelectedRow] = useState();
	const [hiringStage, setHiringStage] = useState(null);

	const isOnWebScreen = useMediaQuery('(min-width:1200px)');
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1199px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const candidate = useSelector((state) => state.CandidateReducer);
	const auth = useSelector((state) => state.authReducer);

	const testTableHeader = [
		{ id: 'title', name: 'Title', type: 'string', disableSorting: true, screens: ['web', 'tab', 'mob'] },
		{ id: 'candidatesStatus', name: 'Status', type: 'component', disableSorting: true, enableFilter: true, filterName: 'status', filterOptions: [{ name: 'Pending', value: 'PENDING' }, { name: 'In Progress', value: 'IN_PROGRESS' }, { name: 'Completed', value: 'FINISHED' }, { name: 'Declined', value: 'DECLINED' }, { name: 'Not Qualified', value: 'NOT_QUALIFIED' }, { name: 'Expired', value: 'EXPIRED' }, { name: 'Invite Bounced', value: 'INVITE_BOUNCED'},], screens: ['web', 'tab',] },
		{ id: 'invitedOn', name: 'Invited on', type: 'string', disableSorting: false, screens: ['web',] },
		{ id: 'updatedAt', name: 'Last Activity', type: 'string', disableSorting: false, screens: ['web'] },
		{ id: 'hiringStage', name: 'Hiring Stage', type: 'string', disableSorting: true, enableFilter: true, filterName: 'hiring_stage', filterOptions: [{ name: 'Not Yet Evaluated', value: 'PENDING' }, { name: 'Rejected', value: 'REJECTED' }, { name: 'Shortlisted', value: 'SHORTLISTED' }], screens: ['web', 'tab'] },
	];

	const OnOpenRightSideBartestTableHeader = [
		{ id: 'title', name: 'Title', type: 'string', disableSorting: true, screens: ['web', 'tab', 'mob'] },
		{ id: 'candidatesStatus', name: 'Status', type: 'component', disableSorting: true, enableFilter: true, filterName: 'status', filterOptions: [{ name: 'Pending', value: 'PENDING' }, { name: 'In Progress', value: 'IN_PROGRESS' }, { name: 'Completed', value: 'FINISHED' }, { name: 'Declined', value: 'DECLINED' }, { name: 'Not Qualified', value: 'NOT_QUALIFIED' }, { name: 'Expired', value: 'EXPIRED' }, { name: 'Invite Bounced', value: 'INVITE_BOUNCED'},], screens: ['web', 'tab',] },
		{ id: 'hiringStage', name: 'Hiring Stage', type: 'string', disableSorting: true, enableFilter: true, filterName: 'hiring_stage', filterOptions: [{ name: 'Not Yet Evaluated', value: 'PENDING' }, { name: 'Rejected', value: 'REJECTED' }, { name: 'Shortlisted', value: 'SHORTLISTED' }], screens: ['web', 'tab', 'mob'] },
	];

	const customSorting = {
		invitedOn: (a, b) => {
			const dateA = new Date(a);
			const dateB = new Date(b);
			return dateA - dateB;
		},
		updatedAt: (a, b) => {
			const dateA = new Date(a);
			const dateB = new Date(b);
			return dateA - dateB;
		},
	};

	const searchColumns = ['title', 'invitedOn', 'updatedAt', 'hiringStage'];

	const defaultOptions = [
		{ name: 'All Assessment(s)', value: 'all', count: 0 },
		{ name: 'Pending', value: 'PENDING', count: 0 },
		{ name: 'In Progress', value: 'IN_PROGRESS', count: 0 },
		{ name: 'Completed', value: 'FINISHED', count: 0 },
		{ name: 'Declined', value: 'DECLINED', count: 0 },
		{ name: 'Not Qualified', value: 'NOT_QUALIFIED', count: 0 },
		{ name: 'Invite Bounced', value: 'INVITE_BOUNCED', count: 0 },
	];

	const [navOptions, setNavOptions] = useState([]);
	const [selectedNavLink, setSelectedNavLink] = useState('all');
	const [testData, setTestData] = useState([]);
	const [customSearch, setCustomSearch] = useState();


	const { commonValues, setCommonValues } = useContext(CommonContext);

	useMemo(() => {
		if (candidate.current_candidate?.assessments === null) return;

		const _resultData = candidate?.current_candidate?.assessments?.map((assessment, index) => {
			return (
				{
					title: (assessment.title),
					invitedOn: getFormatedDate(assessment.created_at, true),
					updatedAt: getFormatedDate(assessment.updated_at, true),
					candidatesStatus: <div key='1' className='candidate-status-container'
						style={{
							border: assessment.status !== 'FINISHED' ? '1px solid black' : '',
							background: assessment.status === 'FINISHED' ? '#7C21FF' : '#FFFFFF',
							color: assessment.status === 'FINISHED' ? '#FFF' : '#121216',
						}}
					>{assessment.status === 'PENDING' ? 'Pending' : assessment.status === 'IN_PROGRESS' ? 'In Progress' : assessment.status === 'NOT_QUALIFIED' ? 'Not Qualified' : assessment.status === 'DECLINED' ? 'Declined' : assessment.status === 'EXPIRED' ? 'EXPIRED' : assessment.status === 'INVITE_BOUNCED' ? 'Invite Bounced' : 'Completed'}</div>,

					hiringStage: `${assessment?.hiring_stage?.charAt(0).toUpperCase() + assessment?.hiring_stage?.toLowerCase()?.slice(1)}`,
					...assessment
				}
			);
		}) || [];

		setTestData([..._resultData]);
	}, [candidate?.current_candidate?.assessments]);


	useMemo(() => {
		if (candidate?.current_candidate?.assessments === null) return;
		const _navOptions = [];
		candidate.current_candidate?.assessments?.length > 0 && defaultOptions.map((navOp) => {
			if (navOp?.value === 'all') {
				return (
					_navOptions.push({ ...navOp, count: candidate.current_candidate?.assessments?.length || 0 })
				);
			}
			else {
				return (
					_navOptions.push({ ...navOp, count: candidate.current_candidate?.assessments?.filter((_assess) => { return (_assess?.status === navOp?.value?.toUpperCase()); }).length || 0 })
				);
			}
		});
		setNavOptions(_navOptions.length > 0 ? [..._navOptions] : [...defaultOptions]);

	}, [candidate?.current_candidate?.assessments]);


	useEffect(() => {
		dispatch(getCandidate({ id: params.id, query: '?expand=assessments' }));
		setIsReady(true);

		return () => {
			setIsRightMenuOpen(false);
			setCommonValues({ ...commonValues, isSideBarExpanded: true });
		};
	}, [hiringStage]);

	if (candidate?.loading || !isReady) {
		return <ProcessingIndicator className='medium-indicator' />;
	}

	if (candidate?.current_candidate?.assessments === null) {
		return <Redirect to={PathName.CANDIDATES} />;
	}




	const onHiringStageUpdate = (hiringStage) => {
		if (candidate?.current_candidate?.assessments === null) return;

		const _resultData = candidate?.current_candidate?.assessments?.map((assessment, index) => {
			return (
				{
					title: (assessment.title),
					invitedOn: getFormatedDate(assessment.created_at, true),
					updatedAt: getFormatedDate(assessment.updated_at, true),
					candidatesStatus: <div key='1' className='candidate-status-container'
						style={{
							background: assessment.status === 'PENDING' ? '#FFF0BA' : assessment.status === 'IN_PROGRESS' ? '#DEDAF2' : assessment.status === 'NOT_QUALIFIED' ? '#FFE1E1' : assessment.status === 'DECLINED' ? '#FFE1E1' : '#E0FFF6',
							color: assessment.status === 'PENDING' ? '#F4C20D' : assessment.status === 'IN_PROGRESS' ? '#6F2DBD' : assessment.status === 'NOT_QUALIFIED' ? '#FC4848' : assessment.status === 'DECLINED' ? '#FC4848' : '#00D69A'
						}}
					>{assessment.status === 'PENDING' ? 'Pending' : assessment.status === 'IN_PROGRESS' ? 'In Progress' : assessment.status === 'NOT_QUALIFIED' ? 'Not Qualified' : assessment.status === 'DECLINED' ? 'Declined' : 'Completed'}</div>,
					hiringStage: `${assessment?.id === selectedRow.id ? hiringStage?.charAt(0).toUpperCase() + hiringStage?.toLowerCase()?.slice(1) : assessment?.hiring_stage?.charAt(0).toUpperCase() + assessment?.hiring_stage?.toLowerCase()?.slice(1)}`,
					...assessment
				}
			);
		}) || [];

		setTestData([..._resultData]);

	};

	const MinimizeViewBar = () => {
		return (
			<div className={'collapsed-left-sidebar-container pointer'}
				onClick={() => {
					setCommonValues({
						...commonValues,
						detailedTestView: {
							isExpanded: false,
							testType: null,
							data: null,
						},
					});
					(isOnTabletScreen || isOnMobileScreen) && setIsRightMenuOpen(false);
				}}
			>
				{isOnMobileScreen &&
					<div className='d-flex flex-row align-items-center justify-content-start' style={{gap: '12px'}}>
						<GetAvtarImage
							className='headline-small sidebar-container-image pointer'
							src={{ image_id: candidate?.current_candidate?.image_id, name: candidate?.current_candidate.first_name + ' ' + candidate?.current_candidate.last_name }}
							nameInitals={`${candidate?.current_candidate.first_name?.charAt(0)}${candidate?.current_candidate.last_name?.charAt(0)}`}
							turnOnBGColors={true}
							size='custom'
							width={34}
							height={34}
						/>
						<span className='subtitle-1 sidebar-container-headline'>
							{candidate?.current_candidate.first_name + ' ' + candidate?.current_candidate.last_name}
						</span>
					</div>
				}
				<MinimizeIcon style={{ width: isOnMobileScreen ? '34' : '44px', height: isOnMobileScreen ? '34' : '44px' }}/>
				{!isOnMobileScreen &&
					<div className='d-flex flex-column align-items-center justify-content-start' style={{gap: '12px'}}>
						<span className='subtitle-1 sidebar-container-headline'>
							{candidate?.current_candidate.first_name + ' ' + candidate?.current_candidate.last_name}
						</span>
						<GetAvtarImage
							className='headline-small sidebar-container-image pointer'
							src={{ image_id: candidate?.current_candidate?.image_id, name: candidate?.current_candidate.first_name + ' ' + candidate?.current_candidate.last_name }}
							nameInitals={`${candidate?.current_candidate.first_name?.charAt(0)}${candidate?.current_candidate.last_name?.charAt(0)}`}
							turnOnBGColors={true}
							size='custom'
							width={48}
							height={48}
						/>
					</div>
				}
			</div>
		);
	};

	return (
		<>
			{
				isOnWebScreen ?
					<div className='view-with-sidebar' style={commonValues.detailedTestView.isExpanded ? { justifyContent: 'flex-start', gap: '24px' } : {}}>
						{
							commonValues.detailedTestView.isExpanded
								?
								<MinimizeViewBar />
								: 
								<div className={`left-sidebar-container ${isRightMenuOpen && ' left-sidebar-open '}`}>
									<div className='test-detail-container w-100 d-flex flex-column align-items-start hide-scrollbar' >
										<div className={`test-detail-header w-100 d-flex flex-column justify-content-between ${isRightMenuOpen && ' test-detail-header w-100 d-flex flex-column '}`} style={{height:'231px'}}>
											<div className=' d-flex align-items-center' style={{height:'44px',gap:'16px'}}> 
												<div className='d-flex align-items-center justify-content-center pointer' style={{width:'44px',height:'44px',background:'#F6F5F8',borderRadius:'50px'}} onClick={history.goBack}>
													<LeftArrowIcon />
												</div>
												<span className='body-2 dark-50'>Candidates</span>
											</div>
											<div className='test-detail-header-left d-flex flex-row w-100 justify-content-between '>		
												<div className='d-flex flex-column align-items-start' >
													<div className='d-flex flex-column text-left body-2' style={{gap:'12px',color:'#808191'}}>
														<span className='headline-3' style={{ textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
															{candidate?.current_candidate.first_name && candidate?.current_candidate.last_name ? `${candidate?.current_candidate.first_name } ${candidate?.current_candidate.last_name}` : candidate?.current_candidate?.email}
															{/* {candidate?.current_candidate.last_name + ' ' + candidate?.current_candidate.last_name} || {candidate?.current_candidate?.email} */}
														</span>
														<span className='body-2 text-start' style={{color:'#808191' }}>
															{candidate?.current_candidate?.email}
														</span>
													</div>
													<div className='d-flex flex-row align-items-center' style={{ marginTop: '10.5px',color:' #808191' }}>
														<InboxIcon/>
														<span className='body-2 mt-1' style={{ marginLeft: '4.8px',color:' #808191' }}>
															{'Invited on '}
															<span className='body-2 dark-100'>
																{new Date(candidate?.current_candidate?.invited_on).toDateString()}
															</span>
														</span>
													</div>
													<div className='d-flex flex-row align-items-center' style={{ marginTop: '10.5px' }} >
														<img src={LastActivity} alt="" />
														<span className='body-2 mt-1' style={{ marginLeft: '4.8px',color:' #808191' }}>
															{'Last Activity '}
															<span className='body-2 dark-100'>
																{getFormatedDate(candidate?.current_candidate?.last_activity)}
															</span></span>
													</div>
												</div>
												<GetAvtarImage
													className='assessment-upload-icon pointer'
													src={{ image_id: candidate?.current_candidate?.image_id, name: candidate?.current_candidate.first_name + ' ' + candidate?.current_candidate.last_name }}
													size='custom'
													width={60}
													height={60}
													nameInitals={`${candidate?.current_candidate.first_name?.charAt(0)}${candidate?.current_candidate.last_name?.charAt(0)}`}
													turnOnBGColors={true}
												/>
											</div>
										</div>
										<div className='candidate-details-container mt-3' >
											{/* <div className='assessments-search-bar mt-3 mb-3'>
												<div className='d-flex' style={{ gap: '0.5em' }}>
													<div className='search'>
														<Search height={'24px'} width={'24px'} />
														<Input
															inputStyle={{
																background: 'transparent',
																border: 'none',
															}}
															className={'w-100'}
															placeholder={'Search ...'}
															onChange={(e) => {
																setCustomSearch(e);
															}}
														/>
													</div>
												</div>
											</div> */}
											<div className='w-100' style={{height: 'calc(100vh - 135px)',borderRadius:'24px',background:'white'}}>
												<Table
													headers={isRightMenuOpen ? OnOpenRightSideBartestTableHeader : testTableHeader}
													data={testData}
													onSelectRow={(row) => {
														setIsRightMenuOpen(true);
														setCommonValues({ ...commonValues, isSideBarExpanded: false });
														setSelectedRow(row);
													}}
													searchColumns={searchColumns}
													customSorting={customSorting}
													// disableSearchInput={true}
													customSearch={customSearch}
													nullTableTitle={'No assessments to show'}
													nullTableDescription={`Assessments with the respective status will be shown here`}
													highlightActiveRow
													selectedId={selectedRow?.id}
												/>
											</div>
										</div>
									</div>
								</div>
						}
						{
							<div className={`right-sidebar-container hide-scrollbar ${isRightMenuOpen && ' test-details-right-sidebar-open '} ${commonValues.detailedTestView.isExpanded && ' test-details-right-sidebar-open-minimized '}`}>
								<TestDetailsRightView
									onClose={() => { setIsRightMenuOpen(false); setCommonValues({ ...commonValues, isSideBarExpanded: true }); }}
									assessment={selectedRow}
									setHiringStage={setHiringStage}
									isForCandidate={true}
									userName={(candidate?.current_candidate.first_name || candidate?.current_candidate.last_name) ? `${candidate?.current_candidate.first_name || ''} ${candidate?.current_candidate.last_name || ''}` : candidate?.current_candidate.email}
									onHiringStageUpdate={onHiringStageUpdate}
									assessmentsLength={testData.length}
									selectedIndex={testData.findIndex((test) => test.id === selectedRow?.id)}
									onSwitchAssessment={(next = false) => {
										const index = testData.findIndex((test) => test.id === selectedRow?.id);
										if (index !== -1) {
											if (next && testData[index + 1]) {
												setSelectedRow(testData[index + 1]);
											} else if (testData[index - 1]) {
												setSelectedRow(testData[index - 1]);
											}
										}
									}}
								/>
							</div>
						}
						<TestDetailedView assessment={selectedRow} candidate={true} />
					</div>
					:
					isOnTabletScreen ?
						<div className='view-with-sidebar' style={{}}>
							{
								isRightMenuOpen
									?
									<MinimizeViewBar />
									:
									<div className={`left-sidebar-container ${isRightMenuOpen && ' left-sidebar-open '}`}>
										<div className='test-detail-container w-100 d-flex flex-column align-items-start hide-scrollbar' >
											<div className={`test-detail-header w-100 d-flex flex-column justify-content-between ${isRightMenuOpen && ' test-detail-header w-100 d-flex flex-column '}`} style={{height:'231px'}}>
												<div className=' d-flex align-items-center' style={{height:'44px',gap:'16px'}}> 
													<div className='d-flex align-items-center justify-content-center pointer' style={{width:'44px',height:'44px',background:'#F6F5F8',borderRadius:'50px'}} onClick={history.goBack}>
														<LeftArrowIcon />
													</div>
													<span className='body-2 dark-50'>Candidates</span>
												</div>
												<div className='test-detail-header-left d-flex flex-row w-100 justify-content-between '>		
													<div className='d-flex flex-column align-items-start' >
														<div className='d-flex flex-column text-left body-2' style={{gap:'12px',color:'#808191'}}>
															<span className='headline-3' style={{ textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
																{candidate?.current_candidate.first_name && candidate?.current_candidate.last_name ? `${candidate?.current_candidate.first_name } ${candidate?.current_candidate.last_name}` : candidate?.current_candidate?.email}
																{/* {candidate?.current_candidate.last_name + ' ' + candidate?.current_candidate.last_name} || {candidate?.current_candidate?.email} */}
															</span>
															<span className='body-2 text-start' style={{color:'#808191' }}>
																{candidate?.current_candidate?.email}
															</span>
														</div>
														<div className='d-flex flex-row align-items-center' style={{ marginTop: '10.5px',color:' #808191' }}>
															<InboxIcon/>
															<span className='body-2 mt-1' style={{ marginLeft: '4.8px',color:' #808191' }}>
																{'Invited on '}
																<span className='body-2 dark-100'>
																	{new Date(candidate?.current_candidate?.invited_on).toDateString()}
																</span>
															</span>
														</div>
														<div className='d-flex flex-row align-items-center' style={{ marginTop: '10.5px' }} >
															<img src={LastActivity} alt="" />
															<span className='body-2 mt-1' style={{ marginLeft: '4.8px',color:' #808191' }}>
																{'Last Activity '}
																<span className='body-2 dark-100'>
																	{getFormatedDate(candidate?.current_candidate?.last_activity)}
																</span></span>
														</div>
													</div>
													<GetAvtarImage
														className='assessment-upload-icon pointer'
														src={{ image_id: candidate?.current_candidate?.image_id, name: candidate?.current_candidate.first_name + ' ' + candidate?.current_candidate.last_name }}
														size='custom'
														width={60}
														height={60}
														nameInitals={`${candidate?.current_candidate.first_name?.charAt(0)}${candidate?.current_candidate.last_name?.charAt(0)}`}
														turnOnBGColors={true}
													/>
												</div>
											</div>
											<div className='candidate-details-container mt-3' >
												{/* <div className='assessments-search-bar mt-3 mb-3'>
													<div className='d-flex' style={{ gap: '0.5em' }}>
														<div className='search'>
															<Search height={'24px'} width={'24px'} />
															<Input
																inputStyle={{
																	background: 'transparent',
																	border: 'none',
																}}
																className={'w-100'}
																placeholder={'Search ...'}
																onChange={(e) => {
																	setCustomSearch(e);
																}}
															/>
														</div>
													</div>
												</div> */}
												<div className='w-100'>
													<Table
														headers={isRightMenuOpen ? OnOpenRightSideBartestTableHeader : testTableHeader}
														data={testData}
														onSelectRow={(row) => {
															setIsRightMenuOpen(true);
															setCommonValues({ ...commonValues, isSideBarExpanded: false });
															setSelectedRow(row);
														}}
														searchColumns={searchColumns}
														customSorting={customSorting}
														// disableSearchInput={true}
														customSearch={customSearch}
														nullTableTitle={'No assessments to show'}
														nullTableDescription={`Assessments with the respective status will be shown here`}
														highlightActiveRow
														selectedId={selectedRow?.id}
													/>
												</div>
											</div>
										</div>
									</div>
							}
							{
								isRightMenuOpen &&
								<div className={`right-sidebar-container hide-scrollbar ${isRightMenuOpen && ' test-details-right-sidebar-open '} ${commonValues.detailedTestView.isExpanded && ' test-details-right-sidebar-open-minimized '}`}>
									{
										!commonValues.detailedTestView.isExpanded ?
											<TestDetailsRightView
												onClose={() => { setIsRightMenuOpen(false); setCommonValues({ ...commonValues, isSideBarExpanded: true }); }}
												assessment={selectedRow}
												setHiringStage={setHiringStage}
												isForCandidate={true}
												userName={(candidate?.current_candidate.first_name || candidate?.current_candidate.last_name) ? `${candidate?.current_candidate.first_name || ''} ${candidate?.current_candidate.last_name || ''}` : candidate?.current_candidate.email}
												onHiringStageUpdate={onHiringStageUpdate}
												assessmentsLength={testData.length}
												selectedIndex={testData.findIndex((test) => test.id === selectedRow?.id)}
												onSwitchAssessment={(next = false) => {
													const index = testData.findIndex((test) => test.id === selectedRow?.id);
													if (index !== -1) {
														if (next && testData[index + 1]) {
															setSelectedRow(testData[index + 1]);
														} else if (testData[index - 1]) {
															setSelectedRow(testData[index - 1]);
														}
													}
												}}
											/>
											:
											<TestDetailedView assessment={selectedRow} candidate={true} />
									}
								</div>
							}

						</div>
						:
						<>
							<div className='test-detail-page-container w-100'>
								<div className='test-detail-page-header-container'>
									{/* <div className='test-detail-page-header'>
										<BackArrowIcon className='icon-wrapper pointer' onClick={history.goBack} />
										<span className='headline-medium semibold black-700'>{`Active Assessments`}</span>
									</div> */}
									{/* {
										isRightMenuOpen &&
										<div className='page-heading'>
											{
												commonValues?.detailedTestView?.isExpanded === false ?
													<span className='body-1 black-500 pointer' onClick={() => setIsRightMenuOpen(false)}>All Assessments<span className='medium primary-text'>/Assessment Details/</span></span>
													:
													<div className='w-100 d-flex flex-row align-items-center pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
														<BackArrowIcon className='icon-wrapper' style={{ width: '12px', height: '12px', marginRight: '8px' }} />
														<span className='body-1 black-500'>{`Assessment Details`}<span className='medium primary-text'>{`/${commonValues.detailedTestView.testType === 'personality_insight' ? 'Personality Detail' : commonValues.detailedTestView.testType === 'case_study_test' ? 'Case Study Statement' : commonValues.detailedTestView.testType === 'anti_cheat_monitor' ? 'Anti-cheat Monitor' : 'Coding Test Detail'}/`}</span></span>
													</div>
											}
										</div>
									} */}
								</div>
								<div className='test-detail-page-content'>
									{
										!isRightMenuOpen ?
											<div className='view-with-sidebar flex-column'>
												<div className='test-detail-container w-100 d-flex flex-column align-items-start mt-3 hide-scrollbar' >
													<div className={`test-detail-header w-100 d-flex flex-column justify-content-between ${isRightMenuOpen && ' test-detail-header w-100 d-flex flex-column '}`} style={{height:'231px'}}>
														<div className=' d-flex align-items-center' style={{height:'44px',gap:'16px'}}> 
															<div className='d-flex align-items-center justify-content-center pointer' style={{width:'44px',height:'44px',background:'#F6F5F8',borderRadius:'50px'}} onClick={history.goBack}>
																<LeftArrowIcon />
															</div>
															<span className='body-2 dark-50'>Candidates</span>
														</div>
														<div className='test-detail-header-left d-flex flex-row w-100 justify-content-between '>		
															<div className='d-flex flex-column align-items-start' >
																<div className='d-flex flex-column text-left body-2' style={{gap:'12px',color:'#808191'}}>
																	<span className='headline-5' style={{ textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
																		{candidate?.current_candidate.first_name && candidate?.current_candidate.last_name ? `${candidate?.current_candidate.first_name } ${candidate?.current_candidate.last_name}` : candidate?.current_candidate?.email}
																		{/* {candidate?.current_candidate.last_name + ' ' + candidate?.current_candidate.last_name} || {candidate?.current_candidate?.email} */}
																	</span>
																	<span className='body-2 text-start' style={{color:'#808191' }}>
																		{candidate?.current_candidate?.email}
																	</span>
																</div>
																<div className='d-flex flex-row align-items-center' style={{ marginTop: '10.5px',color:' #808191' }}>
																	<InboxIcon/>
																	<span className='body-2 mt-1' style={{ marginLeft: '4.8px',color:' #808191' }}>
																		{'Invited on '}
																		<span className='body-2 dark-100'>
																			{new Date(candidate?.current_candidate?.invited_on).toDateString()}
																		</span>
																	</span>
																</div>
																<div className='d-flex flex-row align-items-center' style={{ marginTop: '10.5px' }} >
																	<img src={LastActivity} alt="" />
																	<span className='body-2 mt-1' style={{ marginLeft: '4.8px',color:' #808191' }}>
																		{'Last Activity '}
																		<span className='body-2 dark-100'>
																			{getFormatedDate(candidate?.current_candidate?.last_activity)}
																		</span></span>
																</div>
															</div>
															<GetAvtarImage
																className='assessment-upload-icon pointer'
																src={{ image_id: candidate?.current_candidate?.image_id, name: candidate?.current_candidate.first_name + ' ' + candidate?.current_candidate.last_name }}
																size='custom'
																width={60}
																height={60}
																nameInitals={`${candidate?.current_candidate.first_name?.charAt(0)}${candidate?.current_candidate.last_name?.charAt(0)}`}
																turnOnBGColors={true}
															/>
														</div>
													</div>
													<div className='candidate-details-container mt-3' >
														{/* <div className='assessments-search-bar mt-3 mb-3'>
															<div className='d-flex' style={{ gap: '0.5em' }}>
																<div className='search'>
																	<Search height={'24px'} width={'24px'} />
																	<Input
																		inputStyle={{
																			background: 'transparent',
																			border: 'none',
																		}}
																		className={'w-100'}
																		placeholder={'Search ...'}
																		onChange={(e) => {
																			setCustomSearch(e);
																		}}
																	/>
																</div>
															</div>
														</div> */}
														<div className='w-100'>
															<Table
																headers={isRightMenuOpen ? OnOpenRightSideBartestTableHeader : testTableHeader}
																data={testData}
																onSelectRow={(row) => {
																	setIsRightMenuOpen(true);
																	setCommonValues({ ...commonValues, isSideBarExpanded: false });
																	setSelectedRow(row);
																}}
																searchColumns={searchColumns}
																customSorting={customSorting}
																// disableSearchInput={true}
																customSearch={customSearch}
																nullTableTitle={'No assessments to show'}
																nullTableDescription={`Assessments with the respective status will be shown here`}
																highlightActiveRow
																selectedId={selectedRow?.id}
															/>
														</div>
													</div>
												</div>
											</div>
											:
											<div className={`d-flex flex-column hide-scrollbar`}>
												<MinimizeViewBar />
												{
													commonValues?.detailedTestView?.isExpanded === false ?
														<TestDetailsRightView
															onClose={() => { setIsRightMenuOpen(false); }}
															assessment={selectedRow}
															setHiringStage={setHiringStage}
															isForCandidate={true}
															userName={(candidate?.current_candidate.first_name || candidate?.current_candidate.last_name) ? `${candidate?.current_candidate.first_name || ''} ${candidate?.current_candidate.last_name || ''}` : candidate?.current_candidate.email}
															onHiringStageUpdate={onHiringStageUpdate}
															assessmentsLength={testData.length}
															selectedIndex={testData.findIndex((test) => test.id === selectedRow?.id)}
															onSwitchAssessment={(next = false) => {
																const index = testData.findIndex((test) => test.id === selectedRow?.id);
																if (index !== -1) {
																	if (next && testData[index + 1]) {
																		setSelectedRow(testData[index + 1]);
																	} else if (testData[index - 1]) {
																		setSelectedRow(testData[index - 1]);
																	}
																}
															}}
														/>
														:
														<TestDetailedView assessment={selectedRow} candidate={true} />
												}
											</div>
									}
								</div>
							</div>
						</>
			}

		</>
	);
}
