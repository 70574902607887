import React from 'react';
import PropTypes from 'prop-types';
import eyeClosedIcon from '../../assets/icons/basic/eye-closed.svg';
import eyeIcon from '../../assets/icons/basic/eye.svg';

export default function Input(props) {
	const { varrient, inputContainerClass, style, title, isRequired } = props;

	if (varrient === 'primary') {
		return (
			<div className={`w-100 d-flex flex-column align-items-start ${inputContainerClass}`} style={{ ...style }}>
				{
					title &&
					<div className='mb-2' style={{ minWidth: 'fit-content', paddingLeft: '24px' }}>
						<span className='body-2 dark-50'>
							{title}
							{isRequired &&
								<span className='danger-text'>*</span>
							}
						</span>
					</div>
				}
				<input
					ref={props.ref}
					placeholder={props.placeholder || ''}
					onChange={props.onChange}
					onBlur={props.onBlur}
					onFocus={props.onFocus}
					onKeyDown={props.onKeyDown}
					onKeyPress={props.onKeyPress}
					type={props.type}
					id={props.id}
					name={props.name}
					value={props.value}
					defaultValue={props.defaultValue}
					minLength={props.minLength}
					maxLength={props.maxLength}
					readOnly={props.readOnly}
					min={props.min}
					max={props.max}
					className={`body-2 dark-100 primary-input ${props.className}`}
					disabled={props.disabled}
					style={{
						cursor: props.readOnly ? 'not-allowed' : '',
						...props.inputStyle,
					}}
					{...props.register}
				// ref={props.ref}
				/>
			</div>
		);
	}
	else {
		return (
			<div className="on-boarding-input">
				<input
					placeholder={props.placeholder || ''}
					onChange={props.onChange}
					onBlur={props.onBlur}
					onFocus={props.onFocus}
					type={props.type}
					id={props.id}
					name={props.name}
					value={props.value}
					defaultValue={props.defaultValue}
					min={props.min}
					max={props.max}
					minLength={props.minLength}
					maxLength={props.maxLength}
					readOnly={props.readOnly}
					className={`${props.className} body-2 dark-100`}
					disabled={props.disabled}
					style={{
						cursor: props.readOnly ? 'not-allowed' : '',
						// background: props.readOnly ? '#EAEDEE' : '',
						...props.inputStyle,
					}}
					ref={props.ref}
				/>
				{
					props.enableRevealIcon && (
						<span className='reveal-icon pointer' onClick={props.revealPasswordClick}>
							<img src={props.type === 'password' ? eyeClosedIcon : eyeIcon} style={{ width: '100%', height: '100%' }} />
						</span>
					)
				}
			</div>
		);
	}

}

Input.propTypes = {
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	type: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	minLength: PropTypes.number,
	maxLength: PropTypes.number,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	inputStyle: PropTypes.object,
	ref: PropTypes.any
};

