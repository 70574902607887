import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';
import axios from 'axios';
const cancle_tocken = axios.CancelToken;

export const listTestHistoryResult = createAsyncThunk(
    'assessments/listTestHistoryResult',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const request = await secure_instance.request({
                url: `/v1/test_results/`,
                method: 'GET',
            });

            return request.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    },
);

let historyResultApiCancle = null;

export const getTestHistoryResult = createAsyncThunk(
    'assessments/getTestHistoryResult',
    async ({ data }, { rejectWithValue, dispatch }) => {
        if (historyResultApiCancle) {
            historyResultApiCancle();
        }
        try {
            const request = await secure_instance.request({
                url: `/v1/test_results/?test_type=${data?.test_type}&latest_instance=${data?.latest_instance}&search=${data?.search}&sort=${data?.sort}&sort_direction=${data?.sort_direction}&page=${data?.page}&size=${data?.size}`,
                method: 'GET',
                cancelToken: new cancle_tocken((c) => {
                    historyResultApiCancle = c;
                })
            });

            return request.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    },
);

export const getTestResultPageNo = createAsyncThunk(
    'assessments/getTestHistoryResult',
    async ({ data }, { rejectWithValue, dispatch }) => {
        try {
            const request = await secure_instance.request({
                url: `/v1/test_results/${data?.test_id}/result_page_no`,
                method: 'GET',
            });

            return request.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    },
);