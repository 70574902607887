import React, { useState } from 'react';
import { ReactComponent as TestIcon } from '../../../assets/icons/Views/candidate/assessments/python.svg';
import ProgressBar from '../../../components/common/ProgressBar';

export default function TestHistoryCard(props) {
	const { data, onClick, selectedTest, isRightMenuOpen } = props;

	return (
		<>
			<div className={`test-history-card-container ${selectedTest?.id === data?.id && 'selected-assessment'}`} onClick={() => onClick(data)} style={{gap: isRightMenuOpen && '0px'}}>
				<div className='detail-container'>
					<div className='details'>
						<div className='d-flex align-items-center' style={{ gap: '12px' }}>
							{data.test_icon &&
								<img
									src={data.test_icon}
									style={{
										width: '16.8px',
										height: '16.8px',
									}}
								/>
							}
							<span className='body-1 dark-100 text-start'>{data?.test_title}</span>
							<span className='body-2 dark-50'>{data?.assessment_title}</span>
							{(new Date(data?.expiry_date) < new Date()) &&
								<div className='candidate-status-container body-2' style={{ width: 'fit-content', border: '1px solid black', background: '#FFFFFF', color: '#121216' }}>
									{'Expired'}
								</div>
							}
						</div>
					</div>
					<div className='company-detail'>
						<span className='body-2 dark-50'>Valid Till: <span className='dark-100'>{new Date(data?.expiry_date).toDateString()}</span></span>
					</div>
				</div>
				<div className='detail-container-right'>
					<div className='d-flex align-items-start' style={{ width: '110px' }}>
						<ProgressBar value={data?.test_type === 'coding_test' ? Math.round((data?.score_object?.score) * 100).toFixed(1) : Math.round(parseFloat((10 / data?.score_object?.total_questions) * data?.score_object?.correct_answers) * 10).toFixed(1)} varrient='progress-with-white-bg' />
					</div>
				</div>
			</div>
		</>
	);
}
