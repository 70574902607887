import { createSlice } from '@reduxjs/toolkit';
import { Actions, createResource, deleteResource, getResource, getPublicResource, resetResourceState } from '../thunks/Resource';

const ResourceSlice = createSlice({
	name: 'Resource',
	initialState: {
		loading: false,
		processing: false,
		processing_delete: false,
		data: null,
		upload_percentage: 0,
		error_message: null,
		success_message: null,
	},

	reducers: {
		setUploadProgress: (state, { payload, }) => {
			return {
				...state,
				upload_percentage: payload
			};
		}
	},

	extraReducers: {

		[createResource.pending]: (state) => {

			return {
				...state,
				processing: true,
			};

		},

		[createResource.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				data: payload,
				// upload_percentage: 0,
			};
		},

		[createResource.rejected]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				error_message: payload
			};
		},

		[getResource.pending]: (state) => {

			return {
				...state,
				loading: true,
			};

		},

		[getResource.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				data: payload
			};
		},

		[getResource.rejected]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				data: null,
				// error_message: payload
			};
		},

		[getPublicResource.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[getPublicResource.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				data: payload
			};
		},
		[getPublicResource.rejected]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				data: null,
				// error_message: payload
			};
		},

		[deleteResource.pending]: (state) => {
			return {
				...state,
				processing_delete: true,
			};
		},

		[deleteResource.fulfilled]: (state) => {
			return {
				...state,
				processing_delete: false,
				data: null,
				upload_percentage: 0,
			};
		},

		[deleteResource.rejected]: (state, { payload }) => {
			return {
				...state,
				processing_delete: false,
				error_message: payload
			};
		},

		[resetResourceState.fulfilled]: (state, { payload }) => {
			return payload;
		}


	},
});

// export const { setUploadProgress } = ResourceSlice.actions;
Actions.setUploadProgress = ResourceSlice.actions.setUploadProgress;
export default ResourceSlice.reducer;