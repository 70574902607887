import React, { useState, useMemo } from 'react';
import CustomModal from '../../../common/Modal';
import Table from '../../../common/Table';
import AlertBox from '../../../common/AlertBox';
import Radio from '../../../../components/sub-component/Radio';
import ProcessingIndicator from '../../../common/ProcessingIndicator';
import { ReactComponent as MasterIcon } from '../../../../assets/icons/pricing/Mastercard.svg';
import { ReactComponent as TagIcon } from '../../../../assets/icons/pricing/Tag.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/components/dashboard/trash.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/components/dashboard/chevron-left.svg';
import { RequestStatus, } from '../../../../utils/Constants';
import { useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { deletePaymentMethod, getPaymentMethods, updateDefaultPaymentMethod } from '../../../../redux/thunks/Subscription';

export default function BillingSettingsViewCardModal(props) {

	const { open, processing, handleCloseModal, data } = props;
	const dispatch = useDispatch();
	const billingTableHeader = [
		{ name: 'Card details', id: 'card_details', type: 'component', disableSorting: true, screens: ['web', 'tab', 'mob'] },
		// { name: 'Actions', id: 'actions', type: 'component', disableSorting: true, screens: ['web', 'tab', 'mob'] },
	];
	const [alert, setAlert] = useState(null);

	const handleDeletePaymentMethod = async (id) => {
		const {payload, meta} = await dispatch(deletePaymentMethod({ id: id }));
		if (meta?.requestStatus === RequestStatus.REJECTED) {
			setAlert(<AlertBox
				color='danger'
				description={payload}
				title='Error'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
		} else if (meta?.requestStatus === RequestStatus.FULFILLED) {
			setAlert(<AlertBox
				color='success'
				description={'Your Card has been deleted'}
				title='Success'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
		}
		await dispatch(getPaymentMethods());
	};
	const handleSetDefaultPaymentMethod = async (id) => {
		await dispatch(updateDefaultPaymentMethod({ payment_method_id: id }));
		await dispatch(getPaymentMethods());
	};
	const rowsData = useMemo(() => {
		if (data) {
			return data?.map((card, index) => {
				return {
					card_details: <div key={index} className='mt-3 d-flex justify-content-between align-items-center' style={{background:'#F6F5F8',borderRadius:'32px',padding:'8px 18px 8px 18px',marginBottom:'12px'}}>
						<div style={{ gap: '8px' }} className='payment-card-option d-flex flex-row'>
							<Radio varrient='radio-with-custom-component' onChangeRadioOption={() => handleSetDefaultPaymentMethod(card.id)}
								checked={card.id === card?.customer?.invoice_settings.default_payment_method} />
							<div className='payment-card-compny'>
								<div className='payment-card-owner-detail'>
									<span className='body-3'>{card.billing_details.name}</span>
									<div style={{ gap: '8px' }} className='d-flex align-items-center'>
										<MasterIcon />
										<span className='body-4 dark-50'>**** **** {card?.card?.last4}</span>
									</div>
								</div>
							</div>
						</div>
						<div className='d-flex justify-content-center'>
							{card.id === card?.customer?.invoice_settings.default_payment_method ?
								<TagIcon />
								:
								<>
									{processing ?
										<ProcessingIndicator />
										:
										<div className='pointer'  onClick={() => handleDeletePaymentMethod(card.id)}><DeleteIcon /></div>
									}
								</>
							}
						</div>
					</div>,
					// actions: <div className='d-flex justify-content-center'>
					// 	{card.id === card?.customer?.invoice_settings.default_payment_method ?
					// 		<TagIcon />
					// 		:
					// 		<>
					// 			{processing ?
					// 				<ProcessingIndicator />
					// 				:
					// 				<div onClick={() => handleDeletePaymentMethod(card.id)}><DeleteIcon /></div>
					// 			}
					// 		</>
					// 	}
					// </div>,
					name: card.billing_details?.name,
					brand: card.card?.brand,
					number: card?.card?.last4
				};
			});
		}
		return [];
	}, [data, processing]);

	return (
		<>
			<CustomModal open={open} 
				varrient='alert-warning-modal'
				successAlert={true}
				title='My Cards'
				// description='Currently active cards'
				confirmBtnTitle='Done'
				hideScendBtn={true}
				onConfirm={handleCloseModal}
			>
				{alert}
				<div className='view-card-modal d-flex flex-column' >
					<span className='body-2 dark-100'>
						Currently active cards
					</span>
					<div>
						{rowsData.length > 0 ? (
							<div className='card-table'>
								{rowsData.map((rowData, index) => (
									<div key={index}>
										{rowData.card_details}
									</div>
								))}
							</div>
						) : (
							<div className='w-100 body-1 dark-100 text-center'>
									No Card History
							</div>
						)}
					</div>
				</div>
			</CustomModal>
		</>
	);
}
