import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '../../../components/common/Table';
import { PathName } from '../../../utils/routes';
import { getFormatedDate, calculateTimeDifferenceInDays } from '../../../utils/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { listCandidates } from '../../../redux/thunks/Candidate';
import Input from '../../../components/sub-component/Input';
import { ReactComponent as Search } from '../../../assets/icons/Views/candidate/assessments/search.svg';

export default function CandidateListingView() {
	const candidateTableHeader = [
		{
			id: 'name',
			name: 'Name',
			type: 'string',
			disableSorting: true,
			screens: ['web', 'tab', 'mob'],
		},
		{
			id: 'testTaken',
			name: 'Test Taken',
			type: 'number',
			disableSorting: false,
			screens: ['web', 'tab', 'mob'],
		},
		{
			id: 'email',
			name: 'Email',
			type: 'string',
			disableSorting: true,
			screens: ['web', 'tab'],
		},
		{
			id: 'invitedOn',
			name: 'Invited on',
			type: 'string',
			disableSorting: false,
			screens: ['web'],
		},
		{
			id: 'lastActivity',
			name: 'Last Activity',
			type: 'string',
			disableSorting: false,
			screens: ['web', 'tab', 'mob'],
		},
	];

	const customSorting = {
		testTaken: (a, b) => {
			return a - b;
		},
		invitedOn: (a, b) => {
			const dateA = new Date(a);
			const dateB = new Date(b);
			return dateA - dateB;
		},
		lastActivity: (a, b) => {
			const dateA = new Date(a);
			const dateB = new Date(b);
			return dateA - dateB;
		},
	};

	const searchColumns = [
		'name',
		'testTaken',
		'email',
		'invitedOn',
		'lastActivity',
	];
	const [customSearch, setCustomSearch] = useState();
	const [loading, setLoading] = useState(true);
	const [highlightActiveRow, setHighlightActiveRow] = useState(false);
	const [testData, setTestData] = useState([]);
	const candidate = useSelector((state) => state.CandidateReducer);
	const auth = useSelector((state) => state.authReducer);
	const subscription = useSelector((state) => state.subscriptionReducer);
	const expireAt = subscription?.user_subscription.length > 0 ? subscription?.user_subscription[0]?.expires_at : null;
	const daysLeft = calculateTimeDifferenceInDays(expireAt);
	const openLeftDaysDisclaimer = sessionStorage.getItem('openLeftDaysDisclaimer');

	const history = useHistory();
	const dispatch = useDispatch();

	useMemo(() => {
		if (candidate?.data === null) return [];
		const _data = candidate?.data?.map((candidate, index) => {
			return {
				name: candidate?.first_name + ' ' + candidate?.last_name,
				testTaken: candidate?.total_assessments,
				email: candidate?.email,
				invitedOn: getFormatedDate(candidate?.invited_on, true),
				lastActivity: getFormatedDate(candidate?.last_activity, true),
				...candidate,
			};
		});
		setTestData(_data ? [..._data] : []);
		setLoading(false);
	}, [candidate?.data]);
	
	useEffect(() => {
		dispatch(listCandidates());
	}, []);

	const handleRowSelect = (row) => {
		history.push(`${PathName.CANDIDATES}/${row.id}`);
	};

	return (
		<div className='dashboard-page' style={{ overflow: 'hidden' }}>
			<div className='page-header'>
				<span className='headline-3 dark-100' >
					{'Candidates'}
				</span>
			</div>
			<div className={`${(daysLeft < 6 && auth?.data?.tenant_status === 'trialing' && !openLeftDaysDisclaimer) && ''} page-content`} >
				{candidate?.loading &&
					<div
						style={{
							position: 'fixed',
							top: '0',
							bottom: '0',
							left: '0',
							right: '0',
							height: '25px',
							width: '25px',
							margin: 'auto',
						}}
					>
						<div
							className='processing-indicator'
							style={{ width: '100%', height: '100%' }}
						/>
					</div>
				}
				<div className='w-100 hide-scrollbar' style={{ height: 'calc(100vh - 135px)', borderRadius: '24px', background: 'white', overflowY: 'auto' }}>
					{/* <div className='assessments-search-bar mt-3 mb-3'>
							<div className='d-flex' style={{ gap: '0.5em' }}>
								<div className='search'>
									<Search height={'24px'} width={'24px'} />
									<Input
										inputStyle={{
											background: 'transparent',
											border: 'none',
										}}
										className={'w-100'}
										placeholder={'Search by name...'}
										onChange={(e) => {
											setCustomSearch(e);
										}}
									/>
								</div>
							</div>
						</div> */}

					{(candidate?.loading || loading) ?
						<div className='processing-indicator-wrapper medium-indicator w-100 h-100 d-flex align-items-center justify-content-center'>
							<div
								className='processing-indicator primary'
								style={{ width: '40px', height: '40px' }}
							/>
						</div>
						:
						(testData === null || !testData?.length) ?
							<div className='default-assessment-card-container'>
								<div className='default-assessment-text' style={{ marginTop: '24px' }}>
									<span className='body-1 dark-100'>Invite candidates for assessments to start seeing them here.</span>
								</div>
							</div>
							:
							<Table
								headers={candidateTableHeader}
								setHighlightActiveRow={setHighlightActiveRow}
								data={testData}
								onSelectRow={handleRowSelect}
								searchColumns={searchColumns}
								customSearch={customSearch}
								customSorting={customSorting}
								// disableSearchInput={true}
								nullTableTitle={'No candidates to show'}
								nullTableDescription={'Candidates who have attempted your assessments are shown here'}
							/>
					}
				</div>
			</div>
		</div>
	);
}
