import React, { useState, useEffect, useMemo } from 'react';
import { ReactComponent as SetingIcon } from '../../../../assets/icons/components/dashboard/settings.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/components/dashboard/plus.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/components/dashboard/info.svg';
import { ReactComponent as VisaIcon } from '../../../../assets/icons/components/dashboard/visa.svg';
import { ReactComponent as PaymentIcon } from '../../../../assets/icons/pricing/dollar-sign.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/pricing/download-icon.svg';
import { ReactComponent as AlertIcon } from '../../../../assets/icons/pricing/alert-triangle.svg';
import Table from '../../../common/Table';
import Radio from '../../../sub-component/Radio';
import BillingSettingsAddCardModal from './BillingSettingsAddCardModal';
import BillingSettingsViewCardModal from './BillingSettingsViewCardModal';
import { useMediaQuery } from '@mui/material';
import Plan from '../../../auth/Plan';
import Payment from '../../../auth/Payment';
import CustomModal from '../../../common/Modal';
import { ReactComponent as Check } from '../../../../assets/icons/pricing/tick.svg';
import Button from '../../../sub-component/Button';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
	stripePublicKey,
	fullBlock,
	RequestStatus,
} from '../../../../utils/Constants';
import {
	getSubscriptions,
	updateSubscription,
	cancelSubscriptions,
	getPaymentMethods,
	updateDefaultPaymentMethod,
	getInvoices,
	getUpComingInvoice,
	retryInvoices,
} from '../../../../redux/thunks/Subscription';
import { getTenant } from '../../../../redux/thunks/Tenant';
import { useDispatch, useSelector } from 'react-redux';
import {
	utcToDate,
	formatDateFromSeconds,
	calculateTimeDifferenceInDays,
	dateDiffInDaysAndHours,
} from '../../../../utils/utilities';
import AlertBox from '../../../common/AlertBox';
import ProcessingIndicator from '../../../common/ProcessingIndicator';
import CustomTooltip from '../../../common/CustomTooltip';

export default function BillingSettings(props) {
	const stripePromise = loadStripe(stripePublicKey);
	const dispatch = useDispatch();
	const subscription = useSelector((state) => state.subscriptionReducer);
	const auth = useSelector((state) => state.authReducer);
	const tenants = useSelector((state) => state.tenantReducer);

	const expireAt =
		subscription?.user_subscription.length > 0
			? subscription?.user_subscription[0]?.expires_at
			: null;
	const daysLeft = calculateTimeDifferenceInDays(expireAt);
	const openLeftDaysDisclaimer = sessionStorage.getItem(
		'openLeftDaysDisclaimer'
	);

	const billingTableHeader = [
		{
			id: 'date',
			name: 'Date',
			type: 'string',
			disableSorting: false,
			screens: ['web', 'tab', 'mob'],
		},
		{
			id: 'amount',
			name: 'Amount',
			type: 'number',
			disableSorting: false,
			screens: ['web', 'tab', 'mob'],
		},
		{
			id: 'invoiceStatus',
			name: 'Status',
			type: 'component',
			disableSorting: true,
			screens: ['web', 'tab'],
			enableFilter: true,
			filterName: 'status',
			filterOptions: [
				{ name: 'Paid', value: 'paid' },
				{ name: 'Pending', value: 'open' },
				{ name: 'Cancelled', value: 'void' },
				{ name: 'Draft', value: 'draft' },
			],
		},
		{
			id: 'actions',
			name: 'Actions',
			type: 'component',
			disableSorting: true,
			screens: ['web', 'tab', 'mob'],
		},
	];

	const customSorting = {
		amount: (a, b) => {
			const parseScore = (scoreString) => {
				return parseFloat(scoreString);
			};
			return parseScore(a) - parseScore(b);
		},
		date: (a, b) => {
			const dateA = new Date(a);
			const dateB = new Date(b);
			return dateA - dateB;
		},
		status: (a, b) => {
			return a - b;
		},
	};
	const isOnWebScreen = useMediaQuery('(min-width:1280px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
	const [isViewCardModalOpen, setIsViewCardModalOpen] = useState(false);
	const [plan, setPlan] = useState(null);
	const [openConfirmationDialogue, setOpenConfirmationDialogue] =
		useState(false);
	const [changePlan, setChangePlan] = useState(false);
	const [openCancelPlanDialogue, setOpenCancelPlanDialogue] = useState(false);
	const [openNotCancelPlanDialogue, setOpenNotCancelPlanDialogue] =
		useState(false);
	const [openPlansDialogue, setOpenPlansDialogue] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [alert, setAlert] = useState(null);
	const [loader, setLoader] = useState(true);
	const [trigerEvent, setTrigerEvent] = useState(false);

	function addOneMonthAndFormat() {
		// Get the current date
		const currentDate = new Date();

		// Add 1 month to the current date
		const nextMonthDate = new Date(currentDate);
		nextMonthDate.setMonth(currentDate.getMonth() + 1);

		// Format the date as '20/07/2024'
		const formattedDate = `${nextMonthDate.getDate()}/${String(
			nextMonthDate.getMonth() + 1
		).padStart(2, '0')}/${nextMonthDate.getFullYear()}`;

		// Return the formatted date
		return formattedDate;
	}

	const handleConfirmPlan = (plan) => {
		setPlan(plan);
		setOpenPlansDialogue(false);
		setOpenConfirmationDialogue(true);
	};

	const handleUpdatePlan = (plan) => {
		setPlan(plan);
		setOpenPlansDialogue(false);
		setOpenConfirmationDialogue(true);
		setChangePlan(true);
	};

	const handleRetryInvoices = async () => {
		const { payload, meta } = await dispatch(retryInvoices());
		if (payload === 'No pending invoice') {
			setAlert(
				<AlertBox
					color='warning'
					description={payload}
					title='Alert'
					isOpen={true}
					onCloseAlert={() => {
						setAlert(null);
					}}
				/>
			);
		} else if (meta?.requestStatus === RequestStatus.REJECTED) {
			setAlert(
				<AlertBox
					color='danger'
					description={payload}
					title='Error'
					isOpen={true}
					onCloseAlert={() => {
						setAlert(null);
					}}
				/>
			);
		} else if (meta?.requestStatus === RequestStatus.FULFILLED) {
			setAlert(
				<AlertBox
					color='success'
					description={'All Pending invoices are updated'}
					title='Success'
					isOpen={true}
					onCloseAlert={() => {
						setAlert(null);
					}}
				/>
			);
			setTrigerEvent(!trigerEvent);
			setLoader(true);
		}
	};

	const getSubscription = async () => {
		await dispatch(
			getSubscriptions({
				expand: '[stripe_product, tenant, subscription_usage]',
			})
		);
		await dispatch(getPaymentMethods());
		await dispatch(getInvoices());
		await dispatch(getUpComingInvoice());
		await dispatch(getTenant({ id: auth?.data?.tenant_id }));
		setLoader(false);
	};

	useEffect(() => {
		getSubscription();
	}, [trigerEvent]);

	useEffect(() => {
		if (subscription?.user_subscription) {
			setPlan(subscription?.user_subscription[0]);
		}
	}, [subscription?.user_subscription]);

	const getOverage = (attempts, usage) => {
		if (usage - attempts > 0) {
			return usage - attempts;
		}
		return 0;
	};

	const getTotalCost = (attempts, usage, cost_per_assessment) => {
		if (getOverage(attempts, usage)) {
			return getOverage(attempts, usage) * (cost_per_assessment / 100);
		} else {
			return 0;
		}
	};

	const rowsData = useMemo(() => {
		if (subscription?.invoices) {
			const statusMap = {
				open: 'Pending',
				paid: 'Paid',
				void: 'Cancelled',
				draft: 'Draft',
			};
			return subscription.invoices?.data?.map((invoice, id) => {
				return {
					date: utcToDate(invoice.created),
					amount: `${invoice.status === 'paid'
						? invoice.amount_paid / 100
						: invoice.amount_due / 100
					}$`,
					invoiceStatus: (
						<div
							key='1'
							className={`${invoice.status === 'paid'
								? 'success-tag'
								: invoice.status === 'open'
									? 'pending-tag'
									: invoice.status === 'void'
										? 'fail-tag'
										: 'draft-tag'
							}`}
						>
							{statusMap[invoice.status]}
						</div>
					),
					actions: (
						<div
							className='d-flex justify-content-center'
							style={{ width: '65px' }}
						>
							{' '}
							<a href={invoice.invoice_pdf}>
								<DownloadIcon />
							</a>{' '}
						</div>
					),
					...invoice,
				};
			});
		}
		return [];
	}, [subscription?.invoices]);

	if (loader) {
		return <ProcessingIndicator />;
	}

	// if (!plan) {
	// 	// return <Subscription onConfirmPlan={handleConfirmPlan} />
	// 	return (
	// 		<div className='dashboard-page'>
	// 			<div className='page-header'>
	// 				<span className='headline-medium semibold black-700'>{'Billing Settings'}</span>
	// 			</div>
	// 			<div className='payment-message'>
	// 				<span className='body-1'><span className='medium primary-text'>{`You have ${tenants?.curr_tenant?.free_assessments} free assessment${tenants?.curr_tenant?.free_assessments === 1 ? '' : 's'}. `}</span> Once all your free assessment attempts get consumed, you won&apos;t be able to invite any new candidates until you set up billing for your account. Any existing candidates with pending assessments will not be able to start them. To prevent this, please select a plan and enter your payment information.</span>
	// 			</div>
	// 			<Plan onConfirmPlan={handleConfirmPlan} />
	// 		</div>
	// 	);
	// }

	return (
		<>
			<CustomModal
				open={openConfirmationDialogue}
				title='Confirm Plan'
				confirmBtnTitle={`${changePlan ? 'Update Plan' : 'Add a payment Method'
				}`}
				varrient='alert-warning-modal'
				processing={subscription?.processing}
				successAlert={true}
				onConfirm={async () => {
					if (changePlan) {
						const { payload, error } = await dispatch(
							updateSubscription({
								stripe_pricing_id: plan?.id,
								sub_id: subscription?.user_subscription[0]?.id,
							})
						);
						if (error) {
							setAlert(
								<AlertBox
									description={`Your Selected plan can't be added to this Subscription because this is already in use!`}
									color='danger'
									title='Error'
									onCloseAlert={() => {
										setAlert(null);
									}}
								/>
							);
						} else {
							await dispatch(
								getSubscriptions({
									expand: '[stripe_product, tenant, subscription_usage]',
								})
							);
							setOpenConfirmationDialogue(false);
							setIsDeleteModalOpen(true);
						}
					} else {
						setOpenConfirmationDialogue(false);
						setIsAddCardModalOpen(true);
					}
				}}
				handleCloseModal={() => {
					if (!changePlan) {
						setPlan(null);
					}
					setOpenConfirmationDialogue(false);
					setTrigerEvent(!trigerEvent);
				}}
				btnWidth={`${changePlan ? '150px' : '220px'}`}
			>
				<div className='plan-confirm-dialogue'>
					{plan && (
						<div className='plan-details'>
							<div className='plan-details-header'>
								<span className='headline-4 white'>
									{plan?.name}
								</span>
								<span className='headline-1 white'>
									<span
										className=' headline-1 white'
									>
										{plan?.price}
									</span>
									<span className='body-1 white'>/month</span>
								</span>
							</div>
							<div className='plan-details-info'>
								{plan?.details?.map((feature, index) => {
									return (
										<div key={index} className='plan-details-info-check'>
											<span className='plan-text body-2 dark-100'>{feature}</span>
											<Check />
										</div>
									);
								})}
							</div>
							<div className='plan-pricing'>
								<div className='w-100 d-flex justify-content-between '>
									<span className='body-2 dark-100'>Total Payment</span>
									<span className='body-2 dark-100'>{plan?.price}</span>
								</div>
								<div className='w-100 d-flex justify-content-between body-2 dark-100'>
									<span >
										{auth?.data?.tenant_status === 'trialing'
											? 'Payment Due at Trial End'
											: 'Payment Due Today'}
									</span>
									<span>{plan?.price}.00</span>
								</div>
								{auth?.data?.tenant_status === 'trialing' && (
									<div>
										<span className='body-2 dark-50'>
											Next Payment: {plan?.price}.00 on {addOneMonthAndFormat()}
										</span>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</CustomModal>
			<div className='plans-modal-container' >
				{isOnWebScreen && (
					<CustomModal
						open={openPlansDialogue}
						hideBtns={true}
						customClass='w-1000'
					>
						<div className='plans-modal'>
							<Plan
								onConfirmPlan={
									subscription.user_subscription?.length > 0
										? handleUpdatePlan
										: handleConfirmPlan
								}
								handleCloseModal={() => setOpenPlansDialogue(false)}
								selectedPlan={subscription?.user_subscription[0]}
								isUpdatePlan={true}
							/>
						</div>
					</CustomModal>
				)}
			</div>
			<CustomModal
				open={isDeleteModalOpen}
				varrient='alert-warning-modal'
				successAlert={true}
				title='Plan Updated'
				description={`Your plan has been successfully update to: ${plan?.stripe_product?.name}`}
				confirmBtnTitle='Done'
				hideScendBtn={true}
				onConfirm={() => {
					setIsDeleteModalOpen(false);
					setTrigerEvent(!trigerEvent);
					setLoader(true);
				}}
			/>
			<CustomModal
				open={openCancelPlanDialogue}
				varrient='alert-warning-modal'
				dangerAlert={true}
				title='Cancel subscription?'
				dangerText={`Are you sure you want to cancel the subscription?`}
				description={`After cancellation, you will not be able to invite candidates unless you have Free Assessments.`}
				confirmBtnTitle='Yes, I’m Sure'
				cancelBtnTitle='Not Now'
				btnWidth='150px'
				handleCloseModal={() => {
					setOpenCancelPlanDialogue(false);
				}}
				onConfirm={async () => {
					const { error } = await dispatch(
						cancelSubscriptions({
							sub_id: subscription?.user_subscription[0]?.id,
						})
					);
					if (error) {
						setOpenNotCancelPlanDialogue(true);
					} else {
						setOpenCancelPlanDialogue(false);
						setTrigerEvent(!trigerEvent);
						setLoader(true);
					}
				}}
			/>
			<CustomModal
				open={openNotCancelPlanDialogue}
				varrient='alert-warning-modal'
				warningAlert={true}
				title='You cannot cancel your Subscrption!'
				description={`Please pay your latest pending invoice in order to cancel your subscription.`}
				confirmBtnTitle='Done'
				hideScendBtn={true}
				onConfirm={() => {
					setOpenNotCancelPlanDialogue(false);
					setOpenCancelPlanDialogue(false);
				}}
			/>
			<div className='dashboard-page hide-scrollbar d-flex flex-column' style={{ marginTop: isOnMobileScreen ? '24px' : '0px'}} >
				{fullBlock.includes(auth?.data?.tenant_status) && (
					<div className='payment-message'>
						<AlertIcon />
						<span className='body-2'>
							Missing billing information or failed to charge your payment
							method. Please fix the issue in billing settings or contact your
							Account Owner if you don&apos;t have the necessary permissions.
						</span>
					</div>
				)}
				<div className='page-header d-flex justify-content-between align-items-center w-100 flex-row' style={{padding: isOnMobileScreen ? '0px' : '12px'}}>
					<span className='headline-3 dark-100'>
						{'Billing Settings'}
					</span>

					{isOnMobileScreen ? 
						<div  style={{height:'44px',width:'44px' , background:'#121216',borderRadius:'50px',display:'flex',justifyContent:'center',alignItems:'center'}}
							onClick={() => {
								handleRetryInvoices();
							}}> <PaymentIcon /></div>
					
						: <Button
							btn='ragular-btn'
							size='md'
							varrient='primary'
							preIcon={<PaymentIcon />}
							title={'Pay Latest Pending Invoice'}
							style={{ width: 'max-content' ,	background: '#121216',}}
							onClick={() => {
								handleRetryInvoices();
							}}
					
						/>
					
					}
				</div>
				<div
					className={`${daysLeft < 6 &&
						auth?.data?.tenant_status === 'trialing' &&
						!openLeftDaysDisclaimer &&
						'left-day-message-main-box'
					} page-content`}
				>
					{!isOnWebScreen && openPlansDialogue ? (
						<div className='plans-modal-container' style={{padding : isOnMobileScreen ? '12px' : ''}}  >
							<div className='plans-modal'>
								<Plan
									onConfirmPlan={
										subscription.user_subscription?.length > 0
											? handleUpdatePlan
											: handleConfirmPlan
									}
									handleCloseModal={() => setOpenPlansDialogue(false)}
									selectedPlan={subscription?.user_subscription[0]}
									isUpdatePlan={true}
								/>
							</div>
						</div>
					) : (
						<>
							{alert}
							<div className='billing-settings'>
								<div className='billing-settings-detail'>
									<div className='billing-settings-plan'>
										<div className='d-flex flex-column' style={{gap:'24px'}}>
											<div className='payment-head'>
												<span className='subtitle-1'>
												Plan Details
												</span>
												{auth?.data?.tenant_status === 'trialing' && (
													<div className='trialing-message'>
														<span className='body-3 dark-50'>
														Your free trial will end in{' '}
															<CustomTooltip
																text={`${new Date(
																	formatDateFromSeconds(plan?.expires_at)
																).toDateString()} / ${new Date(
																	formatDateFromSeconds(plan?.expires_at)
																).toLocaleTimeString()}`}
															>
																<span className='dark-100'>
																	{dateDiffInDaysAndHours(plan?.expires_at)}
																</span>
															</CustomTooltip>{' '}
														After the trial ends, your free assessment attempts
														will expire, and you will have to add a payment
														method to continue using Testfuse.
														</span>
													</div>
												)}
											</div>
											<div className='plan-detail-container'>
												<div className='plan-detail-inner-container1'>
													<div className='plan-detail-text'>
														<span className='dark-50 body-2'>Current Plan:</span>
														<span className='dark-100 body-2'>
															{plan?.stripe_product?.name
																? plan?.stripe_product?.name
																: 'None'}
														</span>
													</div>
													<div className='plan-detail-text'>
														<span className='dark-50 body-2'>
														Free Assessment Attempts:
														</span>
														<span className='body-2 dark-100'>
															{tenants?.curr_tenant?.free_assessments
																? tenants?.curr_tenant?.free_assessments
																: '0'}
														</span>
													</div>
													<div className='plan-detail-text'>
														<span className='dark-50 body-2'>
														Assessments in Plan:
														</span>
														<span className='dark-100 body-2'>
															{auth?.data?.tenant_status === 'trialing' ||
															auth?.data?.tenant_status === 'canceled' ||
															auth?.data?.tenant_status === 'free'
																? '-/-'
																: `${Math.min(
																	Math.max(
																		parseInt(
																			plan?.subscription_usage?.assessment_usage
																		),
																		0
																	),
																	plan?.stripe_product?.included_attempts
																)}/${plan?.stripe_product?.included_attempts}`}
														</span>
													</div>
												</div>
												<div className='plan-detail-inner-container2'>
													<div className='plan-detail-text'>
														<span className='dark-50 body-2'>
														Overage Assessments:
														</span>
														<span className='body-2 dark-100'>
															{getOverage(
																plan?.stripe_product?.included_attempts,
																plan?.subscription_usage?.assessment_usage
															)}
														</span>
													</div>
													<div className='plan-detail-text'>
														<span className='dark-50 body-2'>Next Payment:</span>
														<span className='body-2 dark-100'>
															{auth?.data?.tenant_status === 'canceled' ||
															auth?.data?.tenant_status === 'free'
																? 'N/A'
																: `${(
																	subscription?.upcoming_invoice?.amount_due /
																100
																).toFixed(1)} USD`}
														</span>
													</div>
													<div className='plan-detail-text'>
														<span className='body-2 dark-50'>
															{!plan?.cancel_at_period_end &&
															plan?.status === 'trialing'
																? 'Trial Ends On:'
																: plan?.cancel_at_period_end
																	? 'Cancels On:'
																	: 'On:'}
														</span>
														<span className='body-2 dark-100'>
															{plan?.expires_at
																? utcToDate(plan?.expires_at, 'short')
																: 'N/A'}
														</span>
													</div>
												</div>
											</div>
										</div>
										{plan?.cancel_at_period_end ? (
											<div>
												<span className='body-3 medium'>
													*Note: Your subscription will be automatically
													cancelled at the end of your billing period{' '}
													<span
														className='semibold primary-text'
														style={{ textDecorationLine: 'underline' }}
													>{`(after ${daysLeft} day${daysLeft === 1 ? '' : 's'
														})`}</span>
													. You will be able to use Testfuse normally till then.
													Any overage acrued during this time will be charged at
													the end of this period. You will be able to
													resubscribe after cancellation.
												</span>
											</div>
										) : (
											<div
												className='d-flex flex-row'
												style={{ marginTop: '32px', gap: '16px' }}
											>
												{auth?.data?.tenant_status !== 'canceled' &&
													auth?.data?.tenant_status !== 'free' && (
													<Button
														btn='ragular-btn'
														varrient='secondary-btn'
														onClick={() => {
															setOpenCancelPlanDialogue(true);
														}}
														style={{ height: '50px', width: '210px' }}
														title={'Cancel Subscription'}
													/>
												)}
												<Button
													btn='ragular-btn'
													varrient='primary'
													onClick={() => {
														setOpenPlansDialogue(true);
													}}
													style={{ height: '50px', width: '150px' }}
													title={
														auth?.data?.tenant_status === 'canceled' ||
															auth?.data?.tenant_status === 'free'
															? 'Select Plan'
															: 'Change Plan'
													}
												/>
											</div>
										)}
									</div>
									<div className='billing-settings-payment'>
										<div className='payment-head'>
											<span className='headline-small semibold'>
												Payment Methods
											</span>
										</div>
										<Elements
											stripe={stripePromise}
											options={{
												fonts: [
													{
														cssSrc:
															'https://fonts.googleapis.com/css?family=Roboto:300,300i,400,500,600',
													},
												],
											}}
										>
											<BillingSettingsAddCardModal
												addCard={
													subscription.user_subscription?.length > 0
														? true
														: false
												}
												setIsAddCardModalOpen={setIsAddCardModalOpen}
												setTrigerEvent={setTrigerEvent}
												trigerEvent={trigerEvent}
												setLoader={setLoader}
												open={isAddCardModalOpen}
												plan={plan}
											/>
										</Elements>
										{subscription.payment_methods?.data?.length > 0 ? (
											<div
												className='d-flex flex-column'
												style={{ gap: '16px' }}
											>
												{subscription.payment_methods?.data
													?.slice(0, 2)
													.map((payment_method, index) => {
														return (
															<div key={index} className='payment-card-detail' style={{padding:'8px 18px'}}>
																<div className='payment-card-option'>
																	<Radio
																		onChangeRadioOption={async () => {
																			await dispatch(
																				updateDefaultPaymentMethod({
																					payment_method_id: payment_method.id,
																				})
																			);
																			await dispatch(getPaymentMethods());
																		}}
																		checked={
																			payment_method.id ===
																			payment_method?.customer?.invoice_settings
																				.default_payment_method
																		}
																		varrient='radio-with-custom-component'
																	/>
																	<div className='payment-card-compny'>
																		<VisaIcon />
																		<div className='payment-card-owner-detail'>
																			<span className='body-2'>
																				{payment_method.billing_details.name}
																			</span>
																			<span className='body-2' style={{color:'#96999C'}}>
																				**** **** {payment_method?.card?.last4}
																			</span>
																		</div>
																	</div>
																</div>
																<span className='body-2 dark-50'>
																	Card expires on:{' '}
																	{`${payment_method?.card?.exp_month > 9
																		? ''
																		: '0'
																	}${payment_method?.card?.exp_month}`}
																	/
																	{payment_method?.card?.exp_year
																		?.toString()
																		.slice(-2)}
																</span>
															</div>
														);
													})}
											</div>
										) : (
											<div className='payment-card-detail'>
												<span className='body-3' style={{marginLeft:'16px'}}>
													Your card information will be displayed here...
												</span>
												<CustomTooltip
													text={`If you're Trialing, adding a payment method will end the trial and will mark the start of your subscription.`}
												>
													<InfoIcon />
												</CustomTooltip>
											</div>
										)}
										<span
											className='body-2 dark-100 pointer' style={{marginLeft:'5px',textDecoration:'underline'}}
											onClick={() => {
												setIsViewCardModalOpen(true);
											}}
										>
											View All
										</span>
										<div className='add-card-button'>
											<Button
												btn='ragular-btn'
												varrient='primary'
												disabled={
													subscription.payment_methods?.data?.length === 5
												}
												title={'Add Card'}
												style={{ height: '50px', width: '150px' }}
												onClick={() => {
													setIsAddCardModalOpen(true);
												}}
											/>
										</div>
										<BillingSettingsViewCardModal
											open={isViewCardModalOpen}
											data={subscription?.payment_methods?.data}
											processing={subscription?.is_loading}
											handleCloseModal={() => {
												setIsViewCardModalOpen(false);
											}}
										/>
									</div>
								</div>
								
								<div className='w-100' style={{minHeight: 'calc(100vh - 515px)',borderRadius:'24px',background:'white'}}>
									{ subscription.invoices?.data === null || !subscription.invoices?.data?.length ? 
										<div className='default-assessment-card-container'>
											<div className='default-assessment-text' style={{marginTop: '24px'}}>
												<span className='body-1 dark-100'>Oops! there’s no payment history to display in this table</span>
											</div>
										</div>
										:
										<Table
											headers={billingTableHeader}
											data={rowsData}
											disableSearchInput={true}
											customSorting={customSorting}
											nullTableTitle={'No Payment History'}
											nullTableDescription={`Oops! there’s no payment history to display in this table`}
										/>
									}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
