import React, {useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, getTanentProfile } from '../../redux/thunks/UserProfile';
import CryptoJS from 'crypto-js';
import hexDigest from 'crypto-js/enc-hex';
import { GenerateHash } from '../../redux/thunks/CustomerSupport';

export default function ChatwootComponent({ baseUrl, websiteToken, children }) {

	const { userProfileData, data } = useSelector((state) => state.userProfileReducer); //here data is for tanent profile data
	const auth = useSelector((state) => state.authReducer);
	const dispatch = useDispatch();
	const USER_NAME = `${userProfileData?.first_name} ${userProfileData?.last_name}`;
	
	// const [hash, setHash] = useState(null);
	useEffect(() => {
		if (auth.data?.access_token) {
			dispatch(getUserProfile({}));
			if (auth.data?.role?.id !== 'candidate') {
				dispatch(getTanentProfile({ id: '', isCallForCurrAssessment: false }));
			}
		}
	}, [auth.data?.access_token]);
	useEffect(() => {
		const loadChatwootScript = () => {
			const g = document.createElement('script');
			const s = document.getElementsByTagName('script')[0];
			g.src = `${baseUrl}/packs/js/sdk.js`;
			g.defer = true;
			g.async = true;
			s.parentNode.insertBefore(g, s);
			
			
			
			g.onload = async () => {
				
				window.chatwootSDK.run({
					websiteToken: websiteToken,
					baseUrl: baseUrl
				});
				if (auth?.data?.access_token && userProfileData ) {	
					// const accessToken = auth.data?.access_token;
					const result =  await dispatch(GenerateHash());
					const hash = result.payload[1].hash;
					
					if (hash)
					{
						window.$chatwoot.setUser(auth?.data?.user_id.toString(), {
							email: auth?.data?.email,
							name: USER_NAME,
							identifier_hash: hash,
						});
					}
				}		
			};
		
		};
		// Check if document object is available (client-side rendering)
		if (typeof document !== 'undefined') {
			loadChatwootScript();
		}
	}, [baseUrl, websiteToken, auth.data.access_token, userProfileData, data]);
	return (
		<div>
			{children}
		</div>
	);
}