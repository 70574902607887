import React, { useState, useEffect } from 'react';
import EmptyNotiImage from '../../../assets/images/dashboard/Empty-Noti.svg';
import MarkUnreadNotiImage from '../../../assets/images/dashboard/Mark-Unread-Noti.svg';
import { ReactComponent as BulletIcon } from '../../../assets/icons/components/dashboard/Ellipse 249.svg';
import ProcessingIndicator from '../../../components/common/ProcessingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { getNotification, patchMarkAllRead } from '../../../redux/thunks/Notification';
import { getFormatedDate, calculateTimeDifferenceInDays } from '../../../utils/utilities';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import { useInView } from 'react-intersection-observer';
import { resetNotificationData } from '../../../redux/slices/NotificationSlice';
import debounce from 'lodash.debounce'; // Import lodash debounce

export default function Notifications() {
  const [filteredStatus, setFilteredStatus] = useState('');
  const [isReady, setIsReady] = useState(true);
  const [page, setPage] = useState(1);
  const [limit] = useState(11);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [skipEffect, setSkipEffect] = useState(false);

  const notification = useSelector((state) => state.notificationReducer);
  const auth = useSelector((state) => state.authReducer);
  const subscription = useSelector((state) => state.subscriptionReducer);
  const expireAt = subscription?.user_subscription.length > 0 ? subscription?.user_subscription[0]?.expires_at : null;
  const daysLeft = calculateTimeDifferenceInDays(expireAt);
  const openLeftDaysDisclaimer = sessionStorage.getItem('openLeftDaysDisclaimer');
  const dispatch = useDispatch();
  const now = moment().format('YYYY MM DD');
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isOnWebScreen = useMediaQuery('(min-width:1280px)');

  const filteredNotifications = (filteredStatus === '')
    ? notification?.notificationData
    : notification?.notificationData?.filter((_notification) => _notification?.status === filteredStatus);

  useEffect(() => {
    if (skipEffect || isLoading) return;
    if(page === 1){
      dispatch(resetNotificationData());
    }
    const fetchNotifications = async () => {
      if (notification?.totalRecords > notification?.notificationData?.length || notification) {
        setIsLoading(true);
        await dispatch(getNotification({ page, limit, status: filteredStatus }));
        setIsLoading(false);
        setIsFetchingMore(false);
      }
    };

    fetchNotifications();
  }, [page]);
//   useEffect(() => {
//   // Check if the data is already fetched
//   if (!notification?.notificationData?.length && !isLoading) {
//     setPage(1); // Reset to first page when switching back to this tab
//     dispatch(getNotification({ page: 1, limit, status: filteredStatus }));
//   }
// }, [dispatch, filteredStatus, notification?.notificationData, isLoading]);

  const handleMarkAllRead = async () => {
    setIsLoading(true);
    setSkipEffect(true);

    const dispatched = await dispatch(patchMarkAllRead({ data: { status: 'READ' } }));

    if (dispatched?.meta?.requestStatus === 'fulfilled') {
      setPage(1);
      dispatch(resetNotificationData());

      await dispatch(getNotification({ page: 1, limit, status: filteredStatus }))
        .finally(() => {
          setIsLoading(false);
          setSkipEffect(false);
          setIsFetchingMore(false);
        });
    } else {
      setIsLoading(false);
      setSkipEffect(false);
    }
  };

  const displayReadNotification = () => setFilteredStatus('READ');
  
  const { ref, inView } = useInView({
    threshold: 1,
    delay: 5000,
  });
  const getInitial = (eventType) => {
    if (eventType) {
      const splittedString = eventType.split('_');
      return splittedString[splittedString.length - 1][0];
    } else {
      return '';
    }
  };

  const displayAllNotification = () => {
    setPage(1);
    setFilteredStatus('');
  };

  const displayUnReadNotification = () => {
    setPage(1);
    setFilteredStatus('UNREAD');
  };

  // Debounced scroll handler
  const handleScroll = debounce(() => {
    const scrollableElement = document.querySelector('.notification-view-container');
    const scrollTop = scrollableElement.scrollTop;
    const scrollHeight = scrollableElement.scrollHeight;
    const clientHeight = scrollableElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 10 && !isFetchingMore && notification?.totalRecords > notification?.notificationData?.length) {
      setIsFetchingMore(true);
      setPage((prevPage) => prevPage + 1);
    }
  }, 100); // Debounce set to 300ms to prevent rapid firing

  useEffect(() => {
    const scrollableElement = document.querySelector('.notification-view-container');
    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isFetchingMore, page, notification?.totalRecords, notification?.notificationData?.length]);

  return (
    <div className='dashboard-page hide-scrollbar'>
      <div className='page-header hide-scrollbar'>
        <div className='w-100 d-flex align-items-center justify-content-between'>
          <span className='headline-3 dark-100'>Notification</span>
          <div className='d-flex' style={{ gap: '12px' }}>
            <span className='body-2 dark-100 pointer underline' onClick={filteredStatus === '' ? displayUnReadNotification : displayAllNotification}>{filteredStatus === '' ? 'View Unread' : 'View All'}</span>
            <span className='body-2 dark-100 pointer underline' onClick={() => { handleMarkAllRead() }}>Mark all as read</span>
          </div>
        </div>
      </div>
      <div className={`${(daysLeft < 6 && auth?.data?.tenant_status === 'trialing' && !openLeftDaysDisclaimer) && 'left-day-message-main-box'} page-content`}>
        <div className='notification-view-container hide-scrollbar'>
          {(!isLoading && (filteredNotifications === null || !filteredNotifications?.length)) ? (
            <div className='default-notification-alert'>
              <span className='body-1 dark-100 text-center'>No new notifications at the moment!</span>
            </div>
          ) : (
            [...filteredNotifications, null]?.map((data, i) => (
              i !== filteredNotifications.length ? (
                <div key={i} className='notification-message-card d-flex flex-row'>
                  <div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
                    <div className='d-flex align-items-center' style={{ gap: '8px' }}>
                      <span>
                        {data.status === 'UNREAD' && <BulletIcon width={10} height={10} />}
                      </span>
                      <div className='profile-icon'>
                        <span className='bold'>{getInitial(data.event_type)}</span>
                      </div>
                    </div>
                    <span className='body-2 dark-100'>{data.message_body}</span>
                  </div>
                  <div className='d-flex flex-row' data-toggle='tooltip' data-placement='top' title={`${getFormatedDate(data.createdAt)}`}>
                    <span className='body-2 pointer dark-50 mt-2' style={{ marginLeft: '5px' }}>
                      {
                        moment(now).diff(moment(data.createdAt).format('YYYY MM DD'), 'days') === 0
                          ? getFormatedDate(data.createdAt)
                          : moment(now).diff(moment(data.createdAt).format('YYYY MM DD'), 'days') === 1
                            ? '1 day ago'
                            : `${moment(now).diff(moment(data.createdAt).format('YYYY MM DD'), 'days')} days ago`
                      }
                    </span>
                  </div>
                </div>
              ) : (
                notification?.notificationData?.length && !isLoading ? <div key={i} ref={ref}></div> : <></>
              )
            ))
          )}

          {isLoading && notification?.notificationData?.length < notification?.totalRecords &&
            <div className='w-100 d-flex justify-content-center align-items-center' style={{marginTop:'0px',height:'100%'}}>
              <div style={{ height: '20px', width: '20px' }}>
                <ProcessingIndicator />
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
