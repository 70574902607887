import React, { useState, useEffect } from 'react';
import { ReactComponent as AssessmentUploadImageIcon } from '../../../assets/icons/Views/dashboard/test-detail/Group 13693.svg';
import { ReactComponent as CreatedOn } from '../../../assets/icons/Views/dashboard/test-detail/check-square.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/Views/dashboard/assessments/clock.svg';
import { ReactComponent as TimerIcon } from '../../../assets/icons/Views/dashboard/test-detail/HourglassMedium.svg';
import { ReactComponent as SBTIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/skill.svg';
import { ReactComponent as CTIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/code.svg';
import { ReactComponent as VFTIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/value.svg';
import { ReactComponent as PTIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/smile.svg';
import { ReactComponent as TRIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/text.svg';
import { ReactComponent as CSIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/cst.svg';
import { ReactComponent as VRIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/video.svg';
import { ReactComponent as FUIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/upload.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/p-plus.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/list.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/p-check-square.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/Views/dashboard/assessments/info.svg';
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/Views/dashboard/test-detail/left-arrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/Views/dashboard/assessments/arrow-left.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SectionType, TestType } from '../../../utils/Constants';
import { useHistory, useParams } from 'react-router-dom';
import { getAssessSpec } from '../../../redux/thunks/AssessSpec';
import ProcessingIndicator from '../../../components/common/ProcessingIndicator';
import CustomTooltip from '../../../components/common/CustomTooltip';
import { useMediaQuery } from '@mui/material';
import ReactQuill from 'react-quill';
import { getResource } from '../../../redux/thunks/Resource';
import ReactPlayer from 'react-player';
import { secondsTohhmm } from '../../../utils/utilities';

export default function AssessmentCriteria(props) {
	// const { assessSpec } = props;
	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const [isAssessmentDetails, setIsAssessmentDetails] = useState(true);
	const [isReady, setIsReady] = useState(false);
	const assessSpec = useSelector((store) => store.assessSpecReducer);
	const resource = useSelector((store) => store.resourceReducer);
	const isMobile = useMediaQuery('(max-width: 767px)');
	const isOnTablet = useMediaQuery('(min-width: 768px) and (max-width: 1279px)');

	const calculateTime = (_test) => {
		let totalTime = 0;
		_test?.forEach(_eachTest => {
			_eachTest?.questions.forEach(question => {
				totalTime += question.duration || 0;
			});
		});
		return `${parseInt(totalTime / 60)} mins`;
	};
	const calculateValueTestTime = (_test) => {
		let totalTime = 0;
		_test?.forEach(_eachTest => {
			_eachTest?.values.forEach(value => {
				totalTime += value.duration || 0;
			});
		});
		return `${parseInt(totalTime / 60)} mins`;
	};
	const calculateTestTime = (_test) => {
		let totalTime = 0;
		_test?.forEach(_eachTest => {
			totalTime += _eachTest.duration || 0;
		});
		return `${parseInt(totalTime / 60)} mins`;
	};

	const getTitle = (title) => {

		if (isOnTablet && title.length > 42) {
			return title.slice(0, 42) + '...';
		}
		if (isMobile && title.length > 45) {
			return title.slice(0, 45) + '...';
		}
		return title;
	};

	useEffect(() => {
		if (assessSpec?.curr_assessment_spec?.welcome_video?.video_id) {
			dispatch(getResource({ id: assessSpec?.curr_assessment_spec?.welcome_video?.video_id }));
		}
		setIsReady(true);
	}, []);

	useEffect(() => {
		dispatch(getAssessSpec({ id: params.id, query: '?expand=assessments' }));
		setIsReady(true);
	}, []);

	useEffect(() => {
		if (!isAssessmentDetails && assessSpec?.curr_assessment_spec?.welcome_video?.video_id) {
			dispatch(getResource({ id: assessSpec?.curr_assessment_spec?.welcome_video?.video_id }));
		}
	}, [isAssessmentDetails]);

	if (!isReady) {
		return <ProcessingIndicator className='medium-indicator' />;
	}

	return (
		<>
			<div className='dashboard-page hide-scrollbar' style={{}}>
				{/* <div className='page-header'>
					<div className='d-flex align-items-center' style={{ gap: '12px' }}>
						{isMobile &&
							<BackArrowIcon className='icon-wrapper pointer' onClick={history.goBack} />
						}
						<span className='headline-medium semibold black-700'>{`Assessment Criteria`}</span>
					</div>
				</div>

				<div className='page-content'> */}

				<div className='assessment-criteria d-flex flex-column' style={{ marginTop: '16px' }}>
					{/* <div className='assessment-criteria-header d-flex flex-column'>
							<div className='d-flex flex-row align-items-start' style={{ gap: '16px' }}>
								{!isMobile &&
									<div className='icon-wrapper pointer' onClick={history.goBack} style={{ width: '32px', height: '32px' }}>
										<BackArrowIcon />
									</div>
								}
								<AssessmentUploadImageIcon style={{ width: `${isMobile ? '60px' : '80px'}`, height: `${isMobile ? '60px' : '80px'}` }} />
								<div className='d-flex flex-column align-items-start justify-content-center' style={{ gap: '4px' }}>
									<span className='headline-medium semibold' style={{ color: '#2B3340' }}>{getTitle(assessSpec.curr_assessment_spec.title)}</span>
									<div className='d-flex flex-column align-items-start'>
										<div className='d-flex flex-row align-items-center' style={{ marginTop: '4px' }}>
											<CreatedOn style={{ width: '20px', height: '20px', marginRight: '8.87px' }} />
											<span className={`body-2 black-400`}>Created on <span className='primary-text medium'>{new Date(assessSpec.curr_assessment_spec.created_at).toDateString()}</span></span>
										</div>
										<div className='d-flex align-items-center' style={{ marginTop: '8px' }}>
											<ClockIcon style={{ width: '20px', height: '20px', marginRight: '8.87px' }} />
											<span className={`body-2 black-400`}>Total Time <span className='primary-text medium'>
												{
													secondsTohhmm(assessSpec.curr_assessment_spec.objective_test_duration || assessSpec.curr_assessment_spec.duration || 0)
												}
											</span></span>
											<CustomTooltip customPosition={{ bottom: '10px', left: '10px' }}
												text={(assessSpec.curr_assessment_spec.objective_test_duration !== undefined || assessSpec.curr_assessment_spec.objective_test_duration !== null)
													? 'This cumulative duration applies to objective tests (skill, coding, value fitness). Text and Case Study tests have flexible time (hours to days), while Video and Upload tests are without set time limits.'
													: 'This is the cumulative duration for all test types including Case Study and Text Responses'}  >
												<InfoIcon style={{
													width: isMobile || isOnTablet ? '12' : '16px', height: isMobile || isOnTablet ? '12' : '16px',
													marginLeft: '5px', marginBottom: isMobile || isOnTablet ? '2px' : '5px', stroke: '#6F2DBD'
												}} />
											</CustomTooltip>
										</div>
									</div>
								</div>
							</div>
							<div className='assessment-criteria-heading'>
								<div className={`assessment-criteria-heading-type ${isAssessmentDetails && 'assessment-criteria-heading-type-selected'}`} onClick={() => setIsAssessmentDetails(true)}>
									<span className={`body-2`}>Assessment Details</span>
								</div>
								<div className={`assessment-criteria-heading-type ${!isAssessmentDetails && 'assessment-criteria-heading-type-selected'}`} onClick={() => setIsAssessmentDetails(false)}>
									<span className={`body-2`}>Additional Section(s)</span>
								</div>
							</div>
						</div> */}
					{/* {
					isAssessmentDetails
						? */}
					<div className='assessment-criteria-header'>
						<div className='w-100 d-flex align-items-center' style={{gap: '16px'}}>
							<div className='icon-cover' onClick={history.goBack}>
								<LeftArrowIcon />
							</div>
							<span className='body-2 dark-50 truncate-assessment-title'>{assessSpec?.curr_assessment_spec?.title}</span>
						</div>
						<span className='headline-3 text-start dark-100'>Assessment Criteria</span>
					</div>
					<div className='assessment-detail-container'>
						{assessSpec?.curr_assessment_spec?.qualifying_questions &&
						<div className='test-container'>
							<div className='test'>
								<span className='body-1 dark-100'>Qualifying Questions</span>
							</div>
							{assessSpec?.curr_assessment_spec?.qualifying_questions?.map((currentQQ, index) => {
								return (
									<div key={index} className='case-study-card'>
										<div className='test'>
											<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '12px' }}>
												{currentQQ.type === 'numeric' ?
													<PlusIcon style={{ width: '24px', height: '24px', stroke: '#808191' }} />
													: currentQQ.type === 'boolean' ?
														<CheckIcon style={{ width: '24px', height: '24px', stroke: '#808191' }} />
														:
														<ListIcon style={{ width: '24px', height: '24px', stroke: '#808191' }} />
												}
												<span className='body-1 dark-100'>{currentQQ.type === 'numeric' ? 'Numeric ' : currentQQ.type === 'boolean' ? 'True / False' : 'MCQ`s'}</span>
											</div>
										</div>
										<div key={index} className='qq-card'>
											<div className='w-100 d-flex flex-row justify-content-between'>
												<span className='body-2 dark-100'>{`Question ${index + 1}`}</span>
												<span className='body-2 dark-50'>
													Ideal answer:
													<span className='dark-100' style={{ marginLeft: '8px' }}>
														{currentQQ.type === 'mcq' ? currentQQ.answers[currentQQ.correct_answer] : currentQQ.correct_answer.toString()}
													</span>
												</span>
											</div>
											<span className='body-2 dark-100 text-start'>{currentQQ.statement}</span>
										</div>
									</div>
								);
							})
							}
						</div>
						}
						{assessSpec.curr_assessment_spec?.welcome_video &&
						<div className="test-container">
							<div className='test'>
								<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
									<span className='body-1 dark-100'>Welcome Video</span>
								</div>
							</div>
							<div className='case-study-card'>
								<div className='welcome-video-criteria-section'>
									{assessSpec.curr_assessment_spec?.welcome_video?.type === 'url' ?
										<ReactPlayer
											width={'100%'}
											height={'100%'}
											controls={true}
											url={assessSpec.curr_assessment_spec?.welcome_video?.video_url} />
										:
										<video
											style={{ width: '100%', height: '100%' }}
											src={resource?.data?.url}
											controls={true}
											muted={false}
											disablePictureInPicture={true}
											controlsList='nodownload noremoteplayback noplaybackrate'
										/>
									}
								</div>
							</div>
						</div>
						}
						{
							assessSpec.curr_assessment_spec?.sections?.map((currentSection, index) => {
								if (currentSection.type === SectionType.CODING_TEST) {
									const codingTests = assessSpec.curr_assessment_spec.tests?.filter((_test) => {
										return _test.test_type === TestType.CODING_TEST;
									});
									return (
										<div key={index} className='test-container'>
											<div className='test'>
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '12px' }}>
													<CTIcon style={{ width: '24px', height: '24px', stroke: '#808191' }} />
													<span className='body-1 dark-100'>Coding Test</span>
												</div>
												{codingTests &&
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '8px' }}>
													<ClockIcon style={{ width: '20px', height: '20px' }} />
													<span className='body-2 dark-100'>{calculateTestTime(codingTests)}</span>
												</div>
												}
											</div>
											<div className='questions-container'>
												{
													codingTests?.map((currentTest, index) => {
														return (
															<div key={index} className='coding-test-card'>
																<span style={{ opacity: '0.5' }}>
																	<img src={currentTest?.icon} width='17' height='17' alt='' />
																</span>
																<div className='d-flex flex-column align-items-start'>
																	<span className='body-2 dark-100 text-start'>{currentTest?.title}</span>
																	{/* <span className='body-3 primary-text'>{currentTest?.difficulty}</span> */}
																</div>
															</div>
														);
													})
												}
											</div>
										</div>
									);
								} else if (currentSection.type === SectionType.SKILL_TEST) {
									const skillTests = assessSpec.curr_assessment_spec.tests?.filter((_test) => {
										return _test.test_type === TestType.SKILL_TEST;
									});
									return (
										<div key={index} className='test-container'>
											<div className='test' key={index}>
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '12px' }}>
													<SBTIcon style={{ width: '24px', height: '24px', stroke: '#808191' }} />
													<span className='body-1 dark-100'>Skill Based Test</span>
												</div>
												{skillTests &&
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '8px' }}>
													<ClockIcon style={{ width: '20px', height: '20px' }} />
													<span className='body-2 dark-100'>{calculateTestTime(skillTests)}</span>
												</div>
												}
											</div>
											<div className='w-100 questions-container'>
												{
													skillTests?.map((currentTest, index) => {
														return (
															<div key={index} className='skill-test-card'>
																<div className='d-flex flex-column align-items-start'>
																	<span className='body-2 dark-100 text-start'>{currentTest?.title}</span>
																	{/* <span className='body-3 primary-text'>{currentTest?.difficulty}</span> */}
																</div>
															</div>
														);
													})
												}
											</div>
										</div>
									);
								} else if (currentSection.type === SectionType.VALUE_TEST) {
									const valueTests = assessSpec.curr_assessment_spec.tests?.filter((_test) => {
										return _test.test_type === TestType.VALUE_TEST;
									});
									return (
										<div key={index} className='test-container'>
											<div className='test' key={index}>
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '12px' }}>
													<VFTIcon style={{ width: '24px', height: '24px', stroke: '#808191' }} />
													<span className='body-1 dark-100'>Values Fitness Test</span>
												</div>
												{valueTests &&
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '8px' }}>
													<ClockIcon style={{ width: '20px', height: '20px' }} />
													<span className='body-2 dark-100'>{calculateValueTestTime(valueTests)}</span>
												</div>
												}
											</div>
											{
												valueTests?.map((currentTest, index) => {
													return (
														<div key={index} className='questions-container'>
															{
																currentTest?.values?.map((value, _) => {
																	return (
																		<div key={_} className='value-test-card'>
																			<span className='body-2 dark-100 text-start'>{value?.title}</span>
																		</div>
																	);
																})
															}
														</div>
													);
												})
											}
										</div>
									);
								} else if (currentSection.type === SectionType.PERSONALITY_TEST) {
									const personalityTest = assessSpec.curr_assessment_spec.tests?.filter((_test) => {
										return _test.test_type === TestType.PERSONALITY_TEST;
									});
									{
										return personalityTest.map(personality => {
											return <div key={index} className='test-container'>
												<div className='test' key={index}>
													<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '12px' }}>
														<PTIcon style={{ width: '24px', height: '24px', stroke: '#808191' }} />
														<span className='body-1 dark-100'>Personality Test</span>
													</div>
													{personalityTest &&
													<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '8px' }}>
														<ClockIcon style={{ width: '20px', height: '20px' }} />
														<span className='body-2 dark-100'>{parseInt(personality?.duration / 60)} mins</span>
													</div>
													}
												</div>
												<div className='questions-container'>
													<div className='personality-test-group'>
														<span className='text-start body-2 dark-50' style={{ marginLeft: '18px' }}>Job Role</span>
														<div className='value-test-card'>
															<span className='body-2 dark-100'>{personality?.job_dimensions?.job_role}</span>
														</div>
													</div>
													<div className='personality-test-group'>
														<span className='text-start body-2 dark-50' style={{ marginLeft: '18px' }}>Seniority</span>
														<div className='value-test-card'>
															<span className='body-2 dark-100'>{personality?.job_dimensions?.seniority}</span>
														</div>
													</div>
													<div className='personality-test-group'>
														<span className='text-start body-2 dark-50' style={{ marginLeft: '18px' }}>Industry</span>
														<div className='value-test-card'>
															<span className='body-2 dark-100'>{personality?.job_dimensions?.industry}</span>
														</div>
													</div>
													<div className='personality-test-group'>
														<span className='text-start body-2 dark-50' style={{ marginLeft: '18px' }}>Job Location</span>
														<div className='value-test-card'>
															<span className='body-2 dark-100'>{personality?.job_dimensions?.job_location}</span>
														</div>
													</div>
													<div className='personality-test-group'>
														<span className='text-start body-2 dark-50' style={{ marginLeft: '18px' }}>Customer Facing</span>
														<div className='value-test-card'>
															<span className='body-2 dark-100'>{personality?.job_dimensions?.is_customer_facing ? 'Yes' : 'No'}</span>
														</div>
													</div>
													<div className='personality-test-group'>
														<span className='text-start body-2 dark-50' style={{ marginLeft: '18px' }}>Leadership Position</span>
														<div className='value-test-card'>
															<span className='body-2 dark-100'>{personality?.job_dimensions?.is_leadership_position ? 'Yes' : 'No'}</span>
														</div>
													</div>
												</div>
											</div>;
										});
									}
								} else if (currentSection.type === SectionType.CASE_STUDY_TEST) {
									const caseStudyTest = assessSpec?.curr_assessment_spec?.tests?.find((t) => {
										return t.test_type === TestType.CASE_STUDY_TEST;
									});
									return (
										<div key={index} className='test-container'>
											<div className='test' key={index}>
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '12px' }}>
													<CSIcon style={{ width: '24px', height: '24px', stroke: '#808191' }} />
													<span className='body-1 dark-100'>Case Study</span>
												</div>
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '8px' }}>
													<ClockIcon style={{ width: '20px', height: '20px' }} />
													<span className='body-2 dark-100'>{calculateTestTime(assessSpec?.curr_assessment_spec?.tests.filter(t => t.test_type === TestType.CASE_STUDY_TEST))}</span>
												</div>
											</div>
											<div className='d-flex flex-column' style={{ gap: '16px' }}>
												<div className='case-study-card'>
													<span className={`body-2 dark-50`}>Introductory Text</span>
													<ReactQuill
														className='ql-editor-test-instruction'
														theme='bubble'
														readOnly
														value={caseStudyTest?.intro_text}
													/>
												</div>
												{caseStudyTest?.questions?.map(
													(question, index) => {
														return (
															<div className='case-study-card' key={index}>
																<span className={`subtitle-2 dark-100`}>{`Question ${index + 1}`}</span>
																<div className='test-container'>
																	<ReactQuill
																		className='ql-editor-test-instruction'
																		theme='bubble'
																		readOnly
																		value={question.text}
																	/>
																</div>
															</div>
														);
													},
												)}
											</div>
										</div>
									);
								} else if (currentSection.type === SectionType.TEXT_TEST) {
									const textTests = assessSpec.curr_assessment_spec.tests?.filter((_test) => {
										return _test.test_type === TestType.TEXT_TEST;
									});
									return (
										<div key={index} className='test-container'>
											<div className='test' key={index}>
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '12px' }}>
													<TRIcon style={{ width: '24px', height: '24px', stroke: '#808191' }} />
													<span className='body-1 dark-100'>Text Responses</span>
												</div>
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '8px' }}>
													<ClockIcon style={{ width: '20px', height: '20px' }} />
													<span className='body-2 dark-100'>{calculateTime(textTests)}</span>
												</div>
											</div>
											{
												textTests?.map((currentTest, index) => {
													return (
														<div key={index} className='d-flex flex-column align-items-start' style={{ gap: '16px' }}>
															{
																currentTest?.questions?.map((question, qIndex) => {
																	return (
																		<div key={qIndex} className='text-response-card'>
																			<div className='w-100 d-flex flex-row justify-content-between'>
																				<span className='body-2 dark-100'>{`Question ${qIndex + 1}`}</span>
																				<span className='body-2 dark-50'>Response Time:<span className='dark-100' style={{ marginLeft: '8px' }}>{`${question.duration / 60} mins`}</span></span>
																			</div>
																			<span className='body-2 dark-100 text-start'>{question.text}</span>
																		</div>
																	);
																})
															}
														</div>
													);
												})
											}
										</div>
									);
								} else if (currentSection.type === SectionType.VIDEO_TEST) {
									const videoTests = assessSpec.curr_assessment_spec.tests?.filter((_test) => {
										return _test.test_type === TestType.VIDEO_TEST;
									});
									return (
										<div key={index} className='test-container'>
											<div className='test' key={index}>
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '12px' }}>
													<VRIcon style={{ width: '24px', height: '24px', stroke: '#808191' }} />
													<span className='body-1 dark-100'>Video Responses</span>
												</div>
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '8px' }}>
													<ClockIcon style={{ width: '20px', height: '20px' }} />
													<span className='body-2 dark-100'>{calculateTime(videoTests)}</span>
												</div>
											</div>
											{
												videoTests?.map((currentTest, index) => {
													return (
														<div key={index} className='d-flex flex-column align-items-start' style={{ gap: '16px' }}>
															{
																currentTest?.questions?.map((question, qIndex) => {
																	return (
																		<div key={qIndex} className='text-response-card'>
																			<div className='w-100 d-flex flex-row justify-content-between'>
																				<span className='body-2 dark-100'>{`Question ${qIndex + 1}`}</span>
																				<span className='body-2 dark-50'>Record Time:<span className='dark-100' style={{ marginLeft: '8px' }}>{`${question.duration / 60} mins`}</span></span>
																			</div>
																			<span className='body-2 dark-100 text-start'>{question.text}</span>
																		</div>
																	);
																})
															}
														</div>
													);
												})
											}
										</div>
									);
								} else if (currentSection.type === SectionType.UPLOAD_TEST) {
									const uploadTests = assessSpec.curr_assessment_spec.tests?.filter((_test) => {
										return _test.test_type === TestType.UPLOAD_TEST;
									});
									return (
										<div key={index} className='test-container'>
											<div className='test' key={index}>
												<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '12px' }}>
													<FUIcon style={{ width: '24px', height: '24px', stroke: '#808191' }} />
													<span className='body-1 dark-100'>File Upload</span>
												</div>
											</div>
											{
												uploadTests?.map((currentTest, index) => {
													return (
														<div key={index} className='d-flex flex-column align-items-start' style={{ gap: '16px' }}>
															{
																currentTest?.questions?.map((question, qIndex) => {
																	return (
																		<div key={qIndex} className='text-response-card'>
																			<span className='body-2 dark-100'>{`Statement ${qIndex + 1}`}</span>
																			<span className='body-2 dark-100 text-start'>{question.text}</span>
																		</div>
																	);
																})
															}
														</div>
													);
												})
											}
										</div>
									);
								}
							})
						}
					</div>
					{/* :
								<div className='assessment-detail-container'>
									<div className='test-container'>
										<div className='test'>
											<span className='body-1 dark-100'>Qualifying Questions</span>
										</div>
										{
											assessSpec.curr_assessment_spec?.qualifying_questions?.map((currentQQ, index) => {
												return (
													<div key={index} className='case-study-card'>
														<div className='test'>
															<div className='d-flex flex-row align-items-center justify-content-center' style={{ gap: '12px' }}>
																{currentQQ.type === 'numeric' ? 
																	<PlusIcon style={{ width: '24px', height: '24px', stroke: '#808191' }}/>
																	: currentQQ.type === 'boolean' ?
																		<CheckIcon style={{ width: '24px', height: '24px', stroke: '#808191' }}/> 
																		: 
																		<ListIcon style={{ width: '24px', height: '24px', stroke: '#808191' }}/>
																}
																<span className='body-1 dark-100'>{currentQQ.type === 'numeric' ? 'Numeric ' : currentQQ.type === 'boolean' ? 'True / False' : 'MCQ`s'}</span>
															</div>
														</div>
														<div key={index} className='qq-card'>
															<div className='w-100 d-flex flex-row justify-content-between'>
																<span className='body-2 dark-100'>{`Question ${index + 1}`}</span>
																<span className='body-2 dark-50'>
																	Ideal answer: 
																	<span className='dark-100' style={{marginLeft: '8px'}}>
																		{currentQQ.type === 'mcq' ? currentQQ.answers[currentQQ.correct_answer] : currentQQ.correct_answer.toString()}
																	</span>
																</span>
															</div>
															<span className='body-2 dark-100'>{currentQQ.statement}</span>
														</div>
													</div>
												);
											})
										}
									</div>
									<div className="test-container">
										<div className='test'>
											<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
												<span className='body-1 dark-100'>Welcome Video</span>
											</div>
										</div>
										{assessSpec.curr_assessment_spec?.welcome_video && 
											<div className='case-study-card'>
												<div className='welcome-video-criteria-section'>
													{
														assessSpec.curr_assessment_spec?.welcome_video?.type === 'url' ?
															<ReactPlayer
																width={'100%'}
																height={'100%'}
																controls={true}
																url={assessSpec.curr_assessment_spec?.welcome_video?.video_url} />
															:
															<video
																style={{ width: '100%', height: '100%' }}
																src={resource?.data?.url}
																controls={true}
																muted={false}
																disablePictureInPicture={true}
																controlsList='nodownload noremoteplayback noplaybackrate'
															/>
													}
												</div>
											</div>
										}
									</div>
								</div>
						} */}
				</div>
				{/* </div> */}
			</div >
		</>
	);
}

