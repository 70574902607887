import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import SideMenu from '../components/dashboard/SideMenu';
import { getSubscriptions } from '../redux/thunks/Subscription';
import { DashboardRoutes, PathName } from '../utils/routes';
import { calculateTimeDifferenceInDays } from '../utils/utilities';
import { ReactComponent as CrossIcon } from '../assets/icons/Views/dashboard/test-detail/x.svg';
import { useMediaQuery } from '@mui/material';
import { UserRole } from '../utils/Constants';

export default function Dashboard() {
	// /**@type {AuthState} */
	const history = useHistory();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.authReducer);
	const subscription = useSelector((state) => state.subscriptionReducer);
	const [openLeftDaysDialog, setOpenLeftDaysDialog] = useState(true);
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const expireAt =
		subscription?.user_subscription.length > 0 &&
		subscription?.user_subscription[0]?.expires_at;
	const daysLeft = calculateTimeDifferenceInDays(expireAt);
	const openLeftDaysDisclaimer = sessionStorage.getItem(
		'openLeftDaysDisclaimer'
	);

	const handleCancelLeftDaysDisclaimer = () => {
		sessionStorage.setItem('openLeftDaysDisclaimer', true);
		setOpenLeftDaysDialog(false);
	};

	useEffect(() => {
		if (auth.data.role.name !== 'Candidate') {
			dispatch(
				getSubscriptions({
					expand: '[stripe_product, tenant, subscription_usage]',
				})
			);
		}
	}, []);

	return (
		<>
			<Switch>
				{
					<div
						className='dashboard-container dashboard-db hide-scrollbar'
						style={{ padding: isOnMobileScreen ? '12px' : '24px', gap: isOnMobileScreen ? '0px' : '24px', overflowX: 'hidden' }}
					>
						{auth.is_menu_visible && <SideMenu />}
						<div className='dashboard-page-view hide-scrollbar' id='dashboard-page-view-id' style={{height: (auth.data?.role && auth.data.role.name === UserRole.CANDIDATE && isOnMobileScreen) && '100vh'}}>
							{daysLeft < 6 &&
								subscription?.user_subscription[0]?.status === 'trialing' &&
								!openLeftDaysDisclaimer && (
								<div className='left-day-message-box'>
									<span
										className='body-1 dark-50'
										style={{ width: 'calc(100% - 44px)' }}
									>
										<span className='dark-100'>{`${daysLeft} day${daysLeft === 1 ? '' : 's'} left in trial.`}</span>{' '}
											Once the trial is over, add a payment method from the{' '}
										<span className='dark-100'
											style={{ textDecorationLine: 'underline' }}
											onClick={() => {
												history.push(
													`${PathName.SETTINGS + PathName.BILLING_SETTINGS}`
												);
											}}
										>
											Billing
										</span>{' '}
											screen to continue using Testfuse!
									</span>
									<div className='d-flex pointer' onClick={handleCancelLeftDaysDisclaimer}>
										<CrossIcon />
									</div>
								</div>
							)}
							{Object.values(DashboardRoutes).map((route, index) => {
								if (
									route.required_roles.some(
										(item) => auth.data.role.name === item
									)
								) {
									return (
										<Route
											exact={route.exact}
											path={`${route.pathname}`}
											key={index}
										>
											{<route.component routeDetails={route} />}
										</Route>
									);
								}
							})}
							<Route
								exact
								path={`${PathName.MAIN}`}
								render={() => (
									<Redirect to={`${DashboardRoutes.assesments.pathname}`} />
								)}
							/>
						</div>
					</div>
				}
			</Switch>
		</>
	);
}
