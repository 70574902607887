import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/Views/dashboard/assessments/trash.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/Views/dashboard/assessments/plus3.svg';

import 'react-quill/dist/quill.snow.css';
import { useMediaQuery } from '@mui/material';
import Input from '../../../../sub-component/Input';
import SectionContainer from './SectionContainer';
import CustomSwitch from '../../../../sub-component/Switch';

export default function FileUpload(props) {
	const [questions, setQuestions] = useState(
		props?.sectionDetails?.test?.questions.length === 0
			? [{ is_mandatory: false, question: '' }]
			: props?.sectionDetails?.test?.questions,
	);

	const isOnTabletScreen = useMediaQuery('(max-width:1439px)');

	const updateData = (_questions) => {
		props.getUpdatedSectionLocalData({
			...props.sectionDetails,
			test: { ...props.sectionDetails.test, questions: _questions },
		});
	};

	useEffect(() => {
		if (props.error) {
			props.setCheckValidation(true);
		}
	}, [questions]);


	useEffect(() => {
		setQuestions(props?.sectionDetails?.test?.questions.length === 0 ? [{ is_mandatory: false, question: '' }] : props?.sectionDetails?.test?.questions);
	}, [props?.sectionDetails?.test?.questions]);

	useEffect(() => {

		if (props?.resetAssessmentCreationSection) {
			setQuestions(props?.sectionDetails?.test?.questions.length === 0 ? [{ is_mandatory: false, question: '' }] : props?.sectionDetails?.test?.questions);
			props?.getToKnowIFStateUpdated('upload_test_updated', true);
		}

	}, [props?.resetAssessmentCreationSection]);



	const changeIsMandatory = (index) => {
		const updatedQuestions = questions.map((question, i) => {
			if (i == index) {
				return {
					...question,
					is_mandatory: !question.is_mandatory
				};
			}
			return question;
		});
		setQuestions(updatedQuestions);
		updateData(updatedQuestions);
	};

	const onConfirm = () => {
		props.onConfirm({
			...props.sectionDetails,
			test: { ...props.sectionDetails.test, questions: questions },
		});
	};

	const onCheckSectionDataConfermation = () => {
		props.onCheckSectionDataConfermation({
			...props.sectionDetails,
			test: { ...props.sectionDetails.test, questions: questions },
		});
	};

	const onCancel = () => {
		props.onCancel({
			...props.sectionDetails,
			test: { ...props.sectionDetails.test, questions: questions },
		});
	};

	return (
		<SectionContainer
			title={'Upload File '}
			description={
				'Your candidate will see an option to upload any type of file with each question/statement.'
			}
			enableDuration={false}
			onRemoveSection={() => {
				props.onRemoveSection();
			}}
			onConfirm={onConfirm}
			onCancel={onCancel}
			error={props.error}
		>
			<div className='w-100 d-flex flex-column align-items-start' style={{gap: '16px', marginTop: '10px'}}>
				{
					<>
						{questions?.map((_question, i) => {
							return (
								<div key={i} className={`text-response-question-container`}>
									<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{ gap: '12px' }}>
										<div className='w-100 d-flex flex-row align-items-center' style={{ gap: '12px' }}>
											<span className='body-1 dark-50' style={{ width: '130px' }}>{`Statement ${i + 1}:`}</span>
											<CustomSwitch
												label={'Mark Mandatory'}
												checked={_question.is_mandatory}
												onChange={() => changeIsMandatory(i)}
											/>
										</div>
										{questions.length > 1 && (
											<TrashIcon
												className='pointer'
												style={{ width: '24px', height: '24px' }}
												onClick={() => {
												// eslint-disable-next-line prefer-const
													const _questions = [...questions];
													_questions.splice(i, 1);
													setQuestions([..._questions]);
													updateData([..._questions]);
												// onCheckSectionDataConfermation();
												}}
											/>
										)}
									</div>
									<div className='w-100 d-flex flex-column align-items-start' >
										<Input
											varrient={'primary'}
											placeholder='Text here *'
											name='question'
											className={
												(props?.error?.required?.questions
														&& props?.error?.required?.questions?.filter((q, _) => { return q.index === i; })[0]) || null
													? 'primary-input-error'
													: ''
											}
											title={'Create statement'}
											value={_question.question}
											onChange={(e) => {
												const _questions = questions;
												if (i <= questions.length) {
													_questions[i] = {
														..._questions[i],
														question: e.target.value,
													};
												} else {
													_questions.push({
														question_type: '',
														question: e.target.value,
													});
												}
												onCheckSectionDataConfermation();
												setQuestions([..._questions]);
											}}
											onBlur={() => { updateData(questions); }}
										/>
										{((props?.error?.required?.questions && props?.error?.required?.questions?.filter((q, _) => { return q.index === i; })[0]) || null) &&
											<span className='body-3 danger-text text-start' style={{paddingLeft: '24px'}}>Add some text here to fix the issue</span>
										}
									</div>
								</div>
							);
						})}
					</>
				}
				{questions?.length < 5 ?
					<div className='add-question'
						onClick={() => {
							onCheckSectionDataConfermation();
							setQuestions([...questions, { is_mandatory: false, question: '' }]);
							updateData([...questions, { is_mandatory: false, question: '' }]);
						}}
					>
						<PlusIcon style={{stroke: '#121216'}}/>
						<span className='body-3 dark-100'>Add another question</span>
					</div>
					:
					<div className='error-box'>
						<span className='body-3 text-danger'>
						You can only add 5 Upload Question at a time!
						</span>
					</div>
				}
			</div>
		</SectionContainer>
	);
}
