import React from 'react';
import ReactQuill from 'react-quill';
import hljs from 'highlight.js';

const modules = {
	syntax: {
		highlight: (text) => hljs.highlightAuto(text).value,
	},
};

export default function ReactQuillBubble({ value, className }) {
	return (
		<div>
			<ReactQuill
				className={`disable-text-selection ${className}`}
				theme='bubble'
				readOnly
				modules={modules}
				value={value}
			/>
		</div>
	);
}
