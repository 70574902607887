import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const getPublicResult = createAsyncThunk(
    'publicResult/getPublicResult',
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {
            const request = await instance.request({
                url: `/v1/assessments/public_results/${id}`,
                method: 'GET',
            });

            return request?.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    },
);

export const getPublicCodeReport = createAsyncThunk(
    'publicResult/getPublicCodeReport',
    async ({ data }, { rejectWithValue, dispatch }) => {
        try {
            const request = await instance.request({
                url: `/v1/assessments/public_code_report?assess_id=${data?.assess_id}&test_id=${data?.test_id}`,
                method: 'GET',
            });

            return request?.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    },
);