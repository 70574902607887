import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance_resource, secure_instance_resource } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const Actions = {
	setUploadProgress: null,
};


export const createResource = createAsyncThunk(
	'resource/createResource',
	async ({ data, signal }, { rejectWithValue, dispatch }) => {
		try {
			// let formData;
			// if (typeof data === 'string') {
			// 	const svgData = new Blob([data], { type: 'image/svg+xml' });
			// 	formData = new FormData();
			// 	formData.append('file', svgData, 'icon.svg');
			// } else {
			// 	formData = data;
			// }

			const response = await secure_instance_resource.request({
				url: '/v1/resources/',
				method: 'POST',
				data: data,
				signal: signal || null,
				onUploadProgress: (event) => {
					const perc = parseFloat(((event.loaded / event.total) * 100).toFixed(2));
					if (Actions.setUploadProgress !== null) {
						dispatch(Actions.setUploadProgress(perc));
					}
				}
			});

			return response.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const getResource = createAsyncThunk(
	'resource/getResource',
	async ({ id }, { rejectWithValue, dispatch }) => {

		try {

			const response = await secure_instance_resource.request({
				url: `/v1/resources/${id}`,
				method: 'GET',
			});

			return { id: id, ...response.data };
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	}
);

export const getPublicResource = createAsyncThunk(
	'resource/getPublicResource',
	async ({ data }, { rejectWithValue, dispatch }) => {
		try {
			const response = await instance_resource.request({
				url: `/v1/resources/public_resource`,
				method: 'GET',
				params: {
					resource_id: data?.resource_id,
					assess_id: data?.assess_id
				},
			});

			return response.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const deleteResource = createAsyncThunk(
	'resource/deleteResource',
	async ({ id }, { rejectWithValue, dispatch }) => {

		try {

			const response = await secure_instance_resource.request({
				url: `/v1/resources/${id}`,
				method: 'DELETE',
			});

			return { id: id, ...response.data };
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	}
);

export const resetResourceState = createAsyncThunk(
	'resource/resetResourceState',
	async () => {

		return {
			loading: false,
			processing: false,
			processing_delete: false,
			data: null,
			upload_percentage: 0,
			error_message: null,
			success_message: null,
		};

	}
);