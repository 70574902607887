import { createSlice } from '@reduxjs/toolkit';

const AlertSlice = createSlice({
    name: 'Alert Slice',
    initialState: {
        title: '',
        message: '',
        color: '',
        autoRemove: false,
    },
    reducers: {
        updateSlice: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const { updateSlice } = AlertSlice.actions;
export default AlertSlice.reducer;
