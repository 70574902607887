import { createSlice } from '@reduxjs/toolkit';
import { getTanentProfile, getUserProfile, patchTanentProfile, patchUserProfile } from '../thunks/UserProfile';

const UserProfileSlice = createSlice({
	name: 'User Profile Slice',
	initialState: {
		loading: false,
		processing: false,
		data: null,
		userProfileData: null,

		processing_patch_profile: false,
		curr_assess_tenant_profile: null,

		error_message: null,
		success_message: null,
	},

	extraReducers: {

		[getTanentProfile.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[getTanentProfile.fulfilled]: (state, { payload }) => {
			if (payload.isCallForCurrAssessment) {
				return {
					...state,
					loading: false,
					curr_assess_tenant_profile: payload.data,
				};
			}
			else {
				return {
					...state,
					loading: false,
					data: payload.data,
				};
			}
		},
		[getTanentProfile.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

		[patchTanentProfile.pending]: (state) => {
			return {
				...state,
				loading: true,
				processing_patch_profile: true
			};
		},
		[patchTanentProfile.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				processing_patch_profile: false,
				data: payload,
			};
		},
		[patchTanentProfile.rejected]: (state) => {
			return {
				...state,
				loading: false,
				processing_patch_profile: false,
			};
		},

		[getUserProfile.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[getUserProfile.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				userProfileData: payload,
			};
		},
		[getUserProfile.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

		[patchUserProfile.pending]: (state) => {
			return {
				...state,
				loading: true,
				processing_patch_profile: true
			};
		},
		[patchUserProfile.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				processing_patch_profile: false,
				userProfileData: payload,
			};
		},
		[patchUserProfile.rejected]: (state) => {
			return {
				...state,
				loading: false,
				processing_patch_profile: false,
			};
		},

	}

});

export default UserProfileSlice.reducer;