import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

// import logo from '../../assets/icons/logo.svg';
import Button from './../components/sub-component/Button';
import OnboardingBox from '../components/OnboardingBox';
import { ActivationState, RequestStatus } from '../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { activateAccount, resendActivationToken } from '../redux/thunks/Auth';
import { removeAllCookies } from '../utils/utilities';
import { useMediaQuery } from '@mui/material';
// import AlertBox from '../components/common/AlertBox';
// import { PathName } from '../utils/routes';

function AccountActivation() {

	/**@type {AuthState}*/
	const auth = useSelector((state) => state.authReducer);

	// const history = useHistory();
	const dispatch = useDispatch();
	const params = useParams();
	const search = useLocation().search;
	const [accountActivationState, setAccountActivationState] = useState(ActivationState.PENDING);
	// const [processing, setProcessing] = useState(false);
	const [processingResend, setProcessingResend] = useState(false);
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	const handleActivation = useCallback(async () => {

		// setProcessing(true);

		const { error } = await dispatch(activateAccount({ token: params.token }));

		// setProcessing(false);

		if (error) {
			setAccountActivationState(ActivationState.FAILED);
			return;
		}

		setAccountActivationState(ActivationState.SUCCESS);

		// window.location.reload();
		// setAuth({ is_active: true });

	}, []);

	const handleResend = useCallback(async () => {

		setProcessingResend(true);

		const { meta } = await dispatch(resendActivationToken({ email: auth.data.email }));

		setProcessingResend(false);

		if (meta.requestStatus === RequestStatus.REJECTED) {
			setAccountActivationState(ActivationState.FAILED);
			return;
		}

	});

	useEffect(() => {

		handleActivation();

	}, [handleActivation]);


	return (
		<>
			<OnboardingBox>
				<div className="d-flex flex-column signup-form-container" style={{ padding: '0px' }}>
					{
						accountActivationState === ActivationState.PENDING && <>
							<div className="form">
								<span style={{ height: '30px', width: '30px', display: 'block', margin: 'auto' }}>
									<span className='processing-indicator primary' />
								</span>
							</div>
						</>
					}
					{
						accountActivationState === ActivationState.FAILED && <>
							<div className="form">
								<div className={`${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-3'} bold`}>
									Link Expired
								</div>
								<div className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '2'} dark-50`} style={{ marginTop: '8px', lineHeight: '24px' }}>
									It seems that this link has expired. Please generate a new link.
								</div>
								<div style={{ marginTop: '32px' }}>
									<Button
										rectangle-button
										onClick={handleResend}
										title={'Resend Link'}
										processing={processingResend}
										style={{ height: '40px' }}
									/>
								</div>
							</div>
						</>
					}
					{
						accountActivationState === ActivationState.SUCCESS && <>
							<div className="form">
								<div className={`heading ${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-3'}`}>
									Account Activated
								</div>
								<div className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '2'} dark-50`} style={{ marginTop: '10px', lineHeight: '24px' }}>
									Your account has been successfully activated! Press login button to proceed
								</div>
								<div className="submit-button" style={{ marginTop: '32px' }}>
									<Button rectangle-button
										onClick={() => {
											// const _search = search || '';
											removeAllCookies();
											window.location.replace(`/${search || ''}`);
											// history.push({
											// 	pathname: '/',
											// 	...(search & { search: search })
											// });
										}}
										title={'Login'}
										style={{ height: '40px' }}
									/>
								</div>
							</div>
						</>
					}
				</div>
			</OnboardingBox>

		</>
	);
}
export default AccountActivation;