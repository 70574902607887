import React, { useState, useEffect } from 'react';
import Button from '../components/sub-component/Button';
import { useMediaQuery } from '@mui/material';

export default function CookieDisclaimer() {
	const [disclaimerVisible, setDisclaimerVisible] = useState(false);
	const isMobile = useMediaQuery('(max-width: 766px)');

	useEffect(() => {
		const appCookieDisclaimerAccepted = localStorage.getItem(
			'appCookieDisclaimerAccepted'
		);
		if (!appCookieDisclaimerAccepted) {
			setDisclaimerVisible(true);
		}
	}, []);

	const handleAcceptCookieDisclaimer = () => {
		localStorage.setItem('appCookieDisclaimerAccepted', 'true');
		setDisclaimerVisible(false);
	};

	return (
		<div className={`cookie-disclaimer ${disclaimerVisible ? 'cookie-disclaimer-visible' : ''}`}>
			<div className='cookie-disclaimer-content'>
				<span  className='body-2 text-start dark-50'>
					We use cookies to ensure you get the best experience. Read our  
					<a
						href={`${window._env_.TF_LANDING_SITE_URL}/privacy-policy`}
						rel='noopener noreferrer'
						target='_blank'
						className= ' dark-100'
						style={{marginLeft:'5px'}}
					>
						Cookie Policy.
					</a>
				</span>
				<div className='h-100 d-flex align-items-center justify content center'>
					<Button
						btn='ragular-btn'
						varrient='primary'
						title={'Got it!'}
						style={{ width: 'max-content', height: '40px' }}
						onClick={handleAcceptCookieDisclaimer}
					/>
				</div>
			</div>
		</div>
	);
}
