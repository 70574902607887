import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createResource, deleteResource, resetResourceState } from '../../../../redux/thunks/Resource';
import { ReactComponent as AlertIcon } from '../../../../assets/icons/pricing/alert-circle.svg';
import VideoRecorder from '../../../../components/candidate-assessment-flow/VideoRecorder';
import { PersonalityTestQuestionTypes, TestType } from '../../../../utils/Constants';
import ProcessingIndicator from '../../../../components/common/ProcessingIndicator';
import TextArea from '../../../../components/sub-component/TextArea';
import Button from '../../../../components/sub-component/Button';
import { useFocusWithin } from '@react-aria/interactions';
import Timer from '../../../../components/common/Timer';
import TestContentWrapper from './TestContentWrapper';

export default function PersonalityTestCS(props) {
	
	const { question, currentQuestionNo, totalQuestions, assessmentId, processing } = props;

	const dispatch = useDispatch();
	const resource = useSelector((state) => state.resourceReducer);

	const [answer, setAnswer] = useState('');
	const [isVideoUploading, setIsVideoUploading] = useState(false);
	const [videoResourceId, setVideoResourceId] = useState(null);
	const [uploadBlobURL, setUploadBlobURL] = useState(null);
	const [error, setError] = useState();
	const [pVRDuration, setPVRDuration] = useState(0);
	const validAnswerLength = answer?.length >= 500;

	const handleVideoFileUpload = async (blobURL) => {
		setError('');
		const blob = await fetch(blobURL).then((r) => r.blob());
		const file = new File([blob], `${Date.now().toString()}.mp4`, {
			type: 'video/mp4',
		});
		setIsVideoUploading(true);
		const formData = new FormData();
		formData.append('file', file);
		const { payload, meta } = await dispatch(createResource({ data: formData, signal: null }));
		if (meta.requestStatus === 'rejected') {
			setError('Failed to upload video. Please check your internet connection and retry.');
			setIsVideoUploading(false);
			return;
		}
		else {
			setVideoResourceId(payload.id);
			setUploadBlobURL(null);
			setIsVideoUploading(false);
		}
	};

	const onQuestionSubmit = (retry = false) => {
		let data = null;

		if (question.type === PersonalityTestQuestionTypes.VIDEO_RESPONSE) {
			data = {
				assess_id: assessmentId,
				...(retry ? { retry: true } : { resource_id: resource.data?.id })
			};
		}
		else {
			data = {
				assess_id: assessmentId,
				...(answer ? { response_text: answer } : null),
			};
		}
		if (props.onQuestionSubmit && data) {
			props.onQuestionSubmit(data);
		}
	};

	const remainTime = useMemo(() => {
		if (!question) return 0;

		if (error) {
			setError(null);
		}
		const serveTime = new Date(question.serve_time);
		const duration = question.duration * 1000;

		const remainingTime = (serveTime.getTime() + duration - Date.now()) / 1000;
		return Number(remainingTime.toFixed(0));
	}, [question]);

	useEffect(() => {
		setAnswer(null);
		setVideoResourceId(null);
		setUploadBlobURL(null);
		setPVRDuration(0);
	}, [question]);

	useEffect(() => {
		dispatch(resetResourceState());
	}, [question]);

	useEffect(() => {
		if (isVideoUploading && videoResourceId) {
			dispatch(deleteResource({ id: videoResourceId }));
		}
		setIsVideoUploading(false);
	}, [isVideoUploading]);


	const [timeDuration, setTimeDuration] = useState(0);
	const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: e => { },
		onBlurWithin: e => { setIsSkipModalOpen(false); }
	});

	useMemo(() => {
		if (timeDuration === 0) {
			setIsSkipModalOpen(false);
		}
	}, [timeDuration]);

	return (
		<TestContentWrapper
			title={null}
			duration={remainTime}
			currentQuestion={currentQuestionNo}
			totalQuestions={totalQuestions || 0}
			processing={processing}
		>
			{
				(() => {

					if (!question) {
						return (
							<div className='align-self-center' >
								<ProcessingIndicator className={'medium-indicator'} />
							</div>
						);
					}

					return (
						<div className='assessment-test-container'>
							<div className='d-flex flex-column w-100 justify-content-center align-items-start' style={{gap: '12px'}}>
								<div className='w-100 d-flex flex-row align-items-start justify-content-between'>
									<span className='subtitle-2 dark-100 text-start'>{`Question ${currentQuestionNo} :`}</span>
									{/* {question.type === PersonalityTestQuestionTypes.TEXT_RESPONSE &&
										<div style={{ width: '85px' }}>
											<Timer duration={remainTime}
												getUpdatedTime={(duration) => { setTimeDuration(duration); }}
											/>
										</div>
									} */}
								</div>
								<span className='body-1 dark-100 disable-text-selection text-start' dangerouslySetInnerHTML={{ __html: question.text }}></span>
								{question.type === PersonalityTestQuestionTypes.TEXT_RESPONSE &&
									<div 
										className='w-100 d-flex flex-column align-items-start' 
										style={{ maxWidth: '100%', gap: '12px', 
											// opacity: (timeDuration <= 0) ? '0.3' : '1', pointerEvents: (timeDuration <= 0) ? 'none' : 'auto' 
										}} 
										// onKeyDown={(e) => timeDuration <= 0 && e.preventDefault()}
									>
										<span className='subtitle-2 dark-100 text-start'>Your Response :</span>
										<TextArea
											inputStyle={{ minHeight: '460px', padding: '25px' }}
											value={answer}
											onPaste={(e) => { e.preventDefault(); }}
											onChange={(e) => { setAnswer(e.target.value); }}
											placeholder='Write your answer here...'
										/>
										{!validAnswerLength &&
											<span className='body-2 dark-50 text-start'>{answer?.length > 0 ? `${500 - answer?.length} more to go…` : 'Enter atleast 500 characters'}</span>
										}
									</div>
								}
								{question.type === PersonalityTestQuestionTypes.VIDEO_RESPONSE &&
									<>
										<div className='w-100 d-flex flex-column align-items-start' style={{ maxWidth: '538px', gap: '12px' }}>
											<span className='subtitle-2 dark-100 text-start'>Your Response :</span>
											<VideoRecorder
												recordingDuration={question?.duration}
												height='100%'
												width='100%'
												onVideoSubmit={(blobURL) => { setUploadBlobURL(blobURL); handleVideoFileUpload(blobURL); }}
												processingUpload={resource.processing}
												uploadProgress={resource.upload_percentage || 0}
												camMustActive={true}
												onRetry={() => { onQuestionSubmit(true); }}
												retriesLeft={TestType.VIDEO_TEST ? 2 - question.retries : undefined}
												isSomthingIsInProcess={resource.processing}
												setPVRDuration={setPVRDuration}
											/>
											{error &&
												<span className='body-2 danger-text text-start'>
													{error}
													<span className='dark-100 pointer'
														onClick={() => {
															setIsSkipModalOpen(false);
															setError('');
															handleVideoFileUpload(uploadBlobURL);
														}}
														style={{ textDecoration: 'underline', marginLeft: '2px' }}
													>
														Upload Again
													</span>
												</span>
											}
											<span className='body-2 dark-100 text-start'>{2 - question.retries} more attempts <span style={{ fontWeight: '300' }}>left to record this answer</span></span>
										</div>
										{pVRDuration < 60 && 
											<div className='video-limit'>
												<div>
													<AlertIcon />
												</div>
												<span className='body-2 danger-text'>The video response must be at least 1 minute in length; ensure your recording meets this minimum requirement before submitting.</span>
											</div>
										}
									</>
								}
								<div className='w-100 d-flex justify-content-end align-items-end' style={{ marginTop: '12px' }}>
									<div tabIndex={-1} {...focusWithinProps}>
										{isSkipModalOpen?
											<Button
												processing={processing}
												btn='ragular-btn' 
												varrient={'primary'}
												title={'Skip Question?'}
												style={{ width: 'max-content', height: '40px' }}
												onClick={() => {
													const data = {
														assess_id: assessmentId
													};
													if (props.onQuestionSubmit) {
														props.onQuestionSubmit(data);
													}
													setError('');
													setIsSkipModalOpen(false);
												}} 
											/>
											:
											<Button
												processing={processing}
												disabled={
													resource.processing ||
														(question?.type === PersonalityTestQuestionTypes.TEXT_RESPONSE && !validAnswerLength) ||
														(question?.type === PersonalityTestQuestionTypes.VIDEO_RESPONSE && (pVRDuration < 60))
												}
												btn='ragular-btn' 
												varrient={'primary'}
												title={resource.processing ? 'Uploading...' : (currentQuestionNo === totalQuestions || 0 ? 'Submit' : 'Next')}
												style={{ width: '120px', height: '40px' }}
												onClick={() => {
													const tempAnswer = (question?.type === PersonalityTestQuestionTypes.TEXT_RESPONSE ? (answer ? answer !== '' : '') : true) &&
														(question?.type === PersonalityTestQuestionTypes.VIDEO_RESPONSE ? (videoResourceId ? videoResourceId : true) : true);
													// if (!tempAnswer && (timeDuration === null || timeDuration > 0)) {
													if (!tempAnswer) {
														setIsSkipModalOpen(true);
													}
													else if (!isSkipModalOpen) {
														onQuestionSubmit();
														setIsSkipModalOpen(false);
													}
												}} 
											/>
										}
									</div>
								</div>
							</div>
						</div>
					);

				})()
			}
		</TestContentWrapper>
	);
}
