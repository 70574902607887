import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import Select from '../../../../sub-component/Select';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/Views/dashboard/assessments/plus3.svg';
import { ReactComponent as Plus2Icon } from '../../../../../assets/icons/Views/dashboard/assessments/plus.svg';
import { ReactComponent as MoreIcon } from '../../../../../assets/icons/Views/dashboard/assessments/more-vertical.svg';
import SectionContainer from './SectionContainer';
import CustomModal from '../../../../common/Modal';
import { createResource } from '../../../../../redux/thunks/Resource';
import { useDispatch, useSelector } from 'react-redux';
import { CaseStudyQuestionTypes } from '../../../../../utils/Constants';
import Input from '../../../../sub-component/Input';
import CustomSwitch from '../../../../sub-component/Switch';
import { useFocusWithin } from '@react-aria/interactions';
import { useMediaQuery } from '@mui/material';

const questionTypes = [
	{ name: 'Text Response', value: 'text_response' },
	{ name: 'File Upload', value: 'upload_response' },
	{ name: 'Video Response', value: 'video_response' },
];

const videoDurations = [
	{ name: '1 Min', value: 60 },
	{ name: '2 Mins', value: 120 },
	{ name: '5 Mins', value: 300 },
	{ name: '10 Mins', value: 600 },
];

const testDurations = [
	{ name: '1 Hour', value: 3600 },
	{ name: '2 Hours', value: 7200 },
	{ name: '3 Hours', value: 10800 },
	{ name: '12 Hours', value: 43200 },
	{ name: '1 Day', value: 86400 },
	{ name: '2 Days', value: 172800 },
	{ name: '3 Days', value: 259200 },
	{ name: '5 Days', value: 432000 },
	{ name: '7 Days', value: 604800 },
];

const VideoModeTypes = [
	{ name: 'Record with camera', value: 'native' },
	{ name: 'Record with loom', value: 'loom' },
	{ name: 'Let candidate choose camera or loom', value: 'open' },
];

export default function CaseStudyTest(props) {
	const isOnWebScreen = useMediaQuery('(min-width:1280px)');
	const dispatch = useDispatch();
	const quillObjs = useRef([]);
	quillObjs.current = [];
	const limit = 10;

	const resourceReducer = useSelector((state) => state.resourceReducer);

	const [selectedData, setSelectedData] = useState(
		props?.sectionDetails?.test?.questions,
	);

	const [alreadyChanged, setAlreadyChanged] = useState(false);
	const [error, setError] = useState('');

	const [introText, setIntroText] = useState(
		props?.sectionDetails?.test?.intro_text,
	);
	const [duration, setDuration] = useState(
		props?.sectionDetails?.test?.duration,
	);
	const [menuIndex, setMenuIndex] = useState(-1);
	const [openModal, setOpenModal] = useState(false);
	const [url, setUrl] = useState(null);
	const [quillObj, setQuillObj] = useState(null);
	const [quillObjIndex, setQuillObjIndex] = useState(null);
	const [fileUploadType, setFileUploadType] = useState('image');
	const [isDragActive, setIsDragActive] = useState(false);
	const [isDragFailed, setIsDragFailed] = useState(false);
	const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

	const allowedImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
	const allowedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg'];

	const handleDragFile = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.type === 'dragenter' || e.type === 'dragover') {
			setIsDragActive(true);
		} else if (e.type === 'dragleave') {
			setIsDragActive(false);
		}
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setUrl(null);
		setQuillObjIndex(null);
		setQuillObj(null);
	};

	const updateData = (_intro_text, _questions, _duration) => {
		props.getUpdatedSectionLocalData({
			...props.sectionDetails,
			test: {
				...props.sectionDetails.test,
				intro_text: _intro_text || '',
				questions: _questions || [],
				duration: _duration || null,
			},
		});
	};

	const addToRef = (el) => {
		if (el && !quillObjs.current.includes(el)) {
			quillObjs.current.push(el);
		}
	};

	const openImageModal = () => {
		setFileUploadType('image');

		quillObjs.current.map((obj) => {
			if (isNaN(obj.getEditorSelection())) {
				setQuillObjIndex(obj?.getEditorSelection()?.index);
				setQuillObj(obj);
			}
		});

		setOpenModal(true);
	};

	const openVideoModal = () => {
		setFileUploadType('video');

		quillObjs.current.map((obj) => {
			if (isNaN(obj.getEditorSelection())) {
				setQuillObjIndex(obj?.getEditorSelection()?.index);
				setQuillObj(obj);
			}
		});

		setOpenModal(true);
	};

	const handleDragFileUpload = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		setIsDragActive(false);

		const formData = new FormData();
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			const file = e.dataTransfer.files[0];
			if ((fileUploadType === 'image' && file.size <= 5 * 1024 * 1024) || (fileUploadType === 'video' && file.size <= 100 * 1024 * 1024)) {
				if (
					(fileUploadType === 'image' && allowedImageTypes.includes(file.type)) ||
					(fileUploadType === 'video' && allowedVideoTypes.includes(file.type))
				) {
					formData.append('file', file);
					setIsDragFailed(false);


					const { payload } = await dispatch(createResource({ data: formData, signal: null }));

					setUrl(payload.url);
				} else {
					setIsDragFailed(true);
					setError('Format of uploaded file is not supported');
				}
			} else {
				setIsDragFailed(true);
				setError(`The uploaded file exceeded the max size limit (i.e ${fileUploadType === 'image' ? '5' : '100'} MB)`);
			}
		}
	};

	const handleFileUpload = () => {
		setIsDragFailed(false);

		const input = document.createElement('input');

		input.setAttribute('type', 'file');
		if (fileUploadType === 'image') {
			input.setAttribute('accept', 'image/*');
		} else if (fileUploadType === 'video') {
			input.setAttribute('accept', 'video/*');
		}
		input.click();

		input.onchange = async () => {
			const file = input.files[0];
			if ((fileUploadType === 'image' && file.size <= 5 * 1024 * 1024) || (fileUploadType === 'video' && file.size <= 100 * 1024 * 1024)) {
				if ((fileUploadType === 'image' && allowedImageTypes.includes(file.type)) ||
					(fileUploadType === 'video' && allowedVideoTypes.includes(file.type))) {
					const formData = new FormData();
					formData.append('file', file);

					const { payload } = await dispatch(createResource({ data: formData, signal: null }));
					setUrl(payload.url);
					setError('');
				} else {
					setError('Format of uploaded file is not supported');
				}
			} else {
				setError(`The uploaded file exceeded the max size limit (i.e ${fileUploadType === 'image' ? '5' : '100'} MB)`);
			}
		};
	};

	const setUploadedFile = () => {
		if (fileUploadType === 'image') {
			quillObj.getEditor().insertEmbed(quillObjIndex, 'image', url);
		} else if (fileUploadType === 'video') {
			quillObj.getEditor().insertEmbed(quillObjIndex, 'video', url);
		}

		setOpenModal(false);
		setUrl(null);
		setQuillObjIndex(null);
		setQuillObj(null);
	};

	const modules = useMemo(
		() => ({
			toolbar: {
				handlers: {
					image: openImageModal,
					video: openVideoModal,
				},
				container: [
					[{ header: [1, 2, false] }],
					['bold', 'italic', 'underline', 'strike', 'blockquote'],
					[
						{ list: 'ordered' },
						{ list: 'bullet' },
						{ indent: '-1' },
						{ indent: '+1' },
					],
					['link', 'image', 'video'],
					['clean'],
				],
			},
			clipboard: {
				matchVisual: true,
			},
		}),
		[],
	);

	const formats = [
		'header',
		'font',
		'size',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
		'video',
	];

	const changeIsMandatory = (index) => {
		const updatedQuestions = selectedData.map((question, i) => {
			if (i == index) {
				return {
					...question,
					is_mandatory: !question.is_mandatory
				};
			}
			return question;
		});
		setSelectedData(updatedQuestions);
		updateData(introText, updatedQuestions, duration);
	};

	const onConfirm = () => {
		props.onConfirm({
			...props.sectionDetails,
			test: {
				...props.sectionDetails.test,
				intro_text: introText,
				questions: selectedData,
				duration,
			},
		});
	};

	const onCancel = () => {
		props.onCancel({
			...props.sectionDetails,
			test: {
				...props.sectionDetails.test,
				intro_text: introText,
				questions: selectedData,
				duration,
			},
		});
	};

	const onCheckSectionDataConfermation = () => {
		props.onCheckSectionDataConfermation({
			...props.sectionDetails,
			test: {
				...props.sectionDetails.test,
				intro_text: introText,
				questions: selectedData,
				duration,
			},
		});
	};

	useEffect(() => {
		if (props.error) {
			props.setCheckValidation(true);
		}
	}, [selectedData, introText, duration]);



	useEffect(() => {
		if (selectedData.length === 0) {
			setSelectedData([
				{
					question_type: 'text_response',
					question: '',
				},
			]);
		}
	}, [selectedData]);


	useEffect(() => {

		if (props?.resetAssessmentCreationSection) {
			setSelectedData(props?.sectionDetails?.test?.questions);
			props?.getToKnowIFStateUpdated('case_study_test_updated', true);
		}

	}, [props?.resetAssessmentCreationSection]);

	useEffect(() => {
		// props.setValue('case_study_test.intro_text', introText?.replace(/<[^>]+>/g, ''));

		if (
			(introText?.match(/<img([\w\W]+?)>/g) &&
				introText?.match(/<img([\w\W]+?)>/g)[0]) ||
			(introText?.match(/<video([\w\W]+?)>/g) &&
				introText?.match(/<video([\w\W]+?)>/g)[0])
		) {
			// props.setValue('case_study_test.intro_text', introText?.replace(/<[^>]+>/g, '') + 'image|video');
			// props.clearErrors('case_study_test.intro_text');
		}

		if (introText?.replace(/<[^>]+>/g, '')) {
			// props.clearErrors('case_study_test.intro_text');
		}
	}, [introText]);

	useEffect(() => {

	}, [props]);

	const { introTextFocusWithinProps } = useFocusWithin({
		onFocusWithin: (e) => { },
		onBlurWithin: (e) => { }
	});

	// const [error, setError] = useState();
	// useEffect(() => {
	// 	console.log(props.error, 'props.error');
	// }, [props.error]);


	return (
		<SectionContainer
			title={'Case Study'}
			description={'Your candidate will be able to add an open-ended answer based on response type.'}
			onRemoveSection={() => {
				props.onRemoveSection();
			}}
			onConfirm={onConfirm}
			onCancel={onCancel}
			selectedDuration={duration}
			customizedDuration
			durations={testDurations}
			setDuration={(_duration) => {
				setDuration(_duration);
				updateData(introText, selectedData, _duration);
				props.onCheckSectionDataConfermation({
					...props.sectionDetails,
					test: {
						...props.sectionDetails.test,
						duration: _duration,
					},
				});
			}}
			error={props.error}
		>
			<CustomModal
				open={openModal}
				handleCloseModal={handleCloseModal}
				varrient='admin-side-image-upload'
				confirmBtnTitle='Upload'
				onConfirm={setUploadedFile}
				onClickToAddFile={handleFileUpload}
				fileUrl={url}
				fileUploadProcessing={resourceReducer.processing}
				fileUploadPercentage={resourceReducer.upload_percentage}
				fileUploadType={fileUploadType}
				handleDragFile={handleDragFile}
				onDragToAddFile={handleDragFileUpload}
				isDragActive={isDragActive}
				isDragFailed={isDragFailed}
				error={error}
			/>
			<div className='w-100 d-flex flex-column align-items-start' style={{ gap: '16px', marginTop: '10px' }}>
				<div
					className='text-response-question-container'
					style={{ width: '100%', height: 'min-content' }}
					tabIndex={-1}
					id='input-to-force-blur'
					onBlur={() => {
						if (alreadyChanged) {
							props.getUpdatedSectionLocalData({
								...props.sectionDetails,
								test: {
									...props.sectionDetails.test,
									intro_text: introText || '',
									questions: selectedData || [],
									duration: duration || null,
								},
							});
							setAlreadyChanged(false);
						}
					}}
					{...introTextFocusWithinProps}
				>
					<div className='w-100 d-flex flex-column align-items-start'>
						<span className='body-2 black-700 mb-2' style={{ paddingLeft: '24px' }}>
							Add introductory text <span className='danger-text'>*</span>
						</span>
						<ReactQuill
							ref={addToRef}
							className={`case-study-quill-container ${props.error?.intro_text_required && 'rich-text-error'}`}
							theme='snow'
							modules={modules}
							formats={formats}
							value={introText}
							onChange={(value) => {
								setIntroText(value === '<p><br></p>' ? '' : value);
								props.onCheckSectionDataConfermation({
									...props.sectionDetails,
									test: {
										...props.sectionDetails.test,
										intro_text: value === '<p><br></p>' ? '' : value,
										questions: selectedData,
										duration,
									},
								});
								setAlreadyChanged(true);
							}}

							placeholder='Text here'
						/>
					</div>
				</div>
				<div className='w-100 d-flex flex-column' style={{ gap: '16px' }}>
					{
						selectedData.map((_question, index) => {
							const questionError = props?.error?.required?.questions?.filter((ques, _) => { return (ques.index === index); })[0] || null;
							return (
								<>
									{
										index === activeQuestionIndex ?
											<div key={index} className='text-response-question-container'>
												<div className='w-100 h-100 d-flex flex-row align-items-center justify-content-between'>
													<div className='h-100 d-flex flex-row align-items-start justify-content-center' style={{ gap: '12px' }}>
														<span className='body-1 dark-50 text start' style={{ width: '130px' }}>{`Question ${index + 1}:`}</span>
														<CustomSwitch
															label={'Mark Mandatory'}
															checked={_question.is_mandatory}
															onChange={() => changeIsMandatory(index)}
														/>
													</div>
													<div className='text-response-question-container-menu'>
														<MoreIcon
															className='pointer'
															style={{ transform: 'rotate(90deg)' }}
															onClick={() => {
																menuIndex === -1
																	? setMenuIndex(index)
																	: setMenuIndex(-1);
															}}
														/>
														{menuIndex === index && (
															<div className='container-menu'>
																{selectedData?.length < limit &&
																	<div
																		onClick={() => {
																			const tempSelectedData = selectedData;
																			tempSelectedData.push(selectedData[index]);
																			setSelectedData([...selectedData]);
																			updateData(introText, [...tempSelectedData], duration);
																			setMenuIndex(-1);
																		}}
																	>
																		<span
																			className='body-3 black-700'
																			style={{ fontWeight: '500' }}
																		>
																			Duplicate{' '}
																		</span>
																	</div>
																}
																{selectedData.length > 1 && <div
																	onClick={() => {
																		const tempSelectedData = selectedData;
																		tempSelectedData.splice(index, 1);
																		setSelectedData([...tempSelectedData]);
																		updateData(introText, [...tempSelectedData], duration);
																		setMenuIndex(-1);
																	}}
																>
																	<span
																		className='body-3 black-700'
																		style={{ fontWeight: '500' }}
																	>
																		Delete
																	</span>
																</div>}
															</div>
														)}
													</div>
												</div>
												<div className='w-100 d-flex flex-row align-items-start'>
													<div className='text-response-question-upper-container'>
														<div className='d-flex flex-column' style={{ width: (selectedData[index].question_type === CaseStudyQuestionTypes.VIDEO_RESPONSE && isOnWebScreen) ? 'calc(50% - 16px)' : '100%' }}>
															<Select
																readOnly={true}
																options={questionTypes}
																className='question-select-responsive select-ideal-answer'
																style={{ width: '100%' }}
																innerStyle={{ maxWidth: 'none' }}
																selected={_question.question_type}
																placeholder='Select Question Type'
																onSelection={(option) => {
																	const tempSelectedData = selectedData;
																	tempSelectedData[index] = {
																		..._question,
																		question_type: option.value,
																		// question: option?.value === 'upload_response' ? tempSelectedData[index]?.question?.replace(/<[^>]+>/g, '') : tempSelectedData[index]?.question
																	};

																	if (option.value !== CaseStudyQuestionTypes.VIDEO_RESPONSE) {
																		delete tempSelectedData[index].duration;
																	}

																	setSelectedData([...tempSelectedData]);
																	updateData(introText, [...tempSelectedData], duration);
																	props.onCheckSectionDataConfermation({
																		...props.sectionDetails,
																		test: {
																			...props.sectionDetails.test,
																			intro_text: introText,
																			questions: [...tempSelectedData],
																			duration,
																		},
																	});
																}}
															/>
														</div>
														{selectedData[index].question_type === CaseStudyQuestionTypes.VIDEO_RESPONSE && (
															<>
																<div className='d-flex flex-column' style={{ width: (selectedData[index].question_type === CaseStudyQuestionTypes.VIDEO_RESPONSE && isOnWebScreen) ? 'calc(50% - 16px)' : '100%' }}>
																	<Select
																		readOnly={true}
																		options={videoDurations}
																		className={
																			questionError?.duration_required
																				? 'question-select-responsive select-ideal-answer primary-input-error'
																				: 'question-select-responsive select-ideal-answer'
																		}
																		style={{
																			width: '100%',
																		}}
																		innerStyle={{ maxWidth: 'none' }}
																		disabled={_question?.recording_type === 'loom'}
																		toolTip={_question?.recording_type === 'loom' ? `You have only 5 minutes for "Record with loom"` : ''}
																		selected={_question.duration}
																		placeholder='Select Duration *'
																		onSelection={(option) => {
																			const tempSelectedData = selectedData;
																			tempSelectedData[index] = {
																				..._question,
																				duration: option.value
																			};
																			setSelectedData([...tempSelectedData]);
																			updateData(introText, [...tempSelectedData], duration);
																			setMenuIndex(-1);
																			props.onCheckSectionDataConfermation({
																				...props.sectionDetails,
																				test: {
																					...props.sectionDetails.test,
																					intro_text: introText,
																					questions: selectedData,
																					duration: option.value,
																				},
																			});
																		}}
																	/>
																	{
																		questionError?.duration_required &&
																		<span className='body-3 danger-text text-start' style={{ paddingLeft: '24px' }}>Select time to fix the issue</span>
																	}
																</div>
																<div className='d-flex flex-column' style={{ width: (selectedData[index].question_type === CaseStudyQuestionTypes.VIDEO_RESPONSE && isOnWebScreen) ? 'calc(50% - 16px)' : '100%' }}>
																	<Select
																		readOnly
																		options={VideoModeTypes}
																		style={{ widtth: '100%' }}
																		className={
																			questionError?.video_mode_required
																				? 'question-select-responsive select-ideal-answer primary-input-error'
																				: 'question-select-responsive select-ideal-answer'
																		}
																		selected={_question.recording_type}
																		placeholder='Select Video Mode'
																		onSelection={(option) => {
																			const tempSelectedData = selectedData;
																			if (option.value === 'loom') {
																				tempSelectedData[index] = {
																					..._question,
																					duration: 300,
																					recording_type: option.value
																				};
																			}
																			else {
																				tempSelectedData[index] = {
																					..._question,
																					recording_type: option.value
																				};
																			}

																			setSelectedData([...tempSelectedData]);
																			updateData(introText, [...tempSelectedData], duration);
																			setMenuIndex(-1);
																			props.onCheckSectionDataConfermation({
																				...props.sectionDetails,
																				test: {
																					...props.sectionDetails.test,
																					intro_text: introText,
																					questions: selectedData,
																				},
																			});
																		}}
																	/>
																	{
																		questionError?.video_mode_required &&
																		<span className='body-3 danger-text text-start' style={{ paddingLeft: '24px' }}>Select Video Mode to fix the issue</span>
																	}
																</div>
															</>
														)}
													</div>
												</div>
												<div
													className='w-100 d-flex flex-column alig-items-start'
													tabIndex={-1}
													id='input-to-force-blur'
													onBlur={() => { updateData(introText, selectedData, duration); }}
												>
													{
														<ReactQuill

															ref={addToRef}
															className={
																questionError?.question_required
																	? 'case-study-quill-container  rich-text-error'
																	: 'case-study-quill-container'
															}
															theme='snow'
															modules={modules}
															formats={formats}
															value={_question.question}
															onChange={(value) => {
																const tempSelectedData = selectedData;
																tempSelectedData[index] = {
																	..._question,
																	question: value === '<p><br></p>' ? '' : value,
																};
																setSelectedData([...tempSelectedData]);
																props.onCheckSectionDataConfermation({
																	...props.sectionDetails,
																	test: {
																		...props.sectionDetails.test,
																		intro_text: introText,
																		questions: [...tempSelectedData],
																		duration,
																	},
																});

															}}

															placeholder='Text here *'
														/>
													}
													{questionError?.question_required &&
														<span className='body-3 danger-text text-start' style={{ paddingLeft: '24px' }}>Add some text here to fix the issue</span>
													}
												</div>
											</div>
											:
											<div
												className={
													questionError
														? 'minize-view-of-section-container pointer rich-text-error'
														: 'minize-view-of-section-container pointer'
												}
												onClick={() => { onCheckSectionDataConfermation(); setActiveQuestionIndex(index); }}
												style={{ background: 'white', borderRadius: '24px' }}
											>
												<div className='d-flex align-items-center justify-content-between w-100'>
													<div className='w-100 d-flex align-items-center justify-content-start'>
														<Plus2Icon style={{ width: '20px', height: '20px', stroke: '#121216' }} />
														<span className='body-2 dark-100' style={{ marginLeft: '8px', marginRight: '8px' }}>Question #{index + 1}</span>
														<span className='body-2 dark-100'>
															({
																questionTypes.find(
																	(type) => type.value === _question.question_type,
																).name
															})
														</span>
													</div>
												</div>
												<div className='w-100 ' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
													<span className='body-3 black-500'>	{_question.question.replace(/<[^>]+>/g, '') || 'Question statement will be shown here'}</span>
												</div>
											</div>
									}
								</>
							);
						})
					}
				</div>
				{selectedData?.length < limit ?
					<div className='add-question'
						onClick={() => {
							onCheckSectionDataConfermation();
							setSelectedData([
								...selectedData,
								{ question_type: 'text_response', question: `<p></p>` },
							]);
							updateData(introText, [
								...selectedData,
								{ question_type: 'text_response', question: `<p></p>` },
							], duration);
							setActiveQuestionIndex(selectedData.length);

						}}
					>
						<PlusIcon style={{ stroke: '#121216' }} />
						<span className='body-3 dark-100'>Add another question</span>
					</div>
					:
					<div className='error-box'>
						<span className='body-3 text-danger'>
							You can only add 10 Case Study Question at a time!
						</span>
					</div>
				}
			</div>
		</SectionContainer>
	);
}
