import React, { useEffect, useMemo, useState } from 'react';
import Input from '../../../components/sub-component/Input';
import { ReactComponent as UplaodICon } from '../../../assets/icons/Views/candidate/settings/upload.svg';
import { ReactComponent as ProfilePic } from '../../../assets/icons/Views/candidate/settings/profile-pic.svg';
import Button from '../../../components/sub-component/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, patchTanentProfile, patchUserProfile } from '../../../redux/thunks/UserProfile';
import { createResource, getResource, deleteResource } from '../../../redux/thunks/Resource';
import AvtarBadge from '../../../components/dashboard/AvtarBadge';
import ProcessingIndicator from '../../../components/common/ProcessingIndicator';
import { useMediaQuery } from '@mui/material';


const GetImageURLFromId = ({ id, name }) => {
	// const imageResult = await dispatch(getResource({ id }));
	// if (imageResult.error) 'Not Found';

	const dispatch = useDispatch();
	const [resource, setResource] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			if (!id) return;
			setLoading(true);
			const dispatched = await dispatch(getResource({ id: id }));
			setLoading(false);

			if (dispatched.error) return;

			setResource(dispatched.payload);
		})();
	}, []);

	if (loading && id) {
		return (
			<div className='d-flex align-items-center justify-content-center' style={{ width: '96px', height: '96px' }}>
				<ProcessingIndicator className='small-indicator' />
			</div>
		);
	}

	return (
		<AvtarBadge
			size={'custom'}
			width={120}
			height={120}
			// src={imageResult?.payload?.url}
			src={resource?.url}
			name={name}
		/>
	);
};

export default function ProfileSettings() {

	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.authReducer);
	const [userCanNowUpdate, setUserCanNowUpdate] = useState(false);
	const { userProfileData, processing_patch_profile } = useSelector((state) => state.userProfileReducer);

	const [profileDetails, setProfileDetails] = useState();

	useEffect(() => {

		(async () => {
			await dispatch(getUserProfile({}));
		})();

	}, []);

	const resource = useSelector((state) => state.resourceReducer);

	const [isFilePicked, setIsFilePicked] = useState(false);

	const changeHandler = (event) => {
		setProfileDetails({ ...profileDetails, image_id: event.target.files[0] });
		setIsFilePicked(true);
		setUserCanNowUpdate(true);
	};

	const onChangeValueHandle = (e) => {
		setProfileDetails({ ...profileDetails, [e.target.name]: e.target.value });
		setUserCanNowUpdate(true);
	};


	const handleUpdateProfile = async () => {
		if (isFilePicked) {
			if (resource.data?.id) {
				dispatch(deleteResource({ id: resource.data.id }));
			}
			const formData = new FormData();
			formData.append('file', profileDetails?.image_id, profileDetails?.image_id.name);
			const result = await dispatch(createResource({ data: formData, signal: null }));

			if (result.error) return;

			await dispatch(patchUserProfile({ data: { ...profileDetails, image_id: result?.payload?.id } }));
			setIsFilePicked(false);
		}
		else {
			await dispatch(patchUserProfile({ data: { ...profileDetails } }));
		}

	};

	useEffect(() => {
		if (isFilePicked) {
			handleUpdateProfile();
		}
	}, [isFilePicked]);

	useMemo(() => {
		setProfileDetails({ ...userProfileData });
	}, [userProfileData]);

	return (
		<>
			<div className='dashboard-page hide-scrollbar'>
				<div className='page-header'>
					<span className='headline-3 dark1-00'>{'Profile Settings'}</span>
				</div>
				<div className='page-content' >
					<div className='candidate-profile-container'>
						<div className='candidate-profile-pic-detail'>
							<span className='subtitle-1 dark-100'>{'Profile Picture'}</span>
							<div className='candidate-profile-pic'>
								<div style={{ width: '120px', height: '120px', position: 'relative' }}>
									{(profileDetails?.image_id) ?
										<>
											{
												profileDetails?.image_id instanceof File
													?
													<AvtarBadge
														size={'custom'}
														width={120}
														height={120}
														src={URL.createObjectURL(profileDetails?.image_id)}
													/>
													:
													<GetImageURLFromId id={profileDetails?.image_id} />
											}
										</>
										:
										<div className='profile-img'>
											{/* <img src={ProfilePic} alt='profile-pic' /> */}
											<input
												type='file'
												className='position-absolute pointer'
												style={{ left: '0px', width: '120px', height: '120px', opacity: '0' }}
												name='profile_picture'
												onChange={(e) => { changeHandler(e); }}
											/>
											<ProfilePic className='profile-img-upload-icon2' style={{ width: '64px', height: '64px' }} />
											<UplaodICon className='profile-img-upload-icon' style={{ width: '44px', height: '44px' }} />
										</div>
									}
								</div>
								<div className='d-flex flex-column' style={{ gap: '12px', marginTop: '12px' }}>
									<div className='relative pointer align-self-start'>
										{(processing_patch_profile || isFilePicked) ? 
											<Button btn='ragular-btn' varrient='primary' title='' style={{ width: '187px', height: '41px' }} processing={(processing_patch_profile || isFilePicked)}/>
											:
											<span className='w-100 pointer' style={{ padding: '12px 24px 12px 24px', borderRadius: '50px', background: '#121216', color: 'white' }}>Upload Profile Picture</span>
										}
										<input
											type='file'
											className='position-absolute pointer'
											style={{ left: '150px', width: '190px', opacity: '0' }}
											name='profile_picture'
											onChange={(e) => { changeHandler(e); }}
										/>
									</div>
									<span className='body-2 dark-50 mt-2'>
										JPG, JPEG & PNG formats are allowed
									</span>
								</div>
							</div>
						</div>
						<div className='candidate-profile-details'>
							<span className='subtitle-1 dark-100'>{'General Settings'}</span>
							<div className='candidate-profile-other-details'>
								<div className='candidate-profile-edit-detail'>
									<Input
										varrient={'primary'}
										className='input body-3 primary-input'
										placeholder='John'
										title={'First Name'}
										isRequired
										value={profileDetails?.first_name}
										name={'first_name'}
										onChange={onChangeValueHandle}
									/>
								</div>
								<div className='candidate-profile-edit-detail'>
									<Input
										varrient={'primary'}
										className='input body-3 primary-input'
										placeholder='Doe'
										title={'Last Name'}
										isRequired
										value={profileDetails?.last_name}
										name={'last_name'}
										onChange={onChangeValueHandle}
									/>
								</div>
								<div className='candidate-profile-edit-detail'>
									<Input
										readOnly
										varrient='primary'
										className='input body-3 primary-input'
										placeholder='johndoe@gmail.com'
										title={'Company Email'}
										isRequired
										value={profileDetails?.email}
										name={'email'}
										onChange={onChangeValueHandle}
									/>
								</div>
							</div>
							<div className='w-100 d-flex justify-content-end'>
								<Button
									btn='ragular-btn'
									toolTip={userCanNowUpdate ? 'Upadte details' : 'Change somthing to update details'}
									varrient='primary'
									title={'Save Changes'}
									disabled={!userCanNowUpdate}
									className='btn-md align-self-end'
									processing={(processing_patch_profile || resource.processing)}
									onClick={handleUpdateProfile}
								/>
							</div>
						</div>
						{/* <div className='candidate-profile-details'>
							<span className='subtitle-1 dark-100'>{'Change Password'}</span>
							<div className='candidate-profile-other-details'>
								<div className='candidate-profile-edit-detail'>
									<Input
										type='password'
										varrient='primary'
										className='input body-3 primary-input'
										placeholder='********'
										title={'Previous Password'}
										isRequired
										value={profileDetails?.previousPassword}
										name={'previousPassword'}
										onChange={onChangeValueHandle}
									/>
								</div>
								<div className='candidate-profile-edit-detail'>
									<Input
										type='password'
										varrient='primary'
										className='input body-3 primary-input'
										placeholder='********'
										title={'New Password'}
										isRequired
										value={profileDetails?.newPassword}
										name={'newPassword'}
										onChange={onChangeValueHandle}
									/>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
}
