/* eslint-disable react/react-in-jsx-scope */
import { setup, isSupported, } from '@loomhq/record-sdk';
import { oembed } from '@loomhq/loom-embed';
import { useEffect, useState } from 'react';

import Button from '../../../sub-component/Button';
import CustomModal from '../../../common/Modal';
import LoomVideoRecorder from './LoomVideoRecorder';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function LoomVideoRecorderContainer(props) {

	const [videoHTML, setVideoHTML] = useState('');
	const [isBrowserSupport, setIsBrowserSupport] = useState(true);
	// https://www.loom.com/share/1e117ff65b7d4a0490b5d09b163543ab
	const [videoURL, setVideoURL] = useState('');
	const [onRetryModal, setOnRetryModal] = useState(false);
	const history = useHistory();

	const { switchToNativeOption, getLoomVideoUrl, isSomthingIsInProcess, isLoomVideoRecording } = props;



	return (
		<>

			<CustomModal
				open={!isBrowserSupport}
				varrient='alert-warning-modal'
				title='Browser not Supported!'
				description={'Your current browser is not compatible with Loom. Please switch to a supported browser to continue your assessment. Loom does not function in Private/Incognito mode.Make sure that you are using a desktop device.'}
				warningAlert={true}
				warningText={''}
				handleCloseModal={() => { setIsBrowserSupport(true); history.push('/dashboard/assessments'); }}
				confirmBtnTitle={props.avaiableVideoMode === 'loom' ? 'Back to Dashboard' : 'Change to native'}
				btnWidth='max-content'
				btnStyle={{ minWidth: '120px' }}
				hideScendBtn={props.avaiableVideoMode === 'loom'}
				subTitle={'Supported browsers: Google Chrome v90+, Microsoft Edge v90+, and Brave v1.22+'}
				cancelBtnTitle={'Back to Dashboard'}
				// hideBtns={props.avaiableVideoMode !== 'open'}
				onConfirm={() => {
					if (props.avaiableVideoMode === 'open') {
						switchToNativeOption(); setIsBrowserSupport(true);
					}
					else {
						setIsBrowserSupport(true);
						history.push('/dashboard/assessments');
					}
				}
				}
			/>

			<CustomModal
				open={onRetryModal}
				varrient='alert-warning-modal'
				title='Record Again?'
				description={'Are you sure, you want to record a new video for this question?'}
				warningAlert={true}
				warningText={'Warning: By doing this your previous video recording will be replaced!'}
				handleCloseModal={() => { setOnRetryModal(false); }}
				confirmBtnTitle={'Yes, Record Again'}
				btnWidth={'190px'}
				onConfirm={() => {
					// if (props.onRetry) {
					// 	props.onRetry();
					// }

					setVideoURL('');
					getLoomVideoUrl('');
					setOnRetryModal(false);
				}}
			/>

			<div className='loom-recorder-container'>
				{
					(videoHTML && videoURL)
						?
						<div className='loom-iframe-container' dangerouslySetInnerHTML={{ __html: videoHTML }}></div>
						:
						<LoomVideoRecorder
							getLoomVideo={(url) => { setVideoHTML(url); }}
							getLoomVideoUrl={(url) => { setVideoURL(url); getLoomVideoUrl(url); }}
							isLoomVideoRecording={isLoomVideoRecording}
							isSomthingIsInProcess={isSomthingIsInProcess}
							isBrowserSupport={(isSupported) => { setIsBrowserSupport(isSupported); }}
							videoURL={videoURL}
						/>

				}
			</div>
			<div className='video-cs-container-footer'>
				{
					videoURL &&
					<Button btn="ragular-btn" varrient='primary' size="sm"
						disabled={isSomthingIsInProcess}
						title={'Record again'}
						onClick={() => {
							setOnRetryModal(true);
						}} />
				}
			</div>
		</>
	);
}