import React, { useEffect } from 'react';
import { ReactComponent as InviteIcon } from '../../../assets/icons/Views/candidate/assessments/streamline_mail-incoming.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/Views/candidate/assessments/clock.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/Views/dashboard/test-detail/x.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/Views/candidate/assessments/alert-circle.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/Views/dashboard/test-detail/Rounded Action.svg';
import { ReactComponent as CTIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/code.svg';
import { ReactComponent as SBTIcon } from '../../../assets/icons/components/dashboard/assessment-result-card/skill.svg';
import ProgressBar from '../../../components/common/ProgressBar';
import { secondsToDhmsFormat } from '../../../utils/utilities';
import { PathName } from '../../../utils/routes';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';


export default function TestHistoryRightSideMenu(props) {
	const isOnWebScreen = useMediaQuery('(min-width:1340px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const history = useHistory();
	const { test, setSelectedTest, assessmentsLength, selectedAssessmentIndex } = props;

	const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {

		const handleNext = () => {
			if (selectedAssessmentIndex < assessmentsLength - 1) {
				setSelectedTest(selectedAssessmentIndex + 1);
				history.push(`${PathName.CANDIDATES_TEST_HISTORY}`);
			}
		};

		const handlePrev = () => {
			if (selectedAssessmentIndex > 0) {
				setSelectedTest(selectedAssessmentIndex - 1);
				history.push(`${PathName.CANDIDATES_TEST_HISTORY}`);
			}
		};

		const handleKeyDown = (event) => {
			switch (event.key) {
				case 'ArrowLeft':
					handlePrev();
					break;
				case 'ArrowRight':
					handleNext();
					break;
				default:
					// Do nothing for other keys
					break;
			}
		};
		React.useEffect(() => {
			// Attach the event listener when the component mounts
			window.addEventListener('keydown', handleKeyDown);

			// Detach the event listener when the component unmounts
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}, [window]); // Empty dependency array means this effect runs once on mount and cleans up on unmount
		return (
			<div className='pagination' style={{ gap: isOnWebScreen ? '24px' : '12px' }}>
				<div className='h-100 d-flex flex-row align-items-center pointer' style={{ gap: isOnWebScreen ? '24px' : '12px' }} onClick={handlePrev}>
					<ArrowIcon className={`pointer ${props.selectedIndex === 0 && 'disable-arrow-icon'}`} style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
					<span className={`body-2 dark-50`}>Previous</span>
				</div>
				<div className='h-100 d-flex flex-row align-items-center pointer' style={{ gap: isOnWebScreen ? '24px' : '12px' }} onClick={handleNext}>
					<span className={`body-2 dark-50`}>Next</span>
					<ArrowIcon className={`pointer ${props.selectedIndex === props.assessmentsLength - 1 && 'disable-arrow-icon'}`} style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px', transform: 'rotate(180deg)' }} />
				</div>
			</div>
		);
	};

	return (
		<div className='assessment-custom-card-container d-flex flex-column h-100'>
			<div className='right-sidebar-header-container'>
				<div className='right-sidebar-header-pagination'>
					<Pagination />
					<div className='pointer' onClick={props.handleClose}>
						<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
					</div>
				</div>
				<div className='w-100 d-flex flex-row' style={{ gap: '12px' }}>
					<div className={`right-sidebar-header-top`}>
						<div className='w-100 d-flex flex-column align-items-start'>
							<div className='w-100 d-flex flex-row align-items-center justify-content-between'>
								<span className='headline-3 dark-100 text-start'>{test?.test_title || '-'}</span>
								<div className='d-flex align-items-start' style={{ width: '110px' }}>
									<ProgressBar value={test?.test_type === 'coding_test' ? Math.round((test?.score_object?.score) * 100).toFixed(1) : Math.round(parseFloat((10 / test?.score_object?.total_questions) * test?.score_object?.correct_answers) * 10).toFixed(1)} />
								</div>
							</div>
							<span className='subtitle-2 dark-100'>{test?.assessment_title || '-'}</span>
							<span className='subtitle-2 dark-100'>{test?.company_name || '-'}</span>
						</div>
					</div>
				</div>
				<div className='d-flex flex-column align-items-start' style={{ gap: '12px' }} >
					<div className=' d-flex flex-row align-items-center' style={{ gap: '12px' }} >
						<span className={`${isOnMobileScreen ? 'headline-5' : 'headline-5'} dark-100`}>Assessment Details</span>
						{(new Date(test?.expiry_date) < new Date()) &&
							<div className='candidate-status-container body-2' style={{ width: 'fit-content', border: '1px solid black', background: '#FFFFFF', color: '#121216' }}>
								{'Expired'}
							</div>
						}
					</div>
					<div className='d-flex flex-column align-items-start' style={{ gap: '6px' }}>
						<div className='d-flex flex-row align-items-center' style={{ gap: '4px' }}>
							<InviteIcon style={{ width: '18px', height: '18px' }} />
							<span className='body-2 dark-50'>Invited on: <span className='dark-100'>{new Date(test?.invited_on).toDateString()}</span></span>
						</div>
						<div className='d-flex flex-row align-items-center' style={{ gap: '4px' }}>
							<ClockIcon style={{ width: '18px', height: '18px' }} />
							<span className='body-2 dark-50'> Approximate time: <span className='dark-100'>{secondsToDhmsFormat(test?.test_duration || 0)}</span></span>
						</div>
						<div className='d-flex flex-row align-items-center' style={{ gap: '4px' }}>
							<InfoIcon style={{ width: '18px', height: '18px' }} />
							<span className='body-2 dark-50'>Expires on: <span className='dark-100'>{new Date(test?.expiry_date).toDateString()}</span></span>
						</div>
					</div>
				</div>
			</div>
			<div className='assessment-custom-card-result-container' style={{padding: '0px'}}>
				<div className='assessment-result-card-container' style={{ gap: '8px' }}>
					<div className={`assessment-result-card-header assessment-result-card-header-expanded`}>
						<div className='w-100 h-100 d-flex flex-column justify-content-between' style={{ gap: '8px' }}>
							<div className={`w-100 d-flex flex-row align-items-center justify-content-between`} style={{ gap: '8px' }}>
								<div className='d-flex align-items-center'>
									{test?.test_type === 'coding_test' ? 
										<CTIcon style={{ height: '20px', width: '20px', stroke: '#808191'}}/>
										: test?.test_type === 'skill_test' &&
											<SBTIcon style={{ height: '20px', width: '20px', stroke: '#808191'}}/>
									}
									<span className='body-1 dark-100' style={{ marginLeft: '10px' }}>
										{test?.test_type === 'coding_test' ?
											'Coding Test'
											: test?.test_type === 'skill_test' &&
                                               'Skill Based Test'
										}
									</span>
								</div>
							</div>
							<div className={`d-flex align-items-start flex-column`}>
								<span className='body-3 dark-50' style={{ marginRight: '15px' }}>
									Total Score:{' '}
									<span className='dark-100'>
										{test?.test_type === 'coding_test' ? parseFloat((test?.score_object?.score) * 10).toFixed(1) : parseFloat((10 / test?.score_object?.total_questions) * test?.score_object?.correct_answers).toFixed(1)}/10
									</span>
								</span>
								<span className='body-3 dark-50' style={{ marginRight: '15px' }}>
									Finished in:{' '}
									<span className='dark-100'>{`${secondsToDhmsFormat(test?.finished_in)} out of ${secondsToDhmsFormat(test?.test_duration)}`}</span>
								</span>
							</div>
						</div>
					</div>
					<div className={`assessment-result-view ${'assessment-result-view-onExpand'}`}>
						<div className='test_result_card'>
							<div className='result_card-left-side' style={{gap: '8px'}}>
								{test?.test_icon &&
									<img
										src={test?.test_icon}
										style={{
											width: '16.8px',
											height: '16.8px',
										}}
									/>
								}
								<span className='body-2 dark-100 coding-title-truncate'>{test?.test_title}</span>
							</div>
							<div className='result_card-right-side'>
								<span className='body-2 primary-dark-text'>
									{test?.test_type === 'coding_test' ? parseFloat((test?.score_object?.score) * 10).toFixed(1) : parseFloat((10 / test?.score_object?.total_questions) * test?.score_object?.correct_answers).toFixed(1)}
									<span className='dark-50'>/10</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
