import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SectionType } from '../../utils/Constants';
import utilities from '../../utils/utilities';
import ProgressBar from '../common/ProgressBar';
import Timer from '../common/Timer';
import Button from '../sub-component/Button';
import AssessmentInfoModalCard from './AssessmentInfoModalCard';
import Lottie from 'react-lottie-player';
import warningAnimation from '../../assets/icons/candidate-assessment/warning-animation.json';
import { calculateExpiry, calculateTimeDifference } from '../../utils/utilities';
import { ReactComponent as DownArrow } from '../../assets/icons/Arrows/down.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomTooltip from '../common/CustomTooltip';
import AlertBox from '../common/AlertBox'; 

export default function AssessmentProgressInfoHeader(props) {
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	const [showInfoModal, setShowInfoModal] = useState(false);
	const assessment = useSelector((state) => state.assessmentReducer);
	const [showWarningSign, setShowWarningSign] = useState(false);
	const [testDetails, setTestDetails] = useState({
		title: '',
		currentQuestionNo: 0,
		totalQuestions: 0
	});
	const [timeDuration, setTimeDuration] = useState(props.duration || 0);

	const expiry = calculateTimeDifference(calculateExpiry(assessment.current_assessment?.deadline, assessment.current_assessment?.created_at));

	const animation = <Lottie loop animationData={warningAnimation} speed={0.5} style={{ width: '60px', height: '60px' }} play />;

	useEffect(() => {
		if (assessment.current_assessment?.active_test?.section !== null) {
			const active_test = assessment.current_assessment?.active_test;
			setTestDetails({
				title: active_test?.section?.type === SectionType.VALUE_TEST ? 'Value Fitness'
					: active_test?.section?.type === SectionType.PERSONALITY_TEST ? 'Personality Test'
						: active_test?.section?.type === SectionType.CASE_STUDY_TEST ? 'Case Study'
							: active_test?.section?.type === SectionType.TEXT_TEST ? 'Text Response'
								: active_test?.section?.type === SectionType.VIDEO_TEST ? 'Video Response'
									: active_test?.section?.type === SectionType.UPLOAD_TEST ? 'Upload File'
										: utilities.toTitleCase(active_test?.test?.title),
				currentQuestionNo: active_test?.test_progress?.attempted_ques + 1,
				totalQuestions: active_test?.test_progress?.total_ques
			});
		}
	}, [assessment]);

	const handleNotifyDeadline = () => {
		if (expiry.toFixed(0) <= 60) {
			setShowWarningSign(true);
		}
	};

	useEffect(() => {
		if (assessment.current_assessment?.deadline) {

			// Start the interval when the component mounts.
			handleNotifyDeadline();
			const intervalId = setInterval(handleNotifyDeadline, 30000); // 1000ms (1 second)

			// Clear the interval when the component unmounts to avoid memory leaks.
			return () => clearInterval(intervalId);
		}
	}, []);


	return (
		<Fragment>
			{showWarningSign && 
				<AlertBox varrient={'dismiss'} varrientColor='danger' varrientTitle='Deadline Reminder' varrientDescription={`Assessment will automatically conclude in ${expiry.toFixed(0)} minute${expiry.toFixed(0) < 2 ? '' : 's' }. Please submit all your responses before the deadline.`} />
			}
			<div onClick={() => setShowInfoModal(false)} className={`test-info-icon-container ${(showInfoModal && !isOnMobileScreen) && 'test-info-icon-container-open'} `}></div>
			<div className='test-progress-info-header'>
				<div className='d-flex align-items-center ' style={{ justifyContent: 'space-evenly', marginRight: '12px' }}>
					<div className='d-flex align-items-center'>
						<div className='black-400' style={{ maxWidth: '180px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
							<span className='body-1 dark-50'>{testDetails.title}</span>
						</div>
						<span className='body-1 dark-50' style={{ marginLeft: '2px' }}> : </span>
					</div>
					<div className='d-flex ' style={{ width: 'max-content' }}>
						<span className=' body-1 dark-100' style={{ marginLeft: '3px' }}>{testDetails.currentQuestionNo + ' / ' + testDetails.totalQuestions}</span>
					</div>
				</div>
				<div style={{ width: '100%' }}>
					<ProgressBar 
						size='lg' 
						text={`${testDetails.totalQuestions - testDetails.currentQuestionNo} question${testDetails.totalQuestions - testDetails.currentQuestionNo > 1 ? 's' : ''} remaining`}
						value={`${(testDetails.currentQuestionNo / testDetails.totalQuestions) * 100}`} 
					/>
				</div>
				{props?.showTimer &&
					<div
						className='d-flex justify-content-center align-item-center'
						style={{ marginLeft: '24px', minWidth: 'fit-content' }}>
						<Timer duration={props.duration}
							getUpdatedTime={(duration) => { setTimeDuration(duration); if (props?.getUpdatedTime) { props?.getUpdatedTime(duration); } }}
							isCaseStudy={true}
						/>
					</div>
				}
				<div className='test-progress-button'>
					{showWarningSign && 
						<CustomTooltip text={`${expiry.toFixed(0)} minute${expiry.toFixed(0) < 2 ? '' : 's' } remaining`}>
							{animation}
						</CustomTooltip>
					}
					<Button
						btn='ragular-btn'
						varrient='primary'
						title={'Assessment Progress'}
						style={{ width: isOnMobileScreen ? '100%' : 'max-content', height: '40px' }}
						onClick={() => {
							setShowInfoModal(!showInfoModal);
						}}
					/>
					{/* <div
						className='progress-info-section pointer'
						onClick={() => {
							setShowInfoModal(!showInfoModal);
						}}>
						{showWarningSign && animation}
						<span style={{ textDecoration: 'underline', marginLeft: '9px', lineHeight: '21px' }} className='body-2 primary-text semibold'>Assessment Progress</span>
						<DownArrow />
					</div> */}
					{showInfoModal && <AssessmentInfoModalCard showWarningSign={showWarningSign} assessment={assessment} handleClose={() => setShowInfoModal(false)} />}
				</div>
			</div>
		</Fragment>
	);
}
