import React, { useState, useEffect, useMemo, useRef } from 'react';
import TestContentWrapper from '../../../candidate-assessment-flow/flow-steps/tests/TestContentWrapper';
import ProcessingIndicator from '../../../../components/common/ProcessingIndicator';
import Button from '../../../../components/sub-component/Button';
import Select from '../../../../components/sub-component/Select';
import { langNameMapping } from '../../../../utils/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { createExecutedId, executeResult, resetExecResultState } from '../../../../redux/thunks/PublicTestpack';
import useMediaQuery from '@mui/material/useMediaQuery';
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';
import ReactQuill from 'react-quill';
import hljs from 'highlight.js';
import { ReactComponent as RunIcon } from '../../../../assets/icons/assessment-creation/play.svg';



let passedTests = 0;
let failedTests = 0;

const myTheme = createTheme({
	theme: 'dark',
	settings: {
		background: '#10182b',
		foreground: '#f8f8f2',
		caret: '#f8f8f0',
		selection: '#437cde',
		selectionMatch: '#437cde',
		gutterBackground: '#10182b',
		gutterForeground: '#6D8A88',
		gutterBorder: 'transparent',
		lineHighlight: 'rgba(255, 255, 255, 0.2)',
	},
	styles: [
		{ tag: t.comment, color: '#6272a4' },
		{ tag: t.string, color: '#f1fa8c' },
		{ tag: t.atom, color: '#bd93f9' },
		{ tag: t.meta, color: '#f8f8f2' },
		{ tag: [t.keyword, t.operator, t.tagName], color: '#ff79c6' },
		{ tag: [t.function(t.propertyName), t.propertyName], color: '#50fa7b' },
		{
			tag: [t.definition(t.variableName), t.function(t.variableName), t.className, t.attributeName],
			color: '#F4C20D',
		},
		{ tag: t.atom, color: '#bd93f9' },
	],
});

const modules = {
	syntax: {
		highlight: (text) => hljs.highlightAuto(text).value,
	},
};

export default function CodingTestPQ(props) {

	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	const dispatch = useDispatch();
	const { question, testId, isFirstQuestion, isLastQuestion, handleNext, handlePrev } = props;
	const testpack = useSelector((state) => state.publicTestpackReducer);

	const [apiAttempts, setApiAttempts] = useState(0);
	const [error, setError] = useState('');

	const [codeValue, setCodeValue] = useState(
		question?.initial_code ? atob(question?.initial_code) : '',
	);
	const [selectedPreferableLang, setSelectedPreferableLang] = useState(
		question?.code_languages
			? {
				name: question?.code_languages[0].language_name.charAt(0).toUpperCase() +
					question?.code_languages[0].language_name.slice(1),
				value: question?.code_languages[0].language_name,
				initial_code: question?.code_languages[0].initial_code,
				code_language: question?.code_languages[0].code_language
			}
			: question?.code_language
				? {
					name: question?.language_name,
					value: question?.language_name,
					initial_code: question?.initial_code,
					code_language: question?.code_language
				}
				:
				null
	);

	const onCodeChange = (value) => {
		setCodeValue(value);
	};

	const verifyCodeSnapShotOnRun = () => {
		if (!codeValue) {
			return;
		}
		const data = {
			tp_id: testId,
			pq_id: question?.id,
			code_language: question?.code_language,
			source_code: btoa(codeValue),
			type: 'verify',
			...(selectedPreferableLang && {
				code_language: selectedPreferableLang.code_language,
			}),
		};

		dispatch(createExecutedId({ data }));
		setError('');
	};

	const testResults = useMemo(() => {
		if (testpack?.exec_result === null) return;

		let ok = 0;
		let error = 0;

		testpack?.exec_result?.submissions?.forEach((submission) => {
			submission?.has_passed === true ? ok++ : error++;
		});

		return { ok, error };

	}, [testpack?.exec_result]);

	useMemo(() => {
		passedTests = 0;
		failedTests = 0;
		testpack?.exec_result?.submissions?.map((s) => {
			if (s.status.id === 3) {
				passedTests = passedTests + 1;
			} else {
				failedTests = failedTests + 1;
			}
		});
	}, [testpack?.exec_result?.submissions]);

	useEffect(() => {
		if (apiAttempts < 10 && !testpack?.exec_result_api_success) {
			const interval = setInterval(() => {
				if (testpack?.exec_result_id) {
					dispatch(executeResult({ id: testpack?.exec_result_id }));
					setApiAttempts(apiAttempts + 1);
				}
			}, 3000);

			return () => {
				clearInterval(interval);
			};
		} else if (apiAttempts >= 10) {
			dispatch(resetExecResultState());
			setApiAttempts(0);
			setError('Failed to fetch execution results after 10 tries. Try re-running your code.');
		}
	}, [dispatch, apiAttempts, testpack?.exec_result_api_success, testpack?.exec_result_id]);

	useEffect(() => {
		if (question?.language_name) {
			hljs.configure({
				languages: [question?.language_name],
			});
		}
	}, [question?.language_name]);

	useEffect(() => {
		if (question?.code_languages) {
			setSelectedPreferableLang({
				name: question?.code_languages[0]?.language_name?.charAt(0).toUpperCase() +
					question?.code_languages[0]?.language_name?.slice(1),
				value: question?.code_languages[0].language_name,
				initial_code: question?.code_languages[0].initial_code,
				code_language: question?.code_languages[0].code_language
			});

			setCodeValue(atob(question?.code_languages[0]?.initial_code));
		}
	}, [question?.code_languages]);

	const QuestionTextMemo = useMemo(() => {
		return (
			<ReactQuill
				className='ql-editor-coding-candidate-side text-read-only disable-text-selection'
				theme='bubble'
				readOnly
				modules={modules}
				value={question?.text}
			/>
		);
	}, [question]);

	return (
		<TestContentWrapper >
			<div className='d-flex flex-column w-100'>
				<div className='testpack-test-container justify-content-between'>
					<div className='testpack-test-questions-container '>
						{QuestionTextMemo}
					</div>

					<div className='testpack-test-answers-container' >
						<div
							className={`d-flex align-items-start ${isOnTabletScreen ? 'justify-content-end' : 'justify-content-start'} w-100`}
							style={{ marginBottom: '12px' }}
						>
							{question?.code_languages && (
								<Select
									className='justify-content-center'
									style={{ maxWidth: '247px', alignItem: 'flex-end' }}
									inputStyle={{
										color: '#6f2dbd',
										fontWeight: 500,
										fontSize: '14px',
										lineHeight: '20px',
									}}
									innerClassName='grape-placeholder'
									placeholder='Select Preferable Language'
									options={question?.code_languages.map((lang) => {
										return {
											name:
												lang.language_name.charAt(0).toUpperCase() +
												lang.language_name.slice(1),
											value: lang.language_name,
											initial_code: lang.initial_code,
											code_language: lang.code_language
										};
									})}
									readOnly
									selected={selectedPreferableLang?.value}
									onSelection={(data) => {
										setSelectedPreferableLang(data);
										setCodeValue(atob(data.initial_code));
									}}
								/>
							)}
						</div>
						{
							<div
								className='w-100 pt-2'
								style={{
									borderRadius: '8px 8px 0px 0px',
								}}
							>
								<CodeMirror
									value={codeValue}
									height='326px'
									extensions={[
										langs[langNameMapping(selectedPreferableLang.value)]
											? langs[langNameMapping(selectedPreferableLang.value)]()
											: [],
									]}
									theme={myTheme}
									indentWithTab={true}
									basicSetup={
										{ indentOnInput: true, tabSize: 4 }
									}
									onChange={onCodeChange}
									placeholder='Write your code here.'
									style={{ borderRadius: '8px 8px 0px 0px', background: '#121216' }}
								/>
								<div className='run-code-button'>
									{testpack?.exec_result !== null &&
										!testpack?.is_exec_result_poll && (
											<div className={`d-flex ${isOnMobileScreen ? 'flex-column' : 'flex-row'} align-items-start`}>
												<span className='body-2' style={{ color: '#121216' }}>
													Test Output:
												</span>
												<span
													className='body-2'
													style={{ color: '#00D69A', marginLeft: '16px' }}
												>
													{`Ok: ${testResults?.ok}`}
												</span>
												<span
													className='body-2'
													style={{ color: '#FC4848', marginLeft: '16px' }}
												>
													{`Error: ${testResults?.error}`}
												</span>
											</div>
										)}
									<button
										disabled={testpack?.processing_exec_result}
										className='button-small-text d-flex align-items-center'
										onClick={() => { verifyCodeSnapShotOnRun(); }}
										style={{ marginLeft: 'auto', background: '#121216', border: 'none', borderRadius: '50px', width: '95px', color: 'white', padding: '12px 24px 12px 24px', gap: '8px' }}
									>
										<RunIcon />
										Run
									</button>
								</div>
								<div className='CT-result-screen'>
									<div style={{ width: '100%' }}>
										{(() => {
											{
												if (testpack?.processing_exec_result) {
													return (
														<span style={{ textAlign: 'left', display: 'block' }}>
															Compiling...
														</span>
													);
												}
											}

											return testpack?.exec_result?.tokens?.map((token, index) => {
												let results = '';

												if (token?.name) results += `(${token.name}): \n`;

												const submission = testpack?.exec_result?.submissions?.find(
													(submission) => submission.token === token.token,
												);

												if (submission?.message)
													results += `${atob(submission.message)}\n --- \n`;

												if (submission?.stderr)
													results += `${atob(submission.stderr)}\n`;

												if (token?.input_args)
													results += `Input parameters -> ${atob(token.input_args)}\n`;

												if (submission?.expected_output)
													results += `Expected output -> ${atob(
														submission.expected_output,
													)}\n`;

												if (submission?.compile_output && submission?.status.id != 3)
													results += `Compile output -> ${atob(
														submission.compile_output,
													)}\n`;

												if (submission?.stdout)
													results += `Standard output -> ${atob(submission.stdout)}\n`;

												if (submission?.function_return)
													results += `Output -> ${atob(submission.function_return)}\n`;

												return (
													<div
														key={Math.random() * index}
														style={{
															textAlign: 'start',
															borderBottom: '1px solid #FFF',
															padding: '10px 0',
															color: submission?.has_passed ? '#00D69A' : '#FC4848',
														}}
													>
														<pre>{results}</pre>
													</div>
												);
											});
										})()}
									</div>
								</div>
							</div>
						}
						{error &&
							<div className='w-100 d-flex align-items-center justify-content-center' style={{ marginTop: '24px' }}>
								<span className='danger-text'>{error}</span>
							</div>
						}
						<div className='w-100 d-flex justify-content-end align-items-end'
							style={{ marginTop: '24px', gap: '12px' }}
						>
							{!isFirstQuestion &&
								<Button
									processing={props.processing}
									btn='ragular-btn' varrient={'secondary-btn'}
									title={'Previous'}
									style={{ width: '103px', height: '40px' }}
									onClick={handlePrev}
								/>
							}
							{!isLastQuestion &&
								<Button
									processing={props.processing}
									btn='ragular-btn' varrient={'primary'}
									title={'Next'}
									style={{ width: '78px', height: '40px' }}
									onClick={handleNext}
								/>
							}
						</div>
					</div>
				</div>
			</div>
		</TestContentWrapper>
	);
}
