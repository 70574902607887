import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const getTenant = createAsyncThunk(
	'tenant/getTenant',
	async ({ id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/tenants/${id}`,
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);