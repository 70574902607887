import React from 'react';
import { useEffect } from 'react';
// import SignInMobile from './SignInMobile';
// import SignInWeb from './SignInWeb';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import LoginForm from '../components/auth/LoginForm';
import OnboardingBox from '../components/OnboardingBox';
import { PathName } from '../utils/routes';
import { useMediaQuery } from '@mui/material';
// import * as authActions from '../../redux/actions/Auth/authAction';
// import { Redirect } from "react-router-dom";

export default function SignIn(props) {
	const dispatch = useDispatch();
	const search = useLocation().search;
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	useEffect(() => {
		// dispatch(authActions.refreshToken(true , props?.linkId));
	}, [dispatch, props?.linkId]);

	const history = useHistory();

	return (
		<>
			<OnboardingBox >
				<div className="d-flex flex-column login-form-container">
					<div className="form">
						<div className={`${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-3'} dark-100`} id="login">
							Login
						</div>
						<div className="link-text">
							<span className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '2'}`}>{`Don't have an account? `}</span>
							<span className={`link-to-login pointer body-${isOnMobileScreen || isOnTabletScreen ? '1' : '2'}`} onClick={() => {
								history.push({
									pathname: PathName.SIGNUP,
									search: search
								});
							}}>Sign Up</span>
						</div>
						<div>
							<LoginForm />
						</div>
					</div>
				</div>
			</OnboardingBox>
		</>
	);
}
