import { createSlice } from '@reduxjs/toolkit';
import { listTestPacks } from '../thunks/TestPack';

const TestPack = createSlice({
	name: 'Test Pack',

	initialState: {
		loading: false,
		data: null,
		error_message: null,
		success_message: null,
	},

	reducers: {},

	extraReducers: {

		[listTestPacks.pending]: (state) => {
			return {
				...state,
				loading: true
			};
		},

		[listTestPacks.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				data: payload,
			};
		},

		[listTestPacks.rejected]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				error_message: payload
			};
		}
	},
});

export default TestPack.reducer;