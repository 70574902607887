import React, { useState } from 'react';
import { ReactComponent as Search } from '../../assets/icons/Views/candidate/assessments/search.svg';
import { ReactComponent as Filter } from '../../assets/icons/Views/candidate/dashboard/filter.svg';
import { ReactComponent as Sort } from '../../assets/icons/Views/candidate/dashboard/sort.svg';
import { ReactComponent as Clipboard } from '../../assets/icons/Views/candidate/dashboard/clipboard.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/components/common/modal/cross.svg';
import CustomTooltip from './CustomTooltip';
import Input from '../sub-component/Input';
import Select from '../sub-component/Select';
import { useFocusWithin } from '@react-aria/interactions';

export default function NavBar(props) {

	const { navLinks, onNavSelect, selectedNav, setSearchQuery, filters, setFilters } = props;
	const [openSearch, setOpenSearch] = useState(false);
	const [companySearch, setCompanySearch] = useState(false);
	const [openFilters, setOpenFilters] = useState(false);
	const [openSort, setOpenSort] = useState(false);
	const [currentText, setCurrentText] = useState('');

	const sortingByOptions = [
		{ name: 'Expiry Date', value: 'expiry' },
		{ name: 'Invited Date', value: 'invite' },
	];

	const sortingOptions = [
		{ name: 'Ascending', value: 'asc' },
		{ name: 'Descending', value: 'dsc' },
		// { name: 'None', value: 'none' }
	];

	const handleRemoveCompanyTags = () => {
		setFilters({...filters, companyTags: []});
		setCurrentText('');
		handleCloseAll();
	};

	const handleRemoveFilters = () => {
		setFilters({...filters, status: '', title: ''});
		handleCloseAll();
	};

	const handleRemoveSorting = () => {
		setFilters({...filters, type: '', option: ''});
		handleCloseAll();
	};

	const handleCloseAll = () =>{
		setOpenSearch(false);
		setCompanySearch(false);
		setOpenFilters(false);
		setOpenSort(false);
	};


	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: (e) => { },
		onBlurWithin: (e) => {
			handleCloseAll();
		}
	});

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			if (currentText) {
				setFilters({ ...filters, companyTags: [...(filters.companyTags || []), currentText] });
				setCurrentText('');
			}
		}
	};

	const deleteTagAtIndex = (index) => {
		if (index >= 0 && index < filters.companyTags.length) {
			const newArray = [...filters.companyTags];
			newArray.splice(index, 1);
			setFilters({ ...filters, companyTags: newArray });
			// setCompanyTags(newArray);
			return true;
		} else {
			return false;
		}
	};

	return (
		<div className='nav-bar'>
			<div className='assessments-search-bar'>
				<div className='search'>
					<Search height={'24px'} width={'24px'} />
					<Input
						inputStyle={{
							background: 'transparent',
							border: 'none',
						}}
						className={'w-100'}
						placeholder={'Search by assessment title...'}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
				</div>
			</div>
			<div tabIndex={-1} {...focusWithinProps} className='d-flex flex-row align-items-center' style={{ width: '84px', gap: '16px' }}>
				<div  style={{ position: 'relative' }}>
					<Filter height={'44px'} width={'44px'} cursor={'pointer'} onClick={(e) => { handleCloseAll(); setOpenFilters(true); e.stopPropagation(); e.preventDefault(); }} />
					{openFilters && 
						<div style={{ width: '21.875em' }} className='nav-popup'>
							<div className='w-100 d-flex align-items-center justify-content-between'>
								<span className='subtitle-2 dark-100'>Advanced Filters</span>
								<span className='body-2 dark-100 pointer' onClick={handleRemoveFilters}>Remove All</span>
							</div>
							{/* <div style={{ gap: '24px' }} className='d-flex align-items-center'>
								<span className='body-3 dark-100'>Filter</span>
							</div> */}
							<Select readOnly varrient='primary' selected={ filters.status ||''} options={navLinks} onSelection={(status) => setFilters({ ...filters, status: status.value })} placeholder={'Select filter'} />
						</div>
					}
				</div>
				<div style={{ position: 'relative' }}>
					<Sort height={'44px'} width={'44px'} cursor={'pointer'} onClick={(e) => { handleCloseAll(); setOpenSort(true); e.stopPropagation(); e.preventDefault(); }}/>
					{openSort && 
						<div style={{ width: '21.875em' }} className='nav-popup'>
							<div className='w-100 d-flex align-items-center justify-content-between'>
								<span className='subtitle-2 dark-100'>Sort</span>
								<span className='body-2 dark-100 pointer' onClick={handleRemoveSorting}>Remove All</span>
							</div>
							<div className='d-flex align-items-center'>
								<span style={{width: '120px'}} className='body-3 dark-100 text-start'>Sort By</span>
								<Select readOnly varrient='primary' selected={filters.type || ''} options={sortingByOptions} onSelection={(type) => setFilters({ ...filters, type: type.value })} placeholder={'Choose property'} />
							</div>
							<div className='d-flex align-items-center'>
								<span style={{width: '120px'}} className='body-3 dark-100 text-start'>Direction</span>
								<Select readOnly varrient='primary' selected={ filters.option ||''} options={sortingOptions} onSelection={(option) => setFilters({ ...filters, option: option.value })} placeholder={'Choose direction'} />
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}
