import React from 'react';
import { useState } from 'react';


import { useDispatch } from 'react-redux';


import { useMediaQuery } from '@mui/material';
import OnboardingBox from '../components/OnboardingBox';
import Button from '../components/sub-component/Button';
import { PathName } from '../utils/routes';

function UnavailableAssessment() {
	const [processing, setProcessing] = useState(false);
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const handleSubmit = async (e) => {
		e.preventDefault();
		setProcessing(true);
		window.location.replace(PathName.ASSESSMENTS);
		setProcessing(false);
	};

	return (
		<>
			<OnboardingBox>
				<div
					className="d-flex flex-column login-form-container"
					style={{ width: '100%', margin: 'auto' }}
				>
					<div className="form">
						<div className={`${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-medium'} bold`}>Assessment Unavailable</div>
						<div className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '3'} light`} style={{ marginTop: '10px' }}>
							{`We're sorry, but the assessment you are trying to access is no longer available. This could be due to various reasons, such as the assessment period has ended or it has been withdrawn.`}
						</div>
						{/* <div className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '3'} light`} style={{ marginTop: '10px' }}>
							If you have any questions or concerns, please feel free to <span style={{color:'#6F2DBD'}}>contact</span> our support team for assistance.
						</div> */}
						<div>
							<form onSubmit={handleSubmit}>
								<div className="login-input-group">

									<div >
										<Button
											themeRectangleButton
											processing={processing}
											type={'submit'}
											title={'Go To Dashboard'}
											style={{ height: '40px', marginTop: '32px' }}
										/>
									</div>


								</div>
							</form>
						</div>
					</div>
				</div>
			</OnboardingBox>
		</>
	);
}

export default UnavailableAssessment;
