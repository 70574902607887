import React, { useEffect } from 'react';
import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import OnboardingBox from '../../../components/OnboardingBox';
import Button from '../../../components/sub-component/Button';
import PasswordSetUp from '../../PasswordSetUp';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../redux/thunks/User';
import Emoji from '../../../assets/icons/onboarding/Emoji.svg';
import ProcessingIndicator from '../../../components/common/ProcessingIndicator';


function AssessmentConcluded() {

	const [processing, setProcessing] = useState(false);
	const [password, setPassword] = useState(false);
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const access_token = useSelector((state) => state.authReducer?.data?.access_token);
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const auth = useSelector((state) => state.authReducer);


	const assessment = useSelector((state) => state.assessmentReducer);
	console.log(assessment, 'Rejected');
	const dispatch = useDispatch();
	useEffect(() => {
		const fetchUserProfile = async () => {
			setProcessing(true);
			try {
				const userResponse = await dispatch(getUser({ access_token: access_token }));
				const user = userResponse?.payload;
				setUser(user);
				setProcessing(false);
			} catch (error) {
				console.error('Error fetching user profile:', error);
				setProcessing(false);
			}
		};

		fetchUserProfile();
	}, []);
	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 1000);
		return () => clearTimeout(timer);
	}, []);

	const handleSubmitPassword = async () => {
		setProcessing(true);
		// e.preventDefault();
		setPassword(true);
		setProcessing(false);

	};
	const handleSubmit = async () => {
		setProcessing(true);
		window.location.replace('/dashboard/assessments');
		setProcessing(false);
	};
	return (
		<>
			{!password ? (
				<div className='candidate-assessment-flow-container align-items-center justify-content-center'>
					<div className='candidate-assment-flow-wrapper flex-column align-items-center justify-content-center'>
						<div className='d-flex flex-column align-items-center' style={{maxWidth: '664px', padding:'24px', gap: '24px', borderRadius:'24px',backgroundColor:'white'}}>
							<img src={Emoji} alt='' style={{ height: '60px', width: '60px', marginBottom: '-4px' }} />
							<span className={`${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-4'}  dark-100 text-center`}>Assessment Completed Successfully!</span>
							<span className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '3'} dark-50 text-center`} style={{ lineHeight: '16.8px' }}>
								{`Congratulations! You have successfully completed all stages of our recruitment process. To finalize your application, please set up a password for your account. We appreciate your time, effort, and enthusiasm throughout the assessments and interviews. Our team will now review all candidates and make final decisions. Thank you for your interest in joining our team. We will be in touch with further updates regarding the status of your application.`}
							</span>
							<div className='login-input-group d-flex justify-content-center'>
								{loading ?
									<div style={{ height: '20px', width: '20px' }}>
										<ProcessingIndicator />
									</div>
									:
									<Button
										themeRectangleButton
										processing={processing}
										onClick={user?.has_set_password === false ? handleSubmitPassword : handleSubmit}
										title={user?.has_set_password === false ? 'Set Up Password' : 'Go To Dashboard'}
										style={{ height: '52px', width: '184px' }}
									/>}
							</div>
						</div>
					</div>
				</div>
			) : (
				<PasswordSetUp assessment={assessment} access_token={access_token} />
			)}
		</>
	);
}

export default AssessmentConcluded;
