import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import { UserRole, fullBlock, inviteBlock } from '../utils/Constants';
import { PathName } from '../utils/routes';
import { getAssessment, takeOverSession } from '../redux/thunks/Assessment';
import CandidateAssessments from './candidates/assessments/CandidateAssessments';
import AdminAssessments from './dashboard/assessments/AdminAssessments';
import AssessmentCreateWithTestpack from './AssessmentCreateWithTestpack';
import ProcessingIndicator from '../components/common/ProcessingIndicator';

export default function AssessmentPage(props) {

	const auth = useSelector((state) => state.authReducer);
	const assessment = useSelector((state) => state.assessmentReducer);
	const { routeDetails } = props;
	const history = useHistory();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);


	useEffect(() => {
		if (localStorage.getItem('assess_id')) {
			dispatch(getAssessment({ id: localStorage.getItem('assess_id') }));
		}
	}, []);
	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsLoading(false);
		}, 1000);
		return () => clearTimeout(timeout);

	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			localStorage.removeItem('assess_id');
		}, 3000);
		return () => {
			clearInterval(interval);
		};
	}, []);
	return (
		<Switch>
			{
				Object.values(routeDetails.nested_routes).map((route, i) => {
					if (route.required_roles.some(item => auth.data.role.name === item)) {
						return (
							<Route exact={route.exact} path={`${routeDetails.pathname}${route.pathname}`} key={`${route.pathname}_${i}`}>
								<route.component routeDetails={route} />
							</Route>
						);

					}
				})

			}
			{
				
				(auth.data?.role && auth.data.role.name === UserRole.CANDIDATE)
					?
					<Route exact={true} path={`${routeDetails.pathname}`}>
						{isLoading ? (
							<>
								<div style={{width:'100%',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
									<div>
										<div className="processing-indicator primary" style={{width: '40px', height: '40px' }} />
									</div>
								</div>
							</>
						) : (
							(assessment?.current_assessment?.status === 'PENDING' && assessment?.data?.[0]?.spec_status === 'ACTIVE' && (!inviteBlock.includes(assessment?.current_assessment?.tenant?.status) && !fullBlock.includes(assessment?.current_assessment?.tenant?.status))) ? (
								<Redirect to={`${PathName.ASSESSMENTS}/${assessment?.current_assessment?.id}`} />
							) : (
								<CandidateAssessments />
							)
						)}
					</Route>
					:
					((localStorage.getItem('test_type') && localStorage.getItem('test_id')))
						?
						<Route exact={true} path={`${routeDetails.pathname}`}>
							<AssessmentCreateWithTestpack />
						</Route>
						:
						<Route exact path={`${routeDetails.pathname}`} render={() => (
							<Redirect to={`${routeDetails.pathname}${PathName.ACTIVE_ASSESSMENTS}`} />
						)} />
			}
		</Switch>
	);

}
