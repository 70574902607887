import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createResource, deleteResource, resetResourceState } from '../../../../redux/thunks/Resource';
import { CaseStudyQuestionTypes, TestType } from '../../../../utils/Constants';
import { ReactComponent as FileUplaodedIcon } from '../../../../assets/icons/candidate-assessment/file-uploaded.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/candidate-assessment/upload.svg';
import { ReactComponent as AttachIcon } from '../../../../assets/icons/candidate-assessment/attach.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/candidate-assessment/trash.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/candidate-assessment/x.svg';
import { ReactComponent as DownArrow } from '../../../../assets/icons/Arrows/CaretLeft.svg';
import VideoRecorderWithChoice from '../../../../components/shared/VideoRecorderWithChoice';
import VideoRecorder from '../../../../components/candidate-assessment-flow/VideoRecorder';
import ProcessingIndicator from '../../../../components/common/ProcessingIndicator';
import ProgressBar from '../../../../components/common/ProgressBar';
import Button from '../../../../components/sub-component/Button';
import Timer from '../../../../components/common/Timer';
import TestContentWrapper from './TestContentWrapper';
import { useFocusWithin } from '@react-aria/interactions';
import { CircularProgress } from '@mui/material';
import ReactQuill from 'react-quill';

export default function CaseStudyTestCS(props) {
	const { testProgress, test, question, processing, assessmentId } = props;
	const dispatch = useDispatch();

	const resource = useSelector((state) => state.resourceReducer);
	const [isLoomInProcess, setIsLoomInProcess] = useState(false);

	const [viewMore, setViewMore] = useState(false);
	const [textResponse, setTextResponse] = useState(null);
	const [resourceId, setResourceId] = useState(null);
	const [url, setUrl] = useState(null);
	const [isDragActive, setIsDragActive] = useState(false);
	const [isDeclined, setIsDeclined] = useState(false);
	const [uploadState, setUploadState] = useState('empty');
	const [error, setError] = useState('');
	const [isVideoUploading, setIsVideoUploading] = useState(false);
	const [abortController, setAbortController] = useState(new AbortController());
	const [uploadFileObject, setUploadFileObject] = useState(null);
	const [loomVideoURL, setLoomVideoURL] = useState('');
	const [videoMode, setvideoMode] = useState('');

	const onQuestionSubmit = () => {

		let data = null;

		if (question.type === CaseStudyQuestionTypes.VIDEO_RESPONSE) {
			data = {
				assess_id: assessmentId,
				...(videoMode === 'loom' ? { loom_video_url: loomVideoURL, recording_type: videoMode } : { resource_id: resourceId, recording_type: videoMode }),
			};
		}
		else {
			data = {
				assess_id: assessmentId,
				...(textResponse ? { response_text: textResponse } : null),
				...(resourceId ? { resource_id: resourceId } : null),
			};
		}
		if (props.onQuestionSubmit && data) {
			props.onQuestionSubmit(data);
			// dispatch(resetResourceState());
		}
	};

	const handleFileUpload = async () => {
		if (resource.processing) {
			return;
		}
		const input = document.createElement('input');

		input.setAttribute('type', 'file');
		// input.setAttribute('accept', 'image/*');
		input.click();

		input.onchange = async () => {
			const file = input.files[0];
			setUploadState('uploading');
			if ((file.size / 1024) / 1024 > 5) {
				setError('The uploaded file exceeded the max size limit (i.e 5mb)');
				return;
			}

			setError('');
			if (resourceId) {
				await dispatch(deleteResource({ id: resourceId }));
			}
			setUploadFileObject(file);
			uploadFile(file);
		};
	};

	const handleCancel = () => {
		abortController.abort();
	};

	const handleDragFile = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.type === 'dragenter' || e.type === 'dragover') {
			setIsDragActive(true);
		} else if (e.type === 'dragleave') {
			setIsDragActive(false);
		}
	};

	const handleDragFileUpload = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		setIsDragActive(false);

		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			const file = e.dataTransfer.files[0];
			if ((file.size / 1024) / 1024 > 5) {
				setError('The uploaded file exceeded the max size limit (i.e 5mb)');
				return;
			}
			setError('');
			uploadFile(file);
		}
	};

	const uploadFile = async (file) => {
		const formData = new FormData();

		formData.append('file', file);

		const uploadFileReq = await dispatch(createResource({ data: formData, signal: abortController.signal }));
		if (uploadFileReq.meta.requestStatus === 'rejected') {
			if (!abortController?.signal?.aborted) {
				setError('Failed to upload file. Please check your internet connection and retry.');
				return;
			}
			setAbortController(new AbortController());
		}
		else {
			setResourceId(uploadFileReq.payload.id);
			setUrl(uploadFileReq.payload.url);
			setUploadState('uploading done');
			if (isDeclined) {
				setIsDeclined(false);
			}
			setUploadFileObject(null);
		}
	};

	const remainTime = useMemo(() => {
		const serveTime = new Date(test.serve_time);
		const duration = test.duration * 1000;

		const remainingTime = (serveTime.getTime() + duration - Date.now()) / 1000;

		return Number(remainingTime.toFixed(0));
	}, [test?.serve_time]);

	useEffect(() => {
		setViewMore(false);
		setTextResponse(null);
		setResourceId(null);
		setIsDragActive(false);
		setUrl(null);
	}, [question]);

	useEffect(() => {
		dispatch(resetResourceState());
	}, [test]);

	useEffect(() => {
		console.log(resourceId, isVideoUploading);
		if (isVideoUploading && resourceId) {
			dispatch(deleteResource({ id: resourceId }));
		}
		setIsVideoUploading(false);
	}, [isVideoUploading]);


	const [timeDuration, setTimeDuration] = useState(0);
	const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: e => { },
		onBlurWithin: e => { setIsSkipModalOpen(false); }
	});



	const TimerMemo = useMemo(() => {
		if (timeDuration === 0) {
			setIsSkipModalOpen(false);
		}

		return (
			<div className='d-none'>
				<Timer duration={remainTime}
					getUpdatedTime={(duration) => { setTimeDuration(duration); }}
				/>
			</div>
		);
	}, [timeDuration]);


	return (
		<TestContentWrapper
			title={
				<div className='d-flex flex-column'>
					{/* <span
						className={`disable-text-selection ${viewMore
							? 'case-study-expanded-intro-text'
							: 'case-study-collapsed-intro-text'
						}`}
					>
						{viewMore ? (
							<ReactQuill
								className='ql-editor-test-instruction text-read-only disable-text-selection'
								theme='bubble'
								readOnly
								value={test.intro_text}
							/>
						) : (
							test.intro_text.replace(/<[^>]+>/g, '')
						)}
					</span>
					<div
						className='d-flex align-items-center justify-content-between case-study-collapsed-intro-text-view-more'
						onClick={() => setViewMore(!viewMore)}
					>
						<span className='mr-1'>{viewMore ? 'View Less' : 'View More'}</span>
						{viewMore ? <UpArrow /> : <DownArrow />}
					</div> */}
				</div>
			}
			duration={remainTime}
			currentQuestion={testProgress?.attempted_ques + 1}
			totalQuestions={testProgress?.total_ques}
			is_mandatory={props.is_mandatory}
			answer={(question?.type === CaseStudyQuestionTypes.TEXT_RESPONSE ? textResponse?.replace(/<[^>]+>/g, '') : true) &&
				((question?.type === CaseStudyQuestionTypes.UPLOAD_RESPONSE || question?.type === CaseStudyQuestionTypes.VIDEO_RESPONSE) ? resourceId : true)
			}
			processing={processing}
		>
			{(() => {
				if (!question) {
					return (
						<div className='align-self-center' style={{ marginTop: '100px' }}>
							<ProcessingIndicator className={'medium-indicator'} />
						</div>
					);
				}

				return (
					<div className='assessment-test-container flex-column' style={{gap: '24px'}}>
						<div className='d-flex flex-column'>
							<span
								className={`disable-text-selection ${viewMore
									? 'case-study-expanded-intro-text'
									: 'case-study-collapsed-intro-text'
								}`}
							>
								{viewMore ? (
									<ReactQuill
										className='ql-editor-test-instruction text-read-only disable-text-selection'
										theme='bubble'
										readOnly
										value={test.intro_text}
									/>
								) : (
									test.intro_text.replace(/<[^>]+>/g, '')
								)}
							</span>
							<div
								className='d-flex align-items-center case-study-collapsed-intro-text-view-more'
								style={{ gap: '5px', marginTop: viewMore && '12px' }}
								onClick={() => setViewMore(!viewMore)}
							>
								<span className='body-2 dark-100'>{viewMore ? 'View Less' : 'View More'}</span>
								<DownArrow style={{ transform: viewMore ? 'rotate(90deg)' : 'rotate(270deg)' }} />
							</div>
						</div>
						{TimerMemo}
						{question.type === CaseStudyQuestionTypes.TEXT_RESPONSE && (
							<div className='case-study-question-container'>
								<ReactQuill
									className='text-response-ql-editor text-read-only disable-text-selection'
									theme='bubble'
									readOnly
									value={question?.text}
								/>
								<span className='subtitle-2 dark-100 text-start'>Your Response:</span>
								<ReactQuill
									className='text-response-ql-editor case-study-cs-quill-container'
									theme='snow'
									value={textResponse}
									onChange={setTextResponse}
									placeholder='Text here'
								/>
							</div>
						)}
						{question.type === CaseStudyQuestionTypes.UPLOAD_RESPONSE && (
							<div className='case-study-question-container'>
								<ReactQuill
									className='text-response-ql-editor text-read-only disable-text-selection'
									theme='bubble'
									readOnly
									value={question?.text}
								/>
								<span className='subtitle-2 dark-100 text-start'>Your Response:</span>
								<>
									<div className='text-upload-doc-container'>
										<div 
											className='d-flex flex-column align-items-center pointer' 
											style={{ position: 'relative', maxWidth: '260px', height: '90px', gap: '12px' }}
											onClick={handleFileUpload}
											onDragEnter={handleDragFile}
											onDragLeave={handleDragFile}
											onDragOver={handleDragFile}
											onDrop={handleDragFileUpload}
										>
											<UploadIcon />
											<span className='subtitle-2 dark-100'>Click to upload or drag and drop</span>
											<span className='body-2 dark-50'>pdf, csv, txt, or doc (max size 5mb)</span>
										</div>
									</div>
									{resource?.upload_percentage > 0 ?
										<div className='uploaded-doc-container'>
											<AttachIcon />
											<div className='d-flex flex-column align-items-start' style={{width: 'calc(100% - 36px)', gap: '8px'}}>
												<div className='w-100 d-flex flex-row align-items-center justify-content-between'>
													<div className='w-100 d-flex flex-column align-items-start'>
														<span className='body-2 dark-100'>{resource.processing ? uploadFileObject?.name : resource?.data?.name}</span>
														<span className='body-2 dark-100'>{(resource?.data?.size / 1024).toFixed(1)} kb</span>
													</div>
													{resource?.upload_percentage < 100 ? 
														<CrossIcon cursor='pointer' onClick={handleCancel} />
														:
														<DeleteIcon cursor='pointer' onClick={handleCancel} />
													}
												</div>
												<ProgressBar 
													varrient={'progress-with-upload-doc'}
													value={resource?.upload_percentage}
												/>
											</div>
										</div>
										: 
										error &&
											<div className='uploaded-doc-container align-items-center'>
												<AttachIcon />
												<span className='body-2 danger-text'>{error}</span>
											</div>
									}
								</>
							</div>
						)}
						{question.type === CaseStudyQuestionTypes.VIDEO_RESPONSE && (
							<div className='case-study-question-container'>
								<ReactQuill
									className='text-response-ql-editor text-read-only disable-text-selection'
									theme='bubble'
									readOnly
									value={question?.text}
								/>
								<span className='subtitle-2 dark-100 text-start'>Your Response:</span>
								<div className='w-100'>
									<VideoRecorderWithChoice
										question={question}
										testType={TestType.CASE_STUDY_TEST}
										videoCapturedAndUploaded={(video) => {
											if (videoMode === 'loom') {
												setLoomVideoURL(video);
												setResourceId('');
											}
											else {
												setLoomVideoURL('');
												setResourceId(video);
											}
										}}
										isSomthingIsInProcess={resource.processing || isLoomInProcess}
										setVideoCaptureMode={(video_mode) => { setvideoMode(video_mode); }}
										onRetry={() => { }}
										setIsSkipModalOpen={(data) => { setIsSkipModalOpen(data); }}
										isLoomVideoRecording={(status) => { setIsLoomInProcess(status); }}
									/>
								</div>
							</div>
						)}
						<div className='w-100 d-flex justify-content-end align-items-end'>
							<div tabIndex={-1} {...focusWithinProps}>
								{isSkipModalOpen ?
									<Button
										processing={props.processing}
										btn='ragular-btn' 
										varrient={'primary'}
										title={'Skip Question?'}
										style={{ width: 'max-content', height: '40px' }}
										onClick={() => {
											const data = {
												assess_id: props?.assessmentId
											};
											if (props.onQuestionSubmit) {
												props.onQuestionSubmit(data);
												// dispatch(resetResourceState());
											}
											setError('');
											setIsSkipModalOpen(false);
										}} 
									/>
									:
									<Button
										processing={props.processing}
										disabled={
											resource.processing ||
												(props.is_mandatory
													&& (timeDuration > 0)
													&& !((question?.type === CaseStudyQuestionTypes.TEXT_RESPONSE ? (textResponse ? textResponse?.replace(/<[^>]+>/g, '') : '') : true) &&
														((question?.type === CaseStudyQuestionTypes.UPLOAD_RESPONSE) ? (resourceId ? resourceId : false) : question?.type === CaseStudyQuestionTypes.VIDEO_RESPONSE ? (((videoMode === 'native' && resourceId) || (videoMode === 'loom' && loomVideoURL)) ? (resourceId || loomVideoURL) : false) : true))
												) || isLoomInProcess}
										btn="ragular-btn" varrient={'primary'}
										title={resource.processing ? 'Uploading...' : (props.currentQuestionNo === props.totalQuestions || 0 ? 'Submit' : 'Next')}
										style={{ width: '120px', height: '40px' }}
										onClick={() => {
											const answer = (question?.type === CaseStudyQuestionTypes.TEXT_RESPONSE ? (textResponse ? textResponse?.replace(/<[^>]+>/g, '') : '') : true) &&
													((question?.type === CaseStudyQuestionTypes.UPLOAD_RESPONSE) ? (resourceId ? resourceId : false) : question?.type === CaseStudyQuestionTypes.VIDEO_RESPONSE ? (((videoMode === 'native' && resourceId) || (videoMode === 'loom' && loomVideoURL)) ? (resourceId || loomVideoURL) : false) : true);
											if (!answer && (timeDuration === null || timeDuration > 0)) {
												setIsSkipModalOpen(true);

											}
											else if (!isSkipModalOpen) {
												onQuestionSubmit();
												setIsSkipModalOpen(false);
											}
										}} />
								}
							</div>
						</div>
					</div>
				);
			})()}
		</TestContentWrapper>
	);
}
