import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as SmileIcon } from '../../assets/icons/basic/smile.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/basic/success.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/basic/warning.svg';
import { ReactComponent as DangerIcon } from '../../assets/icons/basic/danger.svg';
import { updateSlice } from '../../redux/slices/AlertSlice';
import { useSelector, useDispatch } from 'react-redux';

/**
 * 
 * @param {object} props Props object for component
 * @param {string} props.color class name for alert
 * @param {string} props.title Title of the alert
 * @param {string} props.description Description of the alert
 * @param {Function} props.onCloseAlert Function To Close Modal which is used to set its parent isOpen state to null to close the Alert
 * @returns 
 */
export default function AlertBox(props) {

	const { onCloseAlert, color, description, title, alert, varrient, varrientColor, varrientDescription, varrientTitle } = props;
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();

	useMemo(() => {
		if(alert?.message || alert?.color) {
			setIsOpen(true);
		}
	}, [alert]);

	useMemo(() => {
		if (color || title || description || varrient) {
			setIsOpen(true);
		}
	}, [color, title, description, varrient]);

	const handleDismiss = () => {
		setIsOpen(false);
	};

	const handleAutoDismiss = () => {
		dispatch(updateSlice({ message: '', title: '', color: '', autoRemove: false }));
	};

	useMemo(() => {
		if (isOpen && !varrient && ((!alert?.message || !alert?.color) || (alert?.message && alert?.color && alert?.autoRemove))) {
			setTimeout(() => {
				setIsOpen(false);
				if (onCloseAlert) {
					onCloseAlert();
				}
				if (alert?.autoRemove) {
					handleAutoDismiss();
				}
			}, 2500);
		}
		return;
	}, [isOpen]);

	if (alert?.message || alert?.color) {
		return (
			<>
				{
					isOpen
						?
						<div className='alert-box-container'>
							<div className={`error-alert-box ${alert?.color || 'success'}`}>
								<div className='alert-icon'>
									{alert?.color === 'success' ?
										<SuccessIcon className={`${alert?.color}`} />
										:
										alert?.color === 'warning' ?
											<WarningIcon className={`${alert?.color}`} />
											:
											alert?.color === 'danger' ?
												<DangerIcon className={`${alert?.color}`} />
												:
												<SmileIcon className={`${alert?.color}`} />
									}
								</div>
								<div className='alert-text w-100'>
									<div className='text-header w-100'>
										<span className='body-2 medium black-700'>{alert?.message === 'Network Error' ? 'You are Offline!' : alert?.title}</span>
										{!alert?.autoRemove &&
											<span className={`body-3 semibold dismiss-text ${alert?.color}`} onClick={handleDismiss}>Dismiss</span>
										}
									</div>
									<span className={`${!alert?.autoRemove ? 'body-3 light danger-text' : 'body-2 dark-50'}`}>{alert?.message === 'Connection error. Try again.' ? 'Something is wrong from our side. Please try again or contact support.' : alert?.message === 'Network Error' ? 'Cannot connect to the internet. Please fix your connection and try again.' : alert?.message}</span>
								</div>
							</div>
						</div>
						:
						<></>
				}
			</>
		);
	} else if (varrient === 'check-online-alert') {
		return (
			<>
				{isOpen ?
					<div className='alert-box-container'>
						<div className={`error-alert-box danger`}>
							<div className='alert-icon'>
								<DangerIcon className={`danger`} />
							</div>
							<div className='alert-text'>
								<div className='text-header'>
									<span className='body-2 medium black-700'>You are Offline!</span>
									<span className={`body-3 semibold dismiss-text danger`} onClick={() => setIsOpen(false)}>Dismiss</span>
								</div>
								<span className='body-3 light black-500'>{'Cannot connect to the internet. Please fix your connection and try again.'}</span>
							</div>
						</div>
					</div>
					:
					<></>
				}
			</>
		);
	} else if (varrient === 'dismiss') {
		return (
			<>
				{
					isOpen
						?
						<div className='alert-box-container'>
							<div className={`error-alert-box ${varrientColor || 'success'}`}>
								<div className='alert-icon'>
									{varrientColor === 'success' ?
										<SuccessIcon className={`${varrientColor}`} />
										:
										varrientColor === 'warning' ?
											<WarningIcon className={`${varrientColor}`} />
											:
											varrientColor === 'danger' ?
												<DangerIcon className={`${varrientColor}`} />
												:
												<SmileIcon className={`${varrientColor}`} />
									}
								</div>
								<div className='alert-text w-100'>
									<div className='text-header w-100'>
										<span className='body-2 medium dark-100'>{varrientTitle}</span>
										<span className={`body-3 semibold dismiss-text ${varrientColor}`} onClick={() => setIsOpen(false)}>Dismiss</span>
									</div>
									<span className='body-3 light dark-50'>{varrientDescription}</span>
								</div>
							</div>
						</div>
						:
						<></>
				}
			</>
		);
	} else {
		return (
			<>
				{
					isOpen
						?
						<div className='alert-box-container' style={props.top ? { top: `${props.top}px`, ...props.style } : props.style}>

							<div className={`alert-box ${color || 'success'}`}>
								<div>
									{color === 'success' ?
										<SuccessIcon className={`${color}`} />
										:
										color === 'warning' ?
											<WarningIcon className={`${color}`} />
											:
											color === 'danger' ?
												<DangerIcon className={`${color}`} />
												:
												<SmileIcon className={`${color}`} />
									}
								</div>
								<div className='d-flex flex-column align-items-start' style={{ marginLeft: '10px' }}>
									<span className={`subtitle-2 ${color}` } style={{ marginTop: '2px' }}>{title}</span>
									<span className='body-2 dark-50' style={{ marginTop: '8px' }}>{description}</span>
								</div>
							</div>
						</div>
						:
						<></>
				}
			</>
		);
	}
}