import React, { useMemo, useState, useEffect } from 'react';
import Editor from './Editor';
import { Modal, Box } from '@mui/material';
import Button from '../../../../components/sub-component/Button';
import Input from '../../../../components/sub-component/Input';
import parse from 'html-react-parser';
import { postEmailPreview } from '../../../../redux/thunks/Notification';
import { updateAssessSpec } from '../../../../redux/thunks/AssessSpec';
import { getEmailTemplate } from '../../../../redux/thunks/Notification';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import AlertBox from '../../../../components/common/AlertBox';
import ProcessingIndicator from '../../../../components/common/ProcessingIndicator';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/components/common/modal/x.svg';
import { calculateExpiryWithDhms, calculateExpiryWithDhmsUTC, isoDateFormateFromSeconds } from '../../../../utils/utilities';

export default function EmailCustomizationModal({ open, handleClose, curr_assessment_spec, isMobile, setMessage }) {

	const dispatch = useDispatch();
	const [updatedTemplate, setUpdatedTemplate] = useState('');
	const [updatedTemplateSubject, setUpdatedTemplateSuject] = useState('');
	const [customizedTemplate, setCustomizedTemplate] = useState('');
	const [alert, setAlert] = useState(null);
	const [isError, setIsError] = useState(false);
	const [saveButtonLoading, setSaveButtonLoading] = useState(false);
	const user = useSelector((state) => state.userProfileReducer);
	const deadlineDatetime = calculateExpiryWithDhmsUTC(curr_assessment_spec?.deadline);

	const formats = [
		'font',
		'size',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'clean',
		'align'
	];

	const modules = {
		toolbar: {
			container: '#toolbar',

		},
		clipboard: {
			matchVisual: false,
		}
	};

	const handleEmailCustomization = async () => {
		try {
			const { payload } = await dispatch(getEmailTemplate({ event_name: 'candidate_created' }));
			// if (curr_assessment_spec?.other_settings?.email_template_data[0]?.sub_templates?._template_data?.template_text === "default_template") {
			// console.log('==>',curr_assessment_spec?.other_settings?.email_template_data[0]?.sub_templates?._template_data?.template_text || '');
			setCustomizedTemplate(curr_assessment_spec?.other_settings?.email_template_data[0]?.sub_templates?._template_data?.template_text || '');
			updatePreview(curr_assessment_spec?.other_settings?.email_template_data[0]?.sub_templates?._template_data?.template_text || '');
			// } else {
			//     setCustomizedTemplate(curr_assessment_spec?.other_settings?.email_template_data[0]?.sub_templates?._template_data?.template_text || ""
			//     )
			//     if (curr_assessment_spec?.other_settings?.email_template_data[0]?.sub_templates?._template_data?.template_text) {
			//         updatePreview(curr_assessment_spec?.other_settings?.email_template_data[0]?.sub_templates?._template_data?.template_text)
			//     } else {
			//         setUpdatedTemplate(payload[0]?.base_template)
			//     }
			//     setUpdatedTemplateSuject(payload[0]?.subject)
			// }
		} catch (error) {
			console.log('Failed to fetch template', error);
		}
	};

	useEffect(() => {
		if(curr_assessment_spec?.title && user?.data?.company_name) 
			handleEmailCustomization();
	}, [curr_assessment_spec?.title, user?.data?.company_name]);

	useEffect(() => {
		setUpdatedTemplate('');
		handleEmailCustomization();
		if (open) {
			const currentTemplate = curr_assessment_spec?.other_settings?.email_template_data[0]?.sub_templates?._template_data?.template_text || customizedTemplate;
			setCustomizedTemplate(currentTemplate);

		}
	}, [open]);

	const updatePreview = debounce(async (customizedTemplate) => {
		try {
			const { payload, error } = await dispatch(postEmailPreview(
				{
					event_name: 'candidate_created',
					sub_templates: {
						_template_data: {
							template_text: customizedTemplate === '<p><br></p>' ? '' : customizedTemplate,
							template_values: {
								company_name: user?.data?.company_name,
								assessment_title: curr_assessment_spec?.title,
								deadline: (curr_assessment_spec?.deadline / 86400),
								deadline_datetime: {
									'formatted': (deadlineDatetime),
									'iso': (isoDateFormateFromSeconds(calculateExpiryWithDhms(curr_assessment_spec?.deadline)))
								}
							}
						}
					}
				}
			));
			if (!error) {
				setUpdatedTemplate(payload.email_preview);
				setUpdatedTemplateSuject(curr_assessment_spec?.other_settings?.email_template_data[0].subject || payload.subject);
				setIsError(false);
			} else {
				setIsError(true);
				setAlert(<AlertBox
					color='danger'
					description={'Something seems to be wrong in the template, make sure you have properly enclosed your curly braces'}
					title='Error'
					onCloseAlert={() => { setAlert(null); }}
				/>);
			}
		} catch (error) {
			console.log('Failed to update preview', error);
		}
	}, 2000);

	const handleSave = async () => {
		try {
			setSaveButtonLoading(true);
			const data = {
				other_settings: {
					email_template_data: [
						{
							event_name: 'candidate_created',
							subject: updatedTemplateSubject,
							sub_templates: {
								_template_data: {
									template_text: customizedTemplate === '<p><br></p>' ? '' : customizedTemplate || '',
									template_values: {
										company_name: user?.data?.company_name,
										assessment_title: curr_assessment_spec?.title,
										deadline: (curr_assessment_spec?.deadline / 86400),
										deadline_datetime: {
											'formatted': (deadlineDatetime),
											'iso': (isoDateFormateFromSeconds(calculateExpiryWithDhms(curr_assessment_spec?.deadline)))
										}
									}
								}
							}
						},
						{
							event_name: 'candidate_invited',
							subject: updatedTemplateSubject,
							sub_templates: {
								_template_data: {
									template_text: customizedTemplate === '<p><br></p>' ? '' : customizedTemplate || '',
									template_values: {
										company_name: user?.data?.company_name,
										assessment_title: curr_assessment_spec?.title,
										deadline: (curr_assessment_spec?.deadline / 86400),
										deadline_datetime: {
											'formatted': (deadlineDatetime),
											'iso': (isoDateFormateFromSeconds(calculateExpiryWithDhms(curr_assessment_spec?.deadline)))
										}
									}
								}
							}
						}
					]
				}
			};
			await dispatch(updateAssessSpec({ id: curr_assessment_spec.id, data }));
			setMessage(<AlertBox
				color='success'
				description={'Template Saved Successfuly'}
				title='Success'
				onCloseAlert={() => { setAlert(null); }}
			/>);
			handleClose();
		} catch (error) {
			console.log('Failed to save template', error);
		} finally {
			setSaveButtonLoading(false);
		}
	};

	const editorMemo = useMemo(() => {
		if (open) {
			return (
				<Editor 
					modules={modules} 
					formats={formats} 
					updatePreview={updatePreview} 
					deadlineDatetime={deadlineDatetime} 
					deadlineDatetimeIso={isoDateFormateFromSeconds(calculateExpiryWithDhms(curr_assessment_spec?.deadline))}
					customizedTemplate={customizedTemplate} 
					setCustomizedTemplate={setCustomizedTemplate} 
				/>
			);
		}
	}, [open]);

	return (
		<Modal
			open={open}
			//   onClose={handleClose}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
			className='custom-modal'
		>
			<Box>
				<div className='email-customization-modal'>
					{updatedTemplate ? 
						<div>
							{
								alert
							}
							<div className='email-customization-modal-header'>
								<span className='headline-medium semibold'> Email Customization </span>
								<span className='pointer' onClick={handleClose}> <CrossIcon /> </span>
							</div>
							<div className='d-flex justify-content-end' style={{ minWidth: isMobile && 'calc(410px - 24px)', gap: '20px' }}>
								<Button
									btn='ragular-btn'
									varrient='primary'
									title={'Save'}
									style={{ width: '120px', marginBottom: '32px' }}
									onClick={handleSave}
									disabled={isError}
									processing={saveButtonLoading}
									rounded
								/>
							</div>
							<div className='email-customization-modal-container'>
								<div className='email-customization-modal-container-left'>
									<div style={{ marginBottom: '20px' }} className='d-flex flex-column'>
										<label style={{ marginBottom: '10px' }} className='headline-small semibold'>Email Subject </label>
										<Input
											varrient='primary'
											className='input body-3 primary-input'
											value={updatedTemplateSubject}
											onChange={(e) => { setUpdatedTemplateSuject(e.target.value); }}
										/>
									</div>
									<span className='headline-small semibold'>Customized Content</span>
									<div style={{ marginTop: '10px', height: '91%' }}>{editorMemo}</div>
								</div>
								<div className='email-customization-modal-container-right'>
									<span className='headline-small semibold'>Preview</span>
									<div className='template-container'>
										<div style={{ height: '50px', borderBottom: '1px solid #DEDAF2', width: '100%' }} className='body-2 d-flex align-items-center'>
											<span style={{marginLeft: '10px'}}>Subject: {updatedTemplateSubject} </span>
										</div>
										{parse((updatedTemplate?.replace('{{{_template_data}}}', 'Customized text Goes here..')))}
									</div>
								</div>
							</div>
						</div> 
						: 
						<div className='d-flex' style={{ width: '100%', height: '100%' }}>
							<ProcessingIndicator />
						</div>
					}
				</div>
			</Box>
		</Modal>
	);
}