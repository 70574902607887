import React, { useEffect, useState } from 'react';
import SectionContainer from '../SectionContainer';
import QuestionContainer from './QuestionContainer';
import Checkbox from '../../../../../sub-component/Checkbox';

import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/Views/dashboard/assessments/plus3.svg';
import { useDispatch, useSelector } from 'react-redux';
import { listQuesPrompt } from '../../../../../../redux/thunks/QuesPrompt';

export default function TextResponses(props) {

	const dispatch = useDispatch();

	const [questionnaire, setQuestionnaire] = useState(props?.sectionDetails?.test);

	const textTestLimit = 10;
	const videoTestLimit = 5;

	const quesPrompts = useSelector((state) => state.quesPromptReducer);


	const onConfirm = () => {
		props.onConfirm({ ...props.sectionDetails, test: questionnaire });
	};

	const onCancel = () => {
		props.onCancel({ ...props.sectionDetails, test: questionnaire });
	};

	const onCheckSectionDataConfermation = () => {
		props.onCheckSectionDataConfermation({ ...props.sectionDetails, test: questionnaire });
	};

	useEffect(() => {
		if (props.error) {
			props.setCheckValidation(true);
		}
	}, [questionnaire]);

	useEffect(() => {

		if (props?.resetAssessmentCreationSection) {
			setQuestionnaire(props?.sectionDetails?.test);
			props?.getToKnowIFStateUpdated(props.sectionDetails?.section?.type === 'text_test' ? 'text_test_updated' : 'video_test_updated', true);
		}

	}, [props?.resetAssessmentCreationSection]);




	useEffect(() => {

		if (props.sectionDetails?.section?.type === 'text_test' && !quesPrompts.data?.text_ques_prompts) {
			dispatch(listQuesPrompt({ type: 'text_response' }));
		} else if (props.sectionDetails?.section?.type === 'video_test' && !quesPrompts.data?.video_ques_prompts) {
			dispatch(listQuesPrompt({ type: 'video_response' }));
		}

	}, []);

	useEffect(() => {
		if (questionnaire?.questions?.length === 0) {
			if (props.sectionDetails?.section?.type === 'text_test') {
				setQuestionnaire({ ...questionnaire, questions: [{ question_type: 'add_question', question: '', duration: null }] });
				props.getUpdatedSectionLocalData({ ...props.sectionDetails, test: { ...questionnaire, questions: [{ question_type: 'add_question', question: '', duration: null }] } });
			}
			else {
				setQuestionnaire({ ...questionnaire, questions: [{ question_type: 'add_question', question: '', duration: null, recording_type: null }] });
				props.getUpdatedSectionLocalData({ ...props.sectionDetails, test: { ...questionnaire, questions: [{ question_type: 'add_question', question: '', duration: null, recording_type: null }] } });
			}

		}
	}, [questionnaire?.questions?.length]);


	return (
		<SectionContainer
			title={props?.sectionDetails?.section?.type === 'text_test' ? 'Text Responses' : 'Video Responses '}
			description={props?.sectionDetails?.section?.type === 'text_test' ? 'Your candidate will be able to enter a text answer.' : 'Your candidate will record a video against each question/statement.'}
			enableDuration={true}
			onRemoveSection={() => { props.onRemoveSection(); }}
			onConfirm={onConfirm}
			onCancel={onCancel}
			duration={questionnaire?.questions?.reduce((preValue, data) => preValue + Math.floor((data?.duration || 0) / 60), 0)}
			error={props.error ? true : false}
		>

			<div className='w-100 d-flex flex-column align-items-start' style={{gap: '16px', marginTop: '10px'}}>
				{
					props?.sectionDetails?.section?.type === 'text_test' &&
					<div>
						<Checkbox
							primary checked={questionnaire.allow_file_upload}
							onChange={() => {
								setQuestionnaire({ ...questionnaire, allow_file_upload: !questionnaire.allow_file_upload });
								props.getUpdatedSectionLocalData({ ...props.sectionDetails, test: { ...questionnaire, allow_file_upload: !questionnaire.allow_file_upload } });
							}} title="Allow candidate to upload file"
							titleStyle={{ fontSize: '16px', color: '#56585A', fontWeight: '300' }}
						/>
					</div>
				}

				{/* <span className='body-3' style={{ marginTop: '24px', fontWeight: '500', color: '#2B3340' }}>Add introductory text</span>
				<Input varrient={'primary'} placeholder="Text here" value={questionnaire.intro_text} name="question"
					style={{ marginTop: '12px' }}
					onChange={(e) => { setQuestionnaire({ ...questionnaire, intro_text: e.target.value }); }} /> */}

				{/* <span className='body-3' style={{ marginTop: '24px', fontWeight: '500', color: '#2B3340' }}>Questions</span> */}

				{
					quesPrompts.loading ? (
						<div className='processing-indicator-wrapper medium-indicator'>
							<div className="processing-indicator primary" style={{ width: '100%', height: '100%' }} />
						</div>

					) : (
						questionnaire.questions?.map((_, _index) => {
							return (
								<div key={_index} className='w-100'>
									<QuestionContainer
										onCheckSectionDataConfermation={onCheckSectionDataConfermation}
										type={props?.sectionDetails?.section?.type}
										index={_index}
										limit={props?.sectionDetails?.section?.type === 'text_test' ? textTestLimit : videoTestLimit}
										error={(props?.error?.required?.questions
											&& props?.error?.required?.questions?.filter((q, _) => { return q.index === _index; })[0]) || null}
										questions={questionnaire.questions}
										getQuestions={(_questions) => { setQuestionnaire({ ...questionnaire, questions: _questions }); }}
										getUpdatedData={(_questions) => { props.getUpdatedSectionLocalData({ ...props.sectionDetails, test: { ...questionnaire, questions: _questions } }); }}
										loading={quesPrompts.loading}
										quesPrompts={
											props?.sectionDetails?.section?.type === 'text_test' ? (
												quesPrompts.data?.text_ques_prompts
											) : (
												quesPrompts.data?.video_ques_prompts
											)}
									/>
								</div>
							);
						})
					)
				}



				{(props?.sectionDetails?.section?.type === 'text_test' ? questionnaire?.questions?.length < textTestLimit : questionnaire?.questions?.length < videoTestLimit) ?
					<div className='add-question'
						onClick={() => {
							onCheckSectionDataConfermation();
							const _questions = questionnaire.questions;
							if (props.sectionDetails?.section?.type === 'text_test') {
								_questions.push({ question_type: 'create_custom_question', question: '', duration: null });
							}
							else {
								_questions.push({ question_type: 'create_custom_question', question: '', duration: null, recording_type: null });
							}
							setQuestionnaire({ ...questionnaire, questions: _questions });
							props.getUpdatedSectionLocalData({ ...props.sectionDetails, test: { ...questionnaire, questions: _questions } });
						}}
					>
						<PlusIcon style={{stroke: '#121216'}}/>
						<span className='body-3 dark-100'>Add another question</span>
					</div>
					:
					<div className='error-box'>
						<span className='body-3 text-danger'>
							{props?.sectionDetails?.section?.type === 'text_test' ? 'You can only add 10 Text Response Question at a time!' : 'You can only add 5 Video Response Question at a time!'}
						</span>
					</div>
				}
			</div>
		</SectionContainer>
	);
}
