import React, { useEffect } from 'react';
import Button from '../../../../components/sub-component/Button';
import { ReactComponent as AvatarIcon } from '../../../../assets/icons/Views/candidate/assessments/avatar.svg';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/Views/candidate/assessments/CheckCircle.svg';
import { ReactComponent as InviteIcon } from '../../../../assets/icons/Views/candidate/assessments/streamline_mail-incoming.svg';
import { ReactComponent as LastActivity } from '../../../../assets/icons/Views/dashboard/test-detail/Vector1.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/Views/candidate/assessments/clock.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/Views/dashboard/test-detail/x.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/Views/candidate/assessments/alert-circle.svg';
import { ReactComponent as InfoIconRed } from '../../../../assets/icons/Views/dashboard/assessments/info-red.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/Views/dashboard/test-detail/Rounded Action.svg';
import { GetAvtarImage } from '../../../../components/dashboard/AvtarBadge';
import { secondsToDhmsFormat, calculateExpiry } from '../../../../utils/utilities';
import { inviteBlock, fullBlock } from '../../../../utils/Constants';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import CustomTooltip from '../../../../components/common/CustomTooltip';

export default function CandidateAssessmentRightSideMenuHeader(props) {
	const isOnWebScreen = useMediaQuery('(min-width:1340px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const { assessment, setSelectedAssessment, assessmentsLength, selectedAssessmentIndex } = props;

	const resource = useSelector((state) => state.resourceReducer);

	const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {

		const handleNext = () => {
			if (selectedAssessmentIndex < assessmentsLength - 1) {
				setSelectedAssessment(selectedAssessmentIndex + 1);
			}
		};

		const handlePrev = () => {
			if (selectedAssessmentIndex > 0) {
				setSelectedAssessment(selectedAssessmentIndex - 1);
			}
		};

		const handleKeyDown = (event) => {
			switch (event.key) {
				case 'ArrowLeft':
					handlePrev();
					break;
				case 'ArrowRight':
					handleNext();
					break;
				default:
					// Do nothing for other keys
					break;
			}
		};
		React.useEffect(() => {
			// Attach the event listener when the component mounts
			window.addEventListener('keydown', handleKeyDown);

			// Detach the event listener when the component unmounts
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}, [window]); // Empty dependency array means this effect runs once on mount and cleans up on unmount
		return (
			<div className='pagination' style={{ gap: isOnWebScreen ? '24px' : '12px' }}>
				<div className='h-100 d-flex flex-row align-items-center pointer' style={{ gap: isOnWebScreen ? '24px' : '12px' }} onClick={handlePrev}>
					<ArrowIcon className={`pointer ${props.selectedIndex === 0 && 'disable-arrow-icon'}`} style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
					<span className={`body-2 dark-50`}>Previous</span>
				</div>
				<div className='h-100 d-flex flex-row align-items-center pointer' style={{ gap: isOnWebScreen ? '24px' : '12px' }} onClick={handleNext}>
					<span className={`body-2 dark-50`}>Next</span>
					<ArrowIcon className={`pointer ${props.selectedIndex === props.assessmentsLength - 1 && 'disable-arrow-icon'}`} style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px', transform: 'rotate(180deg)' }} />
				</div>
			</div>
		);
	};

	useEffect(() => {

	}, [assessment]);

	return (
		<div className='right-sidebar-header-container'>
			<div className='right-sidebar-header-pagination'>
				<Pagination />
				<div className='pointer' onClick={props.handleClose}>
					<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
				</div>
			</div>
			<div className='w-100 d-flex flex-row' style={{ gap: '12px' }}>
				{/* {
					assessment?.avatar_id
						?
						<GetAvtarImage src={{ image_id: assessment?.avatar_id, name: assessment?.title || '' }} size="custom" width={72} height={72} />
						:
						<AvatarIcon style={{ height: '72px', width: '72px' }} />
				} */}
				<div className={`right-sidebar-header-top`}>
					<div className='d-flex flex-column align-items-start'>
						<span className='headline-3 dark-100'>{assessment?.title || '-'}</span>
						<span className='subtitle-2 dark-100'>{assessment?.tenant?.company_name || '-'}</span>
					</div>
					{(assessment?.status === 'IN_PROGRESS' || assessment?.status === 'PENDING') && (
						<div className='d-flex flex-row align-items-center' style={{ gap: '8px' }}>
							<Button
								disabled={assessment?.status === 'PENDING' && (inviteBlock.includes(assessment?.tenant?.status) || fullBlock.includes(assessment?.tenant?.status) || assessment?.tenant?.status === 'pre_onboard' || assessment?.spec_status === 'ARCHIVED')}
								btn='ragular-btn'
								varrient='primary'
								size={isOnMobileScreen && 'sm'}
								rounded
								title={assessment?.status === 'IN_PROGRESS' ? 'Resume' : 'Start'}
								onClick={() => { props.onStart(assessment); }}
							/>
							<Button
								disabled={assessment?.status === 'IN_PROGRESS' || (inviteBlock.includes(assessment?.tenant?.status) || fullBlock.includes(assessment?.tenant?.status) || assessment?.tenant?.status === 'pre_onboard') || (assessment?.status === 'PENDING' && assessment?.spec_status === 'ARCHIVED')}
								btn='ragular-btn'
								varrient='secondary-btn'
								size={isOnMobileScreen && 'sm'}
								rounded
								title={'Decline'}
								onClick={() => { props.onAssessmentDecline(assessment); }}
							/>
							{(assessment?.spec_status !== 'ARCHIVED') &&
								(((inviteBlock.includes(assessment?.tenant?.status) || fullBlock.includes(assessment?.tenant?.status)) && assessment?.status === 'PENDING') || assessment?.tenant?.status === 'pre_onboard') &&
								<CustomTooltip
									text={'Please contact the recruiter for this role to gain access to this assessment.'}
								>
									<InfoIconRed style={{ width: '28px', height: '28px' }} />
								</CustomTooltip>
							}
							{(assessment?.status !== 'PENDING' && assessment?.spec_status === 'ARCHIVED') &&
								<CustomTooltip customPosition={{ bottom: '12px', left: '8px' }}
									text={'This assessment is no longer available.'}
								>
									<InfoIconRed style={{ width: '28px', height: '28px' }} />
								</CustomTooltip>
							}
						</div>
					)}
				</div>
			</div>
			<div className='d-flex flex-column align-items-start' style={{ gap: '12px' }} >
				<div className=' d-flex flex-row align-items-center' style={{ gap: '12px' }} >
					<span className={`${isOnMobileScreen ? 'headline-5' : 'headline-5'} dark-100`}>Assessment Details</span>
					<div
						className='candidate-status-container body-2'
						style={{
							width: 'fit-content',
							border: assessment.status !== 'FINISHED' ? '1px solid black' : '',
							background: assessment.status === 'FINISHED' ? '#7C21FF' : '#FFFFFF',
							color: assessment.status === 'FINISHED' ? '#FFF' : '#121216',
						}}
					>
						{assessment.status === 'PENDING' ? 'Pending' : assessment.status === 'IN_PROGRESS' ? 'In Progress' : assessment.status === 'NOT_QUALIFIED' ? 'Not Qualified' : assessment.status === 'DECLINED' ? 'Declined' : assessment.status === 'EXPIRED' ? 'EXPIRED' : 'Completed'}
					</div>
				</div>
				<div className='d-flex flex-column align-items-start' style={{ gap: '6px' }}>
					<div className='d-flex flex-row align-items-center' style={{ gap: '4px' }}>
						<InviteIcon style={{ width: '18px', height: '18px' }} />
						<span className='body-2 dark-50'>
							Invited on: <span className='dark-100'>	{new Date(assessment?.created_at).toDateString()}</span>
						</span>
					</div>
					{assessment?.status !== 'DECLINED' &&
						<div className='d-flex flex-row align-items-center' style={{ gap: '4px' }}>
							<LastActivity style={{ width: '18px', height: '18px' }} fill= '#808191' />
							<span className='body-2 dark-50'>
								Last Activity: <span className='dark-100'>{assessment?.status === 'PENDING' ? 'Not started yet' : new Date(assessment?.updated_at).toDateString()}</span>
							</span>
						</div>
					}
					<div className='d-flex flex-row align-items-center' style={{ gap: '4px' }}>
						<ClockIcon style={{ width: '18px', height: '18px' }} />
						<span className='body-2 dark-50'>
							Approx. Duration: <span className='dark-100'>{secondsToDhmsFormat(assessment?.objective_test_duration || assessment?.duration || 0)}</span>
						</span>
					</div>
					{assessment?.status === 'EXPIRED' &&
						<div className='d-flex flex-row align-items-center' style={{ gap: '4px' }}>
							<InfoIcon style={{ width: '18px', height: '18px' }} />
							<span className='body-2 dark-50'>
								Expires on: <span className='dark-100'>{calculateExpiry(assessment.deadline, assessment.created_at)}</span>
							</span>
						</div>
					}
				</div>
				{assessment?.completed_on_creation &&
					<div className='already-completed-message'>
						<div>
							<CheckCircleIcon />
						</div>
						<span className='body-2 dark-100'>This test has been automatically marked as Completed because you have previously attempted the value and personality assessments</span>
					</div>
				}
			</div>
		</div>
	);
}
