import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
// import { ReactComponent as Arrow } from '../../assets/icons/Arrows/right.svg';

export default function Button(props) {

	const { btn, varrient, size, title, preIcon, postIcon, disabled, processing, active, draggable, onDragStart, onDragEnd, rounded, titleStyle, isGoogleAuth, preIconMargin, textClass } = props;

	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	if (btn === 'ragular-btn') {
		return (
			<button
				title={props.toolTip}
				id={props.id}
				onClick={props.onClick}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
				style={{ ...props.style }}
				disabled={disabled || processing}
				className={`${varrient === 'danger' ? 'danger-btn' : varrient === 'primary' ? (disabled || processing) ? 'primary-button-disabled' : active ? 'primary-button-active' : ' primary-button ' : varrient === 'primary-light' ? (disabled || processing) ? 'primary-light-button-disabled' : active ? 'primary-light-button-active' : ' primary-light-button ' : varrient === 'secondary-btn' ? (disabled || processing) ? 'secondary-btn-disabled' : active ? 'secondary-btn-v2-active' : ' secondary-btn-v2 ' : (disabled || processing) ? 'secondary-btn-disabled' : ' secondary-btn-v2 '}
								${size === 'lg' ? 'btn-lg' : size === 'sm' ? 'btn-sm' : 'btn-md'} d-flex align-items-center ${(preIcon || postIcon) ? '' : ' justify-content-center'} ${props.className}`}
			>
				{
					processing ? (
						<div className='w-100 d-flex align-items-center justify-content-center'>
							<span className='button-processing-indicator-wrapper'>
								<span className='processing-indicator grey' />
							</span>
						</div>
					)
						:

						(
							<>
								{
									preIcon &&
									<div style={{ marginRight: preIconMargin || '8px', position: 'relative' ,marginTop:'-4px'}}>
										{preIcon}
									</div>
								}

								{
									// (isOnMobileScreen && preIcon) || (isOnMobileScreen && postIcon)
									// 	?
									// 	null
									// 	:
									<span
										className={`${textClass ? textClass : size === 'lg'
											? 'button-large-text' : size === 'sm' ? 'body-2' : 'body-2'}`}
										style={titleStyle}
									>
										{title || 'Button'}
									</span>
								}

								{
									postIcon &&
									<div style={{ marginLeft: '8px' }}>
										{postIcon}
									</div>
								}
							</>
						)
				}
			</button>
		);
	}
	else if (btn === 'in-section-btn') {
		return (
			<button
				onDragEnd={onDragEnd}
				onDragStart={onDragStart}
				draggable={draggable}
				onClick={props.onClick}
				style={{ ...props.style }}
				disabled={disabled || processing}
				className={`in-section-btn ${props.className}`}
			>
				{
					preIcon &&
					<div className='d-flex align-items-center justify-content-center' style={{ marginRight: '9px' }}>
						{preIcon}
					</div>
				}

				<span>
					{title || 'Button'}
				</span>

				{
					postIcon &&
					<div className='d-flex align-items-center justify-content-end' style={{ marginLeft: '9px' }}>
						{postIcon}
					</div>
				}
			</button>
		);
	}
	else if (btn === 'in-section-btn-V2') {
		return (
			<button
				onDragEnd={onDragEnd}
				onDragStart={onDragStart}
				draggable={draggable}
				onClick={props.onClick}
				style={{ ...props.style }}
				disabled={disabled || processing}
				className={`in-section-btn-V2 ${props.className}`}
			>
				<div className='d-flex align-items-center justify-content-center'>
					{
						preIcon &&
					<div className='d-flex align-items-center justify-content-center' style={{ marginRight: '9px' }}>
						{preIcon}
					</div>
					}

					<span>
						{title || 'Button'}
					</span>
				</div>

				{
					postIcon &&
					<div className='d-flex align-items-center justify-content-end' style={{ marginLeft: '9px' }}>
						{postIcon}
					</div>
				}
			</button>
		);
	}
	else {
		return (
			<button
				type={props.type || 'button'}
				className='rectangle-button p2 button-medium-text'
				onClick={props.onClick}
				disabled={props.disabled || props.processing}
				style={{ ...props.style }}
			>
				<div className='d-flex justify-content-center align-content-center'>
					{props.processing ? (
						<span className='button-processing-indicator-wrapper'>
							<span className='processing-indicator' />
						</span>
					) : (
						<span className="title">{props.title}</span>
					)}
				</div>

			</button>
		);
	}

	// if (props.webButton) {
	// 	return (
	// 		<div
	// 			className={props.className || 'btn'}
	// 			onClick={props.onClick}
	// 			style={props.style}>
	// 			<span className=''>{props.label || 'Button'}</span>
	// 		</div>
	// 	);
	// } else if (props.rectangle-button) {
	// 	return (
	// 		<button
	// 			type='button'
	// 			className='rectangle-button p2 poppin-14-700'
	// 			onClick={props.onClick}
	// 			disabled={props.disabled}rectangle-button
	// 			style={{...props.style}}
	// 		>
	// 			<div className="title">{props.title}</div>
	// 			{/* {props.isBusy && <>
	// 				<div className="spinner-border text-light">
	// 				</div>
	// 			</>
	// 			} */}
	// 		</button>
	// 	);
	// } else if (props.button) {
	// 	return (
	// 		<div
	// 			id='abcd123'
	// 			className={`${props.className} button-small`}
	// 			onClick={props.onClick}
	// 			style={{ ...props.style }}>
	// 			{props.loading ? (
	// 				<div className='spinner-border text-light' role='status'>
	// 					<span className='sr-only'>Loading...</span>
	// 				</div>
	// 			) : (
	// 				<span className={props.textClass} style={{ color: 'white', ...props.textStyle }}>
	// 					{props.label || 'Button'}
	// 				</span>
	// 			)}
	// 		</div>
	// 	);
	// } else if (props.rightArrow) {
	// 	return (
	// 		<div
	// 			className={props.className || 'button-small'}
	// 			onClick={props.onClick}
	// 			style={props.style}>
	// 			<div className='d-flex align-items-center justify-content-center'>
	// 				<div>
	// 					{props.loading ? (
	// 						<div className='spinner-border text-light' role='status'>
	// 							<span className='sr-only'>Loading...</span>
	// 						</div>
	// 					) : (
	// 						<span className={props.textClass} style={{ color: 'white' }}>
	// 							{props.label || 'Button'}
	// 						</span>
	// 					)}
	// 				</div>
	// 				<div style={{ marginLeft: '5px', marginBottom: '3px' }}>
	// 					{props.loading ? null : <Arrow stroke='white' />}
	// 				</div>
	// 			</div>
	// 		</div>
	// 	);
	// } else if (props.whiteButton) {
	// 	return (
	// 		<div
	// 			className={props.className || 'white-button-sm'}
	// 			onClick={props.onClick}
	// 			style={{ ...props.style }}>
	// 			<span className={props.textClass}>{props.label || 'Button'}</span>
	// 		</div>
	// 	);
	// } else if (props.dashboardbtn) {
	// 	return (
	// 		<div>
	// 			<div
	// 				className={props.className || 'dashboard-button'}
	// 				onClick={props.onClick}
	// 				style={{ ...props.style }}>
	// 				<span className={props.textClass} style={props.textStyle}>
	// 					{props.label || 'Button'}
	// 				</span>
	// 			</div>
	// 		</div>
	// 	);
	// }
	// else if (props.download) {
	// 	return (

	// 		<div style={{ width: 'fit-content', padding: '10px 20px', border: '1px solid #00B4D8', borderRadius: '50px', cursor: 'pointer', ...props.style }}
	// 			onClick={props.onClick}
	// 			className="d-flex justify-content-center align-items-center" >
	// 			{
	// 				props.leftIcon &&
	// 				props.leftIcon
	// 			}
	// 			<div className="d-flex justify-content-center align-items-center">
	// 				<span className={`${props.fontStyle || 'b2'}` + ` ${props.className}`}
	// 					style={{ color: '#00B4D8' }}
	// 				>{props.title || 'Next'}</span>
	// 			</div>
	// 		</div>
	// 	);
	// }
}

Button.propTypes = {
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onClick: PropTypes.func,
	type: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	processing: PropTypes.bool,
	title: PropTypes.string,
	style: PropTypes.object,
};
