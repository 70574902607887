import React, { useEffect, useState } from 'react';
import { oembed } from '@loomhq/loom-embed';

export default function LoomEmbedVideo(props) {

	const { loomUrl, backUpVideoSrc } = props;

	const [video, setVideo] = useState(null);
	const [error, setError] = useState('');

	const displayVideo = async (url) => {
		try {
			const response = await oembed(`${url}`, { width: 389, height: 206 });
			setVideo(response.html);
			setError('');
		} catch (error) {
			console.log(error, 'html 123');
			setError('Failed to load resource!');
		}

	};
	useEffect(() => {
		displayVideo(loomUrl);
	}, [loomUrl]);

	useEffect(() => {
		// console.log(backUpVideoSrc, 'backUpVideoSrc');
	}, [backUpVideoSrc]);


	return (
		<>
			{
				error
					?
					backUpVideoSrc?.url
						?
						<video
							className='video-response-result'
							src={backUpVideoSrc?.url}
							controls={true}
						/>
						:
						<span className='body-2 danger-text' style={{ textAlign: 'left' }}>Failed to load resource!</span >
					:
					<div className='loom-video-recorder' dangerouslySetInnerHTML={{ __html: video }}></div>
			}



		</>
	);
}
