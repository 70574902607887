import React, { useMemo } from 'react';
import Input from './Input';
import ReactQuillBubble from './ReactQuillBubble';

export default function Radio(props) {
	const {
		varrient,
		placeholder,
		optionValue,
		value,
		name,
		onChange,
		className,
		style,
		radioGroupName,
		radioValue,
		onChangeRadioOption,
		checked,
		defaultValue,
		readonly,
		fontStyle,
		isHtmlRender,
		disabled,
		onBlur,
		isError
	} = props;

	if (varrient === 'radio-with-input') {
		const ChoiceTextMemo = useMemo(() => {
			return <ReactQuillBubble value={value} className='ql-editor-skill-candidate-side option-read-only' />;
		}, []);

		return (
			<div
				className={`${checked && ' selected-radio '
				} radio-with-input-container pointer ${readonly ? 'readonly-radio-with-input-container' : ''} ${className}`}
				style={{ ...style }}
				onClick={!disabled ? onChangeRadioOption : () => { }}
			>
				<label className='custom-radio'>
					<input 
						checked={checked}
						// className='form-check-input'
						type='radio'
						name={radioGroupName}
						id='radioNoLabel1'
						value={radioValue}
						aria-label='...'
						readOnly={true}
						disabled={disabled}
					/>
					<span className='radio-mark'></span>
					{readonly ? (
						<div
							className='read-only-div h-100 d-flex align-items-center'
							style={{ textAlign: 'left' }}
						>
							{optionValue && (
								<div style={{ marginRight: '4px' }}>
									<span className='body-2 dark-100 disable-text-selection'>
										{optionValue}: 
									</span>
								</div>
							)}
							<div style={{ marginTop: '2px' }}>
								<span className='body-2 dark-100 disable-text-selection'>
									{ChoiceTextMemo}
								</span>
							</div>
						</div>
					) : (
						<Input
							className={'radio-label-input'}
							varrient={'primary'}
							placeholder={placeholder}
							value={value}
							name={name}
							onChange={onChange}
							defaultValue={defaultValue}
							onBlur={onBlur}
						/>
					)}
				</label>
			</div>
		);
	} 
	else if (varrient === 'radio-with-input-2') {
		const ChoiceTextMemo = useMemo(() => {
			return <ReactQuillBubble value={value} className='ql-editor-skill-candidate-side option-read-only' />;
		}, []);

		return (
			<div
				className={`${checked && ' selected-radio '
				} radio-with-input-container pointer d-flex align-items center ${readonly ? 'readonly-radio-with-input-container' : ''} ${className}`}
				style={{ borderRadius:'24px',background:'#F6F5F8' ,border:'none'}}
				onClick={!disabled ? onChangeRadioOption : () => { }}
			>
				<input
					checked={checked}
					className='form-check-input'
					type='radio'
					name={radioGroupName}
					id='radioNoLabel1'
					value={radioValue}
					aria-label='...'
					readOnly={true}
					disabled={disabled}
				/>

				{readonly ? (
					<div
						className='read-only-div d-flex align-items-center'
						style={{ textAlign: 'left' }}
					>
						{optionValue && (
							<div style={{ marginRight: '18px' }}>
								<span className='body-2 dark-100 disable-text-selection'>
									{optionValue}
								</span>
							</div>
						)}
						{ChoiceTextMemo}
					</div>
				) : (
					<Input
						className={'radio-label-input'}
						varrient={'primary'}
						placeholder={placeholder}
						value={value}
						name={name}
						onChange={onChange}
						defaultValue={defaultValue}
						onBlur={onBlur}
					/>
				)}
			</div>
		);
	} 
	
	else if (varrient === 'radio-with-custom-component') {
		return (
			<div
				className={`d-flex align-items-center ${className} ${isError && ' error-radio '}`}
				style={{ ...style }}
			>
				<label className='custom-radio'>
					<input 
						checked={checked}
						// className='form-check-input'
						type='radio'
						name={radioGroupName}
						id='radioNoLabel1'
						value={radioValue}
						onChange={!disabled ? onChangeRadioOption : () => { }}
						aria-label='...'
						readOnly={readonly}
					/>
					<span className='radio-mark'></span>
					{props.children}
				</label>
			</div>
		);
	}
}
