import React from 'react';
import ProgressBar from '../../../components/common/ProgressBar';

export default function TestpackProgressInfoHeader(props) {
	const { title, totalQuestions, currentQuestionNo,  } = props;

	return (
		<div className='test-progress-info-header'>
			<div
				className='d-flex align-items-center '
				style={{ justifyContent: 'space-evenly', marginRight: '12px' }}
			>
				<div className='d-flex align-items-center'>
					<div
						className='black-400'
						style={{
							maxWidth: '220px',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						<span className='body-2 black-400'>{title} Preview Question </span>
					</div>
					<span className='body-2 black-400' style={{ marginLeft: '2px', marginRight: '6px' }}>:</span>
				</div>
				<div className='d-flex ' style={{ width: 'max-content' }}>
					<span className=' body-2 black-700'>
						{(currentQuestionNo + 1) + ' / ' + (totalQuestions !== undefined ? totalQuestions : '0')}
					</span>
				</div>
			</div>

			<div style={{ width: '100%' }}>
				<ProgressBar
					size='lg'
					value={`${
						((currentQuestionNo + 1) / totalQuestions) * 100
					}`}
				/>
			</div>
		</div>
	);
}
