import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance, secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const inviteUsers = createAsyncThunk(
	'user/inviteUsers',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/users/invite_multiple_candidates/',
				method: 'POST',
				data: data,
			});
			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const listUsers = createAsyncThunk(
	'user/listUsers',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const { query } = data;

			const request = await secure_instance.request({
				url: `/v1/users/${query ? query : ''}`,
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const listSystemRoles = createAsyncThunk(
	'user/listSystemRoles',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/roles/',
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const createUser = createAsyncThunk(
	'user/createUser',
	async ({ data, query }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/users/${query ? query : ''}`,
				method: 'POST',
				data: data
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const updateUser = createAsyncThunk(
	'user/updateUser',
	async ({ data, id, users }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/users/${id}`,
				method: 'PATCH',
				data: { role_id: data?.role_id }
			});
			return { ...request.data, email: data?.email, users: [...users] };
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const deleteUser = createAsyncThunk(
	'user/deleteUser',
	async ({ id, email }, { rejectWithValue, dispatch }) => {
		try {
			await secure_instance.request({
				url: `/v1/users/${id}`,
				method: 'DELETE',
			});

			return email;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const userPassword = createAsyncThunk(
	'user/userPassword',
	async ({ password, access_token }, { rejectWithValue, dispatch }) => {
		console.log(password, access_token, 'data321');
		try {
			const request = await instance.request({
				url: '/v1/users/password/',
				method: 'PATCH',
				headers: {
					Authorization: `Bearer ${access_token}`
				},
				data: {
					password: password,
				},
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);
export const getUser = createAsyncThunk(
	'user/getUser',
	async ({ access_token }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/users/me',
				method: 'GET',
				headers: {
					Authorization: `Bearer ${access_token}`
				},
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

