import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { CommonContext } from '../../../utils/contexts/CommonContext';
import { ReactComponent as CrossIcon } from '../../../assets/icons/Views/dashboard/test-detail/x.svg';
import { ReactComponent as CSTIcon } from '../../../assets/icons/components/code-resposnse-result/cst.svg';
import { ReactComponent as SmileIcon } from '../../../assets/icons/components/code-resposnse-result/smile.svg';
import { ReactComponent as CodingIcon } from '../../../assets/icons/components/code-resposnse-result/Frame 2608688.svg';

import PublicCodingTestDetails from './PublicCodingTestDetails';
import CaseStudyQuestionDetails from '../../dashboard/test-details/CaseStudyQuestionDetails';
import PersonalityInsightDetails from '../../dashboard/test-details/PersonalityInsightDetails';
import PersonalityResponseDetails from '../../dashboard/test-details/PersonalityResponseDetails';
import AntiCheatMonitor from '../../dashboard/test-details/test-details-right-view-extended-screens/AntiCheatMonitor';

export default function PublicTestDetailedView(props) {
	const { assessment, isForPublic } = props;
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const { commonValues, setCommonValues } = useContext(CommonContext);
	const publicResult = useSelector((state) => state.publicResultReducer);

	return (
		<div className={`test-detailed-container hide-scrollbar ${commonValues.detailedTestView.isExpanded ? (isForPublic ? 'public-test-detailed-view-expanded' : 'test-detailed-view-expanded') : 'minimized-test-detailed-view'}`}>
			{commonValues.detailedTestView.testType === 'personality_insight' ?
				<>
					<div
						className='w-100 d-flex flex-column'
						style={{ gap: '24px' }}
					>
						<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{ gap: '24px' }}>
							<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
								<SmileIcon />
								<span className='headline-3 dark-100 text-start'>Personality Insights</span>
							</div>
							<div className='d-flex justify-content-end pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
								<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
							</div>
						</div>
						<PersonalityInsightDetails assessment={assessment} />
					</div>
				</>
				:
				commonValues.detailedTestView.testType === 'personality_response' ?
					<>
						<div
							className='w-100 d-flex flex-column'
							style={{ gap: '24px' }}
						>
							<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{ gap: '24px' }}>
								<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
									<SmileIcon />
									<span className='headline-3 dark-100 text-start'>Personality Response</span>
								</div>
								<div className='d-flex justify-content-end pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
									<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
								</div>
							</div>
							<PersonalityResponseDetails assessment={assessment} isForPublic={isForPublic} />
						</div>
					</>
					:
					commonValues.detailedTestView.testType === 'case_study_test' ?
						<>
							<div
								className='w-100 d-flex flex-column'
								style={{ gap: '24px' }}
							>
								<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{ gap: '24px' }}>
									<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
										<CSTIcon />
										<span className='headline-3 dark-100 text-start'>Case Study Question Statement</span>
									</div>
									<div className='d-flex justify-content-end pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
										<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
									</div>
								</div>
								<CaseStudyQuestionDetails isForPublic={isForPublic} />
							</div>
						</>
						:
						commonValues.detailedTestView.testType === 'anti_cheat_monitor' ?
							<div
								className='w-100 d-flex flex-column'
								style={{ gap: '24px' }}
							>
								<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{ gap: '24px' }}>
									<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
										<span className='headline-3 dark-100 text-start'>Anti-Cheat Monitor</span>
									</div>
									<div className='d-flex justify-content-end pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
										<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
									</div>
								</div>
								<AntiCheatMonitor assessment={assessment} />
							</div>
							:
							<>
								{publicResult?.loading ?
									<div className='w-100 d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
										<div className='processing-indicator primary' style={{ width: '25px', height: '25px' }} />
									</div>
									:
									<>
										<div className='w-100 d-flex flex-column' style={{ gap: '24px' }}>
											<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{ gap: '24px' }}>
												<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
													<CodingIcon />
													<span className='headline-3 dark-100 text-start'>{commonValues.detailedTestView.testType === 'coding_test' ? 'Coding Test' : 'Test Name'}</span>
												</div>
												<div className='d-flex justify-content-end pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
													<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
												</div>
											</div>
											<PublicCodingTestDetails assessment={assessment} codeReport={publicResult?.public_code_snapshot} />
										</div>
									</>
								}
							</>
			}
		</div>
	);
}
