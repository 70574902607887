import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const listQuesPrompt = createAsyncThunk(
	'quesPrompt/listQuesPrompt',
	async ({ type }, { rejectWithValue, dispatch }) => {

		try {

			if (!type) {
				type = 'text_response';
			}

			const response = await secure_instance.request({
				url: `/v1/ques_prompts/?prompt_type=${type}`,
				method: 'GET',
			});

			const data = type === 'text_response' ? {
				text_ques_prompts: response.data,
			} : {
				video_ques_prompts: response.data,
			};

			return data;

		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}

	}
);