import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/components/common/table/search.svg';
import { RequestStatus, TestType } from '../../utils/Constants';
import Highlighter from 'react-highlight-words';
import CustomModal from '../common/Modal';

import { useSelector, useDispatch } from 'react-redux';
import { CreateIntercomTestPackTicket } from '../../redux/thunks/CustomerSupport';
import AlertBox from '../../components/common/AlertBox';

export default function Search(props) {
	const { applyFuzzyFilter, setStart, setFinish, setOpenFilterCard } = props;

	const dispatch = useDispatch();
	const [data, setData] = useState(props?.data);
	const [searchOnAttributes, setsearchOnAttributes] = useState(
		props.searchOnAttributes || ['title'],
	);
	const [searchedInput, setSearchedInput] = useState('');

	const [searchedData, setsearchedData] = useState([]);
	const [openSuggestionBox, setOpenSuggestionBox] = useState(false);
	const [alert, setAlert] = useState(null);
	const suggestionBoxRef = useRef(null);
	const [userQuery, setUserQuery] = useState({
		value: '',
		title: ''
	});
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
				setOpenSuggestionBox(false);
			}
		};
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	const { loading } = useSelector((state) => state.customerSupportReducer);

	const sendQuery = async () => {
		const data = { test_pack_type: userQuery.title === 'Not Applicable' ? null : userQuery.title === TestType.VALUE_TEST ? 'value_fitness_test_pack' : `${userQuery.title}_pack`, description: userQuery.value };
		// console.log(data, 'userQuery');

		if (!data.description) {
			setAlert(
				<AlertBox
					style={{ zIndex: '99999' }}
					color='danger'
					description={'Enter the description before submitting the query'}
					title='Error'
					isOpen={true}
					onCloseAlert={() => {
						setAlert(null);
					}}
				/>,
			);
			return;
		}

		const { meta, payload } = await dispatch(CreateIntercomTestPackTicket({ ...data }));
		if (meta?.requestStatus === RequestStatus.REJECTED) {
			setAlert(<AlertBox
				style={{ zIndex: '99999' }}
				color='danger'
				description={payload?.message || 'Sorry, Please try again later'}
				title='Error'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
		}
		else if (meta?.requestStatus === RequestStatus.FULFILLED) {
			setAlert(<AlertBox
				style={{ zIndex: '99999' }}
				color='success'
				description={'Request Sent!'}
				title='Success'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
		}
		setSelectedTestInfo({ test: null, openModal: false });
		setUserQuery({ title: props.testPackType, value: null });

	};

	const searchData = (input) => {
		if (input === '') {
			setsearchedData([]);
			setOpenSuggestionBox(false);
			props.onSearched(props?.data);
		} else {
			let tempArr = [];

			data.forEach((d, i) => {
				searchOnAttributes.map((attribute, _) => {
					if (d[attribute].toLowerCase().includes(input.toLowerCase())) {
						if (!tempArr.find(arr => arr.id === d.id)) {
							tempArr = [...tempArr, d];
						}
						return;
					}
				});
			});

			setsearchedData([...tempArr]);
		}
	};

	const fuzzySearchData = (input) => {
		if (input === '') {
			setsearchedData([]);
			setOpenSuggestionBox(false);
			props.onSearched(props?.data);
		} else {
			let tempArr = [];
			const splittedInput = input.split(' ');

			data.forEach((d, i) => {
				searchOnAttributes.map((attribute, _) => {
					if (attribute === 'title') {
						if (d[attribute].toLowerCase().includes(input.toLowerCase())) {
							if (!tempArr.find(arr => arr.id === d.id)) {
								tempArr = [...tempArr, d];
							}
							return;
						}
					}
					splittedInput.map(si => {
						if (si !== '' && attribute !== 'title') {
							if (d[attribute].toLowerCase().includes(si.toLowerCase())) {
								if (!tempArr.find(arr => arr.id === d.id)) {
									tempArr = [...tempArr, d];
								}
								return;
							}
						}

					});
				});
			});
			setsearchedData([...tempArr]);
			return [...tempArr];
		}
	};

	useEffect(() => {
		// console.log(props?.data, 'props?.data');
		setData(props?.data);
	}, [props?.data]);

	useEffect(() => {
		if (searchedInput === '') {
			props.onSearched(props?.data);
		}
		else {
			const temp = fuzzySearchData(searchedInput);
			props.onSearched(temp);

		}
	}, [data]);

	const [selectedTestInfo, setSelectedTestInfo] = useState({ test: null, openModal: false });




	const selectSuggestion = (value) => {

		setSearchedInput('');

		let tempArr = [];
		let tempData = null;
		if (value.trim() === '') {
			tempArr = data;
		}
		else {
			data.forEach((d, i) => {
				const isAlreadyPresent = tempArr.some(obj =>
					obj.title === d.title
				);
				if (d['title'].toLowerCase() === value.toLowerCase() && !isAlreadyPresent) {
					tempArr = [...tempArr, d];
					tempData = d;
					return;
				}
			});
		}
		// props.onSearched([...tempArr]);
		props?.onSelection(tempData);
		setOpenSuggestionBox(false);
		setsearchedData([]);
	};

	const getMatchedSelectedtion = (value) => {
		setSearchedInput(value);

		let tempArr = [];
		if (value.trim() === '') {
			tempArr = data;
		}
		else {
			data.forEach((d, i) => {
				searchOnAttributes.map((attribute, _) => {
					const isAlreadyPresent = tempArr.some(obj =>
						obj.title === d.title
					);
					if (d[attribute].toLowerCase().includes(value.toLowerCase()) && !isAlreadyPresent) {
						tempArr = [...tempArr, d];
						return;
					}
				});
			});
		}
		props.onSearched([...tempArr]);
		setOpenSuggestionBox(false);
		setsearchedData([]);
	};

	useMemo(() => {
		setUserQuery({ ...userQuery, title: props.testPackType });
	}, [props.testPackType]);

	return (
		<>
			{alert}
			<CustomModal
				open={selectedTestInfo.openModal}
				test={userQuery.title}
				handleCloseModal={() => { setSelectedTestInfo({ test: null, openModal: false }); setUserQuery({ title: props.testPackType, value: null }); }}
				varrient='contact-support-for-search'
				onConfirm={sendQuery}
				value={userQuery?.value}
				processing={loading}
				onChange={(e) => { setUserQuery({ ...userQuery, value: e.target.value }); }}
				getOption={(option) => { setUserQuery({ ...userQuery, title: option.value }); }}

			/>
			<div className='search-container' style={{ position: 'relative' }}>
				<input
					type='text'
					className='body-2 dark-100'
					placeholder={props?.placeholder || 'select'}
					value={searchedInput}
					onChange={(e) => {
						setSearchedInput(e.target.value);
						setStart(0);
						setFinish(9);
						if (e.target.value.length >= 0) {
							if (applyFuzzyFilter) {
								fuzzySearchData(e.target.value);
								setOpenSuggestionBox(true);
							} else {
								searchData(e.target.value);
								setOpenSuggestionBox(true);
							}
						}
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							getMatchedSelectedtion(e.target.value);
						}
					}}
				/>
				<SearchIcon
					style={{ position: 'absolute', right: '15.53px', width: '20px' }}
					className='pointer svg'
					onClick={() => {
						if (applyFuzzyFilter) {
							fuzzySearchData(searchedInput);
							setOpenSuggestionBox(true);
						} else {
							searchData(searchedInput);
							setOpenSuggestionBox(true);
						}
					}}
				/>


				{
					openSuggestionBox && searchedInput.trim().length > 0 &&
					<div className={`suggestion-box-container ${openSuggestionBox ? '' : 'hide-scrollbar'}`} ref={suggestionBoxRef} tabIndex={0}>
						<div className='searched-suggestion '>
							<span className='body-2'>Can’t find what you’re looking for? <span className='body-2 dark-100' style={{ textDecoration: 'underline' }} onClick={() => { setSelectedTestInfo({ openModal: true, test: null }); setOpenSuggestionBox(false); }}>Click here</span> to request your desired test.</span>
						</div>
						{searchedData.length > 0 && (
							<>
								{searchedData.map((data, _) => {
									return (
										<div
											className='d-flex flex-column align-items-start justify-content-center searched-suggestion'
											key={_}
											onClick={() => {
												selectSuggestion(data[searchOnAttributes[0]]);
											}}
										>
											<span className='body-2 dark-50'>{applyFuzzyFilter ? <Highlighter highlightClassName='highlight-searched-text' searchWords={searchedInput.split(' ')} autoEscape={true} textToHighlight={data[searchOnAttributes[0]]} /> : data[searchOnAttributes[0]]}</span>

											{data.test_pack_type === 'coding_test_pack' && (
												<span className='body-5 dark-50' style={{ opacity: '0.7' }}>
													{data['supported_coding_languages']
														? <span>Can be solved in {<Highlighter highlightClassName='highlight-searched-text' searchWords={searchedInput.split(' ')} autoEscape={true} textToHighlight={data[searchOnAttributes[1]]} />} languages - {<Highlighter highlightClassName='highlight-searched-text' searchWords={searchedInput.split(' ')} autoEscape={true} textToHighlight={data[searchOnAttributes[2]]} />}</span>
														: <Highlighter highlightClassName='highlight-searched-text' searchWords={searchedInput.split(' ')} autoEscape={true} textToHighlight={data[searchOnAttributes[2]]} />}
												</span>
											)}
										</div>
									);
								})}
							</>
						)}
					</div>

				}


			</div>
		</>
	);
}
