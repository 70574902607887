import React, { useEffect, useMemo, useState } from 'react';
import Search from '../../../../sub-component/Search';
import { TestType } from '../../../../../utils/Constants';
import cloneDeep from 'lodash/cloneDeep';

import { ReactComponent as PrimaryCrossIcon } from '../../../../../assets/icons/Views/dashboard/assessments/primary-x.svg';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/Views/dashboard/assessments/plus.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/Views/dashboard/assessments/info.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/icons/Views/dashboard/assessments/x.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../../assets/icons/Views/dashboard/assessments/chevron-left.svg';
import { ReactComponent as RightArrowIcon } from '../../../../../assets/icons/Views/dashboard/assessments/chevron-right.svg';
import { ReactComponent as FilterIcon1 } from '../../../../../assets/icons/Views/dashboard/assessments/NewFilter1.svg';
import { ReactComponent as FilterIcon2 } from '../../../../../assets/icons/Views/dashboard/assessments/NewFilter2.svg';
import Button from '../../../../sub-component/Button';
import Checkbox from '../../../../sub-component/Checkbox';

const SearchAndFilterTests = (props) => {

	const { testPackType, onSearchParams, filterOptions, getResultentData, disableFilter } = props;

	const [start, setStart] = useState(0);
	const [Finish, setFinish] = useState(9);
	const [slideIndex, setSlideIndex] = useState(1);

	const [dataList, setDataList] = useState(props.dataList);
	const [filteredDataList, setFilteredDataList] = useState([...props.dataList]);
	const [resultantFilteredAndSearchedDataList, setResultantFilteredAndSearchedDataList] = useState([]);
	const [searchedDataList, setSearchedDataList] = useState([...props.dataList]);

	const [openFilterCard, setOpenFilterCard] = useState(false);
	const [openFilterLabel, setOpenFilterLabel] = useState(false);

	const [CTFilters, setCTFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState([]);


	const handleApplyFilter = (data) => {
		let filteredData = data;
		let radioFilters = false;
		let checkBoxFilters = false;
		// console.log(data, 'dataList');

		CTFilters.filter((filter, _) => { return (filter.type === 'radio'); }).map((_filter) => {
			if (_filter?.selectedOption) {
				radioFilters = true;
				filteredData = filteredData.filter((test) => {
					if (_filter.filter_for === 'supported_coding_languages') {
						if (_filter.selectedOption.id === 'language-agnostic' && test.supported_coding_languages.length > 1) {
							return true;
						} else if (_filter.selectedOption.id === 'language-specific' && test.supported_coding_languages.length === 1) {
							return true;
						}
					}
				});
			}
		});


		CTFilters.filter((filter, _) => { return (filter.type === 'check-box'); }).map((_filter) => {
			if (_filter.selectedOption.length > 0) {
				checkBoxFilters = true;
				filteredData = filteredData.filter((test) => {
					if (_filter.filter_for === 'difficulty') {
						if (_filter.selectedOption.filter((eachOpt, _) => { return (eachOpt.id === test.difficulty.toLowerCase()); }).length > 0) {
							return true;
						}
					}
				});
			}
		});


		// console.log(filteredData, 'filteredData');
		setFilteredDataList([...filteredData]);
	};

	const handleResetFilters = () => {
		setCTFilters([]);
		setSelectedFilters([]);
		setFilteredDataList(dataList);
		setOpenFilterCard(false);
	};

	const handleFilterRemove = (filter, option) => {
		const RemoveFilter = CTFilters.map((_filter, _) => {
			const tempFilter = _filter;
			if (filter.type === _filter.type && filter.filter_for === _filter.filter_for) {
				if (filter.type === 'radio') {
					return { ...tempFilter, selectedOption: null };
				}
				else if (filter.type === 'check-box') {
					return { ...tempFilter, selectedOption: _filter.selectedOption.filter((item) => { return (item.id !== option.id); }) };
				}
			}
			return tempFilter;
		});
		// console.log(RemoveFilter);
		setCTFilters([...RemoveFilter]);
		setSelectedFilters([...cloneDeep(RemoveFilter)]);
		setOpenFilterLabel(true);
	};

	useMemo(() => {
		// console.log(props.dataList, 'props.dataList');
		if (JSON.stringify(dataList) !== JSON.stringify(props.dataList)) {

			setDataList(props.dataList);
			handleApplyFilter(props.dataList);
		}
		// setSearchedDataList([...dataList]);

	}, [props.dataList, openFilterLabel]);

	useEffect(() => {
		// console.log(props.dataList, selectedFilters, 'props.dataList Filters');
		handleApplyFilter(props.dataList);
		setStart(0);
		setFinish(9);
	}, [selectedFilters]);



	useMemo(() => {
		setResultantFilteredAndSearchedDataList([...searchedDataList]);
		setStart(start);
		setFinish(Finish);

	}, [searchedDataList]);

	const onSearchMemo = useMemo(() => {
		return (
			<Search
			
				initailData={filteredDataList}
				applyFuzzyFilter={props.applyFuzzyFilter}
				setOpenFilterCard={setOpenFilterCard}
				openFilterCard={openFilterCard}
				data={filteredDataList.map((data) => {
					if (testPackType === TestType.CODING_TEST) {
						return {
							...data,
							supported_coding_languages_text: data?.supported_coding_languages ?
								`${data?.supported_coding_languages
									?.map((lang, index) => {
										return ` ${index != 0 && index === data.supported_coding_languages.length - 1
											? 'and'
											: index < data.supported_coding_languages.length - 1 &&
												index !== 0
												? ','
												: ''
										} ${lang.language_name} `;
									})
									.join('') || ''}` : '',
						};
					}
					else {
						return { ...data };
					}
				})}

				searchOnAttributes={onSearchParams.searchOnAttributes}
				onSearched={(data) => {
					setSearchedDataList(data);
				}}
				onSelection={(data) => {
					props?.onSelection(data);
				}}
				setStart={setStart}
				setFinish={setFinish}
				testPackType={testPackType}
				placeholder={onSearchParams.placeholder}
			/>
			
		);
	}, [filteredDataList, props.applyFuzzyFilter]);

	const FilterLabel = ({ filterName, onRemoveFilter }) => {
		return (
			<div className='coding-filter-label'>
				<span className='body-2 white'>{filterName === 'Entry-level' ? 'Beginner Level' : filterName === 'Intermediate' ? 'Intermediate Level' : filterName === 'Advanced' ? 'Professional Level' : filterName}</span>
				<PrimaryCrossIcon className='pointer' onClick={() => onRemoveFilter(filterName)} />
			</div>
		);
	};

	const addFilters = (_filter, _option) => {
		const temp_arr = CTFilters.length > 0 ? CTFilters : [];
		// console.log(CTFilters);
		if (temp_arr.length > 0) {
			CTFilters.map((item, _) => {
				if (item.filter_for === _filter.filter_for) {
					if (_filter.type === 'radio') {
						temp_arr[_] = { ..._filter, selectedOption: _option };
					}
					else if (_filter.type === 'check-box') {
						const isOptionSelected = item.selectedOption.filter((_i, _) => { return (_i.id === _option.id); });
						const tempOptionSelected = item.selectedOption;
						if (isOptionSelected.length > 0) {
							temp_arr[_] = { ..._filter, selectedOption: tempOptionSelected.filter((_i, _) => { return (_i.id !== _option.id); }) };
						}
						else {
							tempOptionSelected.push(_option);
							temp_arr[_] = { ..._filter, selectedOption: [...tempOptionSelected] };
						}
					}
				}
				else if (temp_arr.filter((_i, _) => { return (_i.filter_for === _filter.filter_for); }).length === 0) {
					if (_filter.type === 'radio') {
						temp_arr.push({ ..._filter, selectedOption: _option });
					}
					else if (_filter.type === 'check-box') {
						temp_arr.push({ ..._filter, selectedOption: [_option] });
					}
				}
			});
		}
		else {
			if (_filter.type === 'radio') {
				temp_arr.push({ ..._filter, selectedOption: _option });
			}
			else if (_filter.type === 'check-box') {
				temp_arr.push({ ..._filter, selectedOption: [_option] });
			}
		}
		// console.log(temp_arr, 'temp_arr');
		setCTFilters([...temp_arr]);
	};

	const nextSlide = () => {
		const row = resultantFilteredAndSearchedDataList.slice(start, Finish);
		if (row.length < 9) {
			return;
		}
		if (slideIndex !== row.length) {
			setStart(start + 9);
			setFinish(Finish + 9);
		}
		else if (slideIndex === row.length) {
			setStart(0);
			setFinish(9);
		}
	};


	const prevSlide = () => {
		setStart(start - 9);
		setFinish(Finish - 9);
	};

	useMemo(() => {
		getResultentData(searchedDataList.slice(start, Finish));
	}, [searchedDataList, start, Finish]);
	

	return (
		<div className='w-100' style={{ gap: '24px' }}>
			<div className='search-and-filter-test-container '>
				<div className='search-and-filter-test-inner-container'>
					<div style={{width:'100%'}}  onClick={() => setOpenFilterCard(false)}>
						{onSearchMemo}
					</div>
					{
						!disableFilter &&
						<>
							{
								openFilterCard ?
									<FilterIcon2 className='pointer' style={{ width: '44px', height: '44px' }} onClick={() => setOpenFilterCard(false)} />
									:
									<FilterIcon1 className='pointer' style={{ width: '44px', height: '44px' }} onClick={() => setOpenFilterCard(true)} />
							}
							{
								openFilterCard &&
								<div className='coding-test-filter-container d-flex flex-column align-items-start'>
									<div className='filter-header'>
										<span className='subtitle-2 dark-100'>Filters</span>
										<span className='body-2 dark-50 pointer' onClick={handleResetFilters}>Reset Filter</span>
									</div>
									<div className='w-100 d-flex flex-column align-items-start'>
										{
											filterOptions.map((_filter, key) => {
												if (_filter.type === 'radio') {
													return (
														<div className='radio-section' key={key} >
															{
																_filter.options.map((_option, key) => {
																	return (
																		<div key={key} className='select-radio pointer' onClick={() => { addFilters(_filter, _option); }}>
																			<input readOnly type="radio"
																				checked={CTFilters.filter((__filter) => { return ((__filter.type === 'radio' && __filter.filter_for === _filter.filter_for && __filter?.selectedOption?.id === _option.id)); }).length > 0}
																			/>
																			<span className='primary-checkbox-title ms-2 description'>{_option.text}</span>
																		</div>
																	);
																})
															}
														</div>
													);
												}
												else if (_filter.type === 'check-box') {
													return (
														<div className='checkbox-section' key={key}>
															{
																_filter.title &&
																<span className='body-2 dark-50' style={{marginLeft:'4px'}}>{_filter.title}</span>
															}
															{
																_filter.options.map((_option, key) => {
																	return (
																		<div key={key} className='select-checkbox w-100 pointer' onClick={() => { addFilters(_filter, _option); }}>
																			<Checkbox
																				readOnly
																				primary
																				title={_option.text}
																				value={_option.id}
																				id={_option.id}
																				checked={CTFilters.filter((__filter) => { return ((__filter.type === 'check-box' && __filter.filter_for === _filter.filter_for && __filter.selectedOption.filter((item) => { return (item?.id === _option?.id); }).length > 0)); }).length > 0}
																			/>
																		</div>
																	);
																})
															}
														</div>
													);
												}
											})
										}
									</div>
									<div
										className='w-100 d-flex justify-content-between'

									>
										<Button
											btn='ragular-btn'
											varrient='secondary-btn-v2'
											title={'Cancel'}
											style={{height: '32px' }}
											onClick={() => setOpenFilterCard(false)}
										/>
										<Button
											btn='ragular-btn'
											varrient='primary'
											title={'Apply'}
											style={{height: '32px' }}
											onClick={() => {
												handleApplyFilter(props.dataList);
												setSelectedFilters([...cloneDeep(CTFilters)]);
												setOpenFilterCard(false);
												setOpenFilterLabel(false);
											}}
										/>
									</div>
								</div>
							}
						</>
					}


				</div>
				<div className='pagination-test-container'>
					<span className='body-2 dark-100'>{`${resultantFilteredAndSearchedDataList.length === 0 ? start : start + 1}-${Finish > resultantFilteredAndSearchedDataList.length ? resultantFilteredAndSearchedDataList.length : Finish}`}</span>
					<span className='body-2 dark-100'>{`of ${resultantFilteredAndSearchedDataList.length}`}</span>
					<LeftArrowIcon className='pointer' style={{ width: '20px', height: '20px' }} onClick={() => { start < 1 ? {} : prevSlide(); }} />
					<RightArrowIcon className='pointer' style={{ width: '20px', height: '20px' }} onClick={Finish === resultantFilteredAndSearchedDataList.length ? () => { } : nextSlide} />
				</div>
			</div>
			<div className='w-100'>
				{props.children}
			</div>
			{
				selectedFilters.length > 0 &&
				<div className='w-100 d-flex flex-row flex-wrap' style={{ marginTop: '16px', gap: '16px' }}>
					{selectedFilters.map((filter, key) => {
						if (filter.type === 'radio' && filter?.selectedOption) {
							return (
								<FilterLabel
									key={key}
									filterName={filter.selectedOption.text}
									onRemoveFilter={() => { handleFilterRemove(filter, undefined); }}
								/>
							);
						}
						else if (filter.type === 'check-box' && filter.selectedOption.length > 0) {
							return (
								<>
									{
										filter.selectedOption.map((op, _) => {
											return (
												<FilterLabel
													key={_}
													filterName={op.text}
													onRemoveFilter={() => { handleFilterRemove(filter, op); }}
												/>
											);
										})
									}
								</>
							);
						}
					})}
				</div>
			}

		</div>
	);
};

export default SearchAndFilterTests;
