import React, { useMemo, useRef, useState } from 'react';
// import Radio from '../../../../components/sub-component/Radio';
import TestContentWrapper from './TestContentWrapper';
import { ReactComponent as FileUplaodedIcon } from '../../../../assets/icons/candidate-assessment/file-uploaded.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/candidate-assessment/upload.svg';
import { ReactComponent as AttachIcon } from '../../../../assets/icons/candidate-assessment/attach.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/candidate-assessment/trash.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/candidate-assessment/x.svg';
import ProgressBar from '../../../../components/common/ProgressBar';
import Button from '../../../../components/sub-component/Button';
import { useDispatch, useSelector } from 'react-redux';
import { createResource, deleteResource, resetResourceState } from '../../../../redux/thunks/Resource';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useFocusWithin } from '@react-aria/interactions';
import axios from 'axios';


export default function UploadFileCS(props) {

	const { question } = props;

	//processing uploaded
	const [selectedFile, setSelectedFile] = useState(null);

	const [error, setError] = useState();
	const [fileSubmitted, setFileSubmitted] = useState(false);
	// const [showSkipQuestionModal, setShowSkipQuestionModal] = useState(false);

	const dispatch = useDispatch();
	const resource = useSelector((state) => state.resourceReducer);
	// const abortControllerRef = useRef(new AbortController());
	const [abortController, setAbortController] = useState(new AbortController());
	const [uploadFileObject, setUploadFileObject] = useState(null);



	const handleFileUpload = async (event) => {

		if ((event.target.files[0].size / 1024) / 1024 > 5) {
			setError('The uploaded file exceeded the max size limit (i.e 5mb)');
			return;
		}
		setError('');
		// const previousUploadId = resource?.data?.id || null;
		setUploadFileObject(event.target.files[0]);
		if(resource.data?.id){
			dispatch(deleteResource({id: resource.data?.id}));
		}
		uploadFile(event.target.files[0]);


	};

	const uploadFile = async (file) => {
		const formData = new FormData();
		formData.append('file', file, file.name);
		const uploadFileReq = await dispatch(createResource({ data: formData, signal: abortController.signal }));
		if (uploadFileReq.meta.requestStatus === 'rejected') {
			if (!abortController?.signal?.aborted) {
				setError('Failed to upload file. Please check your internet connection and retry.');
				return;
			}
			setAbortController(new AbortController());
		}
		else {

			// if (previousUploadId && fileSubmitted) {
			// 	// dispatch(deleteResource({ id: previousUploadId }));
			// }

			if (!fileSubmitted) {
				setFileSubmitted(true);
			}
			setSelectedFile(file);
			setError('');
			setUploadFileObject(null);
		}
	};

	const handleSubmitAnswer = () => {

		const data = {
			assess_id: props.assessmentId,
			resource_id: resource.data?.id
		};

		if (!data.assess_id || !data.resource_id) {
			setError('File is required');
			return;
		}

		setError('');

		if (props.onQuestionSubmit) {
			setFileSubmitted(false);
			setSelectedFile(null);
			props.onQuestionSubmit(data);
		}


	};

	const handleCancel = () => {
		abortController.abort();
	};

	const handleCancelUpload = () => {
		dispatch(deleteResource({ id: resource.data.id }));
		setSelectedFile(null);
	};

	useEffect(() => {

		if (resource.data !== null) {
			dispatch(resetResourceState());
		}

		return () => {
			if (resource.data?.id && fileSubmitted) {
				dispatch(deleteResource({ id: resource.data.id }));
			}
		};

	}, [question]);

	useEffect(() => {
		dispatch(resetResourceState());
	}, []);


	const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: e => { },
		onBlurWithin: e => { setIsSkipModalOpen(false); }
	});

	return (
		<TestContentWrapper
			title={'Upload File'}
			duration={null}
			currentQuestion={props.currentQuestionNo}
			totalQuestions={props.totalQuestions || 0}
			// onSubmit={handleSubmitAnswer}
			is_mandatory={props.is_mandatory}
			answer={selectedFile}
			onSubmit={(action) => {
				if (action === 'skip') {
					const data = {
						assess_id: props?.assessmentId
					};
					if (props.onQuestionSubmit) props.onQuestionSubmit(data);
				}
				else {
					handleSubmitAnswer();
				}

			}}
			processing={props.processing}
		>
			<div className='assessment-test-container flex-column' style={{gap: '24px'}}>
				<div className='assessment-test-questions-container'>
					<span className='subtitle-2 dark-100 disable-text-selection text-start'>
						{question?.text}
					</span>
				</div>
				<>
					<div className='text-upload-doc-container'>
						<div className='d-flex flex-column align-items-center pointer' style={{ position: 'relative', width: '260px', height: '90px', gap: '12px' }}>
							<UploadIcon />
							<span className='subtitle-2 dark-100'>Click to upload or drag and drop</span>
							<span className='body-2 dark-50'>pdf, csv, txt, or doc (max size 5mb)</span>
							<input
								className='pointer'
								style={{ position: 'absolute', left: 0, right: 0, width: '100%', height: '100%', opacity: 0 }}
								type='file'
								disabled={resource.processing}
								accept='.pdf, .csv, .txt, .doc, .docx'
								onChange={ handleFileUpload}
							/>
						</div>
					</div>
					{resource?.upload_percentage > 0 ?
						<div className='uploaded-doc-container'>
							<AttachIcon />
							<div className='d-flex flex-column align-items-start' style={{width: 'calc(100% - 36px)', gap: '8px'}}>
								<div className='w-100 d-flex flex-row align-items-center justify-content-between'>
									<div className='w-100 d-flex flex-column align-items-start'>
										<span className='body-2 dark-100'>{selectedFile?.name}</span>
										<span className='body-2 dark-100'>{(selectedFile?.size / 1024).toFixed(1)} kb</span>
									</div>
									{resource?.upload_percentage < 100 ? 
										<CrossIcon cursor='pointer' onClick={handleCancelUpload} />
										:
										<DeleteIcon cursor='pointer' onClick={handleCancelUpload} />
									}
								</div>
								<ProgressBar 
									varrient={'progress-with-upload-doc'}
									value={resource?.upload_percentage}
								/>
							</div>
						</div>
						: 
						error &&
							<div className='uploaded-doc-container align-items-center'>
								<AttachIcon />
								<span className='body-2 danger-text'>{error}</span>
							</div>
					}
				</>
				<div className='w-100 d-flex justify-content-end align-items-end'>
					<div tabIndex={-1} {...focusWithinProps}>
						{isSkipModalOpen ?
							<Button
								processing={props.processing}
								btn='ragular-btn' 
								varrient={'primary'}
								title={'Skip Question?'}
								style={{ width: 'max-content', height: '40px' }}
								onClick={() => {
									const data = {
										assess_id: props?.assessmentId
									};
									if (props.onQuestionSubmit) props.onQuestionSubmit(data);
									setIsSkipModalOpen(false);
								}} 
							/>
							:
							<Button
								processing={props.processing}
								disabled={resource.processing || (props.is_mandatory && !(selectedFile))}
								btn='ragular-btn' 
								varrient={'primary'}
								title={resource.processing ? 'Uploading...' : (props.currentQuestionNo === props.totalQuestions || 0 ? 'Submit' : 'Next')}
								style={{ width: '120px', height: '40px' }}
								onClick={() => {
									console.log(selectedFile);
									if ((!selectedFile)) {
										setIsSkipModalOpen(true);
										setError('');
									}
									else if (!isSkipModalOpen) {
										handleSubmitAnswer();
										setIsSkipModalOpen(false);
									}
								}} 
							/>
						}
					</div>
				</div>
			</div>
		</TestContentWrapper>
	);
}
