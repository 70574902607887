import React from 'react';
import defaultImage from '../../../assets/images/onboarding/profile/avatar.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/components/dashboard/profile.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/components/dashboard/logout.svg';
import { ReactComponent as User } from '../../../assets/icons/Views/candidate/settings/profile-pic.svg';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/components/dashboard/arrow.svg';
import { Avatar } from '@mui/material';
import { removeAllCookies } from '../../../utils/utilities';
import { useHistory } from 'react-router-dom';
import AvtarBadge from '../AvtarBadge';
import { useSelector } from 'react-redux';
import { UserRole } from '../../../utils/Constants';
import posthog from 'posthog-js';
import { PathName } from '../../../utils/routes';

export default function ProfileCard(props) {

	const { name, image, email, setisMobileMenuOpen } = props;

	const MAX_WIDTH = 20;
	function truncateName(name) {
		if (name.length > MAX_WIDTH) {
			return name.substring(0, MAX_WIDTH - 3) + '...';
		}
		return name;
	}

	const auth = useSelector((state) => state.authReducer);

	console.log(auth,'auth');
	const history = useHistory();

	const handleLogout = () => {
		try {
			posthog.reset();
			posthog.unregister('email');
		} catch {
			//
		}
		removeAllCookies();
	};

	return (
		<>
			<div className='side-menu-avatar-toggle d-flex flex-column'>
				{ 
					auth.data.role.name === 'Owner' ? 	<div className='side-menu-avatar-toggle-logout d-flex flex-row' style={{borderBottom:'1px solid #E1E1E1',borderBottomLeftRadius:'0px',borderBottomRightRadius:'0px'}} onClick={(e) => {
						history.push('/dashboard/settings/profile-settings');
						e.stopPropagation();
						e.preventDefault();
					}}>
						<User className='side-menu-avatar-toggle-logout-icon' />
						<span className='body-3'>Profile Settings</span>
					</div> :	<div className='side-menu-avatar-toggle-logout d-flex flex-row' style={{borderBottom:'1px solid #E1E1E1',borderBottomLeftRadius:'0px',borderBottomRightRadius:'0px'}} onClick={(e) => {
						history.push('/dashboard/profile-settings');
						e.stopPropagation();
						e.preventDefault();
					}}>
						<User className='side-menu-avatar-toggle-logout-icon' />
						<span className='body-3'>Profile Settings</span>
					</div>
				}
			
				{/* <div className='side-menu-avatar-toggle-profile d-flex flex-row '
					onClick={() => {
						{
							auth.data?.role && auth.data.role.name === UserRole.CANDIDATE
							?
							history.push(`${PathName.MAIN}/profile-settings`)
							:
							history.push(`${PathName.SETTINGS}/profile-settings`);
						}
						setisMobileMenuOpen(false);
					}}>
					<ProfileIcon className='side-menu-avatar-toggle-profile-icon' />
					<span className='body-3' >Edit Profile</span>
					<ArrowIcon />
				</div> */}
				<div className='side-menu-avatar-toggle-logout d-flex flex-row ' onClick={handleLogout}>
					<LogoutIcon className='side-menu-avatar-toggle-logout-icon' />
					<span className='body-3'>Sign Out</span>
				</div>
			</div>
		</>
	);
}
