import React, { useEffect } from 'react';
import AssessmentTestInfoWrapper from '../../../components/candidate-assessment-flow/AssessmentTestInfoWrapper';
import ReactQuill from 'react-quill';
import Radio from '../../../components/sub-component/Radio';
import { calculateTestResultPrevScore } from '../../../utils/utilities';

export default function TestInstructionsAndInfoCard(props) {

	const { varrient, testName, testType, introText, activeSection, testResultPrev, setRetakeTest, retakeTest } = props;

	const varrients = {
		startQualificatioTest: {
			title: 'Qualification Test',
			disableSecondaryBtn: false,
			secondaryBtnTitle: 'Go back to dashboard',
			primaryBtnTitle: 'Start test',
			style: {},
			points: [
				'To take the assessment you must pass the qualification test.',
				'If you do not pass the qualification test then you will not be able to take the assessment.',
			],
			enablePointNo: false
		},
		assessmentInstructions: {
			title: 'Assessment Instructions',
			disableSecondaryBtn: false,
			secondaryBtnTitle: 'Not Now',
			primaryBtnTitle: 'Start Assessment',
			style: { width: '100%', maxWidth: '100%' },
			points: [
				'You will be asked multiple questions of various types as part of this assessment.',
				'You will be asked to attempt some tests under a set time limit, hence, keep a track.',
				'Every correct response to a given test will add up to your score.',
				'Your performance will be evaluated based on your given answers and responses.',
				'You can track your assessment progress after attempting each test.',
				'Some tests will ask you to record video responses, thus, test your camera and microphones.'
			],
			enablePointNo: true
		},
		startTest: {
			title: testName || 'Test Name',
			disableSecondaryBtn: true,
			disablePrimaryBtn: testResultPrev && retakeTest === null,
			secondaryBtnTitle: '',
			primaryBtnTitle: (testResultPrev  && !retakeTest) ? 'Next' : 'Start Test',
			style: {},
			points: testResultPrev ? [] : [`This is a test from the ${activeSection}. Kindly press “Start Test” to proceed further.`],
			enablePointNo: false
		},
		pauseTest: {
			title: testName || 'Test Name',
			disableSecondaryBtn: false,
			secondaryBtnTitle: 'Go to dashboard',
			primaryBtnTitle: 'Resume assessment',
			style: {},
			points: [
				'Your assessment and timer is paused right now, you can resume the assessment whenever you want or either you can go back to dashboard.'
			],
			enablePointNo: false
		},
		caseStudyIntroText: {
			title: 'Case Study',
			disableSecondaryBtn: true,
			secondaryBtnTitle: '',
			primaryBtnTitle: 'Yes I’m ready to start assessment',
			style: { width: '100%', maxWidth: '1280px' },
			points: [],
			enablePointNo: false
		}
	};


	return (
		<AssessmentTestInfoWrapper
			test={props?.test?.test}
			varrient={varrient}
			style={varrients[`${varrient}`].style}
			title={varrients[`${varrient}`].title}
			disableSecondaryBtn={varrients[`${varrient}`].disableSecondaryBtn}
			disablePrimaryBtn={varrients[`${varrient}`].disablePrimaryBtn}
			secondaryBtnTitle={varrients[`${varrient}`].secondaryBtnTitle}
			primaryBtnTitle={varrients[`${varrient}`].primaryBtnTitle}
			onSecondaryBtnClick={props.onSecondaryBtnClick}
			onPrimaryBtnClick={props.onPrimaryBtnClick}
			btnWidth={(varrient === 'caseStudyIntroText' || varrient === 'pauseTest' || varrient === 'startTest' || varrient === 'assessmentInstructions' || varrient === 'startQualificatioTest') ? 'max-content' : '120px'}
			processing={props.processing}
			loading={props.loading}
		>
			<div className='w-100 d-flex flex-column align-items-start'>
				{
					varrients[`${varrient}`].points.map((point, _i) => {
						return (
							<span key={_i} className='body-2 dark-100' style={{ textAlign: 'left', marginTop: _i === 0 ? '0px' : '8px' }}>{varrients[`${varrient}`].enablePointNo && `${_i + 1}. `}{point}</span>
						);
					})
				}
				{(varrient === 'startTest' && testResultPrev) &&
					<div className='w-100 d-flex flex-column' style={{gap: '12px'}}>
						<span className='body-2 dark-50 text-start'>You attempted this test on <span className='dark-100'>{new Date(testResultPrev?.attempted_at).toDateString()}</span>, and scored <span className='primary-text'>{calculateTestResultPrevScore(testResultPrev?.score_object, testType)}/10</span>. You can either use your previous results or retake the test.</span>
						<Radio
							varrient='radio-with-input'
							readonly
							placeholder=''
							dangerouslySetInnerHTML
							onChangeRadioOption={() => {
								setRetakeTest(false);
							}}
							checked={retakeTest === false}
							value={'I wish to proceed with my previous results'}
						/>
						<Radio
							varrient='radio-with-input'
							readonly
							placeholder=''
							dangerouslySetInnerHTML
							onChangeRadioOption={() => {
								setRetakeTest(true);
							}}
							checked={retakeTest}
							value={'I want to retake this test'}
						/>
					</div>
				}
				{varrient === 'assessmentInstructions' &&
					<span className='body-1 dark-100' style={{ marginTop: '24px' }}>
						{'We wish you a very best of luck! please press start if you are ready'}
					</span>
				}
				{varrient === 'caseStudyIntroText' &&
					<span className='body-3' style={{ marginTop: '32px', marginBottom: '16px' }}>
						<ReactQuill
							className='ql-editor-test-instruction text-read-only disable-text-selection'
							theme='bubble'
							readOnly
							value={introText}
						/>
					</span>
				}

			</div>


		</AssessmentTestInfoWrapper>
	);
}
