import React, { useEffect } from 'react';
import OnboardingBox from '../components/OnboardingBox';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import Input from '../components/sub-component/Input';
import Button from '../components/sub-component/Button';
import AlertBox from '../components/common/AlertBox';
import { userPassword } from '../redux/thunks/User';

function PasswordSetUp({ access_token, assessment }) {

	const [processing, setProcessing] = useState(false);
	const [alert, setAlert] = useState(null);
	const [hidePassword, setHidePassword] = useState(true);
	const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
	const dispatch = useDispatch();

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setProcessing(true);
			const dispatched = await dispatch(userPassword({ password: credentials.password, access_token }));
			setProcessing(false);

			if (dispatched.error) {
				setAlert(
					<AlertBox description={`${dispatched.error.message}!`} color='danger' title='Error' onCloseAlert={() => { setAlert(null); }} />
				);
				return;
			}
			setAlert(
				<AlertBox description='Profile updated successfully!' color='success' title='Success' onCloseAlert={() => { setAlert(null); }} />
			);
			window.location.replace('/dashboard/assessments');
		} catch (error) {
			console.error(error);
			setProcessing(false);
			setAlert(
				<AlertBox description={`Error occurred: ${error.message}`} color='danger' title='Error' onCloseAlert={() => { setAlert(null); }} />
			);
		}
	};

	const [credentials, setCredentials] = useState({
		email: '',
		password: '',
		confirm_password: ''
	});

	const handleChange = (fieldName, event) => {
		let value = event.target.value;
		if (fieldName === 'email') {
			value = event.target.value.trim();
		}
		setCredentials({ ...credentials, [fieldName]: value });
	};

	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');





	return (
		<>
			<div className='candidate-assessment-flow-container align-items-center justify-content-center'>
				<div className='candidate-assment-flow-wrapper flex-column align-items-center justify-content-center'>
					<div style={{ Width: '664px', padding:'24px',background:'white' ,borderRadius:'24px'}}>
						<div className='d-flex flex-column align-items-center' >
							<span className={`${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-3'}`}>Set up password</span>
							<form className='w-100' onSubmit={handleSubmit}>
								<div className='d-flex flex-column mt-4'>
									<Input
										placeholder='Email'
										varrient={'primary'}
										onChange={(e) => handleChange('email', e)}
										type={'text'}
										title={'Email'}
										value={assessment?.current_assessment?.email}
										onBoardingInput
									/>
								</div>
								<div className='mt-4'>
									{alert}
								</div>
								<div className='d-flex flex-column form-input-field'>
									<Input
										name={'password'}
										placeholder='Password'
										// varrient={'primary'}
										title={'Confirm Password'}
										onChange={(e) => handleChange('password', e)}
										type={hidePassword ? 'password' : 'text'}
										value={credentials.password}
										enableRevealIcon={true}
										revealPasswordClick={() => setHidePassword(!hidePassword)}
									/>
								</div>
								<div className='d-flex flex-column form-input-field mt-4'>
									<Input
										placeholder='Confirm Password'
										name={'confirm_password'}
										// varrient={'primary'}
										title={'Confirm Password'}
										onChange={(e) => handleChange('confirm_password', e)}
										type={hideConfirmPassword ? 'password' : 'text'}
										value={credentials.confirm_password}
										enableRevealIcon={true}
										revealPasswordClick={() => setHideConfirmPassword(!hideConfirmPassword)}
									/>
								</div>
								<Button
									themeRectangleButton
									processing={processing}
									type={'submit'}
									title={'Save'}
									style={{ height: '40px', marginTop: '24px' }}
								/>
							</form>
						</div>
					</div>
				</div>
			</div>

		</>
	);
}

export default PasswordSetUp;