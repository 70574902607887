import React, { useState } from 'react';
import Input from '../sub-component/Input';
import {
	CardNumberElement,
	useStripe,
	useElements,
	CardExpiryElement,
	CardCvcElement,
} from '@stripe/react-stripe-js';
// import Select from '../sub-component/Select';
import Button from '../sub-component/Button';
import { useDispatch, useSelector } from 'react-redux';
import { createSubscription } from '../../redux/thunks/Subscription';
import AlertBox from '../common/AlertBox';
// import { useHistory } from 'react-router-dom';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
// import fontSrc from '../../assets/font/Montserrat/static/Montserrat-ExtraLight.ttf';
import fontSrc from '../../assets/font/Open_Sans/static/OpenSans-Light.ttf';

const PaymentInfoForm = () => {
	const [form, setForm] = useState({ name: '', postalCode: '', country: '', address: '' });
	const [error, setError] = useState({ name: '', postalCode: '', country: '', address: '' });
	const [alert, setAlert] = useState('');
	const [stripeElementsValidation, setStripeElementsValidation] = useState({});
	const dispatch = useDispatch();
	// const history = useHistory();
	const stripe = useStripe();
	const elements = useElements();


	const subscription = useSelector((state) => state.subscriptionReducer);

	countries.registerLocale(enLocale);
	const countryObj = countries.getNames('en', { select: 'official' });
	const countryArr = Object.entries(countryObj).map(([key, value]) => {
		return {
			label: value,
			value: key,
		};
	}).sort((a, b) => {
		const labelA = a.label.toUpperCase(); // ignore upper and lowercase
		const labelB = b.label.toUpperCase(); // ignore upper and lowercase
		if (labelA < labelB) {
			return -1;
		}
		if (labelA > labelB) {
			return 1;
		}
		// names must be equal
		return 0;
	});

	const cardNumberStyle = {

		style: {

			fonts: [
				{
					src: `url(${fontSrc})`,
					family: 'Open Sans',
				},
			],
			base: {
				fontSize: '14px',
				fontWeight: 400,
				fontStyle: 'normal',
				lineHeight: '18px',
				fontFamily: 'Open Sans',
				color: '#3E454D',

				'::placeholder': {
					color: '#96999C',
				},

			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		},
		showIcon: true,
	};

	const cardStyle = {
		style: {
			base: {
				fontSize: '14px',
				fontStyle: 'normal',
				fontFamily: 'Open Sans',
				'::placeholder': {
					color: '#96999C',
				},
				lineHeight: '20px',
			},

			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		},
	};
	const handleChange = (name, value) => {
		setForm({ ...form, [name]: value });
	};

	const validateStripeForm = (cardInfo) => {
		setStripeElementsValidation({ ...stripeElementsValidation, [cardInfo.name]: cardInfo?.value });
	};
	const isFormValid = () => {

		var isValid = true;

		const _credentials = {
			name: form.name,
			country: form.country,
			address: form.address,
			postalCode: form.postalCode,
			cardNumber: stripeElementsValidation.cardNumber,
			cardExpiry: stripeElementsValidation.cardExpiry,
			cardCVC: stripeElementsValidation.cardCVC,
		};

		var _error = {};
		Object.keys(_credentials).map(key => {
			if (_credentials[key] === null || _credentials[key] === '' || !_credentials[key]) {
				_error = { ..._error, [key]: `${key.replace('_', ' ')} is required` };
				isValid = false;

			} else {
				_error = { ..._error, [key]: null };
			}
		});

		if (_credentials.postalCode.length < 5 || _credentials.postalCode.length > 5) {
			_error = { ..._error, 'postalCode': 'Invalid zip code' };
			isValid = false;
		}

		setError(_error);
		return isValid;


	};
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!isFormValid()) {
			return;
		}

		if (subscription.stripe_products.length <= 0) {
			setAlert(
				<AlertBox description='No package selected' color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
			);
			return;
		}

		const data = {
			...form,
			stripe_pricing_id: subscription.stripe_products[0].stripe_pricings[0].id
		};

		const { payload, error } = await dispatch(createSubscription({ stripe, elements, ...data, CardNumberElement }));
		if (error) {
			setAlert(
				<AlertBox description={`${payload}!`} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
			);
			return;
		}

		window.location.reload();

		// history.push('/dashboard');
	};
	return (
		<>
			<div className="payment-info-form pe-3 ">
				<div className='alert-wrapper'>
					{alert}
				</div>
				<form onSubmit={handleSubmit}>
					<div className="d-flex flex-column form-input-field w-100 ">
						<Input
							placeholder="Cardholder Name"
							onChange={(e) => { handleChange('name', e.target.value); }}
							type="text" value={form.name}
							inputStyle={{ width: '100%' }}
							className={error.name ? 'error-input' : ''}
						/>

						<div className="error-text description">
							{error.name}
						</div>
					</div>
					<div className="d-flex flex-column form-input-field ">
						<CardNumberElement
							className='stripe-input-field-full'
							options={cardNumberStyle}

							onChange={(e) => {
								if (e?.error) {
									setError({ ...error, cardNumber: e?.error?.message });
									// dispatch(
									// 	Actions.validateStripeForm({
									// 		name: "cardNumber",
									// 		value: e?.error?.message,
									// 	}),
									// );
								} else {
									setError({ ...error, cardNumber: '' });
									validateStripeForm({
										name: 'cardNumber',
										value: true,
									});
									// dispatch(
									// 	Actions.validateStripeForm({
									// 		name: "cardNumber",
									// 		value: false,
									// 	}),
									// );
								}
							}}
						/>

						<div className="error-text description">
							{error.cardNumber}
						</div>
					</div>
					<div className='d-flex'>
						<div className="d-flex flex-column form-input-field col-6">
							<CardExpiryElement
								className='stripe-input-field-half '
								options={cardStyle}
								onChange={(e) => {
									if (e?.error) {
										setError({ ...error, cardExpiry: e?.error?.message });
										// dispatch(
										// 	Actions.validateStripeForm({
										// 		name: "cardExpiry",
										// 		value: e?.error?.message,
										// 	}),
										// );
									} else {
										setError({ ...error, cardExpiry: '' });
										validateStripeForm({
											name: 'cardExpiry',
											value: true,
										});
										// dispatch(
										// 	Actions.validateStripeForm({
										// 		name: "cardExpiry",
										// 		value: false,
										// 	}),
										// );
									}
								}}
							/>

							<div className="error-text description">
								{error.cardExpiry}
							</div>
						</div>
						<div className="d-flex flex-column form-input-field col-6" >
							<CardCvcElement
								className='stripe-input-field-half ms-auto'
								options={cardStyle}
								onChange={(e) => {
									if (e?.error) {
										setError({ ...error, cardCVC: e?.error?.message });
										// dispatch(
										// 	Actions.validateStripeForm({
										// 		name: "cardCVC",
										// 		value: e?.error?.message,
										// 	}),
										// );
									} else {
										setError({ ...error, cardCVC: '' });
										validateStripeForm({
											name: 'cardCVC',
											value: true,
										});
										// dispatch(
										// 	Actions.validateStripeForm({
										// 		name: "cardCVC",
										// 		value: false,
										// 	}),
										// );
									}
								}}
							/>

							<div className="error-text description ms-4">
								{error.cardCVC}
							</div>
						</div>
					</div>
					<div className="d-flex flex-column form-input-field w-100 ">
						<Input
							placeholder="Address"
							onChange={(e) => { handleChange('address', e.target.value); }}
							type="text" value={form.address}
							inputStyle={{ width: '100%', }}
							className={error.address ? 'error-input' : ''}
						/>

						<div className="error-text description">
							{error.address}
						</div>
					</div>
					<div className='d-flex'>
						<div className="d-flex flex-column form-input-select col-6 ">
							{/* <Select
							name='country'
							value={form.country}
							placeholder={form.country || 'Country'}
							placeholderStyle={{ color: '#96999C', marginLeft: '-10px', fontSize: '14px' }}
							inputStyle={{
								width: '92%',
								borderRadius: '0px',
								borderBottom: error.countryError ? '1px solid #FC4848' : '1px solid #96999C',
								boxShadow: 'none',
							}}
							options={['US', 'PK', 'UK']}
							selected={form.country}
							onSelect={(selectedState) => {
								handleChange('country', selectedState);
							}}
						/> */}

							<select className='select-drop-down body-3' defaultValue={''} onChange={(e) => handleChange('country', e.target.value)}>
								<option value={''} disabled>Country</option>
								{!!countryArr?.length &&
									countryArr.map(({ label, value }) => (
										<option key={value} value={value}>
											{label}
										</option>
									))}
							</select>

							<div className="error-text description">
								{error.country}
							</div>
						</div>
						<div className="d-flex flex-column form-input-field col-6 ">
							<Input
								placeholder="Postal Code"
								onChange={(e) => { handleChange('postalCode', e.target.value); }}
								type="text" value={form.postalCode}
								inputStyle={{ width: '92%', marginLeft: '8%' }}
								className={error.postalCode ? 'error-input' : ''}
								maxLength={5}
								minLength={5}
							/>

							<div className="error-text description ms-4">
								{error.postalCode}
							</div>
						</div>

					</div>
					<div className="d-flex justify-content-center mt-5 submit-button">
						<Button
							themeRectangleButton
							title={'Continue'}
							// style={{ height: '40px' }}
							type={'submit'}
							processing={subscription.processing}
						// onClick={handleSubmit}
						/>
					</div>
				</form>
			</div>
		</>
	);
};

export default PaymentInfoForm;
