import React, { useState } from 'react';
import { DateToWDDDMMYYYY, LocaleDateToTime } from '../../../../utils/utilities';
import { ReactComponent as DownArrow } from '../../../../assets/icons/Views/dashboard/test-detail/down-arrow.svg';
import { ReactComponent as Cross } from '../../../../assets/icons/components/code-resposnse-result/x-circle.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/components/code-resposnse-result/CheckCircle.svg';
import SnapshotsPlayer from '../../../../components/dashboard/test-details/anti-cheat/SnapshotsPlayer';
import { useMediaQuery } from '@mui/material';

export default function AntiCheatMonitor(props) {

	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const { assessment } = props;
	const [openedCards, setOpenedCards] = useState([]);

	const GenralInfoBox = ({ text, status }) => {
		return (
			<div className='anti-cheat-genral-info-box'>
				<span className='body-2 dark-100' >{text}</span>
				<div >
					<span>{ assessment?.status === 'IN_PROGRESS' ? 'N/A' : status ? <CheckIcon/> : <Cross/> }</span>
				</div>
			</div>
		);
	};


	return (
		<div className='w-100 d-flex flex-column' style={{ gap: '24px' }}>
			<span className='body-2 dark-100' style={{ textAlign: 'left'}}>
				Testfuse assessments can be attempted gradually, test-by-test. All the different times that a candidate returns to an assessment creates an assessment session. Sessions are listed below along with their relevant data. Some relevant flags have also been reported. These features provide valuable insights to the administrators, allowing them to make informed decisions without directly accusing candidates of cheating.
			</span>
			<div className='w-100 d-flex flex-column' style={{ gap: '16px' }}>
				<GenralInfoBox text={'Attempted from the same location?'} status={assessment?.anti_cheat_data?.same_location} />
				<GenralInfoBox text={'Attempted from the same device?'} status={assessment?.anti_cheat_data?.same_device} />
				<GenralInfoBox text={'Full-screen mode always active?'} status={!assessment?.anti_cheat_data?.is_exited_full_screen} />
				<GenralInfoBox text={'Mouse always in assessment window?'} status={!assessment?.anti_cheat_data?.is_left_screen} />
			</div>

			{
				assessment?.sessions?.map((session, index) => {
					return (
						<div
							key={index}
							className={`anti-cheat-genral-info-collapsing-card ${openedCards.includes(index) ? session?.events.length > 0 ? 'anti-cheat-genral-info-collapsing-card-open-with-events' : 'anti-cheat-genral-info-collapsing-card-open' : ''}`}>
							<div className='anti-cheat-genral-info-collapsing-card-header'
								onClick={() => {
									if (openedCards.includes(index)) { setOpenedCards(openedCards.filter((cardIndex) => { return cardIndex !== index; })); }
									else { setOpenedCards([...openedCards, index]); }
								}}
							>
								<div className='w-100 d-flex justify-content-between' ><span className='body-2'>{DateToWDDDMMYYYY(session?.created_at)}</span> 	<DownArrow /></div>
								<div className='anti-cheat-genral-info-collapsing-card-header-text'>
									<span className='body-3 dark-50' >Start Time : <span className='dark-100'>{LocaleDateToTime(session?.created_at)}</span></span>
									<span className='body-3 dark-50' >End Time : <span className='dark-100'>{LocaleDateToTime(session?.updated_at)}</span></span>
								</div>
							
							</div>
							<div className='anti-cheat-genral-info-collapsing-card-body'>
								<div className='d-flex' style={{ marginBottom: session?.events.length > 0 ? '6px' : '0px' ,background:'white',padding : '12px 18px 12px 18px',borderRadius:'24px'}}>
									<div className='d-flex flex-column ' style={{ gap: '6px' }}>
										<span className='body-2 dark-50'>Device Used:</span>
										<span className='body-2 dark-50'>Browser:</span>
										<span className='body-2 dark-50'>Webcam Enabled:</span>
										<span className='body-2 dark-50'>Exit FullScreen:</span>
										<span className='body-2 dark-50'>Mouse left Window:</span>
										<span className='body-2 dark-50'>Location:</span>
									</div>
									<div className='d-flex flex-column' style={{ gap: '6px' }}>
										<span  className='body-2 dark-100'>{session?.device_type}</span>
										<span  className='body-2 dark-100'>{session?.browser}</span>
										<span className='body-2 dark-100'>{session?.events.length > 0 ? 'Yes' : 'No'}</span>
										<span  className='body-2 dark-100'>{session?.events?.some((e) => e?.is_exited_full_screen) ? 'Yes' : 'No'}</span>
										<span  className='body-2 dark-100'>{session?.events?.some((e) => e?.is_left_screen) ? 'Yes' : 'No'}</span>
										<span  className='body-2 dark-100'>{(session?.country_code && session?.city) ? `${session?.city ? `${session?.city}, ` : ''}${session?.country_code}` : '-'}</span>
									</div>
								</div>
								<SnapshotsPlayer snapshots={session?.events.filter((e) => e.event_type === 'snapshot')} />
							</div>
						</div>
					);
				})
			}

		</div>
	);
}
