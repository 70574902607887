import { createAsyncThunk } from '@reduxjs/toolkit';

export const setLatestErrorId = createAsyncThunk(
	'auth/setLatestErrorId',
	async (data, { rejectWithValue }) => {
		try {
			return { ...data };
		} catch (error) {
			return rejectWithValue(error?.message);
		}
	}
);



