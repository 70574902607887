import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PathName } from '../utils/routes';
import { updateAssessSpec, createAssessSpec } from './../redux/thunks/AssessSpec';
import { listTestPacks } from './../redux/thunks/TestPack';
import ProcessingIndicator from '../components/common/ProcessingIndicator';

export default function AssessmentCreateWithTestpack() {
	const assessSpec = useSelector((state) => state.assessSpecReducer);
	const history = useHistory();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	const handleCreateAssessSpec = async (test_type, test_id) => {
		const createAssessSpecResponse = await dispatch(createAssessSpec({ title: 'New Test' }));
		const listTestpackResponse = await dispatch(listTestPacks());
		const testPack = listTestpackResponse?.payload[`${test_type}`]?.find(test => test?.id == test_id);
		
		const sections = [];
		sections.push({
			intro_text: test_type === 'skill_test_packs' ? 'Skill Based Test' : test_type === 'coding_test_packs' ? 'Coding Test' : 'Values Fitness',
			type: (test_type === 'value_fitness_test_packs' ? test_type.replace('_fitness_test_packs', '_test') : test_type.replace('_packs', '')),
		});

		const tests = [];
		tests.push(
			test_type === 'value_fitness_test_packs' ?
				{
					test_type: testPack.test_pack_type.replace('_fitness_test_pack', '_test'),
					values: [
						{
							title: testPack.title,
							value_name: testPack.title,
							text: testPack.text,
							duration: testPack.duration,
							icon: testPack.icon,
							val_id: testPack.id,
						}
					],
					
				}
				:
				{
					title: testPack.title,
					text: testPack.text,
					duration: testPack.duration,
					icon: testPack.icon,
					test_type: testPack.test_pack_type.replace('_pack', ''),
					difficulty: testPack.difficulty,
					tp_id: testPack.id,
				}
		);

		const updatedAssessSpec = { ...assessSpec?.curr_assessment_spec };
		updatedAssessSpec.sections = sections;
		updatedAssessSpec.tests = tests;
		await dispatch(updateAssessSpec({ id: createAssessSpecResponse?.payload?.id, data: updatedAssessSpec }));
		setLoading(false);
		history.push(`${PathName.ASSESSMENTS}/create/${createAssessSpecResponse?.payload?.id}`);

	};

	useEffect(() => {
		handleCreateAssessSpec((localStorage.getItem('test_type').replace('_pack', '_packs')), localStorage.getItem('test_id'));
		localStorage.removeItem('test_type');
		localStorage.removeItem('test_id');
	}, []);
	
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-comtent-center' style={{ width:  '90vw', height: '100vh' }}>
				<ProcessingIndicator className='medium-indicator' />
			</div>
		);
	}
}
