import React, { Fragment, useMemo, useState } from 'react';
import { ReactComponent as PlayIcon } from '../../../../../assets/icons/Views/dashboard/assessments/play.svg';
import { ReactComponent as BarChartIcon } from '../../../../../assets/icons/Views/dashboard/assessments/bar-chart-2.svg';
import { ReactComponent as CodeIcon } from '../../../../../assets/icons/Views/dashboard/assessments/code.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/Views/dashboard/assessments/plus.svg';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/Views/dashboard/assessments/AddIcon.svg';
import { ReactComponent as UserCheckIcon } from '../../../../../assets/icons/Views/dashboard/assessments/user-check.svg';
import { ReactComponent as UploadIcon } from '../../../../../assets/icons/Views/dashboard/assessments/upload.svg';
import { ReactComponent as TypeIcon } from '../../../../../assets/icons/Views/dashboard/assessments/type.svg';
import { ReactComponent as VideoIcon } from '../../../../../assets/icons/Views/dashboard/assessments/video.svg';
// import { ReactComponent as TimerIcon } from '../../../../../assets/icons/Views/dashboard/assessments/HourglassMedium.svg';
import { ReactComponent as Trash2Icon } from '../../../../../assets/icons/Views/dashboard/assessments/trash-2.svg';
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/Views/dashboard/assessments/trash.svg';
import { ReactComponent as MoveIcon } from '../../../../../assets/icons/Views/dashboard/assessments/move.svg';
import { ReactComponent as MaximizeIcon } from '../../../../../assets/icons/Views/dashboard/assessments/maximize-2.svg';
import Button from '../../../../../components/sub-component/Button';
import { QualifyingQuestionsJSON, sectionTypes } from '../../../../../utils/assessmentQuestionsJSON';
import FileUpload from '../../../../../components/dashboard/assessments/create-assessment/section-types/FileUpload';
import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { ReactComponent as CheckIcon } from '../../../../../assets/icons/Views/dashboard/assessments/check-square.svg';
import { ReactComponent as ListIcon } from '../../../../../assets/icons/Views/dashboard/assessments/list.svg';
import { ReactComponent as DeleteMCQsIcon } from '../../../../../assets/icons/Views/dashboard/test-detail/x.svg';
import CustomModal from '../../../../../components/common/Modal';
// import QualifyQuestionSection from '../../../../../components/dashboard/assessments/create-assessment/qq-section-types/QualifyQuestionSection';
import SectionContainer from '../../../../../components/dashboard/assessments/create-assessment/section-types/SectionContainer';
import Input from '../../../../../components/sub-component/Input';
import Radio from '../../../../../components/sub-component/Radio';
import Select from '../../../../../components/sub-component/Select';

import { ReactComponent as WarningIcon } from '../../../../../assets/icons/Views/dashboard/assessments/alert-circle.svg';
import { useSelector } from 'react-redux';
import { QualifyingQuestionValidator } from '../AssessmentCreationValidators';
import { style } from '@mui/system';
import { useFocusWithin } from '@react-aria/interactions';


export default function CreateQualifyingQuestions(props) {

	const isOnTabletScreen = useMediaQuery('(max-width:1439px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const { section, openSectionType, setOpenSectionType } = props;


	const assessSpec = useSelector((state) => state.assessSpecReducer);

	const { numeric, boolean, mcqs } = QualifyingQuestionsJSON;

	const _QQTypes = [
		{ id: 'numeric', icon: <PlusIcon style={{ width: '24px', height: '24px' }} />, title: 'Numeric' },
		{ id: 'true-false', icon: <CheckIcon style={{ width: '24px', height: '24px' }} />, title: 'True/False' },
		{ id: 'mcq', icon: <ListIcon style={{ width: '24px', height: '24px' }} />, title: 'MCQ' }
	];

	const [questions, setQuestions] = useState([]);
	const [sectionsTypesToAdd, setSectionsTypesToAdd] = useState([]);
	const [errorQuestionIndexes, setErrorQuestionIndexes] = useState([]);
	const [focusedQuesionContainer, setFocusedQuesionContainer] = useState(-1);
	const [recentMiniMizedIndexToValidate, setRecentMiniMizedIndexToValidate] = useState(-1);
	const [activeSection, setActiveSection] = useState({ type: null, section: null });
	const [selectedSectionType, setSelectedSectionType] = useState(null);
	const [toDragIndex, setToDragIndex] = useState(-1);
	const [fromDragIndex, setFromDragIndex] = useState(-1);
	const [isSectionDropdownOpen, setIsSectionDropdownOpen] = useState(false);
	const [sectionToDelete, setSectionToDelete] = useState({
		index: -1,
		showModal: false
	});

	useEffect(() => {
		setQuestions(props?.assessmentSpecsDetails?.qualifying_questions || []);
		props.getCurrentQQComponentData(props?.assessmentSpecsDetails?.qualifying_questions || []);
		setSectionsTypesToAdd([..._QQTypes]);
	}, []);

	useEffect(() => {
		if (props?.resetQQ) {
			setQuestions(props?.assessmentSpecsDetails?.qualifying_questions || []);
			setSectionsTypesToAdd([..._QQTypes]);
			setFocusedQuesionContainer(-1);
			setActiveSection({ type: null, section: null });
			props?.getResetQQ(false);
		}
	}, [props?.resetQQ]);


	const EmptySection = () => {
		return (
			<div className={`default-section-div pointer`} style={{ borderColor: '#96999C' }} onClick={() => { if (isOnMobileScreen) { setOpenSectionType({ type: 'welcome_video', isOpen: true }); } }}>
				<div className='d-flex align-items-center' style={{ gap: '8px' }} onClick={() => { setIsSectionDropdownOpen(true); }}>
					<span className='body-2 dark-50'>{'Choose Question Type'}</span>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5 7.5L10 12.5L15 7.5" stroke="#808191" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
				</div>
				<div className='default-section-options'>
					{
						isSectionDropdownOpen &&
						sectionsTypesToAdd.map((sectionType, index) => {
							return (
								<div onClick={() => { setSelectedSectionType(sectionType.id); addSections(sectionType.id); setIsSectionDropdownOpen(false); }} key={index} className='default-section-option' style={{ borderRadius: index === 0 ? '24px 24px 0px 0px' : index === (sectionsTypesToAdd.length - 1) ? '0px 0px 24px 24px' : '0px 0px 0px 0px' }}>
									<span className='body-2 dark-400'>{sectionType.title}</span>
								</div>
							);
						})
					}
				</div>
			</div>
		);
	};


	const addSections = (selectedSection) => {
		const _questions = [...questions, selectedSectionType === 'numeric' || selectedSection === 'numeric'
			? {
				type: 'numeric',
				question: null,
				idealAnswer: null,
				candidateAnswer: null,
			}
			: selectedSectionType === 'mcq' || selectedSection === 'mcq'
				? {
					type: 'mcq',
					question: null,
					idealAnswer: 0,
					candidateAnswer: null,
					options: ['', '']
				}
				: {
					type: 'boolean',
					question: null,
					idealAnswer: null,
					candidateAnswer: null,
				}];
		setQuestions([..._questions]);
		props.getCurrentQQComponentData([..._questions]);
		if (focusedQuesionContainer !== -1) {
			console.log('log 2');
			isQuestionValidate(focusedQuesionContainer, false);
		}
		setFocusedQuesionContainer(questions.length);
		props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: _questions });
		// props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: [..._questions] });
	};

	const replaceSectionPositions = () => {
		if (fromDragIndex !== -1 && toDragIndex !== -1) {
			const _questions = questions;
			const replaceFrom = _questions[fromDragIndex];
			_questions[fromDragIndex] = questions[toDragIndex];
			_questions[toDragIndex] = replaceFrom;
			setQuestions([..._questions]);
			props.getCurrentQQComponentData([..._questions]);
		}

		const errors = questions?.map((_, questionIndex) => {
			const validator = QualifyingQuestionValidator(questions, questionIndex);
			if (validator.isValidate === false) {
				return { questionIndex: questionIndex, ...validator };
			}
		});
		setErrorQuestionIndexes(errors.filter((e, _) => { return (e !== undefined); }));
	};

	const onRemoveSection = (index) => {
		const _questions = questions;
		_questions.splice(index, 1);


		const errors = _questions?.map((_, questionIndex) => {
			const validator = QualifyingQuestionValidator(_questions, questionIndex);
			if (validator.isValidate === false) {
				return { questionIndex: questionIndex, ...validator };
			}
		});
		setErrorQuestionIndexes(errors.filter((e, _) => { return (e !== undefined); }));

		if (focusedQuesionContainer !== -1) {
			if (focusedQuesionContainer > index) {
				setFocusedQuesionContainer(focusedQuesionContainer - 1);
			}
			else if (focusedQuesionContainer < index) {
				setFocusedQuesionContainer(focusedQuesionContainer);
			}
		}
		setQuestions([..._questions]);
		props.getCurrentQQComponentData([..._questions]);
		setSectionToDelete({ index: -1, showModal: false });

		if (_questions.length === 0) {
			props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: null });
		}
		else {
			props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: [..._questions] });
		}
	};

	useMemo(() => {
		console.log(focusedQuesionContainer);
	}, [focusedQuesionContainer]);

	const MinimizeViewOfSection = (props) => {
		const { onRemoveSection, onSelect, sectionDetails, draggable, onDragStart, error, questionIndex } = props;
		return (
			<div style={{ position: 'relative' }} className={error ? 'minize-view-of-section-container minize-view-of-section-container-error pointer' : 'minize-view-of-section-container pointer'} draggable={draggable} onDragStart={onDragStart}>
				<div className={`d-flex align-items-center position-absolute`} style={{ right: '16px', top: '12px' }} >
					<TrashIcon className="pointer" style={{ width: '24px', height: '24px' }} onClick={() => { onRemoveSection(); }} />
				</div>

				<div onClick={() => {
					focusedQuesionContainer !== -1
						? isQuestionValidate(focusedQuesionContainer, false)
						: isQuestionValidate(questionIndex, false);
					onSelect();
				}} className="w-100 h-100 d-flex flex-column align-items-start justify-content-between" style={{ gap: '6px' }}>
					<div className='d-flex align-items-center justify-content-between w-100'>
						<div className='w-100 d-flex align-items-center justify-content-start'>
							{/* {
								(error) &&
								<div className='d-flex align-items-center justify-content-start' style={{ marginRight: '10px' }}>
									<WarningIcon style={{ width: '28px', height: '28px' }} />
								</div>
							} */}
							{sectionDetails?.type === 'mcq' ? <ListIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} /> : sectionDetails?.type === 'boolean' ? <CheckIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} /> : <PlusIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} />}
							<span className='body-2 dark-100' style={{ marginLeft: '10px' }}>{sectionDetails?.type === 'mcq' ? 'MCQ' : sectionDetails?.type === 'boolean' ? 'True/False' : 'Numeric'}</span>
						</div>

					</div>
					<div>
						<span className='body-3 dark-50'>{sectionDetails?.question ? sectionDetails?.question : 'Question statement will be shown here'}</span>
					</div>
				</div>
			</div>
		);
	};

	//---QQ Component Methods

	const onHandleChange = (e, questionIndex) => {
		const _questions = questions;
		_questions[questionIndex] = { ..._questions[questionIndex], [e.target.name]: e.target.value === '' ? null : e.target.value };
		setQuestions([..._questions]);
	};

	const onHandleOptionChange = (e, questionIndex, optionIndex) => {
		const updatedQuestion = { ...questions[questionIndex], options: [...questions[questionIndex].options] };
		updatedQuestion.options[optionIndex] = e.target.value;
		const newQuestions = [...questions];
		newQuestions[questionIndex] = updatedQuestion;
		setQuestions(newQuestions);
	};

	const onHandleSelect = (selectedOption, questionIndex, name) => {
		const _questions = questions;
		_questions[questionIndex] = { ..._questions[questionIndex], [name]: selectedOption.value };
		setQuestions([...questions]);
		props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: _questions });
		props.getCurrentQQComponentData([..._questions]);
	};

	const onAddingMCQsOption = (questionIndex) => {
		const _questions = [...questions];
		const options = [..._questions[questionIndex].options];
		options.push('');
		_questions[questionIndex].options = options;
		setQuestions([..._questions]);
		props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: _questions });
		props.getCurrentQQComponentData([..._questions]);
	};

	useEffect(() => {
		if (assessSpec?.curr_assessment_spec?.qualifying_questions) {
			const errors = assessSpec?.curr_assessment_spec?.qualifying_questions?.map((_, questionIndex) => {
				const validator = QualifyingQuestionValidator(assessSpec?.curr_assessment_spec?.qualifying_questions, questionIndex);
				if (validator.isValidate === false) {
					return { questionIndex: questionIndex, ...validator };
				}
			});
			setErrorQuestionIndexes(errors.filter((e, _) => { return (e !== undefined); }));
		}
	}, []);

	useEffect(() => {
		if (props.checkValidation) {
			const errors = questions?.map((_, questionIndex) => {
				const validator = QualifyingQuestionValidator(questions, questionIndex);
				if (validator.isValidate === false) {
					return { questionIndex: questionIndex, ...validator };
				}
			});
			setErrorQuestionIndexes(errors.filter((e, _) => { return (e !== undefined); }));
		}

		props.setCheckValidation(false);
	}, [props.checkValidation]);


	const isQuestionValidate = (questionIndex, allow_to_visualize_errors) => {
		const validator = QualifyingQuestionValidator(questions, questionIndex);

		if (allow_to_visualize_errors && validator.isValidate === false) {
			return;
		}
		if (validator.isValidate === false) {
			if (errorQuestionIndexes.filter((ques,) => { return (ques.questionIndex === questionIndex); }).length === 0) {
				setErrorQuestionIndexes([...errorQuestionIndexes, { questionIndex: questionIndex, ...validator }]);
			}
			else {
				const errors = errorQuestionIndexes.map((ques, _index) => {

					if (ques.questionIndex === questionIndex) {
						return { questionIndex: questionIndex, ...validator };
					}
					else {
						return ques;
					}
				});
				setErrorQuestionIndexes([...errors]);
			}
			return false;
		} else {
			setErrorQuestionIndexes(errorQuestionIndexes.filter((ques,) => { return (ques.questionIndex !== questionIndex); }));
			return true;
		}
	};


	const handleCreateQualifingQuestion = (questionIndex) => {
		if (isQuestionValidate(questionIndex) === false) {
			return;
		}

		setQuestions([...questions]);
		setFocusedQuesionContainer(-1);

		if (props.onConfirm) {
			props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: questions });
		}

	};

	const onCancelQualifingQuestion = (questionIndex) => {
		setFocusedQuesionContainer(-1);
		if (isQuestionValidate(questionIndex) === false) {
			return;
		}
	};

	useEffect(() => {
		if (selectedSectionType && isOnTabletScreen) {
			// addSections();
			setSelectedSectionType(null);
		}
	}, [selectedSectionType, isOnTabletScreen]);

	useMemo(() => {
		// props.getCurrentQQComponentData([...questions]);

		let questionError = null;
		questions.map((_, questionIndex) => {
			questionError = errorQuestionIndexes.filter((ques, _) => { return (ques.questionIndex === questionIndex); })[0] || null;
		});
		if (questionError) {
			props.setCheckValidation(true);
		}
	}, [questions]);



	useMemo(() => {
		if (props?.activeSection?.type && props?.activeSection?.section !== null) {

			if (props?.activeSection?.type !== 'qualifying_questions') {
				if (activeSection?.type === 'qualifying_questions') {
					if (focusedQuesionContainer !== -1) {
						console.log('log 1');
						isQuestionValidate(focusedQuesionContainer, false);
					}
				}
				setFocusedQuesionContainer(-1);
			}
			else {
				// setFocusedQuesionContainer(props?.activeSection?.section);
			}
		}
		setActiveSection(props?.activeSection);

	}, [props?.activeSection]);

	useMemo(() => {
		if (focusedQuesionContainer !== -1) {
			props.getActiveSection(focusedQuesionContainer);
		}
	}, [focusedQuesionContainer]);




	return (
		<>
			<CustomModal
				open={sectionToDelete.showModal}
				varrient='alert-warning-modal'
				title='Delete Section?'
				description={'Are you sure you want to delete this section?'}
				dangerAlert={true}
				handleCloseModal={() => { setSectionToDelete({ ...sectionToDelete, showModal: false }); }}
				confirmBtnTitle={'Yes, Delete'}
				btnWidth='max-content'
				onConfirm={() => { onRemoveSection(sectionToDelete.index); setSectionToDelete({ index: -1, showModal: false }); }}
			/>


			{
				!isOnMobileScreen ?
					<div className='create-assessment-secttion' style={{ maxHeight: 'max-Content' }}>
						<div className='create-assessment-secttion-container'  >
							<div className='create-assessment-secttion-container-header'>
								<div className='w-100 h-100 d-flex flex-column align-items-start' style={{ justifyContent: 'space-between' }}>
									<span className='subtitle-1 dark-100'>{props?.section?.title}</span>
									<span className='body-2 dark-50' style={{ marginTop: '8px', textAlign: 'left' }}>{props?.section?.subTitle}</span>
								</div>
								<div className='h-100 d-flex align-items-start justify-content-end' style={{ width: '30px' }}>
									<TrashIcon className="pointer" style={{ width: '24px', height: '24px' }} onClick={() => {
										props.onDelete();
										// setFocusedQuesionContainer(-1);
									}} />
								</div>
							</div>
							<div className='add-section-container'>
								{
									questions.length > 0 && questions?.map((question, questionIndex) => {
										const questionError = errorQuestionIndexes.filter((ques, _) => { return (ques.questionIndex === questionIndex); })[0] || null;
										return (

											<Fragment key={questionIndex}>
												<div style={{ width: '100%' }} onDragLeave={() => { }}
													onDragOver={() => {
														const getAllAssignSections = questions?.filter((section, _) => { return section !== null; });
														if (questionIndex > getAllAssignSections.length - 1) {
															setToDragIndex(-1);
														}
														else {
															setToDragIndex(questionIndex);
														}
													}}>
													{
														<div className='w-100'>
															{
																focusedQuesionContainer === questionIndex
																	?
																	<SectionContainer
																		title={question.type === 'mcq' ? 'Multiple Choice Question' : question.type === 'numeric' ? 'Numeric' : 'True/False'}
																		description={question.type === 'mcq' ? 'Enter a multiple choice question' : question.type === 'numeric' ? 'Enter a question with a numeric answer' : 'Enter a question with a true/false answer'}
																		enableDuration={false}
																		onRemoveSection={() => { setSectionToDelete({ index: questionIndex, showModal: true }); }}
																		// onConfirm={() => { handleCreateQualifingQuestion(questionIndex); }}
																		// onCancel={() => { onCancelQualifingQuestion(questionIndex); }}
																		error={questionError}

																	>
																		{(questionError?.isValidate === false) && (
																			<div className='error-box mt-2 w-100 mb-3'>
																				<WarningIcon style={{ width: '24px', height: '24px', marginRight: '12px' }} />
																				<span className='body-3 text-danger'>
																					{questionError?.message}
																				</span>
																			</div>
																		)}
																		<div key={questionIndex} className={`question-box position-relative `}>

																			<div className='question-box-question-section'>
																				<span className='body-2 dark-50 mb-2' style={{ paddingLeft: '24px' }}>Question statement</span>
																				<Input varrient={'primary'} placeholder="Write your question here.." value={question.question} name="question"
																					onChange={(e) => {
																						onHandleChange(e, questionIndex);
																						isQuestionValidate(focusedQuesionContainer, true);
																					}}
																					onBlur={(e) => { props.getCurrentQQComponentData([...questions]); }}
																				// onBlur={(e) => { props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: questions }); }}
																				/>

																				{
																					question.type === 'mcq'
																						?
																						<div className='d-flex flex-column align-items-start w-100' style={{ marginTop: '16px' }}>
																							{
																								question.options.length !== 0 &&
																								<span className='body-2 dark-50' style={{ marginLeft: '24px' }}>Choose one answer<span className='danger-text'>*</span></span>
																							}


																							{
																								<div className='w-100'>

																									{
																										question?.options.map((option, index) => {
																											return (
																												<div key={index} className="w-100 d-flex align-items-center mt-2">
																													<Radio
																														style={{ background: index === question.idealAnswer ? '#ECEDF1' : '#F6F5F8' }}
																														varrient="radio-with-input"
																														onChangeRadioOption={() => {
																															const _questions = questions;
																															_questions[questionIndex] = {
																																..._questions[questionIndex],
																																idealAnswer: index,
																															};
																															setQuestions([..._questions]);
																															props.getCurrentQQComponentData([..._questions]);

																														}}
																														checked={index === question.idealAnswer}
																														onBlur={(e) => { props.getCurrentQQComponentData([...questions]); }}
																														readOnly={false}
																														disabled={false}
																														radioValue={index}
																														value={questions[questionIndex].options[index]}
																														placeholder="Write your answer here.."
																														onChange={(e) => {
																															onHandleOptionChange(e, questionIndex, index);
																															isQuestionValidate(focusedQuesionContainer, true);
																														}}

																													/>
																													{
																														question.options.length > 2 &&
																														<DeleteMCQsIcon
																															className="pointer"
																															key={index}
																															style={{ width: '24px', height: '24px', marginLeft: '16px' }}
																															onClick={() => {

																																const _questions = questions;
																																_questions[questionIndex].options = _questions[questionIndex].options.filter((_, optionIndex) => optionIndex !== index);
																																setQuestions([..._questions]);
																																props.getCurrentQQComponentData([..._questions]);
																																props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: _questions });
																															}}
																														/>
																													}
																												</div>
																											);
																										})
																									}
																									<div className='d-flex justify-content-start align-items-center pointer' style={{ width: '192px', marginTop: '15.5px', borderRadius: '24px', height: '32px', border: '1px solid #121216' }} onClick={() => { onAddingMCQsOption(questionIndex); }}  >
																										<AddIcon style={{ width: '16px', height: '16px', stroke: '#121216', marginLeft: '16px' }} />
																										<span className='body-2 dark-100' style={{ marginLeft: '4px' }}> Add another option</span>
																									</div>

																								</div>
																							}


																						</div>
																						:
																						<div className='question-answer-selection-container ' style={{ marginTop: '16px' }}>
																							<div className='w-100 d-flex flex-column align-items-start'>
																								<div className='' style={{ minWidth: 'fit-content', marginLeft: '24px' }}>
																									<span className='body-2 dark-50 d-flex' style={{ fontWeight: '300' }}>Response Type</span>
																								</div>
																								<Select readOnly
																									options={[{ name: 'Numeric', value: 'numeric' }, { name: 'True/False', value: 'boolean' }]}
																									className="question-select-responsive"
																									selected={question.type}
																									inputStyle={{ fontWeight: '300' }}

																									onSelection={(option) => {
																										const _questions = questions;
																										_questions[questionIndex] = {
																											..._questions[questionIndex],
																											type: option.value,
																											idealAnswer: null,
																											candidateAnswer: null,
																										};
																										setQuestions([...questions]);
																										props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: _questions });
																										props.getCurrentQQComponentData([..._questions]);
																										isQuestionValidate(focusedQuesionContainer, true);
																									}}
																								/>
																							</div>
																							{
																								question.type === 'numeric'
																									?
																									<div className='w-100 d-flex flex-column align-items-start'>
																										<div className='mb-2' style={{ minWidth: 'fit-content' }}>
																											<span className='body-2 dark-50 d-flex' style={{ marginLeft: '24px' }}>Ideal answer<span className='danger-text'>*</span></span>
																										</div>
																										<Input type={'number'} varrient={'primary'} placeholder="Write your Ideal answer here.." value={question?.idealAnswer || ''} name="idealAnswer"
																											onChange={(e) => {
																												onHandleChange(e, questionIndex, 'idealAnswer');
																												isQuestionValidate(focusedQuesionContainer, true);
																											}}
																											inputContainerClass="question-input-responsive"
																											onBlur={(e) => { props.getCurrentQQComponentData([...questions]); }}
																										/>
																									</div>
																									:
																									<div className='w-100 d-flex flex-column align-items-start'>
																										<div style={{ minWidth: 'fit-content' }}>
																											<span className='body-2 dark-50' style={{ marginLeft: '24px' }}>Ideal answer<span className='danger-text'>*</span></span>
																										</div>
																										<Select readOnly options={[{ name: 'True', value: true }, { name: 'False', value: false }]}
																											className="question-select-responsive select-ideal-answer"
																											selected={question.idealAnswer}
																											onSelection={(option) => {
																												onHandleSelect(option, questionIndex, 'idealAnswer');
																												isQuestionValidate(focusedQuesionContainer, true);
																											}}
																										/>
																									</div>
																							}

																						</div>
																				}
																			</div>
																		</div>
																	</SectionContainer>
																	:
																	<div
																		className='w-100'
																		onDragEnd={() => { replaceSectionPositions(); }}
																		draggable={true}
																		onDragStart={() => { setSelectedSectionType(section.id); setFromDragIndex(questionIndex); }}
																	>
																		<MinimizeViewOfSection
																			sectionDetails={question}
																			onRemoveSection={() => { setSectionToDelete({ index: questionIndex, showModal: true }); }}
																			onSelect={() => { setFocusedQuesionContainer(questionIndex); }}
																			error={questionError?.isValidate === false ? questionError : false}
																			questionIndex={questionIndex}
																		/>
																	</div>
															}
														</div>
													}
												</div>
											</Fragment>
										);
									})
								}
								{
									questions.length < 10 ?
										<EmptySection />
										:
										<div className='error-box mt-2'>
											<span className='body-4 text-danger'>
												You can only add 10 Qualifying Question at a time!
											</span>
										</div>
								}

							</div>
						</div>
						{/* <div className='create-assessment-secttion-options-container'>
							<div className='section-type-container'>
								{
									sectionsTypesToAdd.map((sectionType, index) => {
										const draggable = !isOnTabletScreen && questions.length < 10;
										return (
											<Fragment key={index}>
												{
													<Button
														onClick={
															() => {
																if (isOnTabletScreen) {
																	setSelectedSectionType(sectionType.id);
																}
															}
														}
														disabled={questions.length === 10}
														draggable={draggable}
														onDragEnd={async () => { addSections(); }}
														onDragStart={() => { setSelectedSectionType(sectionType.id); setFromDragIndex(-1); }}
														className={'draggable body-2 dark-50'} key={index} btn='in-section-btn'
														preIcon={sectionType.icon} title={sectionType.title}
														style={{ width: '100%', maxWidth: '278px', marginTop: index === 0 ? '0px' : '16px', cursor: `${draggable ? 'grab' : 'not-allowed'}` }} />
												}
											</Fragment>
										);
									})
								}
							</div>
						</div> */}
					</div>
					:
					<div className='create-assessment-secttion'>
						{
							!openSectionType.isOpen ?
								<div className='create-assessment-secttion-container'>
									<div className='create-assessment-secttion-container-header'>
										<div className='w-100 h-100 d-flex flex-column align-items-start' style={{ justifyContent: 'space-between' }}>
											<span className='headline-small semibold black-700'>{props?.section?.title}</span>
											<span className='body-2 black-500' style={{ marginTop: '8px', textAlign: 'left' }}>{props?.section?.subTitle}</span>
										</div>
										<div className='h-100 d-flex align-items-start justify-content-end' style={{ width: '30px' }}>
											<TrashIcon className="pointer" style={{ width: '24px', height: '24px' }} onClick={() => {
												props.onDelete();
											}} />
										</div>
									</div>
									<div className='add-section-container'>
										{
											questions.length > 0 && questions?.map((question, questionIndex) => {
												const questionError = errorQuestionIndexes.filter((ques, _) => { return (ques.questionIndex === questionIndex); })[0] || null;
												return (
													<Fragment key={questionIndex}>
														<div style={{ width: '100%' }} onDragLeave={() => { }}
															onDragOver={() => {
																const getAllAssignSections = questions?.filter((section, _) => { return section !== null; });
																if (questionIndex > getAllAssignSections.length - 1) {
																	setToDragIndex(-1);
																}
																else {
																	setToDragIndex(questionIndex);
																}
															}}>
															{
																<div className='w-100'>
																	{
																		focusedQuesionContainer === questionIndex
																			?
																			<SectionContainer
																				title={question.type === 'mcq' ? 'Multiple Choice Question' : question.type === 'numeric' ? 'Numeric' : 'True/False'}
																				description={question.type === 'mcq' ? 'Enter a multiple choice question' : question.type === 'numeric' ? 'Enter a question with a numeric answer' : 'Enter a question with a true/false answer'}
																				enableDuration={false}
																				onRemoveSection={() => { setSectionToDelete({ index: questionIndex, showModal: true }); }}
																				error={questionError}
																			>
																				{(questionError?.isValidate === false) && (
																					<div className='error-box mt-2'>
																						<span className='body-3 text-danger'>
																							{questionError?.message}
																						</span>
																					</div>
																				)}
																				<div key={questionIndex} className={`question-box position-relative `}>
																					<div className='question-box-question-section'>
																						<span className='body-2 black-700' style={{ marginBottom: '12px' }}>Question statement</span>
																						<Input varrient={'primary'} placeholder="Write your question here.." value={question.question} name="question"
																							onChange={(e) => {
																								onHandleChange(e, questionIndex);
																								isQuestionValidate(focusedQuesionContainer, true);
																							}}
																							onBlur={(e) => { props.getCurrentQQComponentData([...questions]); }}
																						/>
																						{
																							question.type === 'mcq'
																								?
																								<div className='d-flex flex-column align-items-start w-100' style={{ marginTop: '16px' }}>
																									{
																										question.options.length !== 0 &&
																										<span className='body-2 black-700'>Choose one right answer<span className='danger-text'>*</span></span>
																									}
																									{
																										<div className='w-100'>
																											{
																												question?.options.map((option, index) => {
																													return (
																														<div key={index} className="w-100 d-flex align-items-center" style={{ marginTop: '12px' }}>
																															<Radio
																																style={{ background: index === question.idealAnswer ? '#ECEDF1' : '#F6F5F8' }}
																																varrient="radio-with-input"
																																onChangeRadioOption={() => {
																																	const _questions = questions;
																																	_questions[questionIndex] = {
																																		..._questions[questionIndex],
																																		idealAnswer: index,
																																	};
																																	setQuestions([..._questions]);
																																	props.getCurrentQQComponentData([..._questions]);
																																}}
																																checked={index === question.idealAnswer}
																																onBlur={(e) => { props.getCurrentQQComponentData([...questions]); }}
																																readOnly={false}
																																disabled={false}
																																radioValue={index}
																																value={questions[questionIndex].options[index]}
																																placeholder="Write your answer here.."
																																onChange={(e) => {
																																	onHandleOptionChange(e, questionIndex, index);
																																	isQuestionValidate(focusedQuesionContainer, true);
																																}}
																															/>
																															{
																																question.options.length > 2 &&
																																<DeleteMCQsIcon
																																	className="pointer"
																																	key={index}
																																	style={{ width: '24px', height: '24px', marginLeft: '16px' }}
																																	onClick={() => {
																																		const _questions = questions;
																																		_questions[questionIndex].options = _questions[questionIndex].options.filter((_, optionIndex) => optionIndex !== index);
																																		setQuestions([..._questions]);
																																		props.getCurrentQQComponentData([..._questions]);
																																		props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: _questions });
																																	}}
																																/>
																															}
																														</div>
																													);
																												})
																											}
																											<div className='w-100 d-flex justify-content-start align-items-center pointer' style={{ marginTop: '15.5px' }} onClick={() => { onAddingMCQsOption(questionIndex); }}  >
																												<PlusIcon style={{ width: '24px', height: '24px', stroke: '#121216' }} />
																												<span className='body-3 medium primary-text' style={{ marginLeft: '4px' }}> Add option</span>
																											</div>

																										</div>
																									}
																								</div>
																								:
																								<div className='question-answer-selection-container' style={{ marginTop: '16px' }}>
																									<div className='w-100 d-flex flex-row align-items-center'>
																										<div style={{ minWidth: 'fit-content', marginRight: '16px' }}>
																											<span className='body-2 black-700 d-flex' style={{ fontWeight: '300' }}>Response Type</span>
																										</div>
																										<Select readOnly
																											options={[{ name: 'Numeric', value: 'numeric' }, { name: 'True/False', value: 'boolean' }]}
																											className="question-select-responsive"
																											selected={question.type}
																											inputStyle={{ fontWeight: '300' }}
																											onSelection={(option) => {
																												const _questions = questions;
																												_questions[questionIndex] = {
																													..._questions[questionIndex],
																													type: option.value,
																													idealAnswer: null,
																													candidateAnswer: null,
																												};
																												setQuestions([...questions]);
																												props.onConfirm({ ...props.assessmentSpecsDetails, qualifying_questions: _questions });
																												props.getCurrentQQComponentData([..._questions]);
																												isQuestionValidate(focusedQuesionContainer, true);
																											}}
																										/>
																									</div>
																									{
																										question.type === 'numeric'
																											?
																											<div className='w-100 d-flex flex-row align-items-center'>
																												<div style={{ minWidth: 'fit-content', marginRight: '16px' }}>
																													<span className='body-2 black-700 d-flex' style={{ fontWeight: '300' }}>Ideal answer<span className='danger-text'>*</span></span>
																												</div>
																												<Input type={'number'} varrient={'primary'} placeholder="Write your Ideal answer here.." value={question?.idealAnswer || ''} name="idealAnswer"
																													onChange={(e) => {
																														onHandleChange(e, questionIndex, 'idealAnswer');
																														isQuestionValidate(focusedQuesionContainer, true);
																													}}
																													inputContainerClass="question-input-responsive"
																													onBlur={(e) => { props.getCurrentQQComponentData([...questions]); }}
																												/>
																											</div>
																											:
																											<div className='w-100 d-flex flex-row align-items-center'>
																												<div style={{ minWidth: 'fit-content', marginRight: '16px' }}>
																													<span className='body-2 black-700 d-flex' style={{ fontWeight: '300' }}>Ideal answer<span className='danger-text'>*</span></span>
																												</div>
																												<Select readOnly options={[{ name: 'True', value: true }, { name: 'False', value: false }]}
																													className="question-select-responsive select-ideal-answer"
																													selected={question.idealAnswer}
																													onSelection={(option) => {
																														onHandleSelect(option, questionIndex, 'idealAnswer');
																														isQuestionValidate(focusedQuesionContainer, true);
																													}}
																												/>
																											</div>
																									}
																								</div>
																						}
																					</div>
																				</div>
																			</SectionContainer>
																			:
																			<div
																				className='w-100'
																				onDragEnd={() => { replaceSectionPositions(); }}
																				draggable={true}
																				onDragStart={() => { setSelectedSectionType(section.id); setFromDragIndex(questionIndex); }}
																			>
																				<MinimizeViewOfSection
																					sectionDetails={question}
																					onRemoveSection={() => { setSectionToDelete({ index: questionIndex, showModal: true }); }}
																					onSelect={() => { setFocusedQuesionContainer(questionIndex); }}
																					error={questionError?.isValidate === false ? questionError : false}
																					questionIndex={questionIndex}
																				/>
																			</div>
																	}
																</div>
															}
														</div>
													</Fragment>
												);
											})
										}
										{
											questions.length < 10 ?
												<EmptySection />
												:
												<div className='error-box mt-2'>
													<span className='body-4 text-danger'>
														You can only add 10 Qualifying Question at a time!
													</span>
												</div>
										}
									</div>
								</div>
								:
								(openSectionType.type === 'qualifying_questions' && openSectionType.isOpen) &&
								<div className='create-assessment-secttion-options-container'>
									<div className='section-type-container'>
										{
											sectionsTypesToAdd.map((sectionType, index) => {
												const draggable = !isOnTabletScreen && questions.length < 10;
												return (
													<Fragment key={index}>
														{
															<Button
																onClick={
																	() => {
																		setSelectedSectionType(sectionType.id);
																		setOpenSectionType({ type: null, isOpen: false });
																	}
																}
																disabled={questions.length === 10}
																draggable={draggable}
																onDragEnd={async () => { addSections(); }}
																onDragStart={() => { setSelectedSectionType(sectionType.id); setFromDragIndex(-1); }}
																className={'draggable'} key={index} btn='in-section-btn'
																preIcon={sectionType.icon} title={sectionType.title}
																style={{ width: '100%', maxWidth: '278px', marginTop: index === 0 ? '0px' : '16px', cursor: `${draggable ? 'grab' : 'not-allowed'}` }} />
														}
													</Fragment>
												);
											})
										}
									</div>
								</div>
						}
					</div>
			}
		</>

	);
}