import { createSlice } from '@reduxjs/toolkit';
import { setLatestErrorId } from '../thunks/ErrorBoundary';

const ErrorBoundarySlice = createSlice({
	name: 'user',
	initialState: {
		loading: false,
		processing: false,
		data: null,
		lastError: null,
		error_message: null,
		success_message: null,
	},
	reducers: {},
	extraReducers: {
		[setLatestErrorId.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[setLatestErrorId.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				lastError: {
					eventId: payload?.eventId,
					error: `${payload?.error}`,
					componentStack: `${payload?.componentStack}`,
					resetError: payload?.resetError?.toString()
				}
			};
		},
		[setLatestErrorId.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},
	},
});

export default ErrorBoundarySlice.reducer;
