import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssessmentSpecStatus, AssessmentStatus, UserRole, fullBlock, inviteBlock } from '../utils/Constants';
import CandidateAssessFlow from './candidate-assessment-flow/CandidateAssessFlow';
import TestDetails from './dashboard/test-details/TestDetails';
import AntiCheatContextWrapper from '../utils/contexts/candidate-flow-contexts/AntiCheatContext';
import AssessmentConcluded from './candidate-assessment-flow/Assessment-concluded/AssessmentConcluded';
import { getUser } from '../redux/thunks/User';
import { getAssessment } from '../redux/thunks/Assessment';
import UnavailableAssessment from './UnavailableAssessment';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function AssessmentDetailsPage(props) {

	const auth = useSelector((state) => state.authReducer);
	
	const dispatch = useDispatch();
	const {id} = useParams();

	const [isLoading, setIsLoading] = useState(true);
	const assessment = useSelector((state) => state.assessmentReducer);
	const savedIsReviewSubmit = JSON.parse(localStorage.getItem('isReviewSubmit'));
	useEffect(() => {
		if (auth.data?.role && auth.data.role.name === UserRole.CANDIDATE) {
			dispatch(getAssessment({ id: id }));
		}
	}, [auth.data]);
	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsLoading(false); 
		}, 2000);

		return () => clearTimeout(timeout); 

	}, []);
	
	if (auth.data?.role && auth.data.role.name === UserRole.CANDIDATE) {
		return <>
			{isLoading ? (
				<>
					<div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<div>
							<div className="processing-indicator primary" style={{ width: '40px', height: '40px' }} />
						</div>
					</div>
				</>
			) : (
				(assessment?.current_assessment?.status === AssessmentStatus.IN_PROGRESS || assessment?.current_assessment?.status === AssessmentStatus.FINISHED) && assessment?.current_assessment?.spec_status !== 'ARCHIVED'  || (!inviteBlock.includes(assessment?.current_assessment?.tenant?.status) && !fullBlock.includes(assessment?.current_assessment?.tenant?.status) && assessment?.current_assessment?.status === AssessmentStatus.PENDING ) ? (
					assessment?.current_assessment?.status === AssessmentStatus.FINISHED && savedIsReviewSubmit === true  ? (
						<AssessmentConcluded />
					) : (
						<AntiCheatContextWrapper>
							<CandidateAssessFlow />
						</AntiCheatContextWrapper>
					)
				) : (
					<>
						<UnavailableAssessment />
					</>
				)
			)}
		</>;
	} else {
		return <TestDetails setSideMenuExpand={props.setSideMenuExpand} />;
	}

}

