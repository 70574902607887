import { createSlice } from '@reduxjs/toolkit';
import { getNotification, patchNotificationStatus, patchMarkAllRead, getEmailTemplate, postEmailPreview } from '../thunks/Notification';

const NotificationSlice = createSlice({
  name: 'Notification Slice',
  initialState: {
    loading: false,
    processing: false,
    data: null,
    notificationData: [],
    current_notification: null,
    processing_patch_noti_status: false,
    error_message: null,
    success_message: null,
  },
  reducers: {
    resetNotificationData: (state) => {
      state.notificationData = [];
    },
  },


  extraReducers: {
    [getNotification.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [getNotification.fulfilled]: (state, { payload }) => {
      const uniqueNewNotifications = payload.data.filter(
        newNotif => !state.notificationData.some(
          existingNotif => existingNotif.id === newNotif.id
        )
      );

      return {
        ...state,
        notificationData: payload.page === 1
          ? payload.data  // Replace data if it's the first page
          : [...state.notificationData, ...uniqueNewNotifications],  // Append only unique data
        totalRecords: payload.totalRecords,
        loading: false,
      };
    },


    [getNotification.rejected]: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    [patchNotificationStatus.pending]: (state) => {
      return {
        ...state,
        processing_patch_noti_status: false,
      };
    },
    [patchNotificationStatus.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        processing_patch_noti_status: true,
        current_notification: payload,
      };
    },
    [patchNotificationStatus.rejected]: (state, { error }) => {
      return {
        ...state,
        error_message: error?.message,
      };
    },

    [patchMarkAllRead.pending]: (state) => {
      return {
        ...state,
        processing: true,
      };
    },
    [patchMarkAllRead.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        processing: false,
        success_message: payload?.message,
      };
    },
    [patchMarkAllRead.rejected]: (state, { error }) => {
      return {
        ...state,
        error_message: error?.message,
        processing: false,
      };
    },
  },
});
export const { resetNotificationData } = NotificationSlice.actions;
export default NotificationSlice.reducer;
