import { createSlice } from '@reduxjs/toolkit';
import { listQuesPrompt } from '../thunks/QuesPrompt';

const QuesPrompt = createSlice({
	name: 'Quest Prompt',
	initialState: {
		loading: false,
		processing: false,
		data: null,
		error_message: null,
		success_message: null,
	},

	extraReducers: {
		[listQuesPrompt.pending]: (state) => {

			return {
				...state,
				loading: true,
			};

		},

		[listQuesPrompt.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				data: payload
			};
		},

		[listQuesPrompt.rejected]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				error_message: payload
			};
		}
	}

});

export default QuesPrompt.reducer;