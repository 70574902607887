import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TestpackProgressInfoHeader from './flow-steps/TestpackProgressInfoHeader';
import { getPublicTestPack } from '../../redux/thunks/PublicTestpack';
import SkillBasedTestPQ from './flow-steps/tests/SkillBasedTestPQ';
import CodingTestPQ from './flow-steps/tests/CodingTestPQ';
import TestContentWrapper from '../candidate-assessment-flow/flow-steps/tests/TestContentWrapper';
import { ReactComponent as TestFuseWatermarkIcon } from '../../assets/icons/candidate-assessment/Watermark.svg';

export default function TestpackPreviewQuestions() {

	const dispatch = useDispatch();
	const search = useLocation().search;
	const test_id = new URLSearchParams(search).get('test_id');

	const testpack = useSelector((state) => state.publicTestpackReducer);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const preview_questions = testpack?.current_testpack?.preview_questions;

	const active_question = preview_questions && preview_questions[currentQuestionIndex];


	const handleNext = () => {
		setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
	};

	const handlePrev = () => {
		setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
	};

	const isFirstQuestion = currentQuestionIndex === 0;
	const isLastQuestion = preview_questions && currentQuestionIndex === preview_questions.length - 1;


	useEffect(() => {
		dispatch(getPublicTestPack({ id: test_id }));
	}, [dispatch]);


	return (
		<div className='testpack-preview-questions-container'>
			<div className='d-flex flex-column w-100 align-items-center justify-content-center align-self-start' style={{gap: '32px'}}>
				<TestpackProgressInfoHeader
					title={testpack?.current_testpack?.title}
					totalQuestions={ preview_questions && preview_questions?.length}
					currentQuestionNo={currentQuestionIndex}
				/>
				<div className='testpack-preview-questions-disclaimer'>
					<span className='body-2 black-600 text-start'>
						These are only preview questions, similar questions will be given to the candidate in the actual test pack. These preview questions will not be displayed to the candidates.
					</span>
				</div>
				{ preview_questions ?
					<div className='w-100 d-flex align-items-center justify-content-center' style={{marginBottom: '50px'}}>
						{ testpack?.current_testpack?.test_pack_type === 'coding_test_pack' ?
							<CodingTestPQ
								question={active_question}
								testId={testpack?.current_testpack?.id}
								isFirstQuestion={isFirstQuestion}
								isLastQuestion={isLastQuestion}
								handleNext={handleNext}
								handlePrev={handlePrev}
							/>
							:
							<SkillBasedTestPQ
								active_question={active_question}
								isFirstQuestion={isFirstQuestion}
								isLastQuestion={isLastQuestion}
								handleNext={handleNext}
								handlePrev={handlePrev}
								
							/>
						}
					</div>
					:
					<div className='w-100 d-flex align-items-center justify-content-center' style={{marginBottom: '50px'}}>
						<TestContentWrapper />
					</div>
				}
			</div>
			<div className='watermark' style={{marginBottom: '100px'}}>
				<TestFuseWatermarkIcon style={{ height: '50px' }} />
			</div>
		</div>
	);
}
