import React, { useState } from 'react';
import { ReactComponent as ErrorImg } from '../assets/images/dashboard/Error/error.svg';
import Button from '../components/sub-component/Button';
import { PathName } from '../utils/routes';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import CustomModal from '../components/common/Modal';
import TextArea from '../components/sub-component/TextArea';
import AlertBox from '../components/common/AlertBox';
import { useMediaQuery } from '@mui/material';

export default function ErrorPage() {
	const history = useHistory();
	const [isReportModalOpen, setIsReportModalOpen] = useState(false);
	const [alert, setAlert] = useState(null);
	const [formValues, setFormValues] = useState({ message: '', });
	const error = useSelector((state) => state.errorBoundaryReducer);
	const auth = useSelector((state) => state.authReducer);
	const isMobile = useMediaQuery('(max-width: 767px)');
	const onChangeHandler = (event) => {
		const { name, value } = event.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const handleSubmit = () => {
		Sentry.captureMessage(error?.error, {
			extra: { email: auth?.data?.email, description: formValues.message },
			event_id: error?.eventId || Sentry.lastEventId(),
		});
		setAlert(<AlertBox
			color='success'
			description={'Your Report sent Successfully!'}
			title='Success'
			isOpen={true}
			onCloseAlert={() => { setAlert(null); }}
		/>);
		setIsReportModalOpen(false);
	};


	return (
		<>
			{
				alert
			}
			{
				<CustomModal
					open={isReportModalOpen}
					handleCloseModal={() => { setIsReportModalOpen(false); }}
					confirmBtnTitle={'Submit'}
					onConfirm={() => { handleSubmit(); }}
				>
					<div className="modal-header d-flex flex-column">
						<span className="headline-medium semibold black-700">Report Issue</span>
					</div>
					<div className='w-100 d-flex flex-column align-items-start mt-4' style={{ gap: '5px' }}>
						<label className=''>Summary</label>
						<TextArea
							inputStyle={{ width: '700px', height: '265px', maxHeight: '265px' }}
							className=''
							placeholder='Issue summary'
							name='message'
							value={formValues.message}
							onChange={onChangeHandler}
						/>
					</div>
				</CustomModal>
			}
			<div className='error-page-view-container'>
				<div className='d-flex flex-column' style={{ gap: '24px' }}>
					<ErrorImg width={isMobile ? 300 : 500} height={isMobile ? 216 : 373} />
					<div className='d-flex flex-column align-items-center' style={{ gap: '16px' }}>
						<span className='headline-medium semibold black-700'>Something went wrong</span>
						<span className={`body-${isMobile ? "1" : "2"} black-500`}>Please Try again or report an issue</span>
					</div>
				</div>
				<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
					<Button
						btn='ragular-btn'
						varrient='secondary'
						title={'Report an Issue'}
						className='btn-md'
						style={{ border: 'none', backgroundColor: '#F9F9FC' }}
						onClick={() => { setIsReportModalOpen(true); }}
					/>
					<Button
						btn='ragular-btn'
						varrient='primary'
						title={'Go to Dashboard'}
						className='btn-md'
						onClick={() => {
							localStorage.clear();
							history.push(`${PathName.ASSESSMENTS}`);
							window.location.reload();
						}}
					/>
				</div>
			</div>
		</>
	);
}
