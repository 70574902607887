import React from 'react';
import { ReactComponent as ZapierLogo } from '../assets/icons/zapier.svg';
import Button from '../components/sub-component/Button';
// import Tick from '../assets/icons/onboarding/checkmark.png';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { oauthAuthorization } from '../redux/thunks/OAuth';
import { handleMenuVisibility } from '../redux/slices/AuthSlice';
import { useEffect } from 'react';
import { UserRole } from '../utils/Constants';
import ProcessingIndicator from '../components/common/ProcessingIndicator';

export default function OAuthScreen(props) {
	const dispatch = useDispatch();
	const location = useLocation();

	const auth = useSelector((state) => state.authReducer);

	useEffect(() => {
		dispatch(handleMenuVisibility(false));
	}, []);

	const handleAllowAccessClicked = () => {
		dispatch(oauthAuthorization(location.search));
	};

	if (auth.loading) {
		return <ProcessingIndicator />;
	}

	return (
		<div
			className="d-flex flex-column justify-content-between w-100"
			style={{
				maxWidth: '582px',
				padding: '24px 48px',
				boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
				borderRadius: '6px',
				// marginTop: '132px',
				margin: '132px auto 0',
			}}
		>
			<div className="d-flex justify-content-center">
				<div className="oauth-card">
					<div>
						<ZapierLogo />
					</div>
					<div style={{ padding: ' 25px 0px 40px 0px' }}>
						<span className="p1">
							Zapier wants to access your Testfuse account
						</span>
					</div>
					{/* {(auth.data?.role && auth.data.role.name === UserRole.CANDIDATE) ? ( */}
					{([auth.data?.role && UserRole.ADMIN, UserRole.OWNER].includes(auth.data?.role?.name)) ? (
						<>
							{/* <div>Company Name goes here</div> */}
							<div className="d-flex" style={{ marginTop: '25px' }}>
								<span className="headline3">Zapier will be able to:</span>
							</div>
							<div className="d-flex flex-column">
								<div className="d-flex" style={{ margin: '5px 10px' }}>
									{/* <img
								src={Tick}
								alt=""
								style={{ width: '25px', height: '25px' }}
							/> */}
									<span
										className="circle-check-icon icon-success"
										style={{ marginRight: '10px' }}
									/>
									<div>
										<span className="description">
											{props.access || 'Create or Delete Assessments.'}
										</span>
									</div>
								</div>
								<div className="d-flex" style={{ margin: '5px 10px' }}>
									<span
										className="circle-check-icon icon-success"
										style={{ marginRight: '10px' }}
									/>
									<div>
										<span className="description">
											{props.access || 'Invite candidates for an Assessment.'}
										</span>
									</div>
								</div>
								<div className="d-flex" style={{ margin: '5px 10px' }}>
									<span
										className="circle-check-icon icon-success"
										style={{ marginRight: '10px' }}
									/>
									<div>
										<span className="description">
											{props.access || 'Shortlist candidates.'}
										</span>
									</div>
								</div>
							</div>
							<div style={{ marginTop: '60px', textAlign: 'left' }}>
								<span className="description" style={{ textAlign: 'left' }}>
									By clicking on allow access, you authorize Zapier to use your
									information in accordance with its{' '}
									<span style={{ color: '#00B4D8' }}><a href='https://zapier.com/legal/data-privacy' rel='noopener noreferrer' target='_blank'>privacy policy</a></span>.
								</span>
							</div>
							<div
								className="d-flex justify-content-end"
								style={{ marginTop: '24px' }}
							>
								{/* <div style={{ width: '150px', height: '40px' }}> */}
								<Button
									btn="ragular-btn"
									title={'Cancel'}
									onClick={window.close}
									style={{
										width: '150px',
										height: '40px',
										marginRight: '16px',
									}}
								/>

								<Button
									btn="ragular-btn"
									varrient="primary"
									title={'Allow Access'}
									onClick={handleAllowAccessClicked}
									style={{ width: '150px', height: '40px' }}
								/>
								{/* </div> */}
							</div>
						</>
					) : (
						<>
							<div className="d-flex" style={{ marginTop: '25px' }}>
								<span className="headline3">Insufficient Permissions</span>
							</div>
							<div style={{ textAlign: 'left' }}>
								<span className="description" style={{ textAlign: 'left' }}>
									Oops! your account doesn’t support the action that you’re
									trying tp perform. Try{' '}
									<span style={{ color: '#00B4D8' }}>signing</span> in with
									another account
								</span>
							</div>
							<div
								className="d-flex justify-content-end"
								style={{ marginTop: '24px' }}
							>
								<Button
									btn="ragular-btn"
									title={'Cancel'}
									onClick={window.close}
									style={{
										width: '150px',
										height: '40px',
										marginRight: '16px',
									}}
								/>
							</div>
						</>
					)}
				</div>
			</div>
			<div></div>
		</div>
	);
}
