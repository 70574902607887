import { createSlice } from '@reduxjs/toolkit';
import { Judge0Status } from '../../utils/Constants';
import {
	answerTestQuestion,
	createAssessment,
	verifyCodeSnapShot,
	saveCodeSnapShot,
	createTest,
	getNextActiveTest,
	getTestResultPrev,
	patchTestWithPrevResult,
	patchPublicLinkState,
	createTestQuestion,
	executeResult,
	resetExecResultState,
	getAssessment,
	getCodelanguage,
	getTest,
	listAssessments,
	patchAssessment,
	patchAssessmentHiringStage,
	getCodingTestSnapShots,
	createSession,
	sessionHeartBeat,
	takeOverSession,
	resetAssessmentReducerState,
} from '../thunks/Assessment';

const AssessmentSlice = createSlice({
	name: 'Assessment Slice',
	initialState: {
		loading: false,
		processing: false,
		data: null,
		test_result_prev: null,
		processing_test_result_prev: false,
		shared_publicly: false,

		current_assessment: null,
		loading_current_assess: false,
		processing_patch_assess: false,
		processing_answer_question: false,
		processing_current_question: false,

		processing_code_snapshot: false,
		code_snapshot: null,

		processing_exec_result: false,
		exec_result_api_success: false,
		exec_result: null,

		error_message: null,
		success_message: null,
		code_language: null,

		creating_session_loading: false,
		takeover_session: undefined
	},

	reducers: {
		setSwitchState(state, action) {
			state.shared_publicly = action.payload;
		},
	},

	extraReducers: {
		[createAssessment.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[createAssessment.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				current_assessment: payload,
			};
		},
		[createAssessment.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[patchAssessment.pending]: (state) => {
			return {
				...state,
				processing_patch_assess: true,
			};
		},
		[patchAssessment.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_patch_assess: false,
				current_assessment: payload,
			};
		},
		[patchAssessment.rejected]: (state) => {
			return {
				...state,
				processing_patch_assess: false,
			};
		},

		[patchAssessmentHiringStage.pending]: (state) => {
			return {
				...state,
				processing_patch_assess: true,
			};
		},
		[patchAssessmentHiringStage.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_patch_assess: false,
				current_assessment: payload,
			};
		},
		[patchAssessmentHiringStage.rejected]: (state) => {
			return {
				...state,
				processing_patch_assess: false,
			};
		},

		[listAssessments.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[listAssessments.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				data: payload,
			};
		},
		[listAssessments.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

		[getAssessment.pending]: (state) => {
			return {
				...state,
				loading_current_assess: true,
			};
		},
		[getAssessment.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading_current_assess: false,
				current_assessment: payload,
			};
		},
		[getAssessment.rejected]: (state) => {
			return {
				...state,
				loading_current_assess: false,
			};
		},

		[createTest.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[createTest.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				current_assessment: payload,
				current_test: payload,
			};
		},
		[createTest.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[getNextActiveTest.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[getNextActiveTest.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				current_assessment: {
					...state.current_assessment,
					active_test: payload,
				},
			};
		},
		[getNextActiveTest.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

		[getTestResultPrev.pending]: (state) => {
			return {
				...state,
				processing_test_result_prev: true,
			};
		},
		[getTestResultPrev.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_test_result_prev: false,
				current_assessment: {
					...state.current_assessment,
					test_result_prev: payload,
				},
			};
		},
		[getTestResultPrev.rejected]: (state) => {
			return {
				...state,
				processing_test_result_prev: false,
			};
		},

		[patchTestWithPrevResult.pending]: (state) => {
			return {
				...state,
				processing_patch_assess: true,
			};
		},
		[patchTestWithPrevResult.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_patch_assess: false,
				current_assessment: payload,
			};
		},
		[patchTestWithPrevResult.rejected]: (state) => {
			return {
				...state,
				processing_patch_assess: false,
			};
		},

		[patchPublicLinkState.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[patchPublicLinkState.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				current_assessment: payload,
			};
		},
		[patchPublicLinkState.rejected]: (state) => {
			return {
				...state,
				processing: false,
				shared_publicly: !state.current_assessment.shared_publicly,
			};
		},

		[getTest.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[getTest.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				current_assessment: payload,
				current_test: payload,
				current_question: payload.active_question,
			};
		},
		[getTest.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

		[createTestQuestion.pending]: (state) => {
			return {
				...state,
				processing_current_question: true,
			};
		},
		[createTestQuestion.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_current_question: false,
				current_assessment: payload,
				current_question: payload,
			};
		},
		[createTestQuestion.rejected]: (state) => {
			return {
				...state,
				processing_current_question: false,
			};
		},

		[answerTestQuestion.pending]: (state) => {
			return {
				...state,
				processing_answer_question: true,
			};
		},
		[answerTestQuestion.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_answer_question: false,
				current_assessment: payload,
				current_test: null,
				current_question: null,
				exec_result: null,
			};
		},
		[answerTestQuestion.rejected]: (state) => {
			return {
				...state,
				processing_answer_question: false,
			};
		},

		[verifyCodeSnapShot.pending]: (state) => {
			return {
				...state,
				processing_code_snapshot: true,
				exec_result: null,
				exec_result_api_success: false
			};
		},
		[verifyCodeSnapShot.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_code_snapshot: false,
				code_snapshot: payload,
			};
		},
		[verifyCodeSnapShot.rejected]: (state) => {
			return {
				...state,
				processing_code_snapshot: false,
			};
		},

		[saveCodeSnapShot.pending]: (state) => {
			return {
				...state,
			};
		},
		[saveCodeSnapShot.fulfilled]: (state) => {
			return {
				...state,
			};
		},
		[saveCodeSnapShot.rejected]: (state) => {
			return {
				...state,
			};
		},

		[executeResult.pending]: (state) => {
			return {
				...state,
				processing_exec_result: true,
			};
		},
		[executeResult.fulfilled]: (state, { payload }) => {
			if (!payload.submissions || payload.submissions?.length < payload.tokens?.length) {
				return {
					...state,
					exec_result: payload,
				};
			}
			return {
				...state,
				processing_exec_result: false,
				exec_result_api_success: true,
				exec_result: payload,
				code_snapshot:
					payload.submissions?.length >= payload.tokens?.length
						? null
						: state.code_snapshot,
			};
		},
		[executeResult.rejected]: (state) => {
			return {
				...state,
				processing_exec_result: false,
			};
		},

		[resetExecResultState.pending]: (state) => {
			return {
				...state,
			};
		},
		[resetExecResultState.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_exec_result: false,
				code_snapshot: null,
			};
		},
		[resetExecResultState.rejected]: (state) => {
			return {
				...state,
			};
		},

		[getCodelanguage.fulfilled]: (state, { payload }) => {
			return {
				...state,
				code_language: payload,
			};
		},

		[getCodingTestSnapShots.pending]: (state) => {
			return {
				...state,
				processing_code_snapshot: true,
			};
		},
		[getCodingTestSnapShots.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_code_snapshot: false,
				code_snapshot: payload,
			};
		},
		[getCodingTestSnapShots.rejected]: (state) => {
			return {
				...state,
				processing_code_snapshot: false,
			};
		},

		[createSession.pending]: (state) => {
			return {
				...state,
				creating_session_loading: true,
			};
		},
		[createSession.fulfilled]: (state) => {
			return {
				...state,
				creating_session_loading: false,
				takeover_session: false
			};
		},
		[createSession.rejected]: (state, { payload }) => {
			return {
				...state,
				creating_session_loading: false,
				takeover_session: payload === 'An active session already exists!' ? false : true
			};
		},

		[sessionHeartBeat.pending]: (state) => {
			return {
				...state,
			};
		},
		[sessionHeartBeat.fulfilled]: (state) => {
			return {
				...state,
			};
		},
		[sessionHeartBeat.rejected]: (state, { payload }) => {
			return {
				...state,
				takeover_session: payload === 'An active session already exists!' ? false : true
			};
		},
		[takeOverSession.pending]: (state) => {
			return {
				...state,
			};
		},
		[takeOverSession.fulfilled]: (state) => {
			return {
				...state,
				takeover_session: false
			};
		},
		[takeOverSession.rejected]: (state) => {
			return {
				...state,
				takeover_session: undefined
			};
		},

		[resetAssessmentReducerState.pending]: (state) => {
			return {
				...state,
			};
		},
		[resetAssessmentReducerState.fulfilled]: (state, { payload }) => {
			return {
				...state,
				...payload
			};
		},
		[resetAssessmentReducerState.rejected]: (state) => {
			return {
				...state,
			};
		},
	},
});

export const { setSwitchState } = AssessmentSlice.actions;
export default AssessmentSlice.reducer;
