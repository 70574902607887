import { CaseStudyQuestionTypes, TestType } from '../../../../utils/Constants';

export const WelcomeVideoValidator = (welcome_video) => {
	if (welcome_video?.type) {
		if (welcome_video?.type === 'url') {
			if (welcome_video?.src?.includes('https://www.youtube.com') || welcome_video?.video_url?.includes('https://www.youtube.com')) {
				return { isValidate: true, message: 'success', required: null };
			}
			else {
				return { isValidate: false, message: 'A Youtube Link is required!', required: 'src' };
			}
		}
		else {
			if (welcome_video?.src || welcome_video?.video_url || welcome_video?.video_id) {
				return { isValidate: true, message: 'success', required: null };
			}
			else {
				return { isValidate: false, message: 'Upload a video with size 25MB!', required: 'src' };
			}
		}
	}
	else {
		return { isValidate: false, message: 'Choose atleast one way to show welcome video!', required: 'type' };
	}
};


export const QualifyingQuestionValidator = (questions, index) => {

	const question = questions[index];

	const idealAnswer = (question?.idealAnswer !== undefined) ? question?.idealAnswer : question?.correct_answer;
	const questionStatement = question?.question || question?.statement || null;

	if (!questionStatement || !question?.type || idealAnswer === null || !idealAnswer.toString()) {
		return { isValidate: false, message: 'All fields are required!', required: index };
	}

	if (question?.type === 'mcq' && question?.options) {
		if (question?.options.some(option => (option === '' || option === null) || (question?.options.length < 2))) {
			return { isValidate: false, message: 'All options are required!', required: index };
		}

		if (question?.options.filter((option, optIndex) => {
			return (
				question?.options.filter((_option, _) => { if (optIndex !== _ && option !== null) { return (option === _option); } }).length > 0
			);
		}).length > 0) {
			return { isValidate: false, message: 'options should be unique', required: index };
		}
	}
	return { isValidate: true, message: 'success', required: null };
};

export const AssessmentTestValidation = (test) => {
	if (test?.test_type === TestType.TEXT_TEST || test?.test_type === TestType.VIDEO_TEST || test?.test_type === TestType.UPLOAD_TEST) {
		if (test?.questions?.length > 0) {

			const errors = test?.questions?.map((q, i) => {
				let questionRequirements = { index: i };
				let question = '';
				if (q?.question !== undefined && q?.question !== null) {
					question = q?.question;
				}
				else if (q?.text !== undefined && q?.text !== null) {
					question = q?.text;
				}
				else {
					question = q;
				}


				if (!question || question === '') {
					questionRequirements = { ...questionRequirements, question_required: true };
				}

				if ((!q?.recording_type) && test?.test_type === TestType.VIDEO_TEST) {
					questionRequirements = { ...questionRequirements, video_mode_required: true };
				}

				if ((!q?.duration || q?.duration === 0) && test?.test_type !== TestType.UPLOAD_TEST) {
					questionRequirements = { ...questionRequirements, duration_required: true };
				}

				if (Object.keys(questionRequirements).length > 1) {
					return questionRequirements;
				}
			});
			if (errors.filter((e, _) => { return (e !== undefined); }).length > 0) {
				return { isValidate: false, message: 'Field required!', testType: test?.test_type, required: { questions: errors.filter((e, _) => { return (e !== undefined); }) } };
			}
			else {
				return { isValidate: true, message: 'success', required: null };
			}
		}
		else {
			return { isValidate: false, message: 'Field required!', testType: test?.test_type, required: { questions: [{ index: 0, question_required: true, duration_required: true }] } };
		}
	}
	else if (test?.test_type === TestType.CODING_TEST || test?.test_type === TestType.SKILL_TEST || test?.test_type === TestType.VALUE_TEST) {
		if (test?.questions.length !== 0) {
			return { isValidate: true, message: 'success', required: null };
		}
		else {
			return { isValidate: false, message: 'Atleast one should be selected', testType: test?.test_type, required: {} };
		}
	}
	else if (test?.test_type === TestType.CASE_STUDY_TEST) {

		let response = { isValidate: true, message: 'success', required: null };


		if (!test?.intro_text?.replace(/<[^>]+>/g, '')) {
			response = { ...response, testType: test?.test_type, isValidate: false, message: 'Field required!', intro_text_required: true };
		}

		if (!test?.duration) {
			response = { ...response, testType: test?.test_type, isValidate: false, message: 'Field required!', duration_required: true };
		}



		if (test?.questions?.length > 0) {
			const errors = test?.questions?.map((q, i) => {
				let questionRequirements = { index: i };
				const question = q?.question?.replace(/<[^>]+>/g, '') || q?.text?.replace(/<[^>]+>/g, '');

				if (!question || question === '') {
					questionRequirements = { ...questionRequirements, question_required: true };
				}

				if ((q?.type === CaseStudyQuestionTypes.VIDEO_RESPONSE || q?.question_type === CaseStudyQuestionTypes.VIDEO_RESPONSE) && !q?.recording_type) {
					questionRequirements = { ...questionRequirements, video_mode_required: true };
				}

				if (((q?.type === CaseStudyQuestionTypes.VIDEO_RESPONSE || q?.question_type === CaseStudyQuestionTypes.VIDEO_RESPONSE) && (!q?.duration || q?.duration === 0))) {
					questionRequirements = { ...questionRequirements, duration_required: true };
				}

				if (Object.keys(questionRequirements).length > 1) {
					return questionRequirements;
				}
			});
			if (errors.filter((e, _) => { return (e !== undefined); }).length > 0) {
				response = { ...response, isValidate: false, message: 'Field required!', testType: test?.test_type, required: { questions: errors.filter((e, _) => { return (e !== undefined); }) } };
			}
		}
		else {
			response = { ...response, isValidate: false, message: 'Field required!', testType: test?.test_type, required: { questions: [{ index: 0, question_required: true, duration_required: true }] } };

		}
		return response;
	} else if (test?.test_type === TestType.PERSONALITY_TEST) {
		let response = { isValidate: true, message: 'success', required: null };

		if (!test?.job_dimensions?.job_role || test?.job_dimensions?.job_role === '') {
			response = { ...response, testType: test?.test_type, isValidate: false, message: 'Field required!', job_role_required: true };
		}
		if (!test?.job_dimensions?.seniority || test?.job_dimensions?.seniority === '') {
			response = { ...response, testType: test?.test_type, isValidate: false, message: 'Field required!', seniority_required: true };
		}
		if (!test?.job_dimensions?.industry || test?.job_dimensions?.industry === '') {
			response = { ...response, testType: test?.test_type, isValidate: false, message: 'Field required!', industry_required: true };
		}
		if (!test?.job_dimensions?.job_location || test?.job_dimensions?.job_location === '') {
			response = { ...response, testType: test?.test_type, isValidate: false, message: 'Field required!', job_location_required: true };
		}
		return response;
	}

	// register('case_study_test.intro_text', { required: true });
	// register('case_study_test.customized_duration', { required: true });
	// setValue('case_study_test.intro_text', test?.intro_text);
	// setValue('case_study_test.customized_duration', test?.duration);
	// if (test?.questions?.length > 0) {
	// 	test?.questions.map((q, i) => {
	// 		register(`case_study_test.question${i}`, { required: true });
	// 		setValue(`case_study_test.question${i}`, q.question?.replace(/<[^>]+>/g, ''));
	// 		if (
	// 			(q?.question?.match(/<img([\w\W]+?)>/g) &&
	// 				q?.question?.match(/<img([\w\W]+?)>/g)[0]) ||
	// 			(q?.question?.match(/<video([\w\W]+?)>/g) &&
	// 				q?.question?.match(/<video([\w\W]+?)>/g)[0])
	// 		) {
	// 			setValue(`case_study_test.question${i}`, q?.question?.replace(/<[^>]+>/g, '') + 'image|video');
	// 		}
	// 		if (q.question_type === CaseStudyQuestionTypes.VIDEO_RESPONSE) {
	// 			register(`case_study_test.question_duration${i}`, { required: true });
	// 			setValue(`case_study_test.question_duration${i}`, q.duration);
	// 		}
	// 	});
	// }
	// else {
	// 	register(`case_study_test.question0`, { required: true });
	// 	setValue(`case_study_test.question0`, '');
	// }
};