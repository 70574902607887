import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { ReactComponent as BackArrowIcon } from '../../../assets/icons/Views/dashboard/test-detail/left-arrow.svg';

import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/Views/dashboard/assessments/arrow-left.svg';
import { ReactComponent as DefaultAssessmentIcon } from '../../../assets/icons/Views/dashboard/assessments/Entity avatar.svg';
import UploadIcon from '../../../assets/icons/Views/dashboard/assessments/Group 13693.png';
import { ReactComponent as EditIcon } from '../../../assets/icons/Views/dashboard/assessments/edit-3.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/Views/dashboard/assessments/check.svg';
import { ReactComponent as CloudIcon } from '../../../assets/icons/Views/dashboard/assessments/cloud.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/Views/dashboard/assessments/clock.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/Views/dashboard/assessments/info-1.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/Arrows/Publish.svg';
import { ReactComponent as PublishAssessmentIcon } from '../../../assets/icons/assessment-creation/upload.svg';
import { ReactComponent as OpenAIIcon } from '../../../assets/icons/Views/dashboard/assessments/chat gpt.svg';
import { ReactComponent as AlertIcon } from '../../../assets/icons/Views/dashboard/create-assessment/alert-triangle.svg';
import { ReactComponent as CheckIconv2 } from '../../../assets/icons/assessment-creation/CheckCircle.svg';
import { ReactComponent as TimerIcon } from '../../../assets/icons/Views/dashboard/assessments/HourglassMedium.svg';
import ProcessingIndicator from '../../../components/common/ProcessingIndicator';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Input from '../../sub-component/Input';
// import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Icon, useMediaQuery } from '@mui/material';
import Button from '../../sub-component/Button';
import AvtarBadge, { GetAvtarImage } from '../AvtarBadge';
import CustomModal from '../../common/Modal';
import { createResource, deleteResource } from '../../../redux/thunks/Resource';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusWithin } from '@react-aria/interactions';
import { secondsTohhmm } from '../../../utils/utilities';
import CustomTooltip from '../../common/CustomTooltip';
import Select from '../../sub-component/Select';
import SetAssessmentIcon from './SetAssessmentIcon';


export default function AssessmentCreationHeader(props) {

	const uploadIconRef = useRef(null);
	const [isEditingTitle, setIsEditingTitle] = useState(false);
	const [assessmentTitle, setAssessmentTitle] = useState(props.assessment?.title);
	const [isTitleInvalid, setIsTitleInvalid] = useState(false);
	const [fileType, setFileType] = useState('');
	const [isUploadIconClicked, setIsUploadIconClicked] = useState(false);
	const [loading, setLoading] = useState(false);

	const [onTabAddSectionStepNo, setOnTabAddSectionStepNo] = useState(props.onTabAddSectionStepNo);

	const assessSpec = useSelector((state) => state.assessSpecReducer);
	const resource = useSelector((state) => state.resourceReducer);


	// const [totalDuration, setTotalDuration] = useState(props.totalDuration || '0:00');

	// const dispatch = useDispatch();

	// const assessSpec = useSelector((state) => state.assessSpecReducer);
	const isOnWeb = useMediaQuery('(min-width:1640px)');
	const isMobile = useMediaQuery('(max-width:767px)');
	const isOnTablet = useMediaQuery('(min-width: 768px) and (max-width: 1199px)');
	const isOn1540PX = useMediaQuery('(min-width: 1540px) and (max-width: 1639px)');
	const isOn1440PX = useMediaQuery('(min-width: 1440px) and (max-width: 1539px)');
	const isOn1340PX = useMediaQuery('(min-width: 1340px) and (max-width: 1439px)');




	const history = useHistory();
	const dispatch = useDispatch();

	const maxCharacterLimit = 128;

	const handleTitleChange = (event) => {
		const inputValue = event.target.value;

		if (inputValue.length <= maxCharacterLimit) {
			setAssessmentTitle(inputValue);
		}
	};
	const handleUploadIconClick = () => {
		setIsUploadIconClicked(prevState => !prevState);
	};
	const handleCloseSetAssessment = () => {
		setIsUploadIconClicked(false);
	};
	const handleOnClickEdit = () => {

		if (assessmentTitle === null || assessmentTitle === '') {
			setIsTitleInvalid(true);
			return;
		} else {
			setIsTitleInvalid(false);
		}

		setIsEditingTitle(!isEditingTitle);


		if (props.onConfirmTitle && isEditingTitle) {
			props.onConfirmTitle(assessmentTitle);
		}

	};

	const skillTestSection = assessSpec?.curr_assessment_spec?.sections?.find(section => section.type === 'skill_test' || section.type === 'coding_test');
	const valueTestSection = assessSpec?.curr_assessment_spec?.sections?.find(section => section.type === 'value_test');
	const personalityTestSection = assessSpec?.curr_assessment_spec?.sections?.find(section => section.type === 'personality_test');

	const isFirstStep = props.pathname === '/';
	const isSecondStep = props.pathname === '/second';
	const isThirdStep = props.pathname === '/third';

	const changeHandler = async (event, isUpdate, Component, type) => {

		if (isUpdate && assessSpec?.curr_assessment_spec?.avatar_id) {
			dispatch(deleteResource({ id: assessSpec?.curr_assessment_spec?.avatar_id }));
		}


		if (type === 'icon') {
			setLoading(true);
			const svgString = ReactDOMServer.renderToStaticMarkup(<Component fill="#000000" color="#000000" stroke="#000000" />);
			const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
			const svgFile = new File([svgBlob], 'my-svg-file.svg', { type: 'image/svg+xml' });
			const formData = new FormData();
			formData.append('file', svgFile, svgFile?.name);
			setFileType('');
			const result = await dispatch(createResource({ data: formData, signal: null }));
			setLoading(false);
			setIsUploadIconClicked(false);
			if (!result.error) {
				props.onPicUpload(result?.payload?.id);
			}
		} else if (event.target.files[0]) {
			setLoading(true);
			const image = event.target.files[0];
			const formData = new FormData();
			formData.append('file', image, image?.name);
			setFileType(image.type);
			const result = await dispatch(createResource({ data: formData, signal: null }));
			setLoading(false);
			setFileType('');
			setIsUploadIconClicked(false);
			if (!result.error) {
				props.onPicUpload(result?.payload?.id);
			}
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleOnClickEdit();
		}
	};

	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: (e) => { },
		onBlurWithin: (e) => {
			handleOnClickEdit();
			setIsEditingTitle(false);
		}
	});

	useEffect(() => {
		props.getOnTabAddSectionStepNo(onTabAddSectionStepNo);
	}, [onTabAddSectionStepNo]);

	useEffect(() => {
		setAssessmentTitle(props.assessment?.title);

	}, [props.assessment?.title, props.totalDuration]);

	useEffect(() => {
		console.log(props?.totalDuration, 'props?.totalDuration');
	}, [props?.totalDuration]);
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (uploadIconRef.current && !uploadIconRef.current.contains(event.target)) {
				setIsUploadIconClicked(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [uploadIconRef]);

	const [showPublishModal, setShowPublishModal] = useState(false);

	const AssessmentProfile = useMemo(() => {
		// setAssessmentProfileId(assessSpec?.curr_assessment_spec?.avatar_id);
		return (
			<GetAvtarImage
				onClick={() => { handleUploadIconClick(); }}
				className={`assessment-upload-icon pointer ${(loading || resource.processing) ? ' d-none ' : ''}`}

				src={{ image_id: assessSpec?.curr_assessment_spec?.avatar_id, name: assessmentTitle || props.assessment?.title }}
				image_id={assessSpec?.curr_assessment_spec?.avatar_id}
				size='custom'
				height={isMobile || isOnTablet ? 60 : 80}
				width={isMobile || isOnTablet ? 60 : 80}
				type='icon'
			/>
		);
	}, [assessSpec?.curr_assessment_spec?.avatar_id, loading, resource.processing]);

	if (props.variant === 'primary')
		return (
			<div className='w-100 assessmnet-creation-header-box'>
				<div className='w-100 d-flex justify-content-between align-items-center' tabIndex={-1} style={{ padding: '24px 0px 0px 0px' }}>
					<div className='d-flex align-items-center justify-content-start' style={{ maxWidth: '620px', width: isMobile ? 'calc(100% - 120px)' : isOnTablet ? 'calc(100% - 320px)' : 'calc(100% - 470px)' }} tabIndex={-1} {...focusWithinProps} onKeyDown={handleKeyDown}>
						{isEditingTitle ?
							<Input
								contenteditable={true}
								value={assessmentTitle}
								placeholder={'Assessment Title'}
								inputStyle={{
									background: 'none',
									borderColor: '#392D69',
									...(!isEditingTitle && { borderColor: 'transparent' }),
									...(isTitleInvalid && { borderColor: '#FC4848' }),
									minWidth: '150px',
									width: isOnWeb ? '570px' : isOn1540PX ? '470px' : isOn1440PX ? '370px' : isOn1340PX ? '270px' : '100%' ,
									padding: '0px 0px',
									borderRadius: '0px',
									fontSize: '28px',
								}}
								name='title'
								disabled={!isEditingTitle}
								onChange={handleTitleChange}
							/>
							:
							<span className={`headline-3 dark-100`} style={{ whiteSpace: 'nowrap', maxWidth: 'calc(100% - 20px)', overflow: 'hidden', textOverflow: 'ellipsis' }}>{assessmentTitle}</span>
						}
						<div className='assessment-title-edit-icon-box' style={{ paddingTop: '5px', marginLeft: '8px', cursor: 'pointer' }} onClick={handleOnClickEdit}>
							{(props.currentStep === 'creation' && isEditingTitle) ? 
								<CheckIcon />
								:
								<EditIcon />
							}
						</div>
					</div>
					{props.currentStep === 'creation' &&
						<>
							{isMobile ? 
								<div className='d-flex h-100 align-items-center' style={{ width: '104px', gap: '16px' }}>
									<div
										onClick={props.handleAiAssessmentClick}
										className='d-flex align-items-center justify-content-center'
										style={{
											height: '44px',
											width: '44px',
											background: 'white',
											borderRadius: '50px',
											border: '1px solid black'
										}}
									>
										<OpenAIIcon />
									</div>
									<div
										onClick={props.handlePublishClick}
										className='d-flex align-items-center justify-content-center'
										style={{
											height: '44px',
											width: '44px',
											background: '#121216',
											borderRadius: '50px',
										}}
									>
										<PublishAssessmentIcon />
									</div>
								</div> 
								: 
								<div className='d-flex h-100 align-items-center' style={{ width: isOnTablet ? '301px' : '455px', gap: '16px' }}>
									<Button
										btn='ragular-btn'
										size='md'
										varrient='secondary-btn'
										preIcon={<OpenAIIcon />}
										title={isOnTablet ? 'Create with AI' : 'Create Assessment with AI'}
										style={{ width: 'max-content' }}
										onClick={props.handleAiAssessmentClick}
									/>
									<Button
										btn='ragular-btn'
										size='md'
										varrient='primary'
										preIcon={<PublishAssessmentIcon />}
										title={isOnTablet ? 'Publish' : 'Publish Assessment'}
										style={{ width: 'max-content' }}
										onClick={props.handlePublishClick}
										processing={props.processing}
										disabled={props.processing}
									/>
								</div>
							}
						</>
					}
				</div>
				<div className='assessment-date-container d-flex' style={{ gap: '16px', marginTop: '12px' }}>
					<div className='d-flex align-items-center'>
						<CloudIcon style={{ width: '20px', height: '20px', marginRight: '5px' }} />
						<span className='body-2 dark-50' style={{ marginTop: '3px' }}>Last saved at:  <span className='dark-100'>{new Date(props.assessment.updated_at).toLocaleTimeString()}</span></span>
					</div>
				</div>
				{props.currentStep === 'creation' &&
					<div className='assessment-creation-header flex-column' style={{ gap: '8px' }}>
						<div className='w-100 d-flex justify-content-between align-items-center'>
							<div className='d-flex align-items-center'>
								{skillTestSection && valueTestSection && personalityTestSection ? 
									<div className='d-flex align-items-center' style={{ gap: '8px' }}>
										<CheckIconv2  stroke='#7C21FF'/>
										<span className='body-2 primary-text'>Great! You’ll get a job fit score</span>
									</div>
									: 
									<span className='body-2 dark-100'>Complete the checklist to get job fit score</span>
								}
								<CustomTooltip customStyle={{ height: '20px', width: '20px', marginLeft: '5px', display: 'flex', alignItems: 'center' }} customPosition={{ bottom: '10px', left: '10px' }} text={
									'Candidates will be assigned a job fitness score when they attempt this assessment. Job fitness score aggregates the candidate\'s performance in Skill, Value, and Personality tests.'}>
									<InfoIcon style={{ width: '20px', height: '20px', stroke: '#808191' }} />
								</CustomTooltip>
							</div>
							<div className='d-flex align-items-center'>
								<ClockIcon style={{ width: '20px', height: '20px', marginRight: '5px' }} />
								<span className='body-2 dark-50' style={{ marginTop: '3px' }}>Total Time:  <span className='dark-100'>{`${secondsTohhmm(props.totalDuration) || 0}`}</span></span>
								<CustomTooltip customStyle={{ height: '20px', width: '20px', marginLeft: '5px', display: 'flex', alignItems: 'center' }} customPosition={{ bottom: '10px', left: '10px' }} text={(props.isObjectiveTime !== undefined || props.isObjectiveTime !== null)
									? 'This cumulative duration applies to objective tests (skill, coding, value fitness). Text and Case Study tests have flexible time (hours to days), while Video and Upload tests are without set time limits.'
									: 'This is the cumulative duration for all test types including Case Study and Text Responses'}  >
									<InfoIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} />
								</CustomTooltip>
							</div>
						</div>
						<div className='w-100 d-flex justify-content-start align-items-center' style={{ gap: '8px' }}>
							{!skillTestSection ? 
								<AlertIcon /> 
								: 
								<CheckIconv2 stroke='#808191'/>
							}  
							<span className='body-2 dark-100'>Skill Test</span>
						</div>
						<div className='w-100 d-flex justify-content-start align-items-center' style={{ gap: '8px' }}>
							{!valueTestSection ? 
								<AlertIcon /> 
								:
								<CheckIconv2 stroke='#808191'/>
							}  
							<span className='body-2 dark-100'>Value Test</span>
						</div> 
						<div className='w-100 d-flex justify-content-start align-items-center' style={{ gap: '8px' }}>
							{!personalityTestSection ? 
								<AlertIcon /> 
								: 
								<CheckIconv2 stroke='#808191'/>
							}  
							<span className='body-2 dark-100'>Personality Test</span>
						</div> 
					</div >
				}
			</div >
		);
	else {
		return (
			<div className='w-100 assessmnet-creation-header-box mb-3'>
				<div className=' d-flex justify-content-between align-items-center' tabIndex={-1} style={{ padding: '24px 0px 0px 0px' }}>
					<div className='d-flex align-items-center' style={{ maxWidth: '420px', width: isMobile ? 'calc(100% - 120px)' : isOnTablet ? 'calc(100% - 320px)' : 'calc(100% - 470px)' }} tabIndex={-1} {...focusWithinProps} onKeyDown={handleKeyDown}>
						{isEditingTitle ?
							<Input
								contenteditable={true}
								value={assessmentTitle}
								placeholder={'Assessment Title'}
								inputStyle={{
									background: 'none',
									borderColor: '#392D69',
									...(!isEditingTitle && { borderColor: 'transparent' }),
									...(isTitleInvalid && { borderColor: '#FC4848' }),
									minWidth: '130px',
									width: '100%',
									padding: '0px 0px',
									fontSize: '28px'
								}}
								name='title'
								disabled={!isEditingTitle}
								onChange={handleTitleChange}
							/>
							:
							<span className={`headline-3 dark-100`} style={{ whiteSpace: 'nowrap', maxWidth: 'calc(100% - 20px)', overflow: 'hidden', textOverflow: 'ellipsis' }}>{assessmentTitle}</span>
						}
						<div className='assessment-title-edit-icon-box' style={{ paddingTop: '5px', marginLeft: '8px', cursor: 'pointer' }} onClick={handleOnClickEdit}>
							{(props.currentStep === 'creation' && isEditingTitle) ? 
								<CheckIcon />
								:
								<EditIcon />
							}
						</div>
					</div>
					{props.currentStep === 'creation' &&
						<>
							{isMobile ? 
								<div className='d-flex h-100 align-items-center' style={{ gap: '16px' }}>
									<div
										onClick={props.handleAiAssessmentClick}
										className='d-flex align-items-center justify-content-center'
										style={{
											height: '44px',
											width: '44px',
											background: 'white',
											borderRadius: '50px',
											border: '1px solid black',
											cursor:'pointer'
										}}
									>
										<OpenAIIcon />
									</div>
									<div
										onClick={props.handlePublishClick}
										className='d-flex align-items-center justify-content-center'
										style={{
											height: '44px',
											width: '44px',
											background: '#121216',
											borderRadius: '50px',
											cursor:'pointer'
										}}
									>
										<PublishAssessmentIcon />
									</div>
								</div> 
								: 
								<div className='d-flex h-100 align-items-center' style={{ gap: '16px' }}>
									<Button
										btn='ragular-btn'
										size='md'
										varrient='secondary-btn'
										preIcon={<OpenAIIcon />}
										title={isOnTablet ? 'Create with AI' : 'Create Assessment with AI'}
										style={{ width: 'max-content' }}
										onClick={props.handleAiAssessmentClick}
									/>
									<Button
										btn='ragular-btn'
										size='md'
										varrient='primary'
										preIcon={<PublishAssessmentIcon />}
										title={isOnTablet ? 'Publish' : 'Publish Assessment'}
										style={{ width: 'max-content' }}
										onClick={props.handlePublishClick}
										processing={props.processing}
										disabled={props.processing}
									/>
								</div>
							}
						</>
					}
				</div >
				<div className='assessment-date-container d-flex' style={{ gap: '16px', marginTop: '12px' }}>
					<div className='d-flex align-items-center'>
						<CloudIcon style={{ width: '20px', height: '20px', marginRight: '5px' }} />
						<span className='body-2 dark-50' style={{ marginTop: '3px' }}>Last saved at:  <span className='dark-100'>{new Date(props.assessment.updated_at).toLocaleTimeString()}</span></span>
					</div>
				</div>
				{props.currentStep === 'creation' &&
					<div className='assessment-creation-header flex-column' style={{ gap: '8px' }}>
						<div className='w-100 d-flex flex-column justify-content-between '>
							<div className='d-flex align-items-center'>
								{skillTestSection && valueTestSection && personalityTestSection ? 
									<div className='d-flex align-items-center' style={{ gap: '8px' }}>
										<CheckIconv2  stroke='#7C21FF'/>
										<span className='body-2 primary-text'>Great! You’ll get a job fit score</span>
									</div>
									:
									<span className='body-2 dark-100 text-start'>Complete the checklist to get job fit score</span>	
								}
								<CustomTooltip customStyle={{ height: '20px', width: '20px', marginLeft: '5px', display: 'flex', alignItems: 'center' }} customPosition={{ bottom: '10px', left: '10px' }} text={
									'Candidates will be assigned a job fitness score when they attempt this assessment. Job fitness score aggregates the candidate\'s performance in Skill, Value, and Personality tests.'}>
									<InfoIcon style={{ width: '20px', height: '20px', stroke: '#808191' }} />
								</CustomTooltip>
							</div>
							<div className='w-100 d-flex align-items-center mt-2'>
								<ClockIcon style={{ width: '20px', height: '20px', marginRight: '5px' }} />
								<span className='body-2 dark-50' style={{ marginTop: '3px' }}>Total Time:  <span className='dark-100'>{`${secondsTohhmm(props.totalDuration) || 0}`}</span></span>
								<CustomTooltip customStyle={{ height: '20px', width: '20px', marginLeft: '5px', display: 'flex', alignItems: 'center' }} customPosition={{ bottom: '10px', left: '10px' }} text={(props.isObjectiveTime !== undefined || props.isObjectiveTime !== null)
									? 'This cumulative duration applies to objective tests (skill, coding, value fitness). Text and Case Study tests have flexible time (hours to days), while Video and Upload tests are without set time limits.'
									: 'This is the cumulative duration for all test types including Case Study and Text Responses'}  >
									<InfoIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} />
								</CustomTooltip>
							</div>
						</div>
						<div className='w-100 d-flex justify-content-start align-items-center' style={{ gap: '8px' }}>
							{!skillTestSection ? 
								<AlertIcon /> 
								: 
								<CheckIconv2 stroke='#808191'/>
							}  
							<span className='body-2 dark-100'>Skill Test</span>
						</div>
						<div className='w-100 d-flex justify-content-start align-items-center' style={{ gap: '8px' }}>
							{!valueTestSection ? 
								<AlertIcon /> 
								: 
								<CheckIconv2 stroke='#808191'/>
							}  
							<span className='body-2 dark-100'>Value Test</span>
						</div> 
						<div className='w-100 d-flex justify-content-start align-items-center' style={{ gap: '8px' }}>
							{!personalityTestSection ? 
								<AlertIcon /> 
								: 
								<CheckIconv2 stroke='#808191'/>
							}  
							<span className='body-2 dark-100'>Personality Test</span>
						</div> 
					</div >
				}
			</div >
		);
	}
}

AssessmentCreationHeader.propTypes = {
	assessment: PropTypes.object,
	onConfirmTitle: PropTypes.func
};
