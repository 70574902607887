import React from 'react';
import { useSelector } from 'react-redux';
import AssessmentResults from '../../../dashboard/test-details/AssessmentResults';
import CandidateAssessmentResults from './candidate-assessment-right-side-menu-status-type/CandidateAssessmentResults';
import CandidateAssessmentRightSideMenuHeader from './CandidateAssessmentRightSideMenuHeader';

export default function CandidateAssessmentRightSideMenu(props) {
	const { selectedAssessment, setSelectedAssessment, assessmentsLength, selectedAssessmentIndex } = props;
	const isAttempted = selectedAssessment?.tests.some((test) => test.completed);
	const assessments = useSelector((state) => state.assessmentReducer);

	return (
		<>
			<div className='assessment-custom-card-container d-flex flex-column h-100'>
				<CandidateAssessmentRightSideMenuHeader
					onStart={props.onStart}
					assessment={selectedAssessment}
					setSelectedAssessment={setSelectedAssessment}
					onAssessmentDecline={props?.onAssessmentDecline}
					handleClose={props?.handleClose}
					selectedAssessmentIndex={selectedAssessmentIndex}
					assessmentsLength={assessmentsLength}
				/>
				<div className='assessment-custom-card-result-container'>
					{
						(selectedAssessment?.status === 'FINISHED') || (selectedAssessment?.status === 'EXPIRED' && isAttempted)
							?
							<AssessmentResults assessment={selectedAssessment} loading={assessments?.loading_current_assess} />
							:
							<CandidateAssessmentResults assessment={selectedAssessment} />
					}
				</div>
			</div>
		</>
	);
}
