import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TestType, UserRole } from '../../Constants';
import { takeCandidatePicture } from '../../../redux/thunks/AntiCheat';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom';
import TabId from 'is-tab-duplicated';
import { uuid } from '../../utilities';
import { createSession, getAssessment, resetAssessmentReducerState, sessionHeartBeat, takeOverSession } from '../../../redux/thunks/Assessment';
import CustomModal from '../../../components/common/Modal';
import ProcessingIndicator from '../../../components/common/ProcessingIndicator';
import { handleMenuVisibility } from '../../../redux/slices/AuthSlice';

export const SessionContext = createContext(null);

let heartBeatInterval;

export default function SessionContextWrapper(props) {


	const auth = useSelector((state) => state.authReducer);
	const assessment = useSelector((state) => state.assessmentReducer);


	const [isTakeOverSesstionModalOpen, setIsTakeOverSesstionModalOpen] = useState(false);
	const [openDeadlineModal, setOpenDeadlineModal] = useState(false);
	const dispatch = useDispatch();
	const params = useParams();
	const history = useHistory();

	const [sessionStates, setSessionStates] = useState({
		isPageReady: false,
		intervals: []
	});

	const getCurrentAssessment = async () => {
		const res = await dispatch(getAssessment({ id: params.id }));
		if (res?.meta?.requestStatus === 'rejected') {
			history.push('/dashboard/assessments/');
		}
	};

	const createSessionFunc = async () => {
		const isSessionCreatedRes = await dispatch(createSession({ assess_id: params.id }));
		if (isSessionCreatedRes?.meta?.requestStatus === 'rejected') {
			if (isSessionCreatedRes?.payload === 'An active session already exists!') {
				heartBeatInterval = setInterval(async () => {
					await sessionHeartBeatFunc();
				}, 4500);
				await getCurrentAssessment();
				setSessionStates({ ...sessionStates, isPageReady: true });
			}
			else if (isSessionCreatedRes?.payload === 'A session is active in another location!') {
				setSessionStates({ ...sessionStates, isPageReady: false });
				setIsTakeOverSesstionModalOpen(true);
			} else if (isSessionCreatedRes?.payload === 'Test is in a terminal state: EXPIRED') {
				setOpenDeadlineModal(true);
			}
		}
		else {
			heartBeatInterval = setInterval(async () => {
				await sessionHeartBeatFunc();
			}, 4500);
			await getCurrentAssessment();
			setSessionStates({ ...sessionStates, isPageReady: true });
		}

	};

	const sessionHeartBeatFunc = async () => {
		const res = await dispatch(sessionHeartBeat({ assess_id: params.id, takeover_session: false }));
		if (res.meta.requestStatus === 'rejected') {
			if (res?.payload === 'A session is active in another location!') {
				clearInterval(heartBeatInterval);
				setIsTakeOverSesstionModalOpen(true);
			} else if (res?.payload === 'Test is in a terminal state: EXPIRED') {
				setOpenDeadlineModal(true);
			}
		}
	};

	const sessionOverTake = async () => {
		const res = await dispatch(takeOverSession({ assess_id: params.id }));
		if (res.meta.requestStatus === 'rejected') {
			// navigate('/dashboard/assessments');
		}
		else {
			heartBeatInterval = setInterval(async () => {
				await sessionHeartBeatFunc();
			}, 4500);
			if (assessment.current_assessment === null) {
				await getCurrentAssessment();
			}
		}
		setSessionStates({ ...sessionStates, isPageReady: true });
	};

	useEffect(() => {
		if (assessment?.current_assessment?.status === 'FINISHED') {
			clearInterval(heartBeatInterval);
			sessionStates?.intervals.map((a) => {
				clearInterval(a);
			});
		}
	}, [assessment?.current_assessment]);

	useEffect(() => {
		dispatch(handleMenuVisibility(false));
		TabId.initInstance();
		if (TabId.isTabDuplicated()) {
			sessionStorage.setItem('tab_id', `${uuid()}`);
		}
		createSessionFunc();

		return () => {
			clearInterval(heartBeatInterval);
			dispatch(handleMenuVisibility(true));

			sessionStates?.intervals.map((a) => {
				// console.log(a, 'interval');
				clearInterval(a);
			});


			sessionStorage.removeItem('cam_and_mic_permission_for_ss');
			sessionStorage.removeItem('cam_and_mic_permission_for_test');
			sessionStorage.removeItem('check_cam_and_mic_permission');
		};
	}, []);

	useMemo(() => {
		if (!sessionStorage.getItem('cam_and_mic_permission_for_ss')) {
			sessionStorage.setItem('cam_and_mic_permission_for_ss', 'undefined');
		}

		if (!sessionStorage.getItem('cam_and_mic_permission_for_test')) {
			sessionStorage.setItem('cam_and_mic_permission_for_test', 'undefined');
		}

		if (!sessionStorage.getItem('check_cam_and_mic_permission')) {
			sessionStorage.setItem('check_cam_and_mic_permission', 'undefined');
		}

	}, [sessionStorage.getItem('cam_and_mic_permission_for_ss'), sessionStorage.getItem('cam_and_mic_permission_for_test'), sessionStorage.getItem('check_cam_and_mic_permission')]);


	if (isTakeOverSesstionModalOpen) {
		return (
			<CustomModal
				open={isTakeOverSesstionModalOpen}
				varrient='alert-warning-modal'
				title='Assessment Already in Progress!'
				description={`You can't start the assessment here as it is already in progress on some other device or server.`}
				warningAlert={true}
				warningText={'Warning: If you choose to takeover the assessment from here, it will be automatically closed on other devices.'}
				handleCloseModal={() => {
					setIsTakeOverSesstionModalOpen(false);
					dispatch(resetAssessmentReducerState({ takeover_session: undefined }));
					window.location.replace('/dashboard/assessments/');
				}}
				confirmBtnTitle={'Yes, Takeover'}
				btnWidth='max-content'
				onConfirm={async () => {
					sessionOverTake();
					setIsTakeOverSesstionModalOpen(false);
					dispatch(resetAssessmentReducerState({ takeover_session: undefined }));
				}}
			/>
		);
	} 
	else if (openDeadlineModal) {

		return (
			<CustomModal
				open={openDeadlineModal}
				varrient='expire-assessment-warning-modal'
				title='Assessment Expired!'
				description={`The assessment that you're trying to access has expired because its deadline has passed.`}
				warningAlert={true}
				hideScendBtn={true}
				confirmBtnTitle='Go to Dashboard'
				btnWidth='max-content'
				onConfirm={() => {
					setOpenDeadlineModal(false);
					history.push('/dashboard/assessments');
				}}
			/>
		);
	}
	else {

		return (
			<SessionContext.Provider value={{ sessionStates, setSessionStates }}>
				<>
					{
						(sessionStates.isPageReady)
							?
							props.children
							:
							<div style={{width:'100%',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
								<div>
									<div className="processing-indicator primary" style={{width: '40px', height: '40px'}} />
								</div>
						
							</div>
					}
				</>
			</SessionContext.Provider>
		);
	}
}
