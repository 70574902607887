import React from 'react';
import { PersonalityType } from '../../../utils/personalityType';
import ProgressBar from '../../../components/common/ProgressBar';
import { useMediaQuery } from '@mui/material';

export default function PersonalityInsightDetails(props) {
	const isOnWebScreen = useMediaQuery('(min-width:1280px)');
	const { assessment } = props;
	const personalityType = PersonalityType[assessment?.personality_test_result?.pers_type];
	return (
		<>
			{assessment?.personality_test_result?.error === null ?
				<div className='w-100 d-flex flex-column' style={{ gap: '24px' }}>
					<div className={`w-100 d-flex ${isOnWebScreen ? 'flex-row' : 'flex-column'} align-items-start justify-content-between`} style={{ marginBottom: '24px', gap: '24px' }}>
						<span className='subtitle-2 dark-50'>{`${`${assessment?.user?.first_name} ${assessment?.user?.last_name}`} Personality Type is`}</span>
						<div className='d-flex flex-column align-items-start' style={{ width: '225px', gap: '12px' }}>
							<span className='subtitle-2 primary-text text-start'>{`A ${personalityType?.pt_alias} (${assessment?.personality_test_result?.pers_type})`}</span>
							<div className='w-100 d-flex flex-row align-items-start' style={{ gap: '12px' }}>
								<span className='body-2 dark-100 text-center' style={{ width: 'calc(100% - 100px)' }}>{`Role Compatibility`}</span>
								<div style={{ width: '100px' }}>
									<ProgressBar value={assessment?.personality_test_result?.score ? parseInt((100 / 1) * assessment?.personality_test_result?.score) : 0}/>
								</div>
							</div>
						</div>
					</div>
					<div className='w-100 d-flex flex-column align-items-start' style={{ gap: '16px' }}>
						{/* <span className='subtitle-2 dark-100'>Description:</span> */}
						<span className='body-2 dark-100 text-start'>{personalityType?.description}</span>
					</div>
					<div className='w-100 d-flex flex-column align-items-start' style={{ padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#FFF', gap: '16px' }}>
						<span className='subtitle-2 dark-100'>Strengths:</span>
						{
							personalityType?.strength_info?.strengths?.map((s, i) => (
								<div key={i} className='w-100 d-flex flex-column align-items-start' style={{ padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#F6F5F8', gap: '10px' }}>
									<span className='body-2 dark-50 text-start'>{s.strength}</span>
									<span className='body-2 dark-100 text-start'>{s.description}</span>
								</div>
							))
						}
						<span className='body-2 dark-100 text-start'>{personalityType?.strength_info?.disclaimer}</span>
					</div>
					<div className='w-100 d-flex flex-column align-items-start' style={{ padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#FFF', gap: '16px' }}>
						<span className='subtitle-2 dark-100'>Weaknesses:</span>
						{
							personalityType?.weakness_info?.weaknesses?.map((w, i) => (
								<div key={i} className='w-100 d-flex flex-column align-items-start' style={{ padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#F6F5F8', gap: '10px' }}>
									<span className='body-2 dark-50 text-start'>{w.weakness}</span>
									<span className='body-2 dark-100 text-start'>{w.description}</span>
								</div>
							))
						}
						<span className='body-2 dark-100 text-start'>{personalityType?.weakness_info?.disclaimer}</span>
					</div>
					<div className='w-100 d-flex flex-column align-items-start' style={{ padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#FFF', gap: '16px' }}>
						<span className='subtitle-2 dark-100'>Potential career paths:</span>
						{
							personalityType?.career_path_info?.career_paths?.map((c, i) => (
								<div key={i} className='w-100 d-flex flex-column align-items-start' style={{ padding: '24px', borderRadius: '24px', border: '1px solid #ECEDF1', background: '#F6F5F8', gap: '10px' }}>
									<span className='body-2 dark-50 text-start'>{c.career_path}</span>
									<span className='body-2 dark-100 text-start'>{c.description}</span>
								</div>
							))
						}
						<span className='body-2 dark-100 text-start'>{personalityType?.career_path_info?.disclaimer}</span>
					</div>
				</div>
				:
				<div>
					<span className='body-1 danger-text'>{assessment?.personality_test_result?.error?.message}</span>
				</div>
			}
		</>
	);
}
