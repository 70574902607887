import { createSlice } from '@reduxjs/toolkit';
import { createAssessSpec, getAssessSpec, listAssessSpec, listAssessSpecWithQueryParam, getAssessSpecStatsData, archiveAssessSpec, deleteAssessSpec, updateAssessSpec, resetCurrAssessSpec, getAssessSpecAI, cloneAssessSpec } from '../thunks/AssessSpec';

const AssessSpec = createSlice({
	name: 'Assessment Slice',
	initialState: {
		loading: false,
		jdConversion: false,
		processing: false,
		data: null,
		statsData: null,
		curr_assessment_spec: null,
		error_message: null,
		success_message: null,
		clone_assessSpec_processing: false,
		auto_generated: false
	},

	reducers: {},

	extraReducers: {
		[listAssessSpec.pending]: (state) => {
			// state.loading = true;
			return {
				...state,
				loading: true,
			};
		},

		[listAssessSpec.fulfilled]: (state, { payload }) => {
			// state.loading = false;
			// state.data = payload;
			return {
				...state,
				loading: false,
				data: payload,
			};
		},

		[listAssessSpec.rejected]: (state) => {
			// state.loading = false;
			return {
				...state,
				loading: false,
			};
		},

		[listAssessSpecWithQueryParam.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},

		[listAssessSpecWithQueryParam.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				data: payload,
			};
		},

		[listAssessSpecWithQueryParam.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

		[getAssessSpecStatsData.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[getAssessSpecStatsData.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				statsData: payload,
			};
		},
		[getAssessSpecStatsData.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

		[getAssessSpec.pending]: (state) => {
			// state.loading = true;
			return {
				...state,
				loading: true,
			};
		},

		[getAssessSpec.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				curr_assessment_spec: payload,
			};
		},

		[getAssessSpec.rejected]: (state) => {
			// state.loading = false;
			return {
				...state,
				loading: false,
			};
		},

		[getAssessSpecAI.pending]: (state) => {
			// state.loading = true;
			return {
				...state,
				jdConversion: true,
			};
		},

		[getAssessSpecAI.fulfilled]: (state, { payload }) => {

			return {
				...state,
				jdConversion: false,
				// curr_assessment_spec: { ...state.curr_assessment_spec, ...payload },
				auto_generated: true
			};
		},

		[getAssessSpecAI.rejected]: (state) => {
			return {
				...state,
				jdConversion: false,
			};
		},

		[createAssessSpec.pending]: (state) => {
			// state.processing = true;
			return {
				...state,
				processing: true,
			};
		},

		[createAssessSpec.fulfilled]: (state, { payload }) => {
			// state.processing = false;
			// state.curr_assessment_spec = payload;
			return {
				...state,
				processing: false,
				curr_assessment_spec: payload,
			};
		},

		[createAssessSpec.rejected]: (state) => {
			// state.processing = false;
			return {
				...state,
				processing: false,
			};
		},

		[archiveAssessSpec.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},

		[archiveAssessSpec.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				// data: [...state.data, payload],
				// data: state.data.filter((assessSpec, _) => { return assessSpec.id !== payload.id; }),
			};
		},

		[archiveAssessSpec.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[deleteAssessSpec.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},

		[deleteAssessSpec.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				// data: [...state.data, payload],
				// data: state.data.filter((assessSpec, _) => { return assessSpec.id !== payload.id; }),
			};
		},

		[deleteAssessSpec.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[updateAssessSpec.pending]: (state) => {
			// state.processing = true;
			return {
				...state,
				processing: true,
			};
		},

		[updateAssessSpec.fulfilled]: (state, { payload }) => {
			// state.processing = false;
			// state.curr_assessment_spec = payload;
			return {
				...state,
				processing: false,
				curr_assessment_spec: payload,
				auto_generated: false
			};
		},

		[updateAssessSpec.rejected]: (state) => {
			// state.processing = false;
			return {
				...state,
				processing: false,
			};
		},

		[resetCurrAssessSpec.fulfilled]: (state, { payload }) => {
			return {
				...state,
				...payload
			};
		},

		[cloneAssessSpec.pending]: (state) => {
			// state.processing = true;
			return {
				...state,
				clone_assessSpec_processing: true,
			};
		},

		[cloneAssessSpec.fulfilled]: (state, { payload }) => {
			// state.processing = false;
			// state.curr_assessment_spec = payload;
			return {
				...state,
				clone_assessSpec_processing: false,
				// cloned_assessSpec: payload,
			};
		},

		[cloneAssessSpec.rejected]: (state) => {
			// state.processing = false;
			return {
				...state,
				clone_assessSpec_processing: false,
			};
		},

	},
});

export default AssessSpec.reducer;
