import React from 'react';

export default function TextArea(props) {

	const { disabled, inputContainerClass, style, title } = props;

	return (
		<div className={`primary-textarea-container ${inputContainerClass}`} style={{ ...style }}>
			{title &&
					<div style={{ minWidth: 'fit-content', marginRight: '16px' }}>
						<span className='body-2 dark-100'>{title}</span>
					</div>
			}
			<textarea
				ref={props.ref}
				placeholder={props.placeholder || ''}
				onChange={props.onChange}
				onBlur={props.onBlur}
				onFocus={props.onFocus}
				onKeyDown={props.onKeyDown}
				onCopy={props.onCopy}
				onCut={props.onCut}
				onPaste={props.onPaste}
				onContextMenu={props.onContextMenu}
				type={props.type}
				id={props.id}
				name={props.name}
				value={props.value}
				defaultValue={props.defaultValue}
				minLength={props.minLength}
				maxLength={props.maxLength}
				readOnly={props.readOnly}
				className={`body-2 dark-100 primary-textarea ${props.className}`}
				disabled={disabled}
				style={{
					...props.inputStyle,
				}}
			/>
		</div>
	);
}
