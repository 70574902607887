import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const takeCandidatePicture = createAsyncThunk(
	'anti_cheat/TakeCandidatePicture',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/${data.assess_id}/event`,
				method: 'POST',
				data: data.file,
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const takeCandidateScreenEvent = createAsyncThunk(
	'anti_cheat/takeCandidateScreenEvent',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/assessments/${data.assess_id}/event?${data.qs.toString()}`,
				method: 'POST',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);