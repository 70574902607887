import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '../../api/axios/axios-config';
import * as Constant from './../../utils/Constants';
import * as Utils from '../../utils/utilities';
import { handleApiError } from '../../api/axios/interceptors';

export const signup = createAsyncThunk(
	'auth/signup',
	async ({ is_candidate, data }, { rejectWithValue, dispatch }) => {
		try {

			var request = null;

			if (is_candidate) {

				if (data.invite_id) {
					const _req = await instance.request({
						url: `/v1/assess_specs/invite?invite_id=${data.invite_id}`,
						method: 'GET',
					});

					if (!_req.data?.is_valid) {
						throw new Error('Invalid invite id');
					}
				}

				request = await instance.request({
					url: `/v1/auth/user-signup/?invite_id=${data.invite_id}`,
					method: 'POST',
					data: {
						invite_token: data.invite_token,
						email: data.email,
						password: data.password,
						first_name: data.first_name,
						last_name: data.last_name
					}
				});

			} else {
				request = await instance.request({
					url: '/v1/auth/register/',
					method: 'POST',
					data: {
						first_name: data.first_name,
						last_name: data.last_name,
						company_name: data.company_name,
						email: data.email,
						password: data.password,
						activation_path: `${window.location.hostname}/activation`,
					},
				});
			}

			Utils.setCookie(Constant.tokenCookieName, request.data.refresh_token, 7);
			return { ...request.data, email: data.email };
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const googleSignup = createAsyncThunk(
	'auth/google/signup',
	async ({ data }, { rejectWithValue, dispatch }) => {
		try {
			var request = null;

			if (data.invite_id || data.invite_token) {

				if (data.invite_id) {
					const _req = await instance.request({
						url: `/v1/assess_specs/invite?invite_id=${data.invite_id}`,
						method: 'GET',
					});

					if (!_req.data?.is_valid) {
						throw new Error('Invalid invite id');
					}
				}

				request = await instance.request({
					url: `/v1/auth/google/signup/?invite_id=${data.invite_id}`,
					method: 'POST',
					data: {
						invite_token: data.invite_token,
						googleOauthCode: data.googleOauthCode
					}
				});

			} else {

				request = await instance.request({
					url: `/v1/auth/google/register`,
					method: 'POST',
					data: {
						googleOauthCode: data.googleOauthCode
					}
				});

			}

			Utils.setCookie(Constant.tokenCookieName, request.data.refresh_token, 7);
			return { ...request.data, email: request.data.email };
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

// export const candidateSignup = createAsyncThunk(
// 	'auth/candidateSignup',
// 	async (data, { rejectWithValue }) => {

// 		try {

// 			const request = await instance.request({
// 				url: `/v1/auth/candidate-signup/`,
// 				method: 'POST',
// 				data: {
// 					invite_token: data.invite_token,
// 					email: data.email,
// 					password: data.password,
// 					first_name: data.first_name,
// 					last_name: data.last_name
// 				}
// 			});

// 			Utils.setCookie(Constant.tokenCookieName, request.data.refresh_token, 7);

// 			return request.data;

// 		} catch (error) {
// 			return rejectWithValue(error);
// 		}

// 	}
// );

export const login = createAsyncThunk(
	'auth/login',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: '/v1/auth/login',
				method: 'POST',
				data: {
					email: data.email,
					password: data.password,
				},
			});

			Utils.setCookie(
				Constant.tokenCookieName,
				request.data.refresh_token,
				data.remember_me ? 7 : 1
			);
			return { ...request.data, email: data.email };
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const googleLogin = createAsyncThunk(
	'auth/google/login',
	async ({ data }, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: '/v1/auth/google/login',
				method: 'POST',
				data: {
					googleOauthCode: data.googleOauthCode
				},
			});

			Utils.setCookie(
				Constant.tokenCookieName,
				request.data.refresh_token,
				data.remember_me ? 7 : 1
			);
			return { ...request.data, email: request.data.email };
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const refreshToken = createAsyncThunk(
	'auth/refreshToken',
	async (_, { rejectWithValue, dispatch }) => {
		try {
			const refresh_token = Utils.getCookie(Constant.tokenCookieName);

			const request = await instance.request({
				url: '/v1/auth/refresh',
				method: 'POST',
				data: {
					refresh_token,
				},
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const backgroundRefreshToken = createAsyncThunk(
	'auth/backgroundRefreshToken',
	async (_, { rejectWithValue, dispatch }) => {
		try {
			const refresh_token = Utils.getCookie(Constant.tokenCookieName);

			const request = await instance.request({
				url: '/v1/auth/refresh',
				method: 'POST',
				data: {
					refresh_token,
				},
			});

			Utils.deleteCookie(Constant.tokenCookieName);
			Utils.setCookie(Constant.tokenCookieName, request.data.refresh_token);
			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const activateAccount = createAsyncThunk(
	'auth/activateAccount',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: '/v1/auth/activate',
				method: 'POST',
				data: {
					activation_token: data.token,
				},
			});

			return request.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	}
);

export const resendActivationToken = createAsyncThunk(
	'auth/resendActivationToken',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: '/v1/auth/activate/request',
				method: 'POST',
				data: {
					email: data.email,
				},
			});

			return request.data.message;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const requestResetPassword = createAsyncThunk(
	'auth/requestResetPassowrd',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: '/v1/auth/resetpassword/request',
				method: 'POST',
				data: {
					email: data.email,
				},
			});

			return request;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const resetPassword = createAsyncThunk(
	'auth/resetPassword',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: '/v1/auth/resetpassword',
				method: 'PATCH',
				data: {
					reset_token: data.reset_token,
					password: data.password
				},
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const candidateActivation = createAsyncThunk(
	'auth/candidateActivation',
	async ({ activation_token, route }, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: '/v1/auth/candidate_activation/',
				method: 'POST',
				data: {
					activation_token: activation_token,
				},
			});

			Utils.setCookie(
				Constant.tokenCookieName,
				request?.data?.data?.refresh_token,
				7
			);
			return request?.data;
		} catch (error) {
			if (error?.message === 'Invalid Token') {
				window.location.replace(route);
			}
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const resendInvite = createAsyncThunk(
	'auth/resendInvite',
	async ({ invite_token, assess_id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: '/v1/auth/resend_invite/',
				method: 'POST',
				data: {
					invite_token: invite_token,
					assess_id: assess_id,
				},
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const candidateInvite = createAsyncThunk(
	'auth/candidateInvite',
	async ({ invite_id }, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: `/v1/assess_specs/invite?invite_id=${invite_id}`,
				method: 'GET',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const candidatePublicInvite = createAsyncThunk(
	'auth/candidatePublicInvite',
	async ({ email, tenant_id, spec_id, tenant_status }, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: '/v1/auth/candidate/',
				method: 'POST', data: {
					email: email,
					tenant_id: tenant_id,
					spec_id: spec_id,
					tenant_status: tenant_status
				},
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

// Utils.setCookie(Constant.tokenCookieName, request.data.refresh_token, 7);
