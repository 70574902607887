// const baseURL = process.env.REACT_APP_GW_URL;
// `REACT_APP_GW_URL=${process.env.API_GW_URL}`
let baseURL =  '';

if(window && window._env_ && window._env_.REACT_APP_GW_URL)
	baseURL = window._env_.REACT_APP_GW_URL;
else
	baseURL = 'http://localhost:8080';

//const baseURL = "localhost:4000";

export default baseURL;