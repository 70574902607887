import React, { useMemo, useState } from 'react';
import Button from '../../../components/sub-component/Button';
import { ReactComponent as ClockIcon } from '../../../assets/icons/Views/candidate/assessments/clock.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/Views/candidate/assessments/alert-circle.svg';
import { ReactComponent as InfoIconRed } from '../../../assets/icons/Views/dashboard/assessments/info-red.svg';
import { ReactComponent as AvatarIcon } from '../../../assets/icons/Views/candidate/assessments/avatar.svg';
import { ReactComponent as CompleteIcon } from '../../../assets/icons/Views/candidate/assessments/complete.svg';
import { ReactComponent as DeclineIcon } from '../../../assets/icons/Views/candidate/assessments/decline.svg';
import { useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { patchAssessment } from '../../../redux/thunks/Assessment';
import CustomModal from '../../../components/common/Modal';
import { calculateExpiry, secondsToDhmsFormat } from '../../../utils/utilities';
import { inviteBlock, fullBlock } from '../../../utils/Constants';
import { GetAvtarImage } from '../../../components/dashboard/AvtarBadge';
import {
	CircularProgressbarWithChildren,
	buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getAssessSpec } from '../../../redux/thunks/AssessSpec';
import CustomTooltip from '../../../components/common/CustomTooltip';
import ProgressBar from '../../../components/common/ProgressBar';

export default function AssessmentCard(props) {
	const isOnWebScreen = useMediaQuery('(min-width:1340px)');
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1339px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const { data, onClick, onStart, selectedAssessment, isRightMenuOpen } = props;

	const {
		title,
		status,
		tenant,
		testDuration,
		spec_status,
		duration,
		objective_test_duration,
		testType,
		created_at,
		deadline,
		updated_at,
		cum_score,
		cum_score_obj
	} = data;

	const dispatch = useDispatch();
	const [score, setScore] = useState(0);
	const isObjectiveTest = data?.tests?.some((test) => ['skill_test', 'coding_test', 'value_test', 'personality_test'].includes(test.test_type));

	// useMemo(() => {
	// 	if (data.status === 'NOT_QUALIFIED' || data.status === 'DECLINED') {
	// 		setScore(0);
	// 	}
	// 	else {
	// 		setScore(80);
	// 	}
	// }, [data]);

	setTimeout(() => {
		if (data.status === 'NOT_QUALIFIED' || data.status === 'DECLINED') {
			setScore(0);
		}
		else {
			setScore(Math.round(cum_score || cum_score_obj?.score * 100) || '0');
		}
	}, 100);

	return (
		<>
			<div className={`assessment-card-container ${selectedAssessment?.id === data?.id && 'selected-assessment'}`} onClick={() => onClick(data)} style={{gap: isRightMenuOpen && '0px'}}>
				<div className='detail-container'>
					{/* <div className='d-flex flex-row align-items-start h-100' style={{ gap: '12px' }}>
						<div className='assessment-icon'>
							{
								<GetAvtarImage src={{ image_id: data?.avatar_id || data?.tenant?.image_id , name: title || '' }} turnOnBGColors size="custom" width={40} height={40} />
							}
						</div>
					</div> */}
					<div className='details'>
						<div className='d-flex align-items-center' style={{ gap: '12px' }}>
							<span className='subtitle-2 dark-100 text-start'>{title}</span>
							<div
								className='candidate-status-container body-2'
								style={{
									width: 'fit-content',
									border: status !== 'FINISHED' ? '1px solid black' : '',
									background: status === 'FINISHED' ? '#7C21FF' : '#FFFFFF',
									color: status === 'FINISHED' ? '#FFF' : '#121216',
								}}
							>
								{status === 'PENDING' ? 'Pending' : status === 'IN_PROGRESS' ? 'In Progress' : status === 'NOT_QUALIFIED' ? 'Not Qualified' : status === 'DECLINED' ? 'Declined' : status === 'EXPIRED' ? 'EXPIRED' : status == "INVITE_BOUNCED" ? "Invite Bounced" : 'Completed' }
							</div>
						</div>
						{(status === 'PENDING' || status === 'IN_PROGRESS') ?
							<></>
							:
							<>
								{(isObjectiveTest && (!isOnWebScreen || isRightMenuOpen)) &&
									<div className='details-progress'>
										<ProgressBar value={score} varrient='progress-with-white-bg' />
									</div>
								}
							</>
						}
					</div>
					<div className='company-detail'>
						<span className='body-2 dark-100'>{tenant?.company_name}</span>
						<div className='time'>
							<ClockIcon />
							<span className='body-2 dark-50'>Approx. duration: <span className='dark-100'>{secondsToDhmsFormat(objective_test_duration || duration || 0)}</span></span>
						</div>
						<div className='time'>
							<InfoIcon />
							{deadline ?
								<span className='body-2 dark-50'>Expire on: <span className='dark-100'>{calculateExpiry(deadline, created_at)}</span></span>
								:
								<span className='body-2 dark-50'>No Deadline</span>
							}
						</div>
					</div>
					{(!isOnWebScreen || isRightMenuOpen) &&
						<div className='company-detail'>
							<span className='body-2 dark-50'>Invited on: <span className='dark-100'>{new Date(created_at).toDateString()}</span></span>
							{(status === 'NOT_QUALIFIED' || status === 'FINISHED') &&
								<span className='body-2 dark-50'>Last Activity: <span className='dark-100'>{new Date(updated_at).toDateString()}</span></span>
							}
						</div>
					}
				</div>
				<div className='detail-container-right'>
					{(status === 'PENDING' || status === 'IN_PROGRESS' ) ?
						<div className={`d-flex ${(isOnTabletScreen || isRightMenuOpen) ? 'flex-column' : 'flex-row'}`} style={{gap: '12px',alignItems:'center'}}>
							<Button
								disabled={status === 'PENDING' && (inviteBlock.includes(data?.tenant?.status) || fullBlock.includes(data?.tenant?.status) || data?.tenant?.status === 'pre_onboard' || spec_status === 'ARCHIVED')}
								btn='ragular-btn'
								varrient='primary'
								title={status === 'PENDING' ? 'Start' : 'Resume'}
								rounded
								onClick={(e) => {
									e.stopPropagation();
									onStart(data);
								}}
							/>
							<Button
								disabled={status === 'IN_PROGRESS' || (inviteBlock.includes(data?.tenant?.status) || fullBlock.includes(data?.tenant?.status) || data?.tenant?.status === 'pre_onboard') || (status === 'PENDING' && spec_status === 'ARCHIVED')}
								btn='ragular-btn'
								varrient='secondary-btn-v2'
								title={'Decline'}
								rounded
								onClick={(e) => {
									e.stopPropagation();
									props.onAssessmentDecline(data);
								}}
							/>
							{(spec_status !== 'ARCHIVED') && (((inviteBlock.includes(data?.tenant?.status) || fullBlock.includes(data?.tenant?.status)) && status === 'PENDING') || data?.tenant?.status === 'pre_onboard') && (
								<CustomTooltip customPosition={{ bottom: '12px', left: '8px' }}
									text={'Please contact the recruiter for this role to gain access to this assessment.'}
								>
									<InfoIconRed style={{ width: '28px', height: '28px' }} />
								</CustomTooltip>
							)}
							{(status === 'PENDING' && spec_status === 'ARCHIVED') && 
								<CustomTooltip customPosition={{ bottom: '12px', left: '8px' }}
									text={'This assessment is no longer available.'}
								>
									<InfoIconRed style={{ width: '28px', height: '28px' }} />
								</CustomTooltip>
							}
						</div>
						:
						(isOnWebScreen && !isRightMenuOpen) &&
							<div className='d-flex flex-column align-items-end' style={{ width: 'max-content', gap: '12px' }}>
								{isObjectiveTest &&
									<>
										{/* <div style={isOnMobileScreen ? { width: '50px', height: '50px' } : { width: '64px', height: '64px' }}>
											<CircularProgressbarWithChildren
												strokeWidth={9}
												value={(data.status === 'NOT_QUALIFIED' || data.status === 'DECLINED') ? 5 : score}
												styles={buildStyles({
													rotation: 0.25,
													strokeLinecap: 'butt',
													// pathTransitionDuration: 5,
													// Colors
													pathColor: score === '- -' ? '#DEDAF2' : score > 79 ? '#00D69A' : score > 64 ? '#46B4FB' : score > 50 ? '#F4C20D' : '#FC4848',
													// textColor: '#f88',
													trailColor: score === '- -' ? '#DEDAF2' : score > 79 ? '#E0FFF6' : score > 64 ? '#D6F0FF' : score > 50 ? '#FFF0BA' : '#FFE1E1',
													backgroundColor: '#3e98c7',
												})}
											>
												<span className='body-4 black-700' style={{ marginTop: '4px' }}>Score</span>
												<span className='body-3' style={{ color: score === '- -' ? '#6F2DBD' : score > 79 ? '#00D69A' : score > 64 ? '#46B4FB' : score > 50 ? '#F4C20D' : '#FC4848', marginTop: '-4px' }}>{score}%</span>
											</CircularProgressbarWithChildren>
										</div> */}
										<div className='d-flex align-items-start' style={{ width: '110px' }}>
											<ProgressBar value={score} varrient='progress-with-white-bg' />
										</div>
									</>
								}
								{
									<div className='d-flex flex-row align-items-start' style={{ gap: '12px' }}>
										<span className='body-2 dark-50'>Invited on: <span className='dark-100'>{new Date(created_at).toDateString()}</span></span>
										{(status === 'NOT_QUALIFIED' || status === 'FINISHED') &&
											<span className='body-2 dark-50'>Last Activity: <span className='dark-100'>{new Date(updated_at).toDateString()}</span></span>
										}
									</div>
								}
							</div>
					}
				</div>
			</div>
		</>
	);
}
