import React, { useEffect, useState } from 'react';
import { setup, isSupported, } from '@loomhq/record-sdk';
import { oembed } from '@loomhq/loom-embed';

import { ReactComponent as LoomIcon } from '../../../../assets/icons/candidate-assessment/loom.svg';
import Button from '../../../sub-component/Button';
import CustomModal from '../../../common/Modal';
const BUTTON_ID = 'loom-record-sdk-button';

export default function LoomVideoRecorder(props) {

	const { videoURL, isBrowserSupport, getLoomVideo, getLoomVideoUrl, isSomthingIsInProcess, isLoomVideoRecording } = props;


	const [loading, setLoading] = useState(true);

	async function setupLoom() {
		// https://dev.loom.com/docs/record-sdk/details/api#buttonemitterevents
		const { supported, error } = await isSupported();

		if (!supported || error) {
			isBrowserSupport(false);
			return;
		}

		const button = document.getElementById(BUTTON_ID);
		if (!button) {
			return;
		}
		const { configureButton } = await setup({
			publicAppId: `${window._env_?.LOOM_APP_ID}`,
		});

		const sdkButton = configureButton({ element: button });
		setLoading(false);

		sdkButton.on('insert-click', async (video) => {
			console.log('insert');
			const { html } = await oembed(video.sharedUrl, { width: 538, height: 403.5 });
			getLoomVideo(html);
			getLoomVideoUrl(video.sharedUrl);
		});

		sdkButton.on('lifecycle-update', async (state) => {
			console.log(state, 'sdkButton-lifecycle-update');
			if (state === 'post-recording' || state === 'active-recording' || state === 'pre-recording') {
				console.log(state, 'sdkButton-lifecycle-update open');

				isLoomVideoRecording(true);
			}
			if (state === 'closed') {
				console.log(state, 'sdkButton-lifecycle-update close');

				isLoomVideoRecording(false);
			}
		});
	}

	useEffect(() => {
		if (!videoURL) {
			setupLoom();
		}
	}, [videoURL]);


	return (
		<Button
			btn={'ragular-btn'}
			preIcon={<LoomIcon />}
			title="Start Loom"
			id={BUTTON_ID}
			disabled={loading || isSomthingIsInProcess}
			processing={loading}
			style={{ opacity: loading ? 0.5 : 1 }}
			toolTip={loading ? 'please wait loom is loading' : ''}
		/>
	);
}
