//https://mui.com/material-ui/react-avatar/
import React, { useEffect, useState } from 'react';
import ProfileIcon from '../../assets/images/onboarding/profile/profile-pic.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { ReactComponent as TickIcon } from '../../assets/icons/components/avtar-badge/Status.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/components/avtar-badge/Status(1).svg';
import { ReactComponent as LockIcon } from '../../assets/icons/components/avtar-badge/Status(2).svg';
import { ReactComponent as OnlineIcon } from '../../assets/icons/components/avtar-badge/Presence.svg';
import { ReactComponent as ExternalIcon } from '../../assets/icons/Views/dashboard/assessments/external-link.svg';
import { AvatarGroup, colors } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getResource, getPublicResource } from '../../redux/thunks/Resource';
import { useMemo } from 'react';
import { stringToColour } from '../../utils/utilities';
import CustomTooltip from '../common/CustomTooltip';


export function GetAvtarImage(props) {
	const dispatch = useDispatch();
	const { size, height, width, src, className, image_id, truncateIcon, image, turnOnBGColors, nameInitals, fontSize, onClick, isForPublic, assessID } = props;
	const [userImage, setUserImage] = useState(null);
	// const [imgCount, setImgCount] = useState(false);
	
	
	const getImage = async () => {
		if (src?.image_id && !image) {
			const imageResult = await dispatch(
				isForPublic ?
					getPublicResource({ 
						data: {
							resource_id: src?.image_id,
							assess_id: assessID,
						}
					})
					:
					getResource({ id: src?.image_id })
			);
			if (!imageResult.error && imageResult?.meta?.requestStatus === 'fulfilled') {
				setUserImage(imageResult.payload.url);
			}
			return;
			
		} else {
			setUserImage(image);
		}
	};


	
	useEffect(() => {
		getImage();
	}, [src?.image_id]);

	return (
		<div className={`custom-avatar-badge ${className}`}
			onClick={onClick}
			style={{
				...(turnOnBGColors ? { backgroundColor: stringToColour(src?.name), } : {}), ...(fontSize ? { fontSize: fontSize } : {})
				, ...(size === 'md' ? { width: 72, height: 72 } : size === 'custom' ? { width: width, height: height } : { width: 72, height: 72 })
			}}>
			{((src?.image_id && userImage)
				? <img className='custom-avatar-img' src={userImage} />
				: nameInitals 
					? `${nameInitals}`?.toUpperCase() 
					: truncateIcon 
						? src?.name 
						: src?.name[0])}
			{!nameInitals && (src?.email ? src?.email.substring(0, 2).toUpperCase() : '')}
		</div>

	);
}

export default function AvtarBadge(props) {

	const { size, status, varrient, users, height, width, src, className, name, turnOnBGColors, showTooltip } = props;

	const handleImageError = (event) => {
		event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAEklEQVR42mJ8/w8AAwAB/EP8mH8JAAAAAElFTkSuQmCC';
	};
	const history = useHistory();
	const getTruncatedIcons = (users) => {
		if (users.length <= 5) {
			return users;
		} else {
			const trucatedUsers = [{
				name: `+${users.length - 4}`,
				truncateIcon: true
			}, ...users.slice(0, 4)];
			return trucatedUsers;
		}
	};

	return (

		<>
			{
				varrient === 'multiple-badges'
					?
					<div className='avatar-group'>
						{
							getTruncatedIcons(users).map((user, i) => {
								return (
									showTooltip && !user.truncateIcon ?
										<CustomTooltip key={i} position='top'
											text={
												<div
													style={{ gap: '6px' }}
													className='d-flex align-items-center pointer'
													onClick={(e) => {
														history.push(`/dashboard/candidates/${user.id}`);
														e.stopPropagation();
														e.preventDefault();
													}}
												>
													{user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email}
													<ExternalIcon />
												</div>
											}>
											<GetAvtarImage
												alt={user?.name}
												turnOnBGColors={turnOnBGColors}
												size="custom"
												width={36}
												height={36}
												src={user}
												truncateIcon={user.truncateIcon}
												nameInitals={user?.nameInitals || ''}
												onClick={(e) => {
													history.push(`/dashboard/candidates/${user.id}`);
													e.stopPropagation();
													e.preventDefault();
												}}
											/>
										</CustomTooltip>
										:
										<GetAvtarImage
											key={i}
											alt={user?.name}
											turnOnBGColors={!user.truncateIcon && turnOnBGColors}
											size="custom"
											width={36}
											height={36}
											src={user}
											truncateIcon={user.truncateIcon}
											nameInitals={user?.nameInitals || ''}
										/>
								);
							})
						}

					</div>
					:
					<div className='avtar-badge-container'>
						{
							status === 'tick'
								?
								<TickIcon className="avtar-badge-status" style={size === 'md' ? { height: '27px', width: '27px' } : { height: '17.5px', width: '17.5px' }} />
								:
								status === 'cross'
									?
									<CrossIcon className="avtar-badge-status" style={size === 'md' ? { height: '27px', width: '27px' } : { height: '17.5px', width: '17.5px' }} />
									:
									status === 'lock'
										?
										<LockIcon className="avtar-badge-status" style={size === 'md' ? { height: '27px', width: '27px' } : { height: '17.5px', width: '17.5px' }} />
										:
										status === 'online'
											?
											<OnlineIcon className="avtar-badge-status" style={size === 'md' ? { height: '14px', width: '14px' } : { height: '8px', width: '8px' }} />
											:
											null
						}
						{/* <Avatar
							className={className}
							alt={src}
							src={src ? src : '/static/images/avatar/1.jpg'}
							sx={size === 'md' ? { width: 72, height: 72 } : size === 'custom' ? { width: width, height: height } : { width: 40, height: 40 }} /> */}
						<div className='custom-avatar-badge' style={{ ...(size === 'md' ? { width: 72, height: 72 } : size === 'custom' ? { width: width, height: height } : { width: 40, height: 40 }), background:'transparent' }}>
							<img className='custom-avatar-img' alt={name?.charAt(0)} src={src ? src : ProfileIcon} onError={handleImageError} />
						</div>
					</div>
			}
		</>
	);
}
