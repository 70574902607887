import React, { useEffect, useState } from 'react';
import Button from '../../../components/sub-component/Button';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getResource } from '../../../redux/thunks/Resource';

export default function WelcomeVideo(props) {

	const [URL, setURL] = useState(props.src);
	const [icon, setIcon] = useState();
	const tenant = useSelector((state) => state.userProfileReducer);
	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			if (tenant?.curr_assess_tenant_profile?.image_id) {
				const imageResult = await dispatch(getResource({ id: tenant?.curr_assess_tenant_profile?.image_id }));
				if (!imageResult.error) {
					setIcon(imageResult.payload.url);
				}
			}
		})();
	}, [tenant?.image_id]);

	const history = useHistory();

	return (
		<div className='welcome-video-container'>
			<div className='assessment-header'>
				{icon ?
					<img className='assessment-header-icon' src={icon} alt='' />
					: 
					<div className='assessment-header-icon-initial'>
						<span className='headline-2 dark-100'>{tenant?.curr_assess_tenant_profile?.company_name?.charAt(0)}</span>
					</div>
				}
				<span className='headline-5 dark-100'>Welcome to the Assessment for {props.title}</span>
				<span className='subtitle-2 dark-50'>{tenant?.curr_assess_tenant_profile?.company_name}</span>
			</div>
			<div className='video'>
				{props.youtubeURL ?
					<ReactPlayer
						width={'100%'}
						height={'100%'}
						controls={true}
						url={URL} />
					:
					<video
						style={{ width: '100%', height: '100%' }}
						src={URL}
						controls={true}
						muted={false}
						disablePictureInPicture={true}
						controlsList='nodownload noremoteplayback noplaybackrate'
					/>
				}
			</div>
			<div className='w-100 d-flex justify-content-end'>
				<Button
					btn='ragular-btn'
					varrient='primary'
					title={'Start Assessment'}
					onClick={props.onPrimaryButtonClick}
					processing={props.processing}
				/>
			</div>
		</div>
	);
}
