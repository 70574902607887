import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import TestContentWrapper from './TestContentWrapper';
import { ReactComponent as RunIcon } from '../../../../assets/icons/candidate-assessment/play-n.svg';
import CodeMirror from '@uiw/react-codemirror';
import { dracula } from '@uiw/codemirror-theme-dracula';
import { langs } from '@uiw/codemirror-extensions-langs';
import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';
import { useDispatch, useSelector } from 'react-redux';
import {
	executeResult,
	getCodelanguage,
	saveCodeSnapShot,
	verifyCodeSnapShot,
	resetExecResultState,
} from '../../../../redux/thunks/Assessment';
import ProcessingIndicator from '../../../../components/common/ProcessingIndicator';
import ReactQuill from 'react-quill';
import hljs from 'highlight.js';
import { useFocusWithin } from '@react-aria/interactions';
import Timer from '../../../../components/common/Timer';
import Button from '../../../../components/sub-component/Button';
import Select from '../../../../components/sub-component/Select';
import { langNameMapping } from '../../../../utils/utilities';


const myTheme = createTheme({
	theme: 'dark',
	settings: {
		background: '#121216',
		foreground: '#f8f8f2',
		caret: '#f8f8f0',
		selection: '#437cde',
		selectionMatch: '#437cde',
		gutterBackground: '#121216',
		gutterForeground: '#6D8A88',
		gutterBorder: 'transparent',
		lineHighlight: 'rgba(255, 255, 255, 0.2)',
	},
	styles: [
		{ tag: t.comment, color: '#6272a4' },
		{ tag: t.string, color: '#f1fa8c' },
		{ tag: t.atom, color: '#bd93f9' },
		{ tag: t.meta, color: '#f8f8f2' },
		{ tag: [t.keyword, t.operator, t.tagName], color: '#ff79c6' },
		{ tag: [t.function(t.propertyName), t.propertyName], color: '#50fa7b' },
		{
			tag: [t.definition(t.variableName), t.function(t.variableName), t.className, t.attributeName],
			color: '#F4C20D',
		},
		{ tag: t.atom, color: '#bd93f9' },
	],
});

const modules = {
	syntax: {
		highlight: (text) => hljs.highlightAuto(text).value,
	},
};

export default function CodingTestCS(props) {
	const { question, assessmentId, testId, testProgress } = props;
	const [codeValue, setCodeValue] = useState(
		question?.initial_code ? atob(question?.initial_code) : '',
	);
	const [selectedPreferableLang, setSelectedPreferableLang] = useState(
		question?.code_languages
			? {
				name: question?.code_languages[0].language_name.charAt(0).toUpperCase() +
					question?.code_languages[0].language_name.slice(1),
				value: question?.code_languages[0].code_language,
				initial_code: question?.code_languages[0].initial_code,
			}
			: question?.code_language
				? {
					name: question?.code_language,
					value: question?.code_language,
					initial_code: question?.initial_code,
				}
				:
				null
	);

	const timerRef = useRef();
	const [pastedText, setPastedText] = useState({
		text: '',
		copy_text: '',
		endIndex: -1,
		isPasted: false
	});
	// const codeValueRef = useRef();
	// const [showSkipQuestionModal, setShowSkipQuestionModal] = useState(false);

	const [activeLineIndex, setActiveLineIndex] = useState(-1);

	const dispatch = useDispatch();
	const assessment = useSelector((state) => state.assessmentReducer);

	const [apiAttempts, setApiAttempts] = useState(0);
	const [error, setError] = useState('');

	const remainTime = useMemo(() => {
		if (!question) return 0;

		timerRef.current = null;
		if (question.initial_code) {
			setCodeValue(atob(question.initial_code));
		} else if (selectedPreferableLang) {
			setCodeValue(atob(selectedPreferableLang.initial_code));
		}

		const serveTime = new Date(question.serve_time);
		const duration = question.duration * 1000;

		const remainingTime = (serveTime.getTime() + duration - Date.now()) / 1000;

		return Number(remainingTime.toFixed(0));
	}, [question]);

	const onQuestionSubmit = () => {

		const data = {
			assess_id: assessmentId,
			test_id: testId,
			ques_id: question.id,
			source_code: btoa(codeValue),
			...(selectedPreferableLang && {
				code_language: selectedPreferableLang.value,
			}),
		};

		if (props.onQuestionSubmit) {
			props.onQuestionSubmit(data);
		}
	};

	const onCodeChange = (value) => {
		setCodeValue(value);
	};

	const verifyCodeSnapShotOnRun = () => {
		if (!codeValue) {
			return;
		}
		const data = {
			assess_id: assessmentId,
			test_id: testId,
			ques_id: question?.id,
			type: 'verify',
			source_code: btoa(codeValue),
			...(selectedPreferableLang && {
				code_language: selectedPreferableLang.value,
			}),
		};

		clearTimeout(timerRef.current);
		dispatch(verifyCodeSnapShot(data));
		setError('');
	};

	const [autoSave, setAutoSave] = useState(false);
	useMemo(() => {
		// console.log(question, 'remainTime');
		if (autoSave && remainTime > 0) {
			const data = {
				assess_id: assessmentId,
				test_id: testId,
				ques_id: question.id,
				type: 'autosave',
				source_code: btoa(codeValue),
				...(selectedPreferableLang && {
					code_language: selectedPreferableLang.value,
				}),
			};

			dispatch(saveCodeSnapShot(data));
			setAutoSave(false);
		}
	}, [autoSave]);

	useMemo(() => {
		setInterval(() => {
			setAutoSave(true);
		}, 5000);
	}, []);

	const GetPastedIndex = (arr1, arr2) => {
		const consecutiveIndexes = [];
		let startIndex = -1;
		let endIndex = -1;

		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] === arr2[0] && i + arr2.length <= arr1.length) {
				const subArray = arr1.slice(i, i + arr2.length);
				// console.log(JSON.stringify(subArray), JSON.stringify(arr2), JSON.stringify(subArray) === JSON.stringify(arr2));
				if (JSON.stringify(subArray) === JSON.stringify(arr2)) {
					startIndex = i;
					endIndex = i + arr2.length - 1;
					break;
				}
			}
		}

		if (startIndex !== -1 && endIndex !== -1) {
			for (let i = startIndex; i <= endIndex; i++) {
				consecutiveIndexes.push(i);
			}
		}

		return consecutiveIndexes;
	};

	useEffect(() => {
		if (pastedText.isPasted && remainTime > 0) {
			// console.log(codeValue?.split('\n'), pastedText?.copy_text?.split('\n'), 'indexes');

			const arr1 = codeValue?.split('\n').map(element => element.trim().replace(' ', ''));
			const arr2 = pastedText?.copy_text?.split('\n').map(element => element.trim().replace(' ', ''));
			const findMatchedIndexes = GetPastedIndex(arr1, arr2);

			const data = {
				assess_id: assessmentId,
				test_id: testId,
				ques_id: question.id,
				type: 'copypaste',
				copy_text: {
					text: pastedText?.copy_text,
					start_index: findMatchedIndexes[0] + 1,
					end_index: findMatchedIndexes[findMatchedIndexes.length - 1] + 1
				},
				source_code: btoa(codeValue),
				...(selectedPreferableLang && {
					code_language: selectedPreferableLang.value,
				}),
			};

			dispatch(saveCodeSnapShot(data));

			setPastedText({
				text: '',
				copy_text: '',
				endIndex: -1,
				isPasted: false
			});
		}

	}, [pastedText.isPasted]);

	const testResults = useMemo(() => {
		if (assessment.exec_result === null) return;

		let ok = 0;
		let error = 0;

		assessment.exec_result.submissions?.forEach((submission) => {
			submission.has_passed === true ? ok++ : error++;
		});

		return { ok, error };

		// return assessment.exec_result.submissions?.filter(submission => !submission?.status?.id !== Judge0Status[3].id);
	}, [assessment.exec_result]);


	useEffect(() => {
		if (apiAttempts < 10 && !assessment?.exec_result_api_success) {
			if (remainTime > 0) {
				const interval = setInterval(() => {
					if (assessment?.code_snapshot?.exec_id) {
						dispatch(
							executeResult({
								id: assessment.code_snapshot.exec_id,
								assess_id: props?.assessmentId,
							}),
						);
						setApiAttempts(apiAttempts + 1);
					}
				}, 3000);

				return () => {
					clearInterval(interval);
				};
			}
		} else if (apiAttempts >= 10) {
			dispatch(resetExecResultState());
			setApiAttempts(0);
			setError('Failed to fetch execution results after 10 tries. Try re-running your code.');
		}
		
	}, [apiAttempts, assessment?.exec_result_api_success, assessment?.code_snapshot?.exec_id]);

	useEffect(() => {
		if (question?.code_language) {
			dispatch(getCodelanguage(question?.code_language));
		} else if (selectedPreferableLang) {
			dispatch(getCodelanguage(selectedPreferableLang.value));
		}
	}, [dispatch, question?.code_language, selectedPreferableLang]);

	useEffect(() => {
		if (assessment?.code_language?.language) {
			hljs.configure({
				languages: [assessment?.code_language?.language],
			});
		}
	}, [assessment?.code_language?.language]);

	useEffect(() => {
		if (question?.code_languages) {
			setSelectedPreferableLang({
				name: question.code_languages[0].language_name.charAt(0).toUpperCase() +
					question.code_languages[0].language_name.slice(1),
				value: question.code_languages[0].code_language,
				initial_code: question.code_languages[0].initial_code,
			});

			setCodeValue(atob(question.code_languages[0].initial_code));
		}
	}, [question?.code_languages]);

	const QuestionTextMemo = useMemo(() => {
		if (!question) return;

		return (
			<ReactQuill
				className='ql-editor-coding-candidate-side text-read-only disable-text-selection'
				theme='bubble'
				readOnly
				modules={modules}
				value={question.text}
			/>
		);
	}, [question]);

	const onCodePaste = (e) => {
		const clipboardData = e.clipboardData || window.clipboardData;
		const pastedData = clipboardData.getData('Text');

		setPastedText({
			...pastedText,
			copy_text: pastedData,
			text: codeValue,
			isPasted: true
		});
	};

	const [timeDuration, setTimeDuration] = useState(0);
	const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: (e) => { },
		onBlurWithin: (e) => {
			setIsSkipModalOpen(false);
		},
	});

	useMemo(() => {
		if (timeDuration === 0) {
			setIsSkipModalOpen(false);
		}
	}, [timeDuration]);

	useMemo(() => {
		console.log(`${codeValue}`, '123321');
	}, [codeValue]);

	return (
		<TestContentWrapper
			title={'Coding Test'}
			duration={remainTime}
			currentQuestion={testProgress?.attempted_ques + 1}
			totalQuestions={testProgress?.total_ques}
			answer={codeValue === question?.initial_code ? null : codeValue}
			onSubmit={(action) => {
				if (action === 'skip') {
					const data = {
						assess_id: props?.assessmentId,
					};
					if (props.onQuestionSubmit) props.onQuestionSubmit(data);
				} else {
					onQuestionSubmit();
				}
			}}
			processing={props.processing}
		>
			{(() => {
				if (!question) {
					return (
						<div className='align-self-center'>
							<ProcessingIndicator className={'medium-indicator'} />
						</div>
					);
				}

				return (
					<div className='assessment-test-coding-container'>
						<div
							className='assessment-test-coding-questions-container'
							style={{ opacity: (timeDuration <= 0) ? '0.3' : '1', pointerEvents: (timeDuration <= 0) ? 'none' : 'auto' }}
						>
							{QuestionTextMemo}
						</div>

						<div
							className='assessment-test-coding-answers-container'
							style={{
								marginBottom: '6px',
								position: 'sticky',
								top: '15px',
								zIndex: '1',
							}}
						>
							<div
								className={`d-flex align-items-center ${question?.code_languages ? 'justify-content-between' : 'justify-content-end'} w-100`}
								style={{
									marginBottom: '12px', opacity: (timeDuration <= 0) ? '0.3' : '1',
									pointerEvents: (timeDuration <= 0) ? 'none' : 'auto'
								}}
							>
								{question?.code_languages && (
									<Select
										className='justify-content-center'
										style={{ maxWidth: '247px' }}
										inputStyle={{
											color: '#121216',
											fontSize: '14px',
											lineHeight: '20px',
										}}
										innerClassName='grape-placeholder'
										placeholder='Select Preferable Language'
										options={question.code_languages.map((lang) => {
											return {
												name:
														lang.language_name.charAt(0).toUpperCase() +
														lang.language_name.slice(1),
												value: lang.code_language,
												initial_code: lang.initial_code,
											};
										})}
										readOnly
										selected={selectedPreferableLang?.value}
										onSelection={(data) => {
											setSelectedPreferableLang(data);
											setCodeValue(atob(data.initial_code));
										}}
									/>
								)}
								<Timer
									duration={remainTime}
									getUpdatedTime={(duration) => {
										setTimeDuration(duration);
									}}
								/>
							</div>
							{
								<div
									className='w-100 pt-2'
									style={{
										borderRadius: '8px 8px 10px 10px',
										background: '#121216',
										opacity: (timeDuration <= 0) ? '0.3' : '1', pointerEvents: (timeDuration <= 0) ? 'none' : 'auto'
									}}
								>
									<CodeMirror
										onPaste={onCodePaste}
										value={codeValue}
										height='326px'
										extensions={[
											langs[langNameMapping(assessment?.code_language?.language)]
												? langs[langNameMapping(assessment?.code_language?.language)]()
												: [],
										]}
										theme={myTheme}
										// theme={dracula}
										indentWithTab={true}
										basicSetup={
											{ indentOnInput: true, tabSize: 4 }
										}
										onChange={onCodeChange}
										placeholder='Write your code here.'
										style={{ borderRadius: '8px 8px 0px 0px' }}
										readOnly={timeDuration <= 0}
									/>
									<div className='run-code-button'>
										{assessment.exec_result !== null &&
												!assessment.is_exec_result_poll && (
											<>
												<span className='body-2 dark-100'>
														Test Output:
												</span>
												<span
													className='body-2'
													style={{ color: '#00D69A', marginLeft: '16px' }}
												>
													{`Ok: ${testResults?.ok}`}
												</span>
												<span
													className='body-2'
													style={{ color: '#FC4848', marginLeft: '16px' }}
												>
													{`Error: ${testResults?.error}`}
												</span>
											</>
										)}
										<Button 
											disabled={
												assessment.processing_code_snapshot ||
													assessment.processing_exec_result ||
													assessment.is_exec_result_poll
											}
											btn='ragular-btn' 
											varrient={'primary'}
											size={'sm'}
											title={'Run'}
											style={{ width: 'max-content', marginLeft: 'auto' }}
											postIcon={<RunIcon />}
											onClick={() => { if (remainTime > 0) { verifyCodeSnapShotOnRun(); } }}
										/>
									</div>
									<div className='CT-result-screen'>
										<div style={{ width: '100%' }}>
											{(() => {
												if (
													assessment.processing_exec_result ||
														assessment.processing_code_snapshot ||
														assessment.is_exec_result_poll 
												) {
													return (
														<span
															style={{ textAlign: 'left', display: 'block' }}
														>
																Compiling...
														</span>
													);
												}

												if (assessment.exec_result === null) {
													return <></>;
												}

												return assessment.exec_result?.tokens?.map(
													(token, index) => {
														let results = `(case ${index + 1}):\n`;

														const submission =
																assessment.exec_result?.submissions?.find(
																	(submission) =>
																		submission.token === token.token,
																);

														if (submission?.message) {
															results += `${atob(
																submission.message,
															)}\n --- \n`;
														}

														if (submission?.stderr) {
															results += `${atob(submission.stderr)}\n`;
														}

														if (token?.input_args) {
															results += `Input parameters -> ${atob(
																token.input_args,
															)}\n`;
														}

														if (token?.expected_output) {
															results += `Expected output -> ${atob(
																token.expected_output,
															)}\n`;
														}

														if (submission?.stdout) {
															results += `Standard output -> ${atob(
																submission.stdout,
															)}\n`;
														}

														if (submission?.compile_output && submission?.status.id != 3) {
															results += `Compile output -> ${atob(
																submission.compile_output,
															)}\n`;
														}

														if (submission?.function_return) {
															results += `Output -> ${atob(
																submission.function_return,
															)}\n`;
														}
														return (
															<div
																key={Math.random() * index}
																style={{
																	textAlign: 'start',
																	borderBottom: '1px solid #FFF',
																	padding: '10px 0',
																	color: submission?.has_passed
																		? '#00D69A'
																		: '#FC4848',
																}}
															>
																<pre
																	style={{ overflow: 'unset', width: 'min-content' }}
																	className='pre-result'>{results}</pre>
															</div>
														);
													},
												);
											})()}
										</div>
									</div>
								</div>
							}
							{ error && 
									<div className='w-100 d-flex align-items-center justify-content-center' style={{marginTop: '24px'}}>
										<span className='danger-text'>{error}</span>
									</div>
							}
							<div
								className='w-100 d-flex justify-content-end align-items-end'
								style={{ marginTop: '24px' }}
							>
								<div tabIndex={-1} {...focusWithinProps}>
									{isSkipModalOpen ? (
										<Button
											processing={props.processing}
											btn='ragular-btn'
											varrient={'primary'}
											title={'Skip Question?'}
											style={{ width: 'max-content', height: '40px' }}
											onClick={() => {
												const data = {
													assess_id: props?.assessmentId,
												};
												if (props.onQuestionSubmit)
													props.onQuestionSubmit(data);

												setIsSkipModalOpen(false);
												setSelectedPreferableLang(null);
											}}
										/>
									) : (
										<Button
											processing={props.processing}
											// disabled={((!selectedChoice || selectedChoice === '') && (timeDuration === null || timeDuration > 0))}
											btn='ragular-btn'
											varrient={'primary'}
											title={
												props.currentQuestionNo === props.totalQuestions || 0
													? 'Submit'
													: 'Next'
											}
											style={{ width: '120px', height: '40px' }}
											onClick={() => {
												if (
													btoa(`${codeValue}`) ===
														(question?.initial_code
															? question?.initial_code
															: selectedPreferableLang?.initial_code) &&
														(timeDuration === null || timeDuration > 0)
												) {
													setIsSkipModalOpen(true);
												} else if (codeValue === '') {
													setIsSkipModalOpen(true);
												} else if (!isSkipModalOpen) {
													onQuestionSubmit();
													setIsSkipModalOpen(false);
												}

												setSelectedPreferableLang(null);
											}}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				);
			})()}
		</TestContentWrapper>
	);
}
