export const QualifyingQuestionsJSON = {
	numeric: {
		type: 'numeric',
		question: '',
		idealAnswer: '',
		candidateAnswer: '',
	},
	boolean: {
		type: 'boolean',
		question: '',
		idealAnswer: '',
		candidateAnswer: '',
	},
	mcqs: {
		type: 'mcq',
		question: '',
		idealAnswer: 0,
		candidateAnswer: '',
		options: ['', '']
	}
};

export const WelcomeVideo = {

	type: 'welcome-video',
	welcome_video: ''
};

export const sectionTypes = [
	{
		type: 'upload_test',
		name: 'File Upload',
		questions: [],
	},
	{
		type: 'skill_test',
		name: 'Skilled Based Test',
		questions: [],
	},
	{
		type: 'value_test',
		name: 'Values Fitness',
		questions: [],
	},
	{
		type: 'coding_test',
		name: 'Coding Test',
		questions: [],
	},
	{
		type: 'personality_test',
		name: 'Personality Test',
		questions: [],
	},
	{
		type: 'video_test',
		name: 'Video Responses',
		intro_text: 'This section includes questions that will assess your personality',
		questions: [],
	},
	{
		type: 'text_test',
		name: 'Text Responses',
		intro_text: 'This section includes some general questions about your',
		allow_file_upload: false,
		questions: [],
	},
	{
		type: 'case_study_test',
		name: 'Case Study',
		questions: [],
		duration: 3600,
		intro_text: 'Your candidate will be able to enter a text answer',
	}
];

export const skill_test_data = [
	{ title: 'Design Systems' },
	{ title: 'Figma' },
	{ title: 'Python' },
	{ title: 'Javascript' },
	{ title: 'Kubernetes' },
	{ title: 'React Native' },
	{ title: 'Node JS' },
	{ title: 'Docker' },
	{ title: 'Redux' }
];