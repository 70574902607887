import { createSlice } from '@reduxjs/toolkit';
import AlertBox from '../../components/common/AlertBox';
import React from 'react';

import {
	createUser,
	inviteUsers,
	listSystemRoles,
	listUsers,
	deleteUser,
	updateUser
} from '../thunks/User';

const UserSlice = createSlice({
	name: 'User Slice',
	initialState: {
		loading: false,
		processing: false,
		data: null,
		processing_user_invite: false,
		loading_system_roles: false,
		system_roles: null,
		error_message: null,
		success_message: null,
	},

	extraReducers: {
		[inviteUsers.pending]: (state) => {
			return {
				...state,
				processing_user_invite: true,
			};
		},
		[inviteUsers.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_user_invite: false,
			};
		},
		[inviteUsers.rejected]: (state, { payload }) => {
			return {
				...state,
				processing_user_invite: false,
			};
		},

		[listUsers.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[listUsers.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				data: payload,
			};
		},
		[listUsers.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

		[createUser.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[createUser.fulfilled]: (state, { payload }) => {
			return {
				...state,
				data: [...state.data, payload],
				processing: false,
			};
		},
		[createUser.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[updateUser.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[updateUser.fulfilled]: (state, { payload }) => {
			const updateUsers = payload?.users;
			const index = updateUsers?.indexOf(updateUsers?.filter((_u, _) => { return _u.email === payload?.email; })[0]);
			updateUsers[index] = { ...updateUsers[index], role: { id: payload.role_id, name: payload.role_id.charAt(0).toUpperCase() + payload.role_id.slice(1) }, user_role: { ...updateUsers[index].user_role, role_id: payload.role_id } };
			return {
				...state,
				data: [...updateUsers],
				processing: false,
			};
		},
		[updateUser.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[deleteUser.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[deleteUser.fulfilled]: (state, { payload }) => {
			return {
				...state,
				data: state.data.filter((d, _) => { return (d.email !== payload); }),
				processing: false,
			};
		},
		[deleteUser.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		/**
		 * List System roles reducer
		 */

		[listSystemRoles.pending]: (state) => {
			return {
				...state,
				loading_system_roles: true,
			};
		},
		[listSystemRoles.fulfilled]: (state, { payload }) => {
			return {
				...state,
				system_roles: payload,
			};
		},
		[listSystemRoles.rejected]: (state) => {
			return {
				...state,
				loading_system_roles: false,
			};
		},
	},
});

export default UserSlice.reducer;
