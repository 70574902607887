import React, { useEffect } from 'react';

import { useHistory } from 'react-router';
import { useState } from 'react';


import { useDispatch } from 'react-redux';


import { useMediaQuery } from '@mui/material';
import { isEmailValid } from '../utils/utilities';
import AlertBox from '../components/common/AlertBox';
import { candidateActivation, candidateInvite, candidatePublicInvite } from '../redux/thunks/Auth';
import OnboardingBox from '../components/OnboardingBox';
import Input from '../components/sub-component/Input';
import Button from '../components/sub-component/Button';
import ProcessingIndicator from '../components/common/ProcessingIndicator';


function CandidateInvite() {
	const [email, setEmail] = useState();
	const [processing, setProcessing] = useState(false);
	const [alert, setAlert] = useState(null);
	const [isloading, setIsLoading] = useState(true);

	const dispatch = useDispatch();
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	const handleChange = (value) => {
		setEmail(`${value}`.trim());
		if (alert) {
			setAlert();
		}
	};
	useEffect(() => {
		setProcessing(true);
		const fetchData = async () => {
			const searchParams = new URLSearchParams(window.location.search);
			const invite_id = searchParams.get('invite_id');
			console.log(invite_id, 'invite_id');
			const valid = await dispatch(candidateInvite({ invite_id: invite_id }));
			if (valid.payload?.is_valid === false) {
				window.location.replace('/assessment-unavailable');
			}
			setProcessing(false);

		};

		fetchData();

	}, []);
	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsLoading(false);
		}, 4000);

		return () => clearTimeout(timeout);

	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const searchParams = new URLSearchParams(window.location.search);
			const inviteId = searchParams.get('invite_id');
			const response = await dispatch(candidateInvite({ invite_id: inviteId }));
			const { tenant_id, spec_id, tenant_status } = response.payload;
			setProcessing(true);
			const candiadteInvite = await dispatch(candidatePublicInvite({ email: email, tenant_id, spec_id, tenant_status }));
			setProcessing(false);
			setAlert(
				<AlertBox description="Invite sent" color='success' title="Success" onCloseAlert={() => { setAlert(null); }} />
			);
			if (candiadteInvite?.error?.message === 'Rejected') {
				setAlert(
					<AlertBox description="Provided email is already a non-candidate user on the platform" color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
				);

			}
		} catch (error) {
			console.log(error, 'error321');
			setProcessing(false);
			if (error.response && error.response.status === 400) {

				setAlert(
					<AlertBox description={`Error occurred: ${error.response.data.message}`} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
				);
			} else {
				// Handle other errors
				setAlert(
					<AlertBox description={`Error occurred: ${error.message}`} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
				);
			}
			window.location.replace('/assessment-unavailable');
		}
	};


	return (
		<>
			{isloading ? (
				<>
					<div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<ProcessingIndicator />

					</div>

				</>
			) : (
				<>
					<OnboardingBox>
						<div
							className="d-flex flex-column login-form-container"
						// style={{ maxWidth: '450px' }}
						>
							<div className="form">
								<div className={`${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-3'}`}>Enter Your Email</div>
								<div className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '3'} dark-50`} style={{ marginTop: '10px' }}>
									{`Thank you for your interest! Please provide your email address below to receive your invitation.`}
								</div>
								<div>
									<form onSubmit={handleSubmit}>
										<div className="login-input-group">
											<div className="d-flex flex-column mt-4">
												<label className='body-2 dark-50 mb-2' style={{ marginLeft: '24px' }}>Email</label>

												<Input
													// placeholder="Email"
													onChange={(e) => {
														handleChange(e.target.value);
													}}
													type={'text'}
													value={email}
													onBoardingInput
													varrient={'primary'}

												/>
											</div>
											<div className='mt-4'>
												{alert}
											</div>
											<div className="">
												<Button
													themeRectangleButton
													// onClick={handleSubmit}
													processing={processing}
													type={'submit'}
													title={'Get Invite'}
													style={{ height: '40px' }}
												/>
											</div>

										</div>
									</form>
								</div>
							</div>
						</div>
					</OnboardingBox>
				</>
			)}

		</>
	);
}

export default CandidateInvite;
