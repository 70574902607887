import { createSlice } from '@reduxjs/toolkit';
import { CreateIntercomTestPackTicket } from '../thunks/CustomerSupport';

const CustomerSupport = createSlice({
	name: 'Test Pack',

	initialState: {
		loading: false,
		data: null,
		error_message: null,
		success_message: null,
	},

	reducers: {},

	extraReducers: {

		[CreateIntercomTestPackTicket.pending]: (state) => {
			return {
				...state,
				loading: true
			};
		},

		[CreateIntercomTestPackTicket.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				data: payload,
			};
		},

		[CreateIntercomTestPackTicket.rejected]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				error_message: payload
			};
		}
	},
});

export default CustomerSupport.reducer;