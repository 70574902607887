import React, { useMemo, useState } from 'react';
import Button from '../../../sub-component/Button';
// import { ReactComponent as DeleteIcon } from '../../../../assets/icons/components/dashboard/trash.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/components/dashboard/plus-icon.svg';
import { ReactComponent as PlusBtnIcon } from '../../../../assets/icons/components/dashboard/plusBtn.svg';
import { ReactComponent as Search } from '../../../../assets/icons/Views/candidate/assessments/search.svg';
import Table from '../../../common/Table';
import AddTeamModal from './AddTeamModal';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { listSystemRoles, listUsers } from '../../../../redux/thunks/User';
import ProcessingIndicator from '../../../common/ProcessingIndicator';
import { listAssessSpec } from '../../../../redux/thunks/AssessSpec';
import { deleteUser } from '../../../../redux/thunks/User';
import { UserRole } from '../../../../utils/Constants';
import CustomModal from '../../../common/Modal';
import { calculateTimeDifferenceInDays } from '../../../../utils/utilities';
import Input from '../../../sub-component/Input';

export default function TeamSettings() {
	const teamTableHeader = [
		{
			id: 'email',
			name: 'Email',
			type: 'string',
			disableSorting: true,
			screens: ['web', 'tab', 'mob'],
		},
		{
			id: 'role',
			name: 'Role',
			type: 'string',
			disableSorting: true,
			screens: ['web', 'tab'],
		},
		{
			id: 'action',
			name: 'action',
			type: 'component',
			disableSorting: true,
			screens: ['web', 'tab', 'mob'],
		},
	];

	const searchColumns = ['email', 'role',];

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [openedModalAction, setOpenedModalAction] = useState('');
	const [selectedUserDetails, setSelectedUserDetails] = useState(null);
	const [customSearch, setCustomSearch] = useState();

	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const [isReady, setIsReady] = useState(false);

	const user = useSelector((state) => state.userReducer);
	const assessSpec = useSelector((state) => state.assessSpecReducer);
	const dispatch = useDispatch();

	const subscription = useSelector((state) => state.subscriptionReducer);
	const expireAt = subscription?.user_subscription?.length > 0 ? subscription?.user_subscription[0]?.expires_at : null;
	const daysLeft = calculateTimeDifferenceInDays(expireAt);
	const openLeftDaysDisclaimer = sessionStorage.getItem('openLeftDaysDisclaimer');

	const handleDeleteUser = async () => {
		const dispatched = await dispatch(
			deleteUser({ id: selectedUserDetails.id, email: selectedUserDetails.email })
		);
		setIsDeleteModalOpen(false);
	};

	const auth = useSelector((state) => state.authReducer);

	const rowsData = useMemo(() => {
		if (user?.data === null) return [];

		return user?.data?.map((user, index) => {
			return {
				email: user?.email,
				role: user?.role?.name,

				action: <div className='d-flex justify-content-between' key={`icon_${index}`} style={{ width: 'fit-content' }}>
					{(user?.role?.name !== UserRole.OWNER && user?.email !== auth?.data?.email && user?.role?.name !== auth?.data?.role?.name) && (
						<React.Fragment>
							<div className='icon-wrapper' onClick={() => { setIsModalOpen(true); setOpenedModalAction('update'); setSelectedUserDetails(user); }}>
								<span className='edit-icon icon-black-500' style={{ width: '24px', height: '24px' }} />
							</div>

							<div className='icon-wrapper danger-wrapper' onClick={() => { setIsDeleteModalOpen(true); setSelectedUserDetails(user); }}>
								<span className='trash-icon icon-black-500' style={{ width: '24px', height: '24px' }} />
							</div>
						</React.Fragment>
					)}
				</div>
			};
		});
	}, [user?.data]);

	const teamModal = useMemo(() => {

		if (user?.system_roles === null) return <></>;
		return (
			<AddTeamModal
				open={isModalOpen}
				handleCloseModal={() => {
					setSelectedUserDetails(null);
					setIsModalOpen(false);
				}}
				selectedUser={selectedUserDetails}
				action={openedModalAction}
				users={user?.data}
				systemRoles={user?.system_roles || []}
				assessSpecs={assessSpec?.data || []}
				adminEmail={user?.data?.length && user?.data[0].email}
			/>
		);
	}, [user?.system_roles, isModalOpen, user?.data]);

	useEffect(() => {
		dispatch(listUsers({ query: '?expand=[user_role, role]' }));
		dispatch(listSystemRoles());

		if (assessSpec.data === null) {
			dispatch(listAssessSpec({ query: '?limit=100' }));
		}
		setIsReady(true);
	}, []);

	if (!isReady || user?.loading) {
		return <ProcessingIndicator className='medium-indicator' />;
	}

	return (
		<>
			<CustomModal 
				open={isDeleteModalOpen}
				varrient='alert-warning-modal'
				dangerAlert={true}
				title="Delete Member"
				description='Are you sure want to delete team member'
				handleCloseModal={() => { setIsDeleteModalOpen(false); }}
				confirmBtnTitle='Delete'
				onConfirm={handleDeleteUser}
			/>
			<div className='dashboard-page'>
				<div className='page-header d-flex flex-row align-items-center justify-content-between'>
					<span className='headline-3 dark-100'>{'Team Settings'}</span>
					{isOnMobileScreen ? (
						<div  style={{height:'44px',width:'44px' , background:'#121216',borderRadius:'50px',display:'flex',justifyContent:'center',alignItems:'center'}}	onClick={() => {
							setIsModalOpen(true);
							setOpenedModalAction('add');
						}}> <PlusIcon /></div>
					
					) : (
						<Button
							btn='ragular-btn'
							varrient='primary'
							preIcon={<PlusIcon />}
							title={'Add New Team Member'}
							// className='btn-sm'
							onClick={() => {
								setIsModalOpen(true);
								setOpenedModalAction('add');
							}}
						/>
					)}
				</div>
				<div className={`${(daysLeft < 6 && auth?.data?.tenant_status === 'trialing' && !openLeftDaysDisclaimer) && 'left-day-message-main-box'} page-content`}>
					<div className='add-new-assessment-container'>
						{teamModal}
					</div>
					<div className=' w-100 ' style={{height: 'calc(100vh - 145px)',borderRadius:'24px',background:'white'}} >
						{/* <div className='assessments-search-bar mt-3 mb-3'>
							<div className='d-flex' style={{ gap: '0.5em' }}>
								<div className='search'>
									<Search height={'24px'} width={'24px'} />
									<Input
										inputStyle={{
											background: 'transparent',
											border: 'none',
										}}
										className={'w-100'}
										placeholder={'Search ...'}
										onChange={(e) => {
											setCustomSearch(e);
										}}
									/>
								</div>
							</div>
						</div> */}
						<Table 
							headers={teamTableHeader}
							data={rowsData} 
							searchColumns={searchColumns} 
							// disableSearchInput={true}
							customSearch={customSearch}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
