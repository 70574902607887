import { createSlice } from '@reduxjs/toolkit';
import { getPublicResult, getPublicCodeReport } from '../thunks/PublicResult';

const PublicResultSlice = createSlice({
    name: 'PublicResultSlice',
    initialState: {
        loading: false,
        processing: false,
        data: null,
        public_result_data: null,
        public_code_snapshot: null,
        error_message: null,
        success_message: null,
    },

    extraReducers: {

        [getPublicResult.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [getPublicResult.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                public_result_data: payload,
            };
        },
        [getPublicResult.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error_message: payload
            };
        },

        [getPublicCodeReport.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [getPublicCodeReport.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                public_code_snapshot: payload,
            };
        },
        [getPublicCodeReport.rejected]: (state) => {
            return {
                ...state,
                loading: false,
            };
        },
    },
});

export default PublicResultSlice.reducer;