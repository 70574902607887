import React from 'react';
import PropTypes from 'prop-types';

/**
 * 
 * @param {object} props 
 * @param {'medium-indicator' | 'small-indicator'} props.className 
 * @returns 
 */
export default function ProcessingIndicator(props) {
	if (props.varrient === 'grey') {
		return (
			<div className={`processing-indicator-wrapper ${props.className}`}>
				<div className="processing-indicator grey" style={{...props.style, width: '100%', height: '100%' }} />
			</div>
		);
	} else {
		return (
			<div className={`processing-indicator-wrapper ${props.className}`}>
				<div className="processing-indicator primary" style={{...props.style, width: '100%', height: '100%' }} />
			</div>
		);
	}

}

ProcessingIndicator.propTypes = {

	className: PropTypes.string

};
