import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CodingTestDetails from '../../../components/dashboard/test-details/CodingTestDetails/CodingTestDetails';
import { ReactComponent as CodingIcon } from '../../../assets/icons/components/code-resposnse-result/Frame 2608688.svg';
import { ReactComponent as SmileIcon } from '../../../assets/icons/components/code-resposnse-result/smile.svg';
import { ReactComponent as CSTIcon } from '../../../assets/icons/components/code-resposnse-result/cst.svg';

import { ReactComponent as AssessmentUploadImageIcon } from '../../../assets/icons/Views/dashboard/assessments/Entity-avatar.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/components/code-resposnse-result/clock.svg';
import { ReactComponent as CodeIcon } from '../../../assets/icons/components/code-resposnse-result/code.svg';
import { ReactComponent as StartIcon } from '../../../assets/icons/components/code-resposnse-result/edit.svg';
import { ReactComponent as CompleteIcon } from '../../../assets/icons/components/code-resposnse-result/check-circle.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/Views/dashboard/test-detail/x.svg';
import { CommonContext } from '../../../utils/contexts/CommonContext';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { DateToMMDDYYYY, secondsToDhms } from '../../../utils/utilities';
import { Avatar, useMediaQuery } from '@mui/material';
import { getCodelanguage } from '../../../redux/thunks/Assessment';
import AntiCheatMonitor from './test-details-right-view-extended-screens/AntiCheatMonitor';
import PersonalityResponseDetails from './PersonalityResponseDetails';
import PersonalityInsightDetails from './PersonalityInsightDetails';
import CaseStudyQuestionDetails from './CaseStudyQuestionDetails';
import { GetAvtarImage } from '../../../components/dashboard/AvtarBadge';
import { getAssessSpec } from '../../../redux/thunks/AssessSpec';
import ProgressBar from '../../../components/common/ProgressBar';

export default function TestDetailedView(props) {
	const isOnWebScreen = useMediaQuery('(min-width:1280px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const { assessment, candidate } = props;

	const { commonValues, setCommonValues } = useContext(CommonContext);
	const dispatch = useDispatch();

	const [currentTest, setCurrentTest] = useState('');
	const [selectedResponse, setSelectedResponse] = useState(null);

	const { code_snapshot, processing_code_snapshot, code_language } = useSelector((state) => state.assessmentReducer);

	useMemo(() => {
		if (commonValues?.detailedTestView?.testType === 'coding_test') {
			setCurrentTest(code_snapshot?.test);
			setSelectedResponse(code_snapshot?.responses[0]);
			// dispatch(getCodelanguage(code_snapshot?.snapshots[code_snapshot.snapshots.length - 1].code_language));
		}
	}, [code_snapshot]);

	useEffect(() => {
		if (assessment?.spec_id && candidate) {
			dispatch(getAssessSpec({ id: assessment?.spec_id }));
		}
	}, [assessment]);

	const nameHandler = (assessment) => (assessment?.user?.first_name || assessment?.user?.last_name) ? `${assessment?.user?.first_name || ''} ${assessment?.user?.last_name || ''}` : `${assessment?.email || '-'}`;
	return (
		<>
			{

				<div className={`test-detailed-container hide-scrollbar ${commonValues.detailedTestView.isExpanded ? 'test-detailed-view-expanded' : 'minimized-test-detailed-view'}`}>
					{/* <div className='w-100 d-flex justify-content-end pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
						<CrossIcon style={{width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px'}}/>
					</div> */}
					{commonValues.detailedTestView.testType === 'personality_insight' ?
						<>
							<div
								className='w-100 d-flex flex-column'
								style={{ gap: '24px' }}
							>
								<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{ gap: '24px' }}>
									<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
										<SmileIcon />
										<span className='headline-3 dark-100 text-start'>Personality Insights</span>
									</div>
									<div className='d-flex justify-content-end pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
										<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
									</div>
								</div>
								<PersonalityInsightDetails assessment={assessment} />
							</div>
						</>
						:
						commonValues.detailedTestView.testType === 'personality_response' ?
							<>
								<div
									className='w-100 d-flex flex-column'
									style={{ gap: '24px' }}
								>
									<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{ gap: '24px' }}>
										<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
											<SmileIcon />
											<span className='headline-3 dark-100 text-start'>Personality Response</span>
										</div>
										<div className='d-flex justify-content-end pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
											<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
										</div>
									</div>
									<PersonalityResponseDetails assessment={assessment} />
								</div>
							</>
							:
							commonValues.detailedTestView.testType === 'case_study_test' ?
								<>
									<div
										className='w-100 d-flex flex-column'
										style={{ gap: '24px' }}
									>
										<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{ gap: '24px' }}>
											<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
												<CSTIcon />
												<span className='headline-3 dark-100 text-start'>Case Study Question Statement</span>
											</div>
											<div className='d-flex justify-content-end pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
												<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
											</div>
										</div>
										<CaseStudyQuestionDetails />
									</div>
								</>
								:
								commonValues.detailedTestView.testType === 'anti_cheat_monitor' ?
									<div
										className='w-100 d-flex flex-column'
										style={{ gap: '24px' }}
									>
										<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{ gap: '24px' }}>
											<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
												<span className='headline-3 dark-100 text-start'>Anti-Cheat Monitor</span>
											</div>
											<div className='d-flex justify-content-end pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
												<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
											</div>
										</div>
										<AntiCheatMonitor assessment={props.assessment} />
									</div>
									:
									<>
										{
											(processing_code_snapshot || !selectedResponse)
												?
												<div className='w-100 d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
													<div className='processing-indicator primary' style={{ width: '25px', height: '25px' }} />
												</div>
												:
												<>
													<div className='w-100 d-flex flex-column' style={{ gap: '24px' }}>
														<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{ gap: '24px' }}>
															<div className='d-flex flex-row align-items-center' style={{ gap: '12px' }}>
																<CodingIcon />
																<span className='headline-3 dark-100 text-start'>{commonValues.detailedTestView.testType === 'coding_test' ? 'Coding Test' : 'Test Name'}</span>
															</div>
															<div className='d-flex justify-content-end pointer' onClick={() => { setCommonValues({ ...commonValues, detailedTestView: { isExpanded: false, testType: null, data: null } }); }}>
																<CrossIcon style={{ width: isOnMobileScreen ? '34px' : '44px', height: isOnMobileScreen ? '34px' : '44px' }} />
															</div>
														</div>
														<div className='w-100 d-flex flex-column justify-content-between' style={{ gap: '24px' }}>
															<div className='d-flex align-items-center coding-test'>
																<div className='d-flex flex-column align-items-start' style={{ gap: '8px' }}>
																	<span className='headline-4 dark-100'
																		title={currentTest?.title}
																		style={{ textAlign: 'left' }}
																	>
																		{currentTest?.title}
																	</span>
																	<span> <ClockIcon /> <span className='body-2 dark-50'>Total Time</span> <span className='body-2' style={{ color: '#121216' }}>{secondsToDhms(currentTest?.duration)} mins</span></span>
																	<span> <CodeIcon /> <span className='body-2 dark-50'>Language Used</span>
																		<span className='body-2 dark-100'>
																			{
																				` ${selectedResponse?.code_language.charAt(0).toUpperCase() +
																			selectedResponse?.code_language.slice(1)}`
																			}
																		</span>
																	</span>
																</div>
															</div>
															<div className='w-100 d-flex align-items-center justify-content-between'>
																<div className='w-100 d-flex flex-column align-items-start' style={{ marginRight: '24px', gap: '8px' }}>
																	<div className='w-100 d-flex flex-row align-items-start justify-content-between' style={{ gap: '16px' }}>
																		<span className='headline-4 dark-100' style={{ textAlign: 'left' }}>{nameHandler(assessment) || 'Candidate Name'}</span>
																		<div style={{ width: '100px' }}>
																			<ProgressBar value={parseInt((currentTest?.score * 100) || 0)} />
																		</div>
																	</div>
																	<span> <StartIcon /> <span className='body-2 dark-50'>Started on </span> <span className='body-2 dark-100 ' >{DateToMMDDYYYY(currentTest?.started_at)}</span></span>
																	<span> <CompleteIcon /> <span className='body-2 dark-50'>Completed on </span> <span className='body-2 dark-100' >{DateToMMDDYYYY(currentTest?.completed_at)}</span></span>
																</div>
															</div>
														</div>
														{/* { isOnWebScreen ?
															:
															<div className='w-100 d-flex flex-column justify-content-between' style={{ marginTop: '32px', gap: '24px' }}>
																<div className='d-flex align-items-center coding-test'>
																	{
																		currentTest.icon
																			?
																			<GetAvtarImage
																				className='headline-small sidebar-container-image pointer'
																				src={{ image_id: currentTest.icon, name: currentTest?.title }}
																				image={currentTest.icon}
																				size='custom'
																				width={80}
																				height={80}
																			// src={currentTest.icon}
																			// sx={{ width: 80, height: 80 }} 
																			/>
																			:
																			<AssessmentUploadImageIcon style={{ height: '80px', width: '80px' }} />
																	}
																	<div className='d-flex flex-column align-items-start' style={{ marginLeft: '24px', gap: '8px' }}>
																		<span className='headline-small semibold black-700'
																			title={currentTest?.title}
																			style={{ textAlign: 'left', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{currentTest?.title}</span>
																		<span> <ClockIcon /> <span className='body-2 black-500'>Total Time</span> <span className='body-2 medium primary-text'>{secondsToDhms(currentTest?.duration)} mins</span></span>
																		<span> <CodeIcon /> <span className='body-2 black-500'>Language Used</span>
																			<span className='body-2 medium primary-text'>
																				{
																					` ${selectedResponse?.code_language.charAt(0).toUpperCase() +
																					selectedResponse?.code_language.slice(1)}`
																				}</span>
																		</span>
																	</div>
																</div>
																<div className='d-flex align-items-center'>
																	{
																		// cum_score === null ?
																		// <></>
																		// :
																		<div style={{ width: '80px', height: '80px' }}>
																			<CircularProgressbarWithChildren
																				strokeWidth={9}
																				// value={(data.status === 'NOT_QUALIFIED' || data.status === 'DECLINED') ? 5 : score}
																				value={parseInt((currentTest?.score * 100) || 0)}
																				styles={buildStyles({
																					rotation: 0.25,
																					strokeLinecap: 'butt',
																					// pathTransitionDuration: 5,
																					// Colors
																					pathColor: (currentTest?.score * 100) || 0 > 79 ? '#00D69A' : (currentTest?.score * 100) || 0 > 64 ? '#46B4FB' : (currentTest?.score * 100) || 0 > 50 ? '#FFF8E0' : '#FC4848',
																					// textColor: '#f88',
																					trailColor: (currentTest?.score * 100) || 0 > 79 ? '#E0FFF6' : (currentTest?.score * 100) || 0 > 64 ? '#D6F0FF' : (currentTest?.score * 100) || 0 > 50 ? '#FFF8E0' : '#FFE1E1',
																					backgroundColor: '#3e98c7',
																				})}
																			>
																				<span className='body-2 medium black-700' style={{ marginBottom: '-6px' }}>Score</span>
																				<span className='body-1 medium' style={{ color: (currentTest?.score * 100) || 0 > 79 ? '#00D69A' : (currentTest?.score * 100) || 0 > 64 ? '#46B4FB' : (currentTest?.score * 100) || 0 > 50 ? '#FFF8E0' : '#FC4848', marginTop: '2px' }}>{parseInt((currentTest?.score * 100) || 0)}%</span>
																			</CircularProgressbarWithChildren>
																		</div>
																	}
																	<div className='d-flex flex-column align-items-start' style={{ marginLeft: '24px', gap: '8px' }}>
																		<span className='headline-small semibold black-700' style={{ textAlign: 'left', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{nameHandler(assessment) || 'Candidate Name'}</span>
																		<span> <StartIcon /> <span className='body-2 black-500'>Started on </span> <span className='body-2 medium primary-text'>{DateToMMDDYYYY(currentTest?.started_at)}</span></span>
																		<span> <CompleteIcon /> <span className='body-2 black-500'>Completed on </span> <span className='body-2 medium primary-text'>{DateToMMDDYYYY(currentTest?.completed_at)}</span></span>
																	</div>
																</div>
															</div>
													} */}
														{/* {
														currentTest?.tp_data?.description &&
														<div className='d-flex' style={{ textAlign: 'left', marginTop: '24px' }}>
															<span className='body-1' style={{borderRadius:'24px',padding:'2em' ,backgroundColor:'white',border:'1px solid #ECEDF1'}}>
																{currentTest?.tp_data?.description}
															</span>
														</div>
													} */}
														<CodingTestDetails selectedResponse={selectedResponse} getSelectedResponse={(res) => { setSelectedResponse(res); }} />
													</div>
												</>
										}
									</>
					}


				</div>
			}
		</>

	);
}
