import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import { ReactComponent as VariablesIcon } from '../../../../assets/icons/Views/dashboard/create-assessment/variables.svg';

export default function Editor({ modules, formats, updatePreview, customizedTemplate, setCustomizedTemplate, deadlineDatetime, deadlineDatetimeIso }) {
	const quillRef = useRef([]);
	// quillRef.current = [];
	const regex = /<p>(.*?){{\s*deadline_datetime\.formatted\s*}}(.*?)<\/p>/;

	function SelectableTextWithOptions() {
		const [showOptions, setShowOptions] = useState(false);

		const options = [{ name: 'company_name', value: '{{ company_name }}' }, { name: 'assessment_title', value: '{{ assessment_title }}' }, { name: 'deadline', value: '{{ deadline }}' }, { name: 'deadline_datetime', value: '{{ deadline_datetime.formatted }}' }];

		const handleClick = () => {
			setShowOptions(!showOptions);
		};

		const handleOptionClick = (option) => {
			setShowOptions(false);
			if (quillRef.current) {
				const quillEditor = quillRef.current.getEditor();
				quillRef.current.getEditor().insertEmbed(quillEditor.selection.savedRange.index, option);
				setCustomizedTemplate(quillRef.current.value?.replace(regex, `<p>$1<a href='https://www.timeanddate.com/worldclock/fixedtime.html?iso=${deadlineDatetimeIso}&utc=224' target="_blank">${deadlineDatetime} UTC</a>$2</p>`));
				updatePreview(quillRef.current.value?.replace(regex, `<p>$1<a href='https://www.timeanddate.com/worldclock/fixedtime.html?iso=${deadlineDatetimeIso}&utc=224' target="_blank">${deadlineDatetime} UTC</a>$2</p>`));
			}
		};
		return (
			<div >
				<div
					className="selectable-text"
					onClick={handleClick}
					style={{
						position: 'relative',
						width: '20px'
					}}
				>
					<VariablesIcon height={'24px'} width={'19px'} />
				</div>
				{showOptions && (
					<div className="custom-options">
						{options.map((option) => (
							<div
								key={option}
								className="option ql-insertStar pointer"
								onClick={() => handleOptionClick(option.value)}
							>
								{option.name}
							</div>
						))}
					</div>
				)}
			</div>
		);
	}


	const CustomToolbar = () => (
		<div id="toolbar">
			<span className='ql-formats'>
				<button className="ql-bold" />
				<button className="ql-italic" />
				<button className="ql-underline" />
			</span>
			<span className='ql-formats'>
				<button className="ql-list" value='ordered' />
				<button className="ql-list" value='bullet' />
				<button className="ql-align ql-active" />
				<button className="ql-align" value='center' />
				<button className="ql-align" value='right' />
			</span>
			<span className="ql-formats"><button type="button" className="ql-size" value="large">
				<svg viewBox="15 15 70 70">
					<path className="ql-fill ql-even" d="M52.6,23.6L36.8,72.4h6.9h0.8h3.6l2.7-9.5h15.4l2.7,9.5h11.4L64.8,23.6H52.6z M58.5,36.7h0.3
        l5.1,17.4H53.5L58.5,36.7L58.5,36.7z M26,39.9L15.5,72.4h7.6l1.8-6.3h9.6l4.1-12.5l-4.4-13.6L26,39.9L26,39.9z M30,48.6L30,48.6
        l3.6,11.7h-6.9L30,48.6L30,48.6z"></path>
				</svg></button></span>
			<span className='ql-formats'>
				<SelectableTextWithOptions />
			</span>
			<span className='ql-formats'><button className="ql-link" /></span>
			<span className='ql-formats'><button className="ql-clean" /></span>
		</div>
	);
	return (
		<div style={{ height: '100%', display:'flex', flexDirection:'column'  }} className="text-editor">
			<CustomToolbar />
			<ReactQuill
				ref={quillRef}
				style={{ height: '100%'}}
				modules={modules}
				formats={formats}
				value={customizedTemplate}
				theme={'snow'} // pass false to use minimal theme
				placeholder='Leave it empty to use the default template'
				onChange={(value) => { setCustomizedTemplate(value?.replace(regex, `<p>$1<a href='https://www.timeanddate.com/worldclock/fixedtime.html?iso=${deadlineDatetimeIso}&utc=224' target="_blank">${deadlineDatetime} UTC</a>$2</p>`)); updatePreview(value?.replace(regex, `<p>$1<a href='https://www.timeanddate.com/worldclock/fixedtime.html?iso=${deadlineDatetimeIso}&utc=224' target="_blank">${deadlineDatetime} UTC</a>$2</p>`)); }}
			/>
		</div>
	);
}
