import React, { Fragment, useMemo, useRef, useEffect, useState } from 'react';
import { ReactComponent as PlayIcon } from '../../../../../assets/icons/Views/dashboard/assessments/book.svg';
import { ReactComponent as BarChartIcon } from '../../../../../assets/icons/Views/dashboard/assessments/bar-chart-2.svg';
import { ReactComponent as CodeIcon } from '../../../../../assets/icons/Views/dashboard/assessments/code.svg';
import { ReactComponent as UserCheckIcon } from '../../../../../assets/icons/Views/dashboard/assessments/user-check.svg';
import { ReactComponent as SmileIcon } from '../../../../../assets/icons/Views/dashboard/assessments/smile.svg';
import { ReactComponent as UploadIcon } from '../../../../../assets/icons/Views/dashboard/assessments/upload.svg';
import { ReactComponent as TypeIcon } from '../../../../../assets/icons/Views/dashboard/assessments/type.svg';
import { ReactComponent as VideoIcon } from '../../../../../assets/icons/Views/dashboard/assessments/video.svg';
import { ReactComponent as WarningIcon } from '../../../../../assets/icons/Views/dashboard/assessments/alert-circle.svg';
import { ReactComponent as YelloWarningIcon } from '../../../../../assets/icons/Views/dashboard/assessments/yellow-alert-circle.svg';
// import { ReactComponent as TimerIcon } from '../../../../../assets/icons/Views/dashboard/assessments/HourglassMedium.svg';
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/Views/dashboard/assessments/trash.svg';
import { ReactComponent as TickIcon } from '../../../../../assets/icons/Views/dashboard/assessments/tick.svg';
import { ReactComponent as MoveIcon } from '../../../../../assets/icons/Views/dashboard/assessments/move.svg';
import { ReactComponent as MaximizeIcon } from '../../../../../assets/icons/Views/dashboard/assessments/maximize-2.svg';
import Button from '../../../../../components/sub-component/Button';
import { sectionTypes } from '../../../../../utils/assessmentQuestionsJSON';
import FileUpload from '../../../../../components/dashboard/assessments/create-assessment/section-types/FileUpload';
import { useMediaQuery } from '@mui/material';
import SkillBasedTest from '../../../../../components/dashboard/assessments/create-assessment/section-types/SkillBasedTest';
import ValueFitness from '../../../../../components/dashboard/assessments/create-assessment/section-types/ValueFitness';
import CodingTest from '../../../../../components/dashboard/assessments/create-assessment/section-types/CodingTest';
import PersonalityTest from '../../../../../components/dashboard/assessments/create-assessment/section-types/PersonalityTest';
import Responses from '../../../../../components/dashboard/assessments/create-assessment/section-types/Responses/Responses';
import CaseStudyTest from '../../../../../components/dashboard/assessments/create-assessment/section-types/CaseStudyTest';
import CustomModal from '../../../../../components/common/Modal';
import CustomTooltip from '../../../../../components/common/CustomTooltip';
import { AssessmentTestValidation } from '../AssessmentCreationValidators';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus, TestType, PersonalityJobRole, PersonalitySeniority, PersonalityIndustry } from '../../../../../utils/Constants';
import { useFocusWithin } from '@react-aria/interactions';
import AlertBox from '../../../../../components/common/AlertBox';
import { CreateIntercomTestPackTicket } from '../../../../../redux/thunks/CustomerSupport';
import dropSound from '../../../../../assets/icons/Views/dashboard/create-assessment/dropsoundeffect.mp3';
import ProcessingIndicator from '../../../../../components/common/ProcessingIndicator';



export const _sectionTypes = [
	{ id: 'skill_test', icon: <BarChartIcon style={{ width: '20px', height: '20px' }} />, title: 'Skill Based Test' },
	{ id: 'coding_test', icon: <CodeIcon style={{ width: '20px', height: '20px' }} />, title: 'Coding Test' },
	{ id: 'value_test', icon: <UserCheckIcon style={{ width: '20px', height: '20px' }} />, title: 'Values Fit Test' },
	{ id: 'personality_test', icon: <SmileIcon style={{ width: '20px', height: '20px' }} />, title: 'Personality Test' },
	{ id: 'case_study_test', icon: <PlayIcon style={{ width: '20px', height: '20px' }} />, title: 'Case Study' },
	{ id: 'text_test', icon: <TypeIcon style={{ width: '20px', height: '20px' }} />, title: 'Text Responses' },
	{ id: 'video_test', icon: <VideoIcon style={{ width: '20px', height: '20px' }} />, title: 'Video Responses' },
	{ id: 'upload_test', icon: <UploadIcon style={{ width: '20px', height: '20px' }} />, title: 'File Upload' },
];
export const _sectionTypesIconMinimizeView = [
	{ id: 'skill_test', icon: <BarChartIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} /> },
	{ id: 'coding_test', icon: <CodeIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} /> },
	{ id: 'value_test', icon: <UserCheckIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} /> },
	{ id: 'personality_test', icon: <SmileIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} /> },
	{ id: 'case_study_test', icon: <PlayIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} /> },
	{ id: 'text_test', icon: <TypeIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} /> },
	{ id: 'video_test', icon: <VideoIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} /> },
	{ id: 'upload_test', icon: <UploadIcon style={{ width: '20px', height: '20px', stroke: '#121216' }} /> },
];

export const addSections = (assessmentSpecsDetails, selectedSectionType, selectedSectionIndex, isSectionValidate, setAssessmentSpecsDetails, getCurrentAssessmentComponentData, setSelectedSectionIndex, getSelectedOnRightSideMenuIndex, onUpdateSections, errorsInSections, setErrorsInSections) => {
	if (assessmentSpecsDetails?.sections?.find(section => section?.type === selectedSectionType)) {
		return;
	}
	// eslint-disable-next-line prefer-const
	if (assessmentSpecsDetails?.sections?.filter((_assessSpec) => { return (_assessSpec === null); }).length === 0) {
		return;
	}
	const tempSectionsArr = [...assessmentSpecsDetails.sections];
	// eslint-disable-next-line prefer-const
	let tempTestsArr = [...assessmentSpecsDetails.tests];

	const _selectedSection = sectionTypes.filter((_section) => { return (_section.type === selectedSectionType); })[0];

	// if (fromDragIndex === -1 && toDragIndex !== -1) {
	// 	//
	// }
	const currLength = assessmentSpecsDetails.sections.filter((item, _) => {
		return (item !== null);
	}).length;
	const _tempSelectedSectionIndex = currLength < 5 && currLength;
	tempSectionsArr[_tempSelectedSectionIndex] = _selectedSection;

	if (selectedSectionType === 'text_test') {
		tempTestsArr[_tempSelectedSectionIndex] = {
			test_type: _selectedSection.type,
			questions: _selectedSection.questions,
			intro_text: _selectedSection.intro_text,
			allow_file_upload: _selectedSection.allow_file_upload
		};
	}
	else if (selectedSectionType === 'video_test') {
		tempTestsArr[_tempSelectedSectionIndex] = {
			test_type: _selectedSection.type,
			questions: _selectedSection.questions,
			intro_text: _selectedSection.intro_text,
			recording_type: _selectedSection.recording_type
		};
	}
	else if (selectedSectionType === 'personality_test') {
		tempTestsArr[_tempSelectedSectionIndex] = {
			test_type: _selectedSection.type,
		};
	}
	else {
		tempTestsArr[_tempSelectedSectionIndex] = { test_type: _selectedSection.type, questions: _selectedSection.questions };
	}

	// if (selectedSectionIndex !== -1) {
	// 	isSectionValidate(assessmentSpecsDetails?.tests[selectedSectionIndex] || {}, errorsInSections, setErrorsInSections);
	// }

	setAssessmentSpecsDetails({ ...assessmentSpecsDetails, sections: [...tempSectionsArr], tests: [...tempTestsArr] });
	getCurrentAssessmentComponentData({ ...assessmentSpecsDetails, sections: [...tempSectionsArr], tests: [...tempTestsArr] });
	setSelectedSectionIndex(_tempSelectedSectionIndex);
	getSelectedOnRightSideMenuIndex(_tempSelectedSectionIndex + 2);

	if (onUpdateSections) {
		onUpdateSections({ ...assessmentSpecsDetails, sections: [...tempSectionsArr], tests: [...tempTestsArr] });
	}
};

export const isSectionValidate = (test, errorsInSections, setErrorsInSections) => {

	if (test === undefined || errorsInSections === undefined || setErrorsInSections === undefined) {
		return;
	}
	const validator = AssessmentTestValidation(test);
	if (validator?.isValidate === false) {
		if (errorsInSections?.filter((section,) => { return (section?.testType === test?.test_type); }).length === 0) {
			setErrorsInSections([...errorsInSections, { ...validator }]);
		}
		else {
			setErrorsInSections(errorsInSections?.map((section, _) => {
				if (section?.testType === test?.test_type) {
					return { ...validator };
				}
				else {
					return section;
				}
			}));
		}
		return false;
	} else {
		setErrorsInSections(errorsInSections?.filter((section,) => { return (section?.testType !== test?.test_type); }));
		return true;
	}

};

export default function CreateAssessment(props) {

	const { openSectionType, setOpenSectionType, selectedSectionIndex, setSelectedSectionIndex, selectedSectionType, setSelectedSectionType,
		errorsInSections, setErrorsInSections, fromDragIndex, setFromDragIndex } = props;
	const isOnTabletScreen = useMediaQuery('(max-width:1200px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const [assessmentSpecsDetails, setAssessmentSpecsDetails] = useState(props.assessmentSpecsDetails);
	const [onTabAddSectionStepNo, setOnTabAddSectionStepNo] = useState(props.onTabAddSectionStepNo);
	const [readMoreContent, setReadMoreContent] = useState(false);
	const [requestionTest, setRequestionTest] = useState(false);

	const audioRef = useRef(null);

	const handlePlay1 = () => {
		audioRef.current.play();
	};



	//////// For Personality Test ///////
	const [jobRole, setJobRole] = useState(PersonalityJobRole);
	const [seniority, setSeniority] = useState(PersonalitySeniority);
	const [industry, setIndustry] = useState(PersonalityIndustry);

	const [shouldupdateStateWithProps, setShouldUpdateStateWithProps] = useState({
		skill_test_updated: false,
		coding_test_updated: false,
		value_test_updated: false,
		personality_test_updated: false,
		case_study_test_updated: false,
		text_test_updated: false,
		video_test_updated: false,
		upload_test_updated: false,
	});

	const [toDragIndex, setToDragIndex] = useState(-1);
	const [tempSectionData, setTempSectionData] = useState(null);
	const [sectionToDelete, setSectionToDelete] = useState({
		index: -1,
		showModal: false
	});



	/**Redux */
	const assessSpec = useSelector((state) => state.assessSpecReducer);


	const [activeSection, setActiveSection] = useState({ type: null, section: null });




	const getSectionSubtitle = (sectionDetails) => {
		const jobDimensions = sectionDetails?.test?.job_dimensions;
		switch (sectionDetails?.section?.type) {
			case 'skill_test':
			case 'coding_test':
				return `${sectionDetails?.test?.questions?.length || 'No'} test${sectionDetails?.test?.questions?.length === 1 ? '' : 's'} added`;
			case 'value_test':
				return `${sectionDetails?.test?.questions?.length || 'No'} value${sectionDetails?.test?.questions?.length === 1 ? '' : 's'} added`;
			case 'personality_test':
				return `${(jobDimensions?.job_role === null || jobDimensions?.seniority === null || jobDimensions?.industry === null || jobDimensions?.job_location === null) ? 'Please fill in all fields' : 'MBTI Test configured'}`;
			default:
				return `${sectionDetails?.test?.questions?.length || 'No'} question${sectionDetails?.test?.questions?.length === 1 ? '' : 's'} added`;
		}
	};

	const replaceSectionPositions = () => {
		// eslint-disable-next-line prefer-const
		if (fromDragIndex !== -1 && toDragIndex !== -1) {
			const tempSectionsArr = [...assessmentSpecsDetails.sections];
			const tempTestsArr = [...assessmentSpecsDetails.tests];

			const fromData = { section: tempSectionsArr[fromDragIndex], test: tempTestsArr[fromDragIndex] };
			tempSectionsArr[fromDragIndex] = tempSectionsArr[toDragIndex];
			tempTestsArr[fromDragIndex] = tempTestsArr[toDragIndex];

			tempSectionsArr[toDragIndex] = fromData.section;
			tempTestsArr[toDragIndex] = fromData.test;

			// setSelectedSectionIndex(toDragIndex);
			setAssessmentSpecsDetails({ ...assessmentSpecsDetails, sections: [...tempSectionsArr], tests: [...tempTestsArr] });
			props.getCurrentAssessmentComponentData({ ...assessmentSpecsDetails, sections: [...tempSectionsArr], tests: [...tempTestsArr] });

			if (props.onUpdateSections) {
				props.onUpdateSections({ ...assessmentSpecsDetails, sections: [...tempSectionsArr], tests: [...tempTestsArr] });
			}
		}

	};

	const onRemoveSection = (index) => {
		const tempSectionsArr = [...assessmentSpecsDetails.sections];
		const tempTestsArr = [...assessmentSpecsDetails.tests];
		tempSectionsArr.splice(index, 1);
		tempSectionsArr.push(null);
		tempTestsArr.splice(index, 1);
		tempTestsArr.push(null);

		const _errors = tempSectionsArr?.map((_section, _) => {
			if (_section !== null) {
				const _test = tempTestsArr?.filter((test, _) => { return (test?.test_type === _section?.type); })[0] || {};
				const validator = AssessmentTestValidation(_test);
				if (validator?.isValidate === false) {
					return { ...validator };
				}
			}
		});


		setAssessmentSpecsDetails({ ...assessmentSpecsDetails, sections: [...tempSectionsArr], tests: [...tempTestsArr] });
		props.getCurrentAssessmentComponentData({ ...assessmentSpecsDetails, sections: [...tempSectionsArr], tests: [...tempTestsArr] });
		setErrorsInSections(_errors?.length ? _errors.filter((e, _) => { return (e !== undefined); }) : []);
		setSelectedSectionIndex(-1);

		if (props.onUpdateSections) {
			props.onUpdateSections({ ...assessmentSpecsDetails, sections: [...tempSectionsArr], tests: [...tempTestsArr] });
		}

	};

	const onCheckSectionDataConfermation = (sectionDetails) => {
		isSectionValidate(sectionDetails?.test, errorsInSections, setErrorsInSections);
	};

	const updatedSectionLocalData = (index, sectionDetails) => {
		const tempSectionsArr = [...assessmentSpecsDetails.sections];
		const tempTestsArr = [...assessmentSpecsDetails.tests];
		tempSectionsArr[index] = sectionDetails.section;
		tempTestsArr[index] = sectionDetails.test;

		setAssessmentSpecsDetails({ ...assessmentSpecsDetails, sections: [...tempSectionsArr], tests: [...tempTestsArr] });
		props.getCurrentAssessmentComponentData({ ...assessmentSpecsDetails, sections: [...tempSectionsArr], tests: [...tempTestsArr] });
	};




	const getComponent = (name) => {
		let component = null;
		switch (name) {
			case 'upload_test':
				component = FileUpload;
				break;
			case 'skill_test':
				component = SkillBasedTest;
				break;
			case 'value_test':
				component = ValueFitness;
				break;
			case 'coding_test':
				component = CodingTest;
				break;
			case 'text_test':
				component = Responses;
				break;
			case 'video_test':
				component = Responses;
				break;
			case 'personality_test':
				component = PersonalityTest;
				break;
			case 'case_study_test':
				component = CaseStudyTest;
				break;
			default:

				component = () => {
					return (
						<div className='default-section-div'>
							<span className='body-3 black-400'>{name}</span>
						</div>
					);
				};
		}
		return component;
	};

	const MinimizeViewOfSection = (props) => {
		const { onRemoveSection, onSelect, sectionDetails, draggable, onDragStart, error } = props;
		return (
			<div
				className={error ? 'minize-view-of-section-container minize-view-of-section-container-error pointer' : 'minize-view-of-section-container pointer'}
				draggable={draggable}
				onDragStart={onDragStart}
				style={{ position: 'relative' }}>

				<div className={`d-flex align-items-center position-absolute`} style={{ right: '16px', top: '12px' }}>
					<TrashIcon className="pointer" style={{ width: '24px', height: '24px' }} onClick={() => { onRemoveSection(); }} />
				</div>

				<div className='w-100 h-100 d-flex flex-column align-items-start justify-content-between'
					style={{ gap: '6px' }}
					onClick={() => {
						if (selectedSectionIndex !== -1) {
							isSectionValidate(assessmentSpecsDetails?.tests[selectedSectionIndex] || {}, errorsInSections, setErrorsInSections);
						}
						onSelect();
					}}
				>
					<div className='d-flex align-items-center justify-content-between w-100'>
						<div className='d-flex align-items-center justify-content-start'>
							{/* {
								error &&
								<div className='d-flex align-items-center justify-content-start' style={{ marginRight: '10px' }}>
									<WarningIcon style={{ width: '28px', height: '28px' }} />
								</div>
							} */}
							<div className='d-flex align-items-center justify-content-start' style={{ marginRight: '10px' }}>
								{
									_sectionTypesIconMinimizeView.filter((_section) => { return _section.id === sectionDetails?.section?.type; })[0].icon
								}
							</div>
							<span className='body-2 dark-100'>{sectionDetails?.section?.name}</span>
						</div>

					</div>
					<div>
						<span className='body-2 dark-50'>{getSectionSubtitle(sectionDetails)}</span>
					</div>
				</div>
			</div>
		);
	};

	useEffect(() => {
		setAssessmentSpecsDetails(props.assessmentSpecsDetails);
		if (props.assessmentDataDataBeforePublish === undefined) {
			props.getCurrentAssessmentComponentData({ ...assessmentSpecsDetails });
		}
	}, [props.assessmentSpecsDetails]);

	useEffect(() => {
		if (selectedSectionType && isOnTabletScreen) {
			addSections(assessmentSpecsDetails, selectedSectionType, selectedSectionIndex, setAssessmentSpecsDetails, props.getCurrentAssessmentComponentData, setSelectedSectionIndex, props.getSelectedOnRightSideMenuIndex, props.onUpdateSections);
		}
	}, [selectedSectionType, isOnTabletScreen]);


	useEffect(() => {
		props.getOnTabAddSectionStepNo(onTabAddSectionStepNo);
	}, [onTabAddSectionStepNo]);


	useEffect(() => {
		if (props?.resetAssessmentCreationSection) {

			setErrorsInSections([]);
			setSelectedSectionIndex(-1);
			props?.getResetAssessmentCreationSection(false);
		}

	}, [props?.resetAssessmentCreationSection]);

	useEffect(() => {
		if (localStorage.getItem('checkAssessmentValidation') === 'check') {
			const tempSectionsArr = [...assessmentSpecsDetails.sections];
			const tempTestsArr = [...assessmentSpecsDetails.tests];

			const _errors = tempSectionsArr?.map((_section, _) => {
				if (_section !== null) {
					const _test = tempTestsArr?.filter((test, _) => { return (test?.test_type === _section?.type); })[0] || {};
					const validator = AssessmentTestValidation(_test);
					if (validator?.isValidate === false) {
						return { ...validator };
					}
				}
			});

			setErrorsInSections(_errors?.length ? _errors.filter((e, _) => { return (e !== undefined); }) : []);
			localStorage.removeItem('checkAssessmentValidation');
		}
	}, [localStorage.getItem('checkAssessmentValidation')]);

	// useEffect(() => {

	// 	const { skill_test_updated, value_test_updated, case_study_test_updated, video_test_updated, upload_test_updated } = shouldupdateStateWithProps;

	// 	if (skill_test_updated && value_test_updated && case_study_test_updated && video_test_updated && upload_test_updated) {
	// 		props?.getResetAssessmentCreationSection(false);
	// 	}

	// }, [props?.resetAssessmentCreationSection, props?.assessmentSpecsDetails, shouldupdateStateWithProps]);



	useEffect(() => {
		if (assessSpec?.curr_assessment_spec) {
			const _errors = assessSpec?.curr_assessment_spec?.sections?.map((_section, _) => {
				const _test = (_section?.type === TestType.SKILL_TEST || _section?.type === TestType.CODING_TEST)
					? { test_type: _section?.type, questions: assessSpec?.curr_assessment_spec?.tests?.filter((test, _) => { return (test?.test_type === _section?.type); }) }
					: _section?.type === TestType.VALUE_TEST ? { test_type: _section?.type, questions: assessSpec?.curr_assessment_spec?.tests?.filter((test, _) => { return (test?.test_type === _section?.type); })[0]?.values || [] }
						: assessSpec?.curr_assessment_spec?.tests?.filter((test, _) => { return (test?.test_type === _section?.type); })[0] || {};

				const validator = AssessmentTestValidation(_test);
				if (validator?.isValidate === false) {
					return { ...validator };
				}
			});
			setErrorsInSections(_errors?.length ? _errors.filter((e, _) => { return (e !== undefined); }) : []);
		}

	}, []);

	// useEffect(() => {
	// console.log(props?.missingTests, 'props?.missingTests?.missing_skill_tests');
	// 	if (assessmentSpecsDetails && props.checkValidation) {

	// 		const _errors = assessmentSpecsDetails?.sections?.map((_section, _) => {
	// 			const _test = assessmentSpecsDetails?.tests?.filter((test, _) => { return (test?.test_type === _section?.type); })[0] || {};
	// 			const validator = AssessmentTestValidation(_test);
	// 			if (validator?.isValidate === false) {
	// 				return { ...validator };
	// 			}
	// 		});
	// 		// setErrorsInSections(_errors?.length ? _errors.filter((e, _) => { return (e !== undefined); }) : []);
	// 	}

	// 	props.setCheckValidation(false);
	// }, [props.checkValidation]);

	useMemo(() => {
		if (props?.activeSection?.type && props?.activeSection?.section !== null) {

			if (props?.activeSection?.type !== 'create_assessment') {
				if (activeSection?.type === 'create_assessment') {
					if (selectedSectionIndex !== -1) {
						isSectionValidate(assessmentSpecsDetails?.tests[selectedSectionIndex] || {}, errorsInSections, setErrorsInSections);
					}
				}
				setSelectedSectionIndex(-1);
			}
			else {
				setSelectedSectionIndex(props?.activeSection?.section);
			}
		}
		setActiveSection(props?.activeSection);

	}, [props?.activeSection]);

	useMemo(() => {
		if (selectedSectionIndex !== -1) {
			props.getActiveSection(selectedSectionIndex);
		}
	}, [selectedSectionIndex]);

	const dispatch = useDispatch();
	const [alert, setAlert] = useState(null);
	const [userQuery, setUserQuery] = useState({
		value: '',
		title: 'Not Applicable'
	});
	const { loading } = useSelector((state) => state.customerSupportReducer);

	const sendQuery = async () => {
		const data = { test_pack_type: userQuery.title === 'Not Applicable' ? null : userQuery.title === TestType.VALUE_TEST ? 'value_fitness_test_pack' : `${userQuery.title}_pack`, description: userQuery.value };

		if (!data.description) {
			setAlert(
				<AlertBox
					style={{ zIndex: '99999' }}
					color='danger'
					description={'Enter the description before submitting the query'}
					title='Error'
					isOpen={true}
					onCloseAlert={() => {
						setAlert(null);
					}}
				/>,
			);
			return;
		}

		const { meta, payload } = await dispatch(CreateIntercomTestPackTicket({ ...data }));
		if (meta?.requestStatus === RequestStatus.REJECTED) {
			setAlert(<AlertBox
				style={{ zIndex: '99999' }}
				color='danger'
				description={payload?.message || 'Sorry, Please try again later'}
				title='Error'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
		}
		else if (meta?.requestStatus === RequestStatus.FULFILLED) {
			setAlert(<AlertBox
				style={{ zIndex: '99999' }}
				color='success'
				description={'Request Sent!'}
				title='Success'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
		}
		setUserQuery({ title: 'Not Applicable', value: null });
		setRequestionTest(false);

	};

	const getMissingTestsString = (missing_tests) => {
		let missing_tests_arr = [];
		if (missing_tests?.missing_skill_tests?.length > 0)
			missing_tests_arr = missing_tests_arr.concat(missing_tests?.missing_skill_tests);
		if (missing_tests?.missing_coding_tests?.length > 0)
			missing_tests_arr = missing_tests_arr.concat(missing_tests?.missing_coding_tests);
		if (missing_tests?.missing_value_tests?.length > 0)
			missing_tests_arr = missing_tests_arr.concat(missing_tests?.missing_value_tests);

		return missing_tests_arr.map(obj => obj.title.charAt(0).toUpperCase() + obj?.title.slice(1)).join(', ') || '';
	};

	const missingTestPackWarningMemo = useMemo(() => {
		return (
			<div className='missing-testpack-message' style={{ maxWidth: readMoreContent ? '100%' : '815px' }}>
				<span className='body-3' style={{ textAlign: 'left' }}>
					We noticed that your job description might require some tests that are missing from our directory
					{
						!readMoreContent
							?
							<span className='bold pointer' style={{ marginLeft: '10px', color: '#6F2DBD' }} onClick={() => { setReadMoreContent(true); }}>...Read More</span>
							:
							<span>
								{` Specifically we might be missing tests in the following areas: ${getMissingTestsString(props?.missingTests)}. You can still proceed with this assessment or you can`}
								<span className='bold pointer' style={{ marginLeft: '10px', color: '#6F2DBD' }}
									onClick={() => {
										setRequestionTest(true);
										setUserQuery({
											...userQuery,
											value: `I need the following tests for my assessment titled ${props?.assessSpecTitle}:
											\n${props?.missingTests?.missing_skill_tests?.length > 0 ? `Skill Tests: ${props?.missingTests?.missing_skill_tests.filter(test => test?.title).map(test => test?.title.charAt(0).toUpperCase() + test?.title.slice(1)).join(', ')}` : ''}
											\n${props?.missingTests?.missing_coding_tests?.length > 0 ? `Coding Tests: ${props?.missingTests?.missing_coding_tests.filter(test => test?.title).map(test => test?.title.charAt(0).toUpperCase() + test?.title.slice(1)).join(', ')}` : ''}
											\n${props?.missingTests?.missing_value_tests?.length > 0 ? `Value Tests: ${props?.missingTests?.missing_value_tests.filter(test => test?.title).map(test => test?.title.charAt(0).toUpperCase() + test?.title.slice(1)).join(', ')}` : ''}`
										});
									}}>Request a New Test
								</span> {`and we'll get right to it!`}<span onClick={() => { setReadMoreContent(false); }} className='bold pointer' style={{ marginLeft: '10px', color: '#6F2DBD' }}>...Read Less</span>
							</span>
					}

				</span>
			</div>
		);
	}, [props?.missingTests, props?.assessSpecTitle, readMoreContent]);
	const [latestSectionIndex, setLatestSectionIndex] = useState(null);
	const handleDragLeave = (index) => {
		const v = (assessmentSpecsDetails?.sections.reduce((acc,cur)=>acc+(cur?0:1),0));
		setLatestSectionIndex(5-v);
		const timer = setTimeout(() => {
			setLatestSectionIndex(null); 
		}, 2000);
		return () => clearTimeout(timer);
	};


	// useEffect(() => {
	// const v = assessmentSpecsDetails?.sections?.reduce((acc, cur) => acc + (cur ? 1 : 0), 0);
	// console.log(v, 'assessmentSpecsDetails');
    
	// setLatestSectionIndex(v);  // Assuming `v` is the latest section index
    
	// const timer = setTimeout(() => {
	//     setLatestSectionIndex(null);
	// }, 2000);
    
	// return () => clearTimeout(timer);
	// }, [assessmentSpecsDetails?.sections]);
	const sectionRefs = useRef([]); 
	useEffect(() => {
		if (latestSectionIndex !== null && sectionRefs.current[latestSectionIndex]) {
			sectionRefs.current[latestSectionIndex].scrollIntoView({
				behavior: 'smooth', 
				block: 'center', 
			});
		}
	}, [latestSectionIndex]);




	return (
		<>
			{alert}
			<CustomModal
				open={sectionToDelete.showModal}
				varrient='alert-warning-modal'
				title='Delete Section?'
				description={'Are you sure you want to delete this section?'}
				dangerAlert={true}
				handleCloseModal={() => { setSectionToDelete({ ...sectionToDelete, showModal: false }); }}
				confirmBtnTitle={'Yes, Delete'}
				btnWidth='max-content'
				onConfirm={() => { onRemoveSection(sectionToDelete.index); setSectionToDelete({ index: -1, showModal: false }); }}
			/>

			<CustomModal
				open={requestionTest}
				test={userQuery?.title}
				handleCloseModal={() => { setRequestionTest(false); }}
				varrient='contact-support-for-search'
				onConfirm={sendQuery}
				value={userQuery?.value}
				processing={loading}
				onChange={(e) => { setUserQuery({ ...userQuery, value: e.target.value }); }}
				getOption={(option) => { setUserQuery({ ...userQuery, title: option.value }); }}

			/>
			{
				(!isOnMobileScreen && !isOnTabletScreen) ?
					<div className='create-assessment-secttion ' style={{ height: 'calc(100vh - 245px)' }}>
						<audio ref={audioRef} src={dropSound}></audio>
						<div className={`create-assessment-secttion-container ${(props?.missingTests?.missing_skill_tests?.length > 0 || props?.missingTests?.missing_coding_tests?.length > 0 || props?.missingTests?.missing_value_tests?.length > 0) ? ' warning-border ' : ''}`} 
							onDragLeave={() => handleDragLeave()}
						>
							<div className='create-assessment-secttion-container-header'>
								<div className='w-100 h-100 d-flex flex-column align-items-start' style={{ justifyContent: 'space-between' }}>
									<div className='d-flex align-items-center' style={{ gap: '8px' }}>
										{
											(props?.missingTests?.missing_skill_tests?.length > 0 || props?.missingTests?.missing_coding_tests?.length > 0 || props?.missingTests?.missing_value_tests?.length > 0) &&
											<YelloWarningIcon />
										}
										<span className='subtitle-1 dark-100'>My Assessment</span>
									</div>
									<span className='body-2 dark-50' style={{ marginTop: '8px', textAlign: 'left' }}>You can add up to 5 different sections in an assessment.</span>

								</div>

								<div className='h-100 d-flex align-items-start justify-content-end' style={{ width: '30px' }}>

								</div>
							</div>
							<div className='add-section-container'>
								{
									(props?.missingTests?.missing_skill_tests?.length > 0 || props?.missingTests?.missing_coding_tests?.length > 0 || props?.missingTests?.missing_value_tests?.length > 0) &&
									<>
										{
											missingTestPackWarningMemo
										}
									</>
								}
								{
									assessmentSpecsDetails?.sections?.map((section, index) => {
										const sectionError = (section && errorsInSections) ? errorsInSections.filter((_section, _) => { return (_section.testType === section.type); })[0] || null : null;
										const isLatestSection = index === latestSectionIndex;

										return (

											<Fragment key={index}>
												<div style={{ width: '100%' }}
													ref={(el) => sectionRefs.current[index] = el}
													onDragLeave={() => handleDragLeave(index)}
												// onDragOver={() => {
												// 		const getAllAssignSections = assessmentSpecsDetails?.sections.filter((section, _) => { return section !== null; });
												// 		if (index > getAllAssignSections.length - 1) {
												// 			setToDragIndex(-1);
												// 		}
												// 		else {
												// 			setToDragIndex(index);
												// 		}
												// 	}}
												>
													{
														section === null
															?
															<div className={`default-section-div ${toDragIndex === index && 'primary'}`} style={isOnTabletScreen ? { cursor: 'pointer' } : {}}
																onClick={
																	() => {
																		if (isOnTabletScreen) {
																			setOnTabAddSectionStepNo(1);
																			setSelectedSectionIndex(index);
																		}
																	}
																}>
																<span className='body-1 dark-50'>Add section</span>
															</div>
															:
															<div
																className='w-100'
																style={{
																	border: isLatestSection ? '1px solid #000' : '',
																	boxShadow: isLatestSection ? '0 4px 8px rgba(0, 0, 0, 0.3)' : '',
																	transition: 'border 0.3s ease, box-shadow 0.3s ease',
																	borderRadius: '24px',
																}}
															>
																{
																	selectedSectionIndex === index
																		?
																		React.createElement(getComponent(section.type),
																			{
																				sectionDetails: { section: section, test: assessmentSpecsDetails?.tests[index] },
																				onRemoveSection: () => { setSectionToDelete({ index: index, showModal: true }); },
																				// onConfirm: (sectionDetails) => { onConfirmSection(index, sectionDetails); },
																				// onCancel: (sectionDetails) => { onCancelSection(index, sectionDetails); },
																				getUpdatedSectionLocalData: (data) => { updatedSectionLocalData(index, data); },
																				onCheckSectionDataConfermation: (sectionDetails) => { onCheckSectionDataConfermation(sectionDetails); },
																				skillTestPacks: props.skillTestPacks,
																				codingTestPacks: props.codingTestPacks,
																				valueFitnessTestPacks: props.valueFitnessTestPacks,
																				error: sectionError,
																				jobRole: jobRole,
																				setJobRole: setJobRole,
																				seniority: seniority,
																				setSeniority: setSeniority,
																				industry: industry,
																				setIndustry: setIndustry,
																				setCheckValidation: props.setCheckValidation,
																				resetAssessmentCreationSection: props?.resetAssessmentCreationSection,
																				getToKnowIFStateUpdated: (name, value) => { setShouldUpdateStateWithProps({ ...shouldupdateStateWithProps, [name]: value }); }
																			})
																		:
																		<div
																			className='w-100'
																			onDragEnd={() => { replaceSectionPositions(); handlePlay1(); }}
																			draggable={!isOnTabletScreen}
																			onDragStart={() => { setSelectedSectionType(section.id); setFromDragIndex(index); }}
																		>
																			<MinimizeViewOfSection
																				sectionDetails={{ section: section, test: assessmentSpecsDetails?.tests[index] }}

																				onRemoveSection={() => { setSectionToDelete({ index: index, showModal: true }); }}
																				onSelect={() => {
																					setSelectedSectionIndex(index);
																					setOnTabAddSectionStepNo(2);

																				}}
																				error={sectionError}
																			/>
																		</div>
																}
															</div>
													}
												</div>
											</Fragment>
										);
									})
								}
							</div>
						</div>
					</div>
					:
					<div className='create-assessment-secttion'>
						{
							!openSectionType.isOpen ?
								<div className={`create-assessment-secttion-container ${(props?.missingTests?.missing_skill_tests?.length > 0 || props?.missingTests?.missing_coding_tests?.length > 0 || props?.missingTests?.missing_value_tests?.length > 0) ? ' warning-border ' : ''}`}>
									<div className='create-assessment-secttion-container-header'>
										<div className='w-100 h-100 d-flex flex-column align-items-start' style={{ justifyContent: 'space-between' }}>
											<div className='d-flex align-items-center' style={{ gap: '8px' }}>
												{
													(props?.missingTests?.missing_skill_tests?.length > 0 || props?.missingTests?.missing_coding_tests?.length > 0 || props?.missingTests?.missing_value_tests?.length > 0) &&
													<YelloWarningIcon style={{ height: '28px', width: '28px' }} />
												}
												<span className='headline-small semibold black-700'>Assessment Creation</span>
											</div>
											<span className='body-2 black-500' style={{ marginTop: '8px', textAlign: 'left' }}>You can add up to 5 different sections in an assessment.</span>

										</div>

										<div className='h-100 d-flex align-items-start justify-content-end' style={{ width: '30px' }}>

										</div>
									</div>
									<div className='add-section-container'>
										{
											(props?.missingTests?.missing_skill_tests?.length > 0 || props?.missingTests?.missing_coding_tests?.length > 0 || props?.missingTests?.missing_value_tests?.length > 0) &&
											missingTestPackWarningMemo
										}
										{!assessmentSpecsDetails?.sections ?
											<ProcessingIndicator />
											:
											assessmentSpecsDetails?.sections?.map((section, index) => {
												const sectionError = (section && errorsInSections) ? errorsInSections.filter((_section, _) => { return (_section.testType === section.type); })[0] || null : null;
												return (

													<Fragment key={index}>
														<div style={{ width: '100%' }} onDragLeave={() => { }}
															onDragOver={() => {
																const getAllAssignSections = assessmentSpecsDetails?.sections.filter((section, _) => { return section !== null; });
																if (index > getAllAssignSections.length - 1) {
																	setToDragIndex(-1);
																}
																else {
																	setToDragIndex(index);
																}
															}}>
															{
																section === null
																	?
																	<div className={`default-section-div ${toDragIndex === index && 'primary'}`} style={isOnTabletScreen ? { cursor: 'pointer' } : {}}
																		onClick={
																			() => {
																				setOpenSectionType({ type: 'create_assessment', isOpen: true });
																			}
																		}>
																		<span className='body-2 medium black-400'>Add section</span>
																	</div>
																	:
																	<div
																		className='w-100'
																	>
																		{
																			selectedSectionIndex === index
																				?
																				React.createElement(getComponent(section.type),
																					{
																						sectionDetails: { section: section, test: assessmentSpecsDetails?.tests[index] },
																						onRemoveSection: () => { setSectionToDelete({ index: index, showModal: true }); },
																						getUpdatedSectionLocalData: (data) => { updatedSectionLocalData(index, data); },
																						onCheckSectionDataConfermation: (sectionDetails) => { onCheckSectionDataConfermation(sectionDetails); },
																						skillTestPacks: props.skillTestPacks,
																						codingTestPacks: props.codingTestPacks,
																						valueFitnessTestPacks: props.valueFitnessTestPacks,
																						error: sectionError,
																						jobRole: jobRole,
																						setJobRole: setJobRole,
																						seniority: seniority,
																						setSeniority: setSeniority,
																						industry: industry,
																						setIndustry: setIndustry,
																						setCheckValidation: props.setCheckValidation,
																						showInfoPage: props.showInfoPage,
																						resetAssessmentCreationSection: props?.resetAssessmentCreationSection,
																						getToKnowIFStateUpdated: (name, value) => { setShouldUpdateStateWithProps({ ...shouldupdateStateWithProps, [name]: value }); }

																					})
																				:
																				<div
																					className='w-100'
																					onDragEnd={() => { replaceSectionPositions(); handlePlay1(); }}
																					draggable={!isOnTabletScreen}
																					onDragStart={() => { setSelectedSectionType(section.id); setFromDragIndex(index); }}
																				>
																					<MinimizeViewOfSection
																						sectionDetails={{ section: section, test: assessmentSpecsDetails?.tests[index] }}

																						onRemoveSection={() => { setSectionToDelete({ index: index, showModal: true }); }}
																						onSelect={() => {
																							setSelectedSectionIndex(index);
																							setOnTabAddSectionStepNo(2);

																						}}
																						error={sectionError}
																					/>
																				</div>
																		}
																	</div>
															}
														</div>
													</Fragment>
												);
											})
										}
									</div>
								</div>
								:
								(openSectionType.type === 'create_assessment' && openSectionType.isOpen) &&
								<div className='create-assessment-secttion-options-container w-100'>
									<div className='section-type-container w-100'>
										{
											_sectionTypes?.map((sectionType, index) => {
												const draggable = !isOnTabletScreen && !assessmentSpecsDetails?.sections?.find(section => section?.type === sectionType?.id);
												const btnStatus = assessmentSpecsDetails?.sections?.filter(section => section?.type === sectionType?.id)?.length > 0
													?
													errorsInSections.filter((_section, _) => { return (_section.testType === sectionType.id); }).length > 0 ? { success: false } : { success: true }
													:
													null;
												return (
													<Fragment key={index}>
														{
															<Button
																onClick={
																	() => {
																		setSelectedSectionType(sectionType.id);
																		setOnTabAddSectionStepNo(2);
																		addSections(assessmentSpecsDetails, sectionType.id, selectedSectionIndex, setAssessmentSpecsDetails, props.getCurrentAssessmentComponentData, setSelectedSectionIndex, props.getSelectedOnRightSideMenuIndex, props.onUpdateSections);
																		setOpenSectionType({ type: null, isOpen: false });
																	}
																}
																className={
																	`draggable in-section-btn
																${btnStatus ? (btnStatus.success === false) ? ' in-section-btn-danger ' : ' in-section-btn-success ' : ''}
																${selectedSectionIndex !== -1 && assessmentSpecsDetails?.sections[selectedSectionIndex]?.type === sectionType?.id ? ' in-section-btn-focused ' : ''}

																`}

																key={index} btn='in-section-btn'
																preIcon={btnStatus ? (btnStatus.success === false) ? <WarningIcon style={{ width: '24px', height: '24px' }} /> : <TickIcon style={{ width: '24px', height: '24px' }} /> : sectionType.icon}
																title={sectionType.title}
																style={{ width: '100%', background: '#F6F5F8', marginTop: index === 0 ? '0px' : '16px', cursor: `${draggable ? 'grab' : 'not-allowed'}` }} />
														}
													</Fragment>
												);
											})
										}
									</div>
								</div>

						}
					</div>
			}
		</>

	);
}
