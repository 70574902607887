import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AssessmentTestInfoWrapper from '../../../components/candidate-assessment-flow/AssessmentTestInfoWrapper';
import Button from '../../../components/sub-component/Button';
import Input from '../../../components/sub-component/Input';
import Radio from '../../../components/sub-component/Radio';
import Select from '../../../components/sub-component/Select';
import { answerTestQuestion } from '../../../redux/thunks/Assessment';

export default function QualificationTest(props) {

	const { result } = props;
	// const [questions, setQuestions] = useState([
	// 	{
	// 		'statement': 'How many years of experience do you have?',
	// 		'type': 'mcq',
	// 		'answers': [
	// 			'0-1',
	// 			'1-2',
	// 			'2+'
	// 		],
	// 		'id': '63083d23b5e8b051fb85741c'
	// 	},
	// 	{
	// 		'statement': 'Will you be able to start working right away if you're hired?',
	// 		'type': 'boolean',
	// 		'id': '63083d24b5e8b051fb85741d'
	// 	},
	// 	{
	// 		'statement': 'How many certifications are required for this position?',
	// 		'type': 'numeric',
	// 		'id': '63083d25b5e8b051fb85741e'
	// 	}
	// ]);

	const [questions, setQuestions] = useState(props.questions);
	const [questionAnswers, setQuestionAnswers] = useState([]);
	// const [processing, setProcessing] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		const tempArr = [];
		questions?.forEach(question => {
			tempArr.push(
				{
					ques_id: question.id,
					type: question.type,
					answer: null
				}
			);
		});
		setQuestionAnswers([...tempArr]);
	}, [questions]);

	const onSubmit = () => {
		// props.onSbumit(questionAnswers);
		// dispatch({ assess_id: props.assessmentId, qualifying_questions: questionAnswers });

		if (props.onSubmit) {
			props.onSubmit({ assess_id: props.assessmentId, qualifying_questions: questionAnswers });
		}
		// (async () => {

		// 	setProcessing(true);
		// 	await dispatch(answerTestQuestion({ assess_id: props.assessmentId, qualifying_questions: questionAnswers }));
		// 	setProcessing(false);


		// })();

	};

	return (
		<>
			{result ?
				<div className='d-flex flex-column align-items-center'>
					<span className='headline-large semibold black-700'>
						{
							result === 'processing' && 'We are evaluating your qualification test, it may take few mins , kindly wait!' ||
								result === 'passed' && 'Congragulations! You are successfully qualified for this assessment' ||
								result === 'failed' && 'Unfortunatly! You are not qualified for this assessment '
						}
					</span>
					{
						result === 'failed' &&
							<>
								<span className='bpdy-1 black-500' style={{ marginTop: '24px' }}>You can review this assessment in Not Qualified assessment(s)</span>
								<Button size={'lg'} btn='ragular-btn' varrient='primary' title={'Go back to assessment(s)'} style={{ width: 'fit-content', marginTop: '24px' }} onClick={() => { }} />
							</>
					}
				</div>
				:
				<AssessmentTestInfoWrapper
					title={'Qualification Test'}
					varrient={'qq-test'}
					disablePrimaryBtn={questionAnswers?.filter((_q) => {return (_q?.answer === null || _q?.answer === ''); }).length > 0}
					disableSecondaryBtn={true}
					btnSize={'lg'}
					primaryBtnTitle={'Submit'}
					onPrimaryBtnClick={onSubmit}
					btnWidth='120px'
					processing={props.processing}
				>
					<div className='qualification-test-container'>
						<span className='body-2 dark-50 text-start'>
								Before starting the actual test you have to solve the qualification test, this is a basic fit check and it will not take much of your time. If you fullfil the qualification test successfully then you may start your actual test, but if somehow you will not complete it successfully then thats mean you are not eligible for this interview, and unfortunattly you cannot proceed to further steps.
						</span>
						<span className='subtitle-2 dark-100 text-start'>Answer the following question(s):</span>
						{questionAnswers.length > 0 &&
								<div className='qualification-test-questions-container'>
									{
										questions?.map((question, index) => {
											return (
												<div key={index} className='w-100 d-flex flex-column align-items-start'>
													<span className='disable-text-selection body-2 black-700'>{`${index + 1}. ${question.statement}`}</span>
													{question.type === 'mcq' &&
														<div className='w-100'>
															{
																question.answers.map((answer, _index) => {
																	const isSelected = answer === questionAnswers[index].answer;
																	return (
																		<Radio
																			style={{ marginTop: '16px', background: isSelected ? '#ECEDF1' : '#F6F5F8'  }}
																			key={_index}
																			varrient='radio-with-input'
																			onChangeRadioOption={() => {
																				const _questions = questionAnswers;
																				_questions[index] = {
																					..._questions[index],
																					answer: answer,
																				};
																				setQuestionAnswers([..._questions]);
																			}}
																			checked={isSelected}
																			radioValue={answer}
																			value={answer}
																			readonly
																			placeholder='Write your answer here..'
																		/>
																	);
																})
															}
														</div>
													}
													{question.type === 'boolean' &&
														<div className='w-100'>
															<Radio
																style={{ marginTop: '16px', background: true === questionAnswers[index].answer ? '#ECEDF1' : '#F6F5F8' }}
																varrient='radio-with-input'
																onChangeRadioOption={() => {
																	const _questions = questionAnswers;
																	_questions[index] = {
																		..._questions[index],
																		answer: true,
																	};
																	setQuestionAnswers([..._questions]);
																}}
																checked={true === questionAnswers[index].answer}
																radioValue={'true'}
																value={'True'}
																readonly
																placeholder='Write your answer here..'
															/>
															<Radio
																style={{ marginTop: '16px', background: false === questionAnswers[index].answer ? '#ECEDF1' : '#F6F5F8' }}
																varrient='radio-with-input'
																onChangeRadioOption={() => {
																	const _questions = questionAnswers;
																	_questions[index] = {
																		..._questions[index],
																		answer: false,
																	};
																	setQuestionAnswers([..._questions]);
																}}
																checked={false === questionAnswers[index].answer}
																radioValue={'false'}
																value={'False'}
																readonly
																placeholder='Write your answer here..'
															/>
														</div>
													}
													{question.type === 'numeric' &&
														<div className='w-100'>
															<Input
																style={{ marginTop: '16px' }}
																type={'number'} varrient={'primary'}
																placeholder='Write your Ideal answer here..'
																value={questionAnswers[index].answer}
																name='idealAnswer'
																onChange={(e) => {
																	const _questions = questionAnswers;
																	_questions[index] = {
																		..._questions[index],
																		answer: e.target.value,
																	};
																	setQuestionAnswers([..._questions]);
																}}
																inputContainerClass='question-input-responsive'
															/>
														</div>
													}
												</div>
											);
										})
									}
								</div>
						}
					</div>
				</AssessmentTestInfoWrapper>
			}
		</>
	);
}
