import React, { useEffect, useState, useRef } from 'react';
import Select from '../../../../sub-component/Select';
import Radio from '../../../../sub-component/Radio';
import SectionContainer from './SectionContainer';
import { useMediaQuery } from '@mui/material';

export default function PersonalityTest(props) {
	const isOnWebScreen = useMediaQuery('(min-width:1280px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const { jobRole, setJobRole, seniority, setSeniority, industry, setIndustry } = props;


	const jobLocation = [
		{ name: 'Onsite', value: 'on-site' },
		{ name: 'Hybrid', value: 'hybrid' },
		{ name: 'Remote', value: 'remote' },
	];

	const [jobDimensions, setJobDimensions] = useState(props?.sectionDetails?.test?.job_dimensions);

	const updateData = (_jobDimensions) => {
		props.getUpdatedSectionLocalData({
			...props.sectionDetails,
			test: {
				...props.sectionDetails.test,
				job_dimensions: _jobDimensions
			},
		});
	};

	useEffect(() => {
		if (props.error) {
			props.setCheckValidation(true);
		}
	}, [jobDimensions]);

	useEffect(() => {
		if (props?.resetAssessmentCreationSection) {
			setJobDimensions(props?.sectionDetails?.test?.job_dimensions);
			props?.getToKnowIFStateUpdated('personality_test_updated', true);
		}
	}, [props?.resetAssessmentCreationSection]);

	const isInitialRender = useRef(true);

	useEffect(() => {
		if (isInitialRender.current) {
			isInitialRender.current = false;
			return;
		}

		updateData({ ...jobDimensions });
		props.onCheckSectionDataConfermation({
			...props.sectionDetails,
			test: {
				...props.sectionDetails.test,
				job_dimensions: jobDimensions
			},
		});
	}, [jobDimensions]);

	const onConfirm = () => {
		props.onConfirm({
			...props.sectionDetails,
			test: { ...props.sectionDetails.test, job_dimensions: jobDimensions },
		});
	};

	const onCancel = () => {
		props.onCancel({
			...props.sectionDetails,
			test: { ...props.sectionDetails.test, job_dimensions: jobDimensions },
		});
	};

	return (
		<SectionContainer
			title={'Personality Test '}
			description={
				'Our personality test is based off of Myers–Briggs Type Indicator (MBTI). In this test, candidate\'s MBTI personality type will be determined. Combined with the role information in the fields below, we will assign a compatibility score to each candidate.'
			}
			enableDuration={false}
			onRemoveSection={() => {
				props.onRemoveSection();
			}}
			onConfirm={onConfirm}
			onCancel={onCancel}
			error={props.error}
		>
			{
				<div
					className='w-100 d-flex align-items-start flex-column'
					style={{ marginTop: '24px', gap: '24px' }}
				>
					<div className='text-response-question-container'>
						<div className={`w-100 d-flex ${isOnMobileScreen ? 'flex-column' : 'flex-row'} justify-conetnt-between align-items-start`} style={{ gap: '16px' }}>
							<div className={`${isOnMobileScreen ? 'w-100' : 'w-50'} d-flex flex-column align-items-start`}>
								<span className={`${isOnWebScreen ? 'body-2' : 'body-4'} medium black-700 mb-2`} style={{ marginLeft: '24px' }}>Job Title</span>
								<Select
									varrient='select-with-search'
									style={{ width: '100%' }}
									className='question-select-responsive select-ideal-answer'
									placeholder='-- Select --'
									innerClassName={
										props?.error && props?.error?.job_role_required
											? 'primary-input-error'
											: ''
									}
									options={jobRole || []}
									onAddOption={(option) => {
										setJobRole([...jobRole, option]);
									}}
									onSelection={(option) => {
										setJobDimensions({ ...jobDimensions, job_role: option?.value });
									}}
									selected={jobDimensions?.job_role}
								/>
							</div>
							<div className={`${isOnMobileScreen ? 'w-100' : 'w-50'} d-flex flex-column align-items-start`}>
								<span className={`${isOnWebScreen ? 'body-2' : 'body-4'} medium black-700 mb-2`} style={{ marginLeft: '24px' }}>Seniority</span>
								<Select
									varrient='select-with-search'
									style={{ width: '100%' }}
									className='question-select-responsive select-ideal-answer'
									placeholder='-- Select --'
									innerClassName={
										props?.error && props?.error?.seniority_required
											? 'primary-input-error'
											: ''
									}
									options={seniority}
									onAddOption={(option) => {
										setSeniority([...seniority, option]);
									}}
									onSelection={(option) => {
										setJobDimensions({ ...jobDimensions, seniority: option?.value });
									}}
									selected={jobDimensions?.seniority}
								/>
							</div>
						</div>
					</div>
					<div className='text-response-question-container'>
						<div className={`w-100 d-flex ${isOnMobileScreen ? 'flex-column' : 'flex-row'} justify-conetnt-between align-items-start`} style={{ gap: '16px' }}>
							<div className={`${isOnMobileScreen ? 'w-100' : 'w-50'} d-flex flex-column align-items-start`}>
								<span className={`${isOnWebScreen ? 'body-2' : 'body-4'} medium black-700 mb-2`} style={{ marginLeft: '24px' }}>Industry</span>
								<Select
									varrient='select-with-search'
									style={{ width: '100%' }}
									className='question-select-responsive select-ideal-answer'
									placeholder='-- Select --'
									innerClassName={
										props?.error && props?.error?.industry_required
											? 'primary-input-error'
											: ''
									}
									options={industry}
									onAddOption={(option) => {
										setIndustry([...industry, option]);
									}}
									onSelection={(option) => {
										setJobDimensions({ ...jobDimensions, industry: option?.value });
									}}
									selected={jobDimensions?.industry}
								/>
							</div>
							<div className={`${isOnMobileScreen ? 'w-100' : 'w-50'} d-flex flex-column align-items-start`}>
								<span className={`${isOnWebScreen ? 'body-2' : 'body-4'} medium black-700 mb-2`} style={{ marginLeft: '24px' }} >Job Location</span>
								<Select
									readOnly
									style={{ width: '100%' }}
									className='question-select-responsive select-ideal-answer'
									placeholder='-- Select --'
									innerClassName={
										props?.error && props?.error?.job_location_required
											? 'primary-input-error'
											: ''
									}
									options={jobLocation}
									onSelection={(option) => {
										setJobDimensions({ ...jobDimensions, job_location: option?.value });
									}}
									selected={jobDimensions?.job_location}
								/>
							</div>
						</div>
					</div>
					<div className='text-response-question-container'>
						<div className={`w-100 d-flex ${isOnMobileScreen ? 'flex-column' : 'flex-row'} justify-conetnt-between align-items-start`} style={{ gap: '16px' }}>
							<div className={`${isOnMobileScreen ? 'w-100' : 'w-50'} d-flex flex-column align-items-start`}>
								<span className={`${isOnWebScreen ? 'body-2' : 'body-4'} medium black-700 mb-2`} style={{ marginLeft: '24px' }}>Customer Facing</span>
								<Radio
									style={{ background: jobDimensions?.is_customer_facing ? '#ECEDF1' : '#F6F5F8' }}
									varrient='radio-with-input'
									readonly
									placeholder=''
									dangerouslySetInnerHTML
									onChangeRadioOption={() => {
										setJobDimensions({ ...jobDimensions, is_customer_facing: true });
									}}
									checked={jobDimensions?.is_customer_facing}
									value={'Yes'}
								/>
								<Radio
									style={{ marginTop: '12px', background: !jobDimensions?.is_customer_facing ? '#ECEDF1' : '#F6F5F8' }}
									varrient='radio-with-input'
									readonly
									placeholder=''
									dangerouslySetInnerHTML
									onChangeRadioOption={() => {
										setJobDimensions({ ...jobDimensions, is_customer_facing: false });
									}}
									checked={!jobDimensions?.is_customer_facing}
									value={'No'}
								/>
							</div>
							<div className={`${isOnMobileScreen ? 'w-100' : 'w-50'} d-flex flex-column align-items-start`}>
								<span className={`${isOnWebScreen ? 'body-2' : 'body-4'} medium black-700 mb-2`} style={{ marginLeft: '24px' }}>Leadership Position</span>
								<Radio
									style={{ background: jobDimensions?.is_leadership_position ? '#ECEDF1' : '#F6F5F8' }}
									varrient='radio-with-input'
									readonly
									placeholder=''
									dangerouslySetInnerHTML
									onChangeRadioOption={() => {
										setJobDimensions({ ...jobDimensions, is_leadership_position: true });
									}}
									checked={jobDimensions?.is_leadership_position}
									value={'Yes'}
								/>
								<Radio
									style={{ marginTop: '12px', background: !jobDimensions?.is_leadership_position ? '#ECEDF1' : '#F6F5F8' }}
									varrient='radio-with-input'
									readonly
									placeholder=''
									dangerouslySetInnerHTML
									onChangeRadioOption={() => {
										setJobDimensions({ ...jobDimensions, is_leadership_position: false });
									}}
									checked={!jobDimensions?.is_leadership_position}
									value={'No'}
								/>
							</div>
						</div>
					</div>
				</div>
			}
		</SectionContainer>
	);
}
