import { createSlice } from '@reduxjs/toolkit';
import { AppState, UserRole } from '../../utils/Constants';
import { login, refreshToken, backgroundRefreshToken, signup, googleSignup, googleLogin, candidateActivation, candidateInvite, candidatePublicInvite } from '../thunks/Auth';

/**
 * @type {AuthState}
 */
const initialState = {
	app_state: AppState.LOADING,
	is_loading: false,
	data: {
		user_id: null,
		tenant_id: null,
		email: null,
		access_token: null,
		expires_at: null,
		role: null,
		is_active: false,
		tenant_status: null,
		has_set_password: false,

	},
	is_menu_visible: true,
	error_message: null,
	success_message: null,
};

const AuthSlice = createSlice({

	name: 'Auth Slice',
	initialState: initialState,

	reducers: {

		handleMenuVisibility: (state, { payload }) => {

			return {
				...state,
				is_menu_visible: payload
			};

		}

	},

	extraReducers: {

		[signup.pending]: (state) => {
			// state.is_loading = true;
			return {
				...state,
				is_loading: true,
			};
		},

		[signup.fulfilled]: (state, { payload }) => {
			// state.is_loading = false;
			// state.data = { ...state.data, ...payload };
			return {
				...state,
				data: {
					...state.data,
					...payload,
				}
			};
		},

		[signup.rejected]: (state, { payload }) => {
			// state.is_loading = false;
			// state.error = payload.error;
			return {
				...state,
				is_loading: false,
				error_message: payload
			};
		},

		[login.pending]: (state) => {
			// state.is_loading = true;
			return {
				...state,
				is_loading: true,
			};
		},

		[login.fulfilled]: (state, { payload }) => {
			// state.is_loading = false;
			// state.data = { ...state.data, ...payload };
			return {
				...state,
				is_loading: false,
				data: { ...state.data, ...payload }
			};
		},

		[login.rejected]: (state, { payload }) => {
			// state.is_loading = false;
			// state.error = payload.error;
			return {
				...state,
				is_loading: false,
				error_message: payload
			};
		},

		[googleSignup.pending]: (state) => {
			// state.is_loading = true;
			return {
				...state,
				is_loading: true,
			};
		},

		[googleSignup.fulfilled]: (state, { payload }) => {
			// state.is_loading = false;
			// state.data = { ...state.data, ...payload };
			return {
				...state,
				data: {
					...state.data,
					...payload,
				}
			};
		},

		[googleSignup.rejected]: (state, { payload }) => {
			// state.is_loading = false;
			// state.error = payload.error;
			return {
				...state,
				is_loading: false,
				error_message: payload
			};
		},

		[googleLogin.pending]: (state) => {
			// state.is_loading = true;
			return {
				...state,
				is_loading: true,
			};
		},

		[googleLogin.fulfilled]: (state, { payload }) => {
			// state.is_loading = false;
			// state.data = { ...state.data, ...payload };
			return {
				...state,
				is_loading: false,
				data: { ...state.data, ...payload }
			};
		},

		[googleLogin.rejected]: (state, { payload }) => {
			// state.is_loading = false;
			// state.error = payload.error;
			return {
				...state,
				is_loading: false,
				error_message: payload
			};
		},

		[refreshToken.pending]: (state) => {
			// state.app_state = AppState.LOADING;
			return {
				...state,
				app_state: AppState.LOADING
			};
		},

		[refreshToken.fulfilled]: (state, { payload }) => {
			// state.app_state = AppState.READY;
			// state.data = {
			// 	...state.data,
			// 	...payload
			// };

			return {
				...state,
				app_state: AppState.READY,
				data: {
					...state.data,
					...payload,
				}
			};

		},
		[refreshToken.rejected]: (state) => {
			return {
				...state,
				app_state: AppState.READY,
			};
		},

		[backgroundRefreshToken.pending]: (state) => {
			// state.app_state = AppState.LOADING;
			return {
				...state,
				app_state: AppState.READY
			};
		},

		[backgroundRefreshToken.fulfilled]: (state, { payload }) => {
			// state.app_state = AppState.READY;
			// state.data = {
			// 	...state.data,
			// 	...payload
			// };

			return {
				...state,
				app_state: AppState.READY,
				data: {
					...state.data,
					...payload,
				}
			};

		},
		[backgroundRefreshToken.rejected]: (state) => {
			return {
				...state,
				app_state: AppState.READY,
			};
		},
		[candidateActivation.pending]: (state) => {
			return {
				...state,
				is_loading: true,
			};
		},

		[candidateActivation.fulfilled]: (state, { payload }) => {
			console.log({ ...payload?.data }, 'payload');
			return {
				...state,
				is_loading: false,
				data: {
					...state.data, ...payload?.data, role: { name: UserRole.CANDIDATE }, is_active: true
				}
			};
		},

		[candidateActivation.rejected]: (state, { payload }) => {
			return {
				...state,
				is_loading: false,
				error_message: payload
			};
		},
		[candidateInvite.pending]: (state) => {
			// state.is_loading = true;
			return {
				...state,
				is_loading: true,
			};
		},

		[candidateInvite.fulfilled]: (state, { payload }) => {
			// state.is_loading = false;
			// state.data = { ...state.data, ...payload };
			return {
				...state,
				is_loading: false,
				data: { ...state.data, ...payload }
			};
		},

		[candidateInvite.rejected]: (state, { payload }) => {
			// state.is_loading = false;
			// state.error = payload.error;
			return {
				...state,
				is_loading: false,
				error_message: payload
			};
		},
		[candidatePublicInvite.pending]: (state) => {
			// state.is_loading = true;
			return {
				...state,
				is_loading: true,
			};
		},

		[candidatePublicInvite.fulfilled]: (state, { payload }) => {
			// state.is_loading = false;
			// state.data = { ...state.data, ...payload };
			return {
				...state,
				is_loading: false,
				data: { ...state.data, ...payload }
			};
		},

		[candidatePublicInvite.rejected]: (state, { payload }) => {
			// state.is_loading = false;
			// state.error = payload.error;
			return {
				...state,
				is_loading: false,
				error_message: payload
			};
		},



	}


});

export const { handleMenuVisibility } = AuthSlice.actions;
export default AuthSlice.reducer;
