import { createSlice } from '@reduxjs/toolkit';
import { listPublicTestpacks, getPublicTestPack, createExecutedId, executeResult, resetExecResultState } from '../thunks/PublicTestpack';

const PublicTestpacks = createSlice({
	name: 'Testpack Preview Questions',

	initialState: {
		loading: false,
		data: null,
		current_testpack: null,
		error_message: null,
		success_message: null,

		processing_exec_result: false,
		exec_result_api_success: false,
		exec_result_id: null,
		exec_result: null,
	},

	reducers: {},

	extraReducers: {

		[listPublicTestpacks.pending]: (state) => {
			return {
				...state,
				loading: true
			};
		},

		[listPublicTestpacks.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				data: payload,
			};
		},

		[listPublicTestpacks.rejected]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				error_message: payload
			};
		},

		[getPublicTestPack.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[getPublicTestPack.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				current_testpack: payload,
			};
		},
		[getPublicTestPack.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

		[createExecutedId.pending]: (state) => {
			return {
				...state,
				processing_exec_result: true,
				exec_result: null,
				exec_result_api_success: false
			};
		},
		[createExecutedId.fulfilled]: (state, { payload }) => {
			return {
				...state,
				exec_result_id: payload.exec_id,
				processing_exec_result: true,
			};
		},

		[executeResult.fulfilled]: (state, { payload }) => {
			if (!payload.submissions || payload.submissions?.length < payload.tokens?.length) {
				return {
					...state,
					exec_result: payload,
					processing_exec_result: state.exec_result_id ? true : false,
				};
			}
			return {
				...state,
				exec_result: payload,
				processing_exec_result: false,
				exec_result_id: null,
				exec_result_api_success: true
			};
		},

		[resetExecResultState.pending]: (state) => {
			return {
				...state,
			};
		},
		[resetExecResultState.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing_exec_result: false,
				exec_result_id: null,
			};
		},
		[resetExecResultState.rejected]: (state) => {
			return {
				...state,
			};
		},
	},
});

export default PublicTestpacks.reducer;