import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CompanyInfoForm from '../components/auth/CompanyInfoForm';
import OnboardingBox from '../components/OnboardingBox';
import { PathName } from '../utils/routes';
// import SignUpWeb from './SignUp/SignUpWeb';

export default function CompanyInfo() {

	const history = useHistory();
	const search = useLocation().search;

	return (
		<>
		<OnboardingBox >
				<div className="d-flex flex-column login-form-container">
				<div className="form">
						<div className="headline-large bold">
							Company Information
						</div>
						<div className="body-3 black-500" style={{ marginTop: '10px' }}>
							{`Enter your company name to move further,`}
						</div>
						<div>
							<CompanyInfoForm />
						</div>
					</div>
				</div>
			</OnboardingBox>
	
		
		</>
	);
}
