import React, { useEffect, useMemo, useRef, useState } from 'react';
import ProgressBar from '../../../common/ProgressBar';
import CodingTestTimeLinePlayer from './CodingTestTimeLinePlayer';

import { dracula } from '@uiw/codemirror-theme-dracula';
import { langs } from '@uiw/codemirror-extensions-langs';
import CodeMirror from '@uiw/react-codemirror';
import { zebraStripes } from '@uiw/codemirror-extensions-zebra-stripes';
import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';
import { useSelector } from 'react-redux';
import { secondsToDhms, langNameMapping } from '../../../../utils/utilities';

const myTheme = createTheme({
	theme: 'dark',
	settings: {
		background: '#10182b',
		foreground: '#f8f8f2',
		caret: '#f8f8f0',
		selection: '#437cde',
		selectionMatch: '#437cde',
		gutterBackground: '#10182b',
		gutterForeground: '#6D8A88',
		gutterBorder: 'transparent',
		lineHighlight: 'rgba(255, 255, 255, 0.2)',
	},
	styles: [
		{ tag: t.comment, color: '#6272a4' },
		{ tag: t.string, color: '#f1fa8c' },
		{ tag: t.atom, color: '#bd93f9' },
		{ tag: t.meta, color: '#f8f8f2' },
		{ tag: [t.keyword, t.operator, t.tagName], color: '#ff79c6' },
		{ tag: [t.function(t.propertyName), t.propertyName], color: '#50fa7b' },
		{
			tag: [t.definition(t.variableName), t.function(t.variableName), t.className, t.attributeName],
			color: '#F4C20D',
		},
		{ tag: t.atom, color: '#bd93f9' },
	],
});

export default function CodingTestTimeline(props) {

	const { codeSnapshots, response } = props;
	const [currentCodeValue, setCurrentCodeValue] = useState('');

	const assessment = useSelector((state) => state.assessmentReducer);

	const [copyTextSuspected, setCopyTextSuspected] = useState({
		text: '',
		lines: []
	});
	const [editorLintedValue, setEditorLintedValue] = useState(null);
	const [previousEditorValue, setPreviousEditorValue] = useState([]);

	// useMemo(() => {
	// 	if (copyTextSuspected.text !== '') {
	// 		//
	// 	}
	// 	else {
	// 		setCopyTextSuspected({ text: '', lines: [99999999] });
	// 	}
	// }, [copyTextSuspected.text, editorLintedValue]);

	// useEffect(() => {
	// 	console.log(currentCodeValue, 'currentCodeValue');
	// }, [currentCodeValue]);


	const CodeEditor = useMemo(() => {
		return (
			<CodeMirror
				value={currentCodeValue}
				height='351px'
				extensions={[
					langs[langNameMapping(response?.code_language)]
						? langs[langNameMapping(response?.code_language)]()
						: []
					,
					zebraStripes({
						lineNumber: copyTextSuspected.lines.length > 0 ? copyTextSuspected.lines : [99999999],
						lightColor: 'yellow',
						darkColor: '#882727',
					})
				]}
				indentWithTab={true}
				basicSetup={
					{ highlightActiveLineGutter: false, indentOnInput: true, tabSize: 4 }
				}

				onUpdate={(updatedData) => { setEditorLintedValue(updatedData.state.doc.text); }}
				theme={myTheme}
				highlightActiveLineGutter={false}
				placeholder=''
				// readOnly
				style={{ borderRadius: '8px 8px 0px 0px' }}

			/>
		);
	}, [copyTextSuspected?.lines, response?.code_language, currentCodeValue]);


	return (
		<div className='coding-test-details-timeline-container mt-3'>
			<div className='coding-test-timeline-details'>
				<div className='w-100 d-flex justify-content-between' style={{height:'50px'}}>
					<div className='d-flex flex-column' style={{ gap: '4px', textAlign: 'left' }}>
						{/* <span className='headline-small semibold black-700'>Timeline:</span> */}
						<span className='body-2 ' style={{color:'#808191'}}>Time Used <span className='body-2' style={{color:'#121216'}}>{secondsToDhms(parseInt(codeSnapshots?.length * 5) - 1)}</span></span>
					</div>
					<div style={{ width: '177px' }}>
						<ProgressBar
							varrient='custom'
							title={{
								text: `Correctness ${response?.score ? parseInt((100 / response?.score?.total) * response?.score?.correct) : 0}%:`,
								className: 'body-2',
							}}
							value={response?.score ? parseInt((100 / response?.score?.total) * response?.score?.correct) : 0}
							isHideValue={true}
							valueText={{
								className: 'body-2',
							}}
							progressBar={{
								style: { height: '8px', marginTop: '6px' },
								// innerStyle: { width: `${response?.score ? parseInt((100 / response?.score?.total) * response?.score?.correct) : 0}%`, backgroundColor: '#00D69A' }
							}}
						/>
					</div>
				</div>
				<div className='coding-test-timeline-details-timmer'>
					<CodingTestTimeLinePlayer snapshots={codeSnapshots} response={response}
						onChangePreviousValue={((value) => { setPreviousEditorValue(value?.split('\n')) || []; })}
						onChangeCodeValue={(value) => { setCurrentCodeValue(value); }}
						onChangeCodeSnapshot={(codeSnapShot) => { props?.onChangeCodeSnapshot(codeSnapShot); if (!codeSnapShot.copy_text || codeSnapShot.copy_text === '') { setCopyTextSuspected({ text: '', lines: [99999999] }); } }}
						getCoppiedText={(value) => {
							console.log('copy_text suspected');
							const _linesHeilights = [];
							for (let index = value?.start_index; index <= value?.end_index; index++) {
								_linesHeilights.push(index);

							}
							setCopyTextSuspected({ lines: _linesHeilights, text: value });
						}}
					/>
				</div>
				<div className='w-100 d-flex justify-content-between' style={{ marginTop: '12px', marginBottom: '12px' }}>
					<span className='body-1' style={{color:'#808191'}}>Execution:</span>
				</div>
				<div className='w-100 ct-exec-result'>
					{
						CodeEditor
					}
				</div>


			</div>
		</div>
	);

}
