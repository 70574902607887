import { updateSlice } from '../../redux/slices/AlertSlice';
/**@type {import ('@reduxjs/toolkit').Store} */
var reduxStore = null;

export const addInterceptors = (instance) => {
	instance.interceptors.request.use(addAccessToken, handleRequestError);
	instance.interceptors.response.use(handleResponseOK, handleResponseError);
};

export const setStore = (store) => {
	reduxStore = store;
};

export const addAccessToken = (config) => {
	if (reduxStore === null) {
		return;
	}

	const state = reduxStore.getState();
	const auth = state.authReducer;
	const accessToken = auth.data.access_token;

	if (!accessToken) {
		return;
	}

	return {
		...config,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	};
};

export const handleRequestError = (error) => {
	if (error.code === 'ERR_NETWORK') {
		return Promise.reject({ message: 'Network Error' });
	}
	return Promise.reject(error?.response?.data);
};

export const handleResponseOK = (response) => {
	return response;
};

export const handleResponseError = (error) => {
	if (error.code === 'ERR_NETWORK') {
		return Promise.reject({ message: 'Network Error' });
	}
	if (error.response?.status >= 500) {
		return Promise.reject({ message: 'Connection error. Try again.' });
	}
	return Promise.reject(error?.response?.data);
};

export const handleApiError = (error, rejectWithValue, dispatch) => {
	if (error?.message === 'Connection error. Try again.' || error?.message === 'Network Error') {
		dispatch(updateSlice({ message: error?.message, title: 'Error', color: 'danger', autoRemove: false }));
		return rejectWithValue(error?.message);
	} else if (error?.message === 'A Company with this email domain already exists. Please ask an administrator to invite you. If you think this is an error, please reach out to our support.') {
		dispatch(updateSlice({ message: error?.message, title: 'Error', color: 'danger', autoRemove: true }));
		return rejectWithValue(error?.message);
	}
	return rejectWithValue(error?.message);
};

// const handleRefreshToken = (config) => {
// 	return new Promise((resolve, reject) => {
// 		const refreshToken = localStorage.getItem("rnr_refresh_token");
// 		console.log("Asking for new access token by using : ", refreshToken);
// 		instance
// 			.request({
// 				url: `/token`,
// 				headers: { "x-refresh-token": refreshToken },
// 				method: "get",
// 			})
// 			.then((response) => {
// 				const accessToken = response.headers["x-token"];
// 				console.log("RESPONSE: ");
// 				console.log(response.headers);
// 				localStorage.setItem("rnr_access_token", accessToken);
// 				console.log("new Access Token", accessToken);
// 				instance
// 					.request({
// 						...config,
// 						data: config.data ? JSON.parse(config.data) : null,
// 						headers: { "x-token": accessToken },
// 					})
// 					.then((res) => {
// 						resolve(res);
// 					})
// 					.catch((err) => {
// 						reject(err);
// 					});
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// };

// axios.interceptors.request.use((config) => {
//     const accessToken = localStorage.getItem('accessToken');
//     return { ...config, headers: { 'accesstoken': accessToken } };
// }, (error) => {
//     return Promise.reject(error);
// });

// // Add a response interceptor
// axios.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   });
