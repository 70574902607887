import React, { useState } from 'react';
import CustomTooltip from './CustomTooltip';

export default function ProgressBar(props) {
	const { value, size, varrient, style, isHideValue, text } = props;
	const [progressBarStyle, setProgressBarStyle] = useState({});

	setTimeout(() => {
		setProgressBarStyle({ width: `${value}%` });
	}, 50);

	if (varrient === 'custom') {
		return (
			<div className='w-100 d-flex flex-column' style={{ ...style }}>
				<div className='w-100 d-flex flex-column align-items-start' style={{ ...props.innerStyle, gap: '16px' }}>
					<span className={props?.title?.className ? props?.title?.className : 'subtitle-2 dark-100'} style={{ ...props?.title?.style }}>{props?.title?.text || 'Assessment Progress'}</span>
					{!isHideValue &&
						<>
							{(typeof value === 'number') &&
								<span className={props?.valueText?.className ? props?.valueText?.className : 'body-2 dark-100'} style={{ ...props?.valueText?.style }}>{`${value}% Complete`}</span>
							}
						</>
					}
				</div>
				<div className='progress-bar-container w-100 position-relative' style={{ ...props?.progressBar?.style, gap: '4px' }}>
					<div className='h-100 w-100' style={{ opacity: '0.2', position: 'absolute' }}></div>
					<div style={{ width: `${value}%`, ...props?.progressBar?.innerStyle }} className='progress-bar'></div>
				</div>
			</div>
		);
	} else if (varrient === 'progress-with-upload-doc') {
		return (
			<div className='w-100 d-flex flex-row align-items-center' style={{ ...style, gap: '12px' }}>
				<div className='progress-bar-container w-100 position-relative' style={{ ...props?.progressBar?.style }}>
					<div className='h-100 w-100' style={{ opacity: '0.2', position: 'absolute' }}></div>
					<div style={{ width: `${value}%`, ...props?.progressBar?.innerStyle }} className='progress-bar'></div>
				</div>
				<span className={props?.valueText?.className ? props?.valueText?.className : 'body-2 dark-50'}>{`${value}%`}</span>
			</div>
		);
	} else if (varrient === 'progress-with-white-bg') {
		return (
			<div className='d-flex flex-column align-items-start'>
				<span className='body-2' >{`${value}%`}</span>
				<div className='progress-bar-container' style={{background: '#FFF'}}>
					<div style={progressBarStyle} className='progress-bar'></div>
				</div>
			</div>
		);
	} else if (varrient === 'progress-with-presentage') {
		return (
			<div className='w-100 d-flex flex-row align-items-center' style={{ ...style, gap: '12px', padding: '0px 12px' }}>
				<span className={props?.valueText?.className ? props?.valueText?.className : 'headline-small bold black-400'} style={{ color: value <= 50 ? '#FC4848' : value <= 75 ? '#F4C20D' : '#00D69A' }}>{`${value}%`}</span>
				<div className='progress-bar-container w-100 position-relative' style={{ ...props?.progressBar?.style }}>
					<div className='h-100 w-100' style={{ backgroundColor: value <= 50 ? '#FC4848' : value <= 75 ? '#F4C20D' : '#00D69A', opacity: '0.2', position: 'absolute' }}></div>
					<div style={{ backgroundColor: value <= 50 ? '#FC4848' : value <= 75 ? '#F4C20D' : '#00D69A', width: `${value}%`, ...props?.progressBar?.innerStyle }} className='progress-bar'></div>
				</div>
			</div>
		);
	} else if (varrient === 'progress-with-number-value') {
		return (
			<div className='w-100 d-flex flex-column align-items-start' style={{ ...style, gap: '4px' }}>
				<span className={props?.valueText?.className ? props?.valueText?.className : 'body-2 dark-100'}>{`${value}`}/10</span>
				<div className='progress-bar-container-number-value w-100 position-relative' style={{ ...props?.progressBar?.style }}>
					<div className='w-100' style={{ position: 'absolute' }}></div>
					<div style={{ width: `${(value * 10)}%`, ...props?.progressBar?.innerStyle }} className='progress-bar'></div>
				</div>
			</div>
		);
	} else {
		return (
			size === 'lg'
				?
				<div className='progress-bar-container-lg'>
					<CustomTooltip text={text} customPosition={{bottom: '10px'}} position='top-end'>
						<div style={progressBarStyle} className='progress-bar'></div>
					</CustomTooltip>
				</div>
				:
				<div className='d-flex flex-column align-items-start'>
					<span className='body-2' >{`${value}%`}</span>
					<div className='progress-bar-container'>
						<div style={progressBarStyle} className='progress-bar'></div>
					</div>
				</div>
		);
	}
}
