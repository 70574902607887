import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VideoRecorder from '../../../../../components/candidate-assessment-flow/VideoRecorder';
import ProcessingIndicator from '../../../../../components/common/ProcessingIndicator';
import Radio from '../../../../../components/sub-component/Radio';
import { createResource, deleteResource, resetResourceState } from '../../../../../redux/thunks/Resource';
import TestContentWrapper from '../TestContentWrapper';
import { useFocusWithin } from '@react-aria/interactions';
import Button from '../../../../../components/sub-component/Button';
import CustomModal from '../../../../../components/common/Modal';
import VideoRecorderWithChoice from '../../../../../components/shared/VideoRecorderWithChoice';
import { TestType } from '../../../../../utils/Constants';

export default function VideoResponsesCS(props) {

	const { question } = props;

	const dispatch = useDispatch();
	const resource = useSelector((state) => state.resourceReducer);

	const [currentVideoId, setCurrentVideoId] = useState('');
	const [loomVideoURL, setLoomVideoURL] = useState('');
	const [videoMode, setvideoMode] = useState('');
	const [isLoomInProcess, setIsLoomInProcess] = useState(false);

	const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: e => { },
		onBlurWithin: e => { setIsSkipModalOpen(false); }
	});

	const onQuestionSubmit = (retry = false) => {
		const data = {
			assess_id: props.assessmentId,
			...(retry ? { retry: true } : videoMode === 'loom' ? { loom_video_url: loomVideoURL, recording_type: videoMode } : { resource_id: resource.data?.id, recording_type: videoMode })
		};
		console.log(data, 'Hit!');

		if (!data.assess_id || (videoMode === 'native' && (!data.resource_id && !data.retry))) {
			return;
		}

		if (props.onVideoRetry && retry) {
			props.onVideoRetry(data);
		}
		else if (props.onQuestionSubmit) {
			props.onQuestionSubmit(data);
		}

		// if (recordedView) {
		// 	//hit a patch api submit question 
		// }
		// else {
		// 	//show confermation to skip the question.
		// 	props.onQuestionSkip();

		// }
	};

	useEffect(() => {
		if (resource.data !== null) {
			dispatch(resetResourceState());
		}
		// return () => {
		// 	if (resource.data?.id && currentVideoId) {
		// 		dispatch(deleteResource({ id: resource.data.id }));
		// 	}
		// };

	}, [question]);

	return (
		<>
			<TestContentWrapper
				title={null}
				// duration={question?.duration || 0}
				duration={null}
				currentQuestion={props.currentQuestionNo}
				totalQuestions={props.totalQuestions || 0}
				// onSubmit={() => onQuestionSubmit(false)}
				is_mandatory={props.is_mandatory}
				processing={props.processing}
				onSubmit={(action) => {
					if (action === 'skip') {
						const data = {
							assess_id: props?.assessmentId
						};
						if (props.onQuestionSubmit) props.onQuestionSubmit(data);
					}
					else {
						onQuestionSubmit();
					}

				}}
			>
				{
					(() => {

						if (!question) {
							return (
								<div className='align-self-center' >
									<ProcessingIndicator className={'medium-indicator'} />
								</div>
							);
						}

						return (
							<div className='assessment-test-container' style={{ justifyContent: 'center' }}>
								<div className='d-flex flex-column w-100 justify-content-center align-items-start' style={{ maxWidth: '800px' }}>
									<span className='subtitle-2 dark-100 disable-text-selection text-start' dangerouslySetInnerHTML={{ __html: question.text }}></span>
									<div className='w-100' style={{ maxWidth: '538px', marginTop: '24px', alignSelf: 'center' }}>
										<VideoRecorderWithChoice
											question={question}
											testType={TestType.VIDEO_TEST}
											videoCapturedAndUploaded={(video) => {
												if (videoMode === 'loom') {
													setLoomVideoURL(video);
													setCurrentVideoId('');
												}
												else {
													setLoomVideoURL('');
													setCurrentVideoId(video);
												}
											}}
											isSomthingIsInProcess={resource.processing || isLoomInProcess}
											setVideoCaptureMode={(video_mode) => { setvideoMode(video_mode); }}
											onRetry={() => { onQuestionSubmit(true); }}
											setIsSkipModalOpen={(data) => { setIsSkipModalOpen(data); }}
											isLoomVideoRecording={(status) => { setIsLoomInProcess(status); }}

										/>
									</div>
									<div className='w-100 d-flex justify-content-end align-items-end' style={{ marginTop: '24px' }}>
										<div tabIndex={-1} {...focusWithinProps}>
											{isSkipModalOpen ?
												<Button
													processing={props.processing}
													btn='ragular-btn' 
													varrient={'primary'}
													title={'Skip Question?'}
													style={{ width: 'max-content', height: '40px' }}
													onClick={() => {
														const data = {
															assess_id: props?.assessmentId
														};
														if (props.onQuestionSubmit) {
															setCurrentVideoId('');
															props.onQuestionSubmit(data);
														}
														setIsSkipModalOpen(false);
													}} 
												/>
												:
												<Button
													processing={props.processing}
													disabled={resource.processing || (props.is_mandatory && ((videoMode === 'native' && !currentVideoId) || (videoMode === 'loom' && loomVideoURL === ''))) || isLoomInProcess}
													btn='ragular-btn' varrient={'primary'}
													title={resource.processing ? 'Uploading...' : (props.currentQuestionNo === props.totalQuestions || 0 ? 'Submit' : 'Next')}
													style={{ width: '120px', height: '40px' }}
													onClick={() => {
														setCurrentVideoId('');
														if ((videoMode === 'native' && !currentVideoId) || (videoMode === 'loom' && loomVideoURL === '')) {
															setIsSkipModalOpen(true);
														}
														else if (!isSkipModalOpen) {
															onQuestionSubmit();
															setIsSkipModalOpen(false);
														}
													}} 
												/>
											}
										</div>
									</div>
								</div>
							</div>
						);
					})()
				}
			</TestContentWrapper>
		</>
	);
}