import { createSlice } from '@reduxjs/toolkit';
import { listCandidates, getCandidate } from '../thunks/Candidate';

const CandidateSlice = createSlice({
	name: 'User Profile Slice',
	initialState: {
		loading: false,
		processing: false,
		data: null,
		candidateData: [],
		current_candidate: null,
		error_message: null,
		success_message: null,
	},

	extraReducers: {

		[listCandidates.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[listCandidates.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				data: payload,
			};
		},
		[listCandidates.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

		[getCandidate.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},
		[getCandidate.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				current_candidate: payload,
			};
		},
		[getCandidate.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},

	}

});

export default CandidateSlice.reducer;