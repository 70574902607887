import React, { useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import { DateToMMDDYYYY, secondsToDhms } from '../../../utils/utilities';
import { ReactComponent as CodeIcon } from '../../../assets/icons/components/code-resposnse-result/code.svg';
import { ReactComponent as StartIcon } from '../../../assets/icons/components/code-resposnse-result/edit.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/components/code-resposnse-result/clock.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/components/code-resposnse-result/left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/components/code-resposnse-result/right.svg';
import { ReactComponent as CompleteIcon } from '../../../assets/icons/components/code-resposnse-result/check-circle.svg';

import ProgressBar from '../../../components/common/ProgressBar';
import CodingTestTimeline from '../../../components/dashboard/test-details/CodingTestDetails/CodingTestTimeline';
import { ExecutedSubmissionExpandedView, ExecutedTask } from '../../../components/dashboard/test-details/CodingTestDetails/ExecutedSubmissionExpandedView';

export default function PublicCodingTestDetails(props) {
	const { assessment, codeReport } = props;
	const [currentTest, setCurrentTest] = useState('');
	const [selectedResponse, setSelectedResponse] = useState(null);
	const [questionNo, setQuestionNo] = useState(0);
	const [currSnapShot, setCurrSnapShot] = useState(null);
	const [selectedSubmission, setSelectedSubmission] = useState({
		index: -1,
		submission: null
	});

	useMemo(() => {
		setCurrentTest(codeReport?.test);
		setSelectedResponse(codeReport?.responses[0]);
	}, [codeReport]);

	const QuestionTextMemo = useMemo(() => {
		if (!codeReport?.responses[0]) return;

		return (
			<ReactQuill
				theme='bubble'
				readOnly
				value={codeReport?.responses[0]?.text}
			/>
		);
	}, [codeReport]);

	const nameHandler = (assessment) => (assessment?.user?.first_name || assessment?.user?.last_name) ? `${assessment?.user?.first_name || ''} ${assessment?.user?.last_name || ''}` : `${assessment?.email || '-'}`;

	return (
		<div className='w-100 d-flex flex-column' style={{ gap: '24px' }}>
			<div className='w-100 d-flex flex-column justify-content-between' style={{ gap: '24px' }}>
				<div className='d-flex align-items-center coding-test'>
					<div className='d-flex flex-column align-items-start' style={{ gap: '8px' }}>
						<span className='headline-4 dark-100'
							title={currentTest?.title}
							style={{ textAlign: 'left' }}
						>
							{currentTest?.title}
						</span>
						<span> <ClockIcon /> <span className='body-2 dark-50'>Total Time</span> <span className='body-2' style={{ color: '#121216' }}>{secondsToDhms(currentTest?.duration)} mins</span></span>
						<span> <CodeIcon /> <span className='body-2 dark-50'>Language Used</span>
							<span className='body-2 dark-100'>
								{` ${selectedResponse?.code_language.charAt(0).toUpperCase() + selectedResponse?.code_language.slice(1)}`}
							</span>
						</span>
					</div>
				</div>
				<div className='w-100 d-flex align-items-center justify-content-between'>
					<div className='w-100 d-flex flex-column align-items-start' style={{ marginRight: '24px', gap: '8px' }}>
						<div className='w-100 d-flex flex-row align-items-start justify-content-between' style={{ gap: '16px' }}>
							<span className='headline-4 dark-100' style={{ textAlign: 'left' }}>{nameHandler(assessment) || 'Candidate Name'}</span>
							<div style={{ width: '100px' }}>
								<ProgressBar value={parseInt((currentTest?.score * 100) || 0)} />
							</div>
						</div>
						<span> <StartIcon /> <span className='body-2 dark-50'>Started on </span> <span className='body-2 dark-100 ' >{DateToMMDDYYYY(currentTest?.started_at)}</span></span>
						<span> <CompleteIcon /> <span className='body-2 dark-50'>Completed on </span> <span className='body-2 dark-100' >{DateToMMDDYYYY(currentTest?.completed_at)}</span></span>
					</div>
				</div>
			</div>
			<div className='coding-test-response-container'>
				<div className='w-100 d-flex flex-column' style={{ marginBottom: '8px' }}>
					<div className='w-100 d-flex justify-content-between align-items-center mb-3'>
						<span className='subtitle-2 dark-100'>Question No {questionNo + 1}</span>
						<div className='d-flex align-items-center' style={{ gap: '11px' }}>
							<ArrowLeftIcon className='pointer' style={{width: '16px', height: '16px'}} onClick={() => { if (questionNo > 0) { setQuestionNo(questionNo - 1); } }} />
							<span className='body-2 medium black-500'><span className='primary-text'>{questionNo + 1}</span> of {codeReport?.responses?.length}</span>
							<ArrowRightIcon className='pointer' style={{width: '16px', height: '16px'}} onClick={() => { if ((questionNo + 1) < codeReport?.responses?.length) { setQuestionNo(questionNo + 1); } }} />
						</div>
					</div>
					<div className='coding-test-question-container'>
						<div className='body-1 text-start w-full mb-3 dark-100'>Statement</div>
						<span className='body 2 dark-100'>
							{QuestionTextMemo}
						</span>
					</div>
				</div>
				<div className='w-100 d-flex flex-column' style={{padding:'2em' ,background:'#F6F5F8',borderRadius:'24px'}}>
					<span className='body-1 text-start dark-100'>Solution:</span>
					{
						selectedResponse?.status === 'SKIPPED' ?
							<span className='body-2 danger-text text-start' style={{marginBottom: '48px'}}>No solution to display! This question was skipped.</span>
							:
							selectedResponse?.status === 'EXPIRED' ?
								<span className='body-2 danger-text text-start' style={{marginBottom: '48px'}}>No solution to display! This question was expired.</span>
								:
								<div className='coding-test-timeline-container'>
									<CodingTestTimeline
										onChangeCodeSnapshot={(codeSnapshot) => { setCurrSnapShot(codeSnapshot); setSelectedSubmission({ submission: null, index: -1 }); }}
										response={selectedResponse}
										codeSnapshots={codeReport?.snapshots?.filter((snapshot, _) => { return (snapshot?.ques_id === selectedResponse?.id); })} 
									/>
									<div className='coding-result-event-container'>
										<div className='d-flex w-100 justify-content-between align-items-center'>
											<span className='headline-small semibold' style={{ color: '#000000' }}>Event:</span>
											<button className='body-3 black-500' style={{width: 'auto', height: 'auto', padding: '4px 8px'}}>{currSnapShot?.type === 'autosave' ? 'Auto Save' : currSnapShot?.type === 'copypaste' ? 'Copy Paste' : 'Verify'}</button>
										</div>
										{currSnapShot?.type === 'verify' &&
											<>
												<span className='body-3 black-700' style={{ fontWeight: '500' }}>Validation Result:
													<span className='body-3' style={{ fontWeight: '400', color: currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (currSnapShot?.final ? 'validation' : 'test')); })[0]?.submissions?.filter((submission, _) => { return (!submission.has_passed); }).length > 0 ? '#FC4848' : '#00D69A' }}>
														{currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (currSnapShot?.final ? 'validation' : 'test')); })[0]?.submissions?.filter((submission, _) => { return (!submission.has_passed); }).length > 0 ?
															' Task failed'
															:
															' Task executed successfully!'
														}
													</span>
												</span>
												<div className='executed-tasks-container'>
													{
														currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (currSnapShot?.final ? 'validation' : 'test')); }).length > 0 &&
														currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (currSnapShot?.final ? 'validation' : 'test')); })[0]?.submissions &&
														currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (currSnapShot?.final ? 'validation' : 'test')); })[0]?.submissions.map((submission, _) => {
															const isFinalSnapshot = currSnapShot?.final;
															const length = currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (isFinalSnapshot ? 'validation' : 'test')); })[0]?.submissions.length;
															return (
																<div key={_} className='d-flex flex-column'>
																	<ExecutedTask key={_} submission={submission} isLastIndex={_ === (length - 1)} onClick={() => { setSelectedSubmission({ submission: submission, index: _ }); }} />
																	{(selectedSubmission.index != -1 && selectedSubmission.index === _ && selectedSubmission.submission) &&
																		<ExecutedSubmissionExpandedView
																			submission={selectedSubmission.submission}
																			token={currSnapShot?.exec_results.filter((result, _) => { return (result.exec_type === (isFinalSnapshot ? 'validation' : 'test')); })[0].tokens[selectedSubmission.index]}
																			submissionIndex={selectedSubmission.index}
																		/>
																	}
																</div>
															);
														})
													}
												</div>
											</>
										}
									</div>
								</div>
					}
				</div>
			</div>
		</div>
	);
}
