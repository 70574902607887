import React from 'react';
import logo from './../assets/icons/logo/testfuseLogo.svg';
import { useMediaQuery } from '@mui/material';

const OnboardingBox = (props) => {
	const { children,isAssessmentConcluded,varrient } = props;
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const isOnTabScreen = useMediaQuery('(max-width: 1200px)');

	if (varrient === 'primary') {
		return (
			
			<div className="registration-background-container d-flex justify-content-center align-items-center w-100 h-100" style={{background:'transparent',overflowX:'hidden'}}>
				<div className="top-content-container" >
					<div className="content-container w-100">
						{/* <div className="top-filled-box"></div>
						<div className="top-empty-box"></div>
						<div className="bottom-filled-box"></div>
						<div className="bottom-dotted-box"></div> */}
						{props.enableFullPage ?
							<div className="content-full-box w-100">
								{children}
							</div>
							:
							<div className="content-box w-100" >
								<div className="logo" >
									{
										isAssessmentConcluded ? (
											<></>
										) : (
											<img src={logo} alt="testfuse" />
										)
									}
								</div>
								{children}
							</div>
						}
					</div>
				</div>
				{/* <div className="footer">
					<div className="footer-content">
						<span className='description primary-text'>© 2023 Testfuse Inc.</span>
						<span className='description'><a href={`${window._env_.TF_LANDING_SITE_URL}/privacy-policy`} rel='noopener noreferrer' target='_blank' className='semibold' style={{ color: '#6F2DBD' }}>Privacy Policy</a></span>
						<span className='description'><a href={`${window._env_.TF_LANDING_SITE_URL}/terms-of-service`} rel='noopener noreferrer' target='_blank' className='semibold' style={{ color: '#6F2DBD' }}>Terms of Service</a></span>
					</div>
				</div> */}
			</div>
		);
	}
	else {
		return (
			<div className="registration-background-container onBoarding-container hide-scrollbar" >
				<div className=' d-flex justify-content-between align-items-center onBoarding-container-text' style={{background:'#121216' ,width:'50%',height:'100%',borderRadius:'24px',padding:'44px'}}>
					<div style={{textAlign:'left',margin:'auto'}}>
						<div className='headline-1 white' style={{fontSize:'48px',textAlign:'left',lineHeight:'60px'}}>Step into the world of <br/> <span style={{color:'#7C21FF'}}>recruiting</span> excellence! Lets <br/> find <span style={{color:'#7C21FF'}}> champions</span> together!</div>

					</div>
				</div>
				<div className='d-flex justify-content-center align-items-center onBoarding-container-box' style={{background:'white' ,width:'47.5%',borderRadius:'24px',padding:'88px'}}>
					<div className="top-content-container w-100">
						<div className="content-container hide-scrollbar">
							{props.enableFullPage ?
								<div className="content-full-box">
									{children}
								</div>
								:
								<div className="content-box">
									<div className="logo d-flex" style={{}}>
										{
											isAssessmentConcluded ? (
												<></>
											) : (
												<img src={logo} alt="testfuse" />
											)
										}
									</div>
									{children}
								</div>
							}
						</div>
					</div>
				</div>
				
				{/* <div className="footer">
					<div className="footer-content">
						<span className='description primary-text'>© 2023 Testfuse Inc.</span>
						<span className='description'><a href={`${window._env_.TF_LANDING_SITE_URL}/privacy-policy`} rel='noopener noreferrer' target='_blank' className='semibold' style={{ color: '#6F2DBD' }}>Privacy Policy</a></span>
						<span className='description'><a href={`${window._env_.TF_LANDING_SITE_URL}/terms-of-service`} rel='noopener noreferrer' target='_blank' className='semibold' style={{ color: '#6F2DBD' }}>Terms of Service</a></span>
					</div>
				</div> */}
			</div>
		);

	}
};

export default OnboardingBox;
