import { createSlice } from '@reduxjs/toolkit';
import { listTestHistoryResult, getTestHistoryResult, getTestResultPageNo } from '../thunks/TestHistory';

const TestHistorySlice = createSlice({
    name: 'AntiCheatSlice',
    initialState: {
        loading: false,
        processing: false,
        data: null,
        test_history_result: null,
        error_message: null,
        success_message: null,
    },

    extraReducers: {

        [listTestHistoryResult.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [listTestHistoryResult.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
            };
        },
        [listTestHistoryResult.rejected]: (state) => {
            return {
                ...state,
                loading: false,
            };
        },

        [getTestHistoryResult.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [getTestHistoryResult.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                test_history_result: payload,
            };
        },
        [getTestHistoryResult.rejected]: (state) => {
            return {
                ...state,
                loading: false,
            };
        },

        [getTestResultPageNo.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [getTestResultPageNo.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                test_history_result: payload,
            };
        },
        [getTestResultPageNo.rejected]: (state) => {
            return {
                ...state,
                loading: false,
            };
        },
    },
});

export default TestHistorySlice.reducer;