import React, { useEffect } from 'react';
import { ReactComponent as UnVistIcon } from '../../assets/icons/candidate-assessment/untick.svg';
import { ReactComponent as VistedIcon } from '../../assets/icons/candidate-assessment/SCheckCircle.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/icons/candidate-assessment/LCheckCircle.svg';
import { calculateExpiry } from '../../utils/utilities';
import ProgressBar from '../common/ProgressBar';
import Button from '../sub-component/Button';

export default function OnTestCompleteCard(props) {

	const { onSecondaryBtnClick, onPrimaryBtnClick, assessment, testName } = props;

	useEffect(() => {
		sessionStorage.setItem('cam_and_mic_permission_for_test', 'blocked');
	}, []);

	return (
		<div className='complete-card-container'>
			<div className='on-test-complete-card-container'>
				<CheckCircleIcon />
				<span className='subtitle-1 dark-100'>You have successfully submitted your {testName}!</span>
				<div className='w-100 d-flex flex-column align-items-start' style={{gap: '12px'}}>
					<div className='w-100 d-flex flex-row align-items-center justify-content-between' style={{gap: '12px'}}>
						<span className='body-1 dark-100 text-start'>Assessment Progress</span>
						<div style={{ width: '110px' }}>
							<ProgressBar value={parseInt(((assessment.current_assessment.tests.filter((test, _) => { return (test.completed === true); }).length + ((assessment?.current_assessment?.qualifying_questions && assessment?.current_assessment?.attempted_qq) ? 1 : 0)) / (assessment.current_assessment.tests.length + (assessment?.current_assessment?.qualifying_questions ? 1 : 0))) * 100)}/>
						</div>
					</div>
					{assessment?.current_assessment?.deadline &&
						<span className='body-2 dark-50'>Deadline: <span className='dark-100'>{calculateExpiry(assessment?.current_assessment?.deadline, assessment?.current_assessment?.created_at)}</span></span>
					}
				</div>
				<div className='on-test-complete-card-stats-container'>
					{assessment?.current_assessment?.qualifying_questions &&
							<div className='w-100 d-flex justify-content-between align-items-center'>
								<span className={`body-2 ${(assessment?.current_assessment?.attempted_qq || assessment?.current_assessment?.active_test?.test?.test_type === 'qualifying_test') ? 'dark-100' : 'dark-100'}`}>{'Qualifying Questions'}</span>
								{assessment?.current_assessment?.attempted_qq &&
									<VistedIcon />
								}
							</div>
					}
					{
						assessment.current_assessment.sections.map((_section, _index) => {
							return (
								<div key={_index} className='w-100 d-flex justify-content-between align-items-center' style={{height: '24px'}}>
									<span className={`body-2 ${(_section.completed) ? 'dark-100' : 'dark-100'}`}>{_section?.intro_text}</span>
									{_section.completed &&
										<VistedIcon />
									}
								</div>
							);
						})
					}
				</div>
				<div className='w-100 d-flex flex-row align-items-start justify-content-between' style={{gap: '24px'}}>
					<Button 
						btn='ragular-btn' 
						varrient='secondary-btn' 
						title={'Pause assessment'} 
						style={{ width: 'max-content', height: '40px' }} 
						onClick={onSecondaryBtnClick} 
					/>
					<Button 
						btn='ragular-btn' 
						varrient='primary' 
						title={'Next Test'} 
						style={{ width: 'max-content', height: '40px', }} 
						onClick={onPrimaryBtnClick} 
					/>
				</div>
			</div>
		</div>
	);
}
