export const userProfileValidator = (userProfile) => {
	const isFirstNameValid = userProfile.first_name !== '' && userProfile.first_name.length > 0;
	const isLastNameValid = userProfile.last_name !== '' && userProfile.last_name.length > 0;

	const isAllValid = isFirstNameValid && isLastNameValid;

	return ({ isAllValid, isFirstNameValid, isLastNameValid });
};

export const companyProfileValidator = (companyProfile) => {
	const isCompanyNameValid = companyProfile.company_name !== '' && companyProfile.company_name.length > 2;

	const isAllValid = isCompanyNameValid;

	return ({ isAllValid, isCompanyNameValid });
};