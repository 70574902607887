import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SignupForm from '../components/auth/SignupForm';
import OnboardingBox from '../components/OnboardingBox';
import { PathName } from '../utils/routes';
import { useMediaQuery } from '@mui/material';
import CustomModal from '../components/common/Modal';
import Button from '../components/sub-component/Button';
// import SignUpWeb from './SignUp/SignUpWeb';

export default function SignUp() {
	const isOnWebScreen = useMediaQuery('(min-width:1200px)');
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1439px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const [openModal, setOpenModal] = useState(false);

	const history = useHistory();
	const search = useLocation().search;
	const searchParams = new URLSearchParams(search);
	const invite_token = searchParams.get('invite_token');
	const invite_id = searchParams.get('invite_id');

	return (
		<>

			<CustomModal
				open={openModal}
				hideBtns
			>
				<div className='w-100 d-flex flex-column align-items-center justify-content-center' style={{ gap: '24px', padding: '24px' }}>
					<span className='headline-4 dark-100 text-center'>Account Creation Exclusively for Companies</span>
					<span className='body-2 dark-50 text-center mb-3'>This account creation is exclusively for companies. If you&apos;re a candidate, please request a company to invite you for an Assessment to start using Testfuse.</span>
					<Button
						btn='ragular-btn'
						varrient='primary'
						title={'Close'}
						style={{ width: 'max-content', height: '52px', }}
						onClick={() => {
							setOpenModal(false);
						}}
						rounded
					/>
				</div>
			</CustomModal>
			<OnboardingBox >
				<div className="d-flex flex-column login-form-container">
					<div className="form">
						<div className={`${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-3'} dark-100`} id="login">
							Signup
						</div>
						<div className='link-text' style={{ marginBottom: '8px' }}>
							<span className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '2'}`}>Already a member? </span>
							<span className={`link-to-login pointer body-${isOnMobileScreen || isOnTabletScreen ? '1' : '2'}`} onClick={() => {
								history.push({
									pathname: PathName.LOGIN,
									search: search
								});
							}}>Login</span>
						</div>
						{(!invite_token && !invite_id) &&
							<span className='body-3 dark-50'>Account creation is exclusively for companies. Click <span className='primary-text pointer' onClick={() => { setOpenModal(true); }}>here</span> if you&apos;re a candidate.</span>
						}
						<div>
							<SignupForm />
						</div>
					</div>
				</div>
			</OnboardingBox>

		</>
	);
}
